import React, { ReactElement, useRef, useEffect, useState } from 'react';

import { CSSTransition } from 'react-transition-group';
import classNames from 'clsx';
import transitionStyle from '../../../styles/animaion/listTransition.module.scss';
import fadeStyle from './fade.module.scss';
import styles from './index.module.scss';

import { Collapse } from 'reactstrap';
import TurningArrow from 'components/misc/TurningArrow/TurningArrow';

//utility method
interface IEntityWithCollapseList {
  openList: boolean;
}

export const toggleList = <
  Entity extends IEntityWithCollapseList & { [x: string]: any }
>(
  setEntities: Function,
  keyEntityId: string
) => {
  return (id: number) =>
    setEntities((prevState: Entity[]) =>
      prevState.map((entity: Entity) =>
        entity[keyEntityId] !== id
          ? entity
          : { ...entity, openList: !entity.openList }
      )
    );
};

type PropsType<Entity> = {
  visibility: boolean;
  openList: boolean;
  entity: Array<Entity>;
  toggleTd: (id: number) => void;
  entityId: number;
  displayedNumberOfItems?: number;
  keyText?: string;
};

function TDCollapse<Entity extends string | JSX.Element>(
  props: PropsType<Entity>
): ReactElement<any, any> {
  const wrapperBlock = useRef<HTMLTableDataCellElement>(null);
  const commentsBlock = useRef<HTMLDivElement>(null);
  const [itemDisplay, setItemDisplay] = useState<number>(1);

  useEffect(() => {
    const tdHeight: number | undefined =
      wrapperBlock?.current?.getBoundingClientRect().height;

    const commentsBlockHeight: number | undefined =
      commentsBlock?.current?.getBoundingClientRect().height;

    if (
      commentsBlockHeight !== undefined &&
      tdHeight !== undefined &&
      ~~(tdHeight / commentsBlockHeight) > 1
    ) {
      setItemDisplay((prevState: number) => prevState + 1);
    }
  }, []);

  const { visibility, openList, entity, toggleTd, entityId } = props;
  return (
    <td
      ref={wrapperBlock}
      className={classNames({
        'd-none': !visibility,
      })}
    >
      <div className={classNames(styles.tableIconsWrapper)}>
        <CSSTransition
          in={!openList}
          timeout={300}
          classNames={fadeStyle}
          unmountOnExit
          appear
        >
          <div className="d-flex flex-column" ref={commentsBlock}>
            {entity.slice(0, itemDisplay).map((el: Entity, i: number) => (
              <div style={{ marginRight: '5px' }} key={i}>
                {<span>{el}</span>}
              </div>
            ))}
          </div>
        </CSSTransition>
        {entity.length > itemDisplay && (
          <div className={classNames(styles.buttonViewFilesContainer)}>
            <TurningArrow
              id={entityId}
              onClick={() => toggleTd(entityId)}
              turnUp={openList}
            />
          </div>
        )}
      </div>
      <Collapse isOpen={openList}>
        <CSSTransition
          in={openList}
          timeout={600}
          classNames={transitionStyle}
          unmountOnExit
          appear
        >
          <ul>
            {entity.map((name: Entity, i: number) => (
              <li key={i} className={styles.listItem}>
                <span>{name}</span>
              </li>
            ))}
          </ul>
        </CSSTransition>
      </Collapse>
    </td>
  );
}

export default TDCollapse;
