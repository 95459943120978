import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { FC } from 'react';
import classNames from 'clsx';
import styles from './index.module.scss';
import { t } from '../../../services/utils/translation';
import { ReactComponent as ArrowIcon } from '../../../img/icons/icon-top-arrow.svg';

type PropsType = {
  id: number | string;
  turnUp: boolean;
  onClick: () => void;
  hintUp?: string;
  hintDown?: string;
  className?: string;
};
const TurningArrow: FC<PropsType> = (props) => {
  const {
    id,
    turnUp,
    onClick,
    hintUp = t('Скрыть'),
    hintDown = t('Смотреть все'),
    className,
  } = props;
  const idArrow = `tooltip-${id}-arrow`;
  return (
    <>
      <div
        id={idArrow}
        onClick={onClick}
        className={classNames(styles.arrowStyle, className, 'mb-2', {
          [styles.rotateArrow]: turnUp,
        })}
      >
        <ArrowIcon />
      </div>
      {
        <ToolTipWrapper placement="top" target={idArrow}>
          {turnUp ? hintUp : hintDown}
        </ToolTipWrapper>
      }
    </>
  );
};

export default TurningArrow;
