import React, { FC, FocusEventHandler, memo } from 'react';
import Select from 'react-select';
import { GroupedOptionsType, OptionsType, ValueType } from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import classNames from 'clsx';
import chroma from 'chroma-js';
import formatOptionLabelHighLighter from '../utils/formatOptionLabelHighLighter';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';
import { IClientSelectOptionWithColor } from 'services/api/interfacesApi/IClientSelectOptionWithColor';

type TypeProps = {
  id: string;
  label?: string;
  placeholder?: string;
  onBlur?: FocusEventHandler;
  onChange: Function; //ChangeEventHandler,
  hasError: boolean;
  required?: boolean;
  defaultValue?: ValueType<IClientSelectOptionV2, boolean>;
  value?: ValueType<IClientSelectOptionV2, boolean>;
  options?:
    | GroupedOptionsType<IClientSelectOptionV2>
    | OptionsType<IClientSelectOptionV2>
    | IClientSelectOptionWithColor[];
  showDefaultOption?: boolean;
  defaultOptionLabel?: string;
  isMulti?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  // Used to display server-side error message, if the server-side error key is not the same as at the front-end side.
  additionalErrorKey?: string;
  colorsOptions?: boolean;
  highlightEditing?: boolean;
};

const SelectFieldColors: FC<TypeProps> = (props) => {
  const {
    id,
    label,
    placeholder,
    onBlur,
    onChange,
    hasError,
    required = false,
    defaultValue,
    value,
    options,
    isMulti = false,
    disabled = false,
    isClearable = true,
    additionalErrorKey,
    colorsOptions = false,
    highlightEditing = false,
  } = props;

  const selectStyles = {
    menu: (styles: any) => ({
      ...styles,
      zIndex: 999,
    }),
    multiValueLabel: (styles: any) => ({ ...styles, background: '#D6F1F7' }),
    multiValueRemove: (styles: any) => ({ ...styles, background: '#D6F1F7' }),
    control: (styles: any, state: any) => ({
      ...styles,
      '&:hover': { borderColor: '#ACB5C4' },
      border: state.isFocused && '1px solid #49A6C8',
      boxShadow: state.isFocused ? '1' : 'none',
    }),
    option: (styles: any) => ({
      ...styles,
      '&:hover': { background: '#D6F1F7' },
    }),
  };

  const colorOptions = {
    menu: (styles: any) => ({ ...styles, zIndex: 999 }),
    control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      const color =
        data.color === '#AD8D43' ? chroma('orange') : chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles: any, { data }: any) => {
      const color =
        data.color === '#AD8D43' ? chroma('orange') : chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  const customStyles = !colorsOptions ? selectStyles : colorOptions;
  return (
    <FormGroup className={classNames({ required: required })}>
      {label && (
        <Label className="control-label" for={id}>
          {label}
        </Label>
      )}
      <ErrorWrapperField id={id} additionalErrorKey={additionalErrorKey}>
        <Select
          placeholder={placeholder ? placeholder : label}
          name={id}
          id={id}
          options={options}
          onBlur={onBlur}
          onChange={(value: ValueType<IClientSelectOptionV2, boolean>) =>
            onChange(value)
          }
          className={classNames('f-custom-select', {
            'is-invalid': hasError,
            borderBackLight: highlightEditing && !hasError,
          })}
          required={required}
          isMulti={isMulti}
          value={value != null ? value : defaultValue}
          defaultValue={defaultValue}
          isDisabled={disabled}
          isClearable={isClearable}
          styles={customStyles}
          formatOptionLabel={formatOptionLabelHighLighter}
        />
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(SelectFieldColors);
