import { IBillingAccountFilters } from '../../services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';
import config from '../../config.json';
import BillingAccountSortCriteria from '../../services/api/organizationsV2/billing-account/BillingAccountSortCriteria';

export const initialBillingAccountsFilters: IBillingAccountFilters = {
  billing_account_pk: undefined,
  billing_account_datetime_added_upper_bound: '',
  billing_account_datetime_added_lower_bound: '',
  billing_account_datetime_edited_lower_bound: '',
  billing_account_datetime_edited_upper_bound: '',
  billing_account_description: '',
  billing_account_type_fk_array: [],
  billing_account_fulltext_search: '',
  billing_account_code_number: undefined,
  billing_account_comment_text: '',
  billing_account_counterparty_fk_array: [],
  skip: 0,
  length: config.pageLength,
  sort_by: BillingAccountSortCriteria.Default,
  billing_account_1c_number: '',
  billing_account_stek_number: '',
  billing_account_address: '',
  isActive1CNumberFilter: true,
};
