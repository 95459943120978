import ApiClientBase from './ApiClientBase';
import { isDev } from '../../config';
import UndercurrentApiMethods from './UndercurrentApiMethods';
import DocumentApiMethods from './DocumentApiMethods';
import { UNDERCURRENT_CHILD_MODULES } from '../constants/UNDERCURRENT_CHILD_MODULES';

const DEV_UNDERCURRENT_INSTALLATION_NAME = 'generic';
class UndercurrentApiClient extends ApiClientBase {
  constructor(installation: string | null = null) {
    super(
      'undercurrent',
      isDev() ? DEV_UNDERCURRENT_INSTALLATION_NAME : installation,
      'v2'
    );
  }
  methodCreator = (partUrl: UNDERCURRENT_CHILD_MODULES) => {
    return new UndercurrentApiMethods(partUrl);
  };

  documentMethodCreator = (partUrl: UNDERCURRENT_CHILD_MODULES) => {
    return new DocumentApiMethods(partUrl);
  };
}

export default UndercurrentApiClient;
