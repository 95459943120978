import useToggleWitchInput from 'hooks/useToggleWitchInput/useToggleWitchInput';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_FRONTPAGE } from 'services/pathConstants';

const useIsStartingPathWasVisited = () => {
  const [isStartingPathWasVisited, setIsStartingPathWasVisited] =
    useToggleWitchInput();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === PATH_FRONTPAGE) {
      setIsStartingPathWasVisited(true);
    }
  }, [location.pathname, setIsStartingPathWasVisited]);

  return isStartingPathWasVisited;
};

export default useIsStartingPathWasVisited;
