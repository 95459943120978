import { memo, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import Pager from '../Pager';
import { PagerProps } from '../Pager';
import classNames from 'clsx';
type PropsTypeWrapperGrayTable = {
  className?: string;
  pagerProps?: PagerProps;
};

const WrapperGrayTable = (
  props: PropsWithChildren<PropsTypeWrapperGrayTable>
) => {
  const { className, children, pagerProps } = props;

  return (
    <div
      className={classNames(
        'd-flex justify-content-between',
        className,
        styles.childWrapper
      )}
    >
      <div className={classNames('ml-4 mr-4')}>
        {children}
        {pagerProps && (
          <Pager
            onPageChange={pagerProps.onPageChange}
            onLengthChange={pagerProps.onLengthChange}
            total={pagerProps.total}
            length={pagerProps.length}
            start={pagerProps.start}
          />
        )}
      </div>
    </div>
  );
};

export default memo(WrapperGrayTable);
