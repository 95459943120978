import { isObject } from '../misc/misc';

/**
 * We don't want to send extra data in requests
 * Method for removing unnecessary  parameters
 *
 * @export
 * @template ParamsType
 * @param {ParamsType} params
 * @param {string[]} [keyToDelete]
 * @param exceptionsKeys
 * @return {*}  {ParamsType}
 */
export function deleteUnnecessaryParameters<
  ParamsType extends {
    [K in keyof ParamsType]: ParamsType[K];
  }
>(
  params: ParamsType,
  keyToDelete?: string[],
  exceptionsKeys?: string[]
): NonNullable<ParamsType> {
  const clearParams = { ...params };
  for (let key in clearParams) {
    if (clearParams.hasOwnProperty(key) && isObject(clearParams[key])) {
      clearParams[key] = deleteUnnecessaryParameters(
        clearParams[key],
        keyToDelete,
        exceptionsKeys
      );
    }
    if (clearParams.hasOwnProperty(key)) {
      const prop: any = clearParams[key];
      if (
        prop === '' ||
        prop === undefined ||
        prop === null ||
        prop?.length === 0 ||
        (keyToDelete?.includes(key) && !exceptionsKeys?.includes(key))
      ) {
        delete clearParams[key];
      }
    }
  }
  return clearParams as NonNullable<ParamsType>;
}

export default deleteUnnecessaryParameters;
