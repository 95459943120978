import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { useMemo } from 'react';
import { targetAccessPolicyForAccess } from '../../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyPersonnel';
import {
  targetAccessPolicyEmployee,
  targetAccessPolicyJobTitle,
} from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyPersonnel';

const useGetEmployeeAccessPolicy = () => {
  const [haveAccessToUpdate, haveAccessToExport] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEmployee,
    targetAction: ['U', 'E'],
  });

  const [jobTitleHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyJobTitle,
    targetAction: ['R'],
  });

  const [accessPolicyHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyForAccess,
    targetAction: ['R'],
  });

  return useMemo(
    () => ({
      accessPolicyHaveAccessToRead,
      jobTitleHaveAccessToRead,
      employee: {
        haveAccessToUpdate,
        haveAccessToExport,
      },
    }),
    [
      haveAccessToExport,
      haveAccessToUpdate,
      jobTitleHaveAccessToRead,
      accessPolicyHaveAccessToRead,
    ]
  );
};

export default useGetEmployeeAccessPolicy;
