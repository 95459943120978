import { ICounterparty } from 'services/api/organizationsV2/counterparty/ICounterparty';
import { getFullNameFromInitials } from 'services/utils/getFullNameFromInitials/getFullNameFromInitials';
import IClientInitials from '../../../../../services/types/IClientInitials';

const getNameCounterparty = (initials: IClientInitials, companyName?: string) =>
  companyName ? companyName : getFullNameFromInitials(initials);

export interface ICounterpartyDetails {
  counterpartyPk: number;
  name: string;
  address: string;
  phoneNumber: number;
  isNaturalPerson: boolean;
  isAffiliate: boolean;
  inn: string;
}

const toCounterpartyDetails = (
  counterparty: ICounterparty
): ICounterpartyDetails => {
  const {
    counterparty_pk: counterpartyPk,
    counterparty_company_full_name: companyName,
    counterparty_individual_last_name: lastName,
    counterparty_individual_first_name: firstName,
    counterparty_individual_middle_name: middleName,
    counterparty_legal_address: address,
    counterparty_phone_number: phoneNumber,
    counterparty_is_natural_person: isNaturalPerson,
    counterparty_inn: inn,
    counterparty_is_affiliate: isAffiliate,
  } = counterparty;
  return {
    counterpartyPk,
    name: getNameCounterparty({ firstName, lastName, middleName }, companyName),
    address,
    phoneNumber,
    isNaturalPerson,
    inn,
    isAffiliate: !!isAffiliate,
  };
};

export default toCounterpartyDetails;
