import { FC, useState, useMemo } from 'react';
import Task from 'models/Task';
import TaskForm, {
  IFormValuesTask,
} from '../../../../components/tasks/TaskForm/TaskForm';
import IClientSelectOptionV2 from '../../../../services/api/interfacesApi/IClientSelectOptionV2';
import Subheader from 'components/layouts/Subheader/Subheader';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import TaskHeader from 'components/tasks/TaskHeader/TaskHeader';
import { PATH_EXTERNAL_REQUEST_TABLE } from '../../../../services/pathConstants';
import { readExternalRequestV2 } from '../../../../services/api/external-request/external-request/externalRequestApi';
import useRead from 'hooks/useRead';
import IPrimaryKey from 'services/types/IPk';
import useExtractGetBillingAccountFromEvent from '../hooks/useExtractGetBillingAccountFromEvent';
import { TFormValuesTaskWithEvent } from './interfaces/TFormValuesTaskWithEvent';
import { convertExternalRequestToFormValuesTask } from './utils/convertExternalRequestToFormValuesTask';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';
import useTaskSuccessHandler from '../hooks/useTaskSuccessHandler';

const getBillingAccountAndReturnInitialValues = (
  initialValues: TFormValuesTaskWithEvent,
  billingAccountFromEvent?: IClientSelectOptionV2
): IFormValuesTask => {
  return {
    ...initialValues,
    account: billingAccountFromEvent?.label
      ? billingAccountFromEvent
      : initialValues?.account,
  };
};

const TaskCreateFromExternalRequestPage: FC = () => {
  const externalRequestPk = Number(useGetIdFromPath());

  const [initialParams] = useState<IPrimaryKey>({ pk: externalRequestPk });

  const { data: initialValuesWithEvent, isLoading } = useRead({
    getDataApi: readExternalRequestV2,
    initialData: { ...new Task(), event: undefined },
    params: initialParams,
    condition: !!externalRequestPk,
    convertData: convertExternalRequestToFormValuesTask,
  });

  const { billingAccountFromEvent, isLoadingEvent } =
    useExtractGetBillingAccountFromEvent(initialValuesWithEvent?.event);

  const initialValues = useMemo(() => {
    return getBillingAccountAndReturnInitialValues(
      initialValuesWithEvent,
      billingAccountFromEvent
    );
  }, [billingAccountFromEvent, initialValuesWithEvent]);

  const successHandler = useTaskSuccessHandler(PATH_EXTERNAL_REQUEST_TABLE);

  return (
    <MainLayout>
      <Subheader />
      <TaskHeader isInitialValuesFromOrder />
      <TaskForm
        initialValuesFromOrder={initialValues}
        successHandler={successHandler}
        viewOnly={false}
        isLoadingTask={isLoading || isLoadingEvent}
      />
    </MainLayout>
  );
};

export default TaskCreateFromExternalRequestPage;
