import useNextWindow from 'hooks/useNextWindow';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PATH_EXTERNAL_REQUEST_ADD,
  PATH_EXTERNAL_REQUEST_COPY,
  PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST,
  PATH_EXTERNAL_REQUEST_EDIT,
  PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST,
} from 'services/pathConstants';

interface IUseGetExternalRequestNavigationHandlers {
  billingAccountId?: number;
  eventPk?: number;
}

const useGetExternalRequestNavigationHandlers = ({
  billingAccountId,
  eventPk,
}: IUseGetExternalRequestNavigationHandlers) => {
  const location = useLocation();
  const navigate = useNavigate();

  const nextWindowExternalRequestEditPage = useNextWindow(
    PATH_EXTERNAL_REQUEST_EDIT,
    location.pathname
  );

  const nextWindowExternalRequestDuplicatePage = useNextWindow(
    PATH_EXTERNAL_REQUEST_COPY,
    location.pathname
  );

  const nextWindowChildrenExternalRequest = useNextWindow(
    PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST,
    location.pathname
  );
  const nextWindowCreateChildrenTask = useNextWindow(
    PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST,
    location.pathname
  );

  const handleAddExternalRequest = () =>
    navigate(PATH_EXTERNAL_REQUEST_ADD, {
      state: { billingAccountId, eventPk },
    });

  return {
    nextWindowCreateChildrenTask,
    nextWindowChildrenExternalRequest,
    nextWindowExternalRequestDuplicatePage,
    nextWindowExternalRequestEditPage,
    handleAddExternalRequest,
  };
};

export default useGetExternalRequestNavigationHandlers;
