import IClientInitials from '../../types/IClientInitials';

/**
 *
 *
 * @param {string} firstName
 * @param {string} lastName
 * @param middleName
 * @return {*}
 */
const getInitials = ({ firstName, lastName, middleName }: IClientInitials) => {
  const checkMiddleName = middleName ? middleName.trim() : '';
  return `${lastName ?? ''} ${firstName?.[0] ? firstName[0] + '.' : '-'} ${
    checkMiddleName && checkMiddleName.length >= 1
      ? checkMiddleName[0] + '.'
      : ''
  }`.trim();
};

export default getInitials;
