import './wdyr';
import { createRoot } from 'react-dom/client';
import App from './components/misc/App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { checkAuthApiV2 } from 'services/api/auth/authApiV2';
import { IBaseResponse } from './services/api/interfacesApi/IBaseResponse';
import { IIdentity } from 'services/api/auth/IRegisterIdentity';
import getActiveInstallation from './store/utils/getActiveInstallation';
import { userLoginApiV2 } from './store/auth/actions';
import { Provider } from 'react-redux';
import CookieManager from 'models/CoockieManager/CookieManager';
import AuthTokenCookie from 'models/CoockieManager/AuthTokenCoockie/AuthTokenCookie';

registerLocale('ru', ru);
const renderApp = () => {
  const rootElement = document.getElementById('root') as HTMLElement;
  const root = createRoot(rootElement);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
};

/**
 * Remove auth token and reload the page to trigger login.
 */

const cookieManger = new CookieManager();
const authTokenCookie = new AuthTokenCookie(cookieManger);

const resetAuth = () => {
  authTokenCookie.removeAuthData();
  document.location.reload();
};

const authToken = authTokenCookie.getAuthToken();

if (authToken != null) {
  (async () => {
    try {
      const response: IBaseResponse<IIdentity> = await checkAuthApiV2();
      const {
        result: { payload },
      } = response;

      if (payload) {
        await store.dispatch(userLoginApiV2(payload));
        getActiveInstallation(
          payload?.identity_list_of_applicable_installations?.[0]
        );
        renderApp();
        return;
      }

      // Remove token if it's not possible to check authentication.
      resetAuth();
    } catch (e) {
      // Remove token if it's not possible to check authentication.
      resetAuth();
    }
  })();
} else {
  renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
