import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import { IEvent } from './IEvent';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import IPrimaryKey from 'services/types/IPk';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IEventWrapper } from './IEventWrapper';
import {
  IEventFilters,
  IGetPaginatedEventParams,
} from './IGetPaginatedParamsEvent';
import { IUpdateEvent } from './IUpdateEvent';
import { ICreateEvent } from './ICreateEvent';
import { IEventClosedParams } from './IEventIsClodedParams';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { IGetHistoryEventPayload } from './IEventHistory';

const api = () =>
  new UndercurrentApiClient().methodCreator(UNDERCURRENT_CHILD_MODULES.EVENT);

export const createEventApi = async (
  params: IBaseParams<ICreateEvent>
): Promise<IBaseResponse<IEventWrapper>> => api().create(params);

export const updateEventApi = async (
  params: IBaseParams<IUpdateEvent>
): Promise<IBaseResponse<IEventWrapper>> => await api().update(params);

export const doCloseEventApi = async (
  params: IBaseParams<IEventClosedParams>
): Promise<IBaseResponse<IEvent>> => api().update(params);

export const readEventApi = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<IEventWrapper>> => api().read(params);

export const getPaginatedEventsApi = async (
  params: IBaseParams<IGetPaginatedEventParams | IEventFilters>
): Promise<IBaseResponse<IEvent[]>> => api().getPaginated(params);

export const deleteEventApi = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectEventApi = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const getHistoryEventApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IGetHistoryEventPayload>> => api().getHistory(params);
