import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeLink } from 'services/localPaths';

const useNextWindow = (path: string, from?: string) => {
  const navigate = useNavigate();
  return useCallback(
    (id?: number | string) => {
      const state = { from };

      if (!id) {
        navigate(path, { state });
        return;
      }
      const newPatch = makeLink(path, id);
      navigate(newPatch, { state });
    },
    [from, navigate, path]
  );
};

export default useNextWindow;
