import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import IPrimaryKey from 'services/types/IPk';
import {
  ICounterpartyFilters,
  IGetCounterpartyPaginatedParams,
} from './IGetCounterPartyPaginatedParams';
import {
  ICreateCounterparty,
  IUpdateCounterparty,
} from './ICreateCounterparty';
import { IGetCounterpartyPaginatedPayload } from './IGetCounterPartyPaginatedPayload';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IGetHistoryCounterpartyPayload } from './IHistoryCounterparty';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { ICounterpartyWrapper } from './ICounterpartyWrapper';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.COUNTERPARTY
  );

export const getCounterpartyPaginatedApiV2 = async (
  filterParams: IBaseParams<
    IGetCounterpartyPaginatedParams | ICounterpartyFilters
  >
): Promise<IBaseResponse<IGetCounterpartyPaginatedPayload>> =>
  api().getPaginated(filterParams);

export const readCounterpartyApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<ICounterpartyWrapper>> => api().read(params);

export const createCounterpartyApiV2 = async (
  counterparty: IBaseParams<ICreateCounterparty>
): Promise<IBaseResponse<ICounterpartyWrapper>> => api().create(counterparty);

export const updateCounterpartyApiV2 = async (
  counterparty: IBaseParams<IUpdateCounterparty>
): Promise<IBaseResponse<ICounterpartyWrapper>> => api().update(counterparty);

export const deleteCounterpartyApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey>
) => api().delete(deleteParams);

export const getForSelectCounterpartyApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const getForSelectCounterpartyAffiliateApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => {
  const apiCounterpartyAffiliate = () =>
    new UndercurrentApiClient().methodCreator(
      UNDERCURRENT_CHILD_MODULES.COUNTERPARTY_AFFILIATE
    );

  return apiCounterpartyAffiliate().forSelect(params);
};

export const getHistoryCounterpartyApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IGetHistoryCounterpartyPayload>> =>
  api().getHistory(params);

export const exportCounterpartyApiV2 = async (
  filterParams: IBaseParams<IGetCounterpartyPaginatedParams | ICounterpartyFilters>
): Promise<IBaseResponse<string>> => api().export(filterParams);
