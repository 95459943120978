import { useCallback } from 'react';
import {
  getForSelectCounterpartyAffiliateApiV2,
  getForSelectCounterpartyApiV2,
} from '../../../../services/api/organizationsV2/counterparty/counterpartyApi';
import { SAVE_FILELD_VALUES_ACTION, useSaveValueStore } from 'hooks/useSaveValueStore';
import { useSelector } from 'react-redux';
import { getTaskFieldsValueFromStore } from 'store/task/selectors';

const getAsyncSelectKey = (isSelected: boolean) =>
  `${Number(isSelected)}-keyAsyncSelect`;

const useGetRequestForSelectCounterparty = () => {
  const saveValueInStoreForTask = useSaveValueStore(SAVE_FILELD_VALUES_ACTION.task);

  const { isSelectedAffiliate } = useSelector(getTaskFieldsValueFromStore);

  const handlerAffiliateCheckbox = useCallback(() => {
    saveValueInStoreForTask({
      value: { isSelectedAffiliate: !isSelectedAffiliate },
    });
  }, [isSelectedAffiliate, saveValueInStoreForTask]);
  const getForselectCounterparty = isSelectedAffiliate
    ? getForSelectCounterpartyAffiliateApiV2
    : getForSelectCounterpartyApiV2;

  const keyAsyncSelect = getAsyncSelectKey(!!isSelectedAffiliate);

  return {
    handlerAffiliateCheckbox,
    getForselectCounterparty,
    keyAsyncSelect,
  };
};

export default useGetRequestForSelectCounterparty;
