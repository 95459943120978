import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

const useSuccessHandlerNavigate = (path: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  const wherePath = useMemo(
    () => (location?.state as { from?: string })?.['from'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return useCallback(() => {
    wherePath ? navigate(wherePath) : navigate(path);
  }, [navigate, path, wherePath]);
};

export default useSuccessHandlerNavigate;
