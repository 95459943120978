import * as Yup from 'yup';
import { t } from 'services/utils/translation';

class JobTitle {
  pk?: number;
  job_title_name?: string;
  job_title_description?: string;
  constructor(pk?: number) {
    this.pk = pk;
  }
  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      job_title_name: Yup.string()
        .trim()
        .min(2, t('Введите 2 или более буквы.'))
        .required(t('Укажите название.')),
    });
  };
}

export default JobTitle;
