import { useCallback, useMemo } from 'react';
import { ValueType } from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

const useExternalRequestHandleSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
  const handleSearchExternalRequestPk = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'external_request_pk');
    },
    [onSearchRequest]
  );

  const handleSearchExternalRequestCodeNumber = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'external_request_external_code_number');
    },
    [onSearchRequest]
  );

  const handleSelectAuthorDkArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'external_request_author_dk_array');
    },
    [onSearchRequest]
  );
  const handleSearchAddress = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'external_request_address_of_incident');
    },
    [onSearchRequest]
  );

  const handleSearchDescription = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'external_request_description');
    },
    [onSearchRequest]
  );

  const handleSelectWorkCategoryArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'external_request_work_category_fk_array');
    },
    [onSearchRequest]
  );

  const handleSelectDepartmentArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(
        option,
        'external_request_responsible_department_fk_array'
      );
    },
    [onSearchRequest]
  );

  const handleSelectImplementersArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'external_request_implementer_dk_array');
    },
    [onSearchRequest]
  );

  const handleSelectExecutiveImplementer = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => onSearchRequest(option, 'external_request_executive_implementer_dk'),
    [onSearchRequest]
  );

  const handleSelectSupervisor = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => onSearchRequest(option, 'external_request_supervisor_dk'),
    [onSearchRequest]
  );

  const handleSelectEvent = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest([], 'external_request_billing_account_fk_array');
      onSearchRequest(option, 'external_request_event_fk_array');
    },
    [onSearchRequest]
  );

  const handleSelectBillingAccount = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest([], 'external_request_event_fk_array');
      onSearchRequest(option, 'external_request_billing_account_fk_array');
    },
    [onSearchRequest]
  );

  const handleSearchCounterpartyAffiliate = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => onSearchRequest(option, 'external_request_affiliate_fk_array'),
    [onSearchRequest]
  );

  const handleSelectStatus = useCallback(
    (
      target: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(target, 'external_request_status_fk_array');
    },
    [onSearchRequest]
  );

  return useMemo(
    () => ({
      handleSearchExternalRequestPk,
      handleSearchExternalRequestCodeNumber,
      handleSelectAuthorDkArray,
      handleSearchDescription,
      handleSearchAddress,
      handleSelectBillingAccount,
      handleSelectEvent,
      handleSelectWorkCategoryArray,
      handleSelectDepartmentArray,
      handleSearchCounterpartyAffiliate,
      handleSelectImplementersArray,
      handleSelectExecutiveImplementer,
      handleSelectSupervisor,
      handleSelectStatus,
    }),
    [
      handleSearchAddress,
      handleSearchCounterpartyAffiliate,
      handleSearchDescription,
      handleSearchExternalRequestCodeNumber,
      handleSearchExternalRequestPk,
      handleSelectAuthorDkArray,
      handleSelectBillingAccount,
      handleSelectDepartmentArray,
      handleSelectEvent,
      handleSelectExecutiveImplementer,
      handleSelectImplementersArray,
      handleSelectStatus,
      handleSelectSupervisor,
      handleSelectWorkCategoryArray,
    ]
  );
};

export default useExternalRequestHandleSearch;
