import { toast } from 'react-toastify';
import { t } from '../services/utils/translation';
import { useCallback } from 'react';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from '../services/utils/catchAndRegError/catchAndRegError';

interface IProps<Params> {
  fetchApi: (params: Params) => Promise<any>;
  errorMessage?: string;
  successMessage?: string;
  successHandler?: () => void;
  errorHandler?: Function;
}

export function usePostV2<Params, Response extends { [x: string]: any }>(
  props: IProps<Params>
) {
  const {
    fetchApi,
    errorMessage = 'Не удалось загрузить данные',
    successMessage = 'Данные отправлены успешно',
    successHandler,
    errorHandler,
  } = props;

  return useCallback(
    async (paramsInCallback: Params, newSuccessMessage?: string) => {
      const actualSuccessMessage = newSuccessMessage ?? successMessage;
      const fullErrorMessage = `${errorMessage}. Обратитесь, пожалуйста, к администратору`;
      try {
        const response: Response = await fetchApi(paramsInCallback);
        if (response?.['error']) {
          catchErrorsIfServerStatusFalse(errorMessage, response?.['error']);
          errorHandler && errorHandler();
        }
        toast(t(actualSuccessMessage));
        successHandler && successHandler();
      } catch (error) {
        catchErrorFromFetch(fullErrorMessage, error);
        errorHandler && errorHandler();
      }
    },
    [errorHandler, errorMessage, fetchApi, successHandler, successMessage]
  );
}
