import IBaseGetListParams from '../../interfacesApi/IBaseGetListParams';
import IClientSelectOptionV2 from '../../interfacesApi/IClientSelectOptionV2';

export enum EmployeeRequestSortCriteria {
  Default = '',
  PkAsc = 'employee_pk',
  PkDesc = '-employee_pk',
  PhoneAsc = 'employee_phone_number',
  PhoneDesc = '-employee_phone_number',
  EmailAsc = 'employee_email',
  EmailDesc = '-employee_email',
  PersonalCodeNumberAsc = 'employee_personnel_code_number',
  PersonalCodeNumberDesc = '-employee_personnel_code_number',
  LastNameAsc = 'employee_last_name',
  LastNameDesc = '-employee_last_name',
}

export interface IEmployeeFilters
  extends IBaseGetListParams<EmployeeRequestSortCriteria> {
  employee_pk?: number;
  // employee_pk_array?: IClientSelectOptionV2[];
  employee_last_name?: string;

  employee_fulltext_search?: string;
  employee_job_title_fk?: number;
  employee_job_title_fk_array?: IClientSelectOptionV2[];
  employee_supervisor_fk?: number;
  employee_supervisor_fk_array?: IClientSelectOptionV2[];
  employee_identity_dk?: IClientSelectOptionV2;
  employee_is_driver?: boolean;
  employee_phone_number?: string;
  employee_email?: string;
  employee_personnel_code_number?: string;
  employee_is_active?: boolean;
}
export interface IGetPaginatedParamsEmployee
  extends IBaseGetListParams<EmployeeRequestSortCriteria> {
  employee_pk?: number;
  employee_pk_array?: number[];
  employee_fulltext_search?: string;
  employee_job_title_fk?: number;
  employee_job_title_fk_array?: number[];
  employee_supervisor_fk?: number;
  employee_supervisor_fk_array?: number[];
  employee_identity_dk?: number;
  employee_is_driver?: boolean;
  employee_last_name?: string;
  employee_first_name?: string;
  employee_middle_name?: string;
  employee_phone_number?: number;
  employee_email?: string;
  employee_personnel_code_number?: string;
  employee_is_active?: boolean;
}
