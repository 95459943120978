import IFileFormValues from '../IFileFormValues';

const findFileIndex = (
  file: IFileFormValues,
  files: Array<IFileFormValues>
): number =>
  files.findIndex(
    (uploadedFile: IFileFormValues) => file.file.name === uploadedFile.file.name
  );

export default findFileIndex;
