import { UI_TITLE } from '../localLocalization/UITitle';
import { t } from './translation';

const YES_TEXT = t('Да');
const NO_TEXT = t('Нет');

const IS_NATURAL_PERSON = t('Физ. лицо');
const IS_NOT_NATURAL_PERSON = t('Юр. лицо');

export const extractGetLeftValueIfTrue =
  (leftString: string, rightString: string) => (isLeftTrue: boolean) =>
    isLeftTrue ? leftString : rightString;

export const getHintForDropdownDelete = extractGetLeftValueIfTrue(
  UI_TITLE.HINT_FOR_DELETE_PARENT,
  ''
);

export const getDropDownEditTitle = extractGetLeftValueIfTrue(
  UI_TITLE.EDIT,
  UI_TITLE.VIEWING
);
export const textFromBoolean = extractGetLeftValueIfTrue(YES_TEXT, NO_TEXT);
export const textFromIsNaturalPerson = extractGetLeftValueIfTrue(
  IS_NATURAL_PERSON,
  IS_NOT_NATURAL_PERSON
);
