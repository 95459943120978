import store from 'store';
import config from 'config.json';

interface IHeaders {
  [x: string]: string;
}

export class ApiHeaderBuilder {
  private readonly _apiHeader: IHeaders;

  // need to specify the default arguments exactly
  constructor(incomingHeaders?: HeadersInit) {
    this._apiHeader = { ...incomingHeaders } as IHeaders;
    this._apiHeader['Cache-Control'] = 'no-cache';
    this._apiHeader['Accept'] = 'application/json';
    this._apiHeader['Strict-Transport-Security'] =
      'max-age=31536000; includeSubDomains';
  }

  contentTypeCustom(contentType: string): ApiHeaderBuilder {
    this._apiHeader['Content-Type'] = contentType;
    return this;
  }

  strictTransportSecurityCustom(
    strictTransportSecurity: string
  ): ApiHeaderBuilder {
    this._apiHeader['Strict-Transport-Security'] = strictTransportSecurity;
    return this;
  }

  acceptCustom(accept: string): ApiHeaderBuilder {
    this._apiHeader['Accept'] = accept;
    return this;
  }

  cacheControlCustom(cacheControl?: string): ApiHeaderBuilder {
    if (cacheControl) {
      this._apiHeader['Cache-Control'] = cacheControl;
      return this;
    }
    return this;
  }

  token(hasAccessToken: boolean): ApiHeaderBuilder {
    if (!hasAccessToken) {
      return this;
    }
    this._apiHeader[config.accessTokenHeaderName] = store.getState().auth.accessToken;
    return this;
  }

  build(): HeadersInit {
    return this._apiHeader;
  }
}

export const createHeadersForGet = (
  hasToken: boolean,
  incomingHeaders?: HeadersInit
) => new ApiHeaderBuilder(incomingHeaders).token(hasToken).build();

export const createHeadersForJson = (
  hasToken: boolean,
  incomingHeaders?: HeadersInit
) =>
  new ApiHeaderBuilder(incomingHeaders)
    .contentTypeCustom('application/json')
    .strictTransportSecurityCustom('max-age=31536000; includeSubDomains')
    .token(hasToken)
    .build();
