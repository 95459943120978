 import React, { FC } from 'react';
import { Input } from 'reactstrap';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';
 
type TypeProps = {
  id: string;
  value: string | number;
  hasError: boolean;
};

const HiddenField: FC<TypeProps> = ({ id, value, hasError }) => {
  return (
    <ErrorWrapperField id={id} >
      <Input
        type="hidden"
        name={id}
        value={value}
        className={hasError ? 'is-invalid' : ''}
      />
     </ErrorWrapperField>
  );
};

export default HiddenField;
