import { FC, useMemo } from 'react';
import config from '../../../config.json';
import { IUseGetList, useGetList } from '../../../hooks/useGetList';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { t } from 'services/utils/translation';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import { useColumnFilter } from '../../../hooks/useColumnFilter';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import LoaderFetch from '../../../components/layouts/LoaderFetch/LoaderFetch';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import { Pager } from '../../../components/tables/Pager';
import useModalV2 from 'hooks/useModalV2';
import { IModal } from 'hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import SubheaderButtonExport from 'components/tables/SubHeaderButtonExport/SubHeaderButtonExport';
import IGetPaginatedParamsJobTitle from 'services/api/staff/job-title/IGetPaginatedParamsJobTitle';
import { IJobTitle } from 'services/api/staff/job-title/IJobTitle';
import { deleteJobTitleApiV2 } from '../../../services/api/staff/job-title/jobTitleApiV2';
import {
  getPaginatedJobTitleApiV2,
  exportJobTitleApiV2,
} from '../../../services/api/staff/job-title/jobTitleApiV2';
import JobTitleForm from 'components/staff/job-title/JobTitleForm/JobTitleForm';
import { SortCriteriaJobTitle } from '../../../services/api/staff/job-title/IGetPaginatedParamsJobTitle';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { UI_TITLE } from '../../../services/localLocalization/UITitle';
import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';
import { targetAccessPolicyJobTitle } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyPersonnel';

const deleteMessageError = t('Не удалось удалить название должности');

const CREATE_BUTTON_TITLE = t('Добавить должность');
const MODAL_EDIT_TITLE = t('Редактировать должность');
const MODAL_CREATE_TITLE = t('Создать должность');
const MODAL_DELETE_TITLE = t('Удалить должность');
const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);

const COLUMN_LABELS = {
  PK: '№',
  NAME: 'Название',
  DESCRIPTION: 'Описание',
};

const filters = [
  { name: 'job_title_pk', label: COLUMN_LABELS.PK },
  {
    name: 'job_title_name',
    label: COLUMN_LABELS.NAME,
  },
  {
    name: 'job_title_description',
    label: COLUMN_LABELS.DESCRIPTION,
  },
] as const;
const columnVisibilityInitial = {
  job_title_pk: true,
  job_title_name: true,
  job_title_description: true,
};

const JobTitlePage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial);

  const [
    haveAccessToUpdate,
    haveAccessToCreate,
    haveAccessToDelete,
    haveAccessToExport,
  ] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyJobTitle,
    targetAction: ['U', 'C', 'D', 'E'],
  });

  const initialParams: IGetPaginatedParamsJobTitle = useMemo(
    () => ({
      skip: 0,
      length: config.pageLength,
    }),
    []
  );

  const {
    data: jobTitleList,
    isLoading,
    refreshListData,
    total,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    params: {
      skip,
      length,
      job_title_pk: filterPk,
      job_title_name: filterName,
      job_title_description: filterDescription,
    },
    params,
  }: IUseGetList<IJobTitle[], IGetPaginatedParamsJobTitle> = useGetList({
    getDataApi: getPaginatedJobTitleApiV2,
    initialParams,
  });

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetJobTitle },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteJobTitleApiV2,
    entityIdKey: 'pk',
  });

  return (
    <MainLayout>
      <Subheader>
        <div style={{ display: 'flex' }} className="mr-5">
          <SubheaderButtonExport
            params={params}
            getExportLinkApi={exportJobTitleApiV2}
            disabled={isLoading}
            haveAccessToExport={haveAccessToExport}
          />
        </div>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <AddIcon className="mr-2 text-white" /> {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={`id pr-0 ${
                  !visibility.job_title_pk ? 'd-none' : ''
                }`}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaJobTitle.NameAsc}
                  title={COLUMN_LABELS.PK}
                  onSort={onSortClick}
                  onSearch={(searchString: string) =>
                    onSearchRequest(searchString, 'job_title_pk')
                  }
                  defaultValue={filterPk ? filterPk + '' : ''}
                  isOnlyNumbers
                />
              </th>

              <th
                scope="col"
                className={`p-0 ${!visibility.job_title_name ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaJobTitle.NameAsc}
                  title={COLUMN_LABELS.NAME}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'job_title_name');
                  }}
                  onSort={onSortClick}
                  defaultValue={filterName}
                />
              </th>

              <th
                scope="col"
                className={`p-0 ${!visibility.job_title_name ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaJobTitle.DescriptionAsc}
                  title={COLUMN_LABELS.DESCRIPTION}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'job_title_description');
                  }}
                  onSort={onSortClick}
                  defaultValue={filterDescription}
                />
              </th>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {jobTitleList &&
                jobTitleList.map((jobTitle: IJobTitle) => {
                  const {
                    job_title_pk: pk,
                    job_title_name: name,
                    job_title_description: description,
                  } = jobTitle;
                  return (
                    <tr key={pk}>
                      <td>
                        <span>{pk}</span>
                      </td>
                      <td>
                        <span>{name}</span>
                      </td>
                      <td>
                        <span>{description}</span>
                      </td>
                      <td align="right">
                        <ActionsDropdown>
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={UI_TITLE.EDIT}
                              onClick={openAddEditForm.bind(null, {
                                pk: pk,
                                rendition: name,
                              })}
                              icon={<EditIcon className="text-primary" />}
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                rendition: name,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        {isLoading && <LoaderFetch />}
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />
        <ModalWrapper
          headerLabel={getModalTitle(!!targetJobTitle?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <JobTitleForm
            successHandler={successHandler}
            pk={targetJobTitle?.pk}
          />
        </ModalWrapper>

        {targetJobTitle?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetJobTitle?.pk}
            entityName={targetJobTitle?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default JobTitlePage;
