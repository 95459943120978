import { TValueOf } from '../TSUtils/TValueOf';
import { toTObject } from '../TSUtils/toTObject';
const RIGHT_PART_KEY_RENULL = '_list_of_attrs_to_renull';

const PARENT_MODEL = {
  externalRequest: 'external_request',
  internalTask: 'internal_task',
  billingAccount: 'billing_account',
  employee: 'employee',
} as const;

const getRenullKey = <T extends TValueOf<typeof PARENT_MODEL>>(moduleName: T) =>
  `${moduleName}${RIGHT_PART_KEY_RENULL}` as const;

//TODO move model names to separate file
export const RENULL_KEYS = toTObject({
  EXTERNAL_REQUEST: getRenullKey(PARENT_MODEL.externalRequest),
  INTERNAL_TASK: getRenullKey(PARENT_MODEL.internalTask),
  BILLING_ACCOUNT: getRenullKey(PARENT_MODEL.billingAccount),
  EMPLOYEE: getRenullKey(PARENT_MODEL.employee),
});

type Params<T> = {
  [K in keyof T]: T[K];
};

export const conditionForSetRenull = <T,>(
  entityCondition: boolean,
  value?: T
) =>
  (entityCondition && value === null) ||
  (Array.isArray(value) && value.length === 0) ||
  value === undefined;

export const getParametersRenull =
  (renullKey: string) =>
  <FormValues,>(
    params: Params<FormValues>,
    enabledParams: string[],
    entityCondition: boolean = true
  ) => {
    const renullParams: { [x: string]: string[] } = { [renullKey]: [] };
    for (let key in params) {
      if (
        params.hasOwnProperty(key) &&
        conditionForSetRenull(entityCondition, params[key]) &&
        enabledParams.includes(key)
      ) {
        renullParams[renullKey].push(key);
      }
    }
    return renullParams;
  };

export const getParametersRenullForExternalRequest = getParametersRenull(
  RENULL_KEYS.EXTERNAL_REQUEST
);

export const getParametersRenullForTask = getParametersRenull(
  RENULL_KEYS.INTERNAL_TASK
);

export const getParametersRenullForEmployee = getParametersRenull(
  RENULL_KEYS.EMPLOYEE
);
