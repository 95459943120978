import { IFileBucket } from 'services/api/documents/file-bucket/IGetResponseFileBuckets';
import { IBooking } from 'services/api/vehicle/booking/IBooking';
import { AttachmentBookingService } from './AttachmentBookingService';
import { AttachmentDocumentsService } from './AttachmentDocumentsService';
import { AttachmentInvoiceService } from './AttachmentInvoiceService';
import { IInvoice } from 'services/api/warehouse/IGetInvoicesResponse';

interface IAttachmentsFromServer {
  bookings?: IBooking[];
  documents?: IFileBucket[];
  invoices?: IInvoice[];
}

export class AttachmentsService {
  public numberOfDocuments: number | null;
  public vehiclesRegistrationPlate: string[] | null;
  public numberOfInvoices: number | null;

  constructor({ bookings, documents, invoices }: IAttachmentsFromServer) {
    const attachmentBookingService = new AttachmentBookingService();
    const attachmentDocumentService = new AttachmentDocumentsService();
    const attachmentInvoiceService = new AttachmentInvoiceService();

    this.vehiclesRegistrationPlate =
      attachmentBookingService.getVehiclesPlateFromBookings(bookings);
    this.numberOfDocuments =
      attachmentDocumentService.getNumberOfDocuments(documents);
    this.numberOfInvoices =
      attachmentInvoiceService.getNumberOfInvoicesFromExternalRequestDetails(
        invoices
      );
  }
}
