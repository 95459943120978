import IGetCommentsPayloadV2, {
  CommentsType,
} from 'services/interfaces/IGetCommentsPayload';
import { IPersonV2 } from 'services/interfaces/IPersonV2';
import makeLineBreakText from 'services/utils/makeLineBreakText/makeLineBreakText';
import { extractFirstAndLastNameFromPerson } from 'services/utils/misc/misc';
import { t } from 'services/utils/translation';
import { IClientComment } from '../../types/IClientComment';

const UNKNOWN_AUTHOR_TEXT = t('Отправитель неизвестен');

/**
 *Make a client comment
 *We know that the entity must be of the same format but with different keys
 */
const toClientComments = (
  commentsListPayload: IGetCommentsPayloadV2,
  currentEmployeePk?: number
): IClientComment[] => {
  const keyForListComments = Object.keys(commentsListPayload)[0];
  return commentsListPayload[keyForListComments].map(
    (comment: CommentsType) => {
      const keyValues = Object.values(comment);
      const [timeAdded, , commentText, author] = [
        keyValues[0], // []..comment_datetime_added
        keyValues[1], //..comment_author_dk
        keyValues[2], //..comment_text
        keyValues[3], //..comment_author
      ] as [string, string, string, IPersonV2];

      const isMyComment = currentEmployeePk === author.id;

      return {
        authorName: author
          ? extractFirstAndLastNameFromPerson(author)
          : UNKNOWN_AUTHOR_TEXT,
        timeAdded,
        commentText: makeLineBreakText(commentText, isMyComment),
        isMyComment,
      };
    }
  );
};

export default toClientComments;
