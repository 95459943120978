import classNames from 'clsx';
import { useField } from 'formik';
import React, { FC, memo, useState, FocusEventHandler } from 'react';
import { OptionsType, ValueType, ActionMeta } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import formatOptionLabelHighLighter from '../utils/formatOptionLabelHighLighter';
import shouldLoadMore from '../utils/shouldLoadMore';
import { IForSelectResponseV2 } from '../DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';
import { getValueForSelect } from 'services/utils/selects/getValueForSelect/getValueForSelect';
import useLoadOptionsInForSelect from 'hooks/useLoadOptionsInForSelect/useLoadOptionsInForSelect';

export const setFixedOptions = (values: OptionsType<IClientSelectOptionV2>) =>
  values.map((option: IClientSelectOptionV2) => ({ ...option, isFixed: true }));

type TypeProps = {
  id: string;
  placeholder?: string;
  className: string;
  selectHandler: (
    params: IBaseParams<IForSelectRequestV2>
  ) => Promise<IBaseResponse<IForSelectResponseV2>>;
  isMulti?: boolean;
  options?: IClientSelectOptionV2 | IClientSelectOptionV2[] | null | undefined;
  'aria-labelledby': string;
  disabled?: boolean;
  onChange?: Function;
  defaultValue?: string;
  isClearable?: boolean;
  onBlur?: FocusEventHandler;
};

const DynamicSelectFormikPaginatedFieldFixed: FC<TypeProps> = (props) => {
  const {
    id,
    placeholder,
    selectHandler,
    isMulti = false,
    options,
    className,
    'aria-labelledby': ariaLabelledBy,
    disabled = false,
    defaultValue,
    isClearable = true,
    onBlur,
  } = props;

  let optionsArray: IClientSelectOptionV2[] = [];
  if (options == null) {
    optionsArray = [];
  } else if (!Array.isArray(options)) {
    optionsArray = [options];
  } else {
    optionsArray = options;
  }

  const onChangeHandler = props.onChange;

  const [, , helpers] = useField<IClientSelectOptionV2[] | null>(id);
  const [loadedOptions, setLoadedOptions] = useState<IClientSelectOptionV2[]>(
    setFixedOptions(optionsArray)
  );

  const loadOptions = useLoadOptionsInForSelect({
    selectHandler,
    setLoadedOptions,
    loadedOptions,
  });
  const onChange = (
    options: ValueType<IClientSelectOptionV2, true>,
    actionMeta: ActionMeta<IClientSelectOptionV2>
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        options = optionsArray.filter((v) => v.isFixed);

        break;
    }

    helpers.setValue(options as IClientSelectOptionV2[]);
    if (onChangeHandler != null) {
      onChangeHandler(options);
    }
  };

  const value = getValueForSelect(
    loadedOptions,
    optionsArray,
    isMulti,
    defaultValue
  );

  const selectStyles = {
    menu: (styles: any) => ({
      ...styles,
      zIndex: 999,
    }),
    multiValueLabel: (styles: any, state: any) =>
      state.data.isFixed
        ? { ...styles, backgroundColor: 'ACB5C4' }
        : {
            ...styles,
            background: '#D6F1F7',
          },
    multiValueRemove: (styles: any, state: any) =>
      state.data.isFixed
        ? { ...styles, display: 'none' }
        : { ...styles, background: '#D6F1F7' },
    control: (styles: any, state: any) => ({
      ...styles,
      '&:hover': { borderColor: '#ACB5C4' },
      border: state.isFocused && '1px solid #49A6C8',
      boxShadow: state.isFocused ? '1' : 'none',
    }),
    option: (styles: any) => ({
      ...styles,
      '&:hover': { background: '#D6F1F7' },
    }),
  };

  return (
    <ErrorWrapperField id={id}>
      <AsyncPaginate
        cacheOptions
        shouldLoadMore={shouldLoadMore}
        loadOptions={loadOptions}
        id={id}
        name={id}
        aria-labelledby={ariaLabelledBy}
        placeholder={placeholder}
        className={classNames('f-custom-select', className)}
        isMulti={isMulti}
        onChange={onChange}
        value={value}
        additional={{
          page: 1,
        }}
        styles={selectStyles}
        isDisabled={disabled}
        debounceTimeout={300}
        isClearable={isClearable}
        onBlur={onBlur}
        formatOptionLabel={formatOptionLabelHighLighter}
      />
    </ErrorWrapperField>
  );
};

export default memo(DynamicSelectFormikPaginatedFieldFixed);
