import * as Sentry from '@sentry/react';

import { FC, PropsWithChildren } from 'react';
import ErrorFallback from '../ErrorFallback/ErrorFallback';

type PropsType = {};

const WithErrorBoundary: FC<PropsWithChildren<PropsType>> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default WithErrorBoundary;
