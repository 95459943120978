import {SessionStorage} from 'services/SessionStorage';
import Otp from '../../../../models/Otp';
import { STEPPER_FORM_DATA_KEY } from "../../../../services/constants/STEPPER_FORM_DATA_KEY";

const getFormDataStepper = () => {
  const sessionStorage = new SessionStorage();
  const otp = sessionStorage.getValue<Otp>(STEPPER_FORM_DATA_KEY);
  return otp  ? otp : new Otp();
};

export default getFormDataStepper;
