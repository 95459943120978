import { useFormikContext } from 'formik';
import IFileFormValues from '../IFileFormValues';
import { IDocumentFormValues } from '../IDocumentFormValues';
import useSetUploadedFilesFile from './useSetUploadedFilesFile';

const useDocumentReturnDeletedFile = () => {
  const {
    setFieldValue,
    values: {
      string_of_comma_separted_file_names_to_delete: stringNamesToDelete,
    },
  } = useFormikContext<IDocumentFormValues>();
  const setUploadedFilesFile = useSetUploadedFilesFile(true);

  return async (file: IFileFormValues) => {
    setUploadedFilesFile(file);
    const newDeletedString = stringNamesToDelete?.replace(file.file.name, '');
   await setFieldValue(
      'string_of_comma_separted_file_names_to_delete',
      newDeletedString
    );
  };
};

export default useDocumentReturnDeletedFile;
