import { useState, ReactElement, memo } from 'react';
import { Collapse } from 'reactstrap';
import styles from './index.module.scss';
import TurningArrow from 'components/misc/TurningArrow/TurningArrow';
import {
  ChildrenEntity,
  toUniformChild,
} from '../../../../services/utils/toUniformListChild/toUniformListChild';
import LinkWitchBlockNavigation from 'components/controls/FormHeader/FormHeaderLink/FormHeaderLinkWitchBlockNavigation/FormHeaderLinkWitchBlockNavigation';
import FormHeaderTitle from '../../FormHeader/FormHeaderTitle/FormHeaderTitle';

type PropsType<T> = {
  listOfChildren: ChildrenEntity<T>[];
  title: string;
  patch: string;
  children: ReactElement;
};

const ListLinkSubHeaderWrapper = <T,>({
  listOfChildren,
  title,
  patch,
  children,
}: PropsType<T>) => {
  const [openList, setOpenList] = useState<boolean>(false);
  const toggleList = () => setOpenList(!openList);

  const firstChild = toUniformChild(listOfChildren[0]);
   const total = listOfChildren.length;
  const showCounter = total > 1;

  return (
    <div className="px-4 py-2 py-sm-2 mr-2 d-flex flex-column justify-content-center align-items-center">
      <div className={styles['title-container']}>
        <FormHeaderTitle title={title} className="mb-2" />
        {showCounter && <span className={styles['counter']}>{total}</span>}
      </div>
      <div className=" d-flex flex-revert align-items-start">
        <div>
          <LinkWitchBlockNavigation
            path={patch}
            pk={firstChild.pk}
            className={styles.tittleChildrenLink}
            tooltipDescription={firstChild.description}
          >
            <span>{firstChild.pk}</span>
          </LinkWitchBlockNavigation>
          <Collapse isOpen={openList}>{children}</Collapse>
        </div>
        {listOfChildren.length > 1 && (
          <TurningArrow
            id={firstChild.pk}
            onClick={toggleList}
            turnUp={openList}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ListLinkSubHeaderWrapper);
