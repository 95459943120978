import useGetTotalDocuments from 'hooks/useGetTotalDocuments/useGetTotalDocuments';
import { t } from 'services/utils/translation';
import { useMemo } from 'react';
import { TAB_QUERY_KEY } from 'services/pathConstants';
import { parentUIElement } from 'services/constants/parentUIElement';
import useGetTabsPatch from 'hooks/useGetTabsPatchs';

interface IUseGetTotalDocumentsProps <Id = number> {
  parentUIElement: parentUIElement;
  id: Id;
}

const useGetCommonTabs = <Id extends number | string>({
  parentUIElement,
  id,
}: IUseGetTotalDocumentsProps<Id>) => {
  const { totalDocument, isLoadingDocument, setRefreshDocuments } =
    useGetTotalDocuments({ id, parentUIElement });
  const tabKey = TAB_QUERY_KEY[parentUIElement];

  const { editPatch, historyPatch, documentsPatch } = useGetTabsPatch(tabKey);

  const commonTabs = useMemo(
    () => [
      { name: t('Редактирование'), patch: editPatch },
      { name: t('История'), patch: historyPatch },
      {
        name: t('Документы'),
        patch: documentsPatch,
        recordsTotal: totalDocument,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalDocument, id]
  );
  return { setRefreshDocuments, isLoadingDocument, commonTabs };
};

export default useGetCommonTabs;
