import { IResponseNotifications } from './IresponseGetNotifications';
import NotificationsApiClient from './../NotificationApiClientBase';
import { IMarkAsReactedParams } from './IMarkAsReactedRequest';
import createTimestamp from '../../utils/createTimestamp/createTimestamp';
import { IRequestGetNotifications } from './IRequestGetNotifications';

export async function getNotificationsApi(
  params: IRequestGetNotifications
): Promise<IResponseNotifications> {
  const api = new NotificationsApiClient();
  const { length, skip } = params;
  const request = {
    params: params?.params,
    id: createTimestamp(),
  };
  return await api.postJson(
    `notification/read/paginated/${skip}/${length}`,
    request
  );
}

export async function markAsReactedToApi(params: IMarkAsReactedParams | null) {
  const request = { params, id: createTimestamp() };
  const api = new NotificationsApiClient();
  return await api.postJson(
    `notification/mark-multiple-as-reacted-to`,
    request
  );
}
