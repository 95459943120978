import BillingAccount from 'models/BillingAccount';
import { IBillingAccount } from 'services/api/organizationsV2/billing-account/IBillingAccount';
import React, { FC } from 'react';
import useRead from '../../../../hooks/useRead';
import { readBillingAccountV2 } from '../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import BillingAccountForm from 'components/organizations/billing-account/BillingAccountForm/BillingAccountForm';
import Subheader from 'components/layouts/Subheader/Subheader';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import { createClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyBillingAccount } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { extractClientSelectOptionsFromCounterpartyInEntity } from 'services/utils/bilingAccountUtils/billingAccountUtils';
import SubHeaderEditEntity from 'components/controls/FormHeader/FormHeaderEditEntity/SubHeaderEditEntity';
import deleteUnnecessaryParameters from '../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { formTitleBillingAccount } from '../../../../services/constants/FormTitles/FormTitles';
import { useParams } from 'react-router-dom';
import useGoBackNavigate from 'hooks/useGoBackNavigate/useGoBackNavigate';
import useSetAndGetExternalRequestAccessPolicy from 'pages/external-request/external-requests/ExternalRequestPage/hooks/useSetExternalRequestAccessPolicy';

const toBillingAccount = ({
  billing_account: payload,
}: {
  billing_account: IBillingAccount;
}): BillingAccount => {
  return {
    ...deleteUnnecessaryParameters(payload, [
      'billing_account_list_of_edits',
      'billing_account_editor',
      'billing_account_author',
      'billing_account_list_of_associated_contracts',
      'billing_account_datetime_edited',
      'billing_account_datetime_added',
    ]),
    billing_account_type: createClientSelectOptionV2<number>(
      payload.billing_account_type.billing_account_type_pk,
      payload.billing_account_type.billing_account_type_rendition
    ),
    pk: payload.billing_account_pk,
    counterparty: extractClientSelectOptionsFromCounterpartyInEntity(
      payload.billing_account_counterparty
    ),
  };
};

type BillingAccountPagePropTypes = {
  id?: string;
};

const BillingAccountEditPage: FC<BillingAccountPagePropTypes> = () => {
  const { id } = useParams<{ id: string }>();

  const pk = id ? Number(id) : undefined;

  const [haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['U'],
  });

  const { billingAccountHaveAccessToRead } =
    useSetAndGetExternalRequestAccessPolicy();

  const isViewOnly = !haveAccessToUpdate;

  const successHandler = useGoBackNavigate();

  const {
    data: initialValue,
    isLoading: isLoadingBillingAccount,
    refreshData,
  } = useRead({
    getDataApi: readBillingAccountV2,
    condition: !!pk && billingAccountHaveAccessToRead,
    params: { pk } as { pk: number },
    convertData: toBillingAccount,
    initialData: new BillingAccount(),
  });

  const subHeaderTitle = formTitleBillingAccount.getFormTitle(
    initialValue.billing_account_code_number
  );

  return (
    <MainLayout>
      <Subheader />
      <SubHeaderEditEntity title={subHeaderTitle} />
      <BillingAccountForm
        successHandler={successHandler}
        pk={pk}
        initialValue={initialValue}
        isLoading={isLoadingBillingAccount}
        isViewOnly={isViewOnly}
        refreshData={refreshData}
      />
    </MainLayout>
  );
};

export default BillingAccountEditPage;
