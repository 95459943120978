import React, { memo } from 'react';
import { ReactComponent as NotificationIcon } from '../../../img/header/notification.svg';
import CounterUI from '../CounterUI/CounterUI';
import styles from './index.module.scss';

import { useSelector } from 'react-redux';
import { getNumberOfNotificationsFromStore } from 'store/notification/selectors';

const NotificationUI = () => {
  const { total } = useSelector(getNumberOfNotificationsFromStore);
  return (
    <>
      <NotificationIcon />
      {total > 0 && (
        <CounterUI count={total} className={styles['notification-counter']} />
      )}
    </>
  );
};
// NotificationUI.whyDidYouRender = true;
export default memo(NotificationUI);
