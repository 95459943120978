import { useMemo, useCallback } from 'react';
import { IGetHistoryCounterpartyPayload } from 'services/api/organizationsV2/counterparty/IHistoryCounterparty';
import Counterparty from 'models/Counterparty';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { ICounterparty } from 'services/api/organizationsV2/counterparty/ICounterparty';

const useUtilsCounterpartyEdit = () => {
  const convertHistoryData = (data: IGetHistoryCounterpartyPayload) => ({
    rows: data?.counterparty_list_of_edits,
    creationInfo: {
      counterparty_datetime_added: data?.counterparty_datetime_added,
      counterparty_author_dk: data?.counterparty_author_dk,
      counterparty_author: data?.counterparty_author,
    },
  });

  const toCounterparty = useCallback(
    ({ counterparty }: { counterparty: ICounterparty }): Counterparty => {
      const newCounterparty = deleteUnnecessaryParameters(counterparty, [
        'counterparty_list_of_edits',
        'counterparty_author',
        'counterparty_editor',
      ]);

      return {
        ...newCounterparty,
        pk: newCounterparty.counterparty_pk,
        counterparty_phone_number:
          newCounterparty.counterparty_phone_number + '',
      };
    },
    []
  );

  return useMemo(
    () => ({
      toCounterparty,
      convertHistoryData,
    }),
    [toCounterparty]
  );
};

export default useUtilsCounterpartyEdit;
