import { parseISO } from 'date-fns';
import { IClientComment } from '../../types/IClientComment';
import { IClientCommentsGroup } from '../../types/IClientCommentsGroup';
import { formatDateTimeLocaleDayAndMonth } from 'services/utils/dateHelper/dateHelper';

const toGroupByDateComments = (
  comments: IClientComment[]
): IClientCommentsGroup[] => {
  const groups: IClientCommentsGroup[] = [];

  comments.forEach((comment) => {
    const dateObj = parseISO(comment.timeAdded);
    const currentYear = new Date().getFullYear();
    const year = dateObj.getFullYear();

    let newDate = formatDateTimeLocaleDayAndMonth(dateObj).toUpperCase();

    if (currentYear !== year) {
      newDate += ` ${year}`;
    }

    const groupIndex = groups.findIndex((group) => group.date === newDate);

    if (groupIndex === -1) {
      groups.push({ date: newDate, comments: [comment] });
    } else {
      groups[groupIndex].comments.push(comment);
    }
  });

  return groups;
};

export default toGroupByDateComments;
