export const extractCreateId =
  <T,>(prefix?: string) =>
  (id?: string | number) => {
    if (id) {
      return prefix ? `${prefix}${id}` : `${id}`;
    } else {
      return null;
    }
  };

export const createTooltipId = extractCreateId('tooltip-');

export const createId = extractCreateId();
