import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import { t } from 'services/utils/translation';
import { clearExport, setExportError } from 'store/export/actions';
import IBaseParams from '../services/api/interfacesApi/IBaseParams';
import { getStatusLoadingExportLinkFromStore } from '../store/export/selectors';
import { usePostV3 } from './usePostV3';
import { setExportLinkLoading, setToastId } from '../store/export/actions';

const WARNING_MESSAGE: string = t('Не удалось загрузить ссылку');
const LOADING_TEXT = t('Получаем ссылку для скачивания файла...');

type PropsType<Params> = {
  getExportLinkApi: (
    params: IBaseParams<Params>
  ) => Promise<IBaseResponse<string>>;
  convertParams?: any; //TODO fix any
};

const useExportApi = <Params,>(props: PropsType<Params>) => {
  const { getExportLinkApi, convertParams } = props;

  const isLoadingExportLink = useSelector(getStatusLoadingExportLinkFromStore);

  const toastId = useRef(null) as any;
  const dispatch = useDispatch();

  const doExport = usePostV3<string, Params>({
    fetchApi: getExportLinkApi,
    viewToast: false,
    errorHandler: () => {
      toast.update(toastId.current, {
        render: WARNING_MESSAGE,
        autoClose: 5000,
        type: 'warning',
        closeButton: true,
      });
      dispatch(setExportError(true));
    },
  });

  const notify = () => {
    const toastContent = (
      <div className="d-flex justify-content-between  align-items-center">
        <Spinner color="info" size="sm" className="p-2 mr-2" />

        <span>{LOADING_TEXT}</span>
      </div>
    );

    toastId.current = toast(toastContent, {
      autoClose: false,
      closeButton: false,
    });
  };

  const handlerExport = async (filterParams: Params) => {
    dispatch(clearExport());
    dispatch(setExportLinkLoading(true));
    dispatch(setToastId(toastId.current));
    notify();
    await doExport(convertParams ? convertParams(filterParams) : filterParams);
  };
  return { isLoadingExportLink, handlerExport };
};
export default useExportApi;
