import { BLOCK_NAVIGATION, SET_VALUE_DIFFERENCE } from './actionTypes';

export interface IValueDifference {
  nameProp: string;
  newValue: string;
  prevValue: string;
}

export const blockNavigationForm = (hasUnsavedValues: boolean) => {
  return {
    type: BLOCK_NAVIGATION,
    payload: hasUnsavedValues,
  };
};

export const setValueDifference = (valueDifference: IValueDifference[]) => {
  return {
    type: SET_VALUE_DIFFERENCE,
    payload: valueDifference,
  };
};
