import { useCallback } from 'react';
import { ValueType } from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { isDigitalValue } from '../../../services/utils/stringHelper/stringHelper';

const useBookingHandleSearch = (
  onSearchRequest: (searchString: unknown, field: string) => void
) => {
  const handleSearchPk = useCallback(
    (value: string) => {
      onSearchRequest(value, 'booking_pk');
    },
    [onSearchRequest]
  );
  const handleSearchVehicleFkArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'booking_vehicle_fk_array');
    },
    [onSearchRequest]
  );
  const handleSearchExternalRequestFk = useCallback(
    (value: string) => {
      if (isDigitalValue(value) || value.length === 0) {
        onSearchRequest(value, 'booking_external_request_fk');
      }
    },
    [onSearchRequest]
  );

  return {
    handleSearchExternalRequestFk,
    handleSearchVehicleFkArray,
    handleSearchPk,
  };
};
export default useBookingHandleSearch;
