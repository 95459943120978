import SubHeaderDetailsItem from 'components/layouts/SubHeaderDetailsItem/SubHeaderDetailsItem';
import { t } from 'services/utils/translation';
import { FC, memo } from 'react';
import { formatDateTimeShort } from 'services/utils/dateHelper/dateHelper';
import { parseISO } from 'date-fns';
const TIME_COMPLETION_TITLE = t('Время исполнения');

type PropsTypeExternalRequestHeaderTimeCompletion = {
  actualCompletion?: string;
  actualCommencement?: string;
};

const ExternalRequestHeaderTimeCompletion: FC<
  PropsTypeExternalRequestHeaderTimeCompletion
> = (props) => {
  const { actualCompletion, actualCommencement } = props;

  const actualCommencementClientDate =
    actualCommencement && formatDateTimeShort(parseISO(actualCommencement));
  const actualCompletionClientDate =
    actualCompletion && formatDateTimeShort(parseISO(actualCompletion));

  return (
    <SubHeaderDetailsItem
      items={{
        [TIME_COMPLETION_TITLE]: (
          <>
            {actualCommencementClientDate && (
              <span>
                {t('c ')}
                {actualCommencementClientDate}
              </span>
            )}
            <br />
            {actualCompletionClientDate && (
              <span>
                {t('по ')}
                {actualCompletionClientDate}
              </span>
            )}
          </>
        ),
      }}
    />
  );
};

export default memo(ExternalRequestHeaderTimeCompletion);
