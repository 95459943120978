import { InstallationListKeys } from 'services/api/auth/IRegisterIdentity';
import { IMenuItemPager } from 'services/interfaces/IMenuItemPager';
import { t } from 'services/utils/translation';
import { toTObject } from 'services/utils/TSUtils/toTObject';

const RIGHT_SIDE_OF_HEADER = toTObject({
  ADD: t('в компанию'),
  REMOVE: t('из компании'),
});

class IdentityFormSetting {
  menuItems: [IMenuItemPager, IMenuItemPager];
  activeItem;
  typeInstallations?: InstallationListKeys;
  title: string = '';
  constructor(
    menuItems: [IMenuItemPager, IMenuItemPager],
    activeItem: string,
    typeInstallations?: InstallationListKeys
  ) {
    this.menuItems = menuItems;
    this.activeItem = activeItem;
    this.typeInstallations = typeInstallations;
    this.title = this.getTitle();
  }

  private getTitle = () =>
    `${this.activeItem} пользователя ${this.createFinalPartTitle()}`;

  private getIsAddingForm = () =>
    this.activeItem === this.menuItems[0].name && !!this.typeInstallations;

  private createFinalPartTitle = () =>
    this.getIsAddingForm()
      ? RIGHT_SIDE_OF_HEADER.ADD
      : RIGHT_SIDE_OF_HEADER.REMOVE;
}

export default IdentityFormSetting;
