import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import IPrimaryKey from '../../../types/IPk';
import IKeyValueT from '../../interfacesApi/IKeyValueT';
import { IForSelectRequestV2 } from '../../../utils/generateMethodForSelect/generateMethodForSelect';
import { IGetPaginatedParamsResponsibleDepartment } from './IGetPaginatedParamsResponsibleDepartment';
import IResponsibleDepartment from './IResponsibleDepartment';
import ICreateResponsibleDepartment from './ICreateResponsibleDepartment';
import IUpdateResponsibleDepartment from './IUpdateResponsibleDepartment';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.RESPONSIBLE_DEPARTMENT
  );

export const getPaginatedResponsibleDepartmentApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsResponsibleDepartment>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IResponsibleDepartment>>> => {
  const getPaginated = api().getPaginated;
  return getPaginated(filterParams);
};

export const readResponsibleDepartmentApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<
  IBaseResponse<{ responsible_department: IResponsibleDepartment }>
> => {
  return api().read(params);
};

export const createResponsibleDepartmentApiV2 = async (
  params: IBaseParams<ICreateResponsibleDepartment>
): Promise<
  IBaseResponse<{ responsible_department: IResponsibleDepartment }>
> => {
  return api().create(params);
};

export const updateResponsibleDepartmentApiV2 = async (
  billingAccountType: IBaseParams<IUpdateResponsibleDepartment>
): Promise<
  IBaseResponse<{ responsible_department: IResponsibleDepartment }>
> => {
  return api().update(billingAccountType);
};

export const deleteResponsibleDepartmentApiV2 = async (
  deleteParams: IBaseParams<IKeyValueT<{ pk: number }>>
): Promise<IBaseResponse<undefined>> => {
  return api().delete(deleteParams);
};

export const getForSelectResponsibleDepartmentApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => {
  return api().forSelect(params);
};
