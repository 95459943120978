import { FilterType } from '../getClearTaskAndExternalRequestFilters/getClearTaskAndExternalRequestFilters';
const getTaskPermanentFiltersValue = ({
  billingAccountPk,
  counterpartyPk,
}: {
  billingAccountPk?: number;
  counterpartyPk?: number;
}) => {
  if (billingAccountPk) {
    return {
      internal_task_billing_account_fk: billingAccountPk,
    };
  }
  if (counterpartyPk) {
    return { internal_task_counterparty_fk: counterpartyPk };
  }
  return undefined;
};

const getExternalRequestPermanentFiltersValue = ({
  eventPk,
  billingAccountPk,
}: {
  eventPk?: number;
  billingAccountPk?: number;
}) => {
  if (billingAccountPk) {
    return {
      external_request_billing_account_fk: billingAccountPk,
    };
  }
  if (eventPk) {
    return {
      external_request_event_fk: eventPk,
    };
  }
  return undefined;
};

export const getPermanentFiltersValue = (
  entityType: FilterType,
  params: {
    billingAccountPk?: number;
    eventPk?: number;
    counterpartyPk?: number;
  }
) => {
  const handlers = {
    task: getTaskPermanentFiltersValue,
    externalRequest: getExternalRequestPermanentFiltersValue,
  };

  const handler = handlers[entityType];
  if (handler) {
    return handler(params);
  }
  return undefined;
};

export default getPermanentFiltersValue;
