import { useReducer } from 'react';
import { toast } from 'react-toastify';
import { t } from 'services/utils/translation';
import { IBaseResponse } from '../services/api/interfacesApi/IBaseResponse';
import createTimestamp from 'services/utils/createTimestamp/createTimestamp';
import IPrimaryKey from 'services/types/IPk';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { Dispatch, SetStateAction } from 'react';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from '../services/utils/catchAndRegError/catchAndRegError';
export interface IModalV2<Entity> {
  state: IState<Entity>;
  openAddEditForm: (entity?: Entity) => void;
  closeAddEditForm: () => void;
  successHandler: () => void;
  toggleDeleteForm: (entity?: Entity) => void;
  entityDeleteHandler: Function;
}

type PropsType = {
  setRefresh?: Dispatch<SetStateAction<boolean>>;
  deleteRequest?: (
    deleteParams: IBaseParams<IPrimaryKey>
  ) => Promise<IBaseResponse<undefined>>;
  entityIdKey?: string;
};

interface IState<Entity> {
  isAddEditFormOpen: boolean;
  isDeleteFormOpen: boolean;
  entity: null | Entity;
}
export function useModalV2<Entity extends { [x: string]: unknown }>(
  props: PropsType
): IModalV2<Entity> {
  const { setRefresh, deleteRequest, entityIdKey = 'id' } = props;

  const initialState: IState<Entity> = {
    isAddEditFormOpen: false,
    isDeleteFormOpen: false,
    entity: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const openAddEditForm = (entity?: Entity) => {
    dispatch({ type: 'openAddEditForm', entity });
  };
  const closeAddEditForm = () => {
    dispatch({ type: 'closeAddEditForm' });
  };

  const successHandler = () => {
    setRefresh && setRefresh((prevState: boolean) => !prevState);
    closeAddEditForm();
  };
  const toggleDeleteForm = (entity?: Entity) => {
    if (entity && !!entity[entityIdKey]) {
      dispatch({ type: 'openDeleteForm', entity });
    } else {
      dispatch({ type: 'closeDeleteForm' });
    }
  };

  const entityDeleteHandler = async (
    pk: number,
    deleteStatusSuspenseMessage: string,
    deleteMessageError: string = 'Не удалось произвести удаление',
    successDeleteHandler?: () => void
  ) => {
    if (deleteRequest) {
      const errorMessage = t(
        `${deleteMessageError}. Пожалуйста, обратитесь к администратору.`
      );

      try {
        const response: IBaseResponse<undefined> = await deleteRequest({
          params: { pk },
          id: createTimestamp(),
        });

        const { outcome, develop, verbose } = response.result;
        if (outcome !== 'WARNING') {
          setRefresh && setRefresh((prevState: boolean) => !prevState);
          successDeleteHandler && successDeleteHandler();
          toast(t(verbose ? verbose : deleteStatusSuspenseMessage));
        } else {
          catchErrorsIfServerStatusFalse(
            verbose ? verbose : errorMessage,
            develop
          );
        }
      } catch (error) {
        catchErrorFromFetch(errorMessage, error);
      } finally {
        toggleDeleteForm();
      }
    }
  };

  return {
    state,
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  };
}

const reducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case 'openAddEditForm':
      return {
        ...state,
        entity: action.entity,
        isAddEditFormOpen: true,
      };
    case 'closeAddEditForm':
      return {
        ...state,
        entity: null,
        isAddEditFormOpen: false,
      };
    case 'openDeleteForm':
      return {
        ...state,
        entity: action.entity,
        isDeleteFormOpen: true,
      };
    case 'closeDeleteForm':
      return {
        ...state,
        entity: null,
        isDeleteFormOpen: false,
      };
    default:
      throw new Error('Action is undefined.');
  }
};

export default useModalV2;
