import useToClientComments from './useToClientComments';
import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';
import { useCallback } from 'react';
import toGroupByDateComments from '../utils/toGropByDateComments/toGroupByDateComments';

const useToGroupByDateComments = (currentEmployeePk?: number) => {
  const toClientComments = useToClientComments(currentEmployeePk);
  return useCallback(
    (commentsListPayload: IGetCommentsPayloadV2) => {
      const clientComments = toClientComments(commentsListPayload);
      const numberAllMessages = clientComments.length;
      return {
        groupComments: toGroupByDateComments(clientComments),
        numberAllMessages,
      };
    },
    [toClientComments]
  );
};

export default useToGroupByDateComments;
