import { IControllerFilters } from 'services/api/controller/IControllerFilters';
import {
  CONTROLLER_SAVE_FILTERS,
  CONTROLLER_CLEAR_FILTERS,
  SET_ACCESS_POLICY_CONTROLLER,
  SET_CONTROLLER_FIELD_VALUES,
  SET_INITIAL_CONTROLLER_FIELD_VALUES,
  CLEAR_CONTROLLER_FIELD_VALUES,
} from './actionTypes';
import initialControllerFilters from './initial/initialControllerFilters';
import { IControllerClientAccessPolicy } from './initial/initialControllerAccessPolicy';
import { IFormValuesController } from 'components/controller/controller/ControllerForm/ControllerForm';

//FILTERS

export const saveControllerFilters = (filters: IControllerFilters) => ({
  type: CONTROLLER_SAVE_FILTERS,
  filters,
});

export const clearControllerFilters = () => ({
  type: CONTROLLER_CLEAR_FILTERS,
  filters: initialControllerFilters,
});

//FORM VALUES

export const saveControllerFieldValue =
  (pk: string) => (values: IFormValuesController) => ({
    type: SET_CONTROLLER_FIELD_VALUES,
    payload: { pk, values },
  });

export const setInitialController =
  (pk: string) => (values: IFormValuesController) => {
    return {
      type: SET_INITIAL_CONTROLLER_FIELD_VALUES,
      payload: { pk, values },
    };
  };

export const clearControllerFieldValue = (pk: string) => ({
  type: CLEAR_CONTROLLER_FIELD_VALUES,
});

export const setControllerAccessPolicy = (
  payload: IControllerClientAccessPolicy
) => ({
  type: SET_ACCESS_POLICY_CONTROLLER,
  payload,
});
