import { InvoiceDescriptionService } from './InvoiceDescriptionService';
import { NumberOfDocumentsDescriptionService } from './NumberOfDocumentsDescriptionService';
import { VehicleDescriptionService } from './VehicleDescriptionService';
import { AttachmentsService } from '../AttachmentsService/AttachmentsService';

export enum AttachmentDescription {
  vehicleDescription = 'vehicleDescription',
  numberOfDocumentsDescription = 'numberOfDocumentsDescription',
  invoiceDescription = 'invoiceDescription',
}

export class AttachmentClientDescriptionBase {
  protected vehicleDescription: string;
  protected numberOfDocumentsDescription: string;
  protected invoiceDescription: string;
  protected excludeFromDescription?: AttachmentDescription[];
  public attachmentDescription: string;

  constructor(
    {
      numberOfInvoices,
      vehiclesRegistrationPlate,
      numberOfDocuments,
    }: AttachmentsService,
    excludeFromDescription?: AttachmentDescription[]
  ) {
    this.excludeFromDescription = excludeFromDescription;

    const invoiceDescriptionService = new InvoiceDescriptionService();
    const numberOfDocumentsDescriptionService =
      new NumberOfDocumentsDescriptionService();
    const vehicleDescriptionService = new VehicleDescriptionService();

    this.vehicleDescription = vehicleDescriptionService.getVehicleDescription(
      vehiclesRegistrationPlate
    );
    this.numberOfDocumentsDescription =
      numberOfDocumentsDescriptionService.getNumberOfDocumentsDescription(
        numberOfDocuments
      );

    this.invoiceDescription =
      invoiceDescriptionService.getInvoiceDescription(numberOfInvoices);
    this.attachmentDescription = this.getAttachmentDescription(
      this.vehicleDescription,
      this.numberOfDocumentsDescription,
      this.invoiceDescription
    );
  }

  protected getAttachmentDescription(
    vehicleDescription: string,
    numberOfDocumentsDescription: string,
    invoiceDescription: string
  ) {
    const { excludeFromDescription } = this;
    const descriptions: string[] = [];

    if (
      !excludeFromDescription ||
      !excludeFromDescription.includes(AttachmentDescription.vehicleDescription)
    ) {
      descriptions.push(vehicleDescription);
    }

    if (
      !excludeFromDescription ||
      !excludeFromDescription.includes(
        AttachmentDescription.numberOfDocumentsDescription
      )
    ) {
      descriptions.push(numberOfDocumentsDescription);
    }

    if (
      !excludeFromDescription ||
      !excludeFromDescription.includes(AttachmentDescription.invoiceDescription)
    ) {
      descriptions.push(invoiceDescription);
    }
    return descriptions.join('\n');
  }
}
