import React, { FC, ChangeEvent, FocusEvent, memo } from 'react';
import { Input } from 'reactstrap';
import MaskedInput from 'react-text-mask';

type TypeProps = {
  id: string;
  placeholder?: string;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  required?: boolean;
  defaultValue?: string;
  value?: string;
  name?: string;
  className?: string;
  disabled?: boolean;
};

const MASK = [
  '+',
  '7',
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const PLACEHOLDER = '+7 (___) ___-__-__';

const MaskedPhoneInput: FC<TypeProps> = (props) => {
  const {
    onBlur,
    onChange,
    onKeyUp,
    className,
    disabled = false,
    value,
    id,
    name,
    placeholder,
  } = props;
  const maskedValue = value ? `+${value}` : '';

  return (
    <MaskedInput
      mask={MASK}
      id={`masked-${id}`}
      onChange={onChange}
      onBlur={onBlur}
      className={className}
      disabled={disabled}
      onKeyUp={onKeyUp}
      placeholder={placeholder || PLACEHOLDER}
      guide={true}
      value={maskedValue}
      render={(
        ref: any,
        { value, onChange, onBlur, onKeyUp, disabled }: any
      ) => (
        <Input
          value={value}
          type="tel"
          inputMode="tel"
          id={id}
          name={name}
          onKeyUp={onKeyUp}
          placeholder={placeholder || PLACEHOLDER}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          innerRef={ref}
          className={className}
        />
      )}
    />
  );
};

export default memo(MaskedPhoneInput);
