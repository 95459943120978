import { IFormValuesEvent } from 'components/organizations/event/EventForm/EventForm';
import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class EventType implements IFormValuesEvent {
  pk?: number;
  event_type_rendition: string = '';
  constructor(pk?: number) {
    this.pk = pk;
  }

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      event_type_rendition: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .required(t('Укажите название.')),
    });
  };
}

export default EventType;
