import classNames from 'clsx';
import { Formik } from 'formik';
import LoginData from 'models/LoginData';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { t } from 'services/utils/translation';
import styles from './index.module.scss';
import { usePostV3 } from 'hooks/usePostV3';
import { loginApiV2 } from 'services/api/auth/authApiV2';
import { RegisterPayload } from '../../../services/api/auth/IRegisterIdentity';
import TextField from 'components/controls/TextField';
import getActiveInstallation from 'store/utils/getActiveInstallation';
import { userLoginApiV2 } from '../../../store/auth/actions';
import CookieManager from 'models/CoockieManager/CookieManager';
import AuthTokenCookie from 'models/CoockieManager/AuthTokenCoockie/AuthTokenCookie';
import InstallationCookie from 'models/CoockieManager/InstallationCookie/InstallationCookie';
import useSaveLastPath from 'hooks/useSaveLastPath/useSaveLastPath';
import { useNavigate } from 'react-router-dom';
import PasswordField from 'components/controls/PasswordField/PasswordField';

const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const { lastVisitedPath } = useSaveLastPath();
  const navigate = useNavigate();
  // todo refactor me
  const handlePayload = (payload: RegisterPayload) => {
    const {
      identity_list_of_applicable_installations: identityInstallations,
      identity_pk,
    } = payload;

    const startInstallation = identityInstallations?.[0];

    const cookieManger = new CookieManager();
    const authTokenCookie = new AuthTokenCookie(cookieManger);
    const installationCookie = new InstallationCookie(cookieManger);

    authTokenCookie.saveAuthToken(identity_pk + '');

    if (startInstallation) {
      const { installation_name, installation_company_name, installation_pk } =
        startInstallation;
      installationCookie.setActiveInstallation(
        installation_name,
        installation_company_name,
        installation_pk
      );
      getActiveInstallation(identityInstallations?.[0]);
    }
    navigate(lastVisitedPath);

    dispatch(userLoginApiV2(payload));
  };

  const handleLogin = usePostV3({
    fetchApi: loginApiV2,
    handlePayload,
    viewToast: false,
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={new LoginData()}
      validationSchema={LoginData.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        await handleLogin(values, setErrors);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form
          onSubmit={handleSubmit}
          noValidate={true}
          className={classNames(styles['login-form'])}
        >
          <h1>{t('Вход')}</h1>

          <TextField
            id="nickname"
            label={t('Логин')}
            hasError={errors.nickname != null && touched.nickname != null}
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.nickname}
            value={values.nickname}
            autoComplete="on"
          />

          <PasswordField
            id="plain_password"
            label={t('Пароль')}
            placeholder={t('Введите пароль')}
            hasError={
              errors.plain_password != null && touched.plain_password != null
            }
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.plain_password}
            autoComplete="on"
          />

          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            className="btn btn-lg btn-primary btn-block"
          >
            {t('Войти')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginPage;
