import {
  IClientCodeActions,
  IClientAccessPolicyModel,
} from '../AccessPolicyForm/AccessPolicyForm';
import styles from './../index.module.scss';
import classNames from 'clsx';
import { Collapse } from 'reactstrap';
import Checkbox from 'components/controls/Checkbox';
import { v4 } from 'uuid';
import { FC, memo } from 'react';
import CollapseControlSection from '../CollapseControlSection/CollapseControlSection';
import { TCheckHandler } from '../AccessPolicyServiceModule.tsx/AccessPolicyServiceModule.tsx';
import useToggle from 'hooks/useToggle/useToggle';

export const getCountActiveAccessPolicy = (codeActions: IClientCodeActions[]) =>
  codeActions.reduce((acc: number, cur: IClientCodeActions) => {
    const count = cur.checked ? 1 : 0;
    return acc + count;
  }, 0);

type PropsType = {
  model: IClientAccessPolicyModel;
  onAdd: (modelName: string, actionCode: string) => void;
  onDelete: (modelName: string, actionCode: string) => void;
  onAllDelete: TCheckHandler;
  onAllAdd: TCheckHandler;
  isSectionChecked: (codeActions?: IClientCodeActions[]) => boolean;
};

const AccessPolicyModelControl: FC<PropsType> = (props) => {
  const {
    model: { labelModel, actionPermissions, nameModel },
    onAdd,
    onDelete,
    onAllDelete,
    onAllAdd,
    isSectionChecked,
  } = props;

  const counter = getCountActiveAccessPolicy(actionPermissions);

  const [open, toggle] = useToggle();
  return (
    <div key={nameModel}>
      <CollapseControlSection
        label={labelModel}
        counter={counter}
        toggle={toggle}
        open={open}
        className={'ml-2'}
        checked={isSectionChecked(actionPermissions)}
        onSectionAdd={() => onAllAdd(nameModel)}
        onSectionDelete={() => onAllDelete(nameModel)}
        sectionName={nameModel}
      />
      <Collapse isOpen={open} className={styles.collapsed}>
        {actionPermissions.map((action: IClientCodeActions) => {
          const { label, actionCode, checked } = action;

          return (
            <Checkbox
              key={action.actionCode}
              className={classNames(styles.action, {})}
              name={`action${v4()}`}
              label={label}
              onChange={() => {
                if (action.checked) {
                  onDelete(nameModel, actionCode);
                } else {
                  onAdd(nameModel, actionCode);
                }
              }}
              checked={checked}
            />
          );
        })}
      </Collapse>
    </div>
  );
};

export default memo(AccessPolicyModelControl);
