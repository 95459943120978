import classNames from 'clsx';
import { ReactComponent as ClearSearchIcon } from 'img/icons/icon-cancel.svg';
import { ReactComponent as SearchIcon } from 'img/icons/icon-loupe.svg';
import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useState,
  memo,
} from 'react';
import { Link } from 'react-router-dom';
import { Input, Spinner } from 'reactstrap';
import { makeLink } from 'services/localPaths';
import styles from './index.module.scss';
import { useGetList, IUseGetList } from '../../../hooks/useGetList';
import IBaseGetListParams from '../../../services/api/interfacesApi/IBaseGetListParams';
import { IBaseResponse } from '../../../services/api/interfacesApi/IBaseResponse';
import { IKeyValueString } from 'services/interfaces/IKeyValue';
import { isDigitalValue } from "../../../services/utils/stringHelper/stringHelper";

export enum HEADER_SEARCH_PROP_KEY_PK {
  externalRequest = 'external_request_pk',
  internalTask = 'internal_task_pk',
  billingAccount = 'billing_account_pk',
}

export enum HEADER_SEARCH_PROP_KEY_LABEL {
  externalRequest = 'external_request_pk',
  internalTask = 'internal_task_pk',
  billingAccount = 'billing_account_code_number',
}

type PropsType = {
  keys: {
    keyPk: HEADER_SEARCH_PROP_KEY_PK;
    keyForViewText: HEADER_SEARCH_PROP_KEY_LABEL;
  };
  patch: string;
  searchField: string;
  tittle: string;
  getList: (params: any) => Promise<IBaseResponse<{[k: string]: any}>>;
};

const TemplateMainLayoutHeaderSearch = (props: PropsType) => {
  const { keys, getList, searchField, tittle, patch } = props;

  const [value, setValue] = useState<string>('');
  const [expanded, setExpanded] = useState(false);

  function expand() {
    setExpanded(true);
  }

  function close(event: any) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setExpanded(false);
    }
  }

  const staticConvertData = (
    (patch: string, keyPk: HEADER_SEARCH_PROP_KEY_PK, keyForViewText: HEADER_SEARCH_PROP_KEY_LABEL) =>
    (payload: IKeyValueString[]) =>
      payload.map((entity: IKeyValueString) => ({
        link: makeLink(patch, entity[keyPk]),
        viewText: entity[keyForViewText],
      }))
  )(patch, keys?.keyPk, keys.keyForViewText);

  const onSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
    }
  };

  const onClear = (e: MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setParams({ [searchField]: '' });
    setValue('');
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isDigitalValue(value) || value === '') {
      setValue(value?.trim());
      onSearchRequest(value?.trim(), searchField);
    }
  };

  const [initialParams] = useState<IBaseGetListParams>({ skip: 0, length: 15 });

  const {
    data: entities,
    onSearchRequest,
    setParams,
    isLoading,
  }: IUseGetList<any, IBaseGetListParams> = useGetList({
    getDataApi: getList,
    initialParams,
    convertData: staticConvertData,
    condition: value?.length > 0,
  });

  const conditionForViewList = entities && !isLoading && expanded;

  return (
    <div
      className={classNames(styles.accountSearchContainer, 'mr-3')}
      onFocus={expand}
      onBlur={close}
    >
      <label
        className={classNames(
          styles.accountSearch,
          'd-flex flex-row justify-content-center align-items-center'
        )}
      >
        <span className={styles.searchIcon}>
          <SearchIcon />
        </span>
        <span className="w-100">
          <Input
            className={styles.searchField}
            placeholder={tittle}
            onKeyDown={onSearch}
            onChange={onChange}
            value={value}
          />
        </span>
        <span
          className={classNames(styles.clearSearchIcon, {
            [styles.clearSearchIconPointer]: value?.length > 0,
          })}
          onClick={onClear}
        >
          {value && <ClearSearchIcon />}
        </span>
      </label>
      <div
        className={classNames(styles.results, {
          [styles.spinnerContainer]: isLoading,
        })}
      >
        {conditionForViewList &&
          entities.map(
            ({ link, viewText }: { link: string; viewText: string }) => (
              <div
                onClick={(event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
                  close(event);
                  setValue('');
                }}
              >
                <Link key={link} className={styles.result} to={link}>
                  {viewText}
                </Link>
              </div>
            )
          )}
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner className="mt-3" color="primary" />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TemplateMainLayoutHeaderSearch);
