import classNames from 'clsx';
import styles from './index.module.scss';
import { FC, PropsWithChildren } from 'react';
import FormHeaderTitle from 'components/controls/FormHeader/FormHeaderTitle/FormHeaderTitle';

type PropsType = {
  title: string;
  id?: string;
};

export const SubHeaderLinkWrapperWithTitle: FC<
  PropsWithChildren<PropsType>
> = ({ title, children, id = '' }) => {
  return (
    <div
      id={id}
      className={classNames(
        styles.wrapperData,
        'd-flex flex-row justify-content-start, '
      )}
    >
      <div className="px-4 py-2 py-sm-2 mr-2 d-flex flex-column justify-content-center align-items-center">
        <FormHeaderTitle title={title} className="mb-2" />
        <div className={classNames(styles.tittleLink)}>{children}</div>
      </div>
    </div>
  );
};

export default SubHeaderLinkWrapperWithTitle;
