import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import React, { FC, useMemo } from 'react';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import DocumentsPage from '../../../documents/DocumentsPage/DocumentsPage';
import { makeLink } from 'services/localPaths';
import { PATH_ORGANIZATIONS_EVENT_EDIT } from 'services/pathConstants';
import useGetRefreshingData from '../../../../hooks/useGetRefreshingData';
import ExternalRequestPage from 'pages/external-request/external-requests/ExternalRequestPage/ExternalRequestPage';

import {
  TAB_QUERY_PARAMS,
  TAB_QUERY_KEY,
} from '../../../../services/pathConstants';
import useGetQueryParameter from 'hooks/useGetQueryParameter';
import { parentUIElement } from 'services/constants/parentUIElement';
import ViewEntity from 'components/layouts/ViewEntity/ViewEntity';
import EventHistory from 'components/organizations/event/EventHistory/EventHistory';
import { IGetHistoryEventPayload } from 'services/api/organizationsV2/event/IEventHistory';
import { IEvent } from 'services/api/organizationsV2/event/IEvent';
import {
  getHistoryEventApiV2,
  readEventApi,
} from '../../../../services/api/organizationsV2/event/eventApi';
import useGetTabsEventView from 'hooks/useTabsEventView/useTabsEventView';
import useGetEventLabels from '../hooks/useGetLabels';
import { useParams } from 'react-router-dom';

const convertHistoryData = (data: IGetHistoryEventPayload) => ({
  rows: data?.event_list_of_edits,
  creationInfo: {
    event_datetime_added: data?.event_datetime_added,
    event_author_dk: data?.event_author_dk,
    event_author: data?.event_author,
  },
});

type AccountViewPagePropTypes = {
  id?: string;
};

const EventViewPage: FC<AccountViewPagePropTypes> = () => {
  const { id } = useParams<{ id: string }>();

  const eventPk = Number(id);
  const { tabs, refreshTabs, isLoadingCountTabs } = useGetTabsEventView({
    eventPk: eventPk,
  });

  const initialParams = useMemo(() => {
    return { pk: eventPk };
  }, [eventPk]);

  const { data: historyData, isLoading: isLoadingHistory } =
    useGetRefreshingData({
      getDataApi: getHistoryEventApiV2,
      initialData: null,
      params: initialParams,
      condition: !!eventPk,
      convertData: convertHistoryData,
    });

  const { data: event } = useGetRefreshingData({
    getDataApi: readEventApi,
    params: initialParams,
    condition: !!eventPk,
    convertData: (response: { event: IEvent }) => response.event,
    initialData: null,
  });

  const labels = useGetEventLabels(event);

  const linkOnRightButton = makeLink(PATH_ORGANIZATIONS_EVENT_EDIT, eventPk);

  const CONTENT_MAP = {
    [TAB_QUERY_PARAMS.externalRequest]: (
      <ExternalRequestPage eventPk={eventPk} />
    ),
    [TAB_QUERY_PARAMS.documents]: (
      <DocumentsPage
        parentUIName={parentUIElement.event}
        parentUIPk={eventPk}
        refreshTabs={refreshTabs}
      />
    ),
    [TAB_QUERY_PARAMS.history]: (
      <EventHistory
        eventRequestHistory={historyData?.rows}
        creationEventInfo={historyData?.creationInfo}
        isLoading={isLoadingHistory}
      />
    ),
  };

  const targetTab = useGetQueryParameter(
    TAB_QUERY_KEY.event
  ) as keyof typeof CONTENT_MAP;

  const ContentForRender =
    CONTENT_MAP[targetTab || TAB_QUERY_PARAMS.externalRequest];

  if (event === null) {
    return (
      <MainLayout>
        <Subheader />
        <LoaderFetch />
      </MainLayout>
    );
  }

  return (
    <ViewEntity
      mainInfo={{
        title: 'Событие',
        value: event.event_name,
      }}
      tabs={tabs}
      linkOnRightButton={linkOnRightButton}
      items={labels || undefined}
      targetTab={targetTab || TAB_QUERY_PARAMS.externalRequest}
      isLoadingCountTabs={isLoadingCountTabs}
    >
      {ContentForRender}
    </ViewEntity>
  );
};

//EventViewPage.whyDidYouRender = true;

export default EventViewPage;
