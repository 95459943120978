import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import IClientSelectOptionV2 from '../services/api/interfacesApi/IClientSelectOptionV2';

class TaskType {
  pk?: number;
  internal_task_type_rendition: string = '';
  internal_task_type_parent_type_fk?: number;
  parent?: IClientSelectOptionV2<number>;

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      internal_task_type_rendition: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .required(t('Укажите название.')),
      parent: Yup.object().nullable(),
    });
  };
}

export default TaskType;
