import React, {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  memo,
  useState,
} from 'react';
import styles from './index.module.scss';
import classNames from 'clsx';
import { Tooltip } from 'reactstrap';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';

type SubheaderButtonType = {
  className?: string;
  onClick?: MouseEventHandler;
  bordered?: boolean;
  disabled?: boolean;
  hintTarget?: string;
  hintText?: string;
  color?: TABLE_COLOR;
};

type PropsType = PropsWithChildren<SubheaderButtonType>;

const SubheaderButton: FC<PropsType> = (props) => {
  const {
    className,
    onClick,
    bordered = true,
    children,
    disabled,
    hintTarget,
    hintText,
    color = TABLE_COLOR.DEFAULT,
  } = props;

  const CLASSNAME_BUTTON_KEY = `button_${color}`;
  const CLASSNAME_BUTTON_DISABLED_KEY = `buttonDisabled_${color}`;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div id={hintTarget}>
      <button
        className={classNames(
          'px-2 py-1 my-1 ml-3 d-flex align-items-center',
          styles[CLASSNAME_BUTTON_KEY],
          {
            [styles[CLASSNAME_BUTTON_DISABLED_KEY]]: disabled,
            [styles.button]: !disabled,
            [styles.bordered]: bordered && !disabled,
            [styles.borderedDisable]: bordered && disabled,
          },

          className
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
      {hintTarget && (
        <Tooltip target={hintTarget} isOpen={tooltipOpen} toggle={toggle}>
          {hintText}
        </Tooltip>
      )}
    </div>
  );
};

export default memo(SubheaderButton);
