import ExternalRequestForm from 'components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import ExternalRequestHeader from 'components/external-request/ExternalRequestHeader/ExternalRequestHeader';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import useFirstExternalRequestStatus from '../hooks/useFirstExternalRequestStatus';

type OrderAddPagePropTypes = {
  id?: string;
};

const ExternalRequestAddPage: FC<OrderAddPagePropTypes> = () => {
  useFirstExternalRequestStatus();
  const location = useLocation();
  const billingAccountId = location.state?.billingAccountId;
  const eventPk = location.state?.eventPk;
  return (
    <MainLayout>
      <Subheader />
      <ExternalRequestHeader
        haveAccessToCreate={true}
        billingAccountId={billingAccountId}
        eventPk={eventPk}
      />
      <ExternalRequestForm
        billingAccountId={billingAccountId}
        eventPk={eventPk}
      />
    </MainLayout>
  );
};

export default ExternalRequestAddPage;
