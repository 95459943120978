import { FC, memo } from 'react';
import {
  demoteFromAdmin,
  promoteToAdmin,
} from 'services/api/identity/identity';
import EditingEntitiesFromIdentityForm from '../EditingEntitiesFromIdentityForm/EditingEntitiesFromIdentityForm';
import { t } from '../../../services/utils/translation';
import { menuItemsAdminSettings } from '../../../services/constants/MenuItemPager/MenuItemPager';
import { InstallationListKeys } from 'services/api/auth/IRegisterIdentity';

type PropsType = {
  isOpen: boolean;
  closeHandler: Function;
};

const AdminIdentityForm: FC<PropsType> = ({ isOpen, closeHandler }) => {
  return (
    <EditingEntitiesFromIdentityForm
      typeInstallations={InstallationListKeys.none}
      isOpen={isOpen}
      closeHandler={closeHandler}
      addEntity={promoteToAdmin}
      removeEntity={demoteFromAdmin}
      tabs={menuItemsAdminSettings}
      buttonAddText={t('Сделать администратором')}
      buttonRemoveText={t('Убрать из администраторов')}
    />
  );
};

export default memo(AdminIdentityForm);
