import { IBooking } from 'services/api/vehicle/booking/IBooking';

export class AttachmentBookingService {
  public getVehiclesPlateFromBookings(bookings?: IBooking[]): string[] | null {
    if (bookings === undefined) {
      return null;
    }
    return this.getAllVehiclesPlateFromBookings(bookings);
  }
  protected getAllVehiclesPlateFromBookings = (bookings: IBooking[]) => {
    return bookings.map((booking: IBooking) => {
      return booking.booking_vehicle.vehicle_registration_plate;
    });
  };
}
