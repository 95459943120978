import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import Folder from 'components/misc/Folder';
import { FC } from 'react';
import { PATH_TASK_FOLDER } from '../../../services/pathConstants';

const TaskFolderPage: FC = () => {
  return (
    <MainLayout>
      <Subheader />
      <Folder path={PATH_TASK_FOLDER} />
    </MainLayout>
  );
};

export default TaskFolderPage;
