import { useCallback } from 'react';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { ValueType } from 'react-select';

const useEventHandleSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
  const handleSearchPk = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'event_pk');
    },
    [onSearchRequest]
  );

  const handleSearchEventName = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'event_name');
    },
    [onSearchRequest]
  );

  const handleSelectEvenTypeArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'event_type_fk_array');
    },
    [onSearchRequest]
  );

  const handleSelectBillingAccountArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'event_billing_account_fk_array');
    },
    [onSearchRequest]
  );

  return {
    handleSearchPk,
    handleSearchEventName,
    handleSelectEvenTypeArray,
    handleSelectBillingAccountArray,
  };
};

export default useEventHandleSearch;
