import { RefObject, useEffect } from 'react';

const useTextAreaByContent = (
  textareaRef: RefObject<HTMLInputElement | HTMLTextAreaElement>,
  value?: string
) => {
  useEffect(() => {
    if (textareaRef?.current?.style) {
      textareaRef.current.style.height = '100%';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
};

export default useTextAreaByContent;
