export interface IStateReducerIdentity {
  isOpenAddUserToInstallations: boolean;
  isOpenAbilityAddToInstallation: boolean;
  isOpenEditMyPassword: boolean;
  isOpenOwnerShip: boolean;
  isOpenAdminForm: boolean;
  isOpenSetPasswordViaAdminForm: boolean;
}

  export enum ActionPointsIdentityControlPanel {
  OPEN_ADD_USER_TO_INSTALLATION = 'OPEN_ADD_USER_TO_INSTALLATION',
  CLOSE_ADD_USER_TO_INSTALLATION = 'CLOSE_ADD_USER_TO_INSTALLATION',
  OPEN_ABILITY_ADD_TO_INSTALLATION = 'OPEN_ABILITY_ADD_TO_INSTALLATION',
  CLOSE_ABILITY_ADD_TO_INSTALLATION = 'CLOSE_ABILITY_ADD_TO_INSTALLATION',
  OPEN_EDIT_MY_PASSWORD = 'OPEN_EDIT_MY_PASSWORD',
  CLOSE_EDIT_MY_PASSWORD = 'CLOSE_EDIT_MY_PASSWORD',
  OPEN_MAKE_OWNER = 'OPEN_MAKE_OWNER',
  CLOSE_MAKE_OWNER = 'CLOSE_MAKE_OWNER',
  OPEN_MAKE_ADMIN = 'OPEN_MAKE_ADMIN',
  CLOSE_MAKE_ADMIN = 'CLOSE_MAKE_ADMIN',
  OPEN_SET_PASSWORD_VIA_ADMIN = 'OPEN_SET_PASSWORD_VIA_ADMIN',
  CLOSE_SET_PASSWORD_VIA_ADMIN = 'CLOSE_SET_PASSWORD_VIA_ADMIN',
}

export type ActionIdentityControlPanelType =
  | {
      type: ActionPointsIdentityControlPanel.OPEN_ADD_USER_TO_INSTALLATION;
    }
  | {
      type: ActionPointsIdentityControlPanel.CLOSE_ADD_USER_TO_INSTALLATION;
    } 
    | {
      type: ActionPointsIdentityControlPanel.OPEN_ABILITY_ADD_TO_INSTALLATION;
    }
  | {
      type: ActionPointsIdentityControlPanel.CLOSE_ABILITY_ADD_TO_INSTALLATION;
    }
  | {
      type: ActionPointsIdentityControlPanel.OPEN_EDIT_MY_PASSWORD;
    }
  | {
      type: ActionPointsIdentityControlPanel.CLOSE_EDIT_MY_PASSWORD;
    }
  | {
      type: ActionPointsIdentityControlPanel.OPEN_MAKE_OWNER;
    }
  | {
      type: ActionPointsIdentityControlPanel.CLOSE_MAKE_OWNER;
    }
  | {
      type: ActionPointsIdentityControlPanel.OPEN_MAKE_ADMIN;
    }
  | {
      type: ActionPointsIdentityControlPanel.CLOSE_MAKE_ADMIN;
    }
  | {
      type: ActionPointsIdentityControlPanel.OPEN_SET_PASSWORD_VIA_ADMIN;
    }
  | {
      type: ActionPointsIdentityControlPanel.CLOSE_SET_PASSWORD_VIA_ADMIN;
    };