import CookieManager from 'models/CoockieManager/CookieManager';
import InstallationCookie from '../InstallationCookie/InstallationCookie';

class AuthTokenCookie {
  private readonly cookieManager: CookieManager;

  constructor(cookieManager: CookieManager) {
    this.cookieManager = cookieManager;
  }

  public saveAuthToken(token: string) {
    this.cookieManager.setCookie('token', token);
  }

  public getAuthToken(): string | undefined {
    return this.cookieManager.getCookie('token');
  }

  public removeAuthData() {
    this.cookieManager.removeCookie('token');
    const installation = new InstallationCookie(this.cookieManager);
    installation.removeActiveInstallation();
  }
}

export default AuthTokenCookie;
