import classNames from 'clsx';
import HiddenField from 'components/controls/HiddenField';
import { Formik } from 'formik';
import { ReactComponent as AddIcon } from 'img/orders/add-comment-btn.svg';
import { ChangeEvent, useState, useRef, memo } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import { t } from 'services/utils/translation';
import styles from './index.module.scss';

import { usePostV3 } from 'hooks/usePostV3';
import ClientComment from 'models/ClientComment';
import { IBaseResponse } from '../../../../services/api/interfacesApi/IBaseResponse';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import useSaveCommentInDraft from './useSaveCommentInDraft';
import useTextAreaByContent from 'hooks/useTextAreaByContent';
import { TFormikHandleChange } from '../../../../services/interfaces/TFormikHandleChange';

export interface IFormValuesClientComment<Pk = number | string> {
  pk: Pk;
  comment_text?: string;
}

type PropsType<CommentParams, Comments, Pk = number | string> = {
  pk: Pk extends number ? number : string;
  refreshCommentList: () => void;
  addCommentApiV2: (
    params: IBaseParams<CommentParams | unknown>
  ) => Promise<IBaseResponse<Comments | unknown>>;
  convertParams: (value: IFormValuesClientComment) => CommentParams;
};

function CommentForm<CommentParams, Comments, Pk extends string | number>(
  props: PropsType<CommentParams, Comments, Pk>
) {
  const { pk, refreshCommentList, addCommentApiV2, convertParams } = props;

  const [textComment, setTextComment] = useState<string>('');

  const [commentDraft, setCommentDraft, remove] = useSaveCommentInDraft({
    pk,
    commentValue: textComment,
  });
  const initialComment = commentDraft
    ? new ClientComment(pk, commentDraft)
    : new ClientComment(pk);

  const successHandler = () => {
    refreshCommentList();
    setTextComment('');
    setCommentDraft('');
    remove();
  };

  const addComment = usePostV3({
    fetchApi: addCommentApiV2,
    successMessage: t('Комментарий добавлен успешно.'),
    successHandler,
  });

  const textareaRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  useTextAreaByContent(textareaRef, textComment);

  const extractHandleChangeComment =
    (handleChange: TFormikHandleChange) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      setTextComment(event.target.value);
      setCommentDraft(event.target.value);
      if (event.target.value.length === 0) {
        remove();
      }
    };

  const extractHandleKeyUpComment =
    (handleChange: TFormikHandleChange) => (event: any) => {
      // We have to manually handle Backspace for textarea fields.
      if (event.key === 'Backspace') {
        handleChange(event);
      }
      if (event.target.value.length === 0) {
        remove();
      }
    };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialComment}
      validationSchema={ClientComment.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        const newValues = convertParams(values);
        await addComment(newValues, setErrors, resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        submitForm,
        resetForm,
      }) => {
        return (
          <Form
            onSubmit={submitForm}
            noValidate={true}
            className={classNames(
              styles.addCommentForm,
              'd-flex flex-row align-items-end w-100'
            )}
          >
            {values.pk != null && (
              <HiddenField
                id="id"
                value={values.pk}
                hasError={errors.pk != null && touched.pk != null}
              />
            )}
            <Input
              innerRef={textareaRef}
              placeholder={t('Напишите сообщение...')}
              type="textarea"
              name="comment_text"
              id="comment_text"
              onBlur={handleBlur}
              onChange={extractHandleChangeComment(handleChange)}
              className={classNames(styles.addCommentTextarea, {
                'is-invalid':
                  errors.comment_text != null && touched.comment_text != null,
              })}
              required={true}
              onKeyUp={extractHandleKeyUpComment(handleChange)}
              value={values.comment_text}
            />
            <FormGroup>
              <button
                type="submit"
                className={styles.addCommentButton}
                disabled={isSubmitting}
                onClick={async (e) => {
                  e.preventDefault();
                  await submitForm();
                  if (textareaRef?.current?.style) {
                    textareaRef.current.style.height = '100%';
                  }
                  resetForm({ values: { pk: pk, comment_text: '' } });
                }}
              >
                <AddIcon />
              </button>
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
}

CommentForm.whyDidYouRender = true;

export default memo(CommentForm);
