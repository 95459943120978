import { useEffect, useState } from 'react';

const useCapsLockOn = () => {
  const [capsLockOn, setCapsLockOn] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      event?.getModifierState && setCapsLockOn(event?.getModifierState('CapsLock'));
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return capsLockOn;
};

export default useCapsLockOn;
