import TDCheckBox from 'components/tables/TDCheckBox/TDCheckBox';
import { Link } from 'react-router-dom';
import {
  ICounterparty,
  ICounterpartyForList,
} from 'services/api/organizationsV2/counterparty/ICounterparty';
import { makeLink } from 'services/localPaths';
import { PATH_ORGANIZATIONS_COUNTERPARTY_EDIT } from 'services/pathConstants';
import getInitials from 'services/utils/getInicials/getInicials';
import { t } from 'services/utils/translation';

const useUtilsForCounterpartyPage = () => {
  const composeCounterPartyList = (
    counterparties: ICounterparty[]
  ): ICounterpartyForList[] =>
    counterparties.map((counterparty: ICounterparty): ICounterpartyForList => {
      const {
        counterparty_is_natural_person: isNaturalPerson,
        counterparty_company_full_name: companyFullName,
        counterparty_individual_last_name: lastName,
        counterparty_individual_first_name: firstName,
        counterparty_individual_middle_name: middleName,
        counterparty_is_affiliate,
        counterparty_pk: pk,
      } = counterparty;

      const affiliateHint = counterparty_is_affiliate
        ? t('Является партнером')
        : t('Не является партнером');

      return {
        ...counterparty,
        counterpartyLink: (
          <Link to={makeLink(PATH_ORGANIZATIONS_COUNTERPARTY_EDIT, pk)}>
            <span>{pk}</span>
          </Link>
        ),
        legalStatus: isNaturalPerson ? t('Физ. лицо') : t('Юр. лицо'),
        name: isNaturalPerson
          ? getInitials({ firstName, lastName, middleName })
          : companyFullName ?? '',
        openList: false,
        isAffiliate: (
          <TDCheckBox
            id={pk}
            hint={affiliateHint}
            isCheck={!!counterparty_is_affiliate}
          />
        ),
      };
    });

  return { composeCounterPartyList };
};

export default useUtilsForCounterpartyPage;
