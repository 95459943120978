import {
  LOAD_MENU,
  REMOVE_CURRENT_EMPLOYEE,
  REMOVE_MENU,
  SET_CURRENT_EMPLOYEE,
  SET_ERROR,
  SET_LOCALIZATION,
} from './actionTypes';
import { ILocalization } from '../../services/api/general/ILocalization';
import { IEmployee } from '../../services/api/staff/employee/IEmployee';
import { SET_MY_FAVORITE_LIST_FOLDER } from './actionTypes';
import {
  IMenuItemV2,
  ISubMenuItemV2,
} from '../../services/interfaces/IMenuItemV2';

export const loadLocalization = (payload: ILocalization) => ({
  type: SET_LOCALIZATION,
  payload,
});

export const setCurrentEmployee = (payload: IEmployee) => ({
  type: SET_CURRENT_EMPLOYEE,
  payload,
});

export const removeCurrentEmployee = () => ({
  type: REMOVE_CURRENT_EMPLOYEE,
});

export const setError = (error?: Error) => {
  return { type: SET_ERROR, payload: error?.message };
};
export const removeMenu = () => ({
  type: REMOVE_MENU,
});

export const loadMenu = (payload: IMenuItemV2[]) => ({
  type: LOAD_MENU,
  payload,
});

export const setFavoritesMyFolder = (payload: ISubMenuItemV2[]) => ({
  type: SET_MY_FAVORITE_LIST_FOLDER,
  payload,
});
