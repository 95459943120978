import classNames from 'clsx';
import React, { ChangeEvent, memo } from 'react';
import { FormGroup, Label } from 'reactstrap';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import Radio from '../Radio';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';
import styles from './indedx.module.scss';

type TypeProps<T extends string> = {
  name: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hasErrors?: boolean;
  options: [IClientSelectOptionV2<T>, IClientSelectOptionV2<T>];
  checkedValue?: number | string | boolean;
  inline?: boolean;
  label?: string;
  required?: boolean;
  disabled?: boolean;
};

const RadiosField = <T extends string>(props: TypeProps<T>) => {
  const {
    name,
    className,
    onChange,
    hasErrors = false,
    options,
    inline = false,
    label,
    required = false,
    checkedValue,
    disabled = false,
  } = props;
  return (
    <FormGroup className={classNames({ required: required }, className)}>
      {label && <Label className="control-label d-block">{label}</Label>}
      <ErrorWrapperField id={name}>
        <div
          className={classNames('d-flex my-3', {
            'is-invalid': hasErrors,
            'flex-row': inline,
            'flex-column': !inline,
          })}
        >
          {options &&
            options.map((option: IClientSelectOptionV2<string>) => (
              <Radio
                key={`${name + option.value}`}
                id={`${option.value}`}
                name={name}
                label={option.label}
                value={option.value}
                defaultChecked={option.value === checkedValue}
                checked={option.value === checkedValue}
                onChange={onChange}
                disabled={disabled}
                className={styles.radio}
              />
            ))}
        </div>
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(RadiosField);
