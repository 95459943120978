import { IBillingAccount } from 'services/api/organizationsV2/billing-account/IBillingAccount';
import { t } from 'services/utils/translation';
import deleteUnnecessaryParameters from '../../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { useMemo } from 'react';
import getStatusLabelColor from 'services/utils/getStatusLabelColor/getStatusLabelColor';
import ColoredStatusHeaderLabel from 'components/misc/ColoredStatusHeaderLabel/ColoredStatusHeaderLabel';
import styles from './index.module.scss';

const HEADER_LABELS = {
  TYPE: t('Тип'),
  ADDRESS: t('Адрес '),
  COUNTERPARTY: t('Контрагент'),
  STATUS: t('Статус'),
};

const useGetBillingAccountLabels = (account: IBillingAccount | null) => {
  return useMemo(() => {
    if (!account) {
      return null;
    }
    const { labelStatus, color } = getStatusLabelColor(
      account.billing_account_is_closed
    );

    const { billing_account_type: type } = account;

    return {
      [HEADER_LABELS.STATUS]: (
        <ColoredStatusHeaderLabel
          color={color}
          className={styles.statusViewDetails}
        >
          {labelStatus}
        </ColoredStatusHeaderLabel>
      ),
      ...deleteUnnecessaryParameters({
        [HEADER_LABELS.TYPE]: type?.billing_account_type_rendition,
      }),
      [HEADER_LABELS.ADDRESS]: <span>{account.billing_account_address}</span>,
    };
  }, [account]);
};

export default useGetBillingAccountLabels;
