import classNames from 'clsx';

import React, { FC, memo, PropsWithChildren } from 'react';

import Sidebar from '../Sidebar';
import styles from './index.module.scss';
import isEqual from 'react-fast-compare';

import useToggle from 'hooks/useToggle/useToggle';
import MainLayoutHeader from '../MainLayoutHeader/MainLayoutHeader';

type MainLayoutPropsType = {
  isInWrapper?: boolean;
};

type PropsType = PropsWithChildren<MainLayoutPropsType>;

const MainLayout: FC<PropsType> = (props) => {
  const { children, isInWrapper } = props;
  const [sidebarOpen, setSidebarOpen] = useToggle();

  return (
    <div>
      <div
        className={classNames({
          [styles['main-content-in-wrapper']]: isInWrapper,
          [styles['main-content']]: !isInWrapper,
        })}
      >
        <Sidebar isOpen={sidebarOpen} />
        <MainLayoutHeader burgerHandler={setSidebarOpen} />
        <main
          role="main"
          className={classNames({ [styles['with-sidebar']]: sidebarOpen })}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

MainLayout.whyDidYouRender = true;

export default memo(MainLayout, isEqual);
