import ListLinkSubHeaderWrapper from '../ListLinkSubHeaderWrapper/ListLinkSubHeaderWrapper';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import styles from './index.module.scss';
import classNames from 'clsx';
import {
  ChildrenEntity,
  toUniformListChild,
  UniformChildType,
} from '../../../../services/utils/toUniformListChild/toUniformListChild';
import FormHeaderLink from '../../FormHeader/FormHeaderLink/FormHeaderLink/FormHeaderLink';
type PropsType<T> = {
  listOfChildren: ChildrenEntity<T>[];
  title: string;
  path: string;
};
const getId = (pk: number) => `header-link-${pk}`;

const ListLinkFormHeader = <T,>(props: PropsType<T>) => {
  const { listOfChildren, title, path } = props;

  const uniformChild = toUniformListChild(listOfChildren);
  const listWithoutFirstChild = uniformChild.slice(1);
  return (
    <ListLinkSubHeaderWrapper
      listOfChildren={listOfChildren}
      title={title}
      patch={path}
    >
      <>
        {listWithoutFirstChild.map((child: UniformChildType) => {
          return (
            <div
              className={classNames(styles.tittleChildrenLink)}
              key={child.pk}
            >
              <FormHeaderLink path={path} pk={child.pk} id={getId(child.pk)}>
                {child.pk}
              </FormHeaderLink>
              <ToolTipWrapper placement="top" target={getId(child.pk)}>
                {child.description}
              </ToolTipWrapper>
            </div>
          );
        })}
      </>
    </ListLinkSubHeaderWrapper>
  );
};

export default ListLinkFormHeader;
