import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import { IGetPaginatedParamsWorkCategory } from './IGetPaginatedParamsWorkCategory';
import IWorkCategory from './IWorkCategory';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import IPrimaryKey from '../../../types/IPk';
import { ICreateWorkCategory } from './ICreateWorkCategory';
import IUpdateWorkCategory from './IUpdateWorkCategory';
import IKeyValueT from '../../interfacesApi/IKeyValueT';
import { IForSelectRequestV2 } from '../../../utils/generateMethodForSelect/generateMethodForSelect';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.WORK_CATEGORY
  );

export const getPaginatedWorkCategoryApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsWorkCategory>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IWorkCategory>>> => {
  const getPaginated = api().getPaginated;
  return getPaginated(filterParams);
};

export const readWorkCategoryApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<{ work_category: IWorkCategory }>> => {
  return api().read(params);
};

export const createWorkCategoryApiV2 = async (
  params: IBaseParams<ICreateWorkCategory>
): Promise<IBaseResponse<{ work_category: IWorkCategory }>> => {
  return api().create(params);
};

export const updateWorkCategoryApiV2 = async (
  billingAccountType: IBaseParams<IUpdateWorkCategory>
): Promise<IBaseResponse<{ work_category: IWorkCategory }>> => {
  return api().update(billingAccountType);
};

export const deleteWorkCategoryApiV2 = async (
  deleteParams: IBaseParams<IKeyValueT<{ pk: number }>>
): Promise<IBaseResponse<undefined>> => {
  return api().delete(deleteParams);
};

export const getForSelectWorkCategoryApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => {
  return api().forSelect(params);
};
