import { getSelectedValue } from '../getSelectedValue/getSelectedValue';

import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { createClientSelectOptionV2 } from '../selects';

export function getValueForSelect<T = number>(
  loadedOptions: IClientSelectOptionV2<T>[],
  optionsArray: IClientSelectOptionV2<T>[],
  isMulti: boolean,
  defaultValue: string | undefined
): IClientSelectOptionV2<T>[] | null | IClientSelectOptionV2<T> {
  const isEmptyLoadedOptions = loadedOptions.length === 0;
  const hasDefaultValue = defaultValue !== undefined;

  if (optionsArray.length > 0) {
    return getSelectedValue(loadedOptions, optionsArray, isMulti);
  }
  if (isEmptyLoadedOptions && hasDefaultValue) {
    return defaultValue
      ? (createClientSelectOptionV2(
          defaultValue,
          defaultValue + ''
        ) as IClientSelectOptionV2<T>)
      : null;
  }

  return isMulti ? [] : null;
}
