import STEPPER_FORM_DATA_KEY from './constants';

function setFormDataStepper<FormData>(formData: FormData) {
  return sessionStorage.setItem(
    STEPPER_FORM_DATA_KEY,
    JSON.stringify({ ...formData })
  );
}

export default setFormDataStepper;
