import classNames from 'clsx';
import React, { FC } from 'react';
import styles from '../index.module.scss';
import ListTab from '../Tabs/ListTab';
import { IMenuItemPagerV2 } from "../../../../services/interfaces/IMenuItemPagerV2";

type ContentMenuTabsProps = {
  items: IMenuItemPagerV2[];
  customClassName?: string;
  targetTab: string;
  isLoadingCountTabs?: boolean;
};

export const TabContext = React.createContext({
  targetTab: '',
  isLoading: false,
});

const PagerWithParameterSwitch: FC<ContentMenuTabsProps> = (props) => {
  const { items, customClassName, targetTab, isLoadingCountTabs } = props;
  return (
    <ul className={classNames(styles.menu, customClassName, 'px-3')}>
      <TabContext.Provider
        value={{ targetTab, isLoading: !!isLoadingCountTabs }}
      >
        <ListTab tabs={items} />
      </TabContext.Provider>
    </ul>
  );
};
export default PagerWithParameterSwitch;
