import {
  PATH_FRONTPAGE,
  PATH_EXTERNAL_REQUEST_TABLE,
  PATH_EXTERNAL_REQUEST_ADD,
  PATH_EXTERNAL_REQUEST_ADD_TITLE,
  PATH_EXTERNAL_REQUEST_EDIT,
  PATH_EXTERNAL_REQUEST_EDIT_TITLE,
  PATH_ORGANIZATIONS_ACCOUNTS,
  PATH_ORGANIZATIONS_ACCOUNTS_ADD,
  PATH_ORGANIZATIONS_ACCOUNTS_ADD_TITLE,
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW_TITLE,
  PATH_TASK_TABLE,
  PATH_TASK_EDIT,
  PATH_TICKETS_TICKET_EDIT_TITLE,
  PATH_PERSONNEL_EMPLOYEE,
  PATH_PERSONNEL_EMPLOYEE_ADD,
  PATH_USERS_STAFF_USER_ADD_TITLE,
  PATH_PERSONNEL_EMPLOYEE_EDIT,
  PATH_USERS_STAFF_USER_EDIT_TITLE,
  PATH_ORGANIZATIONS_COUNTERPARTY_EDIT_TITLE,
  PATH_ORGANIZATIONS_ACCOUNTS_EDIT_TITLE,
  PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST,
  PATH_TICKETS_TASK_FROM_ORDER_TITTLE,
  PATH_EXTERNAL_REQUEST_CREATE_FROM_TASK_TITTLE,
  PATH_EXTERNAL_REQUEST_FROM_TASK,
  PATH_TICKETS_TICKET_EDIT_BACK_TITTLE,
  PATH_EXTERNAL_REQUEST_EDIT_BACK_TITTLE,
  PATH_TASK_CREATE_CHILD_TICKET,
  PATH_TASK_CREATE_CHILD_TASK_TITLE,
  PATH_ORGANIZATIONS_ACCOUNTS_EDIT,
  PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST,
  PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST_TITTLE,
  PATH_ORGANIZATIONS_COUNTERPARTY,
  PATH_ORGANIZATIONS_COUNTERPARTY_ADD,
  PATH_ORGANIZATIONS_COUNTERPARTY_ADD_TITLE,
  PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
  PATH_NOTIFICATIONS,
  PATH_NOTIFICATION_TITLE,
  PATH_EXTERNAL_REQUEST_COPY,
  PATH_EXTERNAL_REQUEST_COPY_TITLE,
  PATH_ORGANIZATIONS_EVENT_ADD,
  PATH_ORGANIZATIONS_EVENT_EDIT,
  PATH_ORGANIZATIONS_EVENT_ADD_TITLE,
  PATH_ORGANIZATIONS_EVENT,
  PATH_ORGANIZATIONS_EVENT_TITLE,
  PATH_ORGANIZATIONS_EVENT_EDIT_TITLE,
  PATH_CONTROLLER_ADD,
  PATH_CONTROLLER_PAGE,
  PATH_CONTROLLER_ADD_TITLE,
  PATH_CONTROLLER_EDIT,
  PATH_CONTROLLER_EDIT_TITLE,
} from './pathConstants';
import { frontpageTitle } from './utils/misc/misc';
import { PATH_ORGANIZATIONS_EVENT_VIEW } from './pathConstants';

type LocalPath = {
  title: string;
  parent: string | null;
};
type LocalPaths = {
  [key: string]: LocalPath;
};

const localPaths: LocalPaths = {
  [PATH_FRONTPAGE]: {
    title: frontpageTitle(),
    parent: null,
  },
  [PATH_PERSONNEL_EMPLOYEE_ADD]: {
    title: PATH_USERS_STAFF_USER_ADD_TITLE,
    parent: PATH_PERSONNEL_EMPLOYEE,
  },
  [PATH_PERSONNEL_EMPLOYEE_EDIT]: {
    title: PATH_USERS_STAFF_USER_EDIT_TITLE,
    parent: PATH_PERSONNEL_EMPLOYEE,
  },
  [PATH_ORGANIZATIONS_COUNTERPARTY_ADD]: {
    title: PATH_ORGANIZATIONS_COUNTERPARTY_ADD_TITLE,
    parent: PATH_ORGANIZATIONS_COUNTERPARTY,
  },
  [PATH_ORGANIZATIONS_COUNTERPARTY_EDIT]: {
    title: PATH_ORGANIZATIONS_COUNTERPARTY_EDIT_TITLE,
    parent: PATH_ORGANIZATIONS_COUNTERPARTY,
  },

  [PATH_ORGANIZATIONS_ACCOUNTS_VIEW]: {
    title: PATH_ORGANIZATIONS_ACCOUNTS_VIEW_TITLE,
    parent: PATH_ORGANIZATIONS_ACCOUNTS,
  },

  [PATH_ORGANIZATIONS_ACCOUNTS_ADD]: {
    title: PATH_ORGANIZATIONS_ACCOUNTS_ADD_TITLE,
    parent: PATH_ORGANIZATIONS_ACCOUNTS,
  },
  [PATH_ORGANIZATIONS_ACCOUNTS_EDIT]: {
    title: PATH_ORGANIZATIONS_ACCOUNTS_EDIT_TITLE,
    parent: PATH_ORGANIZATIONS_ACCOUNTS,
  },
  [PATH_ORGANIZATIONS_EVENT_VIEW]: {
    title: PATH_ORGANIZATIONS_EVENT_TITLE,
    parent: PATH_ORGANIZATIONS_EVENT,
  },

  [PATH_ORGANIZATIONS_EVENT_ADD]: {
    title: PATH_ORGANIZATIONS_EVENT_ADD_TITLE,
    parent: PATH_ORGANIZATIONS_EVENT,
  },
  [PATH_ORGANIZATIONS_EVENT_EDIT]: {
    title: PATH_ORGANIZATIONS_EVENT_EDIT_TITLE,
    parent: PATH_ORGANIZATIONS_EVENT,
  },
  [PATH_TASK_EDIT]: {
    title: PATH_TICKETS_TICKET_EDIT_TITLE,
    parent: PATH_TASK_TABLE,
  },
  [PATH_TASK_CREATE_CHILD_TICKET]: {
    title: PATH_TASK_CREATE_CHILD_TASK_TITLE,
    parent: PATH_TASK_TABLE,
  },
  [PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST]: {
    title: PATH_TICKETS_TASK_FROM_ORDER_TITTLE,
    parent: PATH_TASK_TABLE,
  },
  [PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST]: {
    title: PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST_TITTLE,
    parent: PATH_EXTERNAL_REQUEST_TABLE,
  },
  [PATH_EXTERNAL_REQUEST_FROM_TASK]: {
    title: PATH_EXTERNAL_REQUEST_CREATE_FROM_TASK_TITTLE,
    parent: PATH_EXTERNAL_REQUEST_TABLE,
  },
  [PATH_EXTERNAL_REQUEST_ADD]: {
    title: PATH_EXTERNAL_REQUEST_ADD_TITLE,
    parent: PATH_EXTERNAL_REQUEST_TABLE,
  },
  [PATH_EXTERNAL_REQUEST_EDIT]: {
    title: PATH_EXTERNAL_REQUEST_EDIT_TITLE,
    parent: PATH_EXTERNAL_REQUEST_TABLE,
  },
  [PATH_EXTERNAL_REQUEST_COPY]: {
    title: PATH_EXTERNAL_REQUEST_COPY_TITLE,
    parent: PATH_EXTERNAL_REQUEST_TABLE,
  },

  [PATH_NOTIFICATIONS]: {
    title: PATH_NOTIFICATION_TITLE,
    parent: PATH_FRONTPAGE,
  },
  [PATH_CONTROLLER_ADD]: {
    title: PATH_CONTROLLER_ADD_TITLE,
    parent: PATH_CONTROLLER_PAGE,
  },
  [PATH_CONTROLLER_EDIT]: {
    title: PATH_CONTROLLER_EDIT_TITLE,
    parent: PATH_CONTROLLER_PAGE,
  },
};

interface ILocalPatchWithBackNavigation {
  patch: string;
  wherePath: string;
  wherePathTittle: string;
}

const localPatchWithBackNavigationArray: ILocalPatchWithBackNavigation[] = [
  {
    patch: PATH_TASK_CREATE_CHILD_TICKET,
    wherePath: PATH_TASK_EDIT,
    wherePathTittle: PATH_TICKETS_TICKET_EDIT_BACK_TITTLE,
  },
  {
    patch: PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST,
    wherePath: PATH_EXTERNAL_REQUEST_EDIT,
    wherePathTittle: PATH_EXTERNAL_REQUEST_EDIT_BACK_TITTLE,
  },
  {
    patch: PATH_EXTERNAL_REQUEST_FROM_TASK,
    wherePath: PATH_TASK_EDIT,
    wherePathTittle: PATH_TICKETS_TICKET_EDIT_BACK_TITTLE,
  },
  {
    patch: PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST,
    wherePath: PATH_EXTERNAL_REQUEST_EDIT,
    wherePathTittle: PATH_EXTERNAL_REQUEST_EDIT_BACK_TITTLE,
  },
];

export const findLocalPatchWithBackNavigation = (targetPatch: string) =>
  localPatchWithBackNavigationArray.find(
    ({ patch }: ILocalPatchWithBackNavigation) => targetPatch === patch
  );

export const replaceIdInPatch = (patch: string, id: string) =>
  patch.replace(':id', id);

export const getBackLocalPath = (targetPatch: string, id?: string) => {
  const localPatchWithBackNavigation =
    findLocalPatchWithBackNavigation(targetPatch);
  if (localPatchWithBackNavigation?.patch && id) {
    return {
      patch: replaceIdInPatch(localPatchWithBackNavigation.wherePath, id),
      wherePathTittle: localPatchWithBackNavigation.wherePathTittle + id,
    };
  }
  return null;
};

export const isPathLocal = (path: string): boolean => {
  return localPaths[path] != null;
};
export const getLocalPathTitle = (path: string): string => {
  return isPathLocal(path) ? localPaths[path].title : '';
};
export const getLocalParentPath = (path: string): string | null => {
  if (!isPathLocal(path)) {
    return null;
  }

  const parentPath = localPaths[path].parent;

  if (parentPath === null) {
    return PATH_FRONTPAGE;
  }
  return parentPath;
};

export const makeLink = (patch: string, id: number | string) => {
  return patch.replace(':id', id.toString());
};
