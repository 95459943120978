import { toast } from 'react-toastify';
import { t } from '../services/utils/translation';
import { useCallback } from 'react';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import createTimestamp from '../services/utils/createTimestamp/createTimestamp';
import { IBaseResponse } from '../services/api/interfacesApi/IBaseResponse';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from '../services/utils/catchAndRegError/catchAndRegError';

export type TUseGetPostResult<Payload, NewPayload> =
  | Promise<Payload | NewPayload | undefined>
  | undefined;

interface IProps<Payload, Params, NewPayload> {
  convertParams?: (params: any) => Params; //если нужно конвертировать параметры перед отправкой
  fetchApi: (params: IBaseParams<Params>) => Promise<IBaseResponse<Payload>>; // функция для запроса
  errorMessage?: string; // свой текст в тосте об ошибке
  successMessage?: string; // свой текст в тосте об успехе
  successHandler?: () => void; // функция после успешного запроса
  errorHandler?: Function; // функция после ошибки
  onStep?: (params: Params) => void; // функция для перехода по роутингу на шаг
  handlePayload?: (payload: Payload) => void; // функция для обрабатывания полученных данных
  viewToast?: boolean; //нужно ли показывать тост
  convertPayload?: (payload: Payload) => NewPayload;
}

export function useGetPost<Payload, Params, NewPayload = Payload>(
  props: IProps<Payload, Params, NewPayload>
) {
  const {
    fetchApi,
    errorMessage = 'Не удалось отправить данные',
    successMessage = 'Данные отправлены успешно',
    successHandler,
    errorHandler,
    convertParams,
    onStep,
    handlePayload,
    convertPayload,
    viewToast = true,
  } = props;

  return useCallback(
    async (params: Params): Promise<TUseGetPostResult<Payload, NewPayload>> => {
      const newParams: Params = convertParams ? convertParams(params) : params;

      const fullErrorMessage = `${errorMessage}. Обратитесь, пожалуйста, к администратору`;
      try {
        const response: IBaseResponse<Payload> = await fetchApi({
          id: createTimestamp(),
          params: newParams,
        });
        const {
          result: { verbose, payload, message, outcome },
        } = response;

        if (outcome === 'WARNING') {
          catchErrorsIfServerStatusFalse(
            verbose ? verbose : errorMessage,
            message
          );
          errorHandler && errorHandler();
          return;
        }

        onStep && onStep(newParams);
        handlePayload && payload && handlePayload(payload);

        const successMessageFinal = verbose ? verbose : successMessage;
        viewToast && toast(t(successMessageFinal));
        successHandler && successHandler();
        return convertPayload && payload ? convertPayload(payload) : payload;
      } catch (error) {
        catchErrorFromFetch(fullErrorMessage, error);
        errorHandler && errorHandler();
        return;
      }
    },
    [
      convertParams,
      errorMessage,
      fetchApi,
      onStep,
      handlePayload,
      successMessage,
      viewToast,
      successHandler,
      convertPayload,
      errorHandler,
    ]
  );
}
