import classNames from 'clsx';
import React, { FC, memo } from 'react';
import { FocusEventHandler, GroupTypeBase, ValueType } from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import SelectGroup from '../SelectGroup/SelectGroup';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';
import WitchTooltipSelect from 'components/misc/WithTooltip/WitchTooltipSelect';

const selectStyles = {
  border: `1px`,
  borderRadius: '5px',
};
type TypeProps = {
  id: string;
  label: string;
  placeholder?: string;
  hasError: boolean;
  // Used to display server-side error message, if the server-side error key is not the same as at the front-end side.
  additionalErrorKey?: string;
  isMulti?: boolean;
  options?: GroupTypeBase<IClientSelectOptionV2>[];
  disabled?: boolean;
  required?: boolean;
  onChange: Function;
  onBlur?: FocusEventHandler;
  value?: ValueType<IClientSelectOptionV2, boolean>;
  defaultValue?: ValueType<IClientSelectOptionV2, boolean>;
  highlightEditing?: boolean;
  haveAccessPolicy?: boolean;
};
const SelectGroupField: FC<TypeProps> = (props) => {
  const {
    id,
    label,
    placeholder,
    hasError = false,
    isMulti = false,
    options,
    disabled = false,
    required = false,
    onChange,
    onBlur,
    value,
    defaultValue,
    highlightEditing = false,
    haveAccessPolicy = true,
  } = props;

  const fieldStyles = {
    border: `1px`,
    borderRadius: '5px',
  };
  return (
    <FormGroup className={classNames({ required: required })}>
      <Label id={`${id}_label`} for={id} className="control-label">
        {label}
      </Label>
      <ErrorWrapperField id={id}>
        <WitchTooltipSelect id={id} haveReadPermission={haveAccessPolicy}>
          <SelectGroup
            id={id}
            aria-labelledby={`${id}_label`}
            placeholder={placeholder ? placeholder : label}
            className={classNames('w-100', {
              'is-invalid': hasError,
              borderBackLight: highlightEditing && !hasError,
            })}
            isMulti={isMulti}
            options={options}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            value={value != null ? value : defaultValue}
            required={required}
            selectStyles={selectStyles}
            groupStyles={fieldStyles}
          />
        </WitchTooltipSelect>
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(SelectGroupField);
