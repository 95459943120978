import isMediumPassword, { PASSWORD_MIN_LENGTH } from './isMediumPassword';
import isStrongPassword from './isStrongPassword';

export enum PasswordStrength {
  Weak = 'weak',
  Medium = 'medium',
  Strong = 'strong',
  Broken = 'broken',
}

export const getPasswordStrength = (
  password?: string
): PasswordStrength | '' => {
  if (password === undefined) {
    return '';
  }

  if (isStrongPassword(password)) {
    return PasswordStrength.Strong;
  } else if (isMediumPassword(password)) {
    return PasswordStrength.Medium;
  } else if (password.length >= PASSWORD_MIN_LENGTH) {
    return PasswordStrength.Weak;
  } else if (password.length >= 1) {
    return PasswordStrength.Broken;
  } else {
    return '';
  }
};
