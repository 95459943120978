function randomInteger(min: number, max: number): number {
  return Math.floor(min + Math.random() * (max - min + 1));
}

const createTimestamp = () => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const randomOffset = randomInteger(0, 100);
  return currentTimestamp + randomOffset;
};

export default createTimestamp;
