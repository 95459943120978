import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class WarehouseNote {
  external_request_warehouse_note?: string = '';
  pk?: number;
  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      pk: Yup.number(),
      external_request_warehouse_note: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более символа.')),
    });
  };
}

export default WarehouseNote;
