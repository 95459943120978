import { toast } from 'react-toastify';
import { useCallback } from 'react';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from 'services/utils/catchAndRegError/catchAndRegError';
import TBaseOldPostResponse from 'services/api/interfacesApi/TBaseOldPostResponse';

interface IProps<Params, Payload> {
  convertParams?: (params: any) => Params; //если нужно конвертировать параметры перед отправкой
  fetchApi: (
    params: Params,
    pk?: number | string
  ) => Promise<TBaseOldPostResponse<Payload>>; // функция для запроса
  errorMessage?: string; // свой текст в тосте об ошибке
  successMessage?: string; // свой текст в тосте об успехе
  successHandler?: () => void; // функция после успешного запроса
}

export function usePostOld<Payload, Params>(props: IProps<Params, Payload>) {
  const {
    fetchApi,
    errorMessage = 'Не удалось отправить данные',
    successMessage = 'Данные отправлены успешно',
    successHandler,
    convertParams,
  } = props;

  return useCallback(
    async (
      params: Params,
      setErrors?: Function,
      resetForm?: Function,
      pk?: number | string
    ) => {
      const newParams: Params = convertParams ? convertParams(params) : params;

      const fullErrorMessage = `${errorMessage}. Обратитесь, пожалуйста, к администратору`;
      try {
        const response: TBaseOldPostResponse<Payload> = await fetchApi(
          newParams,
          pk
        );
        if (response?.errors) {
          catchErrorsIfServerStatusFalse(errorMessage, '', setErrors);
          return;
        }

        toast(successMessage);
        successHandler && successHandler();
        resetForm && resetForm({});
      } catch (error) {
        catchErrorFromFetch(fullErrorMessage, error);
      }
    },
    [convertParams, errorMessage, fetchApi, successMessage, successHandler]
  );
}
