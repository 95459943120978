import * as Yup from 'yup';
import { convertClientOptionsToNumbers } from '../services/utils/selects/selects';
import { setFixedOptions } from '../components/controls/DynamicSelectFormikPaginatedFieldFixed/DynamicSelectFormikPaginatedFieldFixed';
import { t } from '../services/utils/translation';
import { SEARCH_IDENTITY_BY } from '../components/identity/EditingEntitiesFromIdentityForm/interfaces/interfaces';
import IClientSelectOptionV2 from '../services/api/interfacesApi/IClientSelectOptionV2';
import { IIdentityState } from '../components/identity/EditingEntitiesFromIdentityForm/interfaces/IIdentityState';
import { regTelephoneNumber } from '../services/utils/myRegex/myRegex';

class IdentityToInstallations {
  public identity_nickname?: string = '';
  public identity_phone_number?: string = '';
  public list_of_installation: IClientSelectOptionV2[] = [];
  public list_of_installation_pks: number[] = [];
  public searchIdentityBy: SEARCH_IDENTITY_BY = SEARCH_IDENTITY_BY.NICKNAME;
  constructor(identity: IIdentityState) {
    this.setInstallationsPks(identity);
    this.setInstallations(identity);
    this.identity_nickname = identity?.identity_nickname;
    this.identity_phone_number = identity?.identity_phone_number;
    this.searchIdentityBy = identity.searchIdentityBy;
  }

  private setInstallationsPks = ({ installations }: IIdentityState) => {
    this.list_of_installation_pks =
      !!installations && installations?.length >= 0
        ? convertClientOptionsToNumbers(installations)
        : [];
  };

  private setInstallations = ({ installations }: IIdentityState) => {
    this.list_of_installation =
      !!installations && installations?.length >= 0
        ? (setFixedOptions(installations) as IClientSelectOptionV2[])
        : [];
  };

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      identity_nickname: Yup.string(),
      identity_phone_number: Yup.string().matches(
        regTelephoneNumber,
        t('Некорректно введен номер телефона')
      ),
    });
  };
}

export default IdentityToInstallations;
