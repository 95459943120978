import { IInvoice } from 'services/api/warehouse/IGetInvoicesResponse';

export class AttachmentInvoiceService {
  public getNumberOfInvoicesFromExternalRequestDetails(
    invoices?: IInvoice[]
  ): number | null {
    if (invoices === undefined) {
      return null;
    }
    return invoices.length;
  }
}
