import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { ValueType } from 'react-select';

/**
 * Convert server select options to the client-side select option format.
 * We're using ReactSelect on client and server responds with Select2 format.
 * @todo: Make server respond with proper format.
 *
 * @param options
 */
export const convertClientOptionsToNumbers = (
  options: IClientSelectOptionV2[]
): number[] => {
  return options.map((option: IClientSelectOptionV2) => option.value);
};

// export function createClientSelectOptionV2<T extends unknown>(
//   value: T,
//   label: string
// ): IClientSelectOptionV2<T>;

// export function createClientSelectOptionV2<T extends unknown>(
//   value?: T,
//   label?: string | undefined
// ): undefined;

// export function createClientSelectOptionV2<T extends unknown>(prop: {
//   value: T;
//   label?: string;
// }): undefined;

export function createClientSelectOptionV2<T>(
  value: T,
  label: string
): IClientSelectOptionV2<T>;
// Перегрузка для случая, когда значения или label не переданы
export function createClientSelectOptionV2<T>(
  value?: T,
  label?: string
): IClientSelectOptionV2<T> | undefined;

// Реализация функции
export function createClientSelectOptionV2<T>(
  value?: T,
  label?: string
): IClientSelectOptionV2<T> | undefined {
  if (value === undefined || label === undefined) {
    return undefined;
  } else {
    return {
      value,
      label,
    };
  }
}

export const extractValueFromClientSelectOptionV2 = <T extends unknown>(
  option: IClientSelectOptionV2<T> | null | undefined
): T | undefined => (option ? option.value : undefined);

export const hasValueInSelect = <T,>(
  value?: ValueType<
    IClientSelectOptionV2<T> | IClientSelectOptionV2<T>[],
    boolean
  > | null
): boolean => {
  if (Array.isArray(value)) {
    return value?.length > 0;
  } else {
    return !!value;
  }
};
