import { IClientDetailBillingAccount } from 'components/tasks/TaskForm/hooks/useGetBillingAccountDetail';
import { IBillingAccount } from 'services/api/organizationsV2/billing-account/IBillingAccount';
import {
  extractCounterpartyFromAccount,
  isLegalStatusCompany,
} from './billingAccountUtils';

const toClientCounterpartyDetailsInBillingAccount = (
  billingAccount: IBillingAccount
) => {
  const detailInfoCounterparty = extractCounterpartyFromAccount(billingAccount);
  const {
    counterparty_company_full_name: companyFullName,
    counterparty_individual_last_name: lastName,
    counterparty_individual_first_name: firstName,
    counterparty_individual_middle_name: middleName,
    counterparty_phone_number: phoneNumber,
    counterparty_pk: counterpartyPk,
  } = detailInfoCounterparty;

  const isCompany = isLegalStatusCompany(detailInfoCounterparty);

  const fullNameCounterparty = isCompany
    ? companyFullName
    : `${lastName} ${firstName} ${middleName || ''}`;

  return {
    pk: counterpartyPk,
    name: fullNameCounterparty,
    phoneNumber,
    isCompany,
  };
};

export const toBillingAccountDetails = (
  billingAccount: IBillingAccount
): IClientDetailBillingAccount => {
  const {
    billing_account_pk: billingAccountPk,
    billing_account_code_number: codeNumber,
    billing_account_address: address,
    billing_account_is_closed: isCLoused,
  } = billingAccount;

  return {
    counterparty: toClientCounterpartyDetailsInBillingAccount(billingAccount),
    billingAccount: {
      codeNumber,
      pk: billingAccountPk,
      address,
      isClosed: isCLoused,
    },
  };
};
