import IBaseSubListEntity from 'components/tables/TDSubList/IBaseSubListEntity';
import { IVehicleType } from 'services/api/vehicle/vehicle-type/IVehicleType';
import toBaseSubListEntity from 'services/utils/toBaseSubListEntity/toBaseSubListEntity';

export interface IVehicleTypeForList {
  vehicle_type_pk: number;
  vehicle_type_datetime_added: string;
  vehicle_type_datetime_edited: string;
  vehicle_type_rendition: string;
  vehicle_type_parent_type_fk: number;
  vehicle_type_list_of_child_types: IBaseSubListEntity[];
}

const toVehicleTypeForList = (taskTypes: IVehicleType[]) =>
  taskTypes.map((taskType: IVehicleType) => ({
    ...taskType,
    vehicle_type_list_of_child_types: toBaseSubListEntity(
      taskType.vehicle_type_list_of_child_types,
      ['vehicle_type_pk', 'vehicle_type_rendition', null]
    ),
  }));

export default toVehicleTypeForList;
