import { BLOCK_NAVIGATION, SET_VALUE_DIFFERENCE } from './actionTypes';
const formReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case BLOCK_NAVIGATION: {
      return {
        ...state,
        hasUnsavedValues: action.payload,
      };
    }
    case SET_VALUE_DIFFERENCE: {
      return {
        ...state,
        diffValues: action.payload,
      };
    }
    default:
      return state;
  }
};

export default formReducer;
