import { ReactComponent as FilterIcon } from 'img/icons/icon-refine.svg';
import React, {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useState,
  memo,
} from 'react';
import {
  Button,
  CustomInput,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { t } from 'services/utils/translation';
import styles from './index.module.scss';

type ColumnFilterProps = {
  onApply?: MouseEventHandler<any>;
};

type PropsType = PropsWithChildren<ColumnFilterProps>;

export const Filter: FC<PropsType> = (props) => {
  const { children, onApply } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
        <DropdownToggle className={`${styles.toggle}`}>
          <FilterIcon className={styles['actions-icon']} />
        </DropdownToggle>
        <DropdownMenu className={`${styles.menu}`}>
          <h1 className={`${styles.header} mb-4`}>{t('Настройка таблицы')}</h1>
          {children}
          <div className="text-right mt-4">
            <Button color="secondary" onClick={toggle} key="cancel">
              {t('Отменить')}
            </Button>{' '}
            <Button
              color="primary"
              onClick={(e) => {
                if (onApply != null) {
                  onApply(e);
                }
                toggle();
              }}
            >
              {t('Применить')}
            </Button>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
type ColumnFilterItemProps = {
  onChange: ChangeEventHandler;
  name: string;
  label: string;
  defaultChecked: boolean;
};

export const Item: FC<ColumnFilterItemProps> = (props) => {
  const { onChange, label, defaultChecked, name } = props;

  return (
    <div className="my-2 d-flex flex-row">
      <CustomInput
        id={name}
        name={name}
        type="checkbox"
        defaultChecked={defaultChecked}
        onChange={onChange}
        label={label}
        className="w-100"
      />
    </div>
  );
};

const ColumnFilterItem = memo(Item);
const ColumnFilter = memo(Filter);

export { ColumnFilterItem, ColumnFilter };
