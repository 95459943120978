import { useMemo } from 'react';
import { clearBillingAccountFilters } from 'store/billingAccount/actions';
import { extractClearCounterpartyBillingAccountFilters } from 'store/counterparty/actions';

const useClearBillingAccountFilters = (counterpartyPk?: number) => {
  return useMemo(() => {
    if (!counterpartyPk) {
      return clearBillingAccountFilters;
    } else {
      return extractClearCounterpartyBillingAccountFilters(counterpartyPk);
    }
  }, [counterpartyPk]);
};

export default useClearBillingAccountFilters;
