import IBaseGetListParams from '../../interfacesApi/IBaseGetListParams';

export enum SortCriteriaAccessPolicy {
  NumberAsc = 'access_policy_pk',
  NumberDesc = '-access_policy_pk',
  NameAsc = 'access_policy_name',
  NameDesc = '-access_policy_name',
  Default = '',
}

export interface IPaginatedParamsAccessPolicy
  extends IBaseGetListParams<SortCriteriaAccessPolicy> {
  access_policy_pk?: number;
  access_policy_pk_array?: number[];
  access_policy_datetime_added_upper_bound?: string;
  access_policy_datetime_added_lower_bound?: string;
  access_policy_datetime_edited_lower_bound?: string;
  access_policy_datetime_edited_upper_bound?: string;
  access_policy_author_dk?: number;
  access_policy_author_dk_array?: number[];
  access_policy_editor_dk?: number;
  access_policy_editor_dk_array?: number[];
  access_policy_name?: string;
  access_policy_name_array?: string[];
  access_policy_description?: string;
  access_policy_description_array?: string[];
  access_policy_fulltext_search?: string;
}
