import { useMemo } from 'react';
import { IVehicleType } from 'services/api/vehicle/vehicle-type/IVehicleType';
import { getPaginatedVehicleTypeApiV2 } from 'services/api/vehicle/vehicle-type/vehicleTypeApi';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import useRead from './useRead';
import { vehicleTypesToGroupOptions } from 'services/utils/convertToGroupOptions/convertToGroupOptions';

const useGetVehicleTypeOptions = () => {
  const initialVehicleTypeParams = useMemo(() => ({ length: 0, skip: 0 }), []);

  const { data: vehicleTypeOptions, isLoading } = useRead({
    getDataApi: getPaginatedVehicleTypeApiV2,
    params: initialVehicleTypeParams,
    convertData: (payload: IBaseGetPaginatedPayload<IVehicleType>) =>
      vehicleTypesToGroupOptions(payload.list_of_entities),

    initialData: [],
  });
  return { vehicleTypeOptions, isLoading };
};

export default useGetVehicleTypeOptions;
