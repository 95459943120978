import useCreateUniqueIdKey from 'hooks/useCreateUniqueIdKey';
import useLocalStorage from 'hooks/useLocalStorage';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LAST_VISITED_PATH from 'services/constants/LocalStorageKeys/LAST_VISITED_PATH';
import { getIdentityPkFromStore } from 'store/auth/selectors';

const useSaveLastPath = () => {
  const identityPk = useSelector(getIdentityPkFromStore);

  const uniqId = useCreateUniqueIdKey();
  const uniqKeyForLatVisitedPath = `${LAST_VISITED_PATH}_${uniqId}`;

  const [lastVisitedPath, setLastVisitedPath] = useLocalStorage({
    key: uniqKeyForLatVisitedPath,
    initialValue: '',
  });

  const location = useLocation();

  const saveLastVisitedPath = useCallback(
    (path?: string) => {
      if (identityPk) {
        const currentPath = location.pathname;
        setLastVisitedPath(path ? path : currentPath);
      }
    },
    [identityPk, location.pathname, setLastVisitedPath]
  );
  const lastVisitedPathSession = sessionStorage.getItem(
    uniqKeyForLatVisitedPath
  );

  return {
    lastVisitedPathSession,
    lastVisitedPath,
    saveLastVisitedPath,
  };
};

export default useSaveLastPath;
