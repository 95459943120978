//@todo refactoring need to be combined into one function
//
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { IKeyValueString } from '../../interfaces/IKeyValue';
import config from '../../../config.json';

export /**
 *
 *
 * @param {string} errorMessage message for client
 * @param {string} JSONMessageFromServer
 * @param {Function} [setErrors] setErrors function from form
 * @return {*}  {void}
 */
const catchErrorsIfServerStatusFalse = (
  errorMessage: string,
  JSONMessageFromServer:
    | IKeyValueString
    | string = 'Empty error field from the server',
  setErrors?: Function
): void => {
  //we may have a response from the server that is not JSON format
  const convertMessageFromServer = JSON.stringify(JSONMessageFromServer)
    ? JSON.stringify(JSONMessageFromServer)
    : JSONMessageFromServer;
  console.error(convertMessageFromServer);
  config.catchErrorsOnWarnings &&
    Sentry.captureException(convertMessageFromServer);

  toast.warn(`${errorMessage}`);

  setErrors && setErrors(convertMessageFromServer);

  return;
};
export /**
 *
 *
 * @param {string} errorMessage message for client
 * @param {ErrorConstructor} error
 * @return {*}  {void}
 */
const catchErrorFromFetch = (
  errorMessage: string,
  error: string | unknown
): void => {
  console.error('Error -> ', error);
  toast.error(errorMessage + '\n' + error);
  Sentry.captureException(error);
  return;
};
