import { IClientAccessPolicyModel } from '../AccessPolicyForm/AccessPolicyForm';
import { useMemo } from 'react';
import { getCountActiveAccessPolicy } from './useGetCountActiveAccessPolicy';

const useGetAllActiveCountInServiceAccessPolicy = (
  codeActions: IClientAccessPolicyModel[]
) =>
  useMemo(() => {
    return codeActions.reduce((acc: number, cur: IClientAccessPolicyModel) => {
      return acc + getCountActiveAccessPolicy(cur.actionPermissions);
    }, 0);
  }, [codeActions]);

export default useGetAllActiveCountInServiceAccessPolicy;
