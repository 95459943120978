import { t } from '../../../../services/utils/translation';

export const COLUMN_LABELS_BILLING_ACCOUNT = {
  NUMBER: t('Номер'),
  DATETIME_ADDED: t('Дата создания'),
  DATETIME_EDITED: t('Дата редактирования'),
  DESCRIPTION: t('Описание'),
  TYPE: t('Тип'),
  ADDRESS: t('Адрес'),
  COUNTERPARTY: t('Контрагент'),
  COMMENT: t('Комментарий'),
  NUMBER_1C: t('1С'),
  NUMBER_STEK: t('СТЕК'),
  NUMBER_1C_AND_STEC: t('1С и СТЕК'),
  IS_CLOSED: t('Статус'),
} as const;

export const billingAccountFilters = [
  { name: 'number', label: COLUMN_LABELS_BILLING_ACCOUNT.NUMBER },
  {
    name: 'datetime_added',
    label: COLUMN_LABELS_BILLING_ACCOUNT.DATETIME_ADDED,
  },
  {
    name: 'datetime_edited',
    label: COLUMN_LABELS_BILLING_ACCOUNT.DATETIME_EDITED,
  },
  { name: 'description', label: COLUMN_LABELS_BILLING_ACCOUNT.DESCRIPTION },
  { name: 'type', label: COLUMN_LABELS_BILLING_ACCOUNT.TYPE },
  { name: 'address', label: COLUMN_LABELS_BILLING_ACCOUNT.ADDRESS },
  { name: 'counterparty', label: COLUMN_LABELS_BILLING_ACCOUNT.COUNTERPARTY },
  { name: 'comment', label: COLUMN_LABELS_BILLING_ACCOUNT.COMMENT },

  {
    name: 'stek_number_and_1c_number',
    label: COLUMN_LABELS_BILLING_ACCOUNT.NUMBER_1C_AND_STEC,
  },
  {
    name: 'isClosed',
    label: COLUMN_LABELS_BILLING_ACCOUNT.IS_CLOSED,
  },
] as const;

export const billingAccountColumnVisibilityInitial = {
  number: true,
  datetime_added: true,
  datetime_edited: true,
  description: true,
  type: true,
  address: true,
  counterparty: true,
  comment: true,
  stek_number_and_1c_number: false,
  isClosed: true,
};
