import { TargetAccessPolicy } from './TargetAccessPolicy';
import { TargetAccessPolicyService } from './TargetAccessPolicyService';

const targetAccessPolicyOrganization = new TargetAccessPolicyService(
  'accounts'
);

export const targetAccessPolicyBillingAccount = new TargetAccessPolicy(
  targetAccessPolicyOrganization,
  'BillingAccount'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyBillingAccountType = new TargetAccessPolicy(
  targetAccessPolicyOrganization,
  'BillingAccountType'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyCounterparty = new TargetAccessPolicy(
  targetAccessPolicyOrganization,
  'Counterparty'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyEvent = new TargetAccessPolicy(
  targetAccessPolicyOrganization,
  'Event'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyEventType = new TargetAccessPolicy(
  targetAccessPolicyOrganization,
  'EventType'
).getTargetAccessPolicyStatement();
