import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import React, { FC, memo, useEffect, useState } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';
import { Label, InputGroup } from 'reactstrap';
import TaskStatus from 'models/TaskStatus';
import ColoredStatusLabel from '../../misc/ColoredStatusLabel';
import { getColorFromHEX } from 'components/tables/misc/colorTr';
import { THexToColorsKeys } from '../../tables/misc/colorTr';
import { usePostV3 } from '../../../hooks/usePostV3';

import {
  IUpdateTaskStatusParams,
  ICreateTaskStatusParams,
} from '../../../services/api/tasks/task-statusesV2/IUpdateTaskStatusParams';
import {
  createTaskStatusesApiV2,
  updateTaskStatusesApiV2,
} from '../../../services/api/tasks/task-statusesV2/taskSatusesV2';
import { colorsMap } from '../../../services/constants/UI_COLORS';

interface IColor {
  backgroundColor: THexToColorsKeys;
}
const toFormValuesUpdate = (
  status: IFormValuesUpdate
): IUpdateTaskStatusParams => ({
  internal_task_status_pk: status?.id,
  internal_task_status_rendition: status?.name,
  internal_task_status_color: status?.color,
  internal_task_status_can_be_first: status?.can_be_first,
  internal_task_status_can_be_last: status?.can_be_last,
});

const toFormValuesCreate = (status: IFormValues): ICreateTaskStatusParams => ({
  internal_task_status_rendition: status?.name,
  internal_task_status_color: status?.color,
  internal_task_status_can_be_first: status?.can_be_first,
  internal_task_status_can_be_last: status?.can_be_last,
});

interface IFormValues {
  id?: number | null;
  name: string;
  color?: string;
  can_be_first?: boolean;
  can_be_last?: boolean;
  order?: number;
}

interface IFormValuesUpdate extends IFormValues {
  id: number;
}

export interface IClientStatus {
  name: string;
  color: THexToColorsKeys;
  id?: number;
}
type PropsType = {
  successHandler: () => void;
  status: IClientStatus | null;
};

const stylesColor: React.CSSProperties = {
  borderRadius: '4px',
  width: '30px',
  height: '30px',
  display: 'inline-block',
  marginRight: '10px',
  marginBottom: '10px',
  textDecoration: 'nome',
  cursor: 'pointer',
};
const FormStatuses: FC<PropsType> = (props) => {
  const { successHandler } = props;
  const statusProp: IClientStatus | null = props.status;
  const [status, setStatus] = useState<IFormValues>(new TaskStatus());

  const [nameStatus, setNameStatus] = useState<string>('');

  const [colors, setColors] = useState<IColor[]>([]);
  const [colorsValue, setColorsValue] = useState<THexToColorsKeys | undefined>(
    '#7F8C8D'
  );

  useEffect(() => {
    const colorsStatuses = (
      Object.keys(colorsMap) as Array<keyof typeof colorsMap>
    ).map((el: keyof typeof colorsMap) => ({
      backgroundColor: el,
    }));

    setColors(colorsStatuses);
    if (statusProp && statusProp.id != null) {
      setNameStatus(statusProp?.name);
      setColorsValue(statusProp?.color);
    } else {
      setColorsValue('#7F8C8D');
    }
  }, [statusProp]);

  useEffect(() => {
    return () => {
      setColorsValue('');
      setStatus(new TaskStatus());
      setNameStatus('');
    };
  }, []);

  const updateStatus = usePostV3({
    fetchApi: updateTaskStatusesApiV2,
    successHandler,
    successMessage: t('Статус успешно обновлен.'),
  });

  const createStatus = usePostV3({
    fetchApi: createTaskStatusesApiV2,
    successHandler,
    successMessage: t('Статус создан успешно.'),
  });

  useEffect(() => {
    if (statusProp && statusProp.name != null) {
      setStatus(statusProp);
    }
  }, [statusProp]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: statusProp?.id ? statusProp.id : null,
        name: nameStatus,
        color: colorsValue,
        can_be_first: status.can_be_first,
        can_be_last: status.can_be_last,
        order: status.order,
      }}
      validationSchema={TaskStatus.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        if (values.id == null) {
          const newValues = toFormValuesCreate(values as IFormValues);

          await createStatus(newValues, setErrors, resetForm);
        } else if (values.id) {
          const newValues = toFormValuesUpdate(values as IFormValuesUpdate);

          await updateStatus(newValues, setErrors, resetForm);
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} noValidate={true}>
          {values.id && (
            <HiddenField
              id="id"
              value={values.id}
              hasError={errors.id != null && touched.id != null}
            />
          )}

          <InputGroup>
            <table className="table table-bordered" style={{ width: '40%' }}>
              <tbody>
                <tr className={`table-${getColorFromHEX(colorsValue)}`}>
                  <td>
                    <ColoredStatusLabel color={colorsValue}>
                      {nameStatus}
                    </ColoredStatusLabel>
                  </td>
                </tr>
              </tbody>
            </table>
          </InputGroup>
          <TextField
            type="text"
            id="name"
            label={t('Статус')}
            placeholder={t('Название статуса')}
            hasError={errors.name != null && touched.name != null}
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.name}
            required={true}
            setName={setNameStatus}
          />

          <Label>Выбрать цвет</Label>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {colors.map((color: IColor, i: number) => (
              <div
                id="color"
                onChange={() =>
                  setFieldValue('color', colors[i].backgroundColor)
                }
                key={color.backgroundColor + i}
                style={{ ...stylesColor, ...color }}
                onClick={() => setColorsValue(colors[i].backgroundColor)}
              />
            ))}
          </div>

          <FormGroup className="d-block text-right mt-4">
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {t('Сохранить')}
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};
const TaskStatusForm = memo(FormStatuses);
export default TaskStatusForm;
