import { toast } from 'react-toastify';
import { clearExport } from '../../store/export/actions';
import { v4 as uuidv4 } from 'uuid';
import { INotification } from '../../services/api/notification/IresponseGetNotifications';
import ButtonLink from 'components/controls/ButtonLink/ButtonLink';
import styles from './index.module.scss';
import { t } from '../../services/utils/translation';
import { Dispatch } from 'redux';

const DOWNLOAD_FILE_TEXT = t('Скачать файл');

export const viewToastWithExportLink = (
  dispatch: Dispatch,
  message: string
) => {
  const notification: Omit<INotification, 'notification_pk'> = JSON.parse(
    decodeURIComponent(message)
  );
  const exportLink = notification?.notification_payload;

  const toastId = uuidv4();

  const handleClick = () => {
    window.location.assign(exportLink);
    dispatch(clearExport());
    toast.dismiss(toastId);
  };

  const Link = (
    <div className={styles.linkWrapper}>
      <ButtonLink
        handleClick={handleClick}
        text={DOWNLOAD_FILE_TEXT}
        classNameCustom={styles.link}
        classNameText={styles.linkText}
      />
    </div>
  );
  toast.success(Link, {
    autoClose: false,
    onClose: () => dispatch(clearExport()),
    toastId,
  });
};

export default viewToastWithExportLink;
