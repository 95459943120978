import { formatDateTimeShort } from '../../../../services/utils/dateHelper/dateHelper';
const saveScreenshot = (blob: Blob, installationName?: string) => {
  const url = URL.createObjectURL(blob);
  const now = formatDateTimeShort(new Date());
  const fileName = `${
    installationName ? installationName : 'screenshot'
  }_${now.replace(/:/g, '-')}.png`;

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default saveScreenshot;
