import {
  IEventFilters,
  IGetPaginatedEventParams,
} from 'services/api/organizationsV2/event/IGetPaginatedParamsEvent';
import { composeClientSelectOptionsInNumbersArray } from 'services/utils/selects/composeIClientSelectOptionsInNumbers';
import trimStringInObject from 'services/utils/trimStringInObject/trimStringInObject';
import { IKeyValue } from 'services/interfaces/IKeyValue';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { extractValueFromClientSelectOptionV2 } from 'services/utils/selects/selects';

export const composeParamsGetEvent = (
  params: IEventFilters
): IGetPaginatedEventParams => {
  const {
    event_type_fk_array,
    event_billing_account_fk_array,
  }: IKeyValue<number[]> = composeClientSelectOptionsInNumbersArray(params, [
    'event_type_fk_array',
    'event_billing_account_fk_array',
  ]);

  const newParams = trimStringInObject({
    ...params,
    event_type_fk_array,
    event_billing_account_fk_array,
    event_is_closed: extractValueFromClientSelectOptionV2(
      params?.event_is_closed
    ),
  });

  return deleteUnnecessaryParameters(newParams, undefined, ['event_is_closed']);
};
