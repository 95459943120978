import TaskTypeForm from 'components/tasks/TaskTypeForm';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import { useColumnFilter } from 'hooks/useColumnFilter';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import React, { FC, useState } from 'react';
import { t } from 'services/utils/translation';

import { IModal } from '../../../../hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { useGetList, IUseGetList } from '../../../../hooks/useGetList';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import {
  deleteTaskTypeV2,
  getPaginatedTaskTypeApiV2,
} from '../../../../services/api/tasks/task-typeV2/task-typeV2';
import { Pager } from 'components/tables/Pager';
import useModalV2 from 'hooks/useModalV2';
import IGetPaginatedTaskTypesParams from '../../../../services/api/tasks/task-typeV2/IGetPaginatedTaskTypesParams';
import config from '../../../../config.json';
import toTaskTypesForList, { ITaskTypeForList } from '../toTaskTypesForList';
import TDSubList from 'components/tables/TDSubList/TDSubList';
import classNames from 'clsx';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { UI_TITLE } from '../../../../services/localLocalization/UITitle';
import { targetAccessPolicyTaskType } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import { extractGetLeftValueIfTrue } from '../../../../services/utils/extractGetLeftValueIfTrue';
import { getHintForDropdownDelete } from '../../../../services/utils/extractGetLeftValueIfTrue';

const deleteMessageError = t('Не удалось удалить тип задачи');
const CREATE_BUTTON_TITLE = t('Создать тип');
const MODAL_EDITING_TITLE = t('Редактировать тип задачи');
const MODAL_CREATE_TITLE = t('Создать тип задачи');
const getTitle = extractGetLeftValueIfTrue(
  MODAL_EDITING_TITLE,
  MODAL_CREATE_TITLE
);

const COLUMN_LABELS = {
  PK: t('№'),
  TYPE: t('Тип'),
  CHILDREN_TYPE: t('Подтип'),
};

const filters = [
  { name: 'pk', label: COLUMN_LABELS.PK },
  { name: 'type', label: COLUMN_LABELS.TYPE },
] as const;
const columnVisibilityInitial = {
  pk: true,
  type: true,
};

const TaskTypePage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial, 'taskTypeColumnFilter');

  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyTaskType,
      targetAction: ['U', 'C', 'D'],
    });

  const [initialParams] = useState<IGetPaginatedTaskTypesParams>({
    skip: 0,
    length: config.pageLength,
  });

  const {
    data: taskTypes,
    isLoading,
    refreshListData,
    onSearchRequest,
    onSortClick,
    setStart,
    setLength,
    params: { skip, length, internal_task_type_pk: filterPk },
    total,
  }: IUseGetList<ITaskTypeForList[], IGetPaginatedTaskTypesParams> = useGetList(
    {
      getDataApi: getPaginatedTaskTypeApiV2,
      initialParams,
      convertData: toTaskTypesForList,
    }
  );

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetTaskType },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteTaskTypeV2,
    entityIdKey: 'pk',
  });

  return (
    <MainLayout>
      <Subheader>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <AddIcon className="mr-2 text-white" /> {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={`id pr-0 ${!visibility.pk ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field="internal_task_type_pk"
                  title={COLUMN_LABELS.PK}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'internal_task_type_pk');
                  }}
                  onSort={onSortClick}
                  defaultValue={filterPk ? filterPk + '' : ''}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${!visibility.type ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field="internal_task_type_rendition"
                  title={COLUMN_LABELS.TYPE}
                  onSearch={(searchString: string) => {
                    onSearchRequest(
                      searchString,
                      'internal_task_type_rendition'
                    );
                  }}
                  onSort={onSortClick}
                />
              </th>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {taskTypes &&
                taskTypes.map((taskType: ITaskTypeForList) => {
                  const {
                    internal_task_type_pk: pk,
                    internal_task_type_list_of_child_types: listChild,
                    internal_task_type_rendition: rendition,
                  } = taskType;
                  return (
                    <tr key={pk}>
                      <td className={classNames({ 'd-none': !visibility.pk })}>
                        {pk}
                      </td>
                      <TDSubList
                        openEditForm={openAddEditForm}
                        openDeleteForm={toggleDeleteForm}
                        listChild={listChild}
                        subListParentName={rendition}
                        visibility={visibility.type}
                      />

                      <td
                        align="right"
                        className={classNames({ 'd-none': !visibility.type })}
                      >
                        <ActionsDropdown>
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={UI_TITLE.EDIT}
                              onClick={openAddEditForm.bind(null, {
                                pk,
                                rendition,
                              })}
                              icon={<EditIcon className="text-primary" />}
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                rendition,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                              disabled={listChild?.length > 0}
                              hint={getHintForDropdownDelete(
                                listChild?.length > 0
                              )}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />

        <LoaderFetch />
        <ModalWrapper
          headerLabel={getTitle(!!targetTaskType?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <TaskTypeForm
            successHandler={successHandler}
            pk={targetTaskType?.pk}
          />
        </ModalWrapper>
        {targetTaskType?.pk && (
          <PopupDeleteForm
            headerLabel={t('Удалить тип задач')}
            entityId={targetTaskType?.pk}
            entityName={targetTaskType?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default TaskTypePage;
