import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

// A wrapper for <Route> that redirects to the frontpage
// screen if user is authenticated.
const GuestRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector((state: RootStateOrAny) => state.auth.loggedIn);
  return !loggedIn ? <Outlet /> : <Navigate to="/" replace />;
};

export default GuestRoute;
