import { IIdentityListOfApplicableInstallationsEntity } from 'services/api/auth/IRegisterIdentity';

export const getInstallationsFromIdentity = (
  listInstallation: IIdentityListOfApplicableInstallationsEntity[] | undefined
) =>
  listInstallation
    ? listInstallation.map(
        (installation: IIdentityListOfApplicableInstallationsEntity) => ({
          label: installation.installation_company_name,
          value: installation.installation_pk,
        })
      )
    : [];
