import { ReactComponent as SearchIcon } from 'img/icons/icon-loupe.svg';
import React, { FC, memo, useCallback, useState } from 'react';
import classNames from 'clsx';
import styles from './index.module.scss';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';
import { TMods } from '../../../services/types/TMods';
import { isDigitalValue } from 'services/utils/stringHelper/stringHelper';

type PropsType = {
  onSearch: (searchString: string) => void;
  title: string;
  className?: string;
  defaultValue?: string;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
  headerColor?: TABLE_COLOR;
  isOnlyNumbers?: boolean;
};

const HeaderWithSearch: FC<PropsType> = (props) => {
  const {
    onSearch,
    title,
    className,
    defaultValue,
    customStyle,
    disabled,
    headerColor = TABLE_COLOR.DEFAULT,
    isOnlyNumbers,
  } = props;
  const [searchActive, setSearchActive] = useState(false);

  const modsLabel: TMods = {
    [styles.borderBackLight]: defaultValue && defaultValue?.length > 0,
    [styles.wrapper]: !defaultValue,
    [styles.grey]: TABLE_COLOR?.GREY === headerColor,
  } as const;

  const handleSearch = useCallback(
    (e: any) => {
      const value = e.currentTarget.value;
      if (
        !isOnlyNumbers ||
        (isOnlyNumbers && isDigitalValue(value)) ||
        value.length === 0
      ) {
        const isHasValue = value.length > 0;
        setSearchActive(isHasValue);
        onSearch(value);
      }
    },
    [isOnlyNumbers, onSearch]
  );

  return (
    <label
      className={classNames(
        ` d-flex flex-row justify-content-start
        align-items-center  ${searchActive ? styles.active : ''} ${
          className ? className : ''
        }`,
        modsLabel
      )}
      style={customStyle}
    >
      {!defaultValue && (
        <span className={styles.thIcon}>
          <SearchIcon />
        </span>
      )}

      <span
        className={classNames(styles.title, {
          [styles.grey]: TABLE_COLOR?.GREY === headerColor,
        })}
      >
        {defaultValue}
      </span>
      <div
        className={classNames(styles.form, {
          [styles.grey]: TABLE_COLOR?.GREY === headerColor,
        })}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          value={defaultValue}
          className={classNames(styles.keyword, styles.value, {
            [styles.grey]: TABLE_COLOR?.GREY === headerColor,
            [styles.value]: !!defaultValue,
          })}
          placeholder={title}
          onChange={handleSearch}
          disabled={disabled}
        />
      </div>
    </label>
  );
};

export default memo(HeaderWithSearch);
