import { useSelector } from 'react-redux';
import useLoadCurrentEmployee from './useLoadCurrentEmployee';
import useLoadLocalization from './useLoadLocalization';
import useLoadMenu from './useLoadMenu/useLoadMenu';
import useLoadMyFavoriteListFolder from './useLoadMyFavoriteListFolder';
import useNotifications from './useNotifications';
import { getInstallationPkFromStore } from '../store/auth/selectors';

const useInitializationInInstallation = () => {
  const authDomain = useSelector(getInstallationPkFromStore);
  useNotifications();
  const { isLoading: isLoadingCurrentEmployee } =
    useLoadCurrentEmployee(authDomain);
  const { isLoading: isLoadingLocalization, hasLocalization } =
    useLoadLocalization(authDomain);

  const isLoading = isLoadingLocalization || isLoadingCurrentEmployee;
  // We can only show the menu if we have an employee and localization
  useLoadMenu(authDomain, hasLocalization);
  useLoadMyFavoriteListFolder();
  return [isLoading];
};

export default useInitializationInInstallation;
