import { FC, memo } from 'react';
import { PATH_TASK_EDIT } from 'services/pathConstants';
import { makeLink } from 'services/localPaths';
import { Link, useLocation } from 'react-router-dom';
import useDelayedHover from 'hooks/useDelayedHover';
import useShowAttachmentOnHover from 'hooks/useShowAttachmentOnHover/useShowAttachmentOnHover';
import TooltipWitchLoading from 'components/misc/Tooltip/TooltipWitchLoading/TooltipWitchLoading';
import { parentUIElement } from 'services/constants/parentUIElement';

type CellWithPkTaskPropsType = {
  pk: number;
};

export const CellWithPkTask: FC<CellWithPkTaskPropsType> = (props) => {
  const { pk } = props;
  const location = useLocation();

  const { isHovered, handleMouseLeave, handleMouseEnter } =
    useDelayedHover(500);

  const { descriptionAttachment, isLoading } = useShowAttachmentOnHover({
      isHovered,
      pk,
      parentUIName: parentUIElement.task,
  });

  return (
    <>
      <Link
        id={`tooltip-attachment-${pk}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        to={{
          pathname: makeLink(PATH_TASK_EDIT, pk),
        }}
        state={{ from: location }}
      >
        {pk}
      </Link>
      <TooltipWitchLoading
        id={pk}
        isLoading={isLoading}
        text={descriptionAttachment}
      />
    </>
  );
};
export default memo(CellWithPkTask);
