import React, { FC, useState } from 'react';
import classNames from 'clsx';
import { Formik } from 'formik';
import { Button, Form } from 'reactstrap';

import { t } from 'services/utils/translation';

import styles from './index.module.scss';
import getFormDataStepper from './services/getFormData';
import VerifyOtp from '../../../models/VerifyOtp';
import TextField from 'components/controls/TextField';
import { usePostStepper } from '../../../hooks/usePostStepper';
import { verifyOtp } from 'services/api/auth/authApiV2';
import PhoneField from 'components/controls/PhoneField/PhoneField';
import CookieManager from 'models/CoockieManager/CookieManager';
import PhoneNumberJwtCookie from 'models/CoockieManager/PhoneNumberJwtCookie/PhoneNumberJwtCookie';

const RegistrationSecondStep: FC = () => {
  const phoneNumber: string = getFormDataStepper().phone_number;
  const [initialValues] = useState<VerifyOtp>(new VerifyOtp(phoneNumber));
  const handlePayload = (payload: string) => {
    const cookieManger = new CookieManager();
    const phoneNumberJwtCookie = new PhoneNumberJwtCookie(cookieManger);

    phoneNumberJwtCookie.setPhoneNumberJwt(payload);
  };

  const onSubmit = usePostStepper({
    fetchApi: verifyOtp,
    activeStep: 2,
    handlePayload: handlePayload,
    successMessage: t('Вы успешно подтвердили код'),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={VerifyOtp.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        await onSubmit(values, setErrors);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form
          onSubmit={handleSubmit}
          noValidate={true}
          className={classNames(styles['registration-form'])}
        >
          <TextField
            id="otp"
            label={t('Код подтверждения')}
            hasError={errors.otp != null && touched.otp != null}
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.otp}
          />
          <PhoneField
            id="phone_number"
            label={t('Телефон')}
            hasError={
              errors.phone_number != null && touched.phone_number != null
            }
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            value={values.phone_number}
            defaultValue={values.phone_number}
            disabled={true}
          />

          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            className="btn btn-lg btn-primary btn-block"
          >
            {t('Отправить код')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationSecondStep;
