export type UniformChildType = {
  pk: number;
  description: string;
};

export type ChildrenEntity<T> = {
  [K in keyof T]: number | string;
};

export const toUniformChild =  <T,>(child: ChildrenEntity<T>) => {
  let newObj: Partial<UniformChildType> = {};
  for (let key in child) {
    if (child.hasOwnProperty(key)) {
      const value: string | number = child[key];
      if (typeof value === 'number') {
        newObj['pk'] = value;
      }
      if (typeof value === 'string') {
        newObj['description'] = value;
      }
    }
  }
  return newObj as UniformChildType;
};

export const toUniformListChild = <T,>(
  listChild: ChildrenEntity<T>[]
): UniformChildType[] => {
  return listChild.reduce(
    (acc: UniformChildType[], currentValue: ChildrenEntity<T>) => {
      let newObj = toUniformChild(currentValue);
      acc.push(newObj as UniformChildType);
      return acc;
    },
    []
  );
};
