import { t } from '../../services/utils/translation';
import {
  CLEAR_TASK_FIELDS_VALUE,
  GET_TASK_V2,
  SET_ACCESS_POLICY_TASK,
  SET_DEFAULT_STATUS,
  SET_INITIAL_TASK,
  SET_IS_ACTIVE_COUNTERPARTY_FILTER,
  SET_MARKED_TASK,
  SET_TASK_FIELDS_VALUE,
  TASKS_CLEAR_FILTERS,
  TASKS_SAVE_FILTERS,
} from './actionTypes';
 import { IFormValuesTask } from '../../components/tasks/TaskForm/TaskForm';
import { initialTasksFilters } from './initialTasksFilters';
import { ITaskFilters } from '../../services/api/tasks/tasks/IGetPaginatedTasksParams';
import { ERROR_ACTION } from '../general/actionTypes';
import { ITaskClientAccessPolicy } from './initialTask';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

//FILTERS

export const saveTasksFilters = (filters: ITaskFilters) => ({
  type: TASKS_SAVE_FILTERS,
  filters,
});

export const clearTasksFilters = () => ({
  type: TASKS_CLEAR_FILTERS,
  payload: initialTasksFilters,
});

export const setIsActiveCounterpartyFilter = (
  isActiveCounterpartyFilter: boolean
) => ({
  type: SET_IS_ACTIVE_COUNTERPARTY_FILTER,
  payload: isActiveCounterpartyFilter,
});

export const setMarkedTask = (id: number) => {
  const errorMessage = t(
    'Что-то пошло не так. Пожалуйста, свяжитесь с администратором. '
  );
  try {
    return {
      type: SET_MARKED_TASK,
      payload: id,
    };
  } catch (e) {
    return {
      type: ERROR_ACTION,
      payload: { errorMessage, e },
    };
  }
};

//FORM VALUES

export const saveTaskFieldValue = (payload?: IFormValuesTask) => ({
  type: SET_TASK_FIELDS_VALUE,
  payload,
});

export const setInitialTask = (formValues: IFormValuesTask) => {
  return {
    type: SET_INITIAL_TASK,
    payload: formValues,
  };
};

export const saveTaskInStore = (formValues: IFormValuesTask) => ({
  type: GET_TASK_V2,
  payload: formValues,
});

export const clearTaskFieldValue = () => ({
  type: CLEAR_TASK_FIELDS_VALUE,
});

export const setDefaultStatus = (
  payload: IClientSelectOptionV2<number> | undefined
) => ({
  type: SET_DEFAULT_STATUS,
  payload,
});

// OTHER

export const setTaskAccessPolicy = (payload: ITaskClientAccessPolicy) => ({
  type: SET_ACCESS_POLICY_TASK,
  payload,
});
