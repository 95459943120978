/**
 * API section - services.
 */

import CentralRegistryApiClient from '../CentralRegistryApiClient';
import IBaseParams from '../interfacesApi/IBaseParams';
import { IBaseResponse } from '../interfacesApi/IBaseResponse';
import { IObtainAccessToken } from './IObtainAccessToken';

export const getObtainAccessTokenApiV3 = async (
  params: IBaseParams<IObtainAccessToken>
): Promise<IBaseResponse<string>> => {
  const api = new CentralRegistryApiClient();
  return await api.postAttr('obtain-access-token', params);
};
