import { IFileBucketSchema } from 'services/api/documents/file-bucket-schema/IFileBucketSchema';
import { IFileBucketSchemaFormValues } from '../FileBucketSchemaFrom';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';

const extractConvertFileBucketSchemaToInitialValues =
  (roles: Record<string, string>) =>
  (fileBucketSchema?: IFileBucketSchema): IFileBucketSchemaFormValues => {
    const accessPolicies = fileBucketSchema?.access_policies
      ? fileBucketSchema?.access_policies.map((pk: number) => {
          return createClientSelectOptionV2(pk, roles[pk]);
        })
      : [];

    return { accessPolicies, ...fileBucketSchema };
  };

export default extractConvertFileBucketSchemaToInitialValues;
