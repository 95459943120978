import { RootStateOrAny } from 'react-redux';
import { ITaskFilters } from '../../services/api/tasks/tasks/IGetPaginatedTasksParams';
import { IFormValuesTask } from '../../components/tasks/TaskForm/TaskForm';
import Task from '../../models/Task';
import IClientSelectOptionV2 from '../../services/api/interfacesApi/IClientSelectOptionV2';
import { ITaskClientAccessPolicy } from './initialTask';
import { RootState } from 'store/IRootState';

export const getTaskMarkedFromStore = (state: RootState) => state.tasks.marked;
export const getTaskFieldsValueFromStore = (state: RootState): Task =>
  state.tasks.fieldValues;
export const getInitialTaskFromStore = (state: RootState): IFormValuesTask =>
  state.tasks.initialTask;
export const getTaskFilters = (state: RootStateOrAny): ITaskFilters =>
  state.tasks.tableFilters;

export const getDefaultStatusFromStore = (
  state: RootState
): IClientSelectOptionV2<number> | undefined => state.tasks.defaultStatus;

export const getTaskAccessPolicyFromStore = (
  state: RootState
): ITaskClientAccessPolicy => state.tasks.taskAccessPolicy.accessPolicy;

export const getTaskIsInstallAccessPolicyFromStore = (
  state: RootState
): boolean => state.tasks.taskAccessPolicy.isInstalled;
