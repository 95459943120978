import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { initialTasksFilters } from './initialTasksFilters';
import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import { IMarkedItem } from 'store/utils/addUniqItems';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

export interface ITaskClientAccessPolicy {
  employeeHaveAccessToRead: boolean;
  billingAccountHaveAccessToRead: boolean;
  taskStatusHaveAccessToRead: boolean;
  taskTypeHaveAccessToRead: boolean;
  task: {
    haveAccessToCreate: boolean;
    haveAccessToExport: boolean;
    haveAccessToArchive: boolean;
    haveAccessToUpdate: boolean;
  };
  counterpartyHaveAccessToRead: boolean;
}
interface ITaskClientAccessPolicyStore {
  isInstalled: boolean;
  accessPolicy: ITaskClientAccessPolicy;
}

const initialAccessPolicy: ITaskClientAccessPolicyStore = {
  isInstalled: false,
  accessPolicy: {
    employeeHaveAccessToRead: false,
    billingAccountHaveAccessToRead: false,
    counterpartyHaveAccessToRead: false,
    taskStatusHaveAccessToRead: false,
    taskTypeHaveAccessToRead: false,
    task: {
      haveAccessToCreate: false,
      haveAccessToExport: false,
      haveAccessToArchive: false,
      haveAccessToUpdate: false,
    },
  },
};

export interface IInitialTaskStore {
  tableFilters: ITaskFilters;
  marked: IMarkedItem[];
  fieldValues: IFormValuesTask;
  isLoading: boolean;
  hasUnsavedValues: boolean;
  initialTask: IFormValuesTask;
  defaultStatus?: IClientSelectOptionV2<number>;
  taskAccessPolicy: ITaskClientAccessPolicyStore;
}

export const initialTaskState: IInitialTaskStore = {
  tableFilters: initialTasksFilters,
  marked: [],
  fieldValues: {},
  isLoading: false,
  hasUnsavedValues: false,
  initialTask: {},
  defaultStatus: undefined,
  taskAccessPolicy: initialAccessPolicy,
};
