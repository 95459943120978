import { FC, memo } from 'react';
import { Formik } from 'formik';
import { usePostV3 } from 'hooks/usePostV3';
import { setPasswordViaAdmin } from 'services/api/identity/identity';
import { Form, FormGroup, Button } from 'reactstrap';
import { t } from 'services/utils/translation';
import TextField from 'components/controls/TextField';
import SetPasswordViaAdmin from 'models/SetPasswordViaAdmin';
import PasswordField from 'components/controls/PasswordField/PasswordField';

type PropsType = {
  successHandler: () => void;
};

const SetPasswordViaAdminForm: FC<PropsType> = ({ successHandler }) => {
  const setPassword = usePostV3({
    fetchApi: setPasswordViaAdmin,
    successHandler,
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={new SetPasswordViaAdmin()}
      validationSchema={SetPasswordViaAdmin.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        await setPassword(values, setErrors);

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} noValidate={true}>
          <TextField
            id="nickname"
            label={t('Логин пользователя')}
            placeholder={t('Введите логин')}
            hasError={errors.nickname != null && touched.nickname != null}
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.nickname}
          />
          <PasswordField
            id="plain_password"
            label={t('Новый Пароль')}
            placeholder={t('Введите пароль')}
            hasError={
              errors.plain_password != null && touched.plain_password != null
            }
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.plain_password}
            checkDifficulty
          />
          <FormGroup className="d-block text-right mt-4">
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {t('Сохранить')}
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default memo(SetPasswordViaAdminForm);
