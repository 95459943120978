import { useCallback } from 'react';
import { IFormValuesEvent } from '../../../../../components/organizations/event/EventForm/EventForm';
import { toFormValuesEvent } from '../utils/toFormValuesEvent/toFormValuesEvent';
import { IEventWrapper } from 'services/api/organizationsV2/event/IEventWrapper';

export const useToFormValuesEvent = () => {
  return useCallback((payload: IEventWrapper): IFormValuesEvent => {
    return toFormValuesEvent(payload.event);
  }, []);
};
