import classNames from 'clsx';
import { FC, useState, PropsWithChildren } from 'react';
import { Tooltip } from 'reactstrap';
import IconWitchDescriptionPropsType from './IconWitchDescriptionPropsType';

const IconWitchDescription: FC<
  PropsWithChildren<IconWitchDescriptionPropsType>
> = (props) => {
  const { children, className, targetId, hintText } = props;

  const target = `tooltip-${targetId ? targetId : ''}`;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      {hintText && (
        <Tooltip target={target} isOpen={tooltipOpen} toggle={toggle}>
          {hintText}
        </Tooltip>
      )}
      <div id={target} className={classNames(className)}>
        {children}
      </div>
    </>
  );
};

export default IconWitchDescription;
