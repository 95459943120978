import CentralRegistryApiClient from '../CentralRegistryApiClient';
import { IForSelectResponseV2 } from '../../../components/controls/DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';
import createTimestamp from 'services/utils/createTimestamp/createTimestamp';
import config from 'config.json';
import { IForSelectRequestV2 } from '../../utils/generateMethodForSelect/generateMethodForSelect';
import IBaseParams from '../interfacesApi/IBaseParams';
import { IBaseResponse } from '../interfacesApi/IBaseResponse';

//TODO: need refactoring; there is a method for for-select in UndercurrentApiClient

export const getInstallationsForSelect = async (
  params: IBaseParams<IForSelectRequestV2>
): Promise<IBaseResponse<IForSelectResponseV2>> => {
  const clearParams = { search: params.params.search };
  const api = new CentralRegistryApiClient();
  const bodyParams = {
    id: createTimestamp(),
    params: clearParams,
  };

  return api.postJson(
    `installation/forselect-read/${params.params.page}/${config.forSelectLength}`,
    bodyParams
  );
};
