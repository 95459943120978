import TextField from 'components/controls/TextField';
import { useFormikContext } from 'formik';
import { ChangeEvent, FC, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { InputGroup } from 'reactstrap';
import { t } from 'services/utils/translation';
import fieldTransition from '../../../styles/animaion/fieldTransition.module.scss';
import PhoneField from 'components/controls/PhoneField/PhoneField';
import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import RadiosField from 'components/controls/RadiosField';
import { SEARCH_IDENTITY_BY } from '../EditingEntitiesFromIdentityForm/interfaces/interfaces';
import { IIdentityState } from '../EditingEntitiesFromIdentityForm/interfaces/IIdentityState';
import IdentityToInstallations from 'models/IdentityToInstallations';
import ListOfIdentitiesToSelect from '../ListOfIdentitiesToSelect/ListOfIdentitiesToSelect';
import {
  IIdentity,
  InstallationListKeys,
} from 'services/api/auth/IRegisterIdentity';
import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIdentityInControlPanel } from 'store/identity/actions';
import { getIdentityInControlPanelFromStore } from 'store/identity/selectors';
import { IButtonAddon } from 'services/interfaces/IButtonAddon';
import { initialIdentityInControlPanel } from '../../../store/initialStore/initialIdentityInControlPanel';

const BUTTON_ADDON_TEXT_CHECK = t('Проверить наличие');
const BUTTON_ADDON_TEXT_CHOICE = t('Выберите пользователя');

const getButtonAddonText = extractGetLeftValueIfTrue(
  BUTTON_ADDON_TEXT_CHOICE,
  BUTTON_ADDON_TEXT_CHECK
);

type PropsType = {
  handleClickAddon: () => void;
  typeInstallations: InstallationListKeys;
};

const IsMoreThanOneIdentity = ({
  listIdentity,
  hasIdentity,
}: IIdentityState): boolean =>
  !!listIdentity && listIdentity.length > 1 && !hasIdentity;

const FindIdentity: FC<PropsType> = (props) => {
  const { handleClickAddon, typeInstallations } = props;
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext<IdentityToInstallations>();
  const dispatch = useDispatch();

  const identity = useSelector(getIdentityInControlPanelFromStore);

  const handlePerformerTypeChange = useCallback(
    async (searchIdentityBy: SEARCH_IDENTITY_BY) => {
      if (searchIdentityBy === SEARCH_IDENTITY_BY.NICKNAME) {
       await  setFieldValue('searchIdentityBy', searchIdentityBy, false);
       await  setFieldValue('identity_phone_number', '', false);

        return;
      } else if (searchIdentityBy === SEARCH_IDENTITY_BY.PHONE_NUMBER) {
        await setFieldValue('searchIdentityBy', searchIdentityBy, false);
        await setFieldValue('identity_nickname', '', false);

        return;
      } else {
        console.error(
          t('Некорректный тип поиска. Обратитесь к администратору.')
        );
      }
    },
    [setFieldValue]
  );
  const handleReset = useCallback(() => {
    resetForm({
      values: new IdentityToInstallations({
        hasIdentity: false,
        searchIdentityBy: identity.searchIdentityBy,
      }),
    });
    dispatch(
      setCurrentIdentityInControlPanel({
        ...initialIdentityInControlPanel,
        searchIdentityBy: identity.searchIdentityBy,
      })
    );
  }, [dispatch, identity, resetForm]);

  const getButtonAddonSettings = useCallback(
    (identity: IIdentityState): IButtonAddon => {
      if (
        values?.identity_nickname &&
        values.identity_nickname === identity?.identity_nickname
      ) {
        return {
          textButton: t('Сбросить'),
          handleClickAddon: handleReset,
        };
      } else {
        return {
          textButton: getButtonAddonText(IsMoreThanOneIdentity(identity)),
          handleClickAddon: handleClickAddon,
          disabled: IsMoreThanOneIdentity(identity),
        };
      }
    },
    [handleClickAddon, handleReset, values.identity_nickname]
  );

  const handleChangeRadios = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const optionValue = event.target.value as SEARCH_IDENTITY_BY;
      handlePerformerTypeChange(optionValue);
      const payloadIdentity = { ...identity, searchIdentityBy: optionValue };
      dispatch(setCurrentIdentityInControlPanel(payloadIdentity));
    },
    [dispatch, handlePerformerTypeChange, identity]
  );

  return (
    <>
      <InputGroup>
        <CSSTransition
          in={!!(values.identity_nickname && values.identity_phone_number)}
          timeout={300}
          classNames={fieldTransition}
          unmountOnExit
        >
          <TextField
            id={'identity-name'}
            disabled={true}
            value={identity.fullName}
            label={t('Пользователь')}
          />
        </CSSTransition>
      </InputGroup>
      <fieldset>
        <RadiosField
          name="searchIdentityBy"
          hasErrors={
            errors.searchIdentityBy != null && touched.searchIdentityBy != null
          }
          options={[
            {
              label: t('По логину'),
              value: SEARCH_IDENTITY_BY.NICKNAME,
            },
            {
              label: t('По номеру телефона'),
              value: SEARCH_IDENTITY_BY.PHONE_NUMBER,
            },
          ]}
          checkedValue={values.searchIdentityBy}
          onChange={handleChangeRadios.bind(null)}
          inline={true}
          required={true}
        />
        {values.searchIdentityBy === SEARCH_IDENTITY_BY.NICKNAME ? (
          <TextField
            id="identity_nickname"
            label={t('Логин')}
            hasError={
              errors.identity_nickname != null &&
              touched.identity_nickname != null
            }
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.identity_nickname}
            value={values.identity_nickname}
            autoComplete="on"
            buttonAddon={getButtonAddonSettings(identity)}
          />
        ) : (
          <PhoneField
            id="identity_phone_number"
            label={t('Телефон')}
            hasError={
              errors.identity_phone_number != null &&
              touched.identity_phone_number != null
            }
            onBlur={handleBlur}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFieldValue(
                'identity_phone_number',
                convertToServerPhoneNumber(event.target.value)
              )
            }
            required={false}
            defaultValue={values.identity_phone_number}
            value={values.identity_phone_number}
            buttonAddon={getButtonAddonSettings(identity)}
          />
        )}

        <CSSTransition
          in={IsMoreThanOneIdentity(identity)}
          timeout={300}
          classNames={fieldTransition}
          unmountOnExit
        >
          <TransitionGroup in={IsMoreThanOneIdentity(identity)}>
            {IsMoreThanOneIdentity(identity) && (
              <ListOfIdentitiesToSelect
                identities={identity.listIdentity as IIdentity[]}
                typeInstallations={typeInstallations}
              />
            )}
          </TransitionGroup>
        </CSSTransition>
      </fieldset>
    </>
  );
};

export default FindIdentity;
