import store from 'store';
import { IIdentityListOfApplicableInstallationsEntity } from '../../services/api/auth/IRegisterIdentity';
import { setAccessToken, setActiveInstallation } from '../auth/actions';
import CookieManager from 'models/CoockieManager/CookieManager';
import InstallationCookie from 'models/CoockieManager/InstallationCookie/InstallationCookie';

/**
 *
 *
 * @param {IIdentityListOfApplicableInstallationsEntity} [identityInstallation]
 * @return {*}
 */
const getActiveInstallation = (
  identityInstallation?: IIdentityListOfApplicableInstallationsEntity
) => {
  const cookieManger = new CookieManager();
  const installationCookie = new InstallationCookie(cookieManger);

  const { installation, installationClientName, activeInstallationPk } =
    installationCookie.getActiveInstallation();
  if (activeInstallationPk && installationClientName && installation) {
    store.dispatch(
      setActiveInstallation(
        installation,
        installationClientName,
        +activeInstallationPk
      )
    );
    installationCookie.setActiveInstallation(
      installation,
      installationClientName,
      +activeInstallationPk
    );
    store.dispatch(setAccessToken(+activeInstallationPk));

    return;
  } else if (!identityInstallation) {
    return;
  } else {
    const { installation_name, installation_company_name, installation_pk } =
      identityInstallation;

    store.dispatch(
      setActiveInstallation(
        installation_name,
        installation_company_name,
        installation_pk
      )
    );
    store.dispatch(setAccessToken(installation_pk));
  }
};

export default getActiveInstallation;
