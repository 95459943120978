import * as Yup from 'yup';
 import { t } from '../utils/translation';
import { checkStartDateBeforeCurrentDate } from 'services/utils/dateHelper/dateHelper';

export function dateNotEarlierThan(this: Yup.StringSchema, msg?: string) {
  return this.test({
    name: 'dateNotEarlierThan',
    exclusive: false,
    message: msg || t('Дата не может быть ранее сегодняшнего дня'),
    test: function () {
      return checkStartDateBeforeCurrentDate(this.path)(this);
    },
  });
}

export default dateNotEarlierThan;
