export const TASKS_SAVE_FILTERS = 'TASKS_SAVE_FILTERS';
export const SET_TASK: string = 'SET_TASK';
export const SET_MARKED_TASK: string = 'SET_MARKED_TASK';
export const SET_TASK_FIELDS_VALUE: string = 'SET_TASK_FIELDS_VALUE';
export const CLEAR_TASK_FIELDS_VALUE: string = 'CLEAR_TASK_FIELDS_VALUE';
export const TASKS_CLEAR_FILTERS: string = 'TASKS_CLEAR_FILTERS';
export const GET_TASK_V2: string = ' GET_TASK_V2';
export const TASK_IS_LOADING: string = 'TASK_IS_LOADING';
export const SET_INITIAL_TASK = 'SET_INITIAL_TASK'; //task-types
export const SET_DEFAULT_STATUS = 'SET_DEFAULT_STATUS';
export const SET_IS_ACTIVE_COUNTERPARTY_FILTER =
  'SET_IS_ACTIVE_COUNTERPARTY_FILTER';
export const SET_ACCESS_POLICY_TASK = 'SET_ACCESS_POLICY_TASK';
