import { FC, memo } from 'react';
import { isSubMenu } from 'services/paths';
import FolderItem from '../FolderItem';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import animationWidth from './animationWidth.module.scss';
import animationHeight from './animationHeight.module.scss';

import classNames from 'clsx';
import {
  IMenuItemV2,
  ISubMenuItemV2,
} from '../../../services/interfaces/IMenuItemV2';
import { CODE_SIZES } from 'config';
import { extractHandlerClientSize } from 'services/utils/extractHandlerClientCodeSize/extractHandlerClientCodeSize';
import useWindowSize from 'hooks/useWindowSize';
import useExtractGetCountNotifications from 'hooks/useGetCountNotifications';

type PropsType = {
  menu: IMenuItemV2[] | ISubMenuItemV2[];
};

const ListFolderItems: FC<PropsType> = ({ menu }) => {
  const getCountNotification = useExtractGetCountNotifications();

  const handlerClientSizeSm = extractHandlerClientSize(CODE_SIZES.xs);

  const { width } = useWindowSize();
  const isLessThanXs = handlerClientSizeSm(width);
  return (
    <TransitionGroup
      component={'div'}
      className="py-3 px-2 p-2 d-flex flex-row flex-wrap justify-content-center justify-content-sm-start"
    >
      {menu.map((menuItem: ISubMenuItemV2 | IMenuItemV2) => (
        <CSSTransition
          key={menuItem.name}
          timeout={600}
          classNames={isLessThanXs ? animationHeight : animationWidth}
          unmountOnExit
        >
          <FolderItem
            hasNotification={!!getCountNotification(menuItem.name)}
            className={classNames('m-2')}
            to={menuItem.url}
            title={menuItem.title}
            isFavorite={isSubMenu(menuItem) ? menuItem?.isFavorite : null}
            name={menuItem.name}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default memo(ListFolderItems);
