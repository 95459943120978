export const EXTERNAL_REQUESTS_SAVE_FILTERS = 'EXTERNAL_REQUESTS_SAVE_FILTERS';
export const EXTERNAL_REQUESTS_CLEAR_FILTERS =
  'EXTERNAL_REQUESTS_CLEAR_FILTERS';
export const SET_MARKED_EXTERNAL_REQUEST = 'SET_MARKED_EXTERNAL_REQUEST';
export const SET_EXTERNAL_REQUEST_FIELDS_VALUE =
  'SET_EXTERNAL_REQUEST_FIELDS_VALUE';
export const SET_EXTERNAL_REQUEST_FIRST_STATUS =
  'SET_EXTERNAL_REQUEST_FIRST_STATUS';
export const SET_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER =
  'SET_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER';
export const SET_IS_ACTIVE_BILLING_ACCOUNT_ARRAY_FILTER =
  'SET_IS_ACTIVE_BILLING_ACCOUNT_ARRAY_FILTER';

export const SET_IS_ACTIVE_WORK_CATEGORY_FILTER =
  'SET_IS_ACTIVE_WORK_CATEGORY_FILTER';

export const SET_IS_ACTIVE_PK_FILTER = 'SET_IS_ACTIVE_PK_FILTER';

export const SET_INITIAL_EXTERNAL_REQUEST = 'SET_INITIAL_EXTERNAL_REQUEST';

export const CLEAR_EXTERNAL_REQUEST_VALUE = 'CLEAR_EXTERNAL_REQUEST_VALUE';

export const SET_ACCESS_POLICY_EXTERNAL_REQUEST =
  'SET_ACCESS_POLICY_EXTERNAL_REQUEST';
