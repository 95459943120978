import IBaseParams from '../../interfacesApi/IBaseParams';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import UndercurrentApiClient from '../../UndercurrentApiClient';
import IKeyValueT from '../../interfacesApi/IKeyValueT';
import { ICreateTaskType, IUpdateTaskType } from './ICreateTaskType';
import { ITaskTypeWrapper, ITaskType } from './IGetTaskTypesPayload';
import IGetPaginatedTaskTypesParams from './IGetPaginatedTaskTypesParams';
import IPrimaryKey from 'services/types/IPk';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IForSelectRequestV2 } from '../../../utils/generateMethodForSelect/generateMethodForSelect';

const { TASK_TYPE } = UNDERCURRENT_CHILD_MODULES;

const api = () => new UndercurrentApiClient().methodCreator(TASK_TYPE);

export const getPaginatedTaskTypeApiV2 = async (
  params: IBaseParams<IGetPaginatedTaskTypesParams>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<ITaskType>>> => {
  return await api().getPaginated(params);
};

export const createTaskTypeApiV2 = async (
  params: IBaseParams<ICreateTaskType>
): Promise<IBaseResponse<ITaskTypeWrapper>> => {
  return api().create(params);
};

export const updateTaskTypeApiV2 = async (
  params: IBaseParams<IUpdateTaskType>
): Promise<IBaseResponse<ITaskTypeWrapper>> => {
  return api().update(params);
};

export const deleteTaskTypeV2 = async (
  deleteParams: IBaseParams<IKeyValueT<{ pk: number }>>
): Promise<IBaseResponse<undefined>> => {
  return await api().delete(deleteParams);
};

export const getTaskTypeParentsForSelectApi = async (
  params: IBaseParams<IForSelectRequestV2>
) => {
  return await api().forSelect(params);
};

export const readTaskTypeV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<ITaskTypeWrapper>> => {
  return await api().read(params);
};
