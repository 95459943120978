import { FC, PropsWithChildren } from 'react';
import classNames from 'clsx';
import styles from './index.module.scss';
import SubHeaderListLinks, {
  ILinkEntity,
} from '../FormHeaderListsLinksArray/FormHeaderListsLinksArray';

export interface IListEntities {
  patch: string;
  titleListLinks: string;
  listLinks?: ILinkEntity[];
}

type PropsType = {
  title: string;
  secondTitle?: string;
  listEntities?: IListEntities[];
};

const SubHeaderEditEntity: FC<PropsWithChildren<PropsType>> = (props) => {
  const { title, listEntities, children, secondTitle } = props;
  const hasEntities = !!listEntities;

  return (
    <div
      className={classNames(
        'py-2 py-md-3 pl-4 pr-4',
        'd-flex flex-row justify-content-between align-items-center',
        styles.header
      )}
    >
      <div
        className={classNames(
          styles.headerData,
          'd-flex flex-column flex-sm-row'
        )}
      >
        <div
          className={classNames(
            styles.title,
            'd-flex flex-column justify-content-center px-3'
          )}
        >
          <div>{title}</div>
        </div>
        {secondTitle && (
          <div
            className={classNames(
              styles.title,
              'd-flex flex-column justify-content-center px-3'
            )}
          >
            <div>{secondTitle}</div>
          </div>
        )}
        <div
          className={classNames(
            'd-flex flex-row justify-content-start align-items-start '
          )}
        >
          {hasEntities && (
            <SubHeaderListLinks attributesForListLinks={listEntities} />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default SubHeaderEditEntity;
