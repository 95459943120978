import { useMemo } from 'react';
import { t } from '../../../../../services/utils/translation';
import useGetCommonTabs from '../../../../../hooks/useGetCommonTabs/useGetCommonTabs';
import { parentUIElement } from 'services/constants/parentUIElement';
import { useGetTaskTab } from '../../../../../hooks/useGetTaskTab/useGetTaskTab';
import { useGetTabExternalRequest } from '../../../../../hooks/useGetTabExternalRequest/useGetTabExternalRequest';
import { TAB_QUERY_KEY, TAB_QUERY_PARAMS } from 'services/pathConstants';
import usePrepareLink from '../../../../../hooks/usePrepareLinks';
import { useGetEventTab } from '../../../../../hooks/useGetEventTab/useGetEvenTab/useGetEvenTab';

type PropsType = {
  accountPk: number;
};

const useTabsAccountView = ({ accountPk }: PropsType) => {
  const { isLoadingTask, taskTab } = useGetTaskTab({
    pk: accountPk,
    parentUI: parentUIElement.account,
  });

  const {
    setRefreshDocuments,
    isLoadingDocument,
    commonTabs: [, historyTab, documentTab],
  } = useGetCommonTabs({
    parentUIElement: parentUIElement.account,
    id: accountPk,
  });

  const { externalRequestTab, isLoadingExternalRequest } =
    useGetTabExternalRequest({
      pk: accountPk,
      parentUI: parentUIElement.account,
    });

  // const { controllerTab, isLoadingController } = useGetTabController({
  //   pk: accountPk,
  //   parentUI: parentUIElement.account,
  // });

  const { eventTab, isLoadingEvent } = useGetEventTab({ pk: accountPk });

  const isLoadingCountTabs =
    isLoadingDocument ||
    isLoadingExternalRequest ||
    isLoadingTask ||
    isLoadingEvent;
  //|| isLoadingController;

  const counterpartyPatch = usePrepareLink({
    query: {
      [TAB_QUERY_KEY.account]: TAB_QUERY_PARAMS.counterparty,
    },
  });

  const counterpartyTab = useMemo(
    () => ({
      name: t('Контрагент'),
      patch: counterpartyPatch,
      isActive: false,
    }),
    [counterpartyPatch]
  );

  const memoTabs = useMemo(
    () => [
      taskTab,
      externalRequestTab,
      eventTab,
      // controllerTab,
      counterpartyTab,
      historyTab,
      documentTab,
    ],
    [
      counterpartyTab,
      documentTab,
      externalRequestTab,
      // controllerTab,
      historyTab,
      taskTab,
      eventTab,
    ]
  );

  return {
    tabs: memoTabs,
    refreshTabs: setRefreshDocuments,
    isLoadingCountTabs,
  };
};

export default useTabsAccountView;
