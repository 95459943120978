import styles from './index.module.scss';
import classNames from 'clsx';

const findAndMakeLink = (text: string, isMyComment?: boolean) => {
  const checkHttps = new RegExp('http[s]?', 'gim');
  const separator = new RegExp('\\s+', 'gim');
  return (
    <>
      {text.split(separator).map((word: string) =>
        checkHttps.test(word) ? (
          <a
            className={classNames({ [styles.isMyComment]: isMyComment })}
            target="_blank"
            href={word.trim()}
            rel="noreferrer"
            key={word}
          >
            {word + ' '}
          </a>
        ) : (
          word + ' '
        )
      )}
    </>
  );
};

export default findAndMakeLink;
