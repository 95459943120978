import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { FC, memo } from 'react';
import { Spinner } from 'reactstrap';
import { t } from 'services/utils/translation';

type ToolTipWrapperProps = { id: number; isLoading: boolean; text?: string };

const TooltipWitchLoading: FC<ToolTipWrapperProps> = (props) => {
  const { id, isLoading, text } = props;
  return (
    <ToolTipWrapper
      placement="auto"
      target={`tooltip-attachment-${id}`}
      isLoadingDescription={isLoading}
    >
      {text ? (
        text
      ) : (
        <div className="">
          {t('Загрузка...')}
          <Spinner size="sm" color="light" />
        </div>
      )}
    </ToolTipWrapper>
  );
};

export default memo(TooltipWitchLoading);
