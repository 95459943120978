import { useFormikContext } from 'formik';
import FileBucket from 'models/FileBucket';
import { Thumb } from 'components/misc/Thumb/Thumb';
import IFileFormValues from '../IFileFormValues';
import useDocumentRemoveFile from '../hooks/useDocumentRemoveFile';
import useDocumentReturnDeletedFile from '../hooks/useDocumentReturnDeletedFile';

const FileBucketFileList = () => {
  const { deleteUploadedFilesFiles, removeAddedFiles } =
    useDocumentRemoveFile();
  const {
    values: { list_of_uploaded_files, uploadedFiles },
  } = useFormikContext<FileBucket>();
  const returnDeletedFile = useDocumentReturnDeletedFile();
  return (
    <>
      {list_of_uploaded_files.map((file: File) => (
        <Thumb
          key={file.name}
          file={file}
          onDeleteFile={removeAddedFiles.bind(null, file)}
        />
      ))}
      {uploadedFiles.map((file: IFileFormValues, i: number) => (
        <Thumb
          key={file.file.name + i}
          file={file.file}
          onDeleteFile={deleteUploadedFilesFiles.bind(null, file)}
          onReturnedDeletedFile={returnDeletedFile.bind(null, file)}
          isActive={file.isActive}
        />
      ))}
    </>
  );
};

export default FileBucketFileList;
