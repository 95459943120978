import { FC, useCallback, useMemo } from 'react';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { t } from 'services/utils/translation';
import { ReactComponent as DetailIcon } from 'img/icons/icon-file.svg';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import { ReactComponent as CloseIcon } from 'img/icons/icon-padlock.svg';
import { ReactComponent as OpenIcon } from 'img/icons/icon-unlock.svg';

import useModalV2 from 'hooks/useModalV2';
import { IModal } from 'hooks/useModal';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import HeaderWithDynamicSelectPaginatedV2 from 'components/tables/HeaderWithDynamicSelectPaginatedV2/HeaderWithDynamicSelectPaginatedV2';
import classNames from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  PATH_CONTROLLER_ADD,
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
} from 'services/pathConstants';
import { makeLink } from 'services/localPaths';
import HeaderWithDatePickerRangeV2 from 'components/tables/HeaderWithDatePickerRangeV2/HeaderWithDatePickerRangeV2';
import TDCheckBox from 'components/tables/TDCheckBox/TDCheckBox';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import RefreshButton from 'components/tables/RefreshButton/RefreshButton';
import useClearFilters from 'hooks/useClearFilters';
import styles from './index.module.scss';
import useFilterBooleanSelect, {
  BooleanSelectField,
} from 'hooks/useFilterIsPruned/useFilterBooleanSelect';
import HeaderWithSelectAdvanced from 'components/tables/HeaderWithSelectAdvanced/HeaderWithSelectAdvanced';
import { CONTROLLER_TEXT } from 'services/localLocalization/servicesAndModels';
import { UI_TITLE } from 'services/localLocalization/UITitle';
import {
  extractGetLeftValueIfTrue,
  getDropDownEditTitle,
} from 'services/utils/extractGetLeftValueIfTrue';
import { useColumnFilter } from 'hooks/useColumnFilter';
import { getControllerFiltersFromStore } from 'store/controller/selectors';
import useSetAndGetControllerAccessPolicy from './hooks/useSetAndGetAccessPolicy';
import {
  clearControllerFilters,
  saveControllerFilters,
} from 'store/controller/actions';
import {
  deleteControllerApi,
  getPaginatedControllersApi,
} from 'services/api/controller/controllerApi';
import { useGetList } from 'hooks/useGetList';
import { useControllerComposeParams } from './hooks/useControllerComposeParams';
import { useToControllerForList } from './hooks/useToControllerForList';
import initialControllerFilters from 'store/controller/initial/initialControllerFilters';
import { IControllerClosedParams } from 'services/api/controller/IControllerClosedParams';
import useControllerHandleSearch from './hooks/useControllerHandleSearch';
import { SortCriteriaController } from 'services/api/controller/SortCriteriaController';
import {
  COLUMN_LABELS_CONTROLLER,
  columnVisibilityInitialController,
  filtersController,
} from './utils/columnVisibilityInitialContoller';
import HeaderWithSearch from 'components/tables/HeaderWithSearch';
import useControllerToClosed from 'hooks/useControllToClosed/useControllToClosed';
import THCustom from 'components/tables/THCustom/THCustom';
import {
  THCustomDate,
  THCustomSelect,
} from 'components/tables/THCustomWrapper/THCustomWrapper';
import { getForSelectBillingAccountApiV2 } from '../../../services/api/organizationsV2/billing-account/billingAccountApi';
import { IControllerForList } from './utils/toControllerForList/toControllerForList';
import { IControllerFilters } from 'services/api/controller/IControllerFilters';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import Pager from 'components/tables/Pager';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';
import useAddOrEditPushNavigation from 'hooks/useAddOrEditPushNavigation';
import { PATH_CONTROLLER_EDIT } from '../../../services/pathConstants';

const CONTROLLER_IS_CLOSED_HINT = `${makeFirstLetterUppercase(
  CONTROLLER_TEXT
)} ${t('закрыт')}`;
const CONTROLLER_IS_OPEN_HINT = `${makeFirstLetterUppercase(
  CONTROLLER_TEXT
)} ${t('открыт')}`;
const CREATE_BUTTON_TITLE = `${t('Добавить')} ${CONTROLLER_TEXT}`;

const OPEN_TEXT_DROPDOWN_ITEM = `${t('Открыть')} ${CONTROLLER_TEXT}`;
const CLOSE_TEXT_DROPDOWN_ITEM = `${t('Закрыть')} ${CONTROLLER_TEXT}`;

const getDropdownCloseText = extractGetLeftValueIfTrue(
  OPEN_TEXT_DROPDOWN_ITEM,
  CLOSE_TEXT_DROPDOWN_ITEM
);

const MODAL_DELETE_TITLE = `${UI_TITLE.DELETE} ${CONTROLLER_TEXT}`;
const deleteMessageError = `${t('Не удалось удалить  ')} ${CONTROLLER_TEXT}`;

type ControllerPagePropsType = {
  billingAccountPk?: number;
};

const ControllerPage: FC<ControllerPagePropsType> = (props) => {
  const { billingAccountPk } = props;
  const isViewPage = !!billingAccountPk;

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitialController);
  const defaultTableFilters = useSelector(
    getControllerFiltersFromStore,
    isEqual
  );

  const initialParams: IControllerFilters = useMemo(
    () => ({
      ...defaultTableFilters,
      controller_billing_account_fk: billingAccountPk,
    }),
    [defaultTableFilters, billingAccountPk]
  );

  const dispatchRedux = useDispatch();

  const {
    billingAccountHaveAccessToRead,
    controller: { haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete },
  } = useSetAndGetControllerAccessPolicy();

  const saveFilters = (filters: IControllerFilters) =>
    dispatchRedux(saveControllerFilters(filters));

  const composeParamsGetController = useControllerComposeParams();
  const toControllerForList = useToControllerForList();

  const onFinally = () => setClearingFiltersSpinner(false);

  const {
    data: controllerList,
    isLoading,
    refreshListData,
    total,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    setParams,
    params: {
      skip,
      length,
      controller_pk: filterPk,
      controller_billing_account_fk_array: filterControllerFkArray,
      controller_description: filterDescription,
      controller_is_closed: filterIsClosed,
      controller_datetime_installed_lower_bound: filterDatetimeAddedLower,
      controller_datetime_installed_upper_bound: filterDatetimeAddedUpper,
      controller_datetime_last_active_lower_bound: filterDatetimeEditedLower,
      controller_datetime_last_active_upper_bound: filterDatetimeEditedUpper,
    },
    params,
    refreshAndReturnToStartPosition,
  } = useGetList({
    getDataApi: getPaginatedControllersApi,
    initialParams,
    convertedParameters: composeParamsGetController,
    convertData: toControllerForList,
    saveFilters,
    onFinally,
  });

  const closeHandler = useControllerToClosed();

  const doCloseOrOpenController = useCallback(
    async (params: IControllerClosedParams) => {
      await closeHandler(params);
      refreshListData();
    },
    [closeHandler, refreshListData]
  );

  const { clearFilters, isDisableClearButton, setClearingFiltersSpinner } =
    useClearFilters(
      initialControllerFilters,
      params,
      clearControllerFilters,
      setParams,
      {
        controller_billing_account_fk: billingAccountPk,
      }
    );

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetController },
    openAddEditForm,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteControllerApi,
    entityIdKey: 'pk',
  });

  const location = useLocation();

  const locationState = { billingAccountPk, from: location };

  useAddOrEditPushNavigation({
    pk: targetController?.pk,
    isAddEditFormOpen,
    pathEdit: PATH_CONTROLLER_EDIT,
    pathAdd: PATH_CONTROLLER_ADD,
    state: locationState,
  });

  //SEARCH

  const {
    handleSearchPk,
    handleSearchControllerDescription,
    handleSelectBillingAccountArray,
  } = useControllerHandleSearch(onSearchRequest);

  const { handleSelectBooleanType, booleanOptions } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.controller,
  });

  return (
    <MainLayout isInWrapper={isViewPage}>
      <Subheader>
        <div style={{ display: 'flex' }} className="mr-5">
          <SubheaderButton
            onClick={clearFilters}
            bordered={true}
            disabled={isDisableClearButton}
          >
            {UI_TITLE.REMOVE_FILTERS}
          </SubheaderButton>
          <RefreshButton
            toggleRefresh={refreshAndReturnToStartPosition}
            isLoading={isLoading}
          />
          {haveAccessToCreate && (
            <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
              <DetailIcon className="mr-2 text-white" />
              {CREATE_BUTTON_TITLE}
            </SubheaderButton>
          )}
        </div>
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <THCustom
                className={classNames(styles.columnControllerPk)}
                isVisible={visibility.controller_pk}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaController.PkAsk}
                  title={COLUMN_LABELS_CONTROLLER.PK}
                  onSort={onSortClick}
                  onSearch={handleSearchPk}
                  defaultValue={filterPk ? filterPk + '' : ''}
                />
              </THCustom>
              <THCustom
                // className={classNames(`id pr-0`, styles.columnDescription)}
                isVisible={visibility.controller_description}
              >
                <HeaderWithSearch
                  title={COLUMN_LABELS_CONTROLLER.DESCRIPTION}
                  onSearch={handleSearchControllerDescription}
                  defaultValue={filterDescription}
                />
              </THCustom>

              <THCustomSelect
                className={styles.columnControllerBillingAccount}
                isVisible={visibility.controller_billing_account}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  selectHandler={getForSelectBillingAccountApiV2}
                  id="internal_task_billing_account_fk_array"
                  title={COLUMN_LABELS_CONTROLLER.BILLING_ACCOUNT}
                  options={filterControllerFkArray}
                  isMulti={true}
                  onChange={handleSelectBillingAccountArray}
                  disabled={isViewPage}
                  haveReadPermission={billingAccountHaveAccessToRead}
                />
              </THCustomSelect>
              <THCustom
                className={styles.columnControllerIsClosed}
                isVisible={visibility.controller_is_closed}
              >
                <HeaderWithSelectAdvanced
                  label={COLUMN_LABELS_CONTROLLER.IS_CLOSED}
                  options={booleanOptions}
                  onChange={handleSelectBooleanType}
                  defaultValue={filterIsClosed}
                />
              </THCustom>
              <THCustomDate
                className={styles.columnControllerDatetimeInstalled}
                isVisible={visibility.controller_datetime_installed}
              >
                <HeaderWithDatePickerRangeV2
                  title={COLUMN_LABELS_CONTROLLER.CREATE_DATE}
                  propsOnSearch={{
                    onSearchRequest,
                    keyDate: [
                      'controller_datetime_installed_lower_bound',
                      'controller_datetime_installed_upper_bound',
                    ],
                  }}
                  startDate={
                    filterDatetimeAddedLower
                      ? new Date(filterDatetimeAddedLower)
                      : null
                  }
                  endDate={
                    filterDatetimeAddedUpper
                      ? new Date(filterDatetimeAddedUpper)
                      : null
                  }
                  field={SortCriteriaController.DateAddedAsc}
                  onSort={onSortClick}
                  hasTime
                />
              </THCustomDate>
              <THCustomDate
                className={classNames(styles.columnControllerDatetimeActive)}
                isVisible={visibility.controller_datetime_active}
              >
                <HeaderWithDatePickerRangeV2
                  title={COLUMN_LABELS_CONTROLLER.EDITING_DATE}
                  propsOnSearch={{
                    onSearchRequest,
                    keyDate: [
                      'controller_datetime_active_lower_bound',
                      'controller_datetime_active_upper_bound',
                    ],
                  }}
                  startDate={
                    filterDatetimeEditedLower
                      ? new Date(filterDatetimeEditedLower)
                      : null
                  }
                  endDate={
                    filterDatetimeEditedUpper
                      ? new Date(filterDatetimeEditedUpper)
                      : null
                  }
                  field={SortCriteriaController.DateEditedAsc}
                  onSort={onSortClick}
                  hasTime
                />
              </THCustomDate>
              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filtersController.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {controllerList &&
                controllerList.map((targetController: IControllerForList) => {
                  const {
                    controller_pk: pk,
                    controller_description: description,
                    controller_billing_account: {
                      billing_account_code_number: billingAccountCodeNumber,
                      billing_account_pk: billingAccountPk,
                    },
                    editedDate,
                    createDate,
                    controller_is_closed: controllerIsClosed,
                  } = targetController;
                  return (
                    <tr key={pk}>
                      <td
                        className={classNames(styles.columnControllerPk, {
                          'd-none': !visibility.controller_pk,
                        })}
                      >
                        <span>
                          <Link
                            to={makeLink(PATH_CONTROLLER_EDIT, pk)}
                            state={locationState}
                          >
                            {pk}
                          </Link>
                        </span>
                      </td>
                      <td
                        className={classNames(
                          styles.columnControllerDescription,
                          {
                            'd-none': !visibility.controller_description,
                          }
                        )}
                      >
                        <span>{description}</span>
                      </td>

                      <td
                        className={classNames(
                          styles.columnControllerController,
                          {
                            'd-none': !visibility.controller_billing_account,
                          }
                        )}
                      >
                        {
                          <Link
                            to={makeLink(
                              PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
                              billingAccountPk
                            )}
                          >
                            {billingAccountCodeNumber}
                          </Link>
                        }
                      </td>
                      <td
                        className={classNames(styles.columnControllerIsClosed, {
                          'd-none': !visibility.controller_is_closed,
                        })}
                      >
                        <TDCheckBox
                          isCheck={controllerIsClosed}
                          hint={
                            controllerIsClosed
                              ? CONTROLLER_IS_CLOSED_HINT
                              : CONTROLLER_IS_OPEN_HINT
                          }
                          id={`isDriver_${pk}`}
                        />
                      </td>
                      <td
                        className={classNames(
                          styles.columnControllerDatetimeAdded,
                          {
                            'd-none': !visibility.controller_datetime_installed,
                          }
                        )}
                      >
                        <span>{createDate}</span>
                      </td>
                      <td
                        className={classNames(
                          styles.columnControllerDatetimeEdited,
                          {
                            'd-none': !visibility.controller_datetime_active,
                          }
                        )}
                      >
                        <span>{editedDate}</span>
                      </td>
                      <td align="right">
                        <ActionsDropdown>
                          <ActionsDropdownItem
                            label={getDropDownEditTitle(haveAccessToUpdate)}
                            onClick={openAddEditForm.bind(null, {
                              pk,
                              rendition: pk,
                            })}
                            icon={<EditIcon className="text-primary" />}
                          />

                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={getDropdownCloseText(controllerIsClosed)}
                              onClick={doCloseOrOpenController.bind(null, {
                                pk,
                                controller_is_closed: !controllerIsClosed,
                              })}
                              icon={
                                controllerIsClosed ? (
                                  <OpenIcon className="text-primary" />
                                ) : (
                                  <CloseIcon className="text-primary" />
                                )
                              }
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                rendition: description,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <LoaderFetch />
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />

        {targetController?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetController?.pk}
            entityName={targetController?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default ControllerPage;
