import ApiClientBase from './ApiClientBase';
import { isDev } from 'config';

class NotificationApiClientBase extends ApiClientBase {
  constructor() {
    const installation = isDev() ? 'centralregistry-dev' : 'centralregistry';
    super('notifications', installation, 'v2');
  }
}

export default NotificationApiClientBase;
