import CookieManager from 'models/CoockieManager/CookieManager';

class InstallationCookie {
  private readonly cookieManager: CookieManager;

  constructor(cookieManager: CookieManager) {
    this.cookieManager = cookieManager;
  }

  public setActiveInstallation(
    installation: string,
    installationClientName: string,
    installationPk: number
  ) {
    this.cookieManager.setCookie(
      'installationClientName',
      installationClientName
    );
    this.cookieManager.setCookie('installation', installation);
    this.cookieManager.setCookie(
      'activeInstallationPk',
      installationPk.toString()
    );
  }

  public removeActiveInstallation() {
    this.cookieManager.removeCookie('installationClientName');
    this.cookieManager.removeCookie('installation');
    this.cookieManager.removeCookie('activeInstallationPk');
  }

  public getInstallation(): string | undefined {
    return this.cookieManager.getCookie('installation');
  }

  public getActiveInstallation(): {
    installation: string | undefined;
    installationClientName: string | undefined;
    activeInstallationPk: string | undefined;
  } {
    return {
      installation: this.cookieManager.getCookie('installation'),
      installationClientName: this.cookieManager.getCookie(
        'installationClientName'
      ),
      activeInstallationPk: this.cookieManager.getCookie(
        'activeInstallationPk'
      ),
    };
  }
}

export default InstallationCookie;
