import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import { FC } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { t } from 'services/utils/translation';
import useRead from '../../../../hooks/useRead';
import {
  createAccessPolicyApiV2,
  readAccessPolicyApiV2,
  updateAccessPolicyApiV2,
} from '../../../../services/api/staff/access-policy/accessPolicyApiV2';
import AccessPolicy from 'models/AccessPolicy';
import {
  IAccessPolicy,
  IAccessPolicyStatement,
  IWrapperAccessPolicy,
  TCRUDA,
} from '../../../../services/api/staff/access-policy/IAccessPolicy';
import { getFullAccessPolicyApiV2 } from '../../../../services/api/general/apiGeneralV2';
import { LocalizationActionsValues } from '../../../../pages/staff/access-policy/AccessPolicyPage/toAccessPolicyForList';
import { useSelector } from 'react-redux';
import { getLocalizationFromStore } from 'store/general/selectors';
import AccessPolicyServiceModule from 'components/staff/access-policy/AccessPolicyServiceModule.tsx/AccessPolicyServiceModule.tsx';
import { usePostV3 } from 'hooks/usePostV3';
import {
  ICreateAccessPolicy,
  IUpdateAccessPolicy,
} from '../../../../services/api/staff/access-policy/ICreateAccessPolicy';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import useAccessPolicyForm from '../AccessPolicyHooks/useAccessPolicyForm';
import classNames from 'clsx';
import styles from './index.module.scss';
export interface IClientCodeActions {
  label: LocalizationActionsValues;
  actionCode: TCRUDA;
  checked: boolean;
}

export interface IClientAccessPolicyModel {
  labelModel: string;
  nameModel: string;
  actionPermissions: IClientCodeActions[];
}

export interface IClientAccessPolicyService {
  labelService: string;
  serviceName: string;
  permissionsForModels: IClientAccessPolicyModel[];
  openServiceList: boolean;
}

type PropsType = {
  successHandler: () => void;
  pk?: number;
};

const AccessPolicyForm: FC<PropsType> = (props) => {
  const { pk, successHandler } = props;

  const localization = useSelector(getLocalizationFromStore);
  const {
    convertParams,
    extractOnAdderDelete,
    extractToAccessPolicyFormValues,
  } = useAccessPolicyForm();

  const { data: initialValues, isLoading } = useRead({
    getDataApi: readAccessPolicyApiV2,
    params: { pk },
    condition: !!pk,
    convertData: (payload: IWrapperAccessPolicy) => payload.access_policy,
    initialData: {} as IAccessPolicy,
  });

  const toAccessPolicyFormValues = extractToAccessPolicyFormValues(
    localization,
    initialValues
  );

  const conditionForGetFullAccessibilityStatement = pk
    ? !!initialValues?.access_policy_pk
    : true;

  const {
    data: fullAccessibilityStatement,
    isLoading: isLoadingFullAccessPolicy,
  } = useRead({
    getDataApi: getFullAccessPolicyApiV2,
    convertData: (payload: IAccessPolicyStatement) =>
      toAccessPolicyFormValues(payload),
    initialData: new AccessPolicy(),
    params: undefined,
    condition: conditionForGetFullAccessibilityStatement,
  });

  const createAccessPolicyApi = usePostV3({
    fetchApi: createAccessPolicyApiV2,
    successHandler,
    convertParams,
    successMessage: t('Роль создана успешно.'),
    viewClientTextToast: true,
  });

  const updateAccessPolicy = usePostV3({
    fetchApi: updateAccessPolicyApiV2,
    successHandler,
    convertParams,
    successMessage: t('Роль обновлена успешно.'),
    viewClientTextToast: true,
  });

  const extractOnAdd = extractOnAdderDelete(true);
  const extractOnDelete = extractOnAdderDelete(false);

  const extractOnAllAdd = extractOnAdderDelete(true, true);
  const extractOnAllDelete = extractOnAdderDelete(false, true);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={fullAccessibilityStatement}
      validationSchema={AccessPolicy.validationSchema()}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        if (values.pk) {
          await updateAccessPolicy(values as IUpdateAccessPolicy);
        } else {
          await createAccessPolicyApi(values as ICreateAccessPolicy);
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => {
        return (
          <>
            {!isLoadingFullAccessPolicy && !isLoading && (
              <Form onSubmit={handleSubmit} noValidate={true}>
                {values.pk != null && (
                  <HiddenField
                    id="pk"
                    value={values.pk}
                    hasError={errors.pk != null && touched.pk != null}
                  />
                )}
                <TextField
                  id="access_policy_name"
                  label={t('Название')}
                  hasError={
                    errors.access_policy_name != null &&
                    touched.access_policy_name != null
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={true}
                  defaultValue={values.access_policy_name}
                />
                <TextField
                  id="access_policy_description"
                  label={t('Описание')}
                  hasError={
                    errors.access_policy_description != null &&
                    touched.access_policy_description != null
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultValue={values.access_policy_description}
                />
                <div>
                  <Label className={classNames('control-label')}>
                    {t('Разрешения')}
                  </Label>
                  <FormGroup
                    className={classNames(
                      'control-label',
                      styles.accessWrapper
                    )}
                  >
                    {values?.access_policy_statement &&
                      values.access_policy_statement.map(
                        (item: IClientAccessPolicyService) => (
                          <div key={item.serviceName}>
                            <AccessPolicyServiceModule
                              accessPolicyServiceModule={item}
                              onAdd={(
                                modelName?: string,
                                actionCode?: string
                              ) =>
                                extractOnAdd(
                                  setFieldValue,
                                  values?.access_policy_statement,
                                  item.serviceName
                                )(modelName, actionCode)
                              }
                              onDelete={(
                                modelName?: string,
                                actionCode?: string
                              ) =>
                                extractOnDelete(
                                  setFieldValue,
                                  values?.access_policy_statement,
                                  item.serviceName
                                )(modelName, actionCode)
                              }
                              onAllDelete={(modelName?: string) =>
                                extractOnAllDelete(
                                  setFieldValue,
                                  values?.access_policy_statement,
                                  item.serviceName
                                )(modelName)
                              }
                              onAllAdd={(modelName?: string) =>
                                extractOnAllAdd(
                                  setFieldValue,
                                  values?.access_policy_statement,
                                  item.serviceName
                                )(modelName)
                              }
                            />
                          </div>
                        )
                      )}
                  </FormGroup>
                </div>
                <FormGroup className="d-block text-right mt-4">
                  <Button type="submit" color="primary" disabled={isSubmitting}>
                    {t('Сохранить')}
                  </Button>
                </FormGroup>
              </Form>
            )}
            {isLoadingFullAccessPolicy && <LoaderFetch />}
          </>
        );
      }}
    </Formik>
  );
};

export default AccessPolicyForm;
