import useRead from 'hooks/useRead';
import Booking from 'models/Booking';
import { FC } from 'react';
import { readBookingApiV2 } from 'services/api/vehicle/booking/bookingApi';
import IPrimaryKey from 'services/types/IPk';
import { IBookingWrapper } from '../../../services/api/vehicle/booking/IBooking';
import { createClientSelectOptionV2 } from '../../../services/utils/selects/selects';
import { usePostV3 } from 'hooks/usePostV3';
import {
  createBookingApiV2,
  updateBookingApiV2,
} from '../../../services/api/vehicle/booking/bookingApi';
import {
  ICreateBooking,
  IUpdateBooking,
} from 'services/api/vehicle/booking/ICreateBooking';
import { t } from 'services/utils/translation';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { Formik } from 'formik';
import classNames from 'clsx';
import { Button, Form, FormGroup } from 'reactstrap';
import HiddenField from 'components/controls/HiddenField';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { getForSelectVehicleApiV2 } from '../../../services/api/vehicle/vehicle/vehicleApi';
import { useLocation } from 'react-router-dom';
import { PATH_VEHICLE_BOOKING } from '../../../services/pathConstants';
import deleteUnnecessaryParameters from '../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

const toBookingFormValues = ({ booking }: IBookingWrapper): Booking => {
  const { booking_pk: pk, booking_vehicle: vehicle } = booking;

  const externalRequest = booking?.booking_external_request;

  return {
    pk,
    vehicle: createClientSelectOptionV2(
      vehicle.vehicle_pk,
      vehicle.vehicle_registration_plate
    ),
    externalRequestPk: externalRequest?.external_request_pk,
  };
};

const isUpdateBooking = (
  booking: ICreateBooking | IUpdateBooking | Booking
): booking is IUpdateBooking => 'pk' in booking;

const convertParams = (values: Booking): ICreateBooking | IUpdateBooking => {
  const { pk, vehicle, externalRequestPk } = values;
  const isCreate = !isUpdateBooking(values);
  const newValues = deleteUnnecessaryParameters({
    pk,
    booking_vehicle_fk: vehicle?.value,
    booking_external_request_fk: externalRequestPk,
  });

  return isCreate
    ? (newValues as ICreateBooking)
    : (newValues as IUpdateBooking);
};

type PropsType = {
  successHandler: () => void;
  pk?: number;
  externalRequestPk?: number;
  isHaveBooking?: boolean;
  isViewOnly?: boolean;
};

export const BookingForm: FC<PropsType> = (props) => {
  const {
    successHandler,
    pk,
    externalRequestPk,
    isHaveBooking,
    isViewOnly = false,
  } = props;

  const location = useLocation();

  const isModal =
    isHaveBooking || location.pathname.includes(PATH_VEHICLE_BOOKING);

  const { data: initialValues, isLoading } = useRead({
    getDataApi: readBookingApiV2,
    params: { pk } as IPrimaryKey,
    condition: !!pk,
    convertData: toBookingFormValues,
    initialData: new Booking(externalRequestPk),
    warningHandler: successHandler,
  });
  const createBooking = usePostV3({
    fetchApi: createBookingApiV2,
    successHandler,
    successMessage: t('Бронь создана успешно.'),
    viewClientTextToast: true,
  });

  const updateBooking = usePostV3({
    fetchApi: updateBookingApiV2,
    successHandler,
    successMessage: t('Бронь отредактирована успешна.'),
    viewClientTextToast: true,
  });

  return (
    <>
      <LoaderFetch />
      {!isLoading && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Booking.validationSchema()}
          onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
            setSubmitting(true);
            const newValues = convertParams(values);
            if (isUpdateBooking(newValues)) {
              await updateBooking(newValues, setErrors, resetForm);
            } else {
              await createBooking(newValues, setErrors, resetForm);
            }
            setSubmitting(false);
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            values: { pk, vehicle },
          }) => (
            <Form
              onSubmit={handleSubmit}
              noValidate={true}
              style={{ maxWidth: '100%' }}
            >
              <div
                className={classNames({
                  'd-flex flex-column flex-md-row justify-content-md-between ml-4 mr-4 mr-md-auto pl-3':
                    !isModal,
                })}
              >
                <div
                  className={'my-2 my-md-3 py-3 w-100 '}
                  style={{
                    maxWidth: '800px',
                  }}
                >
                  {pk && (
                    <HiddenField
                      id="pk"
                      value={pk}
                      hasError={errors.pk != null && touched.pk != null}
                    />
                  )}

                  <DynamicSelectPaginatedFieldV2
                    id="vehicle"
                    label={t('Машина')}
                    placeholder={t('Выберите машину...')}
                    hasError={
                      (errors.vehicle != null && touched.vehicle != null) ||
                      (errors.vehicle != null && touched.vehicle != null)
                    }
                    selectHandler={getForSelectVehicleApiV2}
                    options={vehicle}
                    disabled={isViewOnly}
                  />
                  <FormGroup className="d-block text-right mt-4">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting || isViewOnly}
                    >
                      {t('Сохранить')}
                    </Button>
                  </FormGroup>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
