import { useMemo } from 'react';
import { useGetList } from 'hooks/useGetList';
import { parentUIElement } from 'services/constants/parentUIElement';
import { initialExternalRequestFilters } from '../../store/externalRequest/initialExternalRequestFilters';
import { getPaginatedExternalRequestApiV2 } from '../../services/api/external-request/external-request/externalRequestApi';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

const KEY_PARAMS_FOR_GET_TOTAL_TASK: Record<
  string,
  keyof IExternalRequestFilters
> = {
  [parentUIElement.account]: 'external_request_billing_account_fk',
  [parentUIElement.event]: 'external_request_event_fk',
} as const;

const composeParamsForOrder = (params: IExternalRequestFilters) => {
  return deleteUnnecessaryParameters(params);
};

interface IUseGetTotalExternalRequestProps {
  pk: number;
  parentUI: parentUIElement;
}

const useGetTotalExternalRequest = ({
  pk,
  parentUI,
}: IUseGetTotalExternalRequestProps) => {
  const initialParamsExternalRequest: IExternalRequestFilters = useMemo(
    () => ({
      ...initialExternalRequestFilters,
      [KEY_PARAMS_FOR_GET_TOTAL_TASK[parentUI]]: pk,
    }),
    [parentUI, pk]
  );

  const { total: totalExternalRequest, isLoading: isLoadingExternalRequest } =
    useGetList({
      getDataApi: getPaginatedExternalRequestApiV2,
      initialParams: initialParamsExternalRequest,
      convertedParameters: composeParamsForOrder,
      initialData: 0,
    });

  return { totalExternalRequest, isLoadingExternalRequest };
};

export default useGetTotalExternalRequest;
