import { useGetList } from 'hooks/useGetList';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { useMemo } from 'react';
import { IAccessPolicy } from 'services/api/staff/access-policy/IAccessPolicy';
import { getPaginatedAccessPolicyApiV2 } from 'services/api/staff/access-policy/accessPolicyApiV2';
import { targetAccessPolicyForAccess } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyPersonnel';
const LENGTH_ACCESS_POLICIES = 100;

export const toAccessPolicyOptions = (
  accessPolicyList: IAccessPolicy[]
): Record<string, string> =>
  accessPolicyList.reduce((acc, value) => {
    acc[value.access_policy_pk] = value.access_policy_name;
    return acc;
  }, {} as Record<string, string>);

const useToAccessPolicyTransformToObject = () => {
  const [haveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyForAccess,
    targetAction: ['R'],
  });
  const initialParams = useMemo(
    () => ({ skip: 0, length: LENGTH_ACCESS_POLICIES }),
    []
  );

  const { data: roles, isLoading: isLoadingRoles } = useGetList({
    getDataApi: getPaginatedAccessPolicyApiV2,
    convertData: toAccessPolicyOptions,
    initialParams,
    condition: haveAccessToRead,
  });

  return { roles, isLoadingRoles };
};

export default useToAccessPolicyTransformToObject;
