import { TargetAccessPolicyService } from "./TargetAccessPolicyService";
import { TargetAccessPolicy } from "./TargetAccessPolicy";

export const targetAccessPolicyTaskService = new TargetAccessPolicyService(
    'internal_task'
);

export const targetAccessPolicyTask = new TargetAccessPolicy(
    targetAccessPolicyTaskService,
    'InternalTask'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyTaskType = new TargetAccessPolicy(
    targetAccessPolicyTaskService,
    'InternalTaskType'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyTaskStatus = new TargetAccessPolicy(
    targetAccessPolicyTaskService,
    'InternalTaskStatus'
).getTargetAccessPolicyStatement();
