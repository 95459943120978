import classNames from 'clsx';
import { ReactComponent as VisibilityOffIcon } from 'img/icons/icon-visibility-off.svg';
import { ReactComponent as VisibilityOnIcon } from 'img/icons/icon-visibility-on.svg';
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
  memo,
} from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import styles from './index.module.scss';
import useGetPasswordStrength from 'components/controls/PasswordInput/hooks/useGetPasswordStrength/useGetPasswordStrength';
import useFocus from 'hooks/useFocus/useFocus';
import { t } from 'services/utils/translation';
import { PasswordStrength } from 'components/controls/PasswordInput/hooks/useGetStringCharacteristics/utils/getPasswordStrength';
import PasswordVerificationTips from './ui/PasswordVerificationTips/PasswordVerificationTips';
import UnderLines from './ui/UnderLines/UnderLines';

type TypeProps = {
  id: string;
  placeholder?: string;
  onBlur: FocusEventHandler;
  onChange: ChangeEventHandler;
  onKeyUp?: KeyboardEventHandler;
  required?: boolean;
  defaultValue?: string;
  value?: string;
  name?: string;
  className?: string;
  autoComplete?: 'off' | 'on' | 'new-password';
  disabled?: boolean;
  checkDifficulty?: boolean;
  forwardRef: React.MutableRefObject<null>;
};

const PasswordInput: FC<TypeProps> = (props) => {
  const {
    value,
    defaultValue,
    onBlur,
    onChange,
    onKeyUp,
    placeholder = '',
    id,
    className,
    required = false,
    autoComplete,
    disabled,
    checkDifficulty,
    forwardRef,
  } = props;

  const [visibility, setVisibility] = useState<boolean>(false);

  const autocompleteProps = { autoComplete };

  const toggleVisibility = () => {
    setVisibility((value: boolean) => !value);
  };

  const strength = useGetPasswordStrength(defaultValue);

  const straightTextMap = {
    [PasswordStrength.Broken]: t('Очень слабый'),
    [PasswordStrength.Weak]: t('Слабый'),
    [PasswordStrength.Medium]: t('Хороший'),
    [PasswordStrength.Strong]: t('Очень хороший'),
  };

  const straightText = strength
    ? straightTextMap[strength]
    : straightTextMap[PasswordStrength.Weak];

  const isInputFocused = useFocus(forwardRef);
  return (
    <div>
      <InputGroup className={classNames(styles['input-wrapper'], {})}>
        <Input
          innerRef={forwardRef}
          placeholder={placeholder}
          type={visibility ? 'text' : 'password'}
          name={id}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          className={classNames(className)}
          defaultValue={defaultValue}
          required={required}
          {...autocompleteProps}
          value={value}
          onKeyUp={onKeyUp}
          disabled={disabled}
        />

        <InputGroupAddon addonType="append">
          <InputGroupText
            onClick={toggleVisibility}
            className={styles.visibilityIconContainer}
          >
            <VisibilityOnIcon
              className={classNames(styles.visibilityIconOn, {
                'd-none': visibility,
              })}
            />
            <VisibilityOffIcon
              className={classNames(styles.visibilityIconOff, {
                'd-none': !visibility,
              })}
            />
          </InputGroupText>
        </InputGroupAddon>
        {defaultValue && isInputFocused && checkDifficulty && (
          <PasswordVerificationTips password={defaultValue} />
        )}
      </InputGroup>
      {checkDifficulty && <UnderLines strength={strength} />}

      {checkDifficulty && (
        <div
          className={classNames(
            'mt-2 d-flex justify-content-end',
            styles.strengthText
          )}
        >
          {straightText}
        </div>
      )}
    </div>
  );
};

export default memo(PasswordInput);
