import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import { IBillingAccount } from './IBillingAccount';
import {
  IUpdateBillingAccount,
  ICreateBillingAccount,
} from './ICreateBillingAccountParams';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import {
  IGetPaginatedBillingAccountsParams,
  IBillingAccountFilters,
} from './IGetPaginatedBillingAccountsParams';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import IPrimaryKey from 'services/types/IPk';
import IKeyValueT from '../../interfacesApi/IKeyValueT';
import IAddCommentsBillingAccountParams from './IAddCommentsBillingAccountParams';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IGetHistoryBillingAccountPayload } from './IHistoryBillingAccount';
import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import IBillingAccountWrapper from './IBillingAccountWrapper';
import IBillingAccountClosedParams from './IBillingAccountClosedParams';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.BILLING_ACCOUNT
  );

export const createBillingAccountV2 = async (
  params: IBaseParams<ICreateBillingAccount>
): Promise<IBaseResponse<IBillingAccountWrapper>> => api().create(params);

export const updateBillingAccountV2 = async (
  params: IBaseParams<IUpdateBillingAccount>
): Promise<IBaseResponse<IBillingAccountWrapper>> => await api().update(params);

export const readBillingAccountV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IBillingAccountWrapper>> => api().read(params);

export const getPaginatedBillingAccountsV2 = async (
  params: IBaseParams<
    IGetPaginatedBillingAccountsParams | IBillingAccountFilters
  >
): Promise<IBaseResponse<IBillingAccount[]>> => api().getPaginated(params);

export const deleteBillingAccountApiV2 = async (
  deleteParams: IBaseParams<IKeyValueT<{ pk: number }>>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectBillingAccountApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const addCommentBillingAccountApiV2 = async (
  params: IBaseParams<IAddCommentsBillingAccountParams>
): Promise<IBaseResponse<{ billing_account: IBillingAccount }>> =>
  api().addComment(params);

export const getCommentsBillingAccountApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IGetCommentsPayloadV2>> => api().getComments(params);

export const getHistoryBillingAccountApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IGetHistoryBillingAccountPayload>> =>
  api().getHistory(params);

export const exportBillingAccountApiV2 = async (
  filterParams: IBaseParams<IBillingAccountFilters>
): Promise<IBaseResponse<string>> => api().export(filterParams);

export const doCloseBillingAccountApi = async (
  params: IBaseParams<IBillingAccountClosedParams>
): Promise<IBaseResponse<IBillingAccount>> => api().update(params);
