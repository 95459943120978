import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Row } from 'reactstrap';
import { t } from 'services/utils/translation';
import { IValueDifference } from 'store/form/actions';
import { getDiffValuesFromStore } from 'store/form/selectors';
import { makeFirstLetterUppercase } from '../../../services/utils/stringHelper/stringHelper';

type PropsTypeValueDiffContainer = {
  diffValues: IValueDifference[];
};

const ValueDiffContainerPrompt: FC<PropsTypeValueDiffContainer> = ({
  diffValues,
}) => {
  return (
    <table className="table  table-hover table-outline mb-0">
      <th>{t('Поле')}</th> <th>{t('Старое значение')}</th>
      <th>{t('Несохраненное значение')}</th>
      {diffValues.map(({ nameProp, newValue, prevValue }: IValueDifference) => {
        return (
          <tr key={nameProp}>
            <td>
              <span>{makeFirstLetterUppercase(nameProp)}</span>
            </td>
            <td>
              <span>{prevValue}</span>
            </td>
            <td>
              <span className="text-warning">{newValue}</span>
            </td>
          </tr>
        );
      })}
    </table>
  );
};

type PropsTypeCustomPrompt = {
  cancel: Function;
  ok: Function;
  message: string;
};

export const ContainerMessagePrompt: FC<PropsTypeCustomPrompt> = ({
  ok,
  cancel,
  message,
}: PropsTypeCustomPrompt) => {
  const diffValues = useSelector(getDiffValuesFromStore);

  return (
    <Container>
      <Row>
        <div>
          <p className="">{message}</p>
        </div>
      </Row>
      {diffValues && diffValues?.length > 0 && (
        <Row>{<ValueDiffContainerPrompt diffValues={diffValues} />}</Row>
      )}
      <Row className="justify-content-end pt-2">
        <Button color="primary" onClick={() => ok()}>
          {t('Да')}
        </Button>
        <Button className="ml-2" color="primary" onClick={() => cancel()}>
          {t('Отмена')}
        </Button>
      </Row>
    </Container>
  );
};

type PropsTypeWrapperCustomPrompt = {
  message: string;
  open: boolean;
  handleCancel: Function;
};

const WrapperCustomPrompt: FC<
  PropsWithChildren<PropsTypeWrapperCustomPrompt>
> = (props) => {
  const { open, handleCancel, children } = props;
  return (
    <ModalWrapper
      headerLabel="Уход со страницы"
      isOpen={open}
      closeHandler={handleCancel}
    >
      {children}
    </ModalWrapper>
  );
};

export default WrapperCustomPrompt;
