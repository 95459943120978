import { Formik } from 'formik';
import React, { FC, memo } from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';

import { t } from 'services/utils/translation';
import { usePostV3 } from '../../../../hooks/usePostV3';

import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import classNames from 'clsx';
import ButtonClose from 'components/controls/ButtonClose/ButtonClose';
import {
  createControllerApi,
  updateControllerApi,
} from 'services/api/controller/controllerApi';
import { CONTROLLER_TEXT } from 'services/localLocalization/servicesAndModels';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';
import Controller from 'models/Controller/Controller';
import { preprocessValuesController } from './utils/preprocessValuesController';
import { IUpdateController } from 'services/api/controller/IUpdateController';
import { ICreateController } from 'services/api/controller/ICreateController';
import useControllerToClosed from 'hooks/useControllToClosed/useControllToClosed';
import ControllerComments from '../ControllerComments/ControllerComments';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ControllerFileldsWrapper from './ui/ControllerFileldsr/ControllerFilelds';
import { getControllerFieldsValueFromStore } from 'store/controller/selectors';

export interface IFormValuesController {
  controller_pk?: string;
  controller_description?: string;
  controllerBillingAccount?: IClientSelectOptionV2;
  controllerIsClosed?: boolean;
  controller_new_comment?: string;
}

type PropsType = {
  successHandler: () => void;
  controller_pk?: string;
  initialValues?: IFormValuesController;
  isLoading?: boolean;
  isViewOnly?: boolean;
  refreshData?: () => void;
  billingAccountPk?: number;
};

const ControllerForm: FC<PropsType> = (props) => {
  const {
    successHandler,
    controller_pk,
    initialValues,
    isLoading,
    isViewOnly = false,
    refreshData,
  } = props;

  const billingAccountPk: number | undefined =
    useLocation().state?.billingAccountPk;

  const isNew = !controller_pk;
  const isDefaultBillingAccount = !!billingAccountPk;
  const fieldValues = useSelector(getControllerFieldsValueFromStore);

  const newInitialValues =
    initialValues || new Controller(undefined, billingAccountPk);

  const updateController = usePostV3({
    fetchApi: updateControllerApi,
    successHandler,
    successMessage: `${makeFirstLetterUppercase(CONTROLLER_TEXT)} ${t(
      'обновлено успешно.'
    )}`,
  });

  const createController = usePostV3({
    fetchApi: createControllerApi,
    successHandler,
    successMessage: `${makeFirstLetterUppercase(CONTROLLER_TEXT)} ${t(
      'создано успешно.'
    )}`,
  });

  const closeControllerAndRefreshForm = useControllerToClosed();

  const doCloseOrOpen = async (controllerIsClosed: boolean, pk: string) => {
    await closeControllerAndRefreshForm({
      controller_is_closed: !controllerIsClosed,
      pk,
    });
    refreshData && refreshData();
  };

  return (
    <Row className="mr-0">
      <Formik
        enableReinitialize={true}
        initialValues={
          controller_pk &&
          fieldValues?.[controller_pk]?.controllerBillingAccount
            ? fieldValues[controller_pk]
            : newInitialValues
        }
        validationSchema={Controller.validationSchema()}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);
          const newValues = preprocessValuesController(values);
          if (!isNew) {
            await updateController(
              newValues as IUpdateController,
              setErrors,
              resetForm
            );
          } else {
            await createController(
              newValues as ICreateController,
              setErrors,
              resetForm
            );
          }
          setSubmitting(false);
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Col>
            {!isLoading && (
              <Form
                onSubmit={handleSubmit}
                noValidate={true}
                style={{ maxWidth: '100%' }}
                className={classNames(
                  'd-flex flex-column flex-md-row justify-content-md-between ml-4 mr-4 mr-md-auto pl-3'
                )}
              >
                <div
                  className={classNames('my-2 my-md-3 py-3 w-100')}
                  style={{
                    maxWidth: '800px',
                  }}
                >
                  <ControllerFileldsWrapper
                    isNew={isNew}
                    isViewOnly={isViewOnly}
                    isDefaultBillingAccount={isDefaultBillingAccount}
                    permanentInitialValue={initialValues}
                  />
                  <div className="d-flex flex-end align-items-center justify-content-end">
                    {!isViewOnly &&
                      controller_pk &&
                      values?.controllerIsClosed !== undefined && (
                        <div className="mr-4 mt-2">
                          <ButtonClose
                            isClosed={values?.controllerIsClosed}
                            pk={controller_pk}
                            handler={doCloseOrOpen}
                          />
                        </div>
                      )}

                    {!isViewOnly && (
                      <FormGroup className="d-block text-right mt-4">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {t('Сохранить')}
                        </Button>
                      </FormGroup>
                    )}
                  </div>
                </div>
              </Form>
            )}
            {isLoading && <LoaderFetch />}
          </Col>
        )}
      </Formik>
      <div
        className={classNames('ml-md-4', {
          'd-none': !controller_pk,
        })}
      >
        {!isNew && controller_pk && (
          <ControllerComments pk={controller_pk} viewOnly={isViewOnly} />
        )}
      </div>
    </Row>
  );
};

export default memo(ControllerForm);
