import { useCallback } from 'react';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import ExternalRequestConvertParams from '../utils/ExternalRequestConvertParams/ExternalRequestConvertParams';

const useComposeExternalRequestFilters = () => {
  return useCallback((params: IExternalRequestFilters) => {
    const convertParams = new ExternalRequestConvertParams(params);
    return convertParams.composeParamsGetExternalRequests();
  }, []);
};
//todo: update ExternalRequestConvertParams for add permanent value 
export const useComposeExternalRequestFiltersForExport = () => {
  return useCallback((params: IExternalRequestFilters) => {
    const convertParams = new ExternalRequestConvertParams(params);
    return {
      ...convertParams.composeParamsGetExternalRequests(),
      external_request_is_pruned: false,
    };
  }, []);
};

export default useComposeExternalRequestFilters;
