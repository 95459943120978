import { initialTasksFilters } from 'store/task/initialTasksFilters';
import {
  SET_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA,
  CLEAR_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA,
  SET_IS_ACTIVE_1C_FILTER,
  BILLING_ACCOUNT_SAVE_TASK_FILTERS,
  BILLING_ACCOUNT_SAVE_EXTERNAL_REQUEST_FILTERS,
  BILLING_ACCOUNT_CLEAR_TASK_FILTERS,
  BILLING_ACCOUNT_CLEAR_EXTERNAL_REQUEST_FILTERS,
  SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER,
  SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER,
  SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER,
  BILLING_ACCOUNT_SAVE_FILTERS,
  BILLING_ACCOUNT__CLEAR_FILTERS,
} from './actionTypes';
import { initialExternalRequestFilters } from 'store/externalRequest/initialExternalRequestFilters';

const billingAccountReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case BILLING_ACCOUNT_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case BILLING_ACCOUNT__CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.filters,
      };

    case SET_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA: {
      return {
        ...state,
        defaultValueFormData: action.payload,
      };
    }

    case CLEAR_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA: {
      return {
        ...state,
        defaultValueFormData: {},
      };
    }
    case SET_IS_ACTIVE_1C_FILTER: {
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          isActive1CNumberFilter: action.payload,
        },
      };
    }

    case BILLING_ACCOUNT_SAVE_TASK_FILTERS: {
      const { pk, filters } = action.payload;
      return {
        ...state,
        taskTableFilters: {
          ...state.taskTableFilters,
          [pk]: {
            ...state.taskTableFilters?.[pk],
            ...filters,
          },
        },
      };
    }

    case BILLING_ACCOUNT_SAVE_EXTERNAL_REQUEST_FILTERS: {
      const { pk, filters } = action.payload;
      return {
        ...state,
        externalRequestTableFilters: {
          ...state.externalRequestTableFilters,
          [pk]: {
            ...state.externalRequestTableFilters?.[pk],
            ...filters,
          },
        },
      };
    }

    case BILLING_ACCOUNT_CLEAR_TASK_FILTERS: {
      return {
        ...state,
        taskTableFilters: {
          ...state.taskTableFilters,
          [action.payload]: {
            ...initialTasksFilters,
            internal_task_billing_account_fk: action.payload,
          },
        },
      };
    }

    case BILLING_ACCOUNT_CLEAR_EXTERNAL_REQUEST_FILTERS: {
      return {
        ...state,
        externalRequestTableFilters: {
          ...state.externalRequestTableFilters,
          [action.payload]: {
            ...initialExternalRequestFilters,
            external_request_billing_account_fk: action.payload,
          },
        },
      };
    }

    case SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER: {
      const { eventPk, isActivePkFilter } = action.payload;
      return {
        ...state,
        externalRequestTableFilters: {
          ...state.externalRequestTableFilters,
          [eventPk]: {
            ...state.externalRequestTableFilters?.[eventPk],
            isActivePkFilter,
          },
        },
      };
    }

    case SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER: {
      const { eventPk, isActiveWorkCategoryFilter } = action.payload;
      return {
        ...state,
        externalRequestTableFilters: {
          ...state.externalRequestTableFilters,
          [eventPk]: {
            ...state.externalRequestTableFilters?.[eventPk],
            isActiveWorkCategoryFilter,
          },
        },
      };
    }

    case SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER: {
      const { eventPk, isActiveImplementerDkArrayFilter } = action.payload;
      return {
        ...state,
        externalRequestTableFilters: {
          ...state.externalRequestTableFilters,
          [eventPk]: {
            ...state.externalRequestTableFilters?.[eventPk],
            isActiveImplementerDkArrayFilter,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default billingAccountReducer;
