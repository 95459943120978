import { FC } from 'react';
import { Formik } from 'formik';
import ChangeMyPassword from '../../../models/ChangeMyPassword';
import { usePostV3 } from 'hooks/usePostV3';
import { changePasswordApiV2 } from 'services/api/identity/identity';
import { Form, FormGroup, Button } from 'reactstrap';
import { t } from 'services/utils/translation';
import PasswordField from 'components/controls/PasswordField/PasswordField';

type PropsType = {
  successHandler: () => void;
};

export const IdentityChangeMyPassword: FC<PropsType> = ({ successHandler }) => {
  const changePassword = usePostV3({
    fetchApi: changePasswordApiV2,
    successHandler,
  });
  return (
    <Formik
      enableReinitialize={true}
      initialValues={new ChangeMyPassword()}
      validationSchema={ChangeMyPassword.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        await changePassword(values, setErrors);

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} noValidate={true}>
          <PasswordField
            id="plain_old_password"
            label={t('Старый Пароль')}
            placeholder={t('Введите пароль')}
            hasError={
              errors.plain_old_password != null &&
              touched.plain_old_password != null
            }
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.plain_old_password}
            autoComplete="on"
          />
          <PasswordField
            id="plain_new_password"
            label={t('Новый Пароль')}
            placeholder={t('Введите пароль')}
            hasError={
              errors.plain_new_password != null &&
              touched.plain_new_password != null
            }
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.plain_new_password}
            autoComplete="on"
            checkDifficulty
          />

          <FormGroup className="d-block text-right mt-4">
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {t('Сохранить')}
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};
