const shouldLoadMore = (
  scrollHeight: number,
  clientHeight: number,
  scrollTop: number
) => {
  const bottomBorder = (scrollHeight - clientHeight) / 3;

  return bottomBorder < scrollTop;
};

export default shouldLoadMore;
