import { useCallback } from 'react';
import {
  IControllerForList,
  toControllersForList,
} from '../utils/toControllerForList/toControllerForList';
import { IController } from 'services/api/controller/IController';

export const useToControllerForList = () => {
  return useCallback((controllers: IController[]): IControllerForList[] => {
    return toControllersForList(controllers);
  }, []);
};
