import { FC, useCallback, useMemo } from 'react';
import { useGetList } from '../../../../hooks/useGetList';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { t } from 'services/utils/translation';
import { ReactComponent as DetailIcon } from 'img/icons/icon-file.svg';
import { useColumnFilter } from '../../../../hooks/useColumnFilter';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import { ReactComponent as CloseIcon } from 'img/icons/icon-padlock.svg';
import { ReactComponent as OpenIcon } from 'img/icons/icon-unlock.svg';

import LoaderFetch from '../../../../components/layouts/LoaderFetch/LoaderFetch';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import { Pager } from '../../../../components/tables/Pager';
import useModalV2 from 'hooks/useModalV2';
import { IModal } from 'hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import { UI_TITLE } from '../../../../services/localLocalization/UITitle';
import { extractGetLeftValueIfTrue } from '../../../../services/utils/extractGetLeftValueIfTrue';
import {
  deleteEventApi,
  getPaginatedEventsApi,
} from 'services/api/organizationsV2/event/eventApi';
import { IEventFilters } from 'services/api/organizationsV2/event/IGetPaginatedParamsEvent';
import { SortCriteriaEvent } from '../../../../services/api/organizationsV2/event/SortCriteriaEvent';
import HeaderWithDynamicSelectPaginatedV2 from 'components/tables/HeaderWithDynamicSelectPaginatedV2/HeaderWithDynamicSelectPaginatedV2';
import { useComposeParamsGetEvent } from './hooks/useComposeParamsGetEvent';
import { getForSelectEventTypeApiV2 } from 'services/api/organizationsV2/event-type/eventTypeApi';
import classNames from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
  PATH_ORGANIZATIONS_EVENT_EDIT,
  PATH_ORGANIZATIONS_EVENT_VIEW,
} from 'services/pathConstants';
import { makeLink } from 'services/localPaths';
import EventForm from 'components/organizations/event/EventForm/EventForm';
import { useToEventForList } from './hooks/useToEventForList';
import { IEventForList } from './IEventForList';
import HeaderWithDatePickerRangeV2 from 'components/tables/HeaderWithDatePickerRangeV2/HeaderWithDatePickerRangeV2';
import { getForSelectBillingAccountApiV2 } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import TDCheckBox from 'components/tables/TDCheckBox/TDCheckBox';
import useNextWindow from 'hooks/useNextWindow';
import {
  COLUMN_LABELS_EVENT,
  columnVisibilityInitialEvent,
  filtersEvent,
} from './columnLabelsEvent';
import { useDispatch, useSelector } from 'react-redux';
import useEventHandleSearch from './hooks/useEventHandleSearch';
import { getEventFiltersFromStore } from 'store/event/selectors';
import isEqual from 'react-fast-compare';
import { clearEventFilters, saveEventFilters } from 'store/event/actions';
import RefreshButton from 'components/tables/RefreshButton/RefreshButton';
import useClearFilters from 'hooks/useClearFilters';
import initialEventFilters from 'store/initialStore/initialEventFilters';
import useEventToClosed from 'hooks/useEventToClosed/useEventToClosed';
import styles from './index.module.scss';
import { getDropDownEditTitle } from '../../../../services/utils/extractGetLeftValueIfTrue';
import useFilterBooleanSelect, {
  BooleanSelectField,
} from 'hooks/useFilterIsPruned/useFilterBooleanSelect';
import HeaderWithSelectAdvanced from 'components/tables/HeaderWithSelectAdvanced/HeaderWithSelectAdvanced';
import { IEventClosedParams } from 'services/api/organizationsV2/event/IEventIsClodedParams';
import useGetEventAccessPolicy from './hooks/useGetEventAccessPolicy';

const EVENT_IS_CLOSED_HINT = t('Событие закрыто');
const EVENT_IS_OPEN_HINT = t('Событие открыто');

const deleteMessageError = t('Не удалось удалить тип события');
const CREATE_BUTTON_TITLE = t('Добавить событиe');
const MODAL_CREATE_TITLE = t('Создать событиe');

const OPEN_TEXT_DROPDOWN_ITEM = t('Открыть событие');
const CLOSE_TEXT_DROPDOWN_ITEM = t('Закрыть событие');

const getDropdownCloseText = extractGetLeftValueIfTrue(
  OPEN_TEXT_DROPDOWN_ITEM,
  CLOSE_TEXT_DROPDOWN_ITEM
);

const MODAL_DELETE_TITLE = t('Удалить событиe');

type EventPagePropsType = {
  billingAccountPk?: number;
};

const EventPage: FC<EventPagePropsType> = (props) => {
  const { billingAccountPk } = props;
  const isViewPage = !!billingAccountPk;

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitialEvent);

  const defaultTableFilters = useSelector(getEventFiltersFromStore, isEqual);

  const initialParams: IEventFilters = useMemo(
    () => ({
      ...defaultTableFilters,
      event_billing_account_fk: billingAccountPk,
    }),
    [defaultTableFilters, billingAccountPk]
  );

  const location = useLocation();
  const dispatchRedux = useDispatch();

  const {
    billingAccountHaveAccessToRead,
    eventTypeHaveAccessToRead,
    event: { haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete },
  } = useGetEventAccessPolicy();

  const saveFilters = (filters: IEventFilters) =>
    dispatchRedux(saveEventFilters(filters));

  const composeParamsGetEvent = useComposeParamsGetEvent();
  const toEventForList = useToEventForList();

  const onFinally = () => setClearingFiltersSpinner(false);

  const {
    data: eventList,
    isLoading,
    refreshListData,
    total,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    setParams,
    params: {
      skip,
      length,
      event_pk: filterPk,
      event_name: filterName,
      event_type_fk_array: filterEventTypeFkArray,
      event_billing_account_fk_array: filterBillingAccountFkArray,
      event_is_closed: filterIsClosed,
      event_datetime_added_lower_bound: filterDatetimeAddedLower,
      event_datetime_added_upper_bound: filterDatetimeAddedUpper,
      event_datetime_edited_lower_bound: filterDatetimeEditedLower,
      event_datetime_edited_upper_bound: filterDatetimeEditedUpper,
    },
    params,
    refreshAndReturnToStartPosition,
  } = useGetList({
    getDataApi: getPaginatedEventsApi,
    initialParams,
    convertedParameters: composeParamsGetEvent,
    convertData: toEventForList,
    saveFilters,
    onFinally,
  });
  const closeHandler = useEventToClosed();

  const doCloseOrOpenEvent = useCallback(
    async (params: IEventClosedParams) => {
      await closeHandler(params);
      refreshListData();
    },
    [closeHandler, refreshListData]
  );

  const { clearFilters, isDisableClearButton, setClearingFiltersSpinner } =
    useClearFilters(initialEventFilters, params, clearEventFilters, setParams, {
      event_billing_account_fk: billingAccountPk,
    });

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetEvent },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteEventApi,
    entityIdKey: 'pk',
  });

  //SEARCH

  const {
    handleSearchPk,
    handleSearchEventName,
    handleSelectEvenTypeArray,
    handleSelectBillingAccountArray,
  } = useEventHandleSearch(onSearchRequest);

  const { handleSelectBooleanType, booleanOptions } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.event,
  });

  //NAVIGATION CONTROL

  const nextWindowEventEditPage = useNextWindow(
    PATH_ORGANIZATIONS_EVENT_EDIT,
    location.pathname
  );

  const openEventViewPage = useNextWindow(PATH_ORGANIZATIONS_EVENT_VIEW);

  return (
    <MainLayout isInWrapper={isViewPage}>
      <Subheader>
        <div style={{ display: 'flex' }} className="mr-5">
          <SubheaderButton
            onClick={clearFilters}
            bordered={true}
            disabled={isDisableClearButton}
          >
            {UI_TITLE.REMOVE_FILTERS}
          </SubheaderButton>
          <RefreshButton
            toggleRefresh={refreshAndReturnToStartPosition}
            isLoading={isLoading}
          />
          {haveAccessToCreate && (
            <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
              <DetailIcon className="mr-2 text-white" />
              {CREATE_BUTTON_TITLE}
            </SubheaderButton>
          )}
        </div>
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={classNames(
                  `id pr-0 ${!visibility.pk ? 'd-none' : ''}`,
                  styles.columnEventPk
                )}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaEvent.PkAsk}
                  title={COLUMN_LABELS_EVENT.PK}
                  onSort={onSortClick}
                  onSearch={handleSearchPk}
                  defaultValue={filterPk ? filterPk + '' : ''}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={classNames(
                  `p-2 ${!visibility.event_name ? 'd-none' : ''}`,
                  styles.columnEventName
                )}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaEvent.NameAsc}
                  title={COLUMN_LABELS_EVENT.NAME}
                  onSort={onSortClick}
                  onSearch={handleSearchEventName}
                  defaultValue={filterName}
                />
              </th>
              <th
                scope="col"
                className={classNames(
                  `p-0 ${!visibility.event_name ? 'd-none' : ''}`,
                  styles.columnEventType
                )}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  id="event_type_fk_array"
                  selectHandler={getForSelectEventTypeApiV2}
                  title={COLUMN_LABELS_EVENT.TYPE}
                  isMulti={true}
                  options={filterEventTypeFkArray}
                  onChange={handleSelectEvenTypeArray}
                  haveReadPermission={eventTypeHaveAccessToRead}
                />
              </th>
              <th
                scope="col"
                className={classNames(
                  `p-2 ${!visibility.event_billing_account ? 'd-none' : ''}`,
                  styles.columnEventBillingAccount
                )}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  selectHandler={getForSelectBillingAccountApiV2}
                  id="internal_task_billing_account_fk_array"
                  title={COLUMN_LABELS_EVENT.BILLING_ACCOUNT}
                  options={filterBillingAccountFkArray}
                  isMulti={true}
                  onChange={handleSelectBillingAccountArray}
                  disabled={isViewPage}
                  haveReadPermission={billingAccountHaveAccessToRead}
                />
              </th>
              <th
                scope="col"
                className={classNames(
                  'p-0',
                  !visibility.event_is_closed && 'd-none',
                  styles.columnEventIsClosed
                )}
              >
                <HeaderWithSelectAdvanced
                  label={COLUMN_LABELS_EVENT.IS_CLOSED}
                  options={booleanOptions}
                  onChange={handleSelectBooleanType}
                  defaultValue={filterIsClosed}
                />
              </th>
              <th
                scope="col"
                className={classNames('p-0', styles.columnEventDatetimeAdded, {
                  'd-none': !visibility.event_datetime_added,
                })}
              >
                <HeaderWithDatePickerRangeV2
                  title={COLUMN_LABELS_EVENT.CREATE_DATE}
                  propsOnSearch={{
                    onSearchRequest,
                    keyDate: [
                      'event_datetime_added_lower_bound',
                      'event_datetime_added_upper_bound',
                    ],
                  }}
                  startDate={
                    filterDatetimeAddedLower
                      ? new Date(filterDatetimeAddedLower)
                      : null
                  }
                  endDate={
                    filterDatetimeAddedUpper
                      ? new Date(filterDatetimeAddedUpper)
                      : null
                  }
                  field={SortCriteriaEvent.DateAddedAsc}
                  onSort={onSortClick}
                  hasTime
                />
              </th>
              <th
                scope="col"
                className={classNames('p-0', styles.columnEventDatetimeEdited, {
                  'd-none': !visibility.event_datetime_edited,
                })}
              >
                <HeaderWithDatePickerRangeV2
                  title={COLUMN_LABELS_EVENT.EDITING_DATE}
                  propsOnSearch={{
                    onSearchRequest,
                    keyDate: [
                      'event_datetime_edited_lower_bound',
                      'event_datetime_edited_upper_bound',
                    ],
                  }}
                  startDate={
                    filterDatetimeEditedLower
                      ? new Date(filterDatetimeEditedLower)
                      : null
                  }
                  endDate={
                    filterDatetimeEditedUpper
                      ? new Date(filterDatetimeEditedUpper)
                      : null
                  }
                  field={SortCriteriaEvent.DateEditedAsc}
                  onSort={onSortClick}
                  hasTime
                />
              </th>
              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filtersEvent.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {eventList &&
                eventList.map((targetEvent: IEventForList) => {
                  const {
                    event_pk: pk,
                    event_name: name,
                    event_type: type,
                    event_billing_account: {
                      billing_account_code_number: billingAccountCodeNumber,
                      billing_account_pk: billingAccountPk,
                    },
                    editedDate,
                    createDate,
                    event_is_closed: eventIsClosed,
                  } = targetEvent;
                  return (
                    <tr key={pk}>
                      <td
                        className={classNames(styles.columnEventPk, {
                          'd-none': !visibility.pk,
                        })}
                      >
                        <span>
                          <Link
                            to={makeLink(PATH_ORGANIZATIONS_EVENT_VIEW, pk)}
                          >
                            {pk}
                          </Link>
                        </span>
                      </td>
                      <td
                        className={classNames(styles.columnEventName, {
                          'd-none': !visibility.event_name,
                        })}
                      >
                        <span>{name}</span>
                      </td>
                      <td
                        className={classNames(styles.columnEventType, {
                          'd-none': !visibility.event_type_fk_array,
                        })}
                      >
                        <span>{type.event_type_rendition}</span>
                      </td>

                      <td
                        className={classNames(
                          styles.columnEventBillingAccount,
                          {
                            'd-none': !visibility.event_billing_account,
                          }
                        )}
                      >
                        <Link
                          to={makeLink(
                            PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
                            billingAccountPk
                          )}
                        >
                          {billingAccountCodeNumber}
                        </Link>
                      </td>
                      <td
                        className={classNames(styles.columnEventIsClosed, {
                          'd-none': !visibility.event_is_closed,
                        })}
                      >
                        <TDCheckBox
                          isCheck={eventIsClosed}
                          hint={
                            eventIsClosed
                              ? EVENT_IS_CLOSED_HINT
                              : EVENT_IS_OPEN_HINT
                          }
                          id={`isDriver_${pk}`}
                        />
                      </td>
                      <td
                        className={classNames(styles.columnEventDatetimeAdded, {
                          'd-none': !visibility.event_datetime_added,
                        })}
                      >
                        <span>{createDate}</span>
                      </td>
                      <td
                        className={classNames(
                          styles.columnEventDatetimeEdited,
                          {
                            'd-none': !visibility.event_datetime_edited,
                          }
                        )}
                      >
                        <span>{editedDate}</span>
                      </td>
                      <td align="right">
                        <ActionsDropdown>
                          <ActionsDropdownItem
                            label={UI_TITLE.DETAIL}
                            onClick={openEventViewPage.bind(null, pk)}
                            icon={<DetailIcon className="text-primary" />}
                          />
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={getDropDownEditTitle(haveAccessToUpdate)}
                              onClick={nextWindowEventEditPage.bind(null, pk)}
                              icon={<EditIcon className="text-primary" />}
                            />
                          )}
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={getDropdownCloseText(eventIsClosed)}
                              onClick={doCloseOrOpenEvent.bind(null, {
                                pk,
                                event_is_closed: !eventIsClosed,
                              })}
                              icon={
                                eventIsClosed ? (
                                  <OpenIcon className="text-primary" />
                                ) : (
                                  <CloseIcon className="text-primary" />
                                )
                              }
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                rendition: name,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <LoaderFetch />
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />
        <ModalWrapper
          headerLabel={MODAL_CREATE_TITLE}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <EventForm
            successHandler={successHandler}
            pk={targetEvent?.pk}
            isLoading={false}
            isViewOnly={false}
            billingAccountPk={billingAccountPk}
          />
        </ModalWrapper>

        {targetEvent?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetEvent?.pk}
            entityName={targetEvent?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default EventPage;
