import React, { FC, memo } from 'react';
import { AddressSuggestions, PartySuggestions } from 'react-dadata';
import {
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Button,
} from 'reactstrap';
import classNames from 'clsx';
import 'react-dadata/dist/react-dadata.css';
import TipButton from '../TipButton';
import { FocusEventHandler } from 'react-select';
import { useSelector } from 'react-redux';
import config from 'config.json';
import { ChangeEvent } from 'react';
import styles from './index.module.scss';
import { IButtonAddon } from '../../../services/interfaces/IButtonAddon';
import { getDomainFromStore } from '../../../store/auth/selectors';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';

enum Options {
  delay = 300, // задержка для загрузки подсказок
  minChairs = 1, // минимальное количество символов для показа подсказок
}

export enum TypeSuggestion {
  address = 'address',
  party = 'party',
}

interface IInputProps {
  placeholder?: string;
  required?: boolean;
  type?: string; // тип инпута
  id?: string;
  name?: string;
  className?: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void; // хэндлер для инпута
  style?: any;
  onBlur?: FocusEventHandler;
  disabled?: boolean;
}

type PropsType = {
  buttonAddon?: IButtonAddon;
  inputProps: IInputProps;
  highlightEditing?: boolean;

  id: string;
  placeholder?: string;
  value?: string;
  onChangeSelect: Function; // хэндлер для компонента подсказок
  label: string;
  typeSuggestion: TypeSuggestion; // Тип подсказок
  helpText?: string; // подсказка для инпута
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean; // Регистрация некорректного вода от formik. Если true выводит сообщение
  type?: string; // тип инпута
  defaultValue?: string; // значение, которое будет проставляться в инпут если есть изначальные данные
  renderIfDefaultValue?: boolean;
};

const SUGGESTION_MAP = { address: AddressSuggestions, party: PartySuggestions };

const DaDataBaseField: FC<PropsType> = (props) => {
  const {
    label,
    helpText,
    id,
    inputProps,
    onChangeSelect,
    defaultValue,
    hasError,
    inputProps: { placeholder, className },
    buttonAddon,
    typeSuggestion,
    highlightEditing,
  } = props;
  const Component = SUGGESTION_MAP[typeSuggestion];

  const inputPropsForSuggestions = {
    ...inputProps,
    id,
    value: defaultValue,
    name: id,
    placeholder: placeholder ? placeholder : label,
    className: classNames(className, 'form-control', {
      'is-invalid': hasError,
    }),
  };

  const domain = useSelector(getDomainFromStore);
  const currentKladrId = config.kladrId[domain];

  return (
    <>
      <FormGroup className={inputProps.required ? 'required' : ''}>
        {(label || helpText) && (
          <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
            {label && (
              <Label className="control-label" for={id}>
                {label}
              </Label>
            )}
            {helpText && <TipButton id={id} helpText={helpText} />}
          </div>
        )}
        <ErrorWrapperField id={id}>
          <InputGroup
            className={classNames('flex-nowrap', {
              'is-invalid': hasError,
              borderBackLight: highlightEditing && !hasError,
            })}
          >
            <Component
              defaultQuery={defaultValue}
              inputProps={{ ...inputPropsForSuggestions }}
              token={
                process.env.REACT_APP_DADATA_TOKEN
                  ? process.env.REACT_APP_DADATA_TOKEN
                  : ''
              }
              onChange={(suggestion?: any): void => {
                onChangeSelect(suggestion);
              }}
              containerClassName={classNames(
                { 'is-invalid': hasError },
                styles.dadataContainer
              )}
              {...Options}
              filterLocationsBoost={[{ kladr_id: currentKladrId }]}
              autoload
            />

            {buttonAddon && (
              <InputGroupAddon addonType="prepend">
                <Button
                  color="primary"
                  onClick={() => buttonAddon.handleClickAddon()}
                  className={classNames(buttonAddon.classNameButton)}
                  disabled={buttonAddon.disabled}
                >
                  {buttonAddon.textButton}
                </Button>
              </InputGroupAddon>
            )}
          </InputGroup>
        </ErrorWrapperField>
      </FormGroup>
    </>
  );
};

export default memo(DaDataBaseField);
