import { IIdentityState } from 'components/identity/EditingEntitiesFromIdentityForm/interfaces/IIdentityState';
import {
  SET_CURRENT_IDENTITY_IN_CONTROL_PANEL,
 } from './actionTypes';

export const setCurrentIdentityInControlPanel = (payload: IIdentityState) => {
  return {
    type: SET_CURRENT_IDENTITY_IN_CONTROL_PANEL,
    payload,
  };
};
