import { TargetAccessPolicyService } from './TargetAccessPolicyService';
import { TKeysModelAll } from '../../api/staff/access-policy/IAccessPolicy';

export class TargetAccessPolicy {
  private readonly targetService: TargetAccessPolicyService;
  private readonly modelKey: TKeysModelAll;

  constructor(
    targetService: TargetAccessPolicyService,
    modelKey: TKeysModelAll
  ) {
    this.targetService = targetService;
    this.modelKey = modelKey;
  }

  getTargetAccessPolicyStatement = () => {
    return {
      serviceKey: this.targetService.serviceName,
      modelKey: this.modelKey,
    };
  };
}
