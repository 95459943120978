import React, { FC, memo } from 'react';
import HeaderWithSearch from '../HeaderWithSearch';
import HeaderWithSort from '../HeaderWithSort/HeaderWithSort';
import styles from './index.module.scss';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';

type PropsType = {
  onSearch: (searchString: string) => void;
  onSort: (sortCriterion: string) => void;
  field: string;
  title: string;
  defaultValue?: string;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
  headerColor?: TABLE_COLOR;
  isOnlyNumbers?: boolean;
};

const HeaderWithSearchAndSort: FC<PropsType> = (props) => {
  const {
    title,
    onSearch,
    onSort,
    field,
    defaultValue,
    customStyle,
    disabled,
    headerColor,
    isOnlyNumbers,
  } = props;

  return (
    <div className="d-flex flex-row flex-nowrap align-items-center">
      <HeaderWithSearch
        className={styles['search']}
        title={title}
        onSearch={onSearch}
        defaultValue={defaultValue}
        customStyle={customStyle}
        disabled={disabled}
        headerColor={headerColor}
        isOnlyNumbers={isOnlyNumbers}
      />
      <HeaderWithSort
        className={styles['sort']}
        field={field}
        onSort={onSort}
      />
    </div>
  );
};

export default memo(HeaderWithSearchAndSort);
