import ColorHelper from '../ColorHelper/ColorHelper';

class ColorCalculator extends ColorHelper {
  private readonly brightnessThreshold: number = 130;
  private readonly colorAdjustmentAmount: number = 40;
  private readonly redColorAddition: number = 0x440000;
  private readonly blueChannelWeight: number = 114;
  private readonly redChannelWeight: number = 299;
  private readonly greenChannelWeight: number = 587;
  private readonly colorMask: number = 0x00ffffff;

  private calculateHash(str: string): number {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << this.hashShift) - hash);
      hash = hash & hash;
    }

    return hash;
  }

  public generateColor(str: string): string {
    if (!str) {
      throw new Error('Invalid input: Please provide a non-empty string.');
    }
    const { blueChannelWeight, redChannelWeight, greenChannelWeight } = this;

    const hash = this.calculateHash(str);
    const color = `#${((hash & this.colorMask) | this.redColorAddition)
      .toString(16)
      .slice(0, this.hexStringLength)
      .padStart(this.hexStringLength, '0')}`;

    const brightness = this.calculateBrightness(color, {
      blueChannelWeight,
      redChannelWeight,
      greenChannelWeight,
    });

    if (brightness < this.brightnessThreshold) {
      return this.adjustBrightness(color, this.colorAdjustmentAmount);
    } else {
      return color;
    }
  }
}

export default ColorCalculator;
