import { useCallback } from 'react';

import { usePostV3 } from 'hooks/usePostV3';
import { getIdentityApiV2 } from 'services/api/identity/identity';
import IGetIdentitiesParams from 'services/api/identity/IGetIdentitiesParams';
import {
  IIdentity,
  InstallationListKeys,
} from '../../../../services/api/auth/IRegisterIdentity';
import { IGetIdentitiesFilters } from '../../../../services/api/identity/IGetIdentitiesParams';
import IGetIdentitiesPayload from 'services/api/identity/IGetIdentitiesPayload';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIdentityInControlPanel } from 'store/identity/actions';
import toClientIdentityState from '../utils/toClientIdentityState';
import { getIdentityInControlPanelFromStore } from 'store/identity/selectors';
import { IIdentityState } from '../interfaces/IIdentityState';

export const IDENTITY_CONTROL_PANEL_MAX_LENGTH = 5;

const hasOneIdentity = (identities: IIdentity[]) => identities.length === 1;

const thereIsMoreThanOne = (identities: IIdentity[]) => identities.length > 1;

const getFirstFoundIdentity = (identities: IIdentity[]) => identities[0];

export const toFiltersIdentityInstallationsSetting = (
  params: Pick<
    IGetIdentitiesFilters,
    'identity_nickname' | 'identity_phone_number'
  >
): IGetIdentitiesParams => ({
  ...params,
  skip: 0,
  length: IDENTITY_CONTROL_PANEL_MAX_LENGTH,
});

const useHandleClickButtonFindIdentity = (
  typeInstallations: InstallationListKeys
) => {
  const dispatch = useDispatch();

  const identity = useSelector(getIdentityInControlPanelFromStore);

  const handlePayload = useCallback(
    ({ list_of_identity_entities: listIdentity }: IGetIdentitiesPayload) => {
      if (!listIdentity || listIdentity?.length <= 0) {
        toast.warn('Пользователь не найден');

        return;
      }
      if (hasOneIdentity(listIdentity)) {
        const firstFoundIdentity = getFirstFoundIdentity(listIdentity);

        const payloadIdentity: IIdentityState = toClientIdentityState({
          identity: firstFoundIdentity,
          searchIdentityBy: identity?.searchIdentityBy,
          typeInstallations,
        });

        dispatch(setCurrentIdentityInControlPanel(payloadIdentity));
        return;
      } else if (thereIsMoreThanOne(listIdentity)) {
        const firstFiveSlice = listIdentity.slice(
          0,
          IDENTITY_CONTROL_PANEL_MAX_LENGTH
        );

        const payloadIdentityList: IIdentityState = {
          hasIdentity: false,
          searchIdentityBy: identity.searchIdentityBy,
          listIdentity: firstFiveSlice,
        };
        dispatch(setCurrentIdentityInControlPanel(payloadIdentityList));
        return;
      }
    },
    [dispatch, identity.searchIdentityBy, typeInstallations]
  );

  const getIdentity = usePostV3({
    fetchApi: getIdentityApiV2,
    handlePayload,
    viewToast: false,
  });

  return useCallback(
    async (
      params: Pick<
        IGetIdentitiesFilters,
        'identity_nickname' | 'identity_phone_number'
      >
    ) => {
      const newParams = toFiltersIdentityInstallationsSetting(params);
      await getIdentity(newParams);
    },
    [getIdentity]
  );
};

export default useHandleClickButtonFindIdentity;
