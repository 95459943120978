import { usePostV3 } from 'hooks/usePostV3';
import { useCallback } from 'react';
import IBillingAccountClosedParams from 'services/api/organizationsV2/billing-account/IBillingAccountClosedParams';
import { doCloseBillingAccountApi } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import { t } from 'services/utils/translation';

const SUCCESS_MESSAGE_BILLING_ACCOUNT_OPENED = t(
  'Лицевой счет успешно отредактирован'
);

const useBillingAccountToClosed = () => {
  const doClose = usePostV3({
    fetchApi: doCloseBillingAccountApi,
    viewClientTextToast: true,
    successMessage: SUCCESS_MESSAGE_BILLING_ACCOUNT_OPENED,
  });

  return useCallback(
    async (params: IBillingAccountClosedParams) => {
      await doClose(params);
    },
    [doClose]
  );
};

export default useBillingAccountToClosed;
