import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { getBillingAccountTaskTableFiltersFromStore } from 'store/billingAccount/selectors';
import { getCounterpartyTaskTableFiltersFromStore } from 'store/counterparty/selectors';
import { initialTasksFilters } from 'store/task/initialTasksFilters';
 import { getTaskFilters } from 'store/task/selectors';

type PropsTypeUseGetInitialExternalRequestFilters = {
  counterpartyPk?: number;
  billingAccountPk?: number;
};

const useGetInitialTaskFilters = ({
  counterpartyPk,
  billingAccountPk,
  
}: PropsTypeUseGetInitialExternalRequestFilters) => {
  const defaultTableFilters = useSelector(getTaskFilters, isEqual);

  const defaultBillingAccountFilters = useSelector(
    getBillingAccountTaskTableFiltersFromStore,
    isEqual
  );

  const defaultCounterpartyFilters = useSelector(
    getCounterpartyTaskTableFiltersFromStore,
    isEqual
  );

  const initialParams: ITaskFilters = useMemo(() => {
    if (billingAccountPk) {
      return {
        ...initialTasksFilters,
        ...defaultBillingAccountFilters?.[billingAccountPk],
        internal_task_billing_account_fk: billingAccountPk,
      };
    }

    if (counterpartyPk) {
      return {
        ...initialTasksFilters,
        ...defaultCounterpartyFilters?.[counterpartyPk],
        internal_task_counterparty_fk: counterpartyPk,
      };
    } else {
      return defaultTableFilters;
    }
  }, [
    billingAccountPk,
    counterpartyPk,
    defaultBillingAccountFilters,
    defaultCounterpartyFilters,
    defaultTableFilters,
  ]);

  return initialParams;
};

export default useGetInitialTaskFilters;
