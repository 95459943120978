import { useLocation, useParams } from 'react-router-dom';

const useGetRouteTemplate = () => {
  const { pathname } = useLocation();
  const params = useParams();
  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};
export default useGetRouteTemplate;
