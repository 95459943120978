import { combineReducers } from 'redux';

import billingAccountReducer from 'store/billingAccount/reducer';
import exportReducer from 'store/export/reducer';
import externalRequestReducer from 'store/externalRequest/reducer';
import formReducer from '../form/reducer';
import employeeReducer from 'store/employee/reducer';
import generalReducer from '../general/reducer';
import tasksReducer from '../task/reducer';
import authReducer from '../auth/reducer';
import vehicleReducer from 'store/vehicle/reducer';
import bookingReducer from 'store/booking/reducer';
import notificationReducer from 'store/notification/reducer';
import eventReducer from 'store/controller/reducer';
import identityInControlPanelReducer from 'store/identity/reducer';
import counterpartyReducer from 'store/counterparty/reducer';
import controllerReducer from 'store/controller/reducer';

export const appReducer = combineReducers({
  auth: authReducer,
  notificationsCount: notificationReducer,
  tasks: tasksReducer,
  billingAccounts: billingAccountReducer,
  export: exportReducer,
  externalRequest: externalRequestReducer,
  form: formReducer,
  employee: employeeReducer,
  general: generalReducer,
  vehicle: vehicleReducer,
  booking: bookingReducer,
  event: eventReducer,
  identityInControlPanel: identityInControlPanelReducer,
  counterparty: counterpartyReducer,
  controller: controllerReducer,
});
