import classNames from 'clsx';
import { FC, memo } from 'react';

import styles from './index.module.scss';

export enum ColorCounterBadge {
  Red = 'red',
  Grey = 'grey',
}

type PropsType = {
  count: number | null;
  className?: string;
  color?: ColorCounterBadge;
};
const CounterUI: FC<PropsType> = (props) => {
  const { count, className, color = ColorCounterBadge.Red } = props;

  const notificationCircleStyle = classNames({
    [styles['circle']]: count !== null,
    [styles['counter-without-count']]: count === null,
  });
  return (
    <span
      className={classNames(className, styles[color], notificationCircleStyle)}
    >
      {count && count}
    </span>
  );
};

export default memo(CounterUI);
