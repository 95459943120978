import * as Sentry from '@sentry/react';
import { Event } from '@sentry/react';

import { Integrations } from '@sentry/tracing';
import { isDevEnv } from 'config';
import { useCallback } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { t } from 'services/utils/translation';
import { useEffect } from 'react';
import config from 'config.json';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

const useInitSentry = () => {
  const userName: string | undefined = useSelector(
    (state: RootStateOrAny) => state?.auth?.identity?.identity_first_name
  );

  const setValueInSentry = useCallback(() => {
    const inputEmail: HTMLElement | null = document.getElementById('id_email');
    const inputName: HTMLElement | null = document.getElementById('id_name');
    inputEmail && inputEmail.setAttribute('value', 'potok@mail.com');
    inputName && userName && inputName.setAttribute('value', userName);
  }, [userName]);
  Sentry.init({
    dsn: process.env.REACT_APP_API_PATCH_SENTRY,
    autoSessionTracking: !isDevEnv(),
    integrations: [
      new Integrations.BrowserTracing(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    release: 'potok@' + process.env.NODE_ENV,
    enabled: config.enabledSentryInDev || !isDevEnv(),
    environment: process.env.NODE_ENV,
    beforeSend(event: Event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        setValueInSentry.bind(document);

        Sentry.showReportDialog({
          eventId: event.event_id,
          dsn: process.env.REACT_APP_API_PATCH_SENTRY,
          title: t('Похоже у нас возникли проблемы'),
          subtitle: t('Наша команда получила уведомление.'),
          subtitle2: t(
            'Eсли вы хотите нам помочь, расскажите, что произошло ниже'
          ),
          labelName: t('Имя'),
          labelEmail: t('Почта'),
          labelComments: t('Что произошло'),
          labelClose: t('закрыть'),
          labelSubmit: t('Отправить'),
          errorFormEntry: t(
            'Некоторые поля были введены неверно. Исправьте ошибки и попробуйте еще раз.'
          ),
          successMessage: t('Ваше сообщение было отправлено. Спасибо!'),
          onLoad: () => setValueInSentry(),
        });
      }
      return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.5,
    debug: false,
  });
};

export default useInitSentry;
