import { useMemo } from 'react';
import { IExternalRequestEntityPk } from '../../pages/external-request/external-requests/ExternalRequestPage/ExternalRequestPage';
import { ITaskEntityPk } from 'pages/internal-task/task/TaskPage/TaskPage';
import { FilterType } from 'services/utils/getClearTaskAndExternalRequestFilters/getClearTaskAndExternalRequestFilters';

interface PropsType extends ITaskEntityPk, IExternalRequestEntityPk {
  filterType: FilterType | 'billingAccount';
}

const useGetKeyForMyFilter = ({
  billingAccountPk,
  eventPk,
  counterpartyPk,
  filterType,
}: PropsType) => {
  return useMemo(() => {
    if (!billingAccountPk && !eventPk && !counterpartyPk) {
      return filterType;
    }
    if (billingAccountPk) {
      return `${filterType}-billingAccountPk:${billingAccountPk}`;
    }
    if (eventPk) {
      return `${filterType}-eventPk:${eventPk}`;
    }
    if (counterpartyPk) {
      return `${filterType}-counterpartyPk:${counterpartyPk}`;
    } else {
      return filterType;
    }
  }, [billingAccountPk, counterpartyPk, eventPk, filterType]);
};

export default useGetKeyForMyFilter;
