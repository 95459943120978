import { IBookingFilters } from 'services/api/vehicle/booking/IGetPaginatedParamsBooking';
import config from 'config.json';
import { BookingSortCriteria } from '../../services/api/vehicle/booking/IGetPaginatedParamsBooking';

export const initialBookingFilters: IBookingFilters = {
  booking_pk: undefined,
  booking_vehicle_fk_array: [],
  // booking_period_start_lower_bound?: string;
  // booking_period_start_upper_bound?: string;
  // booking_period_end_lower_bound?: string;
  // booking_period_end_upper_bound?: string;
  booking_external_request_fk: undefined,
  skip: 0,
  length: config.pageLength,
  sort_by: BookingSortCriteria.Default,
};
