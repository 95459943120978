import React, { FC, useState } from 'react';
import classNames from 'clsx';
import { Formik } from 'formik';
import { Button, Form } from 'reactstrap';

import { t } from 'services/utils/translation';

import styles from './index.module.scss';
import getFormDataStepper from './services/getFormData';
import Otp from '../../../models/Otp';
import { usePostStepper } from '../../../hooks/usePostStepper';
import { obtainOtp } from 'services/api/auth/authApiV2';
import PhoneField from 'components/controls/PhoneField/PhoneField';
import { isDevEnv } from '../../../config';

const NEXT_STEP = 1;

const RegistrationFirstStep: FC = () => {
  const [initialValues] = useState<Otp>({
    ...new Otp(),
    phone_number: getFormDataStepper().phone_number,
  });
  const handlePayload = (payload: string) => {
    if (isDevEnv() || window.location.host.indexOf('dev') !== -1) {
      console.log(`'OTP': ${payload}`);
    }
  };

  // TODO: After the obtainOtp endpoint is fixed,
  //try removing the usePostStepper hook and replacing
  // it with a hook usePostV3 with the useStep hook
  const onSubmit = usePostStepper({
    fetchApi: obtainOtp,
    activeStep: NEXT_STEP,
    handlePayload,
    successMessage: t('Код отправлен в смс'),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Otp.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        await onSubmit(values, setErrors);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form
          onSubmit={handleSubmit}
          noValidate={true}
          className={classNames(styles['registration-form'])}
        >
          <h1>{t('Регистрация')}</h1>
          <PhoneField
            id="phone_number"
            label={t('Телефон')}
            hasError={
              errors.phone_number != null && touched.phone_number != null
            }
            onBlur={handleBlur}
            onChange={handleChange}
            required={true}
            defaultValue={values.phone_number}
            value={values.phone_number}
          />

          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            className="btn btn-lg btn-primary btn-block"
          >
            {t('Получить код')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationFirstStep;
