import useInitializationInInstallation from 'hooks/useInitializationInInstallation';
import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';
import getRouter from './getRouter';

const AppRouter: FC = () => {
  const [loading] = useInitializationInInstallation();
  const router = getRouter(loading);

  if (import.meta.webpackHot) {
    import.meta.webpackHot.dispose(() => router.dispose());
  }

  return <RouterProvider router={router} />;
};

export default AppRouter;
