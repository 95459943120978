import IClientSelectOptionV2 from '../../api/interfacesApi/IClientSelectOptionV2';
import { GroupTypeBase } from 'react-select';
import { createClientSelectOptionV2 } from '../selects/selects';
import { ITaskType } from 'services/api/tasks/task-typeV2/IGetTaskTypesPayload';
import { IVehicleType } from 'services/api/vehicle/vehicle-type/IVehicleType';

export interface ISubGroupSelectOption {
  childPk: number;
  childLabel: string;
}
export interface IGroupSelectOption {
  parentPk: number;
  parentLabel: string;
  listChild: ISubGroupSelectOption[];
}

export type TTypesDefaultKeys<Data> = {
  pkKey: keyof Data;
  labelKey: keyof Data;
  listChildKey: keyof Data;
  isActiveKey: keyof Data;
};

interface IKeyNameDefault {
  rendition: string;
  list_of_child_types: string;
  isActive: string;
  pk?: string;
}
const defaultKeys = {
  rendition: 'rendition',
  list_of_child_types: 'list_of_child_types',
  isActive: 'is_active',
  pk: 'pk',
};

export enum GROUP_SELECT_KEY {
  TASK_TYPE = 'internal_task_type',
  VEHICLE_TYPE = 'vehicle_type',
}

export const createKeysForCreateGroupOptions = (
  entityName: GROUP_SELECT_KEY,
  {
    rendition,
    list_of_child_types,
    isActive,
    pk = 'pk',
  }: IKeyNameDefault = defaultKeys
) => {
  return {
    pkKey: `${entityName}_${pk}`,
    labelKey: `${entityName}_${rendition}`,
    listChildKey: `${entityName}_${list_of_child_types}`,
    isActiveKey: `${entityName}_${isActive}`,
  } as const;
};

const createGroupSelect = (
  option: IClientSelectOptionV2<number> | IClientSelectOptionV2[]
) =>
  Array.isArray(option)
    ? { label: option[0].label + ':', options: option }
    : {
        label: option.label + ':',
        options: [option],
      };

const convertToDefaultOptions = <Data, Data2 extends []>(
  { pkKey, labelKey, listChildKey, isActiveKey }: TTypesDefaultKeys<Data>,
  data: Data
) => {
  const children = data[listChildKey] as unknown as Data2;
  return {
    parentPk: data[pkKey],
    parentLabel: data[labelKey],
    listChild: children.reduce(
      (acc: ISubGroupSelectOption[], childOption: Data) => {
        if (childOption[isActiveKey]) {
          acc.push({
            childPk: childOption[pkKey] as unknown as number,
            childLabel: childOption[labelKey] as unknown as string,
          });
        }
        return acc;
      },
      []
    ),
  };
};

export const extractCreateConverterGroupOptions =
  <Data extends Record<string, any>>({
    pkKey,
    labelKey,
    listChildKey,
    isActiveKey,
  }: TTypesDefaultKeys<Data>) =>
  (options: Data[]): IGroupSelectOption[] =>
    options.reduce((acc: IGroupSelectOption[], data: Data) => {
      if (isActiveKey) {
        const option = convertToDefaultOptions(
          {
            pkKey,
            labelKey,
            listChildKey,
            isActiveKey,
          },
          data
        );
        acc.push(option);
      }
      return acc;
    }, []);

//TODO не вызывать эти функции в глобальной среде - замкнуть их  внутри extractConvertingGroupSelect
const converterTaskTypeOptions = extractCreateConverterGroupOptions(
  createKeysForCreateGroupOptions(GROUP_SELECT_KEY.TASK_TYPE)
);

const converterVehicleTypeOptions = extractCreateConverterGroupOptions(
  createKeysForCreateGroupOptions(GROUP_SELECT_KEY.VEHICLE_TYPE)
);

export const extractConvertingGroupSelect2 =
  <Data extends ITaskType | IVehicleType>(
    converter: <Data extends ITaskType | IVehicleType>(
      options: Data[]
    ) => IGroupSelectOption[]
  ) =>
  (payload: Data[]): GroupTypeBase<IClientSelectOptionV2>[] => {
    const convertingSelectOptions = converter(payload);
    return convertingSelectOptions
      .map((entity: IGroupSelectOption) => {
        const { parentPk, parentLabel, listChild } = entity;

        const parentClientSelectOption = createClientSelectOptionV2(
          parentPk,
          parentLabel
        ) as IClientSelectOptionV2;
        if (listChild.length > 0) {
          const childrenOptions: IClientSelectOptionV2[] = listChild.map(
            (child: ISubGroupSelectOption) => {
              const { childPk, childLabel } = child;
              return createClientSelectOptionV2(childPk, childLabel);
            },
            []
          );
          return [parentClientSelectOption, ...childrenOptions];
        } else {
          return parentClientSelectOption;
        }
      })
      .map(createGroupSelect);
  };

export const taskTypesToGroupOptions = extractConvertingGroupSelect2(
  converterTaskTypeOptions
);
export const vehicleTypesToGroupOptions = extractConvertingGroupSelect2(
  converterVehicleTypeOptions
);
