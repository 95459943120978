import React, { FC, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import { t } from 'services/utils/translation';
import styles from './index.module.scss';
import { ReactComponent as CameraIcon } from '../../../img/icons/icon-camera.svg';
import saveScreenshot from './utils/saveScreenshot';
import copyToClipboardImage from './utils/copyToClipboardImage';
import { catchErrorFromFetch } from 'services/utils/catchAndRegError/catchAndRegError';
import classNames from 'clsx';
import { useSelector } from 'react-redux';
import { getActiveInstallationName } from 'store/general/selectors';

type ScreenshotButtonPropsType = {
  className?: string;
};

const ScreenshotButton: FC<ScreenshotButtonPropsType> = ({ className }) => {
  const installationClientName = useSelector(getActiveInstallationName);

  const takeScreenshot = useCallback(async () => {
    try {
      const canvas = await html2canvas(document.body);

      canvas.toBlob(async (blob) => {
        if (blob) {
          await copyToClipboardImage(blob);
          saveScreenshot(blob, installationClientName);
        } else {
          toast.error(t('Ошибка при копировании скриншота в буфер обмена'));
        }
      }, 'image/png');
    } catch (error) {
      catchErrorFromFetch(t('Ошибка при создании скриншота:'), error);
    }
  }, [installationClientName]);

  const id = 'screenshotButton';

  return (
    <CameraIcon
      onClick={takeScreenshot}
      className={classNames(styles.screenButton, className)}
      id={id}
    />
  );
};

export default ScreenshotButton;
