import React, { FC } from 'react';

import Tab from './Tab';
import { IMenuItemPagerV2 } from '../../../../services/interfaces/IMenuItemPagerV2';

type PropsTypeListTab = {
  tabs: IMenuItemPagerV2[];
};

const ListTab: FC<PropsTypeListTab> = ({ tabs }) => {
  return (
    <>
      {tabs.map(({ name, recordsTotal, patch }: IMenuItemPagerV2) => (
        <Tab name={name} patch={patch} recordsTotal={recordsTotal} key={name} />
      ))}
    </>
  );
};

export default ListTab;
