import { composeClientSelectOptionsInNumbersArray } from 'services/utils/selects/composeIClientSelectOptionsInNumbers';
import trimStringInObject from 'services/utils/trimStringInObject/trimStringInObject';
import { IKeyValue } from 'services/interfaces/IKeyValue';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { extractValueFromClientSelectOptionV2 } from 'services/utils/selects/selects';
import {
  IControllerFilters,
  IControllerPaginatedParams,
} from 'services/api/controller/IControllerFilters';

export const composeParamsGetController = (
  params: IControllerFilters
): IControllerPaginatedParams => {
  const { controller_billing_account_fk_array }: IKeyValue<number[]> =
    composeClientSelectOptionsInNumbersArray(params, [
      'controller_billing_account_fk_array',
    ]);

  const newParams = trimStringInObject({
    ...params,
    controller_billing_account_fk_array,
    controller_is_closed: extractValueFromClientSelectOptionV2(
      params?.controller_is_closed
    ),
  });

  return deleteUnnecessaryParameters(newParams, undefined, [
    'controller_is_closed',
  ]);
};
  