import IBaseGetListParams from 'services/api/interfacesApi/IBaseGetListParams';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

export enum BookingSortCriteria {
  Default = '',
  PkAsc = 'booking_pk',

  PkDesc = '-booking_pk',
  VehicleAsk = 'booking_vehicle_fk',
  VehicleDesc = '-booking_vehicle_fk',
  ExternalRequestFkAsc = 'booking_external_request_fk',
  ExternalRequestFkDesc = '-booking_external_request_fk',
  PeriodStartLowerBoundAsc = 'booking_period_start_lower_bound',
  PeriodStartLowerBoundDesc = '-booking_period_start_lower_bound',
  PeriodEndLowerBoundAsc = 'booking_period_end_lower_bound',
  PeriodEndLowerBoundDesc = '-booking_period_end_lower_bound',
}

export interface IGetPaginatedParamsBooking
  extends IBaseGetListParams<BookingSortCriteria> {
  booking_pk?: number;
  // booking_pk_array?: number[];
  // booking_fulltext_search?: string;
  // booking_vehicle_fk?: number;
  booking_vehicle_fk_array?: number[] | null;
  // booking_internal_task_fk?: number;
  // booking_internal_task_fk_array?: number[] | null;
  booking_external_request_fk?: number;
  // booking_external_request_fk_array?: number[] | null;
  // booking_period_start_lower_bound?: string;
  // booking_period_start_upper_bound?: string;
  // booking_period_end_lower_bound?: string;
  // booking_period_end_upper_bound?: string;
}

export interface IBookingFilters
  extends IBaseGetListParams<BookingSortCriteria> {
  booking_pk?: number;
  booking_vehicle_fk_array?: IClientSelectOptionV2[];
  // booking_period_start_lower_bound?: string;
  // booking_period_start_upper_bound?: string;
  // booking_period_end_lower_bound?: string;
  // booking_period_end_upper_bound?: string;
  booking_external_request_fk?: number;
}
