import { useMemo } from 'react';

export enum HEADER_SELECT_COLOR {
  GREY = '#D6DCE2',
  DEFAULT = '',
}

interface ISelectStyles {
  headerColor?: HEADER_SELECT_COLOR;
  disabled?: boolean;
}

const defaultSelectStyles = { headerColor: HEADER_SELECT_COLOR.DEFAULT };

const useGetSelectStyle = (props?: ISelectStyles) => {
  const styles = props || defaultSelectStyles;
  return useMemo(() => {
    const { headerColor } = styles;
    return {
      menu: (styles: any) => ({
        ...styles,
        zIndex: 999,
        cursor: 'pointer',
      }),
      multiValueLabel: (styles: any) => ({
        ...styles,
        background: '#D6F1F7',
        cursor: 'pointer',
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        background: '#D6F1F7',
      }),
      control: (styles: any) => ({
        ...styles,
        '&:hover': { borderColor: '#ACB5C4' },
        border: 'none',
        height: '100%',
        backgroundColor: headerColor,
        cursor: 'pointer',
      }),
      dropdownIndicator: (styles: any) => ({ ...styles, display: 'none' }),
      IndicatorsContainer: (styles: any) => ({ ...styles, display: 'none' }),
      indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
      container: (styles: any) => ({
        ...styles,
        height: '100%',
        cursor: 'pointer',
      }),
      option: (styles: any) => ({ ...styles, cursor: 'pointer' }),
    };
  }, [styles]);
};

export default useGetSelectStyle;
