import classNames from 'clsx';
import React, { ChangeEventHandler, FC, FocusEventHandler, memo } from 'react';
import {
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import MaskedPhoneInput from '../MaskedPhoneInput';
import TipButton from '../TipButton';
import { IButtonAddon } from '../../../services/interfaces/IButtonAddon';
import { cleanUpPhone } from '../../../services/utils/stringHelper/stringHelper';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';

type TypeProps = {
  id: string;
  label: string;
  placeholder?: string;
  onBlur: FocusEventHandler;
  onChange: ChangeEventHandler;
  hasError: boolean;
  required?: boolean;
  value?: string;
  defaultValue?: string;
  autoComplete?: 'on' | 'off' | 'new-password';
  helpText?: string;
  disabled?: boolean;
  highlightEditing?: boolean;
  buttonAddon?: IButtonAddon;
};

const PhoneField: FC<TypeProps> = (props) => {
  const {
    id,
    label,
    placeholder,
    onBlur,
    onChange,
    hasError,
    required = false,
    autoComplete,
    value,
    helpText,
    disabled = false,
    highlightEditing = false,
    buttonAddon,
  } = props;

  const autoCompleteProps = { autoComplete };

  return (
    <FormGroup className={required ? 'required' : ''}>
      <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
        <Label className="control-label" for={id}>
          {label}
        </Label>
        {helpText && <TipButton id={id} helpText={helpText} />}
      </div>
      <ErrorWrapperField id={id}>
        <InputGroup
          className={classNames('flex-nowrap', { 'is-invalid': hasError })}
        >
          <MaskedPhoneInput
            placeholder={placeholder ? placeholder : ''}
            name={id}
            id={id}
            onBlur={onBlur}
            onChange={onChange}
            className={classNames({
              'is-invalid': hasError,
              borderBackLight: highlightEditing && !hasError,
            })}
            required={required}
            {...autoCompleteProps}
            value={cleanUpPhone(value)}
            disabled={disabled}
          />
          {buttonAddon && (
            <InputGroupAddon addonType="prepend">
              <Button
                color="primary"
                onClick={() => buttonAddon.handleClickAddon()}
                className={classNames(buttonAddon.classNameButton)}
                disabled={buttonAddon.disabled}
              >
                {buttonAddon.textButton}
              </Button>
            </InputGroupAddon>
          )}
        </InputGroup>
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(PhoneField);
