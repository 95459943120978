import { usePostV3 } from '../usePostV3';
import { useCallback } from 'react';
import { t } from 'services/utils/translation';
import { CONTROLLER_TEXT } from 'services/localLocalization/servicesAndModels';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';
import { doCloseControllerApi } from 'services/api/controller/controllerApi';
import { IControllerClosedParams } from 'services/api/controller/IControllerClosedParams';

const SUCCESS_MESSAGE_CONTROLLER_OPENED =
  makeFirstLetterUppercase(CONTROLLER_TEXT) + t(' успешно отредактировано');

const useControllerToClosed = () => {
  const doCloseController = usePostV3({
    fetchApi: doCloseControllerApi,
    viewClientTextToast: true,
    successMessage: SUCCESS_MESSAGE_CONTROLLER_OPENED,
  });

  return useCallback(
    async (params: IControllerClosedParams) => {
      await doCloseController(params);
    },
    [doCloseController]
  );
};

export default useControllerToClosed;
