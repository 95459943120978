import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { extractValueFromClientSelectOptionV2 } from 'services/utils/selects/selects';
import { IFormValuesController } from '../ControllerForm';
import { ICreateController } from 'services/api/controller/ICreateController';
import { IUpdateController } from 'services/api/controller/IUpdateController';
import trimStringInObject from 'services/utils/trimStringInObject/trimStringInObject';

const EXTRA_CONTROLLER_PARAMETERS = [
  'controllerBillingAccount',
  'controllerIsClosed',
];

export const preprocessValuesController = (
  controllerValues: IFormValuesController
): ICreateController | IUpdateController => {
  const updatedValues = {
    pk: controllerValues.controller_pk,
    ...controllerValues,
    controller_billing_account_fk: extractValueFromClientSelectOptionV2(
      controllerValues?.controllerBillingAccount
    ),
  };

  const finalValues = trimStringInObject(
    deleteUnnecessaryParameters(updatedValues, EXTRA_CONTROLLER_PARAMETERS)
  );

  return finalValues as ICreateController | IUpdateController;
};
