import CentralRegistryApiClient from '../CentralRegistryApiClient';
import IObtainOtp from './IObtainOpt';
import IBaseParams from '../interfacesApi/IBaseParams';
import IVerifyOtp from './IVerifyOtp';
import IRegisterFormData from './IRegisterFormData';
import { RegisterPayload, IIdentity } from './IRegisterIdentity';
import { IBaseResponse } from '../interfacesApi/IBaseResponse';
import { ILoginParams } from './ILoginParams';
import createTimestamp from 'services/utils/createTimestamp/createTimestamp';

export async function obtainOtp(
  bodyParams: IBaseParams<IObtainOtp>
): Promise<string> {
  const api = new CentralRegistryApiClient();
  return api.postForm('obtain-otp', bodyParams);
}

export async function verifyOtp(
  bodyParams: IBaseParams<IVerifyOtp>
): Promise<string> {
  const api = new CentralRegistryApiClient();
  return api.postForm('verify-otp', bodyParams);
}

export async function registerIdentity(
  bodyParams: IBaseParams<IRegisterFormData>
): Promise<IBaseResponse<RegisterPayload>> {
  const api = new CentralRegistryApiClient();
  return api.postForm('register', bodyParams);
}

export async function loginApiV2(
  bodyParams: ILoginParams
): Promise<IBaseResponse<IIdentity>> {
  const api = new CentralRegistryApiClient();
  return api.postForm(`login`, bodyParams);
}

export async function logoutApiV2(): Promise<IBaseResponse<undefined>> {
  const api = new CentralRegistryApiClient();
  return api.postJson('logout', { id: createTimestamp() });
}

export async function checkAuthApiV2(): Promise<IBaseResponse<IIdentity>> {
  const api = new CentralRegistryApiClient();
  return api.postJson('obtain-current-identity', { id: createTimestamp() });
}
