export const isImg = (fileName: string): boolean => {
  const imgExtensions = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.bmp',
    '.svg',
    '.webp',
    'tiff',
    'tif',
  ];

  const lastDot = fileName.lastIndexOf('.');
  const fileExtension = fileName.slice(lastDot).toLowerCase();
  return imgExtensions.includes(fileExtension);
};
