import React, { FC, PropsWithChildren } from 'react';
import styles from './index.module.scss';
export interface PropsType {
  background?: string;
}

const FlowChartWrapper: FC<PropsWithChildren<PropsType>> = ({ children }) => {
  return (
    <>
      <div className={styles.container}>{children}</div>
    </>
  );
};

export default FlowChartWrapper;
