import { ValueType } from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

const useTaskHandlersSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
  const handlePkTaskSearch = (searchString: string) => {
    onSearchRequest(searchString, 'internal_task_pk');
  };
  const handleAuthorTaskMultiSelect = (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => onSearchRequest(option, 'internal_task_author_dk_array');

  const handleSupervisorTaskMultiSelect = (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => onSearchRequest(option, 'internal_task_supervisor_dk_array');

  const handleCounterpartyTaskMultiSelect = (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => {
    onSearchRequest([], 'internal_task_billing_account_fk_array');
    onSearchRequest(option, 'internal_task_counterparty_fk_array');
  };
  const handleBillingAccountTaskMultiSelect = (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => {
    onSearchRequest([], 'internal_task_counterparty_fk_array');
    onSearchRequest(option, 'internal_task_billing_account_fk_array');
  };

  const handleTaskTypeGroupSelect = (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => {
    onSearchRequest(option, 'internal_task_type_fk_array');
  };

  const handleStatusTaskMultiSelect = (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => onSearchRequest(option, 'internal_task_status_fk_array');

  const handleDescriptionTaskSearch = (searchString: string) => {
    onSearchRequest(searchString, 'internal_task_description');
  };

  return {
    useTaskHandlersSearch,
    handlePkTaskSearch,
    handleAuthorTaskMultiSelect,
    handleSupervisorTaskMultiSelect,
    handleCounterpartyTaskMultiSelect,
    handleBillingAccountTaskMultiSelect,
    handleTaskTypeGroupSelect,
    handleStatusTaskMultiSelect,
    handleDescriptionTaskSearch,
  };
};

export default useTaskHandlersSearch;
