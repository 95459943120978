import { useEffect } from 'react';
import config from '../config.json';
import useGetPrevValue from './useGetPrevValue';

const useSetDocumentTitle = (newDocumentTitle: string) => {
  const prevValue = useGetPrevValue(newDocumentTitle);
  useEffect(() => {
    if (prevValue !== newDocumentTitle || prevValue !== undefined) {
      document.title = `${config.siteName}: ${newDocumentTitle}`;
    }
    return () => {
      document.title = `${config.siteName}`;
    };
  }, [newDocumentTitle, prevValue]);
};

export default useSetDocumentTitle;
