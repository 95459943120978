export enum SortCriteriaEvent {
  Default = '',
  PkAsk = 'event_pk',
  PkDesk = '-event_pk',
  DateAddedAsc = 'event_datetime_added',
  DateAddedDesc = '-event_datetime_added',
  NameAsc = 'event_name',
  NameDesc = '-event_name',
  DateEditedAsc = 'event_datetime_edited',
  DateEditedDesc = '-event_datetime_edited',
}
