import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { usePostV3 } from './usePostV3';
import useOnStep from './useOnStep/useOnStep';

interface IProps<Params, Payload> {
  fetchApi: (params: IBaseParams<Params>) => Promise<any>;
  activeStep: number;
  errorMessage?: string;
  successMessage?: string;
  successHandler?: () => void;
  errorHandler?: Function;
  handlePayload?: (payload: Payload) => void;
}

export function usePostStepper<Params, Payload>(
  props: IProps<Params, Payload>
) {
  const {
    fetchApi,
    errorMessage = 'Не удалось загрузить данные',
    successMessage = 'Данные отправлены успешно',
    successHandler,
    errorHandler,
    activeStep,
    handlePayload,
  } = props;

  const onStep = useOnStep<Params>(activeStep);

  return usePostV3({
    fetchApi,
    errorMessage,
    successMessage,
    successHandler,
    errorHandler,
    onStep,
    handlePayload,
  });
}
