import classNames from 'clsx';
import styles from './index.module.scss';
import { FC, memo } from 'react';
import { TMods } from '../../../services/types/TMods';

export enum CIRCLE_COLOR {
  BLACK = 'black',
  CITY_BLUE = 'cityBlue',
}

export enum CIRCLE_SIZE {
  XS = 'xs',
  M = 'm',
  S = 's',
}

type CirclePropsType = {
  id?: string;
  size?: CIRCLE_SIZE;
  color?: CIRCLE_COLOR;
  className?: string;
  isInvisible?: boolean;
};

export const Circle: FC<CirclePropsType> = (props) => {
  const {
    className,
    size = CIRCLE_SIZE.XS,
    color = CIRCLE_COLOR.BLACK,
    isInvisible = false,
    id,
  } = props;

  const mods = [styles[size], styles[color]];
  const specialMods: TMods = {
    invisible: isInvisible,
  };

  return (
    <div
      id={id}
      className={classNames(styles.circle, ...mods, className, specialMods)}
    />
  );
};
export default memo(Circle);
