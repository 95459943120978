import { t } from '../translation';

const getSuccessMessage = (model: string, ending = '') => {
  return {
    successMessageCreate: `${model} ${t('успешно создан') + ending}`,
    successMessageUpdate: `${model} ${t(' успешно отредактирован') + ending}`,
  };
};

export default getSuccessMessage;
