import * as Yup from 'yup';
import { t } from '../utils/translation';
import { getFileSize } from '../utils/getFileSize/getFileSize';
import config from 'config.json';

function fileSizeValidator(
  values: any[] | undefined,
  maxLimit: number = config.maxDataSizeKb
): boolean {
  return values ? getFileSize(values) < maxLimit : true;
}

export function dataSizeIsValid(
  this: Yup.ArraySchema<any>,
  maxLimit?: number,
  msg?: string
) {
  return this.test({
    name: 'data-size-is-valid',
    exclusive: false,
    message: msg || t('Достигнут лимит размера.'),
    test: function (value?: any[]) {
      return fileSizeValidator(value, maxLimit);
    },
  });
}
