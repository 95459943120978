import { FC, useEffect, useState } from 'react';
import RegistrationFirstStep from './RegistrationFirstStep';
import RegistrationSecondStep from './RegistrationSecondStep';
import { useLocation } from 'react-router';
import RegistrationSecondFinal from './RegistationFinal';

const TABS = [
  { component: RegistrationFirstStep },
  { component: RegistrationSecondStep },
  { component: RegistrationSecondFinal },
];
const Registration: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const location = useLocation();

  const tab = TABS[activeStep];

  useEffect(() => {
    const actualStep = location.state?.activeStep
      ? location.state?.activeStep
      : 0;
    setActiveStep(actualStep);
  }, [location.state?.activeStep]);

  return <>{!!tab && <tab.component />}</>;
};

export default Registration;
