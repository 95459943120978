import classNames from 'clsx';
import { ReactComponent as MoreIcon } from 'img/icons/icon-more.svg';

import React, {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  memo,
} from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import styles from './index.module.scss';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { Direction } from 'reactstrap/es/Dropdown';

type TypeActionsDropdownItem = {
  onClick: MouseEventHandler<any>;
  label: string;
  icon: ReactNode;
  disabled?: boolean;
  hint?: string;
};

const ActionsDropdownItem: FC<TypeActionsDropdownItem> = (props) => {
  const { onClick, label, icon, disabled = false, hint } = props;

  return (
    <div id={'Tooltip-' + label}>
      <DropdownItem
        className={`${styles.item} py-2 d-flex flex-row`}
        onClick={onClick}
        disabled={disabled}
      >
        {icon} <span className="ml-2">{label}</span>
      </DropdownItem>
      {hint && (
        <ToolTipWrapper placement="left" target={'Tooltip-' + label}>
          {hint}
        </ToolTipWrapper>
      )}
    </div>
  );
};
memo(ActionsDropdownItem);

type ActionsDropdownProps = {
  classNameBtn?: string;
  direction?: Direction;
  className?: string;
};
type PropsType = PropsWithChildren<ActionsDropdownProps>;

const ActionsDropdown: FC<PropsType> = (props) => {
  const { children, classNameBtn, direction = 'left', className } = props;

  return (
    <UncontrolledButtonDropdown direction={direction} className={className}>
      <DropdownToggle className={classNames(classNameBtn, styles.toggle)}>
        <MoreIcon />
      </DropdownToggle>
      <DropdownMenu className={`${styles.menu}`} right>
        {children}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};
memo(ActionsDropdown);

type MassActionsDropdownProps = {
  toggleClassName: string;
  toggleLabel: string;
};

type MassPropsType = PropsWithChildren<MassActionsDropdownProps>;

const MassActionsDropdown: FC<MassPropsType> = (props) => {
  const { children, toggleClassName, toggleLabel } = props;

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle className={toggleClassName}>{toggleLabel}</DropdownToggle>
      <DropdownMenu>{children}</DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};
memo(MassActionsDropdown);

export { MassActionsDropdown, ActionsDropdown, ActionsDropdownItem };
