import React, { FC, useState, memo, useCallback } from 'react';
import { StylesConfig } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import formatOptionLabelHighLighter from '../utils/formatOptionLabelHighLighter';
import shouldLoadMore from '../utils/shouldLoadMore';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { IForSelectResponseV2 } from '../DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { CSSObject } from '@emotion/styled';
import PopoutSelectDropdown from './ui/PopoutSelectDropdown/PopoutSelectDropdown';
import classNames from 'clsx';
import { ReactComponent as DropdownIndicatorIcon } from '../../../img/icons/icon-loupe.svg';
import { components, IndicatorProps } from 'react-select';
import { PopoutSelectMenuPosition } from './ui/PopoutSelectMenu/PopoutSelectMenu';
import CustomOptionsForPopoutDynamicSelected from './ui/CustomOptionForPopoutDynamicSelected/CustomOptionForPopoutDynamicSelected';
import ButtonSubHeaderFormForPopout from '../ButtonSubHeaderFormForPopout/ButtonSubHeaderFormForPopout';
import useToggleWitchInput from 'hooks/useToggleWitchInput/useToggleWitchInput';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';
import useLoadOptionsInForSelect from 'hooks/useLoadOptionsInForSelect/useLoadOptionsInForSelect';

const DropdownIndicator: React.FC<IndicatorProps<any, any>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorIcon />
    </components.DropdownIndicator>
  );
};

const MAX_WIDTH = 'maxWidth';
const WHITE_SPACE = 'whiteSpace';
const TEXT_OVERFLOW = 'textOverflow';

const selectStyles: StylesConfig<any, false> = {
  control: (provided: CSSObject) => ({
    ...provided,
    minWidth: 270,
    '&:hover': { borderColor: '#ACB5C4' },
    // border: 'none',
    height: '100%',
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  container: () => ({
    height: '100%',
  }),
  option: (styles: any) => ({
    ...styles,
    [WHITE_SPACE]: 'nowrap',
    [MAX_WIDTH]: '270px',
    [TEXT_OVERFLOW]: 'ellipsis',
    overflow: 'hidden',
  }),

  dropdownIndicator: (styles: any) => ({ ...styles, display: 'none' }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
};

type TypeProps = {
  id: string;
  placeholder?: string;
  className?: string;
  selectHandler: (
    params: IBaseParams<IForSelectRequestV2>
  ) => Promise<IBaseResponse<IForSelectResponseV2>>;
  'aria-labelledby'?: string;
  onChange: (option: IClientSelectOptionV2) => Promise<void>;
  isClearable?: boolean;
  closeMenuOnSelect?: string;
  options?: IClientSelectOptionV2[];
  buttonDefaultText: string;
  position?: PopoutSelectMenuPosition;
  isViewIconBefore?: boolean;
  handlerForBeforeIcon?: () => void;
};

const PopoutDynamicSelectPaginated: FC<TypeProps> = (props) => {
  const {
    id,
    placeholder,
    selectHandler,
    className,
    'aria-labelledby': ariaLabelledBy,
    onChange,
    options,
    buttonDefaultText,
    position,
    isViewIconBefore,
    handlerForBeforeIcon,
  } = props;

  const [isOpen, setIsOpen] = useToggleWitchInput();
  let optionsArray: IClientSelectOptionV2[] = options ?? [];

  const [loadedOptions, setLoadedOptions] =
    useState<IClientSelectOptionV2[]>(optionsArray);

  const curentId = useGetIdFromPath();

  const loadOptions = useLoadOptionsInForSelect({
    selectHandler,
    setLoadedOptions,
    loadedOptions,
    curentId,
  });

  const onChangeHandler = useCallback(
    async (option: IClientSelectOptionV2) => {
      await onChange(option);
      setIsOpen(false);
    },
    [onChange, setIsOpen]
  );

  const getValue = () => {
    if (loadedOptions != null && optionsArray.length > 0) {
      let value = loadedOptions.find((option: IClientSelectOptionV2) =>
        optionsArray.find((option2) => option.value === option2.value)
      );
      if (value == null) {
        value = optionsArray[0];
      }

      return value;
    } else {
      return null;
    }
  };

  //TODO target should be an argument
  return (
    <div className="d-flex-column">
      <ButtonSubHeaderFormForPopout
        buttonDefaultText={buttonDefaultText}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isViewIconBefore={isViewIconBefore}
        handlerForBeforeIcon={handlerForBeforeIcon}
      />
      <PopoutSelectDropdown
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className={classNames(className)}
        position={position}
      >
        <AsyncPaginate
          cacheOptions
          loadOptions={loadOptions}
          id={id}
          name={id}
          aria-labelledby={ariaLabelledBy}
          placeholder={placeholder}
          className={className}
          onChange={onChangeHandler}
          value={getValue()}
          additional={{
            page: 1,
          }}
          styles={selectStyles}
          debounceTimeout={300}
          shouldLoadMore={shouldLoadMore}
          formatOptionLabel={formatOptionLabelHighLighter}
          backspaceRemovesValue={false}
          components={{
            DropdownIndicator: DropdownIndicator,
            IndicatorSeparator: null,
            Option: CustomOptionsForPopoutDynamicSelected,
          }}
          controlShouldRenderValue
          tabSelectsValue={false}
          menuIsOpen
          hideSelectedOptions={false}
        />
      </PopoutSelectDropdown>
    </div>
  );
};

export default memo(PopoutDynamicSelectPaginated);
