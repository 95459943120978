import { clearTasksFilters } from 'store/task/actions';
import { clearExternalRequestFilters } from 'store/externalRequest/actions';
import {
  extractClearBillingAccountExternalRequestFilters,
  extractClearBillingAccountTaskFilters,
} from 'store/billingAccount/actions';
import { extractClearCounterpartyTaskFilters } from 'store/counterparty/actions';
import { extractClearEventExternalRequestFilters } from 'store/event/actions';

export enum FilterType {
  task = 'task',
  externalRequest = 'externalRequest',
}

interface IPropsHandler {
  billingAccountPk?: number;
  counterpartyPk?: number;
  eventPk?: number;
}

interface FilterHandlers {
  [key: string]: (pk: IPropsHandler) => any;
}

const filterHandlers: FilterHandlers = {
  task: ({ billingAccountPk, counterpartyPk }) => {
    if (!billingAccountPk && !counterpartyPk) {
      return clearTasksFilters;
    }
    if (billingAccountPk) {
      return extractClearBillingAccountTaskFilters(billingAccountPk);
    }
    if (counterpartyPk) {
      return extractClearCounterpartyTaskFilters(counterpartyPk);
    }
    return clearTasksFilters;
  },
  externalRequest: ({ billingAccountPk, eventPk }) => {
    if (!billingAccountPk && !eventPk) {
      return clearExternalRequestFilters;
    }
    if (billingAccountPk) {
      return extractClearBillingAccountExternalRequestFilters(billingAccountPk);
    }
    if (eventPk) {
      return extractClearEventExternalRequestFilters(eventPk);
    }
    return clearExternalRequestFilters;
  },
};

export const getClearFilters = (
  type: FilterType,
  {
    billingAccountPk,
    counterpartyPk,
    eventPk,
  }: {
    billingAccountPk?: number;
    counterpartyPk?: number;
    eventPk?: number;
  }
) => {
  const handler = filterHandlers[type];
  if (!handler) {
    throw new Error('Invalid filter type');
  }
  return handler({ billingAccountPk, counterpartyPk, eventPk });
};
