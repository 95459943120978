import { IEvent } from 'services/api/organizationsV2/event/IEvent';
import { IFormValuesEvent } from '../../../../../../components/organizations/event/EventForm/EventForm';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';

export const toFormValuesEvent = (data: IEvent): IFormValuesEvent => {
  const {
    event_pk,
    event_name,
    event_billing_account,
    event_type,
    event_is_closed,
  } = data;
  return {
    pk: event_pk,
    event_name,
    eventBillingAccount: createClientSelectOptionV2<number>(
      event_billing_account.billing_account_pk,
      event_billing_account.billing_account_code_number
    ),
    eventType: createClientSelectOptionV2<number>(
      event_type.event_type_pk,
      event_type.event_type_rendition
    ),
    eventIsClosed: event_is_closed,
  };
};
