import { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'clsx';

export enum PopoutSelectMenuPosition {
  left = 'left',
  center = 'center',
}

type PopoutSelectMenuPropsType = {
  position?: PopoutSelectMenuPosition;
} & JSX.IntrinsicElements['div'];

const PopoutSelectMenu: FC<PopoutSelectMenuPropsType> = (props) => {
  return <div className={classNames(styles.menu)} {...props} />;
};

export default PopoutSelectMenu;
