import ControllerForm from 'components/controller/controller/ControllerForm/ControllerForm';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import useSuccessHandlerNavigate from 'hooks/useSuccessHandlerNavigate/useSuccessHandlerNavigate';
import { PATH_CONTROLLER_PAGE } from 'services/pathConstants';

const ControllerAddPage = () => {
  const successHandler = useSuccessHandlerNavigate(PATH_CONTROLLER_PAGE);
  return (
    <MainLayout>
      <Subheader />
      <ControllerForm successHandler={successHandler} />
    </MainLayout>
  );
};

export default ControllerAddPage;
