import React, { FC, memo } from 'react';

type PropsType = {
  url: string;
  alt: string;
  className: string;
};

const ImgPreview: FC<PropsType> = (props) => {
  const { url, alt, className } = props;

  const openImg = (url: string) => {
    window.open(url, '_blank');
  };
  
  return (
    <img
      src={url}
      alt={alt}
      loading="lazy"
      onClick={() => url && openImg(url)}
      className={className}
    />
  );
};

export default memo(ImgPreview);
