import usePrepareLink from 'hooks/usePrepareLinks';
import { TAB_QUERY_PARAMS } from 'services/pathConstants';
import { TAB_QUERY_KEY } from '../../services/pathConstants';
import { t } from 'services/utils/translation';
import { parentUIElement } from 'services/constants/parentUIElement';
import useGetTotalExternalRequest from 'hooks/useGetTotalExternalRequest/useGetTotalExternalRequest';

export type TParentUiForExternalRequestTab =
  | parentUIElement.account
  | parentUIElement.event;

interface IUseGetExternalRequestTab {
  pk: number;
  parentUI: TParentUiForExternalRequestTab;
}

export const useGetTabExternalRequest = ({
  pk,
  parentUI,
}: IUseGetExternalRequestTab) => {
  const { totalExternalRequest, isLoadingExternalRequest } =
    useGetTotalExternalRequest({
      pk,
      parentUI,
    });

  const tabKey = TAB_QUERY_KEY[parentUI];

  const externalRequestPatch = usePrepareLink({
    query: {
      [tabKey]: TAB_QUERY_PARAMS.externalRequest,
    },
  });

  const externalRequestTab = {
    name: t('Заявки'),
    recordsTotal: totalExternalRequest,
    patch: externalRequestPatch,
  };

  return { externalRequestTab, isLoadingExternalRequest };
};
