import classNames from 'clsx';
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { getLocalParentPath } from 'services/localPaths';
import { PATH_FRONTPAGE } from 'services/pathConstants';
import { findMenuItem, parentMenuItem, getPathTitle } from 'services/paths';
import { frontpageTitle } from 'services/utils/misc/misc';
import styles from './index.module.scss';
import { InstallationsDropDown } from '../InstallationsDropDown/InstallationsDropDown';
import { getMenuFromStore } from '../../../store/general/selectors';
import { IMenuItemV2 } from '../../../services/interfaces/IMenuItemV2';
import useGetRouteTemplate from 'hooks/useGetRouteTemplate/useGetRouteTemplate';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';

const Breadcrumbs: FC = () => {
  const menu: IMenuItemV2[] = useSelector(getMenuFromStore);

  const location = useLocation();
  const matchPath = useGetRouteTemplate();
  const currentPath = matchPath ? matchPath : location.pathname;
  const pk = useGetIdFromPath();
  const pathTitle = getPathTitle(menu, currentPath);
  const currentTitle = pk ? `${pathTitle}: №${pk}` : pathTitle;
  let parent = parentMenuItem(menu, currentPath);
  if (parent == null && currentPath === PATH_FRONTPAGE) {
    return (
      <div
        className={classNames(
          styles.breadcrumbs,
          ' d-md-flex flex-row justify-content-start'
        )}
      >
        <InstallationsDropDown />
      </div>
    );
  }

  if (parent == null && currentPath !== PATH_FRONTPAGE) {
    const parentPath = getLocalParentPath(currentPath);

    if (parentPath !== PATH_FRONTPAGE && parentPath != null) {
      parent = findMenuItem(menu, parentPath);
      if (parent == null) {
        return (
          <div
            className={classNames(
              styles.breadcrumbs,
              'd-none d-md-flex flex-row justify-content-start'
            )}
          >
            <span
              className={classNames(
                styles.current,
                'd-flex flex-column justify-content-center'
              )}
            >
              {currentTitle}
            </span>
          </div>
        );
      }
    }
  }

  const parentPath = parent ? parent.url : PATH_FRONTPAGE;
  const parentTitle = parent ? parent.title : frontpageTitle();

  return (
    <div
      className={classNames(
        styles.breadcrumbs,
        'd-none d-md-flex flex-row justify-content-start mr-2'
      )}
    >
      <NavLink
        className={classNames(
          styles.link,
          'd-flex flex-column justify-content-center'
        )}
        to={parentPath}
      >
        {parentTitle}
      </NavLink>
      <span
        className={classNames(
          styles.separator,
          'd-flex flex-column justify-content-center'
        )}
      >
        /
      </span>
      <span
        className={classNames(
          styles.current,
          'd-flex flex-column justify-content-center'
        )}
      >
        {currentTitle}
      </span>
    </div>
  );
};

export default memo(Breadcrumbs);
