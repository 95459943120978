import { useSelector } from 'react-redux';
import { getNumberOfNotificationsFromStore } from 'store/notification/selectors';

interface IClientNotificationNameCount {
  externalRequestNotificationCount?: number;
  taskNotificationCount?: number;
}

const extractGetCountNotificationItem =
  ({
    externalRequestNotificationCount,
    taskNotificationCount,
  }: IClientNotificationNameCount) =>
  (itemName: string) =>
    ({
      external_request: externalRequestNotificationCount,
      ExternalRequest: externalRequestNotificationCount,
      internal_task: taskNotificationCount,
      InternalTask: taskNotificationCount,
    }?.[itemName]);

const useExtractGetCountNotifications = (): ((
  itemName: string
) => number | undefined) => {
  const {
    external_request: externalRequestNotificationCount,
    internal_task: taskNotificationCount,
  } = useSelector(getNumberOfNotificationsFromStore);

  return extractGetCountNotificationItem({
    externalRequestNotificationCount,
    taskNotificationCount,
  });
};

export default useExtractGetCountNotifications;
