import config from 'config.json';
import getObjectWitchActualDateTime from './extractGetObjectWitchActualDateTime';

export enum ExternalRequestDatetimeOfActualKey {
  commencement = 'external_request_datetime_of_actual_commencement',
  completion = 'external_request_datetime_of_actual_completion',
}

const STATUS_MAP = {
  [ExternalRequestDatetimeOfActualKey.commencement]:
    config.externalRequestStatusForSetActualDatetimeCommencement,
  [ExternalRequestDatetimeOfActualKey.completion]:
    config.externalRequestStatusForSetActualDatetimeCompletion,
};

const extractGetDateTimeActualForExternalRequest =
  (key: ExternalRequestDatetimeOfActualKey) => (nextStatus?: number) => {
    const conditionForSetActualDatetime = STATUS_MAP[key] === nextStatus;
    return getObjectWitchActualDateTime(key)(conditionForSetActualDatetime);
  };

export const getDatetimeActualCommencement =
  extractGetDateTimeActualForExternalRequest(
    ExternalRequestDatetimeOfActualKey.commencement
  );

export const getDatetimeActualCompletion =
  extractGetDateTimeActualForExternalRequest(
    ExternalRequestDatetimeOfActualKey.completion
  );
