import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import IPrimaryKey from 'services/types/IPk';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import { IEmployee, IEmployeeWrapper } from './IEmployee';
import {
  IGetPaginatedParamsEmployee,
  IEmployeeFilters,
} from './IGetPaginatedParamsEmployee';
import { ICreateEmployee, IUpdateEmployee } from './ICreateEmpoyee';
import { IForSelectRequestV2 } from '../../../utils/generateMethodForSelect/generateMethodForSelect';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.EMPLOYEE
  );

export const getPaginatedEmployeeApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsEmployee>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IEmployee>>> =>
  api().getPaginated(filterParams);

export const readEmployeeApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<IEmployeeWrapper>> => api().read(params);

export const createEmployeeApiV2 = async (
  params: IBaseParams<ICreateEmployee>
): Promise<IBaseResponse<IEmployeeWrapper>> => await api().create(params);

export const updateEmployeeApiV2 = async (
  employee: IBaseParams<IUpdateEmployee>
): Promise<IBaseResponse<IEmployeeWrapper>> => await api().update(employee);

export const deleteEmployeeApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectEmployeeApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const getForSelectOnlyActiveEmployeeApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => {
  const newParams = {
    id: params.id,
    params: { ...params.params, only_active: true },
  };
  return api().forSelectWitchAdditionalParams(newParams);
};

export const getForSelectOnlySupervisorEmployeeApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => {
  const newParams = {
    id: params.id,
    params: { ...params.params, only_supervisor: true },
  };
  return api().forSelectWitchAdditionalParams(newParams);
};

export const getForSelectOnlySupervisorAndActiveEmployeeApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => {
  const newParams = {
    id: params.id,
    params: { ...params.params, only_supervisor: true, only_active: true },
  };
  return api().forSelectWitchAdditionalParams(newParams);
};
export const exportEmployeeApiV2 = async (
  filterParams: IBaseParams<IEmployeeFilters>
): Promise<IBaseResponse<string>> => api().export(filterParams);
