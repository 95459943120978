import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

export const findTargetSchema = (
  listSchema: IClientSelectOptionV2[],
  targetSchema?: number
) => {
  if (targetSchema) {
    return listSchema.find(
      (schema: IClientSelectOptionV2) => schema.value === targetSchema
    );
  }
  return undefined;
};
