import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class SetPasswordViaAdmin {
  public nickname: string = '';
  public plain_password: string = '';
  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      nickname: Yup.string()
        .min(1, t('Введите 1 или более букв.'))
        .required(t('Введите логин пользователя.')),
      plain_password: Yup.string()
        .checkPasswordComplexity()
        .required(t('Введите новый пароль.')),
    });
  };
}

export default SetPasswordViaAdmin;
