import styles from './../index.module.scss';
import { Collapse } from 'reactstrap';
import { FC, memo } from 'react';
 import {
  IClientAccessPolicyService,
  IClientAccessPolicyModel,
  IClientCodeActions,
} from '../AccessPolicyForm/AccessPolicyForm';
import AccessPolicyModelControl from '../AccessPolicyModelControl/AccessPolicyModelControl';
import CollapseControlSection from '../CollapseControlSection/CollapseControlSection';
import useGetAllActiveCountInServiceAccessPolicy from '../AccessPolicyHooks/useGetAllActiveCountInServiceAccessPolicy';
import useToggle from 'hooks/useToggle/useToggle';

export type TCheckHandler = (modelName?: string, actionCode?: string) => void;

const isCodeActionChecked = (code: IClientCodeActions) => code.checked;

const extractIsSectionChecked =
  (permissionsForModels: IClientAccessPolicyModel[]) =>
  (targetCodeActions?: IClientCodeActions[]): boolean => {
    if (targetCodeActions) {
      return targetCodeActions.every(isCodeActionChecked);
    } else {
      return permissionsForModels.every((model: IClientAccessPolicyModel) =>
        model.actionPermissions.every(isCodeActionChecked)
      );
    }
  };

type PropsType = {
  accessPolicyServiceModule: IClientAccessPolicyService;
  onAdd: TCheckHandler;
  onDelete: TCheckHandler;
  onAllDelete: TCheckHandler;
  onAllAdd: TCheckHandler;
  hasError?: boolean;
  required?: boolean;
};

const AccessPolicyServiceModule: FC<PropsType> = (props) => {
  const {
    accessPolicyServiceModule: { labelService, permissionsForModels },
    onAdd,
    onDelete,
    onAllDelete,
    onAllAdd,
  } = props;

  const [open, toggle] = useToggle();
   const counter =
    useGetAllActiveCountInServiceAccessPolicy(permissionsForModels);

  const isSectionChecked = extractIsSectionChecked(permissionsForModels);
  return (
    <>
      <CollapseControlSection
        label={labelService}
        counter={counter}
        toggle={toggle}
        open={open}
        checked={isSectionChecked()}
        onSectionDelete={onAllDelete}
        onSectionAdd={onAllAdd}
      />

      <Collapse isOpen={open} className={styles.collapsed} key={1}>
        {permissionsForModels.map(
          (modelPermission: IClientAccessPolicyModel) => (
            <AccessPolicyModelControl
              model={modelPermission}
              key={modelPermission.nameModel}
              onAdd={onAdd}
              onDelete={onDelete}
              onAllAdd={onAllAdd}
              onAllDelete={onAllDelete}
              isSectionChecked={isSectionChecked}
            />
          )
        )}
      </Collapse>
    </>
  );
};

export default memo(AccessPolicyServiceModule);
