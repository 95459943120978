import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import Folder from 'components/misc/Folder';
import React, { FC } from 'react';
import { PATH_PERSONNEL } from 'services/pathConstants';

const StaffFolderPage: FC = () => {
  return (
    <MainLayout>
      <Subheader />
      <Folder path={PATH_PERSONNEL} />
    </MainLayout>
  );
};

export default StaffFolderPage;
