import { IExternalRequestFilters } from '../../services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import config from '../../config.json';
import ExternalRequestSortCriteria from '../../services/api/external-request/external-request/ExternalRequestSortCriteria';

export const initialExternalRequestFilters: IExternalRequestFilters = {
  external_request_pk: undefined,
  external_request_datetime_added_upper_bound: '',
  external_request_datetime_added_lower_bound: '',
  external_request_datetime_edited_lower_bound: '',
  external_request_datetime_edited_upper_bound: '',
  external_request_author_dk_array: [],
  external_request_comment_text: '',
  external_request_description: '',
  external_request_work_category_fk_array: [],
  external_request_status_fk_array: [],
  external_request_billing_account_fk: undefined,
  external_request_billing_account_fk_array: [],
  // external_request_parent_external_request_fk_array: [],
  external_request_responsible_department_fk_array: [],
  external_request_external_code_number: '',
  external_request_reporter_full_name: '',
  external_request_reporter_phone_number: undefined,
  external_request_address_of_incident: '',
  external_request_source_of_information: '',
  external_request_supervisor_dk: undefined,
  external_request_implementer_dk_array: [],
  external_request_executive_implementer_dk: undefined,
  external_request_date_supposed_duedate_lower_bound: '',
  external_request_date_supposed_duedate_upper_bound: '',
  external_request_affiliate_fk_array: [],
  external_request_is_pruned: undefined,
  external_request_event_fk: undefined,
  external_request_event_fk_array: [],
  skip: 0,
  length: config.pageLength,
  sort_by: ExternalRequestSortCriteria.Default,
  isActiveImplementerDkArrayFilter: true,
  isActivePkFilter: true,
  isActiveBillingAccountFilter: true,
  isActiveWorkCategoryFilter: true,
};
