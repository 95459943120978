import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import React, { FC } from 'react';
import useRead from '../../../../hooks/useRead';
import { readEmployeeApiV2 } from '../../../../services/api/staff/employee/employeeApi';
import toEmployeeFormValues from './toEmployeeFormValues';
import { IEmployee } from '../../../../services/api/staff/employee/IEmployee';
import Employee from 'models/Employee';
import EmployeeForm from 'components/staff/employee/EmployeeForm/EmployeeForm';
import getInitials from 'services/utils/getInicials/getInicials';
import { targetAccessPolicyEmployee } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyPersonnel';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { t } from 'services/utils/translation';
import SubHeaderDetails from 'components/layouts/SubHeaderDetails/SubHeaderDetails';
import styles from './index.module.scss';
import { makeFirstLetterUppercase } from '../../../../services/utils/stringHelper/stringHelper';
import { EMPLOYEE_TEXT } from 'services/localLocalization/servicesAndModels';
import { useParams } from 'react-router-dom';
import useGoBackNavigate from 'hooks/useGoBackNavigate/useGoBackNavigate';

const HEADER_LABELS = {
  EMPLOYEE_TEXT: makeFirstLetterUppercase(EMPLOYEE_TEXT),
  EMPLOYEE_NICKNAME: t('Логин'),
};

type EmployeeEditPagePropTypes = {
  id?: string;
};

const EmployeeEditPage: FC<EmployeeEditPagePropTypes> = () => {
  const { id } = useParams<{ id: string }>();
  const goBack = useGoBackNavigate();

  const pk = id ? Number(id) : undefined;

  const [haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEmployee,
    targetAction: ['U'],
  });

  const { data: initialValue, isLoading } = useRead({
    getDataApi: readEmployeeApiV2,
    params: { pk },
    convertData: (employee: { employee: IEmployee }): Employee => {
      return toEmployeeFormValues(employee.employee);
    },
    condition: !!pk,
    initialData: new Employee(pk),
  });

  const fullNameEmployee = getInitials({
    firstName: initialValue?.employee_first_name,
    middleName: initialValue?.employee_middle_name,
    lastName: initialValue?.employee_last_name,
  });

  const identityNickName = initialValue?.employee_identity_nickname
    ? {
        [HEADER_LABELS?.EMPLOYEE_NICKNAME]:
          initialValue?.employee_identity_nickname,
      }
    : undefined;

  return (
    <MainLayout>
      <Subheader />
      <SubHeaderDetails
        classNameMainInfoValue={styles.mainInfoValue}
        mainInfo={{
          title: t('Сотрудник'),
          value: fullNameEmployee,
        }}
        items={identityNickName}
      />

      <EmployeeForm
        successHandler={goBack}
        pk={pk}
        initialValue={initialValue}
        isLoading={isLoading}
        isViewOnly={!haveAccessToUpdate}
      />
    </MainLayout>
  );
};

export default EmployeeEditPage;
