import usePrepareLink from './usePrepareLinks';
import { TAB_QUERY_KEY, TAB_QUERY_PARAMS } from '../services/pathConstants';

export type TQueryValue = typeof TAB_QUERY_KEY[keyof typeof TAB_QUERY_KEY];

const useGetTabsPatch = (key: TQueryValue) => {
  const documentsPatch = usePrepareLink({
    query: {
      [key]: TAB_QUERY_PARAMS.documents,
    },
  });

  const editPatch = usePrepareLink({
    query: {
      [key]: TAB_QUERY_PARAMS.edit,
    },
  });

  const historyPatch = usePrepareLink({
    query: {
      [key]: TAB_QUERY_PARAMS.history,
    },
  });

  const warehousePatch = usePrepareLink({
    query: {
      [key]: TAB_QUERY_PARAMS.warehouse,
    },
  });

  const bookingPatch = usePrepareLink({
    query: {
      [key]: TAB_QUERY_PARAMS.booking,
    },
  });

  return {
    editPatch,
    historyPatch,
    documentsPatch,
    warehousePatch,
    bookingPatch,
  };
};

export default useGetTabsPatch;
