import config from 'config.json';

export const extractIsFileNameLimit =
  (maxLimit: number) =>
  (files: File[]): boolean =>
    files.every((file: File) => file.name.length < maxLimit);

export const isFilenameLimitInDocumentForm = extractIsFileNameLimit(
  config.fileNameLimitLength
);
