import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import CounterUI from 'components/layouts/CounterUI/CounterUI';
import styles from './index.module.scss';
import {
  IMenuItemV2,
  ISubMenuItemV2,
} from '../../../../services/interfaces/IMenuItemV2';
import { ReactComponent as FavoriteIcon } from '../../../../img/icons/icon-star-fill.svg';
import classNames from 'clsx';
import useFavoriteFolderItemHandler from 'components/misc/FolderItem/useFolderItemHandlers';
import useExtractGetCountNotifications from '../../../../hooks/useGetCountNotifications';

type PropsType = {
  menuItem: IMenuItemV2 | ISubMenuItemV2;
  isFavorite?: boolean;
};

const SideBarItem: FC<PropsType> = ({
  menuItem: { url, name, title },
  isFavorite,
}) => {
  const onFavoriteHandler = useFavoriteFolderItemHandler({
    menuItemProps: {
      to: url,
      name,
      title,
      isFavorite,
    },
  });

  const getCountNotification = useExtractGetCountNotifications();

  const itemNotificationCount = getCountNotification(name);

  const hasNotification = !!itemNotificationCount && itemNotificationCount > 0;

  return (
    <div
      className={classNames(
        'd-flex justify-content-between align-items-center'
      )}
    >
      <div className="d-flex pr-3">
        <NavLink to={url} className={styles.link}>
          <div className="d-flex">{title}</div>
        </NavLink>
        {hasNotification && (
          <CounterUI count={itemNotificationCount} className={styles.counter} />
        )}
      </div>
      {isFavorite && (
        <div>
          <FavoriteIcon
            onClick={onFavoriteHandler}
            className={classNames({
              [styles.isFavoriteIcon]: isFavorite,
            })}
          />
        </div>
      )}
    </div>
  );
};

export default SideBarItem;
