 import { useCallback } from 'react';
import { IEvent } from 'services/api/organizationsV2/event/IEvent';
import { toEventsForList } from '../utils/toEventForList/toEventForList';
import { IEventForList } from '../IEventForList';

export const useToEventForList = () => {
  return useCallback((events: IEvent[]): IEventForList[] => {
    return toEventsForList(events);
  }, []);
};
