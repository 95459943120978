import getStringCharacteristics from 'services/utils/stringHelper/getStringCharacteristics/getStringCharacteristics.tsx';

export const PASSWORD_MIN_LENGTH = 6;

const isMediumPassword = (password: string) => {
  const {
    containsUppercase,
    containsLowercase,
    containsNumber,
    containsMinLength,
  } = getStringCharacteristics(password, PASSWORD_MIN_LENGTH);

  return (
    containsUppercase &&
    containsLowercase &&
    containsNumber &&
    containsMinLength
  );
};

export default isMediumPassword;
