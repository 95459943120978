import IBaseGetListParams from 'services/api/interfacesApi/IBaseGetListParams';

export enum SortCriteriaEventType {
  Default = '',
  NumberAsc = 'event_type_pk',
  NumberDesc = '-event_type_pk',
  RenditionAsc = 'event_type_rendition',
  RenditionDesc = 'event_type_rendition',
}

export interface IEventTypeGetPaginatedParams
  extends IBaseGetListParams<SortCriteriaEventType> {
  event_type_pk?: number;
  event_type_rendition?: string;
  // "event_type_datetime_added_upper_bound": "2022-02-09T15:09:37.965Z",
  // "event_type_datetime_added_lower_bound": "2022-02-09T15:09:37.965Z",
  // "event_type_datetime_edited_lower_bound": "2022-02-09T15:09:37.965Z",
  // "event_type_datetime_edited_upper_bound": "2022-02-09T15:09:37.965Z",
  // "event_type_fulltext_search": "string",
  // "event_type_rendition": "string"
}
