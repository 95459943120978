import classNames from 'clsx';
import styles from './index.module.scss';
import { THexToColorsKeys } from 'components/tables/misc/colorTr';
import { FC, memo, ReactNode } from 'react';

type PropsTypeColoredStatusHeaderLabel = {
  isVisible?: boolean;
  color: THexToColorsKeys;
  children?: ReactNode;
  className?: string;
};

const ColoredStatusHeaderLabel: FC<PropsTypeColoredStatusHeaderLabel> = (
  props
) => {
  const { color, isVisible = true, children, className } = props;
  return (
    <div
      className={classNames(styles.status, className, {
        'd-none': !isVisible,
      })}
      style={{
        backgroundColor: color,
      }}
    >
      {children}
    </div>
  );
};

export default memo(ColoredStatusHeaderLabel);
