import classNames from 'clsx';
import styles from './index.module.scss';
import { FC, MouseEventHandler } from 'react';
import { ReactComponent as DeleteIcon } from '../../../img/icons/icon-trash.svg';
type PropsType = {
  onClick: MouseEventHandler;
  className?: string;
};

const ButtonDelete: FC<PropsType> = ({ onClick, className }) => {
  return (
    <button
      type="button"
      className={classNames(
        'd-flex justify-content-center align-items-center',
        styles.deleteIconContainer,
        className
      )}
      onClick={onClick}
    >
      <DeleteIcon />
    </button>
  );
};

export default ButtonDelete;
