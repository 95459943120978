import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {
  PATH_EXTERNAL_REQUEST_ADD,
  PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST,
  PATH_EXTERNAL_REQUEST_EDIT,
  PATH_EXTERNAL_REQUEST_FOLDER,
  PATH_EXTERNAL_REQUEST_WORK_CATEGORY,
  PATH_LOGIN,
  PATH_NOTIFICATIONS,
  PATH_ORGANIZATIONS,
  PATH_ORGANIZATIONS_ACCOUNTS,
  PATH_ORGANIZATIONS_ACCOUNTS_ADD,
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
  PATH_ORGANIZATIONS_COUNTERPARTY,
  PATH_ORGANIZATIONS_COUNTERPARTY_ADD,
  PATH_ORGANIZATIONS_EVENT,
  PATH_ORGANIZATIONS_EVENT_ADD,
  PATH_ORGANIZATIONS_EVENT_EDIT,
  PATH_ORGANIZATIONS_EVENT_TYPE,
  PATH_PERSONNEL,
  PATH_PERSONNEL_ACCESS_POLICY,
  PATH_PERSONNEL_EMPLOYEE,
  PATH_PERSONNEL_EMPLOYEE_ADD,
  PATH_PERSONNEL_EMPLOYEE_EDIT,
  PATH_PERSONNEL_JOB_TITLE,
  PATH_TASK_EDIT,
  PATH_TASK_STATUSES,
  PATH_TASK_TABLE,
  PATH_TASK_TYPES,
  PATH_VEHICLE,
  PATH_EXTERNAL_REQUEST_COPY,
  PATH_EXTERNAL_REQUEST_FROM_TASK,
  PATH_EXTERNAL_REQUEST_RESPONSIBLE_DEPARTMENT,
  PATH_EXTERNAL_REQUEST_TABLE,
  PATH_ORGANIZATIONS_ACCOUNTS_EDIT,
  PATH_ORGANIZATIONS_BILLING_ACCOUNT_TYPE,
  PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
  PATH_TASK_CREATE_CHILD_TICKET,
  PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST,
  PATH_TASK_FOLDER,
  PATH_VEHICLE_TYPE,
  PATH_VEHICLES_FOLDER,
  PATH_ORGANIZATIONS_EVENT_VIEW,
  PATH_DOCUMENTS_FILE_BUCKET_SCHEMA,
  PATH_CONTROLLER_FOLDER,
  PATH_CONTROLLER_PAGE,
  PATH_CONTROLLER_EDIT,
  PATH_CONTROLLER_ADD,
} from 'services/pathConstants';
import AuthRoute from '../AuthRoute';
import GuestRoute from '../GuestRoute';
import TaskStatusesPage from 'pages/internal-task/task-status/TaskStatusesPage/TaskStatusesPage';
import NotificationsPage from 'pages/notifications/NotificationsPage';
import AuthPage from '../../../pages/misc/AuthPage/AuthPage';
import CounterpartyPage from '../../../pages/organizations/counterparty/CounterpartyPage';
import BillingAccountTypePage from 'pages/organizations/BillingAccountTypePage/BillingAccountTypePage';
import BillingAccountPage from '../../../pages/organizations/billing-accounts/BillingAccountPage/BillingAccountPage';
import BillingAccountEditPage from '../../../pages/organizations/billing-accounts/BillingAccountEditPage/BillingAccountEditPage';
import CounterpartyEditPage from 'pages/organizations/counterparty/CounterpartyEditPage';
import TaskCreateChildTaskPage from 'pages/internal-task/task/TaskCreateChildTask/TaskCreateChildTaskPage';
import TaskCreateFromExternalRequestPage from '../../../pages/internal-task/task/TaskCreateFromExternalRequestPage/TaskCreateFromExternalRequestPage';
import WorkCategoryPage from 'pages/external-request/work-category/WorCategoryPage/WorkCategoryPage';
import ExternalRequestPage from '../../../pages/external-request/external-requests/ExternalRequestPage/ExternalRequestPage';
import ExternalRequestEditPage from '../../../pages/external-request/external-requests/ExternalRequestEditPage/ExternalRequestEditPage';
import ExternalRequestFromTask from '../../../pages/external-request/external-requests/ExternalRequestFromTask/ExternalRequestFromTask';
import ExternalRequestCreateChildPage from '../../../pages/external-request/external-requests/ExternalRequesCreateChildPage/ExternalRequesCreateChildPage';
import ExternalRequestCreateDuplicatePage from 'pages/external-request/external-requests/ExternalRequestCreateDuplicatePage/ExternalRequestCreateDuplicatePage';
import BillingAccountViewPage from 'pages/organizations/billing-accounts/BillingAccountViewPage/BillingAccountViewPage';
import JobTitlePage from 'pages/staff/job-title/JobTitlePage';
import EmployeePage from '../../../pages/staff/employee/EmployeePage/EmployeePage';
import EmployeeEditPage from 'pages/staff/employee/EmployeeEditPage/EmployeeEditPage';
import AccessPolicyPage from 'pages/staff/access-policy/AccessPolicyPage/AccessPolicyPage';
import ExternalRequestFolderPage from '../../../pages/external-request/ExternaRequestFolderPage/ExternalRequestFolderPage';
import TaskFolderPage from 'pages/internal-task/TaskFolderPage/TaskFolderPage';
import ResponsibleDepartmentPage from 'pages/external-request/responsible-department/ResponsibleDepartment.tsx/ResponsibleDepartmentPage';
import VehicleTypePage from 'pages/vehicle/vehicle-type/VehicleTypePage';
import VehiclePage from 'pages/vehicle/vehicle/VehiclePage';
import VehicleFolderPage from 'pages/vehicle/VehicleFolderPage/VehicleFolderPage';
import {
  PATH_VEHICLE_BOOKING,
  PATH_DOCUMENTS_FOLDER,
} from '../../../services/pathConstants';
import BookingPage from 'pages/vehicle/booking/BookingPage';
import EventTypePage from 'pages/organizations/EventTypePage/EventTypePage';
import EventPage from 'pages/organizations/event/EventPage/EventPage';
import EventEditPage from 'pages/organizations/event/EventEditPage/EventEditPage';
import EventViewPage from 'pages/organizations/event/EventViewPage/EventViewPage';
import BillingAccountAddPage from 'pages/organizations/billing-accounts/BillingAccountAddPage/BillingAccountAddPage';
import DocumentsFolderPage from 'pages/documents/DocumentsFolderPage/DocumentsFolderPage';
import FileBucketSchemaPage from 'pages/documents/FileBucketSchemaPage/FileBucketSchemaPage';
import WithErrorBoundary from 'components/misc/WithErrorBoundary/WithErrorBoundary';
import ExternalRequestAddPage from 'pages/external-request/external-requests/ExternalRequestAddPage/ExternalRequestAddPage';
import NotFoundPage from 'pages/misc/NotFoundPage';
import HomePage from 'pages/misc/HomePage';
import TaskTypePage from 'pages/internal-task/task-type/TaskTypePage/TaskTypePage';
import StaffFolderPage from 'pages/staff/StaffFolderPage';
import TaskPage from 'pages/internal-task/task/TaskPage/TaskPage';
import TicketEditPage from 'pages/internal-task/task/TaskEditPage/TaskEditPage';
import * as Sentry from '@sentry/react';
import OrganizationsPage from 'pages/organizations/OrganizationsFolderPage';
import ControllerPage from 'pages/contrtoller/ControllerPage/ControllerPage';
import ContrtollerFolderPage from 'pages/contrtoller/ContrtollerFolderPage/ContrtollerFolderPage';
import ControllerEditPage from 'pages/contrtoller/ControllEditPage/ControllerEditPage';
import ControllerAddPage from 'pages/contrtoller/ControllerAddPage/ControllerAddPage';

const getRouter = (loading: boolean = false) => {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const routes = createRoutesFromElements(
    <>
      <Route path={PATH_LOGIN} element={<GuestRoute />}>
        <Route path="" element={<AuthPage />} />
      </Route>
      <Route
        element={
          <WithErrorBoundary>
            <AuthRoute loading={loading} />
          </WithErrorBoundary>
        }
      >
        <Route path="/" element={<HomePage />} />
        <Route path={PATH_PERSONNEL_JOB_TITLE} element={<JobTitlePage />} />
        <Route path={PATH_TASK_TYPES} element={<TaskTypePage />} />
        <Route
          path={PATH_EXTERNAL_REQUEST_WORK_CATEGORY}
          element={<WorkCategoryPage />}
        />
        <Route
          path={PATH_EXTERNAL_REQUEST_RESPONSIBLE_DEPARTMENT}
          element={<ResponsibleDepartmentPage />}
        />
        <Route path={PATH_TASK_STATUSES} element={<TaskStatusesPage />} />
        <Route path={PATH_ORGANIZATIONS} element={<OrganizationsPage />} />
        <Route
          path={PATH_ORGANIZATIONS_COUNTERPARTY}
          element={<CounterpartyPage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_COUNTERPARTY_ADD}
          element={<CounterpartyEditPage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_COUNTERPARTY_EDIT}
          element={<CounterpartyEditPage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_BILLING_ACCOUNT_TYPE}
          element={<BillingAccountTypePage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_ACCOUNTS}
          element={<BillingAccountPage />}
        />
        <Route path={PATH_ORGANIZATIONS_EVENT} element={<EventPage />} />
        <Route
          path={PATH_ORGANIZATIONS_EVENT_VIEW}
          element={<EventViewPage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_EVENT_ADD}
          element={<EventEditPage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_EVENT_EDIT}
          element={<EventEditPage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_EVENT_TYPE}
          element={<EventTypePage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_ACCOUNTS_ADD}
          element={<BillingAccountAddPage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_ACCOUNTS_EDIT}
          element={<BillingAccountEditPage />}
        />
        <Route
          path={PATH_ORGANIZATIONS_ACCOUNTS_VIEW}
          element={<BillingAccountViewPage />}
        />
        <Route path={PATH_PERSONNEL} element={<StaffFolderPage />} />
        <Route
          path={PATH_PERSONNEL_ACCESS_POLICY}
          element={<AccessPolicyPage />}
        />
        <Route path={PATH_PERSONNEL_EMPLOYEE} element={<EmployeePage />} />
        <Route
          path={PATH_PERSONNEL_EMPLOYEE_ADD}
          element={<EmployeeEditPage />}
        />
        <Route
          path={PATH_PERSONNEL_EMPLOYEE_EDIT}
          element={<EmployeeEditPage />}
        />
        <Route path={PATH_TASK_TABLE} element={<TaskPage />} />
        <Route path={PATH_TASK_EDIT} element={<TicketEditPage />} />
        <Route
          path={PATH_TASK_CREATE_CHILD_TICKET}
          element={<TaskCreateChildTaskPage />}
        />
        <Route
          path={PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST}
          element={<TaskCreateFromExternalRequestPage />}
        />
        <Route path={PATH_TASK_FOLDER} element={<TaskFolderPage />} />
        <Route
          path={PATH_EXTERNAL_REQUEST_FOLDER}
          element={<ExternalRequestFolderPage />}
        />
        <Route
          path={PATH_EXTERNAL_REQUEST_TABLE}
          element={<ExternalRequestPage />}
        />
        <Route
          path={PATH_EXTERNAL_REQUEST_ADD}
          element={<ExternalRequestAddPage />}
        />
        <Route
          path={PATH_EXTERNAL_REQUEST_EDIT}
          element={<ExternalRequestEditPage />}
        />
        <Route
          path={PATH_EXTERNAL_REQUEST_COPY}
          element={<ExternalRequestCreateDuplicatePage />}
        />
        <Route
          path={PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST}
          element={<ExternalRequestCreateChildPage />}
        />
        <Route
          path={PATH_EXTERNAL_REQUEST_FROM_TASK}
          element={<ExternalRequestFromTask />}
        />
        <Route path={PATH_VEHICLE} element={<VehiclePage />} />
        <Route path={PATH_VEHICLES_FOLDER} element={<VehicleFolderPage />} />
        <Route path={PATH_VEHICLE_TYPE} element={<VehicleTypePage />} />
        <Route path={PATH_VEHICLE_BOOKING} element={<BookingPage />} />
        <Route path={PATH_DOCUMENTS_FOLDER} element={<DocumentsFolderPage />} />
        <Route
          path={PATH_DOCUMENTS_FILE_BUCKET_SCHEMA}
          element={<FileBucketSchemaPage />}
        />
        <Route
          path={PATH_CONTROLLER_FOLDER}
          element={<ContrtollerFolderPage />}
        />
        <Route path={PATH_CONTROLLER_PAGE} element={<ControllerPage />} />
        <Route path={PATH_CONTROLLER_EDIT} element={<ControllerEditPage />} />
        <Route path={PATH_CONTROLLER_ADD} element={<ControllerAddPage />} />

        <Route path={PATH_NOTIFICATIONS} element={<NotificationsPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </>
  );

  return sentryCreateBrowserRouter(routes);
};

export default getRouter;
