import { toast } from 'react-toastify';
import { t } from '../../../services/utils/translation';
import { catchErrorFromFetch } from '../../../services/utils/catchAndRegError/catchAndRegError';

const ERR_TEXT = t('Не удалось скопировать ссылку');

export const useHandleNotificationPayload = (): [
  (url: string) => void,
  (textToCopy: string) => void
] => {
  const handleClickDownload = (url: string) => window.location.assign(url);

  const copyText = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success(t('Ссылка скопирована в буфер обмена'));
    } catch (err) {
      catchErrorFromFetch(ERR_TEXT, err);
    }
  };

  return [handleClickDownload, copyText];
};

export default useHandleNotificationPayload;
