import CommentBlockWrapper, {
  extractConvertParamsForAddComment,
} from 'components/controls/CommentBlock/CommentBlockWrapper/CommentBlockWrapper';
import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import {
  addCommentControllerApi,
  getCommentsControllerApi,
} from 'services/api/controller/controllerApi';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';
import IPrimaryKey from 'services/types/IPk';
import { getCurrentEmployeePkFromStore } from 'store/general/selectors';

type PropsType = {
  pk: string;
  viewOnly: boolean;
};

const ControllerComments: FC<PropsType> = ({ pk, viewOnly }) => {
  const authorId = useSelector(getCurrentEmployeePkFromStore);

  const convertParams = extractConvertParamsForAddComment(
    authorId,
    'controller'
  );
  return (
    <CommentBlockWrapper
      pk={pk}
      getComments={
        getCommentsControllerApi as (
          params: IBaseParams<IPrimaryKey<number | string>>
        ) => Promise<IBaseResponse<IGetCommentsPayloadV2>>
      }
      addCommentApiV2={
        addCommentControllerApi as (
          params: IBaseParams<unknown>
        ) => Promise<IBaseResponse<unknown>>
      }
      convertParams={convertParams}
      viewOnly={viewOnly}
    />
  );
};

export default memo(ControllerComments);
