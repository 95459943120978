import UndercurrentApiClient from '../../UndercurrentApiClient';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import {
  IExternalRequestFilters,
  IPaginatedParamsExternalRequest,
} from './useGetExternalRequestNavigationHandlers';
import IBaseParams from '../../interfacesApi/IBaseParams';
import { IExternalRequest } from 'services/api/external-request/external-request/IExternalRequest';
import IPrimaryKey from '../../../types/IPk';
import IWrapperExternalRequest from './IWrapperExternalRequest';
import IAddCommentExternalRequestParams from './IAddCommentExternalRequestParams';
import { ICreateExternalRequest } from './ICreateExternalRequest';
import { IHistoryExternalRequestPayload } from './IHistoryExternalRequest';
import { IBaseGetPaginatedPayload } from '../../../IBaseGetPaginated';
import ApiActions from 'services/api/apiActions';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { ISetParentTaskForExternalRequest } from './ISetParentTaskForExternalRequest';
import { IDeleteParentForExternalRequest } from './IDeleteParentForExternalRequest';
import { ISetParentExternalRequestForExternalRequest } from './ISetParentExternalRequestForExternalRequest';
import { createExternalRequestParentUpdateApiMethod } from 'services/api/UndercurrentParentUpdateApiMethods';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.EXTERNAL_REQUEST
  );

export const getPaginatedExternalRequestApiV2 = async (
  params: IBaseParams<IExternalRequestFilters | IPaginatedParamsExternalRequest>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IExternalRequest[]>>> =>
  api().getPaginated(params);

export const exportExternalRequestV2 = async (
  filterParams: IBaseParams<IExternalRequestFilters>
): Promise<IBaseResponse<string>> => api().export(filterParams);

export const readExternalRequestV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IWrapperExternalRequest>> => api().read(params);

export const getHistoryExternalRequestV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IHistoryExternalRequestPayload>> =>
  api().getHistory(params);

export const createExternalRequestV2 = async (
  params: IBaseParams<ICreateExternalRequest>
): Promise<IBaseResponse<IWrapperExternalRequest>> => api().create(params);

export const updateExternalRequestV2 = async (
  params: IBaseParams<ICreateExternalRequest>
): Promise<IBaseResponse<IWrapperExternalRequest>> => api().update(params);

export const addCommentExternalRequestApiV2 = async (
  params: IBaseParams<IAddCommentExternalRequestParams>
): Promise<IBaseResponse<IWrapperExternalRequest>> => api().addComment(params);

export const getCommentsExternalRequestApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IGetCommentsPayloadV2>> => api().getComments(params);

export const doPrunedExternalRequestApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IExternalRequest>> =>
  api().doUpdate(params, ApiActions.PRUNE);

export const getExternalRequestForSelectApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const setParentTaskForExternalRequestApi = async (
  params: IBaseParams<ISetParentTaskForExternalRequest>
): Promise<IBaseResponse<IWrapperExternalRequest>> => api().update(params);

export const setParentExternalRequestForExternalRequestApi = async (
  params: IBaseParams<ISetParentExternalRequestForExternalRequest>
): Promise<IBaseResponse<IWrapperExternalRequest>> =>
  createExternalRequestParentUpdateApiMethod().addParent(params);

export const deleteParentForExternalRequestApi = async (
  params: IBaseParams<IDeleteParentForExternalRequest>
): Promise<IBaseResponse<IWrapperExternalRequest>> => api().update(params);
