import TemplateMainLayoutHeaderSearch, {
  HEADER_SEARCH_PROP_KEY_LABEL,
  HEADER_SEARCH_PROP_KEY_PK,
} from 'components/layouts/TemplateMainLayoutHeaderSearch/TemplateMainLayoutHeaderSearch';
import { getPaginatedBillingAccountsV2 } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import { t } from 'services/utils/translation';
import { PATH_ORGANIZATIONS_ACCOUNTS_VIEW } from '../../../../services/pathConstants';
import { FC } from 'react';

const BillingAccountSearch: FC = () => {
  return (
    <TemplateMainLayoutHeaderSearch
      keys={{
        keyPk: HEADER_SEARCH_PROP_KEY_PK.billingAccount,
        keyForViewText: HEADER_SEARCH_PROP_KEY_LABEL.billingAccount,
      }}
      patch={PATH_ORGANIZATIONS_ACCOUNTS_VIEW}
      searchField={'billing_account_code_number'}
      getList={getPaginatedBillingAccountsV2}
      tittle={t('Поиск по лицевому счету')}
    />
  );
};

export default BillingAccountSearch;
