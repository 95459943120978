import config from 'config.json';
import {
  IEmployeeFilters,
  EmployeeRequestSortCriteria,
} from '../../services/api/staff/employee/IGetPaginatedParamsEmployee';

export const initialEmployeeFilters: IEmployeeFilters = {
  employee_pk: undefined,
  // employee_pk_array: [],
  employee_fulltext_search: '',
  employee_job_title_fk_array: [],
  employee_supervisor_fk_array: [],
  employee_identity_dk: undefined,
  employee_is_driver: undefined,
  employee_is_active: undefined,
  employee_phone_number: '',
  employee_email: '',
  employee_personnel_code_number: '',
  skip: 0,
  length: config.pageLength,
  sort_by: EmployeeRequestSortCriteria.Default,
};
