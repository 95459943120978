import { useMemo } from 'react';
import getStringCharacteristics from 'services/utils/stringHelper/getStringCharacteristics/getStringCharacteristics.tsx';
import { PASSWORD_MIN_LENGTH } from './utils/isMediumPassword';

const useGetStringCharacteristics = (password?: string) => {
  return useMemo(() => {
    const {
      containsUppercase,
      containsLowercase,
      containsNumber,
      containsMinLength,
      containsSpecialCharacter,
    } = getStringCharacteristics(password, PASSWORD_MIN_LENGTH);

    return {
      containsUppercase,
      containsLowercase,
      containsNumber,
      containsMinLength,
      containsSpecialCharacter,
    };
  }, [password]);
};

export default useGetStringCharacteristics;
