import { useCallback, useMemo } from 'react';
import extractHandlePayloadOptions from './extractHandlePayloadOptions';
import { IForSelectResponseV2 } from '../../components/controls/DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';

const defaultOption = {
  options: [],
  hasMore: false,
};

const useExtractHandleSelectResponse = (curentId?: string) => {
  const handlePayloadOptions = useMemo(() => {
    return extractHandlePayloadOptions(curentId);
  }, [curentId]);

  return useCallback(
    (page: any, payload?: IForSelectResponseV2) => {
      if (payload) {
        const { list_of_items: items, more } = payload;
        const options = handlePayloadOptions(items);
        return {
          options,
          hasMore: more,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return defaultOption;
      }
    },
    [handlePayloadOptions]
  );
};

export default useExtractHandleSelectResponse;
