import usePrepareLink from 'hooks/usePrepareLinks';
import { TAB_QUERY_KEY, TAB_QUERY_PARAMS } from 'services/pathConstants';
import { t } from 'services/utils/translation';
import { parentUIElement } from 'services/constants/parentUIElement';
import useGetEventTotal from '../useGetEventTotal/useGetEventTotal';

export type TParentUiForEventTab = parentUIElement.account;

interface IUseGetEventTab {
  pk: number;
  parentUI?: TParentUiForEventTab;
}

export const useGetEventTab = ({
  pk,
  parentUI = parentUIElement.account,
}: IUseGetEventTab) => {
  const { totalEvent, isLoadingEvent } = useGetEventTotal({
    pk,
    parentUI,
  });

  const tabKey = TAB_QUERY_KEY[parentUI];

  const eventPatch = usePrepareLink({
    query: {
      [tabKey]: TAB_QUERY_PARAMS.event,
    },
  });

  const eventTab = {
    name: t('События'),
    recordsTotal: totalEvent,
    patch: eventPatch,
  };

  return { eventTab, isLoadingEvent };
};
