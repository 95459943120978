type StorageType = 'localStorage' | 'sessionStorage';
export class Storage {
  protected storage: globalThis.Storage;
  constructor(typeStorage: StorageType) {
    this.storage = this.geTypeStorage(typeStorage);
  }

  geTypeStorage = (type: StorageType): globalThis.Storage =>
    ({
      localStorage: localStorage,
      sessionStorage: sessionStorage,
    }[type]);

  setValue = <Value extends unknown>(key: string, value: Value) => {
    if (value) {
      const JSONValue = JSON.stringify(value);
      this.storage.setItem(key, JSONValue);
    } else {
      console.error(`Error -> Missing value for create ${key} in storage`);
      return;
    }
  };

  getValue = <Value extends unknown>(key: string): Value => {
    const data = this.storage.getItem(key);
    return !data ? null : JSON.parse(data);
  };

  removeValue = (key: string): void => sessionStorage.removeItem(key);
}

export class SessionStorage extends Storage {
  constructor() {
    super('sessionStorage');
  }
}


