export interface IControllerClientAccessPolicy {
  billingAccountHaveAccessToRead: boolean;
  controller: {
    haveAccessToUpdate: boolean;
    haveAccessToCreate: boolean;
    haveAccessToDelete: boolean;
    haveAccessRead: boolean;
  };
}

export interface IControllerClientAccessPolicyStore {
  isInstalled: boolean;
  accessPolicy: IControllerClientAccessPolicy;
}

export const initialControllerAccessPolicy: IControllerClientAccessPolicyStore =
  {
    isInstalled: false,
    accessPolicy: {
      billingAccountHaveAccessToRead: false,
      controller: {
        haveAccessToUpdate: false,
        haveAccessToCreate: false,
        haveAccessToDelete: false,
        haveAccessRead: false,
      },
    },
  };
