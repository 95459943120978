import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import { regTelephoneNumber } from "../services/utils/myRegex/myRegex";

class Otp {
  public phone_number: string = '';

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      phone_number: Yup.string()
        .trim()
        .matches(regTelephoneNumber, t('Некорректно введен номер телефона'))
        .required(t('Укажите телефон.')),
    });
  };
}

export default Otp;
