import useRead from 'hooks/useRead';
import { IEventShort } from 'services/api/organizationsV2/event/IEventShort';
import { IEventWrapper } from 'services/api/organizationsV2/event/IEventWrapper';
import { readEventApi } from 'services/api/organizationsV2/event/eventApi';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';

const useExtractGetBillingAccountFromEvent = (event?: IEventShort) => {
  const { data: billingAccountFromEvent, isLoading: isLoadingEvent } = useRead({
    getDataApi: readEventApi,
    condition: !!event?.event_pk,
    params: { pk: event?.event_pk },
    convertData: ({ event }: IEventWrapper) =>
      createClientSelectOptionV2(
        event.event_billing_account.billing_account_pk,
        event.event_billing_account.billing_account_code_number
      ),
    initialData: { label: '', value: 0 },
  });

  return { billingAccountFromEvent, isLoadingEvent };
};

export default useExtractGetBillingAccountFromEvent;
