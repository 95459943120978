import useLocalStorage, { TypeSetValue } from './useLocalStorage';
import { ISubMenuItemV2 } from '../services/interfaces/IMenuItemV2';
import useCreateUniqueIdKey from './useCreateUniqueIdKey';

const useMyListOfFavoriteFolders = (): [
  ISubMenuItemV2[],
  TypeSetValue<ISubMenuItemV2[]>
] => {
  const uniqId = useCreateUniqueIdKey();
  const [myListOfFavoriteFolders, setMyListOfFavoriteFolders] = useLocalStorage<
    ISubMenuItemV2[]
  >({
    key: `myListOfFavoriteFolders-${uniqId}`,
    initialValue: [],
  });
  return [myListOfFavoriteFolders, setMyListOfFavoriteFolders];
};

export default useMyListOfFavoriteFolders;
