import config from "../../../config.json";
// regular expression for phone inputs
// change the config by adding the code
// Example 9|4
export const regTelephoneNumber = new RegExp(
  `\\+7\\s\\((${config.telephoneAreaCodes})[0-9]{2}\\)\\s[0-9]{3}-[0-9]{2}-[0-9]{2}|(${config.telephoneAreaCodes})[0-9]{9}`,
  'g'
);
  export const regEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  export const regTransport = new RegExp(
      /(([АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{1,2})(\d{2,3})|(\d{4}[АВЕКМНОРСТУХ]{2})(\d{2})|(\d{3}C?D{1,2}\d{3})(\d{2})|([АВЕКМНОРСТУХ]{2}\d{3}[АВЕКМНОРСТУХ])(\d{2})|([АВЕКМНОРСТУХ]\d{4})(\d{2})|(\d{3}[АВЕКМНОРСТУХ])(\d{2})|(\d{4}[АВЕКМНОРСТУХ])(\d{2}))/i
  );
  export const regISO = new RegExp(
      /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
  );


//TODO: "C" in "ЛС" is Latin. force to remake change to Russian

export const regBillingAccountNumberInLabel = /(?<=ЛC:\s)[а-яА-ЯёЁa-zA-Z0-9]+/;
