import classNames from 'clsx';
import styles from './index.module.scss';

import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import HeaderWithSearch from 'components/tables/HeaderWithSearch';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import Pager from 'components/tables/Pager';
import { useColumnFilter } from 'hooks/useColumnFilter';
import { ReactComponent as CreateIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as PrintIcon } from 'img/icons/icon-printer.svg';

import { FC, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'services/utils/translation';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { useReactToPrint } from 'react-to-print';
import { composeClientSelectOptionsInNumbersArray } from '../../../../services/utils/selects/composeIClientSelectOptionsInNumbers';
import trimStringInObject from 'services/utils/trimStringInObject/trimStringInObject';
import LoaderFetch from '../../../../components/layouts/LoaderFetch/LoaderFetch';
import useClearFilters from 'hooks/useClearFilters';

import HeaderWithDynamicSelectPaginatedV2 from 'components/tables/HeaderWithDynamicSelectPaginatedV2/HeaderWithDynamicSelectPaginatedV2';
import { getPaginatedTasksApiV2 } from 'services/api/tasks/tasks/tasksApiV2';
import { useGetList } from '../../../../hooks/useGetList';
import { ITaskForList } from '../../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import {
  IGetPaginatedTasksParams,
  ITaskFilters,
} from '../../../../services/api/tasks/tasks/IGetPaginatedTasksParams';
import HeaderSelectGroup from 'components/tables/HeaderSelectGroup/HeaderSelectGroup';
import HeaderWithDatePickerRangeV2 from 'components/tables/HeaderWithDatePickerRangeV2/HeaderWithDatePickerRangeV2';
import {
  getAllStatusesApiV2,
  getTaskStatusesForSelectApiV2,
} from '../../../../services/api/tasks/task-statusesV2/taskSatusesV2';
import TaskForm from 'components/tasks/TaskForm/TaskForm';
import { IKeyValue } from '../../../../services/interfaces/IKeyValue';
import TasksPrint from '../TasksPrint/TasksPrint';
import useRead from 'hooks/useRead';
import { IGetPaginatedStatusesPayload } from '../../../../services/api/tasks/task-statusesV2/IGetPaginatedStatusesPayload';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import ITaskStatus from 'services/api/tasks/task-statusesV2/ITaskStatus';
import { getForSelectBillingAccountApiV2 } from '../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import SubHeaderButtonExport from 'components/tables/SubHeaderButtonExport/SubHeaderButtonExport';
import { exportTaskApiV2 } from '../../../../services/api/tasks/tasks/tasksApiV2';
import toTaskForList from './toTaskForList';
import { initialTasksFilters } from '../../../../store/task/initialTasksFilters';
import { getForSelectEmployeeApiV2 } from '../../../../services/api/staff/employee/employeeApi';
import { setDefaultStatus } from '../../../../store/task/actions';
import { getDefaultStatusFromStore } from '../../../../store/task/selectors';
import { useGetTaskTypesOptions } from '../../../../hooks/useGetTaskTypesOptions';
import RefreshButton from 'components/tables/RefreshButton/RefreshButton';
import deleteUnnecessaryParameters from '../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import DoubleHeaderFilterContainer from 'components/tables/DoubleHeaderFilterContainer/DoubleHeaderFilterContainer';
import { getForSelectCounterpartyApiV2 } from 'services/api/organizationsV2/counterparty/counterpartyApi';
import useDoubleFilterUtilitiesForTask from './hooks/useDoubleFilterUtilitiesForTask/useDoubleFilterUtilitiesForTask';
import useTaskHandlersSearch from './hooks/useTaskHandlersSearch/useTaskHandlersSearch';
import THCustom from 'components/tables/THCustom/THCustom';
import {
  THCustomDate,
  THCustomSelect,
  THCustomUnruled,
} from 'components/tables/THCustomWrapper/THCustomWrapper';
import HeaderWithSelectAdvanced from 'components/tables/HeaderWithSelectAdvanced/HeaderWithSelectAdvanced';
import useFilterBooleanSelect, {
  BooleanSelectField,
} from 'hooks/useFilterIsPruned/useFilterBooleanSelect';
import TasksList from './ui/TaskList/TasksList';
import useTaskSuccessHandler from '../hooks/useTaskSuccessHandler';
import useGetInitialTaskFilters from '../hooks/useGetInitialTaskFilters';
import useSaveTaskFilters from './hooks/useSaveTaskFilters/useSaveTaskFilters';
import {
  COLUMN_LABELS_TASK,
  columnVisibilityInitialTask,
  taskFilters,
} from './columnTasks';
import {
  FilterType,
  getClearFilters,
} from 'services/utils/getClearTaskAndExternalRequestFilters/getClearTaskAndExternalRequestFilters';
import getPermanentFiltersValue from 'services/utils/getPermanentFiltersValue/getPermanentFiltersValue';
import TableWrapper from 'components/tables/TableWrapper/TableWrapper';
import MyFilters from 'components/tables/MyFilters/MyFilters';
import useGetKeyForMyFilter from 'hooks/useGetKeyForMyFilter/useGetKeyForMyFilter';
import useSetAndGetTaskAccessPolicy from './hooks/useSetAndGetTaskAccessPolicy/useSetAndGetTaskAccessPolicy';

export interface ITaskEntityPk {
  billingAccountPk?: number;
  counterpartyPk?: number;
}

// TODO move to utils
const composeParamsForGetTasks = (
  params: ITaskFilters
): IGetPaginatedTasksParams => {
  const {
    internal_task_author_dk_array,
    internal_task_supervisor_dk_array,
    internal_task_type_fk_array,
    internal_task_billing_account_fk_array,
    internal_task_status_fk_array,
    internal_task_counterparty_fk_array,
  }: IKeyValue<number[]> =
    composeClientSelectOptionsInNumbersArray<ITaskFilters>(params, [
      'internal_task_author_dk_array',
      'internal_task_supervisor_dk_array',
      'internal_task_type_fk_array',
      'internal_task_billing_account_fk_array',
      'internal_task_status_fk_array',
      'internal_task_counterparty_fk_array',
    ]);

  const composeNewParams = trimStringInObject({
    ...params,
    internal_task_is_pruned: false,
    internal_task_author_dk_array,
    internal_task_supervisor_dk_array,
    internal_task_type_fk_array,
    internal_task_billing_account_fk_array,
    internal_task_counterparty_fk_array,
    internal_task_status_fk_array,
  });

  return deleteUnnecessaryParameters(
    composeNewParams,
    ['isActiveCounterpartyFilter'],
    ['internal_task_is_pruned']
  );
};

const extractDefaultStatuses = (
  payloadStatuses: IGetPaginatedStatusesPayload
): IClientSelectOptionV2<number> | undefined => {
  const firstStatus = payloadStatuses?.list_of_entities.find(
    (status: ITaskStatus) => status.internal_task_status_can_be_first
  );

  return (
    firstStatus && {
      value: firstStatus.internal_task_status_pk,
      label: firstStatus?.internal_task_status_rendition,
    }
  );
};

type TicketsPagePropsType = ITaskEntityPk;

const TaskPage: FC<TicketsPagePropsType> = (props) => {
  const { billingAccountPk, counterpartyPk } = props;

  const dispatchRedux = useDispatch();

  const saveFilters = useSaveTaskFilters({ billingAccountPk, counterpartyPk });

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitialTask, 'taskColumnFilter');

  const [state, dispatch] = useReducer(reducer, {
    isCreateModalOpen: false,
    ticketId: null,
  });
  const isInsidePage = !!billingAccountPk || !!counterpartyPk;

  const defaultStatus = useSelector(getDefaultStatusFromStore);

  const {
    employeeHaveAccessToRead,
    billingAccountHaveAccessToRead,
    counterpartyHaveAccessToRead,
    taskTypeHaveAccessToRead,
    taskStatusHaveAccessToRead,
    task: { haveAccessToCreate, haveAccessToExport },
  } = useSetAndGetTaskAccessPolicy();

  const onFinally = () => setClearingFiltersSpinner(false);

  const initialParams = useGetInitialTaskFilters({
    billingAccountPk,
    counterpartyPk,
  });

  const {
    data: tasks,
    isLoading,
    total,
    onSearchRequest,
    onSortClick,
    params,
    refreshListData,
    setStart,
    setLength,
    setData,
    setParams,
  } = useGetList({
    getDataApi: getPaginatedTasksApiV2,
    initialParams,
    convertData: toTaskForList,
    saveFilters,
    onFinally,
    convertedParameters: composeParamsForGetTasks,
  });

  const {
    internal_task_pk: taskPk,
    internal_task_author_dk_array: authorDkArray,
    internal_task_supervisor_dk_array: supervisorDkArray,
    internal_task_billing_account_fk_array: billingAccountDkArray,
    internal_task_counterparty_fk_array: counterpartyDkArray,
    internal_task_status_fk_array: statusFkArray,
    internal_task_date_duedate_upper_bound: duedateUpper,
    internal_task_date_duedate_lower_bound: duedateLower,
    internal_task_description: description,
    internal_task_datetime_edited_upper_bound: datetimeEditedUpper,
    internal_task_datetime_edited_lower_bound: datetimeEditedLower,
    internal_task_datetime_added_upper_bound: datetimeAddedUpper,
    internal_task_datetime_added_lower_bound: datetimeAddedLower,
    internal_task_type_fk_array: taskTypeFkArray,
    internal_task_is_pruned: isPrunedFilter,
    isActiveCounterpartyFilter,
    skip,
    length,
  } = params;

  const refresh = () => {
    refreshListData();
    setParams({ ...params, skip: 0 });
  };

  const permanentValue = getPermanentFiltersValue(FilterType.task, {
    billingAccountPk,
    counterpartyPk,
  });

  const clearFiltersTasks = getClearFilters(FilterType.task, {
    billingAccountPk,
    counterpartyPk,
  });

  const { clearFilters, isDisableClearButton, setClearingFiltersSpinner } =
    useClearFilters(
      initialTasksFilters,
      params,
      clearFiltersTasks,
      setParams,
      permanentValue
    );

  const {
    handlePkTaskSearch,
    handleAuthorTaskMultiSelect,
    handleSupervisorTaskMultiSelect,
    handleCounterpartyTaskMultiSelect,
    handleBillingAccountTaskMultiSelect,
    handleTaskTypeGroupSelect,
    handleStatusTaskMultiSelect,
    handleDescriptionTaskSearch,
  } = useTaskHandlersSearch(onSearchRequest);

  const { handleSelectBooleanType, booleanOptions } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.task,
  });

  const {
    hintForDoubleFilters: {
      hintForBillingAccountFilter,
      hintForCounterpartyFilter,
    },
    callSetIsActiveCounterpartyFilter,
    isDisabledDoubleFilterCounterpartyAndBillingAccoount,
  } = useDoubleFilterUtilitiesForTask({
    params,
    counterpartyPk,
    billingAccountPk,
  });

  const { data: status } = useRead<
    IGetPaginatedStatusesPayload,
    IClientSelectOptionV2<number> | undefined,
    {}
  >({
    getDataApi: getAllStatusesApiV2,
    params: {},
    convertData: extractDefaultStatuses,
    condition: !defaultStatus && taskStatusHaveAccessToRead,
    initialData: undefined,
  });

  useEffect(() => {
    if (!defaultStatus) {
      dispatchRedux(setDefaultStatus(status));
    }
  }, [status, dispatchRedux, defaultStatus]);

  const { taskTypeOptions } = useGetTaskTypesOptions(taskTypeHaveAccessToRead);

  //actions

  const openCreateTicketModal = (ticketId?: number) => {
    dispatch({
      type: 'openCreateTicketModal',
      ticketId,
    });
  };
  const closeCreateTicketModal = () => {
    dispatch({ type: 'closeCreateTicketModal' });
  };

  //PRINT TABLE
  const tasksPrintRef = useRef<HTMLTableElement>(null);
  const handlePrint = useReactToPrint({
    content: () => tasksPrintRef.current,
    documentTitle: 'Задачи',
    copyStyles: true,
  });

  const successHandler = useTaskSuccessHandler();

  const toggleComments = (id: number) => {
    setData((prevState: ITaskForList[]) => {
      return prevState.map((task: ITaskForList) => {
        if (task.internal_task_pk !== id) {
          return task;
        } else {
          return !task.openComments
            ? { ...task, openComments: true }
            : { ...task, openComments: false };
        }
      });
    });
  };

  const keyMyFilter = useGetKeyForMyFilter({
    billingAccountPk,
    counterpartyPk,
    filterType: FilterType.task,
  });

  return (
    <MainLayout isInWrapper={isInsidePage}>
      <Subheader viewBackLocalPatch={!isInsidePage}>
        <div className="d-flex mr-5">
          <SubheaderButton>
            <MyFilters
              saveFilters={saveFilters}
              setFilters={setParams}
              currentFilter={params}
              keyMyFilter={keyMyFilter}
            />
          </SubheaderButton>
          <SubheaderButton
            onClick={clearFilters}
            bordered={true}
            disabled={isDisableClearButton}
          >
            {t('Сбросить фильтры')}
          </SubheaderButton>
          <RefreshButton toggleRefresh={refresh} isLoading={isLoading} />
          <SubHeaderButtonExport
            params={params}
            getExportLinkApi={exportTaskApiV2}
            disabled={isLoading}
            convertParams={composeParamsForGetTasks}
            haveAccessToExport={haveAccessToExport}
          />
          <SubheaderButton
            onClick={handlePrint}
            hintText={t('Распечатать')}
            hintTarget={t('handlePrint')}
            bordered={false}
          >
            <PrintIcon />
          </SubheaderButton>
        </div>
        {haveAccessToCreate && (
          <SubheaderButton
            onClick={() => openCreateTicketModal()}
            bordered={false}
          >
            <CreateIcon className="mr-2 text-white" /> {t('Добавить задачу')}
          </SubheaderButton>
        )}
      </Subheader>
      <TableWrapper>
        <thead>
          <tr className={styles.thead}>
            <THCustom
              className={`${styles.thTaskPk}`}
              isVisible={visibility.pk}
            >
              <HeaderWithSearchAndSort
                field="internal_task_pk"
                title={COLUMN_LABELS_TASK.PK}
                onSort={onSortClick}
                onSearch={handlePkTaskSearch}
                defaultValue={taskPk ? taskPk.toString() : ''}
                isOnlyNumbers
              />
            </THCustom>

            <THCustomSelect
              className={styles.thAuthor}
              isVisible={visibility.author}
            >
              <HeaderWithDynamicSelectPaginatedV2
                selectHandler={getForSelectEmployeeApiV2}
                id="internal_task_author_dk_array"
                title={COLUMN_LABELS_TASK.AUTHOR}
                options={authorDkArray}
                isMulti={true}
                onChange={handleAuthorTaskMultiSelect}
                haveReadPermission={employeeHaveAccessToRead}
              />
            </THCustomSelect>

            <THCustomSelect
              className={styles.thSupervisor}
              isVisible={visibility.supervisor}
            >
              <HeaderWithDynamicSelectPaginatedV2
                selectHandler={getForSelectEmployeeApiV2}
                id="internal_task_supervisor_dk_array"
                title={COLUMN_LABELS_TASK.SUPERVISOR}
                options={supervisorDkArray}
                isMulti={true}
                onChange={handleSupervisorTaskMultiSelect}
                haveReadPermission={employeeHaveAccessToRead}
              />
            </THCustomSelect>

            {!isDisabledDoubleFilterCounterpartyAndBillingAccoount && (
              <THCustomSelect
                isVisible={visibility.accountAndCounterparty}
                className={styles.thAccount}
              >
                <DoubleHeaderFilterContainer
                  isFirstIsVisibleProp={
                    isActiveCounterpartyFilter || !!counterpartyPk
                  }
                  saveIsActive={callSetIsActiveCounterpartyFilter}
                  componentSettings={{
                    first: {
                      value: counterpartyDkArray,
                      hint: hintForCounterpartyFilter,
                      name: 'filterCounterparty',
                    },
                    second: {
                      value: billingAccountDkArray,
                      hint: hintForBillingAccountFilter,
                      name: 'filterBillingAccount',
                    },
                  }}
                  isDisabled={
                    isDisabledDoubleFilterCounterpartyAndBillingAccoount
                  }
                >
                  <HeaderWithDynamicSelectPaginatedV2
                    selectHandler={getForSelectCounterpartyApiV2}
                    id="internal_task_counterparty_fk_array"
                    title={COLUMN_LABELS_TASK.COUNTERPARTY}
                    options={counterpartyDkArray}
                    isMulti={true}
                    onChange={handleCounterpartyTaskMultiSelect}
                    disabled={
                      isDisabledDoubleFilterCounterpartyAndBillingAccoount
                    }
                    haveReadPermission={counterpartyHaveAccessToRead}
                  />
                  <HeaderWithDynamicSelectPaginatedV2
                    selectHandler={getForSelectBillingAccountApiV2}
                    id="internal_task_billing_account_fk_array"
                    title={COLUMN_LABELS_TASK.BILLING_ACCOUNT}
                    options={billingAccountDkArray}
                    isMulti={true}
                    onChange={handleBillingAccountTaskMultiSelect}
                    disabled={
                      isDisabledDoubleFilterCounterpartyAndBillingAccoount
                    }
                    haveReadPermission={billingAccountHaveAccessToRead}
                  />
                </DoubleHeaderFilterContainer>
              </THCustomSelect>
            )}
            <THCustomSelect
              className={styles.thType}
              isVisible={visibility.type}
            >
              <HeaderSelectGroup
                id="internal_task_type_fk_array"
                title={COLUMN_LABELS_TASK.TYPE}
                options={taskTypeOptions}
                isMulti={true}
                onChange={handleTaskTypeGroupSelect}
                className={
                  taskTypeFkArray && taskTypeFkArray.length > 0
                    ? styles['borderBackLight']
                    : ''
                }
                value={taskTypeFkArray}
                key={+isLoading}
                haveReadPermission={taskTypeHaveAccessToRead}
              />
            </THCustomSelect>

            <THCustomSelect
              className={styles.thStatus}
              isVisible={visibility.internal_task_status}
            >
              <HeaderWithDynamicSelectPaginatedV2
                selectHandler={getTaskStatusesForSelectApiV2}
                id="status"
                title={COLUMN_LABELS_TASK.STATUS}
                options={statusFkArray}
                isMulti={true}
                onChange={handleStatusTaskMultiSelect}
                haveReadPermission={taskStatusHaveAccessToRead}
              />
            </THCustomSelect>
            <THCustomSelect
              className={styles.thIsPruned}
              isVisible={visibility.is_pruned}
            >
              <HeaderWithSelectAdvanced
                label={COLUMN_LABELS_TASK.ARCHIVE}
                options={booleanOptions}
                onChange={handleSelectBooleanType}
                defaultValue={isPrunedFilter}
              />
            </THCustomSelect>
            <THCustomDate
              className={styles.thEditedDate}
              isVisible={visibility.editedDate}
            >
              <HeaderWithDatePickerRangeV2
                title={COLUMN_LABELS_TASK.EDITED_DATE_TIME}
                propsOnSearch={{
                  onSearchRequest,
                  keyDate: [
                    'internal_task_datetime_edited_lower_bound',
                    'internal_task_datetime_edited_upper_bound',
                  ],
                }}
                startDate={
                  datetimeEditedLower ? new Date(datetimeEditedLower) : null
                }
                endDate={
                  datetimeEditedUpper ? new Date(datetimeEditedUpper) : null
                }
                field="internal_task_datetime_edited"
                onSort={onSortClick}
                hasTime
              />
            </THCustomDate>
            <THCustomDate
              className={styles.thCreatedDate}
              isVisible={visibility.createDate}
            >
              <HeaderWithDatePickerRangeV2
                title={COLUMN_LABELS_TASK.CREATE_DATE}
                propsOnSearch={{
                  onSearchRequest,
                  keyDate: [
                    'internal_task_datetime_added_lower_bound',
                    'internal_task_datetime_added_upper_bound',
                  ],
                }}
                startDate={
                  datetimeAddedLower ? new Date(datetimeAddedLower) : null
                }
                endDate={
                  datetimeAddedUpper ? new Date(datetimeAddedUpper) : null
                }
                field="internal_task_datetime_added"
                onSort={onSortClick}
                hasTime
              />
            </THCustomDate>

            <THCustomDate
              className={styles.thDuedate}
              isVisible={visibility.duedate}
            >
              <HeaderWithDatePickerRangeV2
                title={COLUMN_LABELS_TASK.DUEDATE}
                propsOnSearch={{
                  onSearchRequest,
                  keyDate: [
                    'internal_task_date_duedate_lower_bound',
                    'internal_task_date_duedate_upper_bound',
                  ],
                }}
                startDate={duedateLower ? new Date(duedateLower) : null}
                endDate={duedateUpper ? new Date(duedateUpper) : null}
                field="internal_task_date_duedate"
                onSort={onSortClick}
              />
            </THCustomDate>

            <THCustom>
              <HeaderWithSearch
                title={COLUMN_LABELS_TASK.DESCRIPTION}
                onSearch={handleDescriptionTaskSearch}
                defaultValue={description}
              />
            </THCustom>
            <THCustomUnruled
              className={`${styles.thParentTask}`}
              isVisible={visibility.parentTask}
            >
              {COLUMN_LABELS_TASK.PARENT_TASK}
            </THCustomUnruled>
            <THCustomUnruled
              className={`${styles.thExternalRequest} p-2`}
              isVisible={visibility.parentExternalRequest}
            >
              {COLUMN_LABELS_TASK.PARENT_EXTERNAL_REQUEST}
            </THCustomUnruled>
            <THCustomUnruled className="p-2" isVisible={visibility.comment}>
              {COLUMN_LABELS_TASK.COMMENT}
            </THCustomUnruled>
            <th scope="col" className="actions p-0">
              <ColumnFilter onApply={applyVisibilityChanges}>
                {taskFilters.map(({ name, label }) => (
                  <ColumnFilterItem
                    key={name}
                    name={name}
                    label={label}
                    defaultChecked={visibility[name]}
                    onChange={onFilterItemToggle}
                  />
                ))}
              </ColumnFilter>
            </th>
          </tr>
        </thead>
        {!isLoading && (
          <tbody>
            <TasksList
              taskList={tasks}
              visibility={visibility}
              toggleComments={toggleComments}
              refresh={refreshListData}
              isLoading={isLoading}
              isDisabledDoubleFilterCounterpartyAndBillingAccoount={
                isDisabledDoubleFilterCounterpartyAndBillingAccoount
              }
            />
          </tbody>
        )}
      </TableWrapper>
      {!isLoading && (
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
          saveFilters={saveFilters}
        />
      )}
      {isLoading && <LoaderFetch />}
      <TasksPrint
        visibility={visibility}
        ref={tasksPrintRef}
        tasks={tasks}
        className={classNames(styles['print-source'])}
      />

      <ModalWrapper
        headerLabel={'Создать задачу'}
        isOpen={state.isCreateModalOpen}
        closeHandler={closeCreateTicketModal}
      >
        <TaskForm
          successHandler={(isSaveAndExit: boolean) => {
            dispatch({ type: 'closeCreateTicketModal' });
            successHandler(isSaveAndExit);
            isSaveAndExit && refreshListData();
          }}
          defaultStatus={defaultStatus}
          viewOnly={false}
          counterpartyPk={counterpartyPk}
          billingAccountId={billingAccountPk}
        />
      </ModalWrapper>
    </MainLayout>
  );
};

TaskPage.whyDidYouRender = false;

const reducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case 'openCreateTicketModal':
      return {
        ...state,
        isCreateModalOpen: true,
      };
    case 'closeCreateTicketModal':
      return {
        ...state,
        isCreateModalOpen: false,
      };
    case 'setTaskPk':
      return { ...state, ticketId: action.taskId };
    default:
      throw new Error('Action is undefined.');
  }
};

export default TaskPage;
