import { MutableRefObject, useEffect, useRef } from 'react';

function useGetPrevValue<Props>(
  value: Props,
  isRegisterChanges: boolean = true
) {
  const prevValue: MutableRefObject<undefined | Props> = useRef();

  useEffect(() => {
    if (isRegisterChanges) {
      prevValue.current = value;
    }
  }, [isRegisterChanges, value]);
  return prevValue.current;
}

export default useGetPrevValue;
