import { useColumnFilter } from 'hooks/useColumnFilter';
import React, { FC, useEffect, useReducer, useMemo, useCallback } from 'react';
import { t } from 'services/utils/translation';
import { IColumnVisibility } from '../../../hooks/useColumnFilter';

import styles from '../index.module.scss';
import { Collapse, Table } from 'reactstrap';
import { ColumnFilter } from 'components/tables/ColumnFilter';
import { ColumnFilterItem } from '../../../components/tables/ColumnFilter';
import classNames from 'clsx';
import { ActionsDropdown } from 'components/tables/ActionsDropdown';
import { ActionsDropdownItem } from '../../../components/tables/ActionsDropdown';
import { ReactComponent as AddIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as CreateIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';

import { ReactComponent as DownLoadAllIcon } from 'img/icons/icon-downoload.svg';

import config from '../../../config.json';
import listTransition from '../../../styles/animaion/listTransition.module.scss';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import ButtonLink from '../../../components/controls/ButtonLink/ButtonLink';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import FilePreview from '../../../components/documents/FilePreview/FilePreview';
import FileBucketForm from '../../../components/documents/FileBucketForm/FileBucketForm';

import { IFileBucket } from '../../../services/api/documents/file-bucket/IGetResponseFileBuckets';

import { parse, parseISO } from 'date-fns';

import LoaderFetch from '../../../components/layouts/LoaderFetch/LoaderFetch';
import { IFiltersDocuments } from 'services/api/documents/file-bucket/IFiltersDocuments';
import HeaderWithSearch from 'components/tables/HeaderWithSearch';
import HeaderWithDatePicker from 'components/tables/HeaderWithDatePicker';
import {
  getIcons,
  getTargetIcon,
} from 'pages/documents/utils/checkingForFileType/checkingForFileType';
import { CSSTransition } from 'react-transition-group';
import iconFadeAnimation from '../iconFade.module.scss';
import { IUseFetchV2, useGetListOld } from '../../../hooks/useGetListOld';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';
import SubHeaderWrapper from 'components/tables/SubHeaderGreyWrapper/SubHeaderGreyWrapper';
import WrapperGrayTable from 'components/tables/WrapperGrayTable/WrapperGrayTable';
import { parentUIElement } from 'services/constants/parentUIElement';
import {
  formatDateForServerV2,
  formatDateTime,
  isValidDate,
  sliceTimestamp,
} from '../../../services/utils/dateHelper/dateHelper';
import {
  deleteFileBucketApi,
  getFileBuckets,
  getFileLink,
} from 'services/api/documents/file-bucket/fileBucketApi';
import useModal, { IModal } from 'hooks/useModal';
import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';

const MODAL_DELETE_TITLE = t('Удалить документ');
const MODAL_EDIT_TITLE = t('Редактировать документ');
const MODAL_CREATE_TITLE = t('Создать документ');

const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);
const COLUMN_LABEL = {
  PK: t('№'),
  NAME: t('Название'),
  DATE_CREATE: t('Создан'),
  DOCUMENTS: t('Файлы'),
};

const filters = [
  { name: 'pk', label: COLUMN_LABEL.PK },
  { name: 'name', label: COLUMN_LABEL.NAME },
  { name: 'date_create', label: COLUMN_LABEL.DATE_CREATE },
  { name: 'documents', label: COLUMN_LABEL.DOCUMENTS },
];

const onDownloadAll = (fileNames: string[]) => {
  const delayMs = 200;
  const urls = fileNames.map((fileName: string) => getFileLink(fileName));
  urls.forEach((url: string, i: number) => {
    setTimeout(() => (window.location.href = url), delayMs + i * delayMs);
  });
};

const columnVisibilityInitial: IColumnVisibility = {
  pk: true,
  name: true,
  date_create: true,
  documents: true,
};

export interface IFileBucketWithFieldOpen extends IFileBucket {
  open: boolean;
}

type TStateDocumentPage = {
  isPreviewModalOpen: boolean;
  fileBucket: IFileBucketWithFieldOpen | null;
  fileName: string | null;
};

const initialState: TStateDocumentPage = {
  isPreviewModalOpen: false,
  fileBucket: null,
  fileName: null,
};

type TActionDocumentPage =
  | {
      type: 'openPreviewModal';
      fileBucket: IFileBucketWithFieldOpen;
      fileName: string | null;
    }
  | { type: 'closePreviewModal' };

type PropsType = {
  parentUIName: parentUIElement;
  parentUIPk: string | number;
  refreshTabs?: () => void;
  viewOnly?: boolean;
};

const DocumentsPage: FC<PropsType> = (props) => {
  const { parentUIName, parentUIPk, refreshTabs, viewOnly } = props;

  const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState);

  const initialParamsDocument: IFiltersDocuments = useMemo(
    () => ({
      parent_ui_element: { [parentUIName]: parentUIPk },
      skip: 0,
      length: config.pageLength,
    }),
    [parentUIName, parentUIPk]
  );

  const {
    data: fileBuckets,
    isLoading,
    onSearchRequest,
    setStart,
    setLength,
    total,
    params: { skip, length },
    params,
    setData,
    doRefresh,
  }: IUseFetchV2<IFileBucketWithFieldOpen[], IFiltersDocuments> = useGetListOld(
    {
      getDataApi: getFileBuckets,
      initialParams: initialParamsDocument,
      convertData: (bucket: IFileBucket[]) =>
        bucket.map((bucket: IFileBucket) => ({
          ...bucket,
          open: false,
        })),
    }
  );
  const refreshData = () => {
    doRefresh();
    refreshTabs && refreshTabs();
  };

  //обновлять список документов при смене заявку в url
  useEffect(() => {
    onSearchRequest({ [parentUIName]: parentUIPk }, 'parent_ui_element');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentUIName, parentUIPk]);

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(
      columnVisibilityInitial,
      `documentColumnFilter${parentUIName}`
    );

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetDocument },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<IFileBucketWithFieldOpen> = useModal({
    setRefresh: refreshData,
    deleteRequest: deleteFileBucketApi,
    entityIdKey: 'pk',
  });

  //files list

  const toggleListDocumentsOpen = useCallback(
    (id: number) =>
      setData((prevEntities: IFileBucketWithFieldOpen[]) => {
        prevEntities = prevEntities.map((el: IFileBucketWithFieldOpen) =>
          el.pk === id ? { ...el, open: !el.open } : el
        );
        return prevEntities;
      }),
    [setData]
  );

  //Preview
  const toggleOpenPreview = async (
    fileBucket?: IFileBucketWithFieldOpen,
    fileName?: string
  ) => {
    if (fileBucket && fileName) {
      dispatch({ type: 'openPreviewModal', fileBucket, fileName });
    }
  };

  const toggleClosePreview = () => {
    dispatch({ type: 'closePreviewModal' });
  };
  return (
    <>
      <SubHeaderWrapper mainTitle={t('Документы')}>
        {viewOnly !== true && (
          <SubheaderButton
            onClick={openAddEditForm.bind(this, undefined)}
            bordered={false}
          >
            <CreateIcon
              className={classNames(styles.svgIcon, 'mr-2')}
              style={{ lineHeight: 0.9 }}
            />
            <span className={classNames(styles.svgIcon)}>
              {t('Добавить документ')}
            </span>
          </SubheaderButton>
        )}
      </SubHeaderWrapper>
      <WrapperGrayTable
        pagerProps={{
          onPageChange: setStart,
          onLengthChange: setLength,
          total: total,
          length,
          start: skip,
        }}
      >
        <Table
          hover
          className={classNames(
            styles.myTable,
            'table table-bordered table-hover table-responsive-md table-responsive-none'
          )}
        >
          <tr className={styles.tableHeader}>
            <th
              className={classNames(styles.thPk, {
                'd-none': !visibility.pk,
              })}
            >
              <HeaderWithSearch
                title={COLUMN_LABEL.PK}
                onSearch={(searchString: string) =>
                  onSearchRequest(searchString, 'pk')
                }
                defaultValue={params?.pk?.toString()}
                headerColor={TABLE_COLOR.GREY}
                isOnlyNumbers
              />
            </th>
            <th
              className={classNames('p-2', styles.thName, {
                'd-none': !visibility.name,
              })}
            >
              <HeaderWithSearch
                title={COLUMN_LABEL.NAME}
                onSearch={(searchString: string) =>
                  onSearchRequest(searchString, 'file_bucket_name')
                }
                defaultValue={params?.file_bucket_name}
                headerColor={TABLE_COLOR.GREY}
              />
            </th>
            <th
              className={classNames(styles.thDateCreate, 'p-2', {
                'd-none': !visibility.date_create,
              })}
            >
              <HeaderWithDatePicker
                title={
                  params.time_added
                    ? params.time_added
                    : COLUMN_LABEL.DATE_CREATE
                }
                value={
                  params.time_added
                    ? parse(
                        params.time_added,
                        config.serverDateFormat,
                        new Date()
                      )
                    : null
                }
                onSearch={(date: Date) => {
                  if (!date || !isValidDate(date)) {
                    onSearchRequest('', 'time_added');

                    return;
                  }
                  const formattedDate = formatDateForServerV2(date);
                  onSearchRequest(formattedDate, 'time_added');
                }}
                headerColor={TABLE_COLOR.GREY}
              />
            </th>

            <th
              className={classNames(styles.thFiles, 'p-2', {
                'd-none': !visibility.documents,
              })}
            >
              {COLUMN_LABEL.DOCUMENTS}
            </th>

            <th className={classNames(styles['fixed-width'], 'actions p-0')}>
              <ColumnFilter onApply={applyVisibilityChanges}>
                {filters &&
                  filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
              </ColumnFilter>
            </th>
          </tr>

          {!isLoading && (
            <tbody>
              {fileBuckets &&
                fileBuckets.map((fileBucket: IFileBucketWithFieldOpen) => (
                  <tr
                    key={fileBucket.pk}
                    data-toggle="collapse"
                    data-target=".multi-collapse1"
                    aria-controls="multiCollapseExample1"
                  >
                    <td className={classNames({ 'd-none': !visibility.pk })}>
                      {fileBucket.pk}
                    </td>
                    <td
                      className={classNames({
                        'd-none': !visibility.name,
                      })}
                    >
                      {fileBucket.file_bucket_name}
                    </td>
                    <td
                      className={classNames({
                        'd-none': !visibility.date_create,
                      })}
                    >
                      {formatDateTime(parseISO(fileBucket.time_added))}
                    </td>
                    <td
                      className={classNames({
                        'd-none': !visibility.documents,
                      })}
                    >
                      <div className={classNames(styles.tableIconsWrapper)}>
                        <CSSTransition
                          in={!fileBucket.open}
                          timeout={300}
                          classNames={iconFadeAnimation}
                          unmountOnExit
                          appear
                        >
                          <div style={{ display: 'flex' }}>
                            {getIcons(
                              fileBucket.list_of_uploaded_file_names
                            ).map((icon: any, i: number) => (
                              <div key={i} style={{ marginRight: '5px' }}>
                                {icon}
                              </div>
                            ))}
                          </div>
                        </CSSTransition>

                        <ButtonLink
                          text={'Смотреть все'}
                          handleClick={toggleListDocumentsOpen.bind(
                            this,
                            fileBucket.pk
                          )}
                          classNameCustom={styles.buttonViewFiles}
                        />
                      </div>
                      <Collapse isOpen={fileBucket.open}>
                        <CSSTransition
                          in={fileBucket.open}
                          timeout={600}
                          classNames={listTransition}
                          unmountOnExit
                          appear
                        >
                          <ul>
                            {fileBucket.list_of_uploaded_file_names?.map(
                              (fileName: any, i: number) => (
                                <li key={fileName + i}>
                                  <div className={styles.iconContainer}>
                                    {<div>{getTargetIcon(fileName)}</div>}
                                    <ButtonLink
                                      text={sliceTimestamp(fileName)}
                                      handleClick={async () => {
                                        await toggleOpenPreview(
                                          fileBucket,
                                          fileName
                                        );
                                      }}
                                    />
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </CSSTransition>
                      </Collapse>
                    </td>
                    <td align="center">
                      <ActionsDropdown>
                        {viewOnly !== true && (
                          <ActionsDropdownItem
                            label={t('Редактировать')}
                            onClick={openAddEditForm.bind(this, fileBucket)}
                            icon={<AddIcon className="text-primary" />}
                          />
                        )}
                        {viewOnly !== true && (
                          <ActionsDropdownItem
                            label={t('Удалить')}
                            onClick={() => {
                              toggleDeleteForm(fileBucket);
                            }}
                            icon={<DeleteIcon className="text-primary" />}
                          />
                        )}

                        {viewOnly !== true && (
                          <ActionsDropdownItem
                            label={t('Скачать все')}
                            onClick={onDownloadAll.bind(
                              null,
                              fileBucket.list_of_uploaded_file_names
                            )}
                            icon={
                              <DownLoadAllIcon
                                className="text-primary  "
                                style={{ width: '18px', height: '18px' }}
                              />
                            }
                          />
                        )}
                      </ActionsDropdown>
                    </td>
                  </tr>
                ))}
              {fileBuckets.length === 0 && (
                <tr>
                  <td colSpan={5}>{t('Документы отсутствуют')}</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </WrapperGrayTable>

      {isLoading && <LoaderFetch />}
      {targetDocument?.pk && (
        <PopupDeleteForm
          headerLabel={MODAL_DELETE_TITLE}
          entityId={targetDocument?.pk}
          entityName={targetDocument?.file_bucket_name}
          deleteHandler={entityDeleteHandler}
          isOpen={isDeleteFormOpen}
          toggleHandler={toggleDeleteForm}
        />
      )}

      <ModalWrapper
        headerLabel={getModalTitle(!!targetDocument?.pk)}
        isOpen={isAddEditFormOpen}
        closeHandler={closeAddEditForm}
      >
        <FileBucketForm
          parent_ui_element={{ [parentUIName]: parentUIPk }}
          toggleCloseCreateModal={successHandler}
          pk={targetDocument?.pk}
        />
      </ModalWrapper>

      {state.fileBucket && state.fileName && (
        <ModalWrapper
          headerLabel={state.fileName && sliceTimestamp(state.fileName)}
          isOpen={state.isPreviewModalOpen}
          closeHandler={toggleClosePreview}
          size="lg"
        >
          <FilePreview
            fileBucket={state.fileBucket}
            fileName={state.fileName}
          />
        </ModalWrapper>
      )}
    </>
  );
};

const reducer = (state: TStateDocumentPage, action: TActionDocumentPage) => {
  switch (action.type) {
    case 'openPreviewModal':
      return {
        ...state,
        isPreviewModalOpen: true,
        fileBucket: action.fileBucket,
        fileName: action.fileName,
      };
    case 'closePreviewModal':
      return {
        ...state,
        isPreviewModalOpen: false,
        fileBucket: null,
      };
    default:
      throw new Error('Action is undefined.');
  }
};

export default DocumentsPage;
