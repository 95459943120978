import { parseISO } from 'date-fns';
import { IEvent } from 'services/api/organizationsV2/event/IEvent';
import { formatDateTimeShort } from 'services/utils/dateHelper/dateHelper';
import { IEventForList } from '../../IEventForList';

export const toEventForList = (event: IEvent): IEventForList => {
  const {
    event_name,
    event_datetime_added: dateAdded,
    event_datetime_edited: editedDate,
  } = event;
  return {
    ...event,
    event_name: event_name ? event_name : '', //TODO: remove conditional expression if event_name is required when creating
    createDate: formatDateTimeShort(parseISO(dateAdded)),
    editedDate: formatDateTimeShort(parseISO(editedDate)),
  };
};

export const toEventsForList = (events: IEvent[]): IEventForList[] =>
  events.map(toEventForList);
