import { t } from '../translation';
import { makeFirstLetterUppercase } from '../stringHelper/stringHelper';

const CREATE_TEXT = t('Создать');

export class FormTitle {
  nameEntity: string;
  constructor(nameEntity: string) {
    this.nameEntity = nameEntity;
  }
  makeFirstLetterEntityNameUppercase = (nameEntity: string) =>
    makeFirstLetterUppercase(nameEntity);
}
class CreateTitle extends FormTitle {
  ending: string = '';
  constructor(nameEntity: string, ending: string = '') {
    super(nameEntity);
    this.ending = ending;
  }
  private generateTitleEnding = () => `${this.nameEntity + this.ending}`;
  public getCreateTitle = () => `${CREATE_TEXT} ${this.generateTitleEnding()}`;
}

class UpdateTitle extends FormTitle {
  prefix?: string;
  constructor(nameEntity: string, prefix: string = '') {
    super(nameEntity);
    this.prefix = prefix;
  }
  public getUpdateTitle = (updateTitle: string | number) =>
    `${this.makeFirstLetterEntityNameUppercase(this.nameEntity)} ${
      this.prefix
    }${updateTitle + ''}`;
}

interface IPropsGeneratorFormTitle {
  nameEntity: string;
  ending?: string;
  prefix?: string;
  updateTitle?: string | number;
}
export class GeneratorFormTitle {
  nameEntity;
  prefix?: string;
  ending?: string;
  updateTitle?: string | number;
  constructor({
    nameEntity,
    updateTitle,
    prefix,
    ending,
  }: IPropsGeneratorFormTitle) {
    this.nameEntity = nameEntity;
    this.updateTitle = updateTitle;
    this.prefix = prefix;
    this.ending = ending;
  }
  public getFormTitle = (updateTitle?: string | number) =>
    updateTitle
      ? new UpdateTitle(this.nameEntity, this.prefix).getUpdateTitle(
          updateTitle
        )
      : new CreateTitle(this.nameEntity, this.ending).getCreateTitle();
}
