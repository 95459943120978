import classNames from 'clsx';
import DynamicSelectPaginatedV2 from 'components/controls/DynamicSelectPaginatedV2';
import React, { FC, memo } from 'react';
import { ValueType } from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import styles from './index.module.scss';
import { HEADER_SELECT_COLOR } from '../../../hooks/useGetSelectStyle';
import { hasValueInSelect } from 'services/utils/selects/selects';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { IForSelectResponseV2 } from 'components/controls/DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import MultiValueLabelWithTooltip from 'components/misc/MultiValueLabelWithTooltip/MultiValueLabelWithTooltip';

type PropsType = {
  id: string;
  selectHandler: (
    params: IBaseParams<IForSelectRequestV2>
  ) => Promise<IBaseResponse<IForSelectResponseV2>>;
  title: string;
  className?: string;
  options?: IClientSelectOptionV2 | IClientSelectOptionV2[] | null;
  onChange: (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => void;
  isMulti?: boolean;
  headerColor?: HEADER_SELECT_COLOR;
  disabled?: boolean;
  haveReadPermission?: boolean;
};

const HeaderWithDynamicSelectPaginatedV2: FC<PropsType> = (props) => {
  const {
    title,
    className,
    selectHandler,
    id,
    options,
    onChange,
    isMulti = false,
    headerColor = HEADER_SELECT_COLOR.DEFAULT,
    disabled,
    haveReadPermission,
  } = props;

  const hasValue = hasValueInSelect(options);

  return (
    <DynamicSelectPaginatedV2
      className={classNames(className, {
        [styles['container-backlight']]: hasValue,
      })}
      id={id}
      placeholder={title}
      selectHandler={selectHandler}
      isMulti={isMulti}
      options={options}
      onChange={onChange}
      isClearable={true}
      headerColor={headerColor}
      customComponents={{ MultiValue: MultiValueLabelWithTooltip }}
      disabled={disabled}
      haveReadPermission={haveReadPermission}
    />
  );
};

export default memo(HeaderWithDynamicSelectPaginatedV2);
