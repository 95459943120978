import { initialEmployeeFilters } from './initialEmplouyeeFilters';
import { initialBillingAccountsFilters } from './initialBillingAccountsFilters';
import { initialVehicleFilters } from './initialVehicleFilters';
import { initialBookingFilters } from './initialBookingFilters';
import initialNotificationCount from './initialNotificationCount';
import { initialExport } from './initialExport';
import initialEventFilters from './initialEventFilters';
import { initialIdentityInControlPanel } from './initialIdentityInControlPanel';
import { initialCounterpartyFilters } from './initialCounterpartyFilters';
import { initialExternalRequestStore } from 'store/externalRequest/initial';
import { initialTaskState } from 'store/task/initialTask';
import initialControllerState from 'store/controller/initial/initialControllerState';

export const initialState = {
  auth: {
    loggedIn: false,
    user: {},
    userPermissions: [],
    activeInstallation: {
      installation: '',
      installationClientName: 'Главная',
      installationPk: null,
      accessToken: '',
    },

    identity: {},
  },
  general: {
    localization: {},
    currentEmployee: {},
    menu: [],
    myFavoriteMenuItems: [],
    isIgnoreStartingPathWasVisited: false,
    errors: null,
  },

  tasks: initialTaskState,
  externalRequest: initialExternalRequestStore,
  employee: {
    tableFilters: initialEmployeeFilters,
  },
  billingAccounts: {
    tableFilters: initialBillingAccountsFilters,
    taskTableFilters: undefined,
  },
  vehicle: { tableFilters: initialVehicleFilters },
  booking: { tableFilters: initialBookingFilters },
  controller: initialControllerState,
  notificationsCount: initialNotificationCount,
  export: initialExport,
  event: { tableFilters: initialEventFilters },
  form: {
    hasUnsavedValues: false,
    diffValues: [],
  },
  identityInControlPanel: initialIdentityInControlPanel,
  counterparty: { tableFilters: initialCounterpartyFilters },
} as const;
