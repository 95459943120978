import React, { FC, memo } from 'react';

import { parseISO } from 'date-fns';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import HistoryRows from 'components/misc/WrapperHistoryRows/HistoryRows';
import HistoryContainerTable from 'components/tables/HistoryContaierTable/HistoryContainerTable';
import { formatDateTime } from '../../../../services/utils/dateHelper/dateHelper';
import {
  IControllerOfHistoryEntity,
  ICreationControllerInfo,
} from 'services/api/controller/IControllerHistory';

type PropTypes = {
  controllerRequestHistory?: IControllerOfHistoryEntity[];
  creationControllerInfo?: ICreationControllerInfo;
  isLoading?: boolean;
};

// delete duplicate - create component container for history
const ControllerHistory: FC<PropTypes> = (props) => {
  const { controllerRequestHistory, isLoading, creationControllerInfo } = props;
  return (
    <HistoryContainerTable>
      {isLoading && <LoaderFetch />}
      {controllerRequestHistory &&
        controllerRequestHistory?.length > 0 &&
        !isLoading &&
        controllerRequestHistory.map((record: IControllerOfHistoryEntity) => {
          return (
            !!record?.controller_edited_column_to_old_and_new_values && (
              <HistoryRows
                editorFullName={record?.controller_editor?.full_name}
                valuesRaw={
                  record.controller_edited_column_to_old_and_new_values
                }
                dateTimeEdit={record?.controller_datetime_edited}
              />
            )
          );
        })}
      {creationControllerInfo?.controller_datetime_added &&
        creationControllerInfo?.controller_author_dk && (
          <tr>
            <td>
              {formatDateTime(
                parseISO(creationControllerInfo?.controller_datetime_added)
              )}
            </td>
            <td colSpan={4}>{'Cоздание'}</td>
            <td>{creationControllerInfo?.controller_author?.full_name}</td>
          </tr>
        )}
    </HistoryContainerTable>
  );
};

export default memo(ControllerHistory);
