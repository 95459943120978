/**
 * Is aimed to translate a string using current locale.
 * For now, it's just a placeholder which helps find all translatable strings in the project.
 *
 * @param str string
 * @returns string
 */
export function t<T extends string = string>(str: T): T {
  return str;
}
