import {
  IAccessPolicyStatementCrunch,
  TCRUDA,
} from 'services/api/staff/access-policy/IAccessPolicy';
import ITargetAccessPolicy from 'services/interfaces/ITargetAccessPolicy';

const TCRUDArrayInBoolArray = (
  listActions: TCRUDA[],
  targetListActions: TCRUDA[]
) => targetListActions.map((action: TCRUDA) => listActions.includes(action));

const haveAccessPolicy = (
  accessPolicyStatement: IAccessPolicyStatementCrunch,
  { serviceKey, modelKey }: ITargetAccessPolicy,
  targetActions: TCRUDA[]
): boolean[] => {
  const targetActionsCRUDModel: TCRUDA[] | undefined =
    accessPolicyStatement?.[serviceKey]?.[modelKey];

  if (targetActionsCRUDModel === undefined) {
    return [];
  } else {
    return TCRUDArrayInBoolArray(targetActionsCRUDModel, targetActions);
  }
};

export default haveAccessPolicy;
