import { useCallback } from 'react';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import createTimestamp from 'services/utils/createTimestamp/createTimestamp';
import { trackPromise } from 'react-promise-tracker';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from '../services/utils/catchAndRegError/catchAndRegError';

interface IUseGetFetchProps<Payload, Params> {
  getDataApi: (params: IBaseParams<Params>) => Promise<IBaseResponse<Payload>>;
  params: Params;
  errorMessage?: string;
  successHandler?: () => void;
  warningHandler?: Function;
}

function useGetFetch<Payload, Params>(
  props: IUseGetFetchProps<Payload, Params>
) {
  const {
    params,
    getDataApi,
    errorMessage = 'Не удалось загрузить данные',
    successHandler,
    warningHandler,
  } = props;

  return useCallback(
    async (paramsEntity?: Params) => {
      try {
        const response: IBaseResponse<Payload> = await trackPromise(
          getDataApi({
            id: createTimestamp(),
            params: paramsEntity ?? params,
          })
        );
        const {
          result: { verbose, payload, message, outcome },
        } = response;

        if (outcome === 'WARNING') {
          warningHandler && warningHandler();
          catchErrorsIfServerStatusFalse(
            verbose ? verbose : errorMessage,
            message
          );
          return;
        }
        successHandler && successHandler();
        return payload;
      } catch (e) {
        catchErrorFromFetch(errorMessage, e);
        return;
      }
    },
    [errorMessage, getDataApi, params, successHandler, warningHandler]
  );
}

export default useGetFetch;
