import setFormDataStepper from 'hooks/utils/setFormData';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useOnStep = <Params,>(activeStep: number) => {
  const navigate = useNavigate();
  const location = useLocation();
    return useCallback(
      (values: Params) => {
          setFormDataStepper(values);
          navigate(location.pathname, {
              state: {
                  activeStep,
              },
          });
      },
      [activeStep, location.pathname, navigate]
  );
};

export default useOnStep;
