import { useDispatch } from 'react-redux';
import useMyListOfFavoriteFolders from './useFavoriteFolders';
import { useEffect } from 'react';
import { setFavoritesMyFolder } from 'store/general/actions';

const useLoadMyFavoriteListFolder = () => {
  const [myFavoriteFolderListFromLocalStorage] = useMyListOfFavoriteFolders();
  // const myFavoriteListFolderFromStore = useSelector(getMenuFavoritesFromStore);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFavoritesMyFolder(myFavoriteFolderListFromLocalStorage));
  }, [dispatch, myFavoriteFolderListFromLocalStorage]);
};

export default useLoadMyFavoriteListFolder;
