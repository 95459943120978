import { ReactComponent as CloseIcon } from 'img/icons/icon-cancel.svg';
import React, { FC, MouseEventHandler } from 'react';
import styles from './index.module.scss';

type TypeProps = {
  onClick: MouseEventHandler
}

const CloseButton: FC<TypeProps> = ({onClick}) => 
  <button className={`p-2 ${styles.close}`} onClick={onClick}>
    <CloseIcon />
  </button>;

export default CloseButton;
