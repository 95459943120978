import useRead from './useRead';
import { useMemo } from 'react';
import { ITaskType } from '../services/api/tasks/task-typeV2/IGetTaskTypesPayload';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import { getPaginatedTaskTypeApiV2 } from 'services/api/tasks/task-typeV2/task-typeV2';
import { taskTypesToGroupOptions } from 'services/utils/convertToGroupOptions/convertToGroupOptions';

export const useGetTaskTypesOptions = (taskTypeHaveAccessToRead: boolean) => {
  const initialTaskTypeParams = useMemo(() => ({ length: 0, skip: 0 }), []);

  const { data: taskTypeOptions, isLoading } = useRead({
    getDataApi: getPaginatedTaskTypeApiV2,
    params: initialTaskTypeParams,
    convertData: (payload: IBaseGetPaginatedPayload<ITaskType>) =>
      taskTypesToGroupOptions(payload.list_of_entities),
    initialData: [],
    condition: taskTypeHaveAccessToRead,
  });
  return { taskTypeOptions, isLoading };
};
