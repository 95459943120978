import { CODE_SIZES } from 'config';

type TCodeSizeMap = {
  [x in CODE_SIZES]: (currentSize: number) => boolean;
};
export const extractHandlerClientSize = (
  codeSize: CODE_SIZES
): ((currentSize: number) => boolean) => {

  const CODE_SIZES_MAP: TCodeSizeMap = {
    xs: (currentSize: number) => currentSize < 768,
    sm: (currentSize: number) => currentSize >= 768,
    md: (currentSize: number) => currentSize >= 992,
    lg: (currentSize: number) => currentSize >= 1200,
  } ;
  return CODE_SIZES_MAP[codeSize];
};
