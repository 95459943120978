import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers/rootReducer';
import { initialState } from './initialStore/InitialState';

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   // Optionally pass options
// });

const processEnv: string | undefined = process.env.NODE_ENV;

const middleware = processEnv === 'development' ? [thunk, logger] : [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
