import { IFileBucket } from 'services/api/documents/file-bucket/IGetResponseFileBuckets';

export class AttachmentDocumentsService {
  public getNumberOfDocuments(documents?: IFileBucket[]): number | null {
    if (documents === undefined) {
      return null;
    } else {
      return this.getNumberOfAllFiles(documents);
    }
  }
  protected getNumberOfAllFiles = (documents: IFileBucket[]) => {
    return documents.reduce((acc, cur) => {
      return acc + cur.list_of_uploaded_file_names.length;
    }, 0);
  };
}
