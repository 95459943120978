import { GeneratorFormTitle } from '../../utils/GeneratorFormTitle/GeneratorFormTitle';
import { CONTROLLER_TEXT } from '../../localLocalization/servicesAndModels';
import {
  BILLING_ACCOUNT_TEXT,
  COUNTERPARTY_TEXT,
  EMPLOYEE_TEXT,
  ENDING_A,
} from '../../localLocalization/servicesAndModels';

export const formTitleBillingAccount = new GeneratorFormTitle({
  nameEntity: BILLING_ACCOUNT_TEXT,
});
export const formTitleCounterparty = new GeneratorFormTitle({
  nameEntity: COUNTERPARTY_TEXT,
  prefix: '№',
  ending: ENDING_A,
});
export const formTitleEmployee = new GeneratorFormTitle({
  nameEntity: EMPLOYEE_TEXT,
  ending: ENDING_A,
});

export const formTitleController = new GeneratorFormTitle({
  nameEntity: CONTROLLER_TEXT,
});
