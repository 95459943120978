import { Formik } from 'formik';
import BillingAccount from 'models/BillingAccount';
import { FC, memo } from 'react';
import { Button, Form, FormGroup, Row, Col } from 'reactstrap';
import HiddenField from 'components/controls/HiddenField';
import { t } from 'services/utils/translation';
import TextField from 'components/controls/TextField';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';

import {
  createBillingAccountV2,
  updateBillingAccountV2,
} from '../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import { usePostV3 } from 'hooks/usePostV3';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { getForSelectBillingAccountTypeApiV2 } from '../../../../services/api/organizationsV2/billing-account-type/billingAccountTypaApi';
import {
  ICreateBillingAccount,
  IUpdateBillingAccount,
} from '../../../../services/api/organizationsV2/billing-account/ICreateBillingAccountParams';
import DynamicSelectPaginatedAddButton from 'components/controls/DynamicSelectPaginatedAddButtom/DynamicSelectPaginatedAddButtom';
import { useSelector } from 'react-redux';
import BillingAccountTypeForm from '../../billing-account-type/BillingAccountTypeForm';
import { useLocation } from 'react-router-dom';
import { PATH_ORGANIZATIONS_ACCOUNTS } from '../../../../services/pathConstants';
import classNames from 'clsx';
import BillingAccountComments from '../BillingAccountComments/BillingAccountComments';
import { getCurrentEmployeePkFromStore } from 'store/general/selectors';
import { UI_TITLE } from '../../../../services/localLocalization/UITitle';
import DaDataBaseField from 'components/controls/DaDataBaseField/DaDataBaseField';
import { DaDataAddressSuggestion } from 'react-dadata';
import { TypeSuggestion } from '../../../controls/DaDataBaseField/DaDataBaseField';
import { getForSelectCounterpartyApiV2 } from 'services/api/organizationsV2/counterparty/counterpartyApi';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import deleteUnnecessaryParameters from '../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import ButtonClose from 'components/controls/ButtonClose/ButtonClose';
import useBillingAccountToClosed from 'hooks/useBillingAccountToClosed/useBillingAccountToClosed';
import IBillingAccountClosedParams from 'services/api/organizationsV2/billing-account/IBillingAccountClosedParams';
import MakePropertiesRequired from 'services/utils/TSUtils/MakePropertiesRequired';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyCounterparty } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { targetAccessPolicyBillingAccountType } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';

type JunkDataForCreateBillingAccount =
  | 'billing_account_type'
  | 'pk'
  | 'counterparty';

const convertParamsForCreate = (
  values: BillingAccount
): ICreateBillingAccount => {
  const newValues = {
    ...values,
    billing_account_type_fk: values?.billing_account_type?.value,
    billing_account_code_number: values?.billing_account_code_number,
    billing_account_counterparty_fk: values?.counterparty?.value,
  };

  return deleteUnnecessaryParameters(newValues, [
    'billing_account_type',
    'pk',
    'billing_account_editor_dk',
    'counterparty',
  ]) as Omit<ICreateBillingAccount, JunkDataForCreateBillingAccount>;
};
const convertParamsForUpdate = (
  values: BillingAccount
): IUpdateBillingAccount => {
  const newValues = {
    ...values,
    billing_account_type_fk: values?.billing_account_type?.value,
    billing_account_code_number: values?.billing_account_code_number,
    billing_account_counterparty_fk: values?.counterparty?.value,
  };
  return deleteUnnecessaryParameters(newValues, [
    'billing_account_type',
    'counterparty',
  ]) as Omit<IUpdateBillingAccount, 'billing_account_list_of_comments'>;
};

const isViewToggleCloseBillingAccount = (
  params: BillingAccount
): params is MakePropertiesRequired<
  BillingAccount,
  'pk' | 'billing_account_is_closed' | 'billing_account_address'
> => 'pk' in params && 'billing_account_address' in params;

type PropsType = {
  successHandler: () => void;
  pk?: number;
  initialValue: BillingAccount;
  isLoading: boolean;
  isViewOnly: boolean;
  refreshData?: () => void;
  lastBillingAccountCodeNumber?: string;
};

const BillingAccountForm: FC<PropsType> = (props) => {
  const {
    initialValue,
    isLoading,
    pk,
    successHandler,
    isViewOnly,
    refreshData,
    lastBillingAccountCodeNumber,
  } = props;

  const isNew = !pk;
  const isDefaultCounterparty = isNew && !!initialValue?.counterparty;
  const location = useLocation();
  const isModal = !location.pathname.includes(PATH_ORGANIZATIONS_ACCOUNTS);
  const authorDk = useSelector(getCurrentEmployeePkFromStore);

  const [haveAccessPolicyToReadCounterparty] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyCounterparty,
    targetAction: ['R'],
  });

  const [haveAccessPolicyToReadBillingAccountType] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccountType,
    targetAction: ['R'],
  });

  const createBillingAccount = usePostV3({
    fetchApi: createBillingAccountV2,
    successHandler,
    successMessage: t('Лицевой счет создан успешно.'),
    convertParams: convertParamsForCreate,
    viewClientTextToast: true,
  });

  const updateBillingAccount = usePostV3({
    fetchApi: updateBillingAccountV2,
    successHandler,
    successMessage: t('Лицевой счет успешно отредактирован.'),
    convertParams: convertParamsForUpdate,
    viewClientTextToast: true,
  });

  const closeBillingAccountAndRefreshForm = useBillingAccountToClosed();

  const toggleClose = async (params: IBillingAccountClosedParams) => {
    await closeBillingAccountAndRefreshForm(params);
    refreshData && refreshData();
  };

  return (
    <Row className="mr-0">
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={BillingAccount.validationSchema()}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);
          isNew &&
            (await createBillingAccount(
              values as ICreateBillingAccount,
              setErrors,
              resetForm
            ));

          !isNew &&
            values.pk &&
            (await updateBillingAccount(
              values as IUpdateBillingAccount,
              setErrors,
              resetForm
            ));

          setSubmitting(false);
        }}
      >
        {({
          values,
          values: { billing_account_is_closed },
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Col>
            {!isLoading && (
              <Form
                className={classNames({
                  'd-flex flex-column flex-md-row justify-content-md-between ml-4 mr-4 mr-md-auto pl-3':
                    !isModal,
                })}
                onSubmit={handleSubmit}
                noValidate={true}
                style={{ maxWidth: !isModal ? '100%' : '' }}
              >
                <div
                  className={'my-2 my-md-3 py-3 w-100'}
                  style={{
                    maxWidth: !isModal ? '800px' : '',
                  }}
                >
                  {!isNew && (
                    <>
                      <HiddenField
                        id="pk"
                        value={values.pk + ''}
                        hasError={errors.pk != null && touched.pk != null}
                      />
                    </>
                  )}

                  <TextField
                    required={true}
                    type="text"
                    id="billing_account_code_number"
                    label={t('Номер лицевого счета')}
                    hasError={
                      errors.billing_account_code_number != null &&
                      touched.billing_account_code_number != null
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.billing_account_code_number}
                    disabled={isViewOnly}
                    helpText={lastBillingAccountCodeNumber}
                  />
                  <DaDataBaseField
                    id="billing_account_address"
                    inputProps={{
                      onChange: handleChange,
                      onBlur: handleBlur,
                      required: true,
                      disabled: isViewOnly,
                    }}
                    label={t('Aдрес')}
                    onChangeSelect={(suggestion?: DaDataAddressSuggestion) => {
                      suggestion &&
                        setFieldValue(
                          'billing_account_address',
                          suggestion.value
                        );
                    }}
                    typeSuggestion={TypeSuggestion.address}
                    defaultValue={values.billing_account_address}
                    hasError={
                      errors.billing_account_address != null &&
                      touched.billing_account_address != null
                    }
                    disabled={isViewOnly}
                  />
                  <DynamicSelectPaginatedAddButton
                    key="billing_account_type_key"
                    selectProps={{
                      haveReadPermission:
                        haveAccessPolicyToReadBillingAccountType,
                      required: true,
                      id: 'billing_account_type',
                      label: t('Тип'),
                      placeholder: t('Выберите тип лицевого счета...'),
                      hasError:
                        errors.billing_account_type != null &&
                        touched.billing_account_type != null,
                      onChange: (
                        billing_account_type: IClientSelectOptionV2<number>
                      ) => {
                        if (billing_account_type) {
                          setFieldValue(
                            'billing_account_type',
                            billing_account_type
                          );
                        }
                      },
                      options: values?.billing_account_type,
                      selectHandler: getForSelectBillingAccountTypeApiV2,
                      additionalErrorKey: 'billing_account_type',
                      onBlur: handleBlur,
                      disabled: isViewOnly,
                    }}
                    modalProps={{
                      headerLabel: 'Добавить тип лицевого счета',
                      Form: (
                        <BillingAccountTypeForm successHandler={() => false} />
                      ),
                    }}
                  />
                  <DynamicSelectPaginatedFieldV2
                    id="counterparty"
                    label={t('Контрагент')}
                    placeholder={t('Выберите контрагента...')}
                    hasError={
                      errors.counterparty != null &&
                      touched.counterparty != null
                    }
                    selectHandler={getForSelectCounterpartyApiV2}
                    options={values.counterparty}
                    onChange={(jobTitle: IClientSelectOptionV2<number>) => {
                      if (jobTitle) {
                        setFieldValue('jobTitle', jobTitle);
                      }
                    }}
                    disabled={isViewOnly || isDefaultCounterparty}
                    required={true}
                    haveReadPermission={haveAccessPolicyToReadCounterparty}
                  />
                  <TextField
                    type="text"
                    id={'billing_account_1c_number'}
                    label={t('Номер 1С')}
                    placeholder={t('Введите номер 1С')}
                    hasError={
                      errors.billing_account_1c_number != null &&
                      touched.billing_account_1c_number != null
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values?.billing_account_1c_number}
                    disabled={isViewOnly}
                  />
                  <TextField
                    type="text"
                    id={'billing_account_stek_number'}
                    label={t('Номер СТЕК')}
                    placeholder={t('Введите номер СТЕК')}
                    hasError={
                      errors.billing_account_stek_number != null &&
                      touched.billing_account_stek_number != null
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values?.billing_account_stek_number}
                    disabled={isViewOnly}
                  />
                  <TextField
                    type="text"
                    id="billing_account_description"
                    label={t('Описание')}
                    hasError={
                      errors.billing_account_description != null &&
                      touched.billing_account_description != null
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={values.billing_account_description}
                    disabled={isViewOnly}
                  />
                  {!isViewOnly && (
                    <div className="d-flex flex-end align-items-center justify-content-end">
                      {isViewToggleCloseBillingAccount(values) && !isNew && (
                        <div className="mr-4 mt-2">
                          <ButtonClose
                            isClosed={!!billing_account_is_closed}
                            pk={values.pk}
                            handler={async () =>
                              await toggleClose({
                                pk: values.pk,
                                billing_account_address:
                                  values.billing_account_address,
                                billing_account_is_closed:
                                  !values.billing_account_is_closed,
                              })
                            }
                          />
                        </div>
                      )}

                      <FormGroup className="d-block text-right mt-4">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {UI_TITLE.SAVE}
                        </Button>
                      </FormGroup>
                    </div>
                  )}
                </div>
              </Form>
            )}
            {isLoading && <LoaderFetch />}
          </Col>
        )}
      </Formik>
      <div
        className={classNames('ml-md-4', {
          'd-none': !pk,
        })}
      >
        {!!authorDk && pk && (
          <BillingAccountComments
            pk={pk}
            authorDk={authorDk}
            viewOnly={isViewOnly}
          />
        )}
      </div>
    </Row>
  );
};

export default memo(BillingAccountForm);
