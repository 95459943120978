import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import React, { FC, memo } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';
import { usePostV3 } from '../../../../hooks/usePostV3';

import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import Event from 'models/Event';
import {
  createEventApi,
  updateEventApi,
} from 'services/api/organizationsV2/event/eventApi';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { getForSelectEventTypeApiV2 } from 'services/api/organizationsV2/event-type/eventTypeApi';
import { getForSelectBillingAccountApiV2 } from '../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import { preprocessValuesEvent } from '../utils/preprocessValuesEvent/preprocessValuesEvent.';
import classNames from 'clsx';
import { isUpdateEvent } from '../utils/isUpdateEvent/isUpdateEvent';
import BillingAccountDetailsSelect from 'components/controls/BillingAccountDetailsSelect/BillingAccountDetailsSelect';
import useToCloseEvent from '../hooks/useToCloseEventAndRefreshForm';
import ButtonClose from 'components/controls/ButtonClose/ButtonClose';

export interface IFormValuesEvent {
  pk?: number;
  event_name?: string;
  eventType?: IClientSelectOptionV2;
  eventBillingAccount?: IClientSelectOptionV2;
  eventIsClosed?: boolean;
}

type PropsType = {
  successHandler: () => void;
  pk?: number;
  initialValues?: IFormValuesEvent;
  isLoading: boolean;
  isViewOnly: boolean;
  className?: string;
  refreshData?: () => void;
  billingAccountPk?: number;
};

const EventForm: FC<PropsType> = (props) => {
  const {
    successHandler,
    pk,
    initialValues,
    isLoading,
    isViewOnly,
    className,
    refreshData,
    billingAccountPk,
  } = props;

  const isNew = !pk;
  const isDefaultBillingAccount = !!billingAccountPk;

  const newInitialValues =
    initialValues || new Event(undefined, billingAccountPk);

  const updateEvent = usePostV3({
    fetchApi: updateEventApi,
    successHandler,
    successMessage: t('Событие обновлено успешно.'),
  });

  const createEvent = usePostV3({
    fetchApi: createEventApi,
    successHandler,
    successMessage: t('Событие создано успешно.'),
  });

  const closeEventAndRefreshForm = useToCloseEvent();

  const doCloseOrOpen = async (eventIsClosed: boolean, pk: number) => {
    await closeEventAndRefreshForm(eventIsClosed, pk);
    refreshData && refreshData();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={newInitialValues}
      validationSchema={Event.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        const newValues = preprocessValuesEvent(values);
        if (isUpdateEvent(newValues)) {
          await updateEvent(newValues, setErrors, resetForm);
        } else {
          await createEvent(newValues, setErrors, resetForm);
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        values: { pk, eventIsClosed },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {!isLoading && (
            <Form
              onSubmit={handleSubmit}
              noValidate={true}
              className={classNames({
                className,
                'd-flex flex-column flex-md-row justify-content-md-between ml-4  mr-md-auto pl-3':
                  !isNew,
              })}
            >
              <div
                className={classNames('my-2 my-md-3 mr-4 py-3 w-100')}
                style={{
                  maxWidth: !isNew ? '800px' : '',
                }}
              >
                {!isNew && (
                  <HiddenField
                    id="pk"
                    value={values.pk + ''}
                    hasError={errors.pk != null && touched.pk != null}
                  />
                )}
                <TextField
                  type="text"
                  id="event_name"
                  label={t('Событие')}
                  placeholder={t('Введите названия события...')}
                  hasError={
                    errors.event_name != null && touched.event_name != null
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultValue={values.event_name}
                  required={true}
                  disabled={isViewOnly}
                />
                <DynamicSelectPaginatedFieldV2
                  id="eventType"
                  label={t('Тип события')}
                  placeholder={t('Выберите тип события...')}
                  hasError={
                    (errors.eventType != null && touched.eventType != null) ||
                    (errors.eventType != null && touched.eventType != null)
                  }
                  selectHandler={getForSelectEventTypeApiV2}
                  options={values?.eventType}
                  required={true}
                  disabled={isViewOnly}
                />
                <BillingAccountDetailsSelect
                  billingAccountPk={values?.eventBillingAccount?.value}
                >
                  <DynamicSelectPaginatedFieldV2
                    id="eventBillingAccount"
                    label={t('Лицевой счет')}
                    placeholder={t('Выберите лицевой счет...')}
                    hasError={
                      (errors.eventBillingAccount != null &&
                        touched.eventBillingAccount != null) ||
                      (errors.eventBillingAccount != null &&
                        touched.eventBillingAccount != null)
                    }
                    selectHandler={getForSelectBillingAccountApiV2}
                    options={values?.eventBillingAccount}
                    required={true}
                    disabled={isViewOnly || isDefaultBillingAccount}
                  />
                </BillingAccountDetailsSelect>
                <div className="d-flex flex-end align-items-center justify-content-end">
                  {!isViewOnly && pk && eventIsClosed !== undefined && (
                    <div className="mr-4 mt-2">
                      <ButtonClose
                        isClosed={eventIsClosed}
                        pk={pk}
                        handler={doCloseOrOpen}
                      />
                    </div>
                  )}

                  {!isViewOnly && (
                    <FormGroup className="d-block text-right mt-4">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {t('Сохранить')}
                      </Button>
                    </FormGroup>
                  )}
                </div>
              </div>
            </Form>
          )}
          {isLoading && <LoaderFetch />}
        </>
      )}
    </Formik>
  );
};

export default memo(EventForm);
