import {
  CONTROLLER_SAVE_FILTERS,
  CONTROLLER_CLEAR_FILTERS,
  SET_ACCESS_POLICY_CONTROLLER,
  SET_CONTROLLER_FIELD_VALUES,
  CLEAR_CONTROLLER_FIELD_VALUES,
  SET_INITIAL_CONTROLLER_FIELD_VALUES,
} from './actionTypes';

const controllerReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case CONTROLLER_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case CONTROLLER_CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.filters,
      };

    case SET_ACCESS_POLICY_CONTROLLER: {
      return {
        ...state,
        accessPolicy: {
          isInstalled: true,
          accessPolicy: { ...action.payload },
        },
      };
    }

    case SET_CONTROLLER_FIELD_VALUES: {
      const { pk, values } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          fieldValues: { ...state.form?.fieldValues, [pk]: values },
        },
      };
    }
    case CLEAR_CONTROLLER_FIELD_VALUES: {
      const copy = {
        ...state.form,
        initialValues: { ...state.form?.initialValues },
      };
      delete copy.form.fieldValues[action.payload];
      return {
        ...state,
        form: copy,
      };
    }
    case SET_INITIAL_CONTROLLER_FIELD_VALUES: {
      const { pk, values } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          initialValues: { ...state.form?.initialValues, [pk]: values },
        },
      };
    }

    default:
      return state;
  }
};

export default controllerReducer;
