import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from '../interfacesApi/IBaseResponse';
import CentralRegistryApiClient from '../CentralRegistryApiClient';
import IGetIdentitiesParams from './IGetIdentitiesParams';
import IGetIdentitiesPayload from './IGetIdentitiesPayload';
import { IChangePasswordParams } from './IChangePasswordParams';
import IIdentityInstallationsForm from './IIdentityInstallationsForm';
import IIdentityAdminSettingsForm from './IIdentityAdminSettingsForm';
import { ISetViaAdminParams } from './ISetViaAdminParams';
import deleteUnnecessaryParameters from "../../utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters";

export /**
 *Remove identity to installations
 *
 * @return {*}  {Promise<IBaseResponse<IGetIdentitiesPayload>>}
 * @param filterParams
 */
const getIdentityApiV2 = async (
  filterParams: IBaseParams<IGetIdentitiesParams>
): Promise<IBaseResponse<IGetIdentitiesPayload>> => {
  const {
    params: { skip, length },
  } = filterParams;
  const clearParams = deleteUnnecessaryParameters(filterParams, [
    'skip',
    'length',
  ]);
  const api = new CentralRegistryApiClient();
  return api.postFilters(
    `identity/paginated-read/${skip}/${length}`,
    clearParams
  );
};

export /**
 *Change password for identity
 *
 * @param {IBaseParams<IChangePasswordParams>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const changePasswordApiV2 = async (
  bodyParams: IBaseParams<IChangePasswordParams>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm('change-password', bodyParams);
};

export /**
 *Add identity to installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const addIdentityToInstallationsApiV2 = async (
  bodyParams: IBaseParams<IIdentityInstallationsForm>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm('add-identity-to-installations', bodyParams);
};

export /**
 *Remove identity to installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const removeIdentityToInstallationsApiV2 = async (
  bodyParams: IBaseParams<IIdentityInstallationsForm>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm('remove-identity-from-installations', bodyParams);
};

export /**
 *Invite identity to installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const inviteIdentityToInstallationsApiV2 = async (
  bodyParams: IBaseParams<IIdentityInstallationsForm>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm('invite-identity-to-installations', bodyParams);
};

export /**
 *Remove invite identity to installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const removeInviteIdentityToInstallationsApiV2 = async (
  bodyParams: IBaseParams<IIdentityInstallationsForm>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm('remove-identity-from-installations', bodyParams);
};

export /**
 *Give Owner identity to installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const giveOwnerShipOfInstallationsToIdentityApiV2 = async (
  bodyParams: IBaseParams<IIdentityInstallationsForm>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm(
    'give-ownership-of-installations-to-identity',
    bodyParams
  );
};

export /**
 *Rescind invite identity from installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const rescindOwnerShipOfInstallationsFromIdentityApiV2 = async (
  bodyParams: IBaseParams<IIdentityInstallationsForm>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm(
    'rescind-ownership-of-installations-from-identity',
    bodyParams
  );
};

export /**
 *Give Owner identity to installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const grantInvitationShipOfInstallationsToIdentityApiV2 = async (
  bodyParams: IBaseParams<IIdentityInstallationsForm>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm(
    'grant-invitation-capability-to-installations-to-identity',
    bodyParams
  );
};

export /**
 *Rescind invite identity from installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const revokeInvitationShipOfInstallationsFromIdentityApiV2 = async (
  bodyParams: IBaseParams<IIdentityInstallationsForm>
): Promise<IBaseResponse<undefined>> => {
  const api = new CentralRegistryApiClient();
  return api.postForm(
    'revoke-invitation-capability-to-installations-from-identity',
    bodyParams
  );
};

export /**
 *Give Owner identity to installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const promoteToAdmin = async (
  bodyParams: IBaseParams<IIdentityAdminSettingsForm>
): Promise<IBaseResponse<undefined>> => {
  const { id, params } = bodyParams;
  const clearParams = deleteUnnecessaryParameters(params);
  const api = new CentralRegistryApiClient();
  return api.postForm('promote-identity-to-admin', { id, params: clearParams });
};

export /**
 *Rescind invite identity from installations
 *
 * @param {IBaseParams<IIdentityInstallationsForm>} bodyParams
 * @return {*}  {Promise<IBaseResponse<undefined>>}
 */
const demoteFromAdmin = async (
  bodyParams: IBaseParams<IIdentityAdminSettingsForm>
): Promise<IBaseResponse<undefined>> => {
  const { id, params } = bodyParams;
  const clearParams = deleteUnnecessaryParameters(params);
  const api = new CentralRegistryApiClient();

  return api.postForm('demote-identity-from-admin', {
    id,
    params: clearParams,
  });
};

export const setPasswordViaAdmin = (
  bodyParams: IBaseParams<ISetViaAdminParams>
): Promise<IBaseResponse<undefined>> => {
  const { id, params } = bodyParams;
  const clearParams = deleteUnnecessaryParameters(params);
  const api = new CentralRegistryApiClient();
  return api.postForm('set-password-via-admin', {
    id,
    params: clearParams,
  });
};
