import { t } from 'services/utils/translation';
import { MainDescriptionService } from './MainDescriptionService';

export class VehicleDescriptionService extends MainDescriptionService {
  protected CARS_TEXT = t('Транспорт');
  public getVehicleDescription = (
    vehiclesRegistrationPlate: string[] | null
  ) => {
    if (vehiclesRegistrationPlate === null) {
      return this.handleNull(this.CARS_TEXT);
    }
    if (vehiclesRegistrationPlate.length <= 0) {
      return `${this.CARS_TEXT}: ${this.NO_TEXT}`;
    }
    const vehicles = vehiclesRegistrationPlate.reduce(
      (acc, registrationPlate, i) => {
        return `${acc}
${i + 1}) ${registrationPlate}`;
      },
      ''
    );

    return `${this.CARS_TEXT}: ${vehicles}`;
  };
}
