import { useCallback, useEffect, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import config from 'config.json';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from '../services/utils/catchAndRegError/catchAndRegError';
interface IFetchDataProps<ResponseTypes, ParamsTypes, DataTypes> {
  getDataApi: (args: ParamsTypes) => Promise<ResponseTypes>;
  initialParams: ParamsTypes;
  errorMessage?: string; // Error message for the user. Default value: 'Не удалось загрузить данные'
  catchErrors?: boolean; // Catch an error from the server if the response came with status 'false' Default value: 'true'
  initialData?: any; // Default value: []
  convertData?: (args: any) => DataTypes; //  A function that will convert the incoming value
  dataKey?: string; // the name of the key to retrieve the data. Default value: 'rows'
  isRefresh?: boolean;
  requestStatusLoading?: boolean; //Do we need loading state? Default value: 'true'
  condition?: boolean; // condition for request
  delayRequest?: boolean; // delay request
  isOneRequest?: boolean; //if we want only one request
  keyTotal?: string; // key for total entities
}
export interface IUseFetchV2<DataTypes, ParamsTypes> {
  data: DataTypes;
  isLoading: boolean;
  total: number;
  onSearchRequest: Function;
  onSortClick: Function;
  params: ParamsTypes;
  doRefresh: () => void;
  setData: Function;
  setStart: (skip: number) => void;
  setLength: (length: number) => void;
}

/**
 *Processing get requests to receive data from api
 *
 * @export
 * @template ResponseTypes
 * @template ParamsTypes
 * @template DataTypes
 * @param {IFetchDataProps<ResponseTypes, ParamsTypes, DataTypes>} props
 * @return {*}  {IUseFetch<DataTypes, ParamsTypes>}
 */
export function useGetListOld<
  ResponseTypes extends { [x: string]: any },
  ParamsTypes,
  DataTypes
>(
  props: IFetchDataProps<ResponseTypes, ParamsTypes, DataTypes>
): IUseFetchV2<DataTypes, ParamsTypes> {
  const {
    errorMessage = 'Не удалось загрузить данные',
    getDataApi,
    initialParams,
    convertData,
    dataKey = 'list_of_entities',
    initialData = [],
    isRefresh = false,
    requestStatusLoading = true,
    condition = true,
    delayRequest = true,
    isOneRequest = false,
    keyTotal = 'total_number_of_entities',
  } = props;

  const searchTimeout = useRef(0);

  const noDelay = () => {
    searchTimeout.current = 0;
  };
  const [data, setData] = useState<DataTypes>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [params, setParams] = useState<ParamsTypes>(initialParams);
  const [refresh, setRefresh] = useState<boolean>(isRefresh);
  function onSearchRequest(searchString: any, field: string) {
    setParams((prevState: ParamsTypes) => ({
      ...prevState,
      skip: 0,
      [field]: searchString,
    }));
  }

  const onSortClick = (sortCriterion: any) => {
    noDelay();
    setParams((prevState: ParamsTypes) => ({
      ...prevState,
      skip: 0,
      sort: sortCriterion,
    }));
  };

  const setStart = (skip: number) => {
    setParams((prevState: ParamsTypes) => ({ ...prevState, skip }));
  };

  const setLength = (length: number) => {
    setParams((prevState: ParamsTypes) => ({ ...prevState, length }));
  };
  const doRefresh = useCallback(
    () => setRefresh((prevState: boolean) => !prevState),
    []
  );

  const fetchData = useCallback(async () => {
    const fullErrorMessage = `${errorMessage} .Пожалуйста, свяжитесь с администратором.'`;

    requestStatusLoading && setIsLoading(true);

    try {
      const response: ResponseTypes = await trackPromise(getDataApi(params));
      if (!response?.['result']) {
        catchErrorsIfServerStatusFalse(fullErrorMessage, response?.['message']);
        return;
      }

      response['result']?.[keyTotal]
        ? setTotal(response['result'][keyTotal])
        : setTotal(0);
      convertData
        ? setData(convertData(response['result']?.[dataKey]))
        : setData(response['result']?.[dataKey]);
    } catch (err) {
      catchErrorFromFetch(fullErrorMessage, err);
      return;
    } finally {
      requestStatusLoading && setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, errorMessage, getDataApi, params, requestStatusLoading]);

  const setIsOneRequest = () => (isOneRequest ? initialData === data : true);
  useEffect(() => {
    if (condition && !!params && setIsOneRequest()) {
      const timeOutId = setTimeout(() => fetchData(), searchTimeout.current);
      if (searchTimeout.current === 0 && delayRequest) {
        searchTimeout.current = config.searchTypingTimeout;
        return;
      }
      return () => clearTimeout(timeOutId);
    } else {
      return () => null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, delayRequest, fetchData, params, refresh]);

  return {
    data,
    isLoading,
    total,
    onSearchRequest,
    onSortClick,
    params,
    setData,
    doRefresh,
    setStart,
    setLength,
  };
}
