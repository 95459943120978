import React from 'react';
import { t } from 'services/utils/translation';
import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';

const NotFoundPage = () => {
  return (
    <div className={styles['fullscreen-wrapper']}>
      <main className={styles['main']}>
        <h2>{t('Страница не найдена')}</h2>
        <NavLink className="btn btn-primary" to="/">
          {t('Вернуться на главную')}
        </NavLink>
      </main>
    </div>
  );
};

export default NotFoundPage;
