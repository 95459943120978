import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import { IClientAccessPolicyService } from '../components/staff/access-policy/AccessPolicyForm/AccessPolicyForm';

class AccessPolicy {
  public pk?: number;
  public access_policy_name: string = '';
  public access_policy_description?: string = '';
  public access_policy_statement: IClientAccessPolicyService[] = [];
  constructor(pk?: number) {
    this.pk = pk;
  }

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      access_policy_name: Yup.string()
        .trim()
        .required(t('Укажите название.'))
        .min(3, t('Название не может быть короче 3 символов.')),
      access_policy_description: Yup.string()
        .trim()
        .notRequired()
        .min(3, t('Описание не может быть короче 3 символов.')),
    });
  };
}

export default AccessPolicy;
