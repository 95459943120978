import { useEffect, useRef } from 'react';

const useSaveIsActiveFirst = (
  isFirstIsVisible: boolean,
  saveIsActive?: (isActive: boolean) => void
) => {
  const isFirstIsVisibleRef = useRef(isFirstIsVisible);
  isFirstIsVisibleRef.current = isFirstIsVisible;

  useEffect(() => {
    isFirstIsVisibleRef.current = isFirstIsVisible;
  }, [isFirstIsVisible]);

  useEffect(() => {
    return () => {
      if (saveIsActive) {
        saveIsActive(isFirstIsVisibleRef.current);
      }
    };
  }, [saveIsActive]);
};

export default useSaveIsActiveFirst;
