import { uuid4 } from '@sentry/utils';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  toUniformListChild,
  UniformChildType,
} from 'services/utils/toUniformListChild/toUniformListChild';
import { t } from 'services/utils/translation';
import { makeLink } from '../../../services/localPaths';

type Children<T> = {
  [K in keyof T]: number | string;
};

type PropsType<T> = {
  listOfChildren: Children<T>[];
  parentFk?: number;
  path: string;
};

const ParentAndChildrenLinks = <Children,>({
  listOfChildren,
  parentFk,
  path,
}: PropsType<Children>) => {
  const listChild = toUniformListChild(listOfChildren);

  const getUniqId = useCallback(() => 'ToolTip-' + uuid4(), []);

  return (
    <>
      {parentFk && (
        <>
          <span>{t('Родительская')}:</span>
          <Link to={makeLink(path, parentFk)}>{parentFk}</Link>
        </>
      )}
      {listChild && listChild.length > 0 && (
        <ul>
          <span> {t('Дочерние')}:</span>
          {listChild.map((child: UniformChildType) => {
            const uniqId = `Tooltip_${getUniqId()}`;
            return (
              <div key={child.pk} id={uniqId} className="m-1">
                <li>
                  <Link to={makeLink(path, child.pk)}>
                    <span className="m-1">{child?.pk}</span>
                  </Link>
                </li>
                <ToolTipWrapper placement="left" target={uniqId}>
                  {child?.description}
                </ToolTipWrapper>
              </div>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default memo(ParentAndChildrenLinks);
