import classNames from 'clsx';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import config from 'config.json';
import { IColumnVisibility, useColumnFilter } from 'hooks/useColumnFilter';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';

import { FC, useMemo } from 'react';
import { t } from 'services/utils/translation';
import styles from './index.module.scss';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import TDCollapse from 'components/tables/TdCollapse/TDCollapse';
import { useGetList } from '../../../../hooks/useGetList';
import { toggleList } from '../../../../components/tables/TdCollapse/TDCollapse';
import {
  IPaginatedParamsAccessPolicy,
  SortCriteriaAccessPolicy,
} from 'services/api/staff/access-policy/IPaginatedParamsAccessPolicy';
import { getPaginatedAccessPolicyApiV2 } from 'services/api/staff/access-policy/accessPolicyApiV2';
import {
  IAccessPolicyForList,
  extractToAccessPolicyForList,
} from './toAccessPolicyForList';
import { Pager } from '../../../../components/tables/Pager';
import { IClientAccessPolicyStatement } from './toAccessPolicyForList';
import { useSelector } from 'react-redux';
import { getLocalizationFromStore } from '../../../../store/general/selectors';
import { IAccessPolicy } from '../../../../services/api/staff/access-policy/IAccessPolicy';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import useModalV2 from '../../../../hooks/useModalV2';
import { IModalV2 } from '../../../../hooks/useModalV2';
import { deleteAccessPolicyApiV2 } from '../../../../services/api/staff/access-policy/accessPolicyApiV2';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import AccessPolicyForm from '../../../../components/staff/access-policy/AccessPolicyForm/AccessPolicyForm';
import useAccessPolicyHandleSearch from './useAccessPolicyHandleSearch';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import hasValuesInObject from '../../../../services/utils/hasValueInObject/hasValueInObject';
import { UI_TITLE } from '../../../../services/localLocalization/UITitle';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyForAccess } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyPersonnel';
import { extractGetLeftValueIfTrue } from '../../../../services/utils/extractGetLeftValueIfTrue';

const deleteMessageError = t('Не удалось удалить роль');
const CREATE_BUTTON_TITLE = t('Создать роль');
const MODAL_EDIT_TITLE = t('Редактировать роль');
const MODAL_CREATE_TITLE = t('Создать роль');

const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);

const MODAL_DELETE_TITLE = t('Удалить роль');

const COLUMN_LABELS = {
  PK: t('№'),
  NAME: t('Наимeнование'),
  PERMISSIONS: t('Разрешения'),
};

const filters = [
  { name: 'pk', label: COLUMN_LABELS.PK },
  { name: 'name', label: COLUMN_LABELS.NAME },
  { name: 'permissions', label: COLUMN_LABELS.PERMISSIONS },
];
const columnVisibilityInitial: IColumnVisibility = {
  pk: true,
  name: true,
  permissions: true,
};

const AccessPolicyPage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial);

  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyForAccess,
      targetAction: ['U', 'C', 'D'],
    });

  const initialParams: IPaginatedParamsAccessPolicy = useMemo(
    () => ({
      skip: 0,
      length: config.pageLength,
    }),
    []
  );

  const localization = useSelector(getLocalizationFromStore);
  const hasLocalization = hasValuesInObject(localization);

  const toAccessPolicyForList = extractToAccessPolicyForList(localization);

  const {
    data: roles,
    isLoading,
    onSortClick,
    setStart,
    setLength,
    total,
    refreshListData,
    params: { length, skip, access_policy_pk, access_policy_name },
    setData,
    onSearchRequest,
  } = useGetList({
    getDataApi: getPaginatedAccessPolicyApiV2,
    convertData: (payload: IAccessPolicy[]) => {
      return toAccessPolicyForList(payload);
    },
    initialParams,
    condition: hasLocalization,
  });

  const [handleSearchPk, handleSearchName] =
    useAccessPolicyHandleSearch(onSearchRequest);

  const toggleListPersonRoles = toggleList(setData, 'access_policy_pk');

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetRole },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModalV2<{ pk: number; name: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteAccessPolicyApiV2,
    entityIdKey: 'pk',
  });

  return (
    <MainLayout>
      <Subheader>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <AddIcon className="mr-2 text-white" /> {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={`id p-0 ${!visibility.pk ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaAccessPolicy.NumberAsc}
                  title={COLUMN_LABELS.PK}
                  onSort={onSortClick}
                  onSearch={handleSearchPk}
                  defaultValue={access_policy_pk ? access_policy_pk + '' : ''}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={`w-50 ${!visibility.name ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaAccessPolicy.NameAsc}
                  title={COLUMN_LABELS.NAME}
                  onSort={onSortClick}
                  onSearch={handleSearchName}
                  defaultValue={access_policy_name}
                />
              </th>
              <th
                scope="col"
                className={`w-75 ${!visibility.permissions ? 'd-none' : ''}`}
              >
                {COLUMN_LABELS.PERMISSIONS}
              </th>
              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters &&
                    filters.map(({ name, label }) => (
                      <ColumnFilterItem
                        key={name}
                        name={name}
                        label={label}
                        defaultChecked={visibility[name]}
                        onChange={onFilterItemToggle}
                      />
                    ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              roles &&
              roles.map(
                ({
                  access_policy_pk: pk,
                  access_policy_name: name,
                  openList,
                  clientAccessPolicyStatement,
                }: IAccessPolicyForList) => {
                  return (
                    <tr key={pk}>
                      <td
                        align="center"
                        className={!visibility.pk ? 'd-none' : ''}
                      >
                        {pk}
                      </td>
                      <td className={!visibility.name ? 'd-none' : ''}>
                        {name}
                      </td>
                      <TDCollapse
                        visibility={visibility.name}
                        openList={openList}
                        entity={clientAccessPolicyStatement.map(
                          (
                            {
                              accessPolicyDetail,
                              labelAccessPolicyStatement,
                            }: IClientAccessPolicyStatement,
                            i: number
                          ) => (
                            <ul>
                              <strong>{labelAccessPolicyStatement}</strong>
                              <li key={i + labelAccessPolicyStatement}>
                                {accessPolicyDetail.map((el, id) => (
                                  <div id={id + ''}>{el}</div>
                                ))}
                              </li>
                            </ul>
                          )
                        )}
                        toggleTd={toggleListPersonRoles}
                        entityId={pk}
                        displayedNumberOfItems={1}
                      />

                      <td align="right">
                        <ActionsDropdown>
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={UI_TITLE.EDIT}
                              onClick={openAddEditForm.bind(null, {
                                pk,
                                name,
                              })}
                              icon={
                                <EditIcon
                                  className={classNames(
                                    styles.icon,
                                    'text-primary'
                                  )}
                                />
                              }
                            />
                          )}

                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                name,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
        {isLoading && <LoaderFetch />}
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />
        <ModalWrapper
          headerLabel={getModalTitle(!!targetRole?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <AccessPolicyForm
            successHandler={successHandler}
            pk={targetRole?.pk}
          />
        </ModalWrapper>
        {targetRole?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetRole?.pk}
            entityName={targetRole?.name}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default AccessPolicyPage;
