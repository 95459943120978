import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';
 import { t } from '../utils/translation';
import { checkStartDateBeforeCurrentDate } from 'services/utils/dateHelper/dateHelper';

export function dateRange(
  this: Yup.StringSchema,
  ref: Reference,
  msg?: string
) {
  return this.test({
    name: 'dateRange',
    exclusive: false,
    message: msg || t('Дата не может быть ранее сегодняшнего дня'), // Сообщение об ошибке
    params: { reference: ref ? ref.path : undefined },
    test: function () {
      return checkStartDateBeforeCurrentDate(this.path)(this); // Функция валидации
    },
  });
}

export default Yup;
