import { ReactNode, useRef } from 'react';
import { FC, memo } from 'react';
import PopoutSelectMenu, {
  PopoutSelectMenuPosition,
} from '../PopoutSelectMenu/PopoutSelectMenu';
import classNames from 'clsx';
import useClickOutside from 'hooks/useClickOutside/useClickOutside';

type PopoutSelectMenuDropdown = {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target?: ReactNode;
  readonly onClose: () => void;
  className?: string;
  position?: PopoutSelectMenuPosition;
};

const PopoutSelectDropdown: FC<PopoutSelectMenuDropdown> = ({
  children,
  isOpen,
  onClose,
  className,
  position,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, onClose);

  return (
    <div className={classNames('position-relative', className)}>
      {isOpen && (
        <div ref={menuRef}>
          <PopoutSelectMenu position={position}>{children}</PopoutSelectMenu>
        </div>
      )}
    </div>
  );
};

export default memo(PopoutSelectDropdown);
