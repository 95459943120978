import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';

import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { extractValueFromClientSelectOptionV2 } from '../../../services/utils/selects/selects';
import useRead from '../../../hooks/useRead';
import VehicleType from 'models/VehicleType';
import { usePostV3 } from 'hooks/usePostV3';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import {
  createVehicleTypeApiV2,
  getForSelectVehicleTypeParentApiV2,
  readVehicleTypeApiV2,
  updateVehicleTypeApiV2,
} from 'services/api/vehicle/vehicle-type/vehicleTypeApi';
import { IWrapperVehicleType } from 'services/api/vehicle/vehicle-type/IVehicleType';
import IUpdateVehicleType from 'services/api/vehicle/vehicle-type/IUpdateVehicleType';
import ICreateVehicleType from 'services/api/vehicle/vehicle-type/ICreateVehicleType';
import deleteUnnecessaryParameters from '../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

const preprocessValues = (values: IFormValues) => {
  return deleteUnnecessaryParameters(
    {
      ...values,
      vehicle_type_parent_type_fk: extractValueFromClientSelectOptionV2(
        values?.parent
      ),
    },
    ['parent']
  );
};

interface IFormValues {
  pk?: number;
  vehicle_type_rendition: string;
  parent?: IClientSelectOptionV2<number>;
  vehicle_type_parent_type_fk?: number;
  // permission?: string;
  // permission_name?: string;
}

type PropsType = {
  successHandler: () => void;
  pk?: number;
};

const VehicleTypeForm: FC<PropsType> = (props) => {
  const { successHandler, pk } = props;

  const [isParent, setIsParent] = useState<boolean>(false);

  const convertData = ({ vehicle_type }: IWrapperVehicleType): IFormValues => {
    setIsParent(!vehicle_type?.vehicle_type_parent_type_fk);
    return {
      pk: vehicle_type?.vehicle_type_pk,
      vehicle_type_rendition: vehicle_type.vehicle_type_rendition,
      vehicle_type_parent_type_fk: vehicle_type.vehicle_type_parent_type_fk,
    };
  };

  const { data: initialValues, isLoading } = useRead({
    getDataApi: readVehicleTypeApiV2,
    params: { pk },
    convertData,
    condition: !!pk,
    initialData: new VehicleType(),
    warningHandler: successHandler,
  });
  const createVehicleType = usePostV3({
    fetchApi: createVehicleTypeApiV2,
    successHandler,
    viewClientTextToast: true,
    successMessage: t('Тип машины успешно создан'),
  });
  const updateVehicleType = usePostV3({
    fetchApi: updateVehicleTypeApiV2,
    successHandler,
    viewClientTextToast: true,
    successMessage: t('Тип машины успешно отредактирован'),
  });

  return (
    <>
      <LoaderFetch />
      {!isLoading && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={VehicleType.validationSchema()}
          onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
            setSubmitting(true);
            const newValues = preprocessValues(values);
            if (newValues?.pk !== undefined) {
              await updateVehicleType(
                newValues as IUpdateVehicleType,
                setErrors
              );
            } else {
              await createVehicleType(
                newValues as ICreateVehicleType,
                setErrors,
                resetForm
              );
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} noValidate={true}>
              {values.pk && (
                <HiddenField
                  id="pk"
                  value={values.pk}
                  hasError={errors.pk != null && touched.pk != null}
                />
              )}
              <TextField
                type="text"
                id="vehicle_type_rendition"
                label={t('Название типа машины')}
                placeholder={t('Введите название типа машины...')}
                hasError={
                  errors.vehicle_type_rendition != null &&
                  touched.vehicle_type_rendition != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={values.vehicle_type_rendition}
                required={true}
              />
              {!isParent && (
                <DynamicSelectPaginatedFieldV2
                  id="parent"
                  label={t('Выбрать родительский тип машин')}
                  placeholder={t('Выберите тип')}
                  hasError={errors.parent != null && touched.parent != null}
                  selectHandler={getForSelectVehicleTypeParentApiV2}
                  options={values.parent}
                  additionalErrorKey="parent_id"
                />
              )}
              <FormGroup className="d-block text-right mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

// const toFormValues = (VehicleType: IVehicleTypeOld): IFormValues => {
//   return {
//     vehicle_type_pk: VehicleType.vehicle_type_pk,
//     name: VehicleType.name,
//     parent_id: VehicleType?.parent?.id && VehicleType.parent.id,
//   };
// };

export default VehicleTypeForm;
