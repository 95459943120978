import { FC, PropsWithChildren, ChangeEventHandler } from 'react';
import { Input, Label } from 'reactstrap';
import styles from './index.module.scss';
import classNames from 'clsx';
import ToolTipWrapper from '../ToolTipWrapper/ToolTipWrapper';
export interface InputGroupWithButtonAddonPropsType {}

interface IInputGroupCheckbox
  extends Omit<InputGroupWithButtonAddonPropsType, 'addonText' | 'hint'> {
  id: string;
  className?: string;
  onChange?: ChangeEventHandler;
  hint: string;
  defaultChecked?: boolean;
  disable?: boolean;
}
type PropsType = PropsWithChildren<IInputGroupCheckbox>;

export const InputGroupWithCheckbox: FC<PropsType> = (props) => {
  const {
    onChange,
    className,
    id,
    hint,
    defaultChecked,
    disable = false,
  } = props;
  const uniqId = `btn-checkbox-${id}`;
  return (
    <>
      <div className={classNames(styles['btn-checkbox'], className)}>
        <Input
          id={uniqId}
          type="checkbox"
          onChange={onChange}
          defaultChecked={defaultChecked}
          disabled={disable}
        />
        <Label for={uniqId} check disabled={disable} />
      </div>
      <ToolTipWrapper placement="top" target={uniqId}>
        {hint}
      </ToolTipWrapper>
    </>
  );
};
