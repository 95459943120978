import styles from './index.module.scss';
import classNames from 'clsx';
import { ReactComponent as ArrowIcon } from 'img/icons/icon-left-arrow.svg';
import CounterUI from 'components/layouts/CounterUI/CounterUI';
import { FC, memo } from 'react';
import Checkbox from 'components/controls/Checkbox';
import { v4 } from 'uuid';
import { TCheckHandler } from '../AccessPolicyServiceModule.tsx/AccessPolicyServiceModule.tsx';

type PropsType = {
  open: boolean;
  counter: number;
  label: string;
  toggle: () => void;
  className?: string;
  checked: boolean;
  onSectionDelete: TCheckHandler;
  onSectionAdd: TCheckHandler;
  sectionName?: string;
};

const CollapseControlSection: FC<PropsType> = ({
  open,
  label,
  counter,
  toggle,
  className,
  checked,
  onSectionDelete,
  onSectionAdd,
  sectionName,
}) => {
  const onChecked = ((checked: boolean) => () => {
    if (checked) {
      onSectionDelete(sectionName);
    } else {
      onSectionAdd(sectionName);
    }
  })(checked);

  return (
    <div className={classNames('d-flex align-items-center', styles.container)}>
      <button
        className={classNames(styles.module, className, {
          [styles.open]: open,
        })}
        onClick={toggle}
        type="button"
      >
        <ArrowIcon />
        <span>{label}</span>
        {counter > 0 && <CounterUI count={counter} />}
      </button>
      <Checkbox
        key={label}
        className={classNames(styles.action, 'w-25', {})}
        name={`action${v4()}`}
        label={''}
        onChange={onChecked}
        checked={checked}
      />
    </div>
  );
};

export default memo(CollapseControlSection);
