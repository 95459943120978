import config from 'config.json';
import React, { FC, memo, useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as CreateIconCalendar } from 'img/icons/icon-calendar-grey.svg';

import styles from './index.module.scss';
import { ReactComponent as CancelIcon } from 'img/icons/icon-cancel.svg';
import { Button } from 'reactstrap';
import classNames from 'clsx';
import CustomToolTip from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { formatISO } from 'date-fns';
import { add } from 'date-fns/esm';
import {
  formatDateForServerV2,
  formatDateFromServer,
  isValidDate,
} from '../../../services/utils/dateHelper/dateHelper';
import { IPropsOnSearch } from '../HeaderWithDatePickerRangeV2/HeaderWithDatePickerRangeV2';
import { t } from 'services/utils/translation';

type PropsType = {
  propsOnSearch: IPropsOnSearch;
  title: string;
  startDate?: Date | null;
  endDate?: Date | null;
  className?: string;
  tooltip?: boolean;
  hasTime?: boolean;
};

interface IPropsOnSearchWithDate extends IPropsOnSearch {
  date: Date[];
}

const prepareDateFormatForServer = (date: [Date, Date], hasTime: boolean) => {
  const [startDate, endDate] = date;
  const formattedDateStart = hasTime
    ? formatISO(startDate)
    : formatDateForServerV2(startDate);
  let formattedDateEnd = undefined;
  if (endDate) {
    formattedDateEnd = hasTime
      ? formatISO(add(endDate, { hours: 23, minutes: 59, seconds: 59 }))
      : formatDateForServerV2(endDate);
  }

  return [formattedDateStart, formattedDateEnd];
};

const DatePickerRangeV2: FC<PropsType> = (props) => {
  let {
    title,
    startDate,
    endDate,
    propsOnSearch: { onSearchRequest, keyDate },
    hasTime = false,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCalendar = {
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  };

  const onDeleteValue = useCallback((props: IPropsOnSearch) => {
    const { onSearchRequest, keyDate } = props;
    const [startDateKey, endDateKey] = keyDate;

    onSearchRequest('', startDateKey);
    onSearchRequest('', endDateKey);
  }, []);

  const onSearchDate = (props: IPropsOnSearchWithDate) => {
    const { date, onSearchRequest, keyDate } = props;
    const [startDateKey, endDateKey] = keyDate;
    const [start, end] = date;
    if ((!start && !end) || !isValidDate(start)) {
      onSearchRequest('', startDateKey);
      onSearchRequest('', endDateKey);
      return;
    }

    const [formattedDateStart, formattedDateEnd] = prepareDateFormatForServer(
      [start, end],
      hasTime
    );
    onSearchRequest(formattedDateStart, startDateKey);
    onSearchRequest(formattedDateEnd, endDateKey);
  };

  const tooltipId = 'tooltip-' + title.replace(/\s/g, '');
  return (
    <>
      <div
        style={{ width: '100%' }}
        className={classNames({
          [styles['container-backlight']]: !!startDate && !!endDate,
          [styles.container]: !startDate || !endDate,
        })}
      >
        <CustomToolTip target={tooltipId} placement="top">
          {!!startDate && !!endDate
            ? `${formatDateFromServer(startDate)} - ${formatDateFromServer(
                endDate
              )}`
            : title}
        </CustomToolTip>
        <div className={classNames(styles.icon, styles.thIcon)} id={tooltipId}>
          {startDate === null ? (
            <CreateIconCalendar onClick={handleCalendar.open} />
          ) : (
            <CancelIcon
              onClick={onDeleteValue.bind(null, {
                onSearchRequest,
                keyDate,
              })}
            />
          )}
        </div>
        <div>
          <DatePicker
            id="datepicker-container"
            locale="ru"
            placeholderText={title}
            selected={startDate}
            dateFormat={config.clientDateFormat}
            onChange={(date: [Date, Date], event: any) => {
              if (event && typeof event.preventDefault === 'function') {
                event.preventDefault();
              }
              onSearchDate({
                onSearchRequest,
                date,
                keyDate,
              });
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            shouldCloseOnSelect={false}
            open={isOpen}
            readOnly
            onClickOutside={handleCalendar.close}
            calendarStartDay={1}
          >
            <div className={styles.buttonCloseContainer}>
              <Button
                size="sm"
                type="button"
                color="light"
                onClick={handleCalendar.close}
              >
                <span>{t('Закрыть')}</span>
              </Button>
            </div>
          </DatePicker>
        </div>
      </div>
    </>
  );
};

export default memo(DatePickerRangeV2);
