import {
  IAccessPolicy,
  TAccessPolicyEnsembleAll,
  IAccessPolicyStatement,
  TCRUDA,
  TServicesKeys,
} from 'services/api/staff/access-policy/IAccessPolicy';
import { t } from '../../../../services/utils/translation';
import { ILocalization } from '../../../../services/api/general/ILocalization';
import { TValueOf } from 'services/utils/TSUtils/TValueOf';
import { getEntries } from 'services/utils/TSUtils/getEntries';
import { ReactElement } from 'react';
import { uniqueId } from 'lodash';
import { makeFirstLetterUppercase } from '../../../../services/utils/stringHelper/stringHelper';

export const localizationActions = {
  C: t('Создание'),
  R: t('Просмотр'),
  U: t('Редактирование'),
  D: t('Удаление'),
  A: t('Архивирование'),
  E: t('Экспорт'),
  I: t('Импорт'),
} as const;

export interface IActionLocalization {
  C: 'Создание';
  R: 'Просмотр';
  U: 'Редактирование';
  D: 'Удаление';
  A: 'Архивирование';
  I: 'Импорт';
  E: 'Экспорт';
}

// export const localizationEnsemble = toTObject({
//   ExternalRequest: t('Заявки'),
//   ResponsibleDepartment: t('Ответственная служба'),
//   WorkCategory: t('Классификация работ'),
//   BillingAccount: t('Лицевые счета'),
//   BillingAccountType: t('типы лицевых счетов'),
//   Counterparty: t('Контрагент'),
//   InternalTask: t('Задачи'),
//   InternalTaskStatus: t('Статусы задач'),
//   InternalTaskType: t('Типы задач'),
//   AccessPolicy: t('Правила доступа'),
//   Employee: t('Сотрудники'),
//   JobTitle: t('Должность'),
// });

// type TLocalizationEnsembleKeys = TKeyOf<typeof localizationEnsemble>;

// type TLocalizationEnsembleValues = TValueOf<typeof localizationEnsemble>;

// type TActionLocalization = TValueOf<typeof actionLocalization>;

// type LocalizationKeys = TKeyOf<ILocalization>;

type LocalizationValues = TValueOf<ILocalization>;
export type LocalizationActionsValues = TValueOf<IActionLocalization>;

export interface IClientAccessPolicyStatement {
  accessPolicyDetail: ReactElement[];
  labelAccessPolicyStatement: LocalizationValues;
}
export interface IAccessPolicyForList extends IAccessPolicy {
  openList: boolean;

  clientAccessPolicyStatement: IClientAccessPolicyStatement[];
}

const extractReduceAccessPolicy =
  (localization: ILocalization) =>
  (
    acc: ReactElement[],
    [keyEnsemble, actionPolicyCRUD]: [TServicesKeys, TCRUDA[]]
  ) => {
    if (actionPolicyCRUD.length > 0) {
      const Component = (
        <>
          {localization[keyEnsemble]}:{' '}
          {extractLocalizationCRUD(actionPolicyCRUD).map(
            (el: string, i: number, array: string[]) => (
              <span key={i + uniqueId()}>
                {el}
                {array.length - 1 !== i ? ', ' : ''}
              </span>
            )
          )}
          <br />
        </>
      );
      acc.push(Component);
    }
    return acc;
  };

const toClientModelAccessPolicy = (
  accessPolicyModels: TAccessPolicyEnsembleAll,
  localization: ILocalization
): ReactElement[] => {
  const reduceAccessPolicy = extractReduceAccessPolicy(localization);
  return getEntries(accessPolicyModels).reduce(reduceAccessPolicy, []);
};

const hasAccessPolicyForModel = (
  accessPolicyModels: TAccessPolicyEnsembleAll
) => {
  return Object.entries(accessPolicyModels).some(
    ([, value]: [string, TCRUDA[]]) => {
      return value.length > 0;
    }
  );
};

const toClientAccessPolicy = (
  accessPolicyStatement: IAccessPolicyStatement,
  localization: ILocalization
) =>
  Object.entries(accessPolicyStatement).reduce(
    (acc: IClientAccessPolicyStatement[], [keyStatement, value]) => {
      if (hasAccessPolicyForModel(value)) {
        const tKeyStatement = keyStatement as keyof typeof localization;
        acc.push({
          labelAccessPolicyStatement: makeFirstLetterUppercase(
            localization[tKeyStatement]
          ) as LocalizationValues,
          accessPolicyDetail: toClientModelAccessPolicy(value, localization),
        });
      }
      return acc;
    },
    []
  );

const extractLocalizationCRUD = (actionPolicyCRUD: TCRUDA[]) =>
  actionPolicyCRUD.map(
    (policyCRUD: TCRUDA): LocalizationActionsValues =>
      localizationActions[policyCRUD]
  );

export const extractToAccessPolicyForList =
  (localization: ILocalization) =>
  (accessPolicy: IAccessPolicy[]): IAccessPolicyForList[] => {
    return accessPolicy.map((permission: IAccessPolicy) => {
      const { access_policy_statement } = permission;
      const clientAccessPolicyStatement = toClientAccessPolicy(
        access_policy_statement,
        localization
      );
      return { ...permission, openList: false, clientAccessPolicyStatement };
    });
  };
