import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import Folder from 'components/misc/Folder';
import React, { FC } from 'react';
import { PATH_ORGANIZATIONS } from 'services/pathConstants';

const OrganizationsPage: FC = () => {
  return (
    <MainLayout>

      <Subheader />
      <Folder path={PATH_ORGANIZATIONS} />
    </MainLayout>
  );
};

export default OrganizationsPage;
