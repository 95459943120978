import { t } from '../../../../services/utils/translation';

export const COLUMN_LABELS_CONTROLLER = {
  PK: '№',
  DESCRIPTION: t('Описание'),
  BILLING_ACCOUNT: t('Лицевой счет'),
  IS_CLOSED: t('Статус'),
  CREATE_DATE: t('Дата создания'),
  EDITING_DATE: t('Дата редактирования'),
} as const;
export const filtersController = [
  { name: 'controller_pk', label: COLUMN_LABELS_CONTROLLER.PK },
  {
    name: 'controller_description',
    label: COLUMN_LABELS_CONTROLLER.DESCRIPTION,
  },
  {
    name: 'controller_billing_account',
    label: COLUMN_LABELS_CONTROLLER.BILLING_ACCOUNT,
  },
  {
    name: 'controller_is_closed',
    label: COLUMN_LABELS_CONTROLLER.IS_CLOSED,
  },
  {
    name: 'controller_datetime_installed',
    label: COLUMN_LABELS_CONTROLLER.CREATE_DATE,
  },
  {
    name: 'controller_datetime_active',
    label: COLUMN_LABELS_CONTROLLER.EDITING_DATE,
  },
] as const;

export const columnVisibilityInitialController = {
  controller_pk: true,
  controller_description: true,
  controller_billing_account: true,
  controller_is_closed: true,
  controller_datetime_installed: true,
  controller_datetime_active: true,
} as const;
