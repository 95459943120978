import { t } from 'services/utils/translation';
import { ActionsDropdown, ActionsDropdownItem } from '../ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import styles from './index.module.scss';
import IBaseSubListEntity from './IBaseSubListEntity';

type PropsType<ChildType extends IBaseSubListEntity> = {
  openEditForm: Function;
  openDeleteForm: Function;
  subListParentName: string;
  listChild: ChildType[];
  visibility: boolean;
};

const TDSubList = <ChildType extends IBaseSubListEntity>(
  props: PropsType<ChildType>
) => {
  const {
    openEditForm,
    openDeleteForm,
    subListParentName,
    listChild,
    visibility,
  } = props;
  return (
    <td className={!visibility ? 'd-none' : ''}>
      {subListParentName}
      <div className="my-2">
        {listChild?.length > 0 &&
          listChild.map((children: ChildType) => (
            <div className={styles['wrapper-list-item']} key={children.pk}>
              <span>{children.name}</span>
              <ActionsDropdown classNameBtn={styles['btn-dropdown']}>
                <ActionsDropdownItem
                  label={t('Редактировать')}
                  onClick={openEditForm.bind(null, {
                    pk: children.pk,
                    name: children.name,
                  })}
                  icon={<EditIcon className="text-primary" />}
                />
                <ActionsDropdownItem
                  label={t('Удалить')}
                  onClick={openDeleteForm.bind(null, {
                    pk: children.pk,
                    name: children.name,
                  })}
                  icon={<DeleteIcon className="text-primary" />}
                />
              </ActionsDropdown>
            </div>
          ))}
      </div>
    </td>
  );
};

export default TDSubList;
