import classNames from 'clsx';
import config from 'config.json';
import { ReactComponent as SearchIcon } from 'img/icons/icon-loupe.svg';
import React, { FC, memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './index.module.scss';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';
import { TMods } from 'services/types/TMods';

type PropsType = {
  onSearch: Function;
  title: string;
  className?: string;
  value?: Date | null;
  backGroundGrey?: boolean;
  headerColor?: TABLE_COLOR;
};

const HeaderWithDatePicker: FC<PropsType> = (props) => {
  const {
    onSearch,
    title,
    className,
    value = null,
    headerColor = TABLE_COLOR.DEFAULT,
  } = props;

  const modsLabel: TMods = {
    [styles.wrapper]: !value,
    [styles['wrapper-backlight']]: !!value,
    [styles.grey]: headerColor === TABLE_COLOR.GREY,
  } as const;

  return (
    <label className={classNames(modsLabel, 'd-flex flex-row', className)}>
      <span className={classNames(styles.icon)}>
        <SearchIcon />
      </span>

      <span
        className={classNames('w-100', {
          [styles.grey]: headerColor === TABLE_COLOR.GREY,
        })}
      >
        <DatePicker
          locale="ru"
          placeholderText={title}
          selected={value}
          dateFormat={config.clientDateFormat}
          onChange={(date: Date, e: any) => {
            if (e && typeof e.preventDefault === 'function') {
              e.preventDefault();
            }

            onSearch(date);
          }}
          shouldCloseOnSelect={true}
          calendarStartDay={1}
          className={classNames({
            [styles.grey]: headerColor === TABLE_COLOR.GREY,
          })}
        />
      </span>
    </label>
  );
};

export default memo(HeaderWithDatePicker);
