import * as Yup from 'yup';
import { t } from 'services/utils/translation';
import {
  regEmail,
  regTelephoneNumber,
} from '../services/utils/myRegex/myRegex';

const KPP_ERROR_MESSAGE = t(
  'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)'
);
const OGRN_ERROR_MESSAGE = t('ОГРН может состоять только из 13 цифр');
class Counterparty {
  isNew?: boolean;
  pk?: number;
  counterparty_description?: string;
  counterparty_is_natural_person?: boolean = true;
  counterparty_email?: string;
  counterparty_phone_number?: string;
  counterparty_actual_address?: string;
  counterparty_legal_address?: string;
  counterparty_individual_last_name?: string;
  counterparty_individual_first_name?: string;
  counterparty_individual_middle_name?: string;
  counterparty_company_full_name?: string;
  counterparty_company_short_name?: string;
  counterparty_inn?: string;
  counterparty_company_ogrn?: string;
  counterparty_company_kpp?: string;
  counterparty_is_affiliate?: boolean = false;

  constructor(pk?: number) {
    this.pk = pk;
  }

  static checkInn = (isNaturalPersons: boolean, inn?: string): boolean => {
    if (inn) {
      return isNaturalPersons ? inn.length === 12 : inn.length === 10;
    } else {
      return true;
    }
  };

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      counterparty_company_full_name: Yup.string()
        .trim()
        .when(
          'counterparty_is_natural_person',
          (isNaturalParson: boolean, schema: any) => {
            if (!isNaturalParson) {
              return schema.required(t('Укажите название компании'));
            }
          }
        ),
      counterparty_individual_last_name: Yup.string()
        .trim()
        .when(
          'counterparty_is_natural_person',
          (isNaturalParson: boolean, schema: any) => {
            if (isNaturalParson) {
              return schema.required(t('Укажите фамилию'));
            }
          }
        ),
      counterparty_individual_first_name: Yup.string()
        .trim()
        .when(
          'counterparty_is_natural_person',
          (isNaturalParson: boolean, schema: any) => {
            if (isNaturalParson) {
              return schema.required(t('Укажите имя'));
            }
          }
        ),
      counterparty_description: Yup.string().trim(),
      counterparty_phone_number: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .required(t('Укажите номер телефона.'))
        .matches(regTelephoneNumber, t('Некорректно введен номер телефона')),
      // billing_account_type_fk: Yup.number().required(t('Укажите тип.')),
      counterparty_actual_address: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .required(t('Укажите фактический адрес.')),
      counterparty_legal_address: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .required(t('Укажите юридический адрес.')),
      counterparty_email: Yup.string()
        .trim()
        .notRequired()
        .matches(regEmail, t('Некорректно введен email')),
      counterparty_company_kpp: Yup.string()
        .trim()
        .notRequired()
        .min(9, KPP_ERROR_MESSAGE)
        .max(9, KPP_ERROR_MESSAGE)
        .matches(/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/, t('Некорректный формат КПП')),
      counterparty_company_ogrn: Yup.string()
        .trim()
        .notRequired()
        .min(13, OGRN_ERROR_MESSAGE)
        .max(13, OGRN_ERROR_MESSAGE)
        .matches(/^\d+$/, t('ОГРН может иметь только цифровое значение')),
      counterparty_inn: Yup.string()
        .trim()
        .notRequired()
        .matches(/^\d+$/, t('ИНН может иметь только цифровое значение'))
        .when(
          'counterparty_is_natural_person',
          (isNaturalPerson: boolean, schema: any) => {
            if (isNaturalPerson) {
              return schema.test(
                'digits length inn',
                t('ИНН должен состоять из 12 цифр для ФЛ.'),
                (inn?: string) => {
                  return this.checkInn(isNaturalPerson, inn);
                }
              );
            } else {
              return schema.test(
                'digits length inn',
                t('ИНН должен состоять из 10 цифр для ЮЛ.'),
                (inn?: string) => {
                  return this.checkInn(isNaturalPerson, inn);
                }
              );
            }
          }
        )
        .innValid('counterparty_is_natural_person'),
    });
  };
}

export default Counterparty;
