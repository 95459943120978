import { AnyAction } from 'redux';
import { changeOfMarking } from '../utils/changeMark';
import {
  SET_IS_ACTIVE_BILLING_ACCOUNT_ARRAY_FILTER,
  SET_IS_ACTIVE_WORK_CATEGORY_FILTER,
  SET_EXTERNAL_REQUEST_FIRST_STATUS,
  SET_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER,
  SET_IS_ACTIVE_PK_FILTER,
  SET_INITIAL_EXTERNAL_REQUEST,
  CLEAR_EXTERNAL_REQUEST_VALUE,
  SET_EXTERNAL_REQUEST_FIELDS_VALUE,
  SET_MARKED_EXTERNAL_REQUEST,
  EXTERNAL_REQUESTS_SAVE_FILTERS,
  EXTERNAL_REQUESTS_CLEAR_FILTERS,
  SET_ACCESS_POLICY_EXTERNAL_REQUEST,
} from './actionTypes';

const externalRequestReducer = (state: any = {}, action: AnyAction) => {
  switch (action.type) {
    case EXTERNAL_REQUESTS_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.payload,
        },
      };
    case EXTERNAL_REQUESTS_CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.payload,
      };

    case SET_MARKED_EXTERNAL_REQUEST:
      const newExternalRequestsActive = changeOfMarking(
        state.marked,
        action.payload
      );
      return {
        ...state,
        marked: newExternalRequestsActive,
      };
    case SET_EXTERNAL_REQUEST_FIELDS_VALUE: {
      return {
        ...state,
        fieldValues: {
          ...state.fieldValues,
          ...action.payload,
        },
      };
    }
    case CLEAR_EXTERNAL_REQUEST_VALUE: {
      return {
        ...state,
        fieldValues: {},
      };
    }
    case SET_INITIAL_EXTERNAL_REQUEST: {
      return {
        ...state,
        initialValues: action.payload,
      };
    }
    case SET_EXTERNAL_REQUEST_FIRST_STATUS: {
      return {
        ...state,
        firstStatus: action.payload,
      };
    }
    case SET_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER: {
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          isActiveImplementerDkArrayFilter: action.payload,
        },
      };
    }

    case SET_IS_ACTIVE_PK_FILTER: {
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          isActivePkFilter: action.payload,
        },
      };
    }

    case SET_IS_ACTIVE_BILLING_ACCOUNT_ARRAY_FILTER: {
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          isActiveBillingAccountFilter: action.payload,
        },
      };
    }
    case SET_IS_ACTIVE_WORK_CATEGORY_FILTER: {
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          isActiveWorkCategoryFilter: action.payload,
        },
      };
    }

    case SET_ACCESS_POLICY_EXTERNAL_REQUEST: {
      return {
        ...state,
        externalRequestAccessPolicy: {
          isInstalled: true,
          accessPolicy: { ...action.payload },
        },
      };
    }

    default:
      return state;
  }
};
export default externalRequestReducer;
