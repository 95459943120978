import { ErrorMessage } from 'formik';
import { FC, PropsWithChildren } from 'react';

type ErrorWrapperFieldPropsType = {
  id: string;
  additionalErrorKey?: string;
};

const ErrorWrapperField: FC<PropsWithChildren<ErrorWrapperFieldPropsType>> = (
  props
) => {
  const { children, id, additionalErrorKey } = props;

  return (
    <>
      {children}
      <ErrorMessage name={id} component="div" className="invalid-feedback" />
      {additionalErrorKey && (
        <ErrorMessage
          name={additionalErrorKey}
          component="div"
          className="invalid-feedback"
        />
      )}
    </>
  );
};

export default ErrorWrapperField;
