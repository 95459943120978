import {
  ICounterpartyFilters,
  IGetCounterpartyPaginatedParams,
} from 'services/api/organizationsV2/counterparty/IGetCounterPartyPaginatedParams';
import { extractValueFromClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

const composeParamsGetCounterparty = (
  params: ICounterpartyFilters
): IGetCounterpartyPaginatedParams => {
  const {
    counterparty_is_natural_person: isNaturalPerson,
    counterparty_is_affiliate: isAffiliate,
  } = params;
  return deleteUnnecessaryParameters({
    ...params,
    counterparty_is_natural_person:
      extractValueFromClientSelectOptionV2(isNaturalPerson),
    counterparty_is_affiliate:
      extractValueFromClientSelectOptionV2(isAffiliate),
  });
};

export default composeParamsGetCounterparty;
 