import useGetRefreshingData from '../../../../hooks/useGetRefreshingData';
import { readBillingAccountV2 } from '../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import { useEffect, useMemo } from 'react';
import { IBillingAccount } from '../../../../services/api/organizationsV2/billing-account/IBillingAccount';
import { useLocation } from 'react-router';
import { toBillingAccountDetails } from 'services/utils/bilingAccountUtils/toClientBillingAccountDetails';
import { useFormikContext } from 'formik';
import ExternalRequest from 'models/ExternalRequest';
import Task from 'models/Task';
import useSetAndGetExternalRequestAccessPolicy from 'pages/external-request/external-requests/ExternalRequestPage/hooks/useSetExternalRequestAccessPolicy';
import { targetAccessPolicyBillingAccount } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';

export interface IClientDetailBillingAccount {
  counterparty: {
    pk: number;
    name: string | undefined;
    phoneNumber: number | undefined;
    isCompany: boolean;
  };

  billingAccount: {
    codeNumber: string | undefined;
    pk: number;
    address: string;
    isClosed: boolean;
  };
}

const useGetBillingAccountDetail = (billingAccountPk?: number) => {
  const location = useLocation();

  const { setFieldValue } = useFormikContext<ExternalRequest | Task>();

  const initialParams = useMemo(() => {
    return { pk: Number(billingAccountPk) };
  }, [billingAccountPk]);

  const [billingAccountHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['R'],
   });
  const {
    data: billingAccountClientDetail,
    isLoading: isLoadingBillingAccount,
  } = useGetRefreshingData({
    getDataApi: readBillingAccountV2,
    params: initialParams,
    condition: !!billingAccountPk && billingAccountHaveAccessToRead,
    convertData: (response: { billing_account: IBillingAccount }) =>
      toBillingAccountDetails(response.billing_account),
    initialData: null,
  });

  const billingAccountDetail = billingAccountClientDetail?.billingAccount;

  useEffect(() => {
    if (billingAccountDetail?.isClosed) {
      setFieldValue('isBillingAccountClosed', billingAccountDetail.isClosed);
    }
  }, [billingAccountDetail?.isClosed, setFieldValue]);

  return { isLoadingBillingAccount, billingAccountClientDetail, location };
};

export default useGetBillingAccountDetail;
