import {
  SAVE_FILELD_VALUES_ACTION,
  useSaveValueStore,
} from 'hooks/useSaveValueStore';
import useExtractHandleBlurWrappers from 'hooks/useHandleBlurWrapersForExtenalRequest';
import { useFormikContext } from 'formik';
import Task from 'models/Task';
import { IFormValuesTask } from '../TaskForm';

const useHandleBlurWrappersForTask = () => {
  const { values, handleBlur } = useFormikContext<IFormValuesTask | Task>();

  const {
    internal_task_description,
    internal_task_reporter_phone_number,
    internal_task_time_adjustment,
  } = values;

  const saveValueInStoreForTask = useSaveValueStore(
    SAVE_FILELD_VALUES_ACTION.task
  );

  const handleBlurDescription = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForTask
  )({ internal_task_description });

  const handleBlurReporterPhoneNumber = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForTask
  )({ internal_task_reporter_phone_number });

  const handleBlurTimeAdjustment = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForTask
  )({ internal_task_time_adjustment });

  return {
    handleBlurDescription,
    handleBlurTimeAdjustment,
    handleBlurReporterPhoneNumber,
  };
};

export default useHandleBlurWrappersForTask;
