import { t } from 'services/utils/translation';

export const UI_TITLE = {
  EDIT: t('Редактировать'),
  VIEWING: t('Просмотр'),
  DELETE: t('Удалить'),
  REMOVE_FILTERS: t('Сбросить фильтры'),
  SAVE: t('Сохранить'),
  HINT_FOR_DELETE_PARENT: t(
    'Для удаления этого типа задач, необходимо перенести или удалить все дочерние'
  ),
  CREATE_SUB_EXTERNAL_REQUEST: t('Создать подзаявку'),
  CREATE_SUBTASK: t('Создать подзадачу'),
  TO_PARENT_EXTERNAL_REQUEST: t('Назначить заявку'),
  TO_PARENT_TASK: t('Назначить задачу'),
  DETAIL: t('Подробно'),
} as const;
