import { useCallback, useMemo } from 'react';
import { ValueType } from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
 
const useEmployeeHandleSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
  const handleSearchPk = useCallback(
    (value: string) => {
      onSearchRequest(value, 'employee_pk');
    },
    [onSearchRequest]
  );
  const handleSearchLastName = useCallback(
    (value: string) => {
      onSearchRequest(value, 'employee_last_name');
    },
    [onSearchRequest]
  );
  const handleSearchSupervisor = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2[] | IClientSelectOptionV2,
        boolean
      >
    ) => {
      onSearchRequest(option, 'employee_supervisor_fk_array');
    },
    [onSearchRequest]
  );

  const handleSearchEmail = useCallback(
    (value: string) => {
      onSearchRequest(value, 'employee_email');
    },
    [onSearchRequest]
  );
  const handleSearchPhoneNumber = useCallback(
    (value: string) => {
      onSearchRequest(value, 'employee_phone_number');
    },
    [onSearchRequest]
  );
  const handleSearchPosition = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2[] | IClientSelectOptionV2,
        boolean
      >
    ) => {
      onSearchRequest(option, 'employee_job_title_fk_array');
    },
    [onSearchRequest]
  );

  const handleSearchPersonalCodeNumber = useCallback(
    (value: string) => {
      onSearchRequest(value, 'employee_personnel_code_number');
    },
    [onSearchRequest]
  );

  const handleSearchIsDriver = useCallback(
    (target: IClientSelectOptionV2<boolean>) => {
      if (target?.value !== undefined) {
        onSearchRequest(target.value, 'employee_is_driver');
      } else {
        onSearchRequest(undefined, 'employee_is_driver');
      }
    },
    [onSearchRequest]
  );

  const handleSearchIsActive = useCallback(
    (target: IClientSelectOptionV2<boolean>) => {
      if (target?.value !== undefined) {
        onSearchRequest(target.value, 'employee_is_active');
      } else {
        onSearchRequest(undefined, 'employee_is_active');
      }
    },
    [onSearchRequest]
  );

  return useMemo(
    () => ({
      handleSearchSupervisor,
      handleSearchEmail,
      handleSearchPhoneNumber,
      handleSearchPosition,
      handleSearchPersonalCodeNumber,
      handleSearchIsDriver,
      handleSearchPk,
      handleSearchLastName,
      handleSearchIsActive,
    }),
    [
      handleSearchEmail,
      handleSearchPersonalCodeNumber,
      handleSearchPhoneNumber,
      handleSearchPosition,
      handleSearchSupervisor,
      handleSearchIsDriver,
      handleSearchPk,
      handleSearchLastName,
      handleSearchIsActive,
    ]
  );
};

export default useEmployeeHandleSearch;
