import { useCallback, useMemo } from 'react';
import { toControllerFormValues } from './utils/toControllerFormValues';
import { IControllerHistoryPayload } from 'services/api/controller/IControllerHistory';
import controllerConvertHistoryData from './utils/controllerConvertHistoryData';
import { IControllerWrapper } from 'services/api/controller/IControllerWrapper';

const useUtilsControllerEdit = () => {
  const toController = useCallback((data: IControllerWrapper) => {
    return toControllerFormValues(data.controller);
  }, []);

  const controllerConvertHistory = useCallback(
    (data: IControllerHistoryPayload) => {
      return controllerConvertHistoryData(data);
    },
    []
  );

  return useMemo(
    () => ({
      toController,
      controllerConvertHistory,
    }),
    [controllerConvertHistory, toController]
  );
};

export default useUtilsControllerEdit;
