import { useMemo } from 'react';
import { useGetList } from 'hooks/useGetList';
import { parentUIElement } from 'services/constants/parentUIElement';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { IEventFilters } from 'services/api/organizationsV2/event/IGetPaginatedParamsEvent';
import { getPaginatedEventsApi } from 'services/api/organizationsV2/event/eventApi';
import initialEventFilters from 'store/initialStore/initialEventFilters';

const KEY_PARAMS_FOR_GET_TOTAL_TASK: Record<string, keyof IEventFilters> = {
  [parentUIElement.account]: 'event_billing_account_fk',
} as const;

const composeParamsForOrder = (params: IEventFilters) => {
  return deleteUnnecessaryParameters(params);
};

interface IUseGetTotalEventProps {
  pk: number;
  parentUI: parentUIElement;
}

const useGetEventTotal = ({ pk, parentUI }: IUseGetTotalEventProps) => {
  const initialParamsEvent: IEventFilters = useMemo(
    () => ({
      ...initialEventFilters,
      [KEY_PARAMS_FOR_GET_TOTAL_TASK[parentUI]]: pk,
    }),
    [parentUI, pk]
  );

  const { total: totalEvent, isLoading: isLoadingEvent } = useGetList({
    getDataApi: getPaginatedEventsApi,
    initialParams: initialParamsEvent,
    convertedParameters: composeParamsForOrder,
    initialData: 0,
  });

  return { totalEvent, isLoadingEvent };
};

export default useGetEventTotal;
