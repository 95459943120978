 import {
  SET_CURRENT_IDENTITY_IN_CONTROL_PANEL,
 } from './actionTypes';

const identityInControlPanelReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SET_CURRENT_IDENTITY_IN_CONTROL_PANEL:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default identityInControlPanelReducer;
