import * as Yup from 'yup';
import { t } from '../utils/translation';
import { validateInputDate } from "../utils/dateHelper/dateHelper";

export function dateValid(this: Yup.StringSchema, msg?: string) {
  return this.test({
    name: 'dateValid',
    exclusive: false,
    message: msg || t('Ошибка в формате даты.'), // Сообщение об ошибке
    test: function (value?: string) {
      if (value === undefined) {
        return true;
      }
      return validateInputDate(value); // Функция валидации
    },
  });
}
