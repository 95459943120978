import ICounterPartyInEntities from 'services/api/organizationsV2/counterparty/ICounterPartyInEntities';
import { makeLink } from 'services/localPaths';
import {
  extractCounterpartyCompanyName,
  extractCounterpartyIndividualName,
  isLegalStatusCompany,
} from 'services/utils/bilingAccountUtils/billingAccountUtils';
import { Link } from 'react-router-dom';
import NullableValue from 'components/tables/NullableValue/NullableValue';
import { FC, memo } from 'react';
import { PATH_ORGANIZATIONS_COUNTERPARTY_EDIT } from 'services/pathConstants';

type PropsTypeCounterpartyFromEntitiesLink = {
  counterparty?: ICounterPartyInEntities;
};

export const CounterpartyFromEntitiesLink: FC<
  PropsTypeCounterpartyFromEntitiesLink
> = ({ counterparty }) =>
  counterparty ? (
    <Link
      to={makeLink(
        PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
        counterparty.counterparty_pk
      )}
    >
      {isLegalStatusCompany(counterparty)
        ? extractCounterpartyCompanyName(counterparty)
        : extractCounterpartyIndividualName(counterparty, true)}
    </Link>
  ) : (
    <NullableValue />
  );

export default memo(CounterpartyFromEntitiesLink);
