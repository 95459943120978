import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IFormValuesTask } from '../components/tasks/TaskForm/TaskForm';
import { saveExternalRequestFieldValue } from '../store/externalRequest/actions';
import { saveTaskFieldValue } from '../store/task/actions';
import { IFormValuesExternalRequest } from '../components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';

export interface IValueForSaveInStore {
  event?: ChangeEvent<HTMLInputElement>;
  value?: IFormValuesTask | IFormValuesExternalRequest;
}

export enum SAVE_FILELD_VALUES_ACTION {
  externalRequest = 'externalRequest',
  task = 'task',
}

const saveFunction = {
  [SAVE_FILELD_VALUES_ACTION.externalRequest]: saveExternalRequestFieldValue,
  [SAVE_FILELD_VALUES_ACTION.task]: saveTaskFieldValue,
};

export /**
 *save value in store for form
 *example: if we take the value from the event saveValueInStore({ event }) or
   onChange={(key_value: IClientSelectOptionV2) => {
   saveValueInStore({ value: { key_value } });
                        }}
 * @param {string} type
 * @return {*}
 */
const useSaveValueStore = (type: keyof typeof saveFunction) => {
  const dispatch = useDispatch();
  return useCallback(
    ({ value }: IValueForSaveInStore) => {
      dispatch(saveFunction[type](value));
    },
    [dispatch, type]
  );
};

export interface IValueForSaveInStore {
  event?: ChangeEvent<HTMLInputElement>;
  value?: IFormValuesTask | IFormValuesExternalRequest;
}
