import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class ClientComment<Pk = number> {
  public pk: Pk;
  public comment_text?: string = '';

  constructor(pk: Pk, comment_text?: string) {
    this.pk = pk;
    this.comment_text = comment_text;
  }

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      pk: Yup.mixed<number | string>(),
      comment_text: Yup.string()
        .trim()
        .min(1, t('Введите 1 или более букв.'))
        .required(t('Введите комментарий.')),
    });
  };
}

export default ClientComment;
