import React, { FC, memo } from 'react';

import { parseISO } from 'date-fns';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import HistoryRows from 'components/misc/WrapperHistoryRows/HistoryRows';
import HistoryContainerTable from 'components/tables/HistoryContaierTable/HistoryContainerTable';
import { formatDateTime } from '../../../../services/utils/dateHelper/dateHelper';
import {
  ICreationEventInfo,
  IEventListOfHistoryEntity,
} from 'services/api/organizationsV2/event/IEventHistory';

type PropTypes = {
  eventRequestHistory?: IEventListOfHistoryEntity[];
  creationEventInfo?: ICreationEventInfo;
  isLoading?: boolean;
};

// delete duplicate - create component container for history
const EventHistory: FC<PropTypes> = (props) => {
  const { eventRequestHistory, isLoading, creationEventInfo } = props;
  return (
    <HistoryContainerTable>
      {isLoading && <LoaderFetch />}
      {eventRequestHistory &&
        eventRequestHistory?.length > 0 &&
        !isLoading &&
        eventRequestHistory.map((record: IEventListOfHistoryEntity) => {
          return (
            !!record?.event_edited_column_to_old_and_new_values && (
              <HistoryRows
                editorFullName={record?.event_editor?.full_name}
                valuesRaw={record.event_edited_column_to_old_and_new_values}
                dateTimeEdit={record?.event_datetime_edited}
              />
            )
          );
        })}
      {creationEventInfo?.event_datetime_added &&
        creationEventInfo?.event_author_dk && (
          <tr>
            <td>
              {formatDateTime(
                parseISO(creationEventInfo?.event_datetime_added)
              )}
            </td>
            <td colSpan={4}>{'Cоздание'}</td>
            <td>{creationEventInfo?.event_author?.full_name}</td>
          </tr>
        )}
    </HistoryContainerTable>
  );
};

export default memo(EventHistory);
