import { useCallback, useEffect, useState } from 'react';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';

import useGetFetch from './useGetFetch';

interface IUseReadProps<Payload, ConvertData, Params> {
  getDataApi: (params: IBaseParams<Params>) => Promise<IBaseResponse<Payload>>;
  convertData: (data: Payload) => ConvertData;
  initialData: ConvertData;
  params: Params;
  condition?: boolean;
  errorMessage?: string;
  refreshProp?: boolean;
  successHandler?: () => void;
  warningHandler?: Function;
}

export interface IUseRead<ConvertData> {
  data: ConvertData;
  isLoading: boolean;
  setData: Function;
  refreshData: () => void;
  request: () => Promise<void>;
}

export function useRead<Payload, ConvertData, Params>(
  props: IUseReadProps<Payload, ConvertData, Params>
): IUseRead<ConvertData> {
  const {
    params,
    getDataApi,
    convertData,
    initialData,
    condition = true,
    errorMessage = 'Не удалось загрузить данные',
    successHandler,
    warningHandler,
    refreshProp,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ConvertData>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const getData = useGetFetch<Payload, Params>({
    getDataApi,
    params,
    errorMessage,
    successHandler,
    warningHandler,
  });

  const refreshData = () => setRefresh(!refresh);

  const processingRequest = useCallback(async () => {
    setIsLoading(true);
    try {
      if (condition) {
        const payload = await getData();
        payload && setData(convertData(payload));
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [convertData, getData, condition]);

  useEffect(() => {
    if (condition) {
      void processingRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, refresh, refreshProp]);

  return {
    data: data ? data : initialData,
    isLoading,
    setData,
    refreshData,
    request: processingRequest,
  };
}

export default useRead;
