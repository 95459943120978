import { IWrapperTask } from 'services/api/tasks/tasks/ITask';
import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import {
  IUpdateTask,
  ICreateTask,
} from '../../../../services/api/tasks/tasks/ICreateTask';
import { IFormValuesTask } from '../TaskForm';
import TaskFormValues from '../TaskFormValues';
import { useCallback } from 'react';

const useTaskFormUtils = () => {
  const checkEditPhoneNumber = useCallback(
    (initialPhoneNumber?: string, currentPhoneNumber?: string) => {
      return (
        !!initialPhoneNumber &&
        !!currentPhoneNumber &&
        convertToServerPhoneNumber(initialPhoneNumber) !==
          convertToServerPhoneNumber(currentPhoneNumber)
      );
    },
    []
  );

  const convertParams = useCallback(
    (values: IFormValuesTask): ICreateTask | IUpdateTask => {
      const newValues = new TaskFormValues(values);
      return newValues.getParams();
    },
    []
  );

  const extractedDisabledFieldTask = useCallback(
    (viewOnlyMode: boolean, isLoadingUpdateForm: boolean) => {
      return viewOnlyMode || isLoadingUpdateForm;
    },
    []
  );

  const extractHandlePayloadCreate = useCallback(
    (isSaveAndExit: boolean, nextEditPage: Function) =>
      (taskWrapper: IWrapperTask) => {
        if (!isSaveAndExit) {
          nextEditPage(taskWrapper.internal_task.internal_task_pk);
        }
      },
    []
  );

  const prepareValuesForComparison = useCallback(
    (currentValues: IFormValuesTask) => {
      const newObj = { ...currentValues };
      delete newObj?.internal_task_comment_text;
      return newObj;
    },
    []
  );

  const extractIsEditing = useCallback(
    (isNew: boolean) => (initialValue: any, currentValue: any) => {
      return !isNew && initialValue !== currentValue;
    },
    []
  );

  return {
    prepareValuesForComparison,
    extractIsEditing,
    extractHandlePayloadCreate,
    extractedDisabledFieldTask,
    convertParams,
    checkEditPhoneNumber,
  };
};

export default useTaskFormUtils
