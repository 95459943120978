import { Button } from 'reactstrap';
import { ReactComponent as CloseIcon } from 'img/icons/icon-padlock.svg';
import { ReactComponent as OpenIcon } from 'img/icons/icon-unlock.svg';
import { t } from 'services/utils/translation';
import { useFormikContext } from 'formik';
import ToolTipWrapper from '../ToolTipWrapper/ToolTipWrapper';

type PropsType = {
  isClosed: boolean;
  pk: number | string;
  handler: (IsClosed: boolean, pk: any) => Promise<void>;
};

const ButtonClose = <Values,>(props: PropsType) => {
  const { isClosed, pk, handler } = props;
  const { resetForm, isSubmitting } = useFormikContext<Values>();
  const color = isClosed ? 'app-red' : 'app-green';
  return (
    <Button
      color={color}
      id="closedButton"
      onClick={async () => {
        await handler(isClosed, pk);
        resetForm();
      }}
      disabled={isSubmitting}
    >
      {isClosed ? <CloseIcon /> : <OpenIcon />}
      <ToolTipWrapper target="closedButton" placement="right">
        {isClosed ? t('Открыть') : t('Закрыть')}
      </ToolTipWrapper>
    </Button>
  );
};

export default ButtonClose;
