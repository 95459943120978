import usePrepareLink from 'hooks/usePrepareLinks';
import { TAB_QUERY_PARAMS } from 'services/pathConstants';
import { TAB_QUERY_KEY } from '../../services/pathConstants';
import { t } from 'services/utils/translation';
import { parentUIElement } from 'services/constants/parentUIElement';
import useGetTotalBillingAccount from 'hooks/useGetTotalBillingAccount/useGetTotalBillingAccount';

export type TParentUIForCounterpartyTab = parentUIElement.counterparty;

interface IUseGetCounterpartyTab {
  pk: number;
  parentUI: TParentUIForCounterpartyTab;
}

export const useGetBillingAccountTab = ({
  pk,
  parentUI,
}: IUseGetCounterpartyTab) => {
  const { totalBillingAccount, isLoadingBillingAccount } =
    useGetTotalBillingAccount({
      pk,
      parentUI,
    });

  const tabKey = TAB_QUERY_KEY[parentUI];

  const billingAccountPatch = usePrepareLink({
    query: {
      [tabKey]: TAB_QUERY_PARAMS.billingAccount,
    },
  });

  const billingAccountTab = {
    name: t('Лицевые счета'),
    recordsTotal: totalBillingAccount,
    patch: billingAccountPatch,
  };

  return { billingAccountTab, isLoadingBillingAccount };
};
