import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import { IFormValuesTask } from '../../components/tasks/TaskForm/TaskForm';

import {
  extractClientSelectOptionsFromCounterpartyInEntity,
  toTitleBillingAccount,
} from '../../services/utils/bilingAccountUtils/billingAccountUtils';
import { createClientSelectOptionV2 } from '../../services/utils/selects/selects';
import { ITask } from '../../services/api/tasks/tasks/ITask';
import {
  formatDate,
  getCurrentDateToClientDate,
  parseDateFromServer,
} from '../../services/utils/dateHelper/dateHelper';
import { SELECTED_COUNTERPARTY_IN_TASK } from 'components/tasks/TaskForm/constants/selectedCounterparty';

const getParentTaskFk = (props: {
  isChild?: boolean;
  isDuplicate?: boolean;
  pk?: number;
  parentFk?: number;
}) => {
  const { parentFk, isDuplicate, isChild, pk } = props;
  if (isDuplicate && !isChild) {
    return undefined;
  }
  return isChild ? pk : parentFk;
};

function toFormValuesTask(
  task: ITask,
  isDuplicate?: boolean,
  isChild?: boolean
): IFormValuesTask {
  const {
    internal_task_parent_internal_task_fk,
    internal_task_pk,
    internal_task_parent_external_request_fk,
    internal_task_list_of_child_external_requests,
    internal_task_list_of_child_internal_tasks,
    internal_task_counterparty: counterparty,
  } = task;

  return {
    taskPk: !isDuplicate && !isChild ? task.internal_task_pk : undefined,
    internal_task_parent_internal_task_fk: getParentTaskFk({
      isDuplicate,
      pk: internal_task_pk,
      isChild,
      parentFk: internal_task_parent_internal_task_fk,
    }),

    internal_task_reporter_phone_number:
      task.internal_task_reporter_phone_number
        ? convertToServerPhoneNumber(
            task.internal_task_reporter_phone_number + ''
          )
        : undefined,
    internal_task_date_duedate:
      !isChild && task.internal_task_date_duedate
        ? formatDate(parseDateFromServer(task.internal_task_date_duedate))
        : getCurrentDateToClientDate(),
    status: !isChild
      ? {
          label: task.internal_task_status.internal_task_status_rendition,
          value: task.internal_task_status.internal_task_status_pk,
        }
      : undefined,

    internal_task_description: task?.internal_task_description,
    internal_task_time_adjustment: task?.internal_task_time_adjustment,
    taskType:
      task.internal_task_type != null && !isChild
        ? createClientSelectOptionV2(
            task.internal_task_type.internal_task_type_pk,
            task.internal_task_type.internal_task_type_rendition
          )
        : undefined,
    supervisor:
      task.internal_task_supervisor?.id && !isChild
        ? createClientSelectOptionV2(
            task.internal_task_supervisor.id,
            task.internal_task_supervisor.full_name
          )
        : undefined,
    account: task.internal_task_billing_account?.billing_account_pk
      ? createClientSelectOptionV2(
          task.internal_task_billing_account.billing_account_pk,
          toTitleBillingAccount(task.internal_task_billing_account)
        )
      : undefined,
    internal_task_author: task?.internal_task_author,
    internal_task_is_pruned: task?.internal_task_is_pruned,
    internal_task_parent_external_request_fk,
    childInternalTasks: internal_task_list_of_child_internal_tasks,
    childExternalRequests: internal_task_list_of_child_external_requests,
    counterparty:
      extractClientSelectOptionsFromCounterpartyInEntity(counterparty),
    selectedCounterparty: counterparty
      ? SELECTED_COUNTERPARTY_IN_TASK.selectedCounterparty
      : SELECTED_COUNTERPARTY_IN_TASK.selectedBillingAccount,
  };
}

export default toFormValuesTask;
