import {
  REMOVE_MENU,
  SET_MY_FAVORITE_LIST_FOLDER,
  SET_CURRENT_EMPLOYEE,
  SET_LOCALIZATION,
  REMOVE_CURRENT_EMPLOYEE,
  LOAD_MENU, SET_ERROR,
} from './actionTypes';

export interface ITab {
  name: string;
  recordsTotal?: number;
}

const generalReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SET_LOCALIZATION:
      return {
        ...state,
        localization: action.payload,
      };
    case SET_CURRENT_EMPLOYEE:
      return {
        ...state,
        currentEmployee: action.payload,
      };
    case REMOVE_CURRENT_EMPLOYEE:
      return {
        ...state,
        currentEmployee: {},
      };
    case LOAD_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case REMOVE_MENU: {
      return {
        ...state,
        menu: [],
      };
    }
    case SET_MY_FAVORITE_LIST_FOLDER: {
      return {
        ...state,
        myFavoriteMenuItems: action.payload,
      };
    }

    case SET_ERROR: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default:
      return state;
  }
};

export default generalReducer;
