import {
  CLEAR_TASK_FIELDS_VALUE,
  GET_TASK_V2,
  SET_ACCESS_POLICY_TASK,
  SET_DEFAULT_STATUS,
  SET_INITIAL_TASK,
  SET_IS_ACTIVE_COUNTERPARTY_FILTER,
  SET_MARKED_TASK,
  SET_TASK,
  SET_TASK_FIELDS_VALUE,
  TASK_IS_LOADING,
  TASKS_CLEAR_FILTERS,
  TASKS_SAVE_FILTERS,
} from './actionTypes';
import addUniqItems from '../utils/addUniqItems';
import { changeOfMarking } from '../utils/changeMark';

/**
 * Ticket reducer.
 *
 * Don't forget to initialize default values in ../CompaniesPage.tsx.
 *
 * @param state
 * @param action
 */
const tasksReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case TASKS_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case TASKS_CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.payload,
      };

    case SET_TASK: {
      const uniqTickets = addUniqItems(state.marked, action.payload);
      return {
        ...state,
        marked: uniqTickets,
      };
    }

    case SET_MARKED_TASK:
      const newTicketsMarked = changeOfMarking(state.marked, action.payload);
      return {
        ...state,
        marked: newTicketsMarked,
      };

    case GET_TASK_V2: {
      return {
        ...state,
        fieldValues: action.payload,
      };
    }

    case SET_TASK_FIELDS_VALUE: {
      return {
        ...state,
        fieldValues: {
          ...state.fieldValues,
          ...action.payload,
        },
      };
    }
    case CLEAR_TASK_FIELDS_VALUE: {
      return {
        ...state,
        fieldValues: {},
      };
    }
    case TASK_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case SET_INITIAL_TASK: {
      return {
        ...state,
        initialTask: action.payload,
      };
    }

    case SET_DEFAULT_STATUS: {
      return {
        ...state,
        defaultStatus: action.payload,
      };
    }
    case SET_IS_ACTIVE_COUNTERPARTY_FILTER: {
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          isActiveCounterpartyFilter: action.payload,
        },
      };
    }

    case SET_ACCESS_POLICY_TASK: {
      return {
        ...state,
        taskAccessPolicy: {
          isInstalled: true,
          accessPolicy: { ...action.payload },
        },
      };
    }
    default:
      return state;
  }
};
export default tasksReducer;
