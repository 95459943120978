import * as Yup from 'yup';
import { t } from 'services/utils/translation';

class ResponsibleDepartment {
  pk?: number;
  responsible_department_rendition?: string;
  constructor(pk?: number) {
    this.pk = pk;
  }
  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      responsible_department_rendition: Yup.string()
        .trim()
        .min(2, t('Введите 2 или более буквы.'))
        .required(t('Укажите название.')),
    });
  };
}

export default ResponsibleDepartment;
