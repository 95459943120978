import { FC, ReactElement, memo } from 'react';
import classNames from 'clsx';
import styles from './index.module.scss';
import { Button, ButtonProps } from 'reactstrap';
type PropsType = {
  handleClick: () => void;
  text: string;
  className?: string;
  iconAfter?: ReactElement;
  iconBefore?: ReactElement;
  isDisabled?: boolean;
} & ButtonProps;

const ButtonSubHeaderForm: FC<PropsType> = (props) => {
  const {
    handleClick,
    text,
    className,
    iconAfter,
    iconBefore,
    isDisabled = false,
  } = props;

  return (
    <Button
      size="sm"
      className={classNames(styles.button, className, 'mb-1')}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <div className="d-flex align-items-center justify-content-center">
        {iconBefore && (
          <div className={classNames(styles.icon)}>{iconBefore}</div>
        )}
        <span>{text}</span>
        {iconAfter && (
          <div className={classNames(styles.icon)}>{iconAfter}</div>
        )}
      </div>
    </Button>
  );
};

export default memo(ButtonSubHeaderForm);
