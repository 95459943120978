import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class TaskStatus {
  id?: number;
  name: string = '';
  color?: string = '';
  can_be_first?: boolean = false;
  can_be_last?: boolean = false;
  order?: number;

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      name: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .required(t('Укажите название.')),
    });
  };
}

export default TaskStatus;
