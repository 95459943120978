import {
  IFileBucketSchemaParams,
  IGetFileBucketSchema,
} from './IGetFileBucketSchema';
import UndercurrentApiClient from '../../UndercurrentApiClient';
import {
  ICreateFileBucketSchemaParams,
  IUpdateFileBucketSchemaParams,
} from './ICreateFileBucketSchema';
import {
  ISuccessfulResponse,
  IValidationError,
} from '../../interfacesApi/IV2ResponseStatus';
 import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IFileBucketSchema } from './IFileBucketSchema';
import TBaseOldPostResponse from 'services/api/interfacesApi/TBaseOldPostResponse';

export async function getFileBucketSchemaApi(
  pk: number
): Promise<TBaseOldPostResponse<IFileBucketSchema>> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET_SCHEMA
  );
  return api.read(pk);
}

export async function getListFileBucketSchemaApi(
  params: IFileBucketSchemaParams
): Promise<IGetFileBucketSchema> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET_SCHEMA
  );
  return api.getPaginated(params);
}

export async function createFileBucketSchemaApi(
  request: ICreateFileBucketSchemaParams
): Promise<ISuccessfulResponse<IFileBucketSchema>> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET_SCHEMA
  );
  return await api.create(request);
}

export async function updateFileBucketSchemaApi(
  request: IUpdateFileBucketSchemaParams
): Promise<ISuccessfulResponse<IFileBucketSchema>> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET_SCHEMA
  );

  return await api.update(request);
}

export async function deleteFileBucketSchemaApi(
  pk: number
): Promise<ISuccessfulResponse<IFileBucketSchema> | IValidationError> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET_SCHEMA
  );
  return api.delete(pk);
}
