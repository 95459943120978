import * as Yup from 'yup';
import { getValueOfYupField } from './utils/getМalueOfAnotherField';

//TODO: need refactoring
export function requiredByCondition(
  this: Yup.AnySchema,
  isRequired: boolean,
  isHasValue: <T>(value: T) => boolean,
  msg?: string
) {
  return this.test({
    message: msg || 'msg',
    name: 'requiredByCondition',
    test<T>(value: T) {
      return isRequired ? isHasValue(value) : true;
    },
  });
}

export interface IParamsRequiredByConditionWithRef {
  keyField: string;
  getCondition: (refValue: any, curInstance: any) => boolean;
  precondition?: boolean;
}

export interface IParamsRequiredByConditionWithDoubleRef {
  keyFields: [string, string];
  getCondition: (refValue: any, curInstance: any) => boolean;
  isHasValue?: (value: any) => boolean;
}

export function requiredByConditionWithDoubleRef(
  this: Yup.AnySchema,
  {
    keyFields,
    getCondition,
    isHasValue,
  }: IParamsRequiredByConditionWithDoubleRef,
  msg?: string
) {
  return this.test({
    message: msg || 'msg',
    name: 'requiredByConditionWithDoubleRef',
    test<T>(value: T) {
      const valueFirstField = getValueOfYupField(keyFields[0])(this);
      const valueSecondField = getValueOfYupField(keyFields[0])(this);
      if (getCondition(valueFirstField, valueSecondField)) {
        return true;
      } else {
        return isHasValue ? isHasValue(value) : false;
      }
    },
  });
}

export function requiredByConditionWithRef(
  this: Yup.AnySchema,
  { keyField, getCondition, precondition }: IParamsRequiredByConditionWithRef,
  msg?: string
) {
  return this.test({
    message: msg || 'msg',
    name: 'requiredByConditionWithRef',
    // params: { valueAnother: getValueOfYupField(keyField)(this) },
    test<T>(value: T) {
      if (precondition === true) {
        return true;
      } else {
        return getCondition(getValueOfYupField(keyField)(this), value);
      }
    },
  });
}
