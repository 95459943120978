import { FC, useState } from 'react';
import config from 'config.json';
import { IUseGetList, useGetList } from '../../../hooks/useGetList';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { t } from 'services/utils/translation';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import { useColumnFilter } from '../../../hooks/useColumnFilter';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import LoaderFetch from '../../../components/layouts/LoaderFetch/LoaderFetch';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import { Pager } from '../../../components/tables/Pager';
import useModalV2 from 'hooks/useModalV2';
import { IModal } from 'hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import IBillingAccountType from '../../../services/api/organizationsV2/billing-account-type/IBillingAccountType';
import {
  IGetBillingAccountTypeParams,
  SortCriteriaBillingAccountType,
} from '../../../services/api/organizationsV2/billing-account-type/IBillingAccountTypeGetPaginatedParams';
import {
  getPaginatedBillingAccountTypeApiV2,
  deleteBillingAccountTypeApiV2,
} from '../../../services/api/organizationsV2/billing-account-type/billingAccountTypaApi';
import BillingAccountTypeForm from 'components/organizations/billing-account-type/BillingAccountTypeForm';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyBillingAccount } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { UI_TITLE } from '../../../services/localLocalization/UITitle';
import { extractGetLeftValueIfTrue } from '../../../services/utils/extractGetLeftValueIfTrue';
import classNames from 'clsx';

const deleteMessageError = t('Не удалось удалить тип лицевого счета');
const CREATE_BUTTON_TITLE = t('Добавить тип');
const MODAL_CREATE_TITLE = t('Создать тип лицевого счета');
const MODAL_EDITING_TITLE = t('Редактировать тип лицевого счета');
const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDITING_TITLE,
  MODAL_CREATE_TITLE
);
const MODAL_DELETE_TITLE = t('Удалить тип лицевого счета');

const COLUMN_LABELS = {
  PK: '№',
  RENDITION: 'Тип',
};

const filters = [
  { name: 'billing_account_type_pk', label: COLUMN_LABELS.PK },
  {
    name: 'billing_account_type_rendition',
    label: COLUMN_LABELS.RENDITION,
  },
] as const;
const columnVisibilityInitial = {
  billing_account_type_pk: true,
  billing_account_type_rendition: true,
};

const BillingAccountTypePage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial);

  const [initialParams] = useState<IGetBillingAccountTypeParams>({
    skip: 0,
    length: config.pageLength,
  });

  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyBillingAccount,
      targetAction: ['U', 'C', 'D'],
    });

  const {
    data: billingAccountTypeList,
    isLoading,
    refreshListData,
    total,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    params: {
      skip,
      length,
      billing_account_type_pk: filterPk,
      billing_account_type_rendition: filterRendition,
    },
  }: IUseGetList<
    IBillingAccountType[],
    IGetBillingAccountTypeParams
  > = useGetList({
    getDataApi: getPaginatedBillingAccountTypeApiV2,
    initialParams,
  });

  const {
    state: {
      isAddEditFormOpen,
      isDeleteFormOpen,
      entity: targetBillingAccount,
    },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteBillingAccountTypeApiV2,
    entityIdKey: 'pk',
  });

  return (
    <MainLayout>
      <Subheader>
        <div style={{ display: 'flex' }} className="mr-5">
          {haveAccessToCreate && (
            <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
              <AddIcon className="mr-2 text-white" />
              {CREATE_BUTTON_TITLE}
            </SubheaderButton>
          )}
        </div>
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={classNames('id pr-0 ', {
                  'd-none': !visibility.billing_account_type_pk,
                })}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaBillingAccountType.NumberAsc}
                  title={COLUMN_LABELS.PK}
                  onSort={onSortClick}
                  onSearch={(searchString: string) =>
                    onSearchRequest(searchString, 'billing_account_type_pk')
                  }
                  defaultValue={filterPk ? filterPk + '' : ''}
                  isOnlyNumbers
                />
              </th>

              <th
                scope="col"
                className={classNames('p-0', {
                  'd-none': !visibility.billing_account_type_rendition,
                })}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaBillingAccountType.RenditionAsc}
                  title={COLUMN_LABELS.RENDITION}
                  onSearch={(searchString: string) => {
                    onSearchRequest(
                      searchString,
                      'billing_account_type_rendition'
                    );
                  }}
                  onSort={onSortClick}
                  defaultValue={filterRendition}
                />
              </th>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {billingAccountTypeList.map(
                (targetBillingAccount: IBillingAccountType) => {
                  const {
                    billing_account_type_pk: pk,
                    billing_account_type_rendition: rendition,
                  } = targetBillingAccount;
                  return (
                    <tr key={pk}>
                      <td
                        className={classNames({
                          'd-none': !visibility.billing_account_type_pk,
                        })}
                      >
                        <span>{pk}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.billing_account_type_rendition,
                        })}
                      >
                        <span>{rendition}</span>
                      </td>
                      <td align="right">
                        <ActionsDropdown>
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={UI_TITLE.EDIT}
                              onClick={openAddEditForm.bind(null, {
                                pk,
                                rendition,
                              })}
                              icon={<EditIcon className="text-primary" />}
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                rendition,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          )}
        </table>

        <LoaderFetch />
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />
        <ModalWrapper
          headerLabel={getModalTitle(!!targetBillingAccount?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <BillingAccountTypeForm
            successHandler={successHandler}
            pk={targetBillingAccount?.pk}
          />
        </ModalWrapper>

        {targetBillingAccount?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetBillingAccount?.pk}
            entityName={targetBillingAccount?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default BillingAccountTypePage;
