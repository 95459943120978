export type RegisterPayload = IIdentity;

export interface IIdentity {
  identity_pk: number;
  identity_is_active: boolean;
  identity_datetime_added: string;
  identity_datetime_edited: string;
  identity_phone_number: string;
  identity_slug: string;
  identity_nickname: string;
  identity_last_name: string;
  identity_first_name: string;
  identity_middle_name: string;
  identity_is_admin: boolean;
  identity_list_of_applicable_installation_pks: number[];
  identity_list_of_applicable_installations: IIdentityListOfApplicableInstallationsEntity[];
  identity_list_of_owned_installations: IIdentityListOfApplicableInstallationsEntity[];
  identity_list_of_owned_installation_pks: number[];
  identity_list_of_installations_with_pending_invite: IIdentityListOfApplicableInstallationsEntity[];
  identity_list_of_installation_pks_can_invite_to: number[];
  identity_list_of_installations_can_invite_to: IIdentityListOfApplicableInstallationsEntity[];
}

export interface IIdentityListOfApplicableInstallationsEntity {
  installation_name: string;
  installation_description?: string;
  installation_company_name: string;
  installation_pk: number;
  installation_is_active: boolean;
  installation_datetime_added: string;
  installation_datetime_edited: string;
}

export enum InstallationListKeys {
  installations = 'identity_list_of_applicable_installations',
  canInvite = 'identity_list_of_installations_can_invite_to',
  owned = 'identity_list_of_owned_installations',
  invite = 'identity_list_of_installations_with_pending_invite',
  none = 'none',
}
