import useEventToClosed from '../../../../hooks/useEventToClosed/useEventToClosed';
import { useCallback } from 'react';

const useToCloseEvent = () => {
  const closeHandler = useEventToClosed();

  return useCallback(
    async (eventIsClosed: boolean, pk: number) => {
      await closeHandler({
        event_is_closed: !eventIsClosed,
        pk,
      });
    },
    [closeHandler]
  );
};

export default useToCloseEvent;
