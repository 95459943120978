import { toast } from 'react-toastify';
import { t } from '../services/utils/translation';
import { useCallback } from 'react';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import createTimestamp from '../services/utils/createTimestamp/createTimestamp';
import { IBaseResponse } from '../services/api/interfacesApi/IBaseResponse';
import {
    catchErrorFromFetch,
    catchErrorsIfServerStatusFalse
} from "../services/utils/catchAndRegError/catchAndRegError";

interface IProps<Params, Payload> {
  convertParams?: (params: any) => Params; //если нужно конвертировать параметры перед отправкой
  fetchApi: (params: IBaseParams<Params>) => Promise<IBaseResponse<Payload>>; // функция для запроса
  errorMessage?: string; // свой текст в тосте об ошибке
  successMessage?: string; // свой текст в тосте об успехе
  successHandler?: () => void; // функция после успешного запроса
  errorHandler?: Function; // функция после ошибки
  onStep?: (params: Params) => void; // функция для перехода по роутингу на шаг
  handlePayload?: (payload: Payload) => void | Promise<void>; // функция для обрабатывания полученных данных
  viewToast?: boolean; //нужно ли показывать тост
  viewClientTextToast?: boolean; //показать клиентский тост или от сервера // TODO: нужно удалить лишние параметры. показывать клиентский тост  если есть параметр  successMessage
}

export function usePostV3<Payload, Params>(props: IProps<Params, Payload>) {
  const {
    fetchApi,
    errorMessage = 'Не удалось отправить данные',
    successMessage = 'Данные отправлены успешно',
    successHandler,
    errorHandler,
    convertParams,
    onStep,
    handlePayload,
    viewToast = true,
    viewClientTextToast = false,
  } = props;

  return useCallback(
    async (params: Params, setErrors?: Function, resetForm?: Function) => {
      const newParams: Params = convertParams ? convertParams(params) : params;

      const fullErrorMessage = `${errorMessage}. Обратитесь, пожалуйста, к администратору`;
      try {
        const response: IBaseResponse<Payload> = await fetchApi({
          id: createTimestamp(),
          params: newParams,
        });
        const {
          result: { verbose, payload, message, outcome },
        } = response;

        if (outcome === 'WARNING') {
          catchErrorsIfServerStatusFalse(
            verbose ? verbose : errorMessage,
            message,
            setErrors
          );
          errorHandler && errorHandler();
          return;
        }
        const isSuccessMessageFromServer = !!verbose && !viewClientTextToast;

        onStep && onStep(newParams);
        handlePayload && payload && (await handlePayload(payload));
        viewToast &&
          toast(t(isSuccessMessageFromServer ? verbose : successMessage));
        successHandler && successHandler();
        resetForm && resetForm({});
      } catch (error) {
        catchErrorFromFetch(fullErrorMessage, error);
        errorHandler && errorHandler();
      }
    },
    [
      convertParams,
      errorMessage,
      fetchApi,
      viewClientTextToast,
      onStep,
      handlePayload,
      viewToast,
      successMessage,
      successHandler,
      errorHandler,
    ]
  );
}
