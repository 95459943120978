import { ReactComponent as SortIcon } from 'img/icons/icon-sort.svg';
import { FC, useState, memo } from 'react';
import SortOrderMarker from 'services/api/SortOrderMarker';
import styles from './index.module.scss';

type PropsType = {
  onSort: Function;
  title?: string;
  field: string;
  className?: string;
  backGroundGrey?: boolean;
};

const cutFilter = (str: string) => str.replace(/filter_/, '');

const getSortCriterion = (
  field: string,
  sortMarker: SortOrderMarker
): string => {
  const newField = cutFilter(field);
  return sortMarker + newField;
};
const HeaderWithSort: FC<PropsType> = (props) => {
  const { title, field, onSort, className } = props;

  const [sortOrder, setSortOrder] = useState(SortOrderMarker.Desc);

  const sortOrderClass = sortOrder === SortOrderMarker.Asc ? 'desc' : 'asc';

  return (
    <div
      className={`d-flex flex-row flex-nowrap justify-content-between ${
        className ? className : ''
      }`}
    >
      {title && (
        <div className="d-flex flex-column justify-content-center">{title}</div>
      )}
      <div className={`d-flex flex-column justify-content-center`}>
        <button
          className={`${styles.sort} ${
            styles[sortOrderClass] ? styles[sortOrderClass] : ''
          }`}
          onClick={(e?: any) => {
            e.preventDefault();

            setSortOrder((order: SortOrderMarker) => {
              return order === SortOrderMarker.Asc
                ? SortOrderMarker.Desc
                : SortOrderMarker.Asc;
            });
            onSort(getSortCriterion(field, sortOrder));
          }}
        >
          <div className={styles.thIcon}>
            <SortIcon />
          </div>
        </button>
      </div>
    </div>
  );
};

export default memo(HeaderWithSort);
