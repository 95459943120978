import { EMPLOYEE_SAVE_FILTERS, EMPLOYEE_CLEAR_FILTERS } from './actionTypes';

const employeeReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case EMPLOYEE_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case EMPLOYEE_CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.filters,
      };

    default:
      return state;
  }
};

export default employeeReducer;
