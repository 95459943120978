import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_TASK_TABLE } from 'services/pathConstants';

const useTaskSuccessHandler = (to: string = PATH_TASK_TABLE) => {
  const navigate = useNavigate();
  const location = useLocation();
  const wherePath = location?.state?.['from'];

  return useCallback(
      (isSaveNadExit: boolean) => {
          if (isSaveNadExit) {
              wherePath ? navigate(wherePath) : navigate(to);
          }
      },
      [navigate, to, wherePath]
  );
};

export default useTaskSuccessHandler;
