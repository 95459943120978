import { IFileBucketSchema } from 'services/api/documents/file-bucket-schema/IFileBucketSchema';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';

const convertListSchemes = (list: IFileBucketSchema[]) => {
  return list
    ? list.map((el: IFileBucketSchema) =>
        createClientSelectOptionV2(el.pk, el.file_bucket_schema_name)
      )
    : [];
};

export default convertListSchemes;
