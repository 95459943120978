import React, { FC, useCallback, useRef } from 'react';
import classNames from 'clsx';
import ButtonSubHeaderForm from '../ButtonSubHeaderForn/ButtonSubHeaderForm';
import { ReactComponent as ArrowIcon } from '../../../img/icons/icon-top-arrow.svg';
import { ReactComponent as CancelIcon } from '../../../img/icons/icon-cancel-custom-color.svg';

import styles from './index.module.scss';
import IconWitchDescription from 'components/misc/IconWitchDescription/IconWitchDescription';
import { t } from 'services/utils/translation';

type PropsTypeButtonSubHeaderFormForPopout = {
  buttonDefaultText: string;
  isOpen: boolean;
  setIsOpen: (input?: boolean | undefined) => void;
  handlerForBeforeIcon?: () => void;
  isViewIconBefore?: boolean;
};

const ButtonSubHeaderFormForPopout: FC<
  PropsTypeButtonSubHeaderFormForPopout
> = (props) => {
  const refIcon = useRef(null);

  const {
    buttonDefaultText,
    isOpen,
    setIsOpen,
    handlerForBeforeIcon,
    isViewIconBefore,
  } = props;

  const handleWitchStopPropagationBeforeIcon = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();
      handlerForBeforeIcon && handlerForBeforeIcon();
    },
    [handlerForBeforeIcon]
  );

  return (
    <ButtonSubHeaderForm
      iconBefore={
        isViewIconBefore ? (
          <IconWitchDescription hintText={t('Удалить родителя')}>
            <div className="d-flex align-items-center">
              <div ref={refIcon}>
                <CancelIcon
                  className={classNames(
                    'ml-2',
                    styles.buttonIcon,
                    styles.rotateIconBefore
                  )}
                  onClick={handleWitchStopPropagationBeforeIcon}
                />
              </div>
              <div className={classNames(styles['verticaLine'])} />
            </div>
          </IconWitchDescription>
        ) : undefined
      }
      iconAfter={
        <ArrowIcon
          className={classNames('ml-2', styles.buttonIcon, {
            [styles.rotateIcon]: isOpen,
          })}
        />
      }
      handleClick={() => setIsOpen(!isOpen)}
      className={classNames(styles.button, {
        [styles.isSelectedButton]: isOpen,
      })}
      text={buttonDefaultText}
    />
  );
};

export default ButtonSubHeaderFormForPopout;
