import {
  IExternalRequest,
  IExternalRequestForList,
} from '../../../../../../services/api/external-request/external-request/IExternalRequest';
import { PATH_EXTERNAL_REQUEST_EDIT } from '../../../../../../services/pathConstants';
import { parseISO } from 'date-fns';
import ColoredStatusLabel from '../../../../../../components/misc/ColoredStatusLabel';
import { IPersonV2WithPosition } from '../../../../../../services/interfaces/IPersonV2';
import getInitials from '../../../../../../services/utils/getInicials/getInicials';
import React from 'react';
import ParentAndChildrenLinks from 'components/tables/ParentAndChildrenList/ParentAndChildrenLinks';
import { PATH_TASK_EDIT } from 'services/pathConstants';
import TDCheckBox from 'components/tables/TDCheckBox/TDCheckBox';
import {
  formatDate,
  formatDateTimeShort,
} from '../../../../../../services/utils/dateHelper/dateHelper';
import { isPrunedToText } from '../../../../../../services/utils/stringHelper/extractBooleanToText/extractBooleanToText';
import CounterpartyFromEntitiesLink from 'components/misc/CounterpartyFromEntitiesLink/CounterpartyFromEntitiesLink';

export const toExternalRequestForList = (
  externalRequest: IExternalRequest[]
): IExternalRequestForList[] => {
  return externalRequest.map((externalRequest: IExternalRequest) => {
    const {
      external_request_datetime_added: dateAdded,
      external_request_pk: pk,
      external_request_date_supposed_duedate: duedate,
      external_request_status: status,
      external_request_datetime_edited: editedDate,
      external_request_list_of_implementers: implementers,
      external_request_parent_internal_task_fk: parentTaskFk,
      external_request_parent_external_request_fk: parentExternalRequestFk,
      external_request_list_of_child_external_requests: childExternalRequests,
      external_request_list_of_child_internal_tasks: childTasks,
      external_request_affiliate: affiliate,
      external_request_is_pruned: isPruned,
      external_request_billing_account: billingAccount,
      external_request_event: event,
    } = externalRequest;

    const isPrunedHint = isPrunedToText(isPruned);
    return {
      billingAccount,
      event,
      ...externalRequest,
      isPrunedText: (
        <TDCheckBox hint={isPrunedHint} id={pk} isCheck={isPruned} />
      ),
      pk,
      createDate: formatDateTimeShort(parseISO(dateAdded)),
      duedate: duedate && formatDate(parseISO(duedate)),
      editedDate: editedDate && formatDateTimeShort(parseISO(editedDate)),
      statusLabel: (
        <ColoredStatusLabel color={status.color}>
          {status.name}
        </ColoredStatusLabel>
      ),
      implementersWithPositions:
        implementers &&
        implementers.map(
          (implementer: IPersonV2WithPosition) =>
            `${getInitials({
              firstName: implementer?.first_name,
              lastName: implementer?.last_name,
              middleName: implementer?.middle_name,
            })} ${
              implementer?.position ? '(' + implementer?.position + ')' : ''
            }`
        ),
      affiliateLink: <CounterpartyFromEntitiesLink counterparty={affiliate} />,
      parentAndChildTaskLinks: (
        <ParentAndChildrenLinks
          listOfChildren={childTasks}
          parentFk={parentTaskFk}
          path={PATH_TASK_EDIT}
        />
      ),
      parentAndChildExternalRequestLinks: (
        <ParentAndChildrenLinks
          listOfChildren={childExternalRequests}
          parentFk={parentExternalRequestFk}
          path={PATH_EXTERNAL_REQUEST_EDIT}
        />
      ),
    };
  });
};
