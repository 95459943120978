import styles from './index.module.scss';
import classNames from 'clsx';
import { FC, useMemo } from 'react';
import { t } from 'services/utils/translation';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import useModalV2 from 'hooks/useModalV2';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import { useColumnFilter } from 'hooks/useColumnFilter';
import { IUseGetList, useGetList } from 'hooks/useGetList';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import {
  BookingSortCriteria,
  IBookingFilters,
  IGetPaginatedParamsBooking,
} from '../../../services/api/vehicle/booking/IGetPaginatedParamsBooking';
import {
  getPaginatedBookingApiV2,
  deleteBookingApiV2,
} from '../../../services/api/vehicle/booking/bookingApi';
import { Button } from 'reactstrap';
import Subheader from 'components/layouts/Subheader/Subheader';
import useBookingHandleSearch from './useBookingHandleSearch';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { BookingForm } from 'components/vehicle/BookingForm/BookingForm';
import toBookingForList from './toBookingForList';
import { IBookingForList } from '../../../services/api/vehicle/booking/IBooking';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { IModalV2 } from '../../../hooks/useModalV2';
import { UI_TITLE } from '../../../services/localLocalization/UITitle';
import { extractGetLeftValueIfTrue } from '../../../services/utils/extractGetLeftValueIfTrue';
import HeaderWithDynamicSelectPaginatedV2 from 'components/tables/HeaderWithDynamicSelectPaginatedV2/HeaderWithDynamicSelectPaginatedV2';
import { getForSelectVehicleApiV2 } from 'services/api/vehicle/vehicle/vehicleApi';
import { composeClientSelectOptionsInNumbersArray } from 'services/utils/selects/composeIClientSelectOptionsInNumbers';
import { HEADER_SELECT_COLOR } from '../../../hooks/useGetSelectStyle';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingFiltersFromStore } from 'store/booking/selectors';
import isEqual from 'react-fast-compare';
import useClearFilters from 'hooks/useClearFilters';
import { initialBookingFilters } from 'store/initialStore/initialBookingFilters';
import { clearBookingFilters, saveBookingFilters } from 'store/booking/actions';
import SubHeaderWrapper from 'components/tables/SubHeaderGreyWrapper/SubHeaderGreyWrapper';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import WrapperGrayTable from 'components/tables/WrapperGrayTable/WrapperGrayTable';
import RefreshButton from 'components/tables/RefreshButton/RefreshButton';
import deleteUnnecessaryParameters from '../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import useGetBookingAccessPolicy from './hooks/useGetBookingAccessPolicy';

const deleteMessageError = t('Не удалось удалить бронь');
const MAIN_TITLE = t('Бронирование машин');
const CREATE_BUTTON_TITLE = t('Добавить машину');
const MODAL_EDIT_TITLE = t('Редактировать бронь');
const MODAL_CREATE_TITLE = t('Забронировать');
const MODAL_DELETE_TITLE = t('Удалить бронь');

const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);

const COLUMN_LABELS_BOOKING = {
  PK: '№',
  VEHICLE: 'Транспорт',
  PERIOD_START: 'Начало бронирования',
  PERIOD_END: 'Окончание бронирования',
  EXTERNAL_REQUEST: 'Заявка',
};

const filters = [
  { name: 'pk', label: COLUMN_LABELS_BOOKING.PK },
  {
    name: 'vehicle',
    label: COLUMN_LABELS_BOOKING.VEHICLE,
  },
  {
    name: 'periodStart',
    label: COLUMN_LABELS_BOOKING.PERIOD_START,
  },
  {
    name: 'periodEnd',
    label: COLUMN_LABELS_BOOKING.PERIOD_END,
  },
  {
    name: 'externalRequest',
    label: COLUMN_LABELS_BOOKING.EXTERNAL_REQUEST,
  },
] as const;
const columnVisibilityInitial = {
  pk: true,
  vehicle: true,
  periodStart: true,
  periodEnd: true,
  externalRequest: true,
};

const convertedParameters = (
  params: IBookingFilters
): IGetPaginatedParamsBooking => {
  const { booking_external_request_fk, booking_pk } = params;
  const { booking_vehicle_fk_array } = composeClientSelectOptionsInNumbersArray(
    params,
    ['booking_vehicle_fk_array']
  );
  const newParams = {
    ...params,
    booking_pk: booking_pk ? +booking_pk : undefined,
    booking_vehicle_fk_array,
    booking_external_request_fk: booking_external_request_fk
      ? +booking_external_request_fk
      : undefined,
  };
  return deleteUnnecessaryParameters(newParams);
};

type PropsType = {
  externalRequest?: IClientSelectOptionV2<number>;
  refreshTabs?: () => void;
  isViewOnly?: boolean;
  isHaveBooking?: boolean;
};

const BookingPage: FC<PropsType> = (props) => {
  const {
    externalRequest,
    refreshTabs,
    isViewOnly = false,
    isHaveBooking = false,
  } = props;

  const dispatchRedux = useDispatch();

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial, 'bookingColumnFilter');

  const {
    vehicleHaveAccessToRead,
    booking: { haveAccessToCreate, haveAccessToUpdate, haveAccessToDelete },
  } = useGetBookingAccessPolicy();

  const defaultBookingFilters = useSelector(
    getBookingFiltersFromStore,
    isEqual
  );

  const initialParams: IBookingFilters = useMemo(
    () => ({
      ...defaultBookingFilters,
      booking_external_request_fk: externalRequest?.value,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const saveFilters = (filters: IBookingFilters) =>
    dispatchRedux(saveBookingFilters(filters));

  const onFinally = () => setClearingFiltersSpinner(false);

  const {
    data: bookingList,
    isLoading,
    refreshListData,
    total,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    setParams,
    params: {
      booking_pk: pkFilter,
      booking_vehicle_fk_array: vehicleFkArrayFilter,
      booking_external_request_fk: externalRequestFkFilter,
      length,
      skip,
    },
    refreshAndReturnToStartPosition,
    params,
  }: IUseGetList<IBookingForList[], IBookingFilters> = useGetList({
    getDataApi: getPaginatedBookingApiV2,
    initialParams,
    convertedParameters,
    convertData: toBookingForList,
    onFinally,
    saveFilters,
  });
  const {
    clearFilters,
    isDisableClearButton,
    // clearingFiltersSpinner,
    setClearingFiltersSpinner,
  } = useClearFilters(
    initialBookingFilters,
    params,
    clearBookingFilters,
    setParams
  );

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetBooking },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModalV2<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteBookingApiV2,
    entityIdKey: 'pk',
  });

  const thisLocationIsExternalRequestPage = !!externalRequest;

  const newSuccessHandler = () => {
    successHandler();
    refreshTabs && refreshTabs();
  };

  const {
    handleSearchExternalRequestFk,
    handleSearchVehicleFkArray,
    handleSearchPk,
  } = useBookingHandleSearch(onSearchRequest);

  return (
    <MainLayout isInWrapper={thisLocationIsExternalRequestPage}>
      {!thisLocationIsExternalRequestPage && <Subheader />}
      <SubHeaderWrapper mainTitle={MAIN_TITLE}>
        {!isHaveBooking && (
          <>
            <RefreshButton
              toggleRefresh={refreshAndReturnToStartPosition}
              isLoading={isLoading}
              color={TABLE_COLOR.GREY}
            />

            <SubheaderButton
              onClick={clearFilters}
              bordered={false}
              disabled={isDisableClearButton}
              color={TABLE_COLOR.GREY}
            >
              {UI_TITLE.REMOVE_FILTERS}
            </SubheaderButton>
          </>
        )}

        {haveAccessToCreate && isHaveBooking && (
          <div className={styles.buttonWrapperAddTransport}>
            <Button
              style={{ lineHeight: 0.9 }}
              color="info"
              onClick={() => {
                openAddEditForm();
              }}
              disabled={isViewOnly}
            >
              <span style={{ fontSize: ' 2em' }}>+</span>
            </Button>
            <span className={styles.description}>{CREATE_BUTTON_TITLE}</span>
          </div>
        )}
      </SubHeaderWrapper>
      <WrapperGrayTable
        pagerProps={{
          onPageChange: setStart,
          onLengthChange: setLength,
          total: total,
          length,
          start: skip,
          saveFilters,
        }}
      >
        <table className="table table-bordered  table-hover table-responsive-md table-hover">
          <thead>
            <tr className={styles.tableHeader}>
              <th
                scope="col"
                style={{ width: '6%' }}
                className={classNames('p-2', {
                  'd-none': !visibility.pk,
                })}
              >
                <HeaderWithSearchAndSort
                  field={BookingSortCriteria.PkAsc}
                  title={COLUMN_LABELS_BOOKING.PK}
                  onSort={onSortClick}
                  onSearch={handleSearchPk}
                  defaultValue={pkFilter ? pkFilter + '' : ''}
                  customStyle={{ marginLeft: '0px' }}
                  headerColor={TABLE_COLOR.GREY}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                style={{ width: '40%' }}
                className={classNames(
                  // 'p-2',

                  {
                    'd-none': !visibility.vehicle,
                  }
                )}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  id="booking_vehicle_fk_array"
                  selectHandler={getForSelectVehicleApiV2}
                  title={COLUMN_LABELS_BOOKING.VEHICLE}
                  isMulti={true}
                  options={vehicleFkArrayFilter}
                  onChange={handleSearchVehicleFkArray}
                  headerColor={HEADER_SELECT_COLOR.GREY}
                  haveReadPermission={vehicleHaveAccessToRead}
                />
              </th>
              <th
                scope="col"
                className={classNames({
                  'd-none': !visibility.externalRequest,
                })}
              >
                <HeaderWithSearchAndSort
                  field={BookingSortCriteria.ExternalRequestFkAsc}
                  title={COLUMN_LABELS_BOOKING.EXTERNAL_REQUEST}
                  onSort={onSortClick}
                  onSearch={handleSearchExternalRequestFk}
                  defaultValue={
                    externalRequestFkFilter ? externalRequestFkFilter + '' : ''
                  }
                  disabled={!!externalRequest?.value}
                  headerColor={TABLE_COLOR.GREY}
                />
              </th>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {bookingList &&
                bookingList.map((booking: IBookingForList) => {
                  const {
                    bookingPk,
                    vehicleTdContentElement,
                    externalRequestLink,
                  } = booking;
                  return (
                    <tr key={bookingPk}>
                      <td
                        align="center"
                        className={!visibility.pk ? 'd-none' : ''}
                      >
                        {bookingPk}
                      </td>
                      <td className={!visibility.vehicle ? 'd-none' : ''}>
                        {vehicleTdContentElement}
                      </td>

                      <td
                        className={!visibility.externalRequest ? 'd-none' : ''}
                      >
                        {externalRequestLink}
                      </td>
                      <td align="center">
                        {!isViewOnly && (
                          <ActionsDropdown>
                            {haveAccessToUpdate && (
                              <ActionsDropdownItem
                                label={UI_TITLE.EDIT}
                                onClick={openAddEditForm.bind(null, {
                                  pk: bookingPk,
                                  rendition: `№${bookingPk}`,
                                })}
                                icon={<EditIcon className="text-primary" />}
                              />
                            )}
                            {haveAccessToDelete && (
                              <ActionsDropdownItem
                                label={UI_TITLE.DELETE}
                                onClick={toggleDeleteForm.bind(null, {
                                  pk: bookingPk,
                                  rendition: `№${bookingPk}`,
                                })}
                                icon={<DeleteIcon className="text-primary" />}
                              />
                            )}
                          </ActionsDropdown>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
      </WrapperGrayTable>
      {isLoading && <LoaderFetch />}
      <ModalWrapper
        headerLabel={getModalTitle(!!targetBooking?.pk)}
        isOpen={isAddEditFormOpen}
        closeHandler={closeAddEditForm}
      >
        <BookingForm
          successHandler={newSuccessHandler}
          pk={targetBooking?.pk}
          externalRequestPk={externalRequest?.value}
          isViewOnly={isViewOnly}
          isHaveBooking={isHaveBooking}
        />
      </ModalWrapper>
      {targetBooking?.pk && (
        <PopupDeleteForm
          headerLabel={MODAL_DELETE_TITLE}
          entityId={targetBooking?.pk}
          entityName={targetBooking?.rendition}
          deleteHandler={entityDeleteHandler}
          isOpen={isDeleteFormOpen}
          toggleHandler={toggleDeleteForm}
          deleteMessageError={deleteMessageError}
          successDeleteHandler={refreshTabs}
        />
      )}
    </MainLayout>
  );
};

export default BookingPage;
