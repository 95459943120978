import { useSelector } from 'react-redux';
import { getIdentityPkFromStore, getInstallationPkFromStore } from "../store/auth/selectors";

const useCreateUniqueIdKey = (additionalId?: string | number) => {
  const installationPk = useSelector(getInstallationPkFromStore);
  const identityPk = useSelector(getIdentityPkFromStore);
  const baseUniqKey = `${identityPk}-${installationPk}`;
  return additionalId ? `${additionalId}-${baseUniqKey}` : baseUniqKey;
};

export default useCreateUniqueIdKey;
