import CloseButton from 'components/controls/CloseButton';
import React, { FC, memo, PropsWithChildren } from 'react';
import { Modal, ModalBody, ModalHeader, ModalProps } from 'reactstrap';

type PropsType = {
  className?: string;
  headerLabel: string;
  entityName?: string;
  closeHandler: Function;
  centered?: boolean;
} & ModalProps;
const ModalWrapper: FC<PropsWithChildren<PropsType>> = (props) => {
  const {
    className,
    headerLabel,
    closeHandler,
    children,
    centered = true,
  } = props;
  const toggle = () => closeHandler();

  return (
    <Modal
      fade={false}
      toggle={toggle}
      className={className ? className : ''}
      centered={centered}
      returnFocusAfterClose={true}
      trapFocus={true}
      {...props}
    >
      <ModalHeader
        toggle={toggle}
        close={<CloseButton onClick={toggle} />}
        className="d-flex flex-row align-items-center text-break"
      >
        {headerLabel}
      </ModalHeader>
      <ModalBody> {children}</ModalBody>
    </Modal>
  );
};

export default memo(ModalWrapper);
