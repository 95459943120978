import { FC } from 'react';
import { t } from '../../../services/utils/translation';

export interface ILabelValue {
  label: string;
  value: string | JSX.Element | JSX.Element[];
}

interface PropsType {
  content: ILabelValue[];
  className?: string;
}

const HorizontalTable: FC<PropsType> = ({ content, className = '' }) => {
  const hasValue = content.length > 0;

  const wrapperClassname = 'p-4 ' + className;
  return (
    <div className={wrapperClassname}>
      <table className="table table-responsive-sm table-hover table-outline mb-0">
        {hasValue ? (
          <tbody>
            {content.map(({ label, value }: ILabelValue) => (
              <tr key={label}>
                <td>
                  <b>{label}</b>
                </td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tr>
            <td>{t('Нет данных')}</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default HorizontalTable;
