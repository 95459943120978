import React, { FC, useState, memo, useMemo, useCallback } from 'react';
import { SelectComponentsConfig, ValueType } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import formatOptionLabelHighLighter from '../utils/formatOptionLabelHighLighter';
import shouldLoadMore from '../utils/shouldLoadMore';
import useGetSelectStyle, {
  HEADER_SELECT_COLOR,
} from 'hooks/useGetSelectStyle';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { IForSelectResponseV2 } from '../DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import classNames from 'clsx';
import WitchTooltipSelect from 'components/misc/WithTooltip/WitchTooltipSelect';
import useLoadOptionsInForSelect from 'hooks/useLoadOptionsInForSelect/useLoadOptionsInForSelect';

type TypeProps = {
  id: string;
  placeholder?: string;
  className: string;
  selectHandler: (
    params: IBaseParams<IForSelectRequestV2>
  ) => Promise<IBaseResponse<IForSelectResponseV2>>;
  isMulti?: boolean;
  options?: IClientSelectOptionV2[] | IClientSelectOptionV2 | null;
  'aria-labelledby'?: string;
  onChange: (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => void;
  isClearable?: boolean;
  closeMenuOnSelect?: string;
  headerColor?: HEADER_SELECT_COLOR;
  customComponents?: SelectComponentsConfig<
    IClientSelectOptionV2 | IClientSelectOptionV2[],
    boolean
  >;
  disabled?: boolean;
  haveReadPermission?: boolean;
};

const DynamicSelectPaginatedV2: FC<TypeProps> = (props) => {
  const {
    id,
    placeholder,
    selectHandler,
    isMulti = false,
    disabled = false,
    options,
    className,
    'aria-labelledby': ariaLabelledBy,
    onChange,
    isClearable = false,
    headerColor,
    customComponents,
    haveReadPermission = true,
  } = props;

  let optionsArray = useMemo(() => {
    if (options == null) {
      return [];
    } else if (!Array.isArray(options)) {
      return [options];
    } else {
      return options;
    }
  }, [options]);

  const [loadedOptions, setLoadedOptions] =
    useState<IClientSelectOptionV2[]>(optionsArray);

  const loadOptions = useLoadOptionsInForSelect({
    selectHandler,
    setLoadedOptions,
    loadedOptions,
  });

  const getValue = useCallback(() => {
    if (loadedOptions != null && optionsArray.length > 0) {
      if (isMulti) {
        return optionsArray;
      }

      let value = loadedOptions.find((option: IClientSelectOptionV2) =>
        optionsArray.find((option2) => option.value === option2.value)
      );
      if (value == null) {
        value = optionsArray[0];
      }

      return value;
    } else {
      return isMulti ? [] : null;
    }
  }, [isMulti, loadedOptions, optionsArray]);

  const value = getValue();

  const selectStyles = useGetSelectStyle({ headerColor });

  return (
    <WitchTooltipSelect id={id} haveReadPermission={haveReadPermission}>
      <AsyncPaginate
        cacheOptions
        loadOptions={loadOptions}
        id={id}
        name={id}
        aria-labelledby={ariaLabelledBy}
        placeholder={placeholder}
        className={classNames(className, {
          disabled: !haveReadPermission || disabled,
        })}
        isMulti={isMulti}
        onChange={onChange}
        value={value}
        additional={{
          page: 1,
        }}
        styles={selectStyles}
        isClearable={isClearable}
        debounceTimeout={300}
        shouldLoadMore={shouldLoadMore}
        formatOptionLabel={formatOptionLabelHighLighter}
        components={customComponents}
        isDisabled={disabled || !haveReadPermission}
      />
    </WitchTooltipSelect>
  );
};

export default memo(DynamicSelectPaginatedV2);
