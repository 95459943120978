import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import IPrimaryKey from 'services/types/IPk';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IEventTypeGetPaginatedParams } from './IEventTypeGetPaginatedParams';
import { IEventType, IEventTypeWrapper } from './IEventType';
import { ICreateEventType } from './ICreateEventType';
import { IUpdateEventType } from './IUpdateEventType';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.EVENT_TYPE
  );

export const getPaginatedEventTypeApiV2 = async (
  filterParams: IBaseParams<IEventTypeGetPaginatedParams>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IEventType[]>>> =>
  api().getPaginated(filterParams);

export const readEventTypeApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<IEventTypeWrapper>> => api().read(params);

export const createEventTypeApiV2 = async (
  params: IBaseParams<ICreateEventType>
): Promise<IBaseResponse<IEventTypeWrapper>> => await api().create(params);

export const updateEventTypeApiV2 = async (
  eventType: IBaseParams<IUpdateEventType>
): Promise<IBaseResponse<IEventTypeWrapper>> => await api().update(eventType);

export const deleteEventTypeApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => await api().delete(deleteParams);

export const getForSelectEventTypeApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);
