import { FC, memo } from 'react';
import {
  addIdentityToInstallationsApiV2,
  removeIdentityToInstallationsApiV2,
} from 'services/api/identity/identity';
import EditingEntitiesFromIdentityForm from '../EditingEntitiesFromIdentityForm/EditingEntitiesFromIdentityForm';
import { InstallationListKeys } from 'services/api/auth/IRegisterIdentity';
import { menuItemsEditInstallations } from "../../../services/constants/MenuItemPager/MenuItemPager";

type PropsType = {
  isOpen: boolean;
  closeHandler: Function;
};

const AddAndRemoveIdentityToInstallationsForm: FC<PropsType> = ({
  isOpen,
  closeHandler,
}) => {
  return (
    <EditingEntitiesFromIdentityForm
      isOpen={isOpen}
      closeHandler={closeHandler}
      addEntity={addIdentityToInstallationsApiV2}
      removeEntity={removeIdentityToInstallationsApiV2}
      tabs={menuItemsEditInstallations}
      typeInstallations={InstallationListKeys.installations}
    />
  );
};

export default memo(AddAndRemoveIdentityToInstallationsForm);
