import { ICounterpartyFilters } from 'services/api/organizationsV2/counterparty/IGetCounterPartyPaginatedParams';
import {
  COUNTERPARTY_SAVE_FILTERS,
  COUNTERPARTY_CLEAR_FILTERS,
  COUNTERPARTY_SAVE_TASK_FILTERS,
  COUNTERPARTY_CLEAR_TASK_FILTERS,
  COUNTERPARTY_CLEAR_BILLING_ACCOUNT_FILTERS,
  COUNTERPARTY_SAVE_BILLING_ACCOUNT_FILTERS,
} from './actionTypes';
import { initialCounterpartyFilters } from 'store/initialStore/initialCounterpartyFilters';
import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { IBillingAccountFilters } from 'services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';

export const saveCounterpartyFilters = (filters: ICounterpartyFilters) => ({
  type: COUNTERPARTY_SAVE_FILTERS,
  filters,
});

export const clearCounterpartyFilters = () => ({
  type: COUNTERPARTY_CLEAR_FILTERS,
  filters: initialCounterpartyFilters,
});

export const saveCounterpartyTaskFilters = (
  filters: ITaskFilters,
  pk: number
) => ({
  type: COUNTERPARTY_SAVE_TASK_FILTERS,
  payload: { pk, filters },
});

export const extractClearCounterpartyTaskFilters = (pk: number) => () => ({
  type: COUNTERPARTY_CLEAR_TASK_FILTERS,
  payload: pk,
});

export const saveCounterpartyBillingAccountFilters = (
  filters: IBillingAccountFilters,
  pk: number
) => ({
  type: COUNTERPARTY_SAVE_BILLING_ACCOUNT_FILTERS,
  payload: { pk, filters },
});

export const extractClearCounterpartyBillingAccountFilters =
  (pk: number) => () => ({
    type: COUNTERPARTY_CLEAR_BILLING_ACCOUNT_FILTERS,
    payload: pk,
  });
