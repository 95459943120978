import { COLORS_KEYS } from 'services/constants/UI_COLORS';
import { t } from '../translation';

const TEXT_OPEN = t('Открыто');
const CLOSE_OPEN = t('Закрыто');

const getStatusLabelColor = (isClose: boolean) =>
  isClose
    ? { labelStatus: CLOSE_OPEN, color: COLORS_KEYS.danger }
    : { labelStatus: TEXT_OPEN, color: COLORS_KEYS.success };

export default getStatusLabelColor;
