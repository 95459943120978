import { NotificationParentUiElement } from 'services/api/notification/IresponseGetNotifications';

const initialNotificationCount: NotificationParentUiElement = {
  external_request: 0,
  internal_task: 0,
  total: 0,
  billing_account: 0,
  export: 0,
};

export default initialNotificationCount;
