import classNames from 'clsx';
import styles from './index.module.scss';
import { FC, PropsWithChildren } from 'react';

type PropsType = {
  id?: string;
};
export const FormHeaderLinkWrapper: FC<PropsWithChildren<PropsType>> = ({
  children,
  id = '',
}) => {
  return (
    <div className={classNames(styles.wrapperData)} id={id}>
      <div className={classNames(styles.tittleLink)}>{children}</div>
    </div>
  );
};

export default FormHeaderLinkWrapper;
