import { memo, useMemo } from 'react';
import CommentList from '../CommentList/CommentList';
import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import IKeyValueT from 'services/api/interfacesApi/IKeyValueT';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import CommentForm, {
  IFormValuesClientComment,
} from '../CommentForm/CommentForm';
import styles from './index.module.scss';
import classNames from 'clsx';
import { IKeyValueNumber } from 'services/interfaces/IKeyValue';
import useGetRefreshingData from 'hooks/useGetRefreshingData';
import { useSelector } from 'react-redux';
import { getCurrentEmployeePkFromStore } from 'store/general/selectors';
import useToGroupByDateComments from './hooks/useToComment';
import IPrimaryKey from 'services/types/IPk';

interface IBaseCommentParams<Pk = number | string> {
  pk: Pk;
  attr: IKeyValueNumber;
  [key: string]: any;
}

export const extractConvertParamsForAddComment =
  (authorDk: number, entityName: string) =>
  <Pk extends number | string>(
    value: IFormValuesClientComment<Pk>
  ): IBaseCommentParams<Pk> => {
    const keyCommentText = `${entityName}_comment_text`;
    const keyCommentAuthorDk = `${entityName}_comment_author_dk`;

    return {
      pk: value.pk,
      [keyCommentText]: value.comment_text,
      attr: { [keyCommentAuthorDk]: authorDk },
    };
  };

type PropsType<CommentParams, Payload, Pk = number | string> = {
  getComments: (
    params: IBaseParams<IPrimaryKey<Pk>>
  ) => Promise<IBaseResponse<IGetCommentsPayloadV2>>;
  pk: Pk;
  addCommentApiV2: (
    params: IBaseParams<CommentParams | unknown>
  ) => Promise<IBaseResponse<Payload>>;
  convertParams: (value: IFormValuesClientComment) => CommentParams;
  viewOnly?: boolean;
  currentEmployeePk?: number;
};

const CommentsBlockWrapper = <
  CommentParams,
  Payload,
  Pk extends number | string
>(
  props: PropsType<CommentParams, Payload, Pk>
) => {
  const {
    getComments,
    pk,
    addCommentApiV2,
    convertParams,
    viewOnly = false,
  } = props;
  const initialParams: IPrimaryKey<Pk> = useMemo(() => ({ pk }), [pk]);
  const currentEmployeePk = useSelector(getCurrentEmployeePkFromStore);
  const toClientComment = useToGroupByDateComments(currentEmployeePk);

  const initialData = { groupComments: [], numberAllMessages: 0 };
  const {
    data: comments,
    refreshData,
    isLoading,
  } = useGetRefreshingData({
    getDataApi: getComments,
    initialData,
    params: initialParams,
    condition: !!pk && !!currentEmployeePk,
    convertData: toClientComment,
    isNotComparePrevParams: true,
  });

  return (
    <div className={styles.comments}>
      {currentEmployeePk && (
        <CommentList comments={comments} isLoading={isLoading} />
      )}
      {!viewOnly && (
        <div
          className={classNames(
            styles.addCommentSection,
            'd-flex flex-row mt-1 p-4'
          )}
        >
          <CommentForm
            refreshCommentList={refreshData}
            addCommentApiV2={addCommentApiV2}
            convertParams={convertParams}
            pk={pk}
          />
        </div>
      )}
    </div>
  );
};

export default memo(CommentsBlockWrapper);
