import { useDispatch, useSelector } from 'react-redux';
import useRead from './useRead';
import { getCurrentEmployeeApiV2 } from '../services/api/general/apiGeneralV2';
import { IEmployeeWrapper } from 'services/api/staff/employee/IEmployee';
import { setCurrentEmployee, removeCurrentEmployee } from '../store/general/actions';
import { IEmployee } from '../services/api/staff/employee/IEmployee';
import {
  getCurrentEmployeeFromStore,

} from '../store/general/selectors';
import { useEffect } from 'react';
import useGetPrevValue from './useGetPrevValue';
import hasValuesInObject from 'services/utils/hasValueInObject/hasValueInObject';
import { getInstallationPkFromStore } from "../store/auth/selectors";

const useLoadCurrentEmployee = (authDomain?: number, offLoad?: boolean) => {
  const currentEmployee = useSelector(getCurrentEmployeeFromStore);
  const reduxDispatch = useDispatch();
  const hasCurrentEmployee = hasValuesInObject(currentEmployee);
  const installationPk = useSelector(getInstallationPkFromStore);
  const { isLoading, request, refreshData } = useRead({
    getDataApi: getCurrentEmployeeApiV2,
    convertData: (payload: IEmployeeWrapper) => {
      reduxDispatch(setCurrentEmployee(payload.employee));
      return payload.employee;
    },
    initialData: {} as IEmployee,
    params: undefined,
    condition: !!authDomain && !!installationPk && !offLoad,
    warningHandler: () => reduxDispatch(removeCurrentEmployee()),
  });

  const prevInstallationPk = useGetPrevValue(installationPk);

  useEffect(() => {
    if (
      prevInstallationPk !== undefined &&
      prevInstallationPk !== installationPk
    ) {
      refreshData();
    }
  }, [installationPk, prevInstallationPk, refreshData]);

  return { currentEmployee, isLoading, request, hasCurrentEmployee };
};

export default useLoadCurrentEmployee;
