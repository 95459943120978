import React, { LegacyRef, forwardRef } from 'react';
import { IKeyValueBoolean } from 'services/interfaces/IKeyValue';
import classNames from 'clsx';
import { getColorFromHEX } from 'components/tables/misc/colorTr';
import ColoredStatusLabel from 'components/misc/ColoredStatusLabel';
import { ITask } from "../../../../services/api/tasks/tasks/ITask";
import { t } from "../../../../services/utils/translation";

type PropsType = {
  visibility: IKeyValueBoolean;
  className?: string;
  tasks: ITask[];
};
const TasksPrint = (props: PropsType, ref: LegacyRef<HTMLTableElement>) => {
  const { visibility, className, tasks } = props;

  return (
    <table
      ref={ref}
      className={classNames(`table table-bordered  ${className}`)}
    >
      <thead>
        <tr>
          <th
            scope="col"
            className={classNames('p-2', { 'd-none': !visibility.pk })}
            style={{ width: '5%', fontWeight: 600 }}
          >
            №
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.author,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            Автор
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.supervisor,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            Исполнитель
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.account,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            Лицевой счет
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.type,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            Тип
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.status,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            Статус
          </th>

          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.duedate,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            {t('Запланировано')}
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.description,
            })}
            style={{ width: '18%', fontWeight: 600 }}
          >
            Текст
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.comment,
            })}
            style={{ width: '17%', fontWeight: 600 }}
          >
            Комментарий
          </th>
        </tr>
      </thead>
      <tbody style={{ maxWidth: '2480px', width: '100%' }}>
        {tasks?.map(
          ({
            internal_task_pk: pk,
            internal_task_author: author,
            internal_task_billing_account: account,
            internal_task_type: taskType,
            internal_task_status: status,
            internal_task_date_duedate: endDate,
            internal_task_description: description,
            internal_task_list_of_comments: comments,
            internal_task_supervisor: supervisor,
          }: ITask) => (
            <tr
              key={pk}
              className={classNames(
                `table-${getColorFromHEX(status?.internal_task_status_color)}`
              )}
            >
              <td
                className={classNames({ 'd-none': !visibility.pk })}
                style={{ width: '5%' }}
              >
                {pk}
              </td>
              <td
                className={classNames({ 'd-none': !visibility.author })}
                style={{ width: '10%' }}
              >
                {author?.full_name}
              </td>
              <td
                className={classNames({
                  'd-none': !visibility.supervisor,
                })}
                style={{ width: '10%' }}
              >
                {supervisor?.full_name}
              </td>
              <td
                className={classNames({
                  'd-none': !visibility?.account,
                })}
                style={{ width: '10%' }}
              >
                {account?.billing_account_code_number
                  ? account?.billing_account_code_number
                  : '-'}
              </td>
              <td
                className={classNames({
                  'd-none': !visibility.type,
                })}
                style={{ width: '10%' }}
              >
                {taskType?.internal_task_type_rendition}
              </td>
              <td
                className={classNames({
                  'd-none': !visibility.status,
                })}
                style={{ width: '10%' }}
              >
                {status && (
                  <ColoredStatusLabel color={status.internal_task_status_color}>
                    {status?.internal_task_status_rendition}
                  </ColoredStatusLabel>
                )}
              </td>
              <td
                className={classNames({
                  'd-none': !visibility.duedate,
                })}
                style={{ width: '10%' }}
              >
                {endDate}
              </td>
              <td
                className={classNames('p-2', {
                  'd-none': !visibility.description,
                })}
                style={{ width: '18%' }}
              >
                {description}
              </td>
              <td
                className={classNames('p-2', {
                  'd-none': !visibility.comment,
                })}
                style={{ width: '17%' }}
              >
                {comments[comments.length - 1]?.internal_task_comment_text}
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default forwardRef<HTMLTableElement, PropsType>(TasksPrint);
