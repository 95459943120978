import React, { FC } from 'react';
import ExternalRequestEditPage from '../ExternalRequestEditPage/ExternalRequestEditPage';
import useFirstExternalRequestStatus from '../hooks/useFirstExternalRequestStatus';

const ExternalRequestCreateChildPage: FC = () => {
  useFirstExternalRequestStatus();

  return <ExternalRequestEditPage isCreateChild />;
};
export default ExternalRequestCreateChildPage;
