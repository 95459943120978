import { SET_NUMBER_NOTIFICATIONS } from "./actionTypes";

 
const notificationReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SET_NUMBER_NOTIFICATIONS: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default notificationReducer;
