import classNames from 'clsx';
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  memo,
  useRef,
} from 'react';
import { FormGroup, Label } from 'reactstrap';
import TipButton from '../TipButton';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';
import PasswordInput from '../PasswordInput/PasswordInput';
import CapsLockIndicator from '../PasswordInput/ui/CapsLockIndicator/CapsLockIndicator';
import useCapsLockOn from './hooks/useCapsLockOn/useCapsLockOn';
import useFocus from 'hooks/useFocus/useFocus';
type TypeProps = {
  id: string;
  label: string;
  placeholder?: string;
  onBlur: FocusEventHandler;
  onChange: ChangeEventHandler;
  hasError: boolean;
  required?: boolean;
  value?: string;
  defaultValue?: string;
  autoComplete?: 'on' | 'off' | 'new-password';
  helpText?: string;
  disabled?: boolean;
  checkDifficulty?: boolean;
};

const PasswordField: FC<TypeProps> = (props) => {
  const {
    id,
    label,
    placeholder,
    onBlur,
    onChange,
    hasError,
    required = false,
    defaultValue,
    autoComplete,
    value,
    helpText,
    disabled,
    checkDifficulty,
  } = props;
  const autoCompleteProps = { autoComplete };
  const valueProp = value ? { value } : {};
  const classNameRequired = required ? 'required' : '';

   const capsLockOn = useCapsLockOn();
  const inputRef = useRef(null);
  const isInputFocused = useFocus(inputRef);

  return (
    <FormGroup className={classNames(classNameRequired)}>
      <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
        <Label className="control-label" for={id}>
          {label}
        </Label>
        {helpText && <TipButton id={id} helpText={helpText} />}
        {capsLockOn && isInputFocused && <CapsLockIndicator />}
      </div>
      <ErrorWrapperField id={id}>
        <div className={'d-flex-column'}>
          <PasswordInput
            forwardRef={inputRef}
            checkDifficulty={checkDifficulty}
            disabled={disabled}
            placeholder={placeholder ? placeholder : label}
            name={id}
            id={id}
            onBlur={onBlur}
            onChange={onChange}
            className={classNames({
              'is-invalid': hasError,
            })}
            defaultValue={defaultValue?.toString()}
            required={required}
            {...autoCompleteProps}
            {...valueProp}
          />
        </div>
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(PasswordField);
