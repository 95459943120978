import { useCallback } from 'react';

const useAccessPolicyHandleSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
  const handleSearchPk = useCallback(
    (value: string) => {
      onSearchRequest(value, 'access_policy_pk');
    },
    [onSearchRequest]
  );
  const handleSearchName = useCallback(
    (value: string) => {
      onSearchRequest(value, 'access_policy_name');
    },
    [onSearchRequest]
  );

  return [handleSearchPk, handleSearchName];
};

export default useAccessPolicyHandleSearch;
