import { useState, useEffect, useCallback } from 'react';
import mammoth from 'mammoth';

const useFileReader = (src: Blob | null, fileType: string | undefined) => {
  const [uriBlob, setUriBlob] = useState<string>('');
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isDoc = (fileType && fileType === 'doc') || fileType === 'docx';
  const readFile = useCallback(async () => {
    setIsLoading(true);
    if (src && !isDoc) {
      const reader = new FileReader();
      reader.readAsDataURL(src);
      reader.onload = () => {
        setUriBlob(reader.result as string);
      };
      reader.onerror = () => {
        throw new Error('Fail to load the file');
      };
    } else if (src && isDoc) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(src);
      reader.onload = async () => {
        try {
          const result = await mammoth.convertToHtml({
            arrayBuffer: reader.result as ArrayBuffer,
          });

          setHtmlContent(result.value);
        } catch (error) {
          console.error('Ошибка при конвертации файла .docx:', error);
        }
      };
      reader.onerror = () => {
        throw new Error('Fail to load the file');
      };
    }
    setIsLoading(false);
  }, [isDoc, src]);

  useEffect(() => {
    void readFile();
  }, [readFile]);

  return { uriBlob, htmlContent, isLoading };
};

export default useFileReader;
