import TDCheckBox from 'components/tables/TDCheckBox/TDCheckBox';
import { IEmployee } from 'services/api/staff/employee/IEmployee';
import getInitials from 'services/utils/getInicials/getInicials';
import { IEmployeeForList } from '../../../../services/api/staff/employee/IEmployee';
import { t } from '../../../../services/utils/translation';
import { getFullNameFromInitials } from '../../../../services/utils/getFullNameFromInitials/getFullNameFromInitials';

const toEmployeeForList = (employees: IEmployee[]): IEmployeeForList[] => {
  return employees.map((employee: IEmployee) => {
    const {
      employee_pk: pk,
      employee_supervisor,
      employee_last_name: lastNameEmployee,
      employee_first_name: firstNameEmployee,
      employee_middle_name: middleNameEmployee,
      employee_is_driver: isDriver,
      employee_is_active: isActive,
    } = employee;

    const fullNameSupervisor = getInitials({
      firstName: employee_supervisor?.employee_first_name,
      middleName: employee_supervisor?.employee_middle_name,
      lastName: employee_supervisor?.employee_last_name,
    });

    const fullNameEmployee = getFullNameFromInitials({
      firstName: firstNameEmployee,
      lastName: lastNameEmployee,
      middleName: middleNameEmployee,
    });

    const hintIsDriver = isDriver
      ? t('Является водителем')
      : t('Не является водителем');

    const hintIsActive = isActive
      ? t('Активный пользователь')
      : t('Не активный пользователь');

    return {
      ...employee,
      fullNameSupervisor,
      isDriver: (
        <TDCheckBox
          isCheck={!!isDriver}
          hint={hintIsDriver}
          id={`isDriver_${pk}`}
        />
      ),
      isActive: (
        <TDCheckBox
          isCheck={!!isActive}
          hint={hintIsActive}
          id={`isActive_${pk}`}
        />
      ),
      fullNameEmployee,
    };
  });
};

export default toEmployeeForList;
