export interface IMarkedItem {
  id: number;
  marked: boolean;
}

const addUniqItems = (stateList: IMarkedItem[], payloadList: IMarkedItem[]) => {
  const concatArray = [...stateList, ...payloadList];
  return concatArray.filter(
    (orderActive: IMarkedItem, pos: number, array: IMarkedItem[]) =>
      array.findIndex((item: IMarkedItem) => item.id === orderActive.id) === pos
  );
};

export default addUniqItems;
