import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import { FC, memo } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';

import {
  createClientSelectOptionV2,
  extractValueFromClientSelectOptionV2,
} from '../../../services/utils/selects/selects';
import useRead from '../../../hooks/useRead';
import { usePostV3 } from 'hooks/usePostV3';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { IVehicleWrapper } from '../../../services/api/vehicle/vehicle/IVehicle';
import Vehicle from 'models/Vehicle';
import {
  createVehicleApiV2,
  readVehicleApiV2,
} from 'services/api/vehicle/vehicle/vehicleApi';
import { updateVehicleApiV2 } from '../../../services/api/vehicle/vehicle/vehicleApi';
import { IUpdateVehicle } from 'services/api/vehicle/vehicle/ICreateVehicle';
import { ICreateVehicle } from '../../../services/api/vehicle/vehicle/ICreateVehicle';
import SelectGroupField from 'components/controls/SelectGroupField/SelectGroupField';
import useGetVehicleTypeOptions from 'hooks/useGetVehicleTypeOptions';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import deleteUnnecessaryParameters from '../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

const preprocessValues = (values: IFormValues) => {
  const newParams = {
    ...values,
    vehicle_type_fk: extractValueFromClientSelectOptionV2(values?.vehicleType),
  };
  return deleteUnnecessaryParameters(newParams, ['vehicle']);
};

interface IFormValues {
  pk?: number;
  vehicle_registration_plate?: string;
  vehicleType?: IClientSelectOptionV2<number>;
  vehicle_garage_code_number?: string;
}

const toFormValues = ({
  vehicle: {
    vehicle_type,
    vehicle_registration_plate,
    vehicle_pk,
    vehicle_garage_code_number,
  },
}: IVehicleWrapper): IFormValues => {
  return {
    pk: vehicle_pk,
    vehicle_registration_plate,
    vehicleType: createClientSelectOptionV2(
      vehicle_type?.vehicle_type_pk,
      vehicle_type?.vehicle_type_rendition
    ),
    vehicle_garage_code_number,
  };
};

type PropsType = {
  successHandler: () => void;
  pk?: number;
};

const VehicleForm: FC<PropsType> = (props) => {
  const { successHandler, pk } = props;

  const { data: initialValues, isLoading } = useRead({
    getDataApi: readVehicleApiV2,
    params: { pk },
    condition: !!pk,
    convertData: toFormValues,
    initialData: new Vehicle(),
    warningHandler: successHandler,
  });

  const { vehicleTypeOptions } = useGetVehicleTypeOptions();

  const createVehicle = usePostV3({
    fetchApi: createVehicleApiV2,
    successHandler,
    viewClientTextToast: true,
    successMessage: t('Машина успешно создана'),
  });
  const updateVehicle = usePostV3({
    fetchApi: updateVehicleApiV2,
    successHandler,
    viewClientTextToast: true,
    successMessage: t('Машина успешно отредактирована'),
  });

  return (
    <>
      <LoaderFetch />
      {!isLoading && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Vehicle.validationSchema()}
          onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
            setSubmitting(true);
            const newValues = preprocessValues(values);
            if (newValues?.pk !== undefined) {
              await updateVehicle(newValues as IUpdateVehicle, setErrors);
            } else {
              await createVehicle(
                newValues as ICreateVehicle,
                setErrors,
                resetForm
              );
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values: {
              vehicleType,
              vehicle_registration_plate,
              vehicle_garage_code_number,
            },
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} noValidate={true}>
              {values.pk && (
                <HiddenField
                  id="pk"
                  value={values.pk}
                  hasError={errors.pk != null && touched.pk != null}
                />
              )}
              <TextField
                type="text"
                id="vehicle_registration_plate"
                label={t('Регистрационный номер машины')}
                placeholder={t('Введите регистрационный номер...')}
                hasError={
                  errors.vehicle_registration_plate != null &&
                  touched.vehicle_registration_plate != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={vehicle_registration_plate}
                required={true}
              />
              <SelectGroupField
                id="vehicleType"
                label={t('Тип машины')}
                placeholder={t('Выберите тип машины...')}
                hasError={
                  errors.vehicleType != null && touched.vehicleType != null
                }
                options={vehicleTypeOptions}
                required={true}
                onChange={(value: IClientSelectOptionV2) => {
                  setFieldValue('vehicleType', value ? value : null);
                }}
                defaultValue={vehicleType}
              />
              <TextField
                type="text"
                id="vehicle_garage_code_number"
                label={t('Регистрационный номер гаража')}
                placeholder={t('Введите номер гаража...')}
                hasError={
                  errors.vehicle_garage_code_number != null &&
                  touched.vehicle_garage_code_number != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={vehicle_garage_code_number}
              />
              <FormGroup className="d-block text-right mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default memo(VehicleForm);
