 import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  memo,
  KeyboardEvent,
} from 'react';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import { IKeyValueString } from 'services/interfaces/IKeyValue';
import classNames from 'clsx';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';

type TypeProps = {
  id: string;
  label: string;
  placeholder?: string;
  onBlur?: FocusEventHandler;
  onChange: ChangeEventHandler;
  hasError?: boolean;
  required?: boolean;
  defaultValue?: string | number;
  value?: string | number;
  options?: IKeyValueString;
  showDefaultOption?: boolean;
  defaultOptionLabel?: string;
  disabled?: boolean;
  highlightEditing?: boolean;
};

const DEFAULT_VALUE = '';

const SelectField: FC<TypeProps> = (props) => {
  const {
    id,
    label,
    placeholder,
    onBlur,
    onChange,
    hasError,
    required = false,
    defaultValue = DEFAULT_VALUE,
    value,
    options,
    showDefaultOption = false,
    defaultOptionLabel,
    disabled,
    highlightEditing = false,
  } = props;
  return (
    <FormGroup className={required ? 'required' : ''}>
      <Label className="control-label" for={id}>
        {label}
      </Label>
      <ErrorWrapperField id={id}>
        <CustomInput
          placeholder={placeholder ? placeholder : label}
          type="select"
          name={id}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          className={classNames({
            'is-invalid': hasError,
            borderBackLight: highlightEditing && !hasError,
          })}
          invalid={hasError}
          value={value != null ? value : defaultValue}
          required={required}
          disabled={disabled}
          onKeyUp={(event: KeyboardEvent) => {
            // not to close the modal window
            event.stopPropagation();
          }}
          error={true}
        >
          {showDefaultOption && (
            <option value="" disabled={required}>
              {defaultOptionLabel ? defaultOptionLabel : label}
            </option>
          )}
          {options &&
            Object.keys(options).map((key) => (
              <option key={key} value={key}>
                {options[key]}
              </option>
            ))}
        </CustomInput>
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(SelectField);
