import { IBillingAccount } from "services/api/organizationsV2/billing-account/IBillingAccount";

const extractLastCodeNumbers = (payload: IBillingAccount[]) => {
  return payload
    .map(
      (billingAccount: IBillingAccount) =>
        billingAccount.billing_account_code_number
    )
    .join('\n');
};

export default extractLastCodeNumbers