import { useReducer, useCallback } from 'react';
import {
  ActionIdentityControlPanelType,
  ActionPointsIdentityControlPanel,
  IStateReducerIdentity,
} from './types';

const initialState: IStateReducerIdentity = {
  isOpenAddUserToInstallations: false,
  isOpenEditMyPassword: false,
  isOpenOwnerShip: false,
  isOpenAdminForm: false,
  isOpenSetPasswordViaAdminForm: false,
  isOpenAbilityAddToInstallation: false,
};

const useActionIdentityControlPanel = () => {
  const [stateIdentityActionPanel, dispatchIdentityActionPanel] = useReducer(
    reducer,
    initialState
  );

  const toggleOpenAddUserToInstallations = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.OPEN_ADD_USER_TO_INSTALLATION,
    });
  }, []);

  const toggleCloseAddUserToInstallations = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.CLOSE_ADD_USER_TO_INSTALLATION,
    });
  }, []);

  const toggleOpenAbilityAddToInstallations = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.OPEN_ABILITY_ADD_TO_INSTALLATION,
    });
  }, []);
  const toggleCloseAbilityAddToInstallations = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.CLOSE_ABILITY_ADD_TO_INSTALLATION,
    });
  }, []);
  const toggleOpenEditMyPassword = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.OPEN_EDIT_MY_PASSWORD,
    });
  }, []);

  const toggleCloseEditMyPassword = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.CLOSE_EDIT_MY_PASSWORD,
    });
  }, []);
  const toggleOpenOwnerShipEdit = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.OPEN_MAKE_OWNER,
    });
  }, []);

  const toggleCloseOwnerShipEdit = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.CLOSE_MAKE_OWNER,
    });
  }, []);

  const toggleOpenAdminForm = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.OPEN_MAKE_ADMIN,
    });
  }, []);

  const toggleCloseAdminForm = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.CLOSE_MAKE_ADMIN,
    });
  }, []);

  const toggleOpenSetViaAdminForm = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.OPEN_SET_PASSWORD_VIA_ADMIN,
    });
  }, []);
  const toggleCloseSetViaAdminForm = useCallback(() => {
    dispatchIdentityActionPanel({
      type: ActionPointsIdentityControlPanel.CLOSE_SET_PASSWORD_VIA_ADMIN,
    });
  }, []);

  return {
    toggleOpenAddUserToInstallations,
    toggleCloseAddUserToInstallations,
    toggleOpenAbilityAddToInstallations,
    toggleCloseAbilityAddToInstallations,
    toggleOpenEditMyPassword,
    toggleCloseEditMyPassword,
    toggleOpenOwnerShipEdit,
    toggleCloseOwnerShipEdit,
    toggleOpenAdminForm,
    toggleCloseAdminForm,
    toggleOpenSetViaAdminForm,
    toggleCloseSetViaAdminForm,
    stateIdentityActionPanel,
  };
};
export default useActionIdentityControlPanel;

const reducer = (
  state: IStateReducerIdentity,
  action: ActionIdentityControlPanelType
) => {
  switch (action.type) {
    case ActionPointsIdentityControlPanel.OPEN_ADD_USER_TO_INSTALLATION:
      return {
        ...state,
        isOpenAddUserToInstallations: true,
      };

    case ActionPointsIdentityControlPanel.CLOSE_ADD_USER_TO_INSTALLATION:
      return {
        ...state,
        isOpenAddUserToInstallations: false,
      };
    case ActionPointsIdentityControlPanel.OPEN_ABILITY_ADD_TO_INSTALLATION:
      return {
        ...state,
        isOpenAbilityAddToInstallation: true,
      };

    case ActionPointsIdentityControlPanel.CLOSE_ABILITY_ADD_TO_INSTALLATION:
      return {
        ...state,
        isOpenAbilityAddToInstallation: false,
      };

    case ActionPointsIdentityControlPanel.OPEN_EDIT_MY_PASSWORD:
      return {
        ...state,
        isOpenEditMyPassword: true,
      };

    case ActionPointsIdentityControlPanel.CLOSE_EDIT_MY_PASSWORD:
      return {
        ...state,
        isOpenEditMyPassword: false,
      };

    case ActionPointsIdentityControlPanel.OPEN_MAKE_OWNER:
      return { ...state, isOpenOwnerShip: true };
    case ActionPointsIdentityControlPanel.CLOSE_MAKE_OWNER:
      return { ...state, isOpenOwnerShip: false };
    case ActionPointsIdentityControlPanel.OPEN_MAKE_ADMIN:
      return { ...state, isOpenAdminForm: true };
    case ActionPointsIdentityControlPanel.CLOSE_MAKE_ADMIN:
      return { ...state, isOpenAdminForm: false };
    case ActionPointsIdentityControlPanel.OPEN_SET_PASSWORD_VIA_ADMIN: {
      return { ...state, isOpenSetPasswordViaAdminForm: true };
    }
    case ActionPointsIdentityControlPanel.CLOSE_SET_PASSWORD_VIA_ADMIN: {
      return { ...state, isOpenSetPasswordViaAdminForm: false };
    }
    default:
      throw new Error('ActionPointsIdentityControlPanel is undefined.');
  }
};
