import {
  COUNTERPARTY_TEXT,
  BILLING_ACCOUNT_TEXT,
} from 'services/localLocalization/servicesAndModels';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';
import { t } from 'services/utils/translation';

export const COLUMN_LABELS_TASK = {
  PK: t('№'),
  AUTHOR: t('Автор'),
  SUPERVISOR: t('Исполнитель'),
  COUNTERPARTY: makeFirstLetterUppercase(COUNTERPARTY_TEXT),
  BILLING_ACCOUNT: makeFirstLetterUppercase(BILLING_ACCOUNT_TEXT),
  ACCOUNT_AND_COUNTERPARTY: `${makeFirstLetterUppercase(
    BILLING_ACCOUNT_TEXT
  )} и ${makeFirstLetterUppercase(COUNTERPARTY_TEXT)}`,
  TYPE: t('Тип'),
  STATUS: t('Статус'),
  CREATE_DATE: t('Дата создания'),
  DUEDATE: t('Запланировано'),
  COMMENT: t('Комментарий'),
  ARCHIVE: t('Архив'),
  EDITED_DATE_TIME: t('Дата редактирования'),
  PARENT_TASK: t('Родственные задачи'),
  PARENT_EXTERNAL_REQUEST: t('Родственные заявки'),
  DESCRIPTION: t('Текст'),
};

export const taskFilters = [
  { name: 'pk', label: COLUMN_LABELS_TASK.PK },
  { name: 'author', label: COLUMN_LABELS_TASK.AUTHOR },
  { name: 'supervisor', label: COLUMN_LABELS_TASK.SUPERVISOR },
  { name: 'accountAndCounterparty', label: COLUMN_LABELS_TASK.ACCOUNT_AND_COUNTERPARTY },

  { name: 'type', label: COLUMN_LABELS_TASK.TYPE },
  { name: 'internal_task_status', label: COLUMN_LABELS_TASK.STATUS },
  { name: 'is_pruned', label: COLUMN_LABELS_TASK.ARCHIVE },

  { name: 'editedDate', label: COLUMN_LABELS_TASK.EDITED_DATE_TIME },
  { name: 'createDate', label: COLUMN_LABELS_TASK.CREATE_DATE },
  { name: 'duedate', label: COLUMN_LABELS_TASK.DUEDATE },
  { name: 'parentTask', label: COLUMN_LABELS_TASK.PARENT_TASK },
  {
    name: 'parentExternalRequest',
    label: COLUMN_LABELS_TASK.PARENT_EXTERNAL_REQUEST,
  },
  { name: 'comment', label: COLUMN_LABELS_TASK.COMMENT },
] as const;

export const columnVisibilityInitialTask = {
  pk: true,
  author: true,
  supervisor: true,
  accountAndCounterparty: true,
  type: true,
  internal_task_status: true,
  editedDate: false,
  createDate: true,
  duedate: true,
  comment: true,
  parentTask: false,
  parentExternalRequest: false,
  is_pruned: false,
} as const;
