import classNames from 'clsx';

import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import animation from './animation.module.scss';

import SideBarItem from './SideBarItem/SideBarItem';
import {
  getMenuFromStore,
  getMenuFavoritesFromStore,
} from '../../../store/general/selectors';
import {
  IMenuItemV2,
  ISubMenuItemV2,
} from '../../../services/interfaces/IMenuItemV2';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

type SidebarPropsType = {
  isOpen: boolean;
};

const Sidebar: FC<SidebarPropsType> = (props) => {
  const { isOpen } = props;

  const primaryMenu = useSelector(getMenuFromStore);

  const favoriteList = useSelector(getMenuFavoritesFromStore);

  return (
    <nav className={classNames(styles.sidebar, { [styles.open]: isOpen })}>
      <div className={styles['primary-menu']}>
        <ul>
          {primaryMenu &&
            primaryMenu.length > 0 &&
            primaryMenu.map((menuItem: IMenuItemV2 | ISubMenuItemV2) => (
              <li key={menuItem.name}>
                <SideBarItem menuItem={menuItem} />
              </li>
            ))}
        </ul>
      </div>
      <div className={styles['secondary-menu']}>
        <ul>
          <TransitionGroup>
            {favoriteList &&
              favoriteList.map((favoriteMenuItem: ISubMenuItemV2) => {
                return (
                  <CSSTransition
                    key={favoriteMenuItem.name}
                    timeout={600}
                    classNames={animation}
                    unmountOnExit
                  >
                    <li key={favoriteMenuItem.name}>
                      <SideBarItem
                        menuItem={favoriteMenuItem}
                        isFavorite={favoriteMenuItem.isFavorite}
                      />
                    </li>
                  </CSSTransition>
                );
              })}
          </TransitionGroup>
        </ul>
      </div>
    </nav>
  );
};

export default memo(Sidebar);
