import classNames from 'clsx';
import React, { FC, memo, KeyboardEvent, FocusEventHandler } from 'react';
import { FormGroup, Label } from 'reactstrap';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import DynamicSelectFormikPaginatedApiV2 from 'components/controls/DynamicSelectFormikPaginatedApiV2';
import DynamicSelectFormikPaginatedFieldFixed from '../DynamicSelectFormikPaginatedFieldFixed/DynamicSelectFormikPaginatedFieldFixed';
import {
  InputGroupWithButtonAddon,
  InputGroupWithButtonAddonPropsType,
} from '../InputGroupWithButtonAddon.tsx/InputGroupWithButtonAddon';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import { IForSelectResponseV2 } from '../DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';
import WitchTooltipSelect from 'components/misc/WithTooltip/WitchTooltipSelect';

export enum TYPE_SELECT {
  fixed = 'fixed',
  default = 'default',
}

export type PropsTypeDynamicSelectPaginatedFieldV2 = {
  id: string;
  label?: string;
  placeholder?: string;
  hasError: boolean;
  // Used to display server-side error message, if the server-side error key is not the same as at the front-end side.
  additionalErrorKey?: string;
  selectHandler: (
    params: IBaseParams<IForSelectRequestV2>
  ) => Promise<IBaseResponse<IForSelectResponseV2>>;
  isMulti?: boolean;
  options?: IClientSelectOptionV2[] | IClientSelectOptionV2 | null;
  disabled?: boolean;
  required?: boolean;
  onChange?: Function;
  defaultValue?: string;
  highlightEditing?: boolean;
  onBlur?: FocusEventHandler;
  type?: TYPE_SELECT;
  className?: string;
  linkAddon?: string;
  addonSettings?: InputGroupWithButtonAddonPropsType;
  keyAsyncSelect?: string;
  haveReadPermission?: boolean;
};

const DynamicSelectPaginatedFieldV2: FC<
  PropsTypeDynamicSelectPaginatedFieldV2
> = (props) => {
  const {
    id,
    label,
    placeholder,
    hasError = false,
    selectHandler,
    isMulti = false,
    options,
    disabled = false,
    required = false,
    onChange,
    defaultValue,
    highlightEditing = false,
    onBlur,
    type = TYPE_SELECT.default,
    className,
    addonSettings,
    keyAsyncSelect,
    haveReadPermission = true,
  } = props;

  const SELECT_COMPONENT_MAP = {
    default: (
      <DynamicSelectFormikPaginatedApiV2
        id={id}
        aria-labelledby={`${id}_label`}
        placeholder={placeholder ? placeholder : label}
        className={classNames(className, 'w-100', {
          'is-invalid': hasError,
          borderBackLight: highlightEditing && !hasError,
        })}
        isMulti={isMulti}
        selectHandler={selectHandler}
        options={options}
        disabled={disabled || !haveReadPermission}
        onChange={onChange}
        defaultValue={defaultValue}
        onBlur={onBlur}
        keyAsyncSelect={keyAsyncSelect}
      />
    ),
    fixed: (
      <DynamicSelectFormikPaginatedFieldFixed
        id={id}
        aria-labelledby={`${id}_label`}
        placeholder={placeholder ? placeholder : label}
        className={classNames(className, 'w-100', {
          'is-invalid': hasError,
          borderBackLight: highlightEditing && !hasError,
        })}
        isMulti={isMulti}
        selectHandler={selectHandler}
        options={options}
        disabled={disabled || !haveReadPermission}
        onChange={onChange}
        defaultValue={defaultValue}
        onBlur={onBlur}
      />
    ),
  };

  const SelectComponentMap = SELECT_COMPONENT_MAP[type];

  return (
    <WitchTooltipSelect id={id} haveReadPermission={haveReadPermission}>
      <FormGroup
        className={classNames({ required: required })}
        onKeyUp={(event: KeyboardEvent) => {
          // not to close the modal window
          event.stopPropagation();
        }}
      >
        {label && (
          <Label id={`${id}_label`} for={id} className="control-label">
            {label}
          </Label>
        )}
        <InputGroupWithButtonAddon {...addonSettings}>
          <div className="d-flex-column w-100">{SelectComponentMap}</div>
        </InputGroupWithButtonAddon>
      </FormGroup>
    </WitchTooltipSelect>
  );
};

export default memo(DynamicSelectPaginatedFieldV2);
