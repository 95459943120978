import { useEffect, RefObject } from 'react';

const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    const addOutsideClickListener = () => {
      document.addEventListener('mousedown', handleOutsideClick);
    };

    const removeOutsideClickListener = () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };

    addOutsideClickListener();

    return () => {
      removeOutsideClickListener();
    };
  }, [ref, callback]);
};

export default useClickOutside;
