import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../../services/utils/translation';
import { Button, Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { getCurrentEmployeeFromStore } from 'store/general/selectors';
import FileBucketSchema from 'models/FileBucketSchema/FileBucketSchema';
import IClientSelectOptionV2 from '../../../services/api/interfacesApi/IClientSelectOptionV2';
import {
  ICreateFileBucketSchemaParams,
  IUpdateFileBucketSchemaParams,
} from '../../../services/api/documents/file-bucket-schema/ICreateFileBucketSchema';
import { getForSelectAccessPolicyApiV2 } from 'services/api/staff/access-policy/accessPolicyApiV2';
import { IAccessPolicy } from 'services/api/staff/access-policy/IAccessPolicy';
import { extractValueFromClientSelectOptionV2 } from '../../../services/utils/selects/selects';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import useToAccessPolicyTransformToObject from 'hooks/useToAccessPolisyTransformToObject/useToAccessPolicyTransformToObject';
import {
  createFileBucketSchemaApi,
  getFileBucketSchemaApi,
  updateFileBucketSchemaApi,
} from '../../../services/api/documents/file-bucket-schema/fileBucketSchemaApi';
import { usePostOld } from 'hooks/usePostOld/usePostOld';
import useReadOld from '../../../hooks/useReadOld/useReadOld';
import extractConvertFileBucketSchemaToInitialValues from './utils/extractConvertFileBucketSchemaToInitialValues';
import { isEmpty } from 'services/utils/misc/misc';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import getErrorMessage from 'services/utils/getErrorMessage/getErrorMessage';
import getSuccessMessage from 'services/utils/getSuccessMessage/getSuccessMessage';

const { errorMessageCreate, errorMessageUpdate, errorMessageRead } =
  getErrorMessage(t('тип документа'));
const { successMessageCreate, successMessageUpdate } = getSuccessMessage(
  t('Тип документа')
);

const isUpdate = (
  schema: IFileBucketSchemaFormValues
): schema is IUpdateFileBucketSchemaParams => 'pk' in schema;

export const toAccessPolicyOptions = (
  accessPolicyList: IAccessPolicy[]
): Record<string, string> =>
  accessPolicyList.reduce((acc, value) => {
    acc[value.access_policy_pk] = value.access_policy_name;
    return acc;
  }, {} as Record<string, string>);

type PropsType = {
  pk?: number;
  successHandler: () => void;
};

const convertParams = (
  values: IFileBucketSchemaFormValues
): ICreateFileBucketSchemaParams | IUpdateFileBucketSchemaParams => {
  const { accessPolicies } = values;

  const newParams = {
    ...values,
    access_policies: accessPolicies
      ? accessPolicies.map(
          (accessPolice: IClientSelectOptionV2<number>) =>
            extractValueFromClientSelectOptionV2(accessPolice) as number
        )
      : undefined,
  };

  const clearNewParams = deleteUnnecessaryParameters(newParams, [
    'accessPolicies',
  ]);

  return isUpdate(values)
    ? (clearNewParams as ICreateFileBucketSchemaParams)
    : (clearNewParams as IUpdateFileBucketSchemaParams);
};

export interface IFileBucketSchemaFormValues {
  pk?: number;
  file_bucket_schema_author_id?: number;
  file_bucket_schema_name?: string;
  file_bucket_schema_description?: string;
  access_policies?: number[];
  accessPolicies?: IClientSelectOptionV2[];
}

const isUpdateFileBucketSchema = (
  fileBucketSchema: FileBucketSchema
): fileBucketSchema is IUpdateFileBucketSchemaParams =>
  'pk' in fileBucketSchema;

const FileBucketSchemaFrom: FC<PropsType> = (props) => {
  const { successHandler, pk } = props;

  const isNew = !pk;
  const authorId = useSelector(getCurrentEmployeeFromStore).employee_pk;

  const { roles = {}, isLoadingRoles } = useToAccessPolicyTransformToObject();

  const convertFileBucketSchemaToInitialValues =
    extractConvertFileBucketSchemaToInitialValues(roles);

  const canBeLoad = !!pk && !isEmpty(roles) && !Array.isArray(roles);

  const { data: initialValues, isLoading: isLoadingFileBucketSchema } =
    useReadOld({
      getDataApi: getFileBucketSchemaApi,
      pk: pk as number,
      condition: canBeLoad,
      convertData: convertFileBucketSchemaToInitialValues,
      initialData: new FileBucketSchema(authorId, pk),
      errorMessage: errorMessageRead,
    });

  const createFileBucketSchema = usePostOld({
    fetchApi: createFileBucketSchemaApi,
    errorMessage: errorMessageCreate,
    successMessage: successMessageCreate,
    successHandler,
  });

  const updateFileBucketSchema = usePostOld({
    fetchApi: updateFileBucketSchemaApi,
    errorMessage: errorMessageUpdate,
    successMessage: successMessageUpdate,
    successHandler,
  });

  const isLoading = (!isNew && isLoadingFileBucketSchema) || isLoadingRoles;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues as IFileBucketSchemaFormValues}
      validationSchema={FileBucketSchema.validationSchema()}
      onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        const nevValues = convertParams(values);
        isUpdateFileBucketSchema(nevValues) && values.pk
          ? updateFileBucketSchema(nevValues, setErrors, resetForm)
          : createFileBucketSchema(nevValues, setErrors, resetForm);

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} noValidate={true}>
          {isLoading && <LoaderFetch />}
          {!isLoading && (
            <>
              <HiddenField
                id="file_bucket_schema_author_id"
                value={authorId}
                hasError={
                  errors.file_bucket_schema_author_id != null &&
                  touched.file_bucket_schema_author_id != null
                }
              />
              {values.pk && (
                <HiddenField
                  id="id"
                  value={values.pk}
                  hasError={errors.pk != null && touched.pk != null}
                />
              )}
              <TextField
                id="file_bucket_schema_name"
                label={t('Название')}
                placeholder="Введите название.."
                hasError={
                  errors.file_bucket_schema_name != null &&
                  touched.file_bucket_schema_name != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                required={true}
                value={values.file_bucket_schema_name}
              />
              <TextField
                id="file_bucket_schema_description"
                label={t('Описание')}
                placeholder="Введите описание.."
                hasError={
                  errors.file_bucket_schema_description != null &&
                  touched.file_bucket_schema_description != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                required={false}
                value={values.file_bucket_schema_description}
              />
              <DynamicSelectPaginatedFieldV2
                selectHandler={getForSelectAccessPolicyApiV2}
                label={t('Роль')}
                id="accessPolicies"
                placeholder={t('Выберите роль...')}
                onBlur={handleBlur}
                onChange={(value: IClientSelectOptionV2<number>) => {
                  setFieldValue('accessPolicies', value);
                }}
                options={values.accessPolicies}
                hasError={
                  errors.accessPolicies != null &&
                  touched.accessPolicies != null
                }
                isMulti
              />
              <FormGroup className="d-block text-right mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FileBucketSchemaFrom;
