import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { useMemo } from 'react';
import {
  targetAccessPolicyBillingAccount,
  targetAccessPolicyBillingAccountType,
  targetAccessPolicyCounterparty,
} from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';

const useGetBillingAccountAccessPolicy = () => {
  const [
    haveAccessToCreate,
    haveAccessToUpdate,
    haveAccessToDelete,
    haveAccessToExport,
  ] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['C', 'U', 'D', 'E'],
  });

  const [billingAccountTypeHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccountType,
    targetAction: ['R'],
  });

  const [counterpartyHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyCounterparty,
    targetAction: ['R'],
  });

  return useMemo(
    () => ({
      billingAccountTypeHaveAccessToRead,
      counterpartyHaveAccessToRead,
      billingAccount: {
        haveAccessToCreate,
        haveAccessToUpdate,
        haveAccessToDelete,
        haveAccessToExport,
      },
    }),
    [
      billingAccountTypeHaveAccessToRead,
      counterpartyHaveAccessToRead,
      haveAccessToCreate,
      haveAccessToDelete,
      haveAccessToExport,
      haveAccessToUpdate,
    ]
  );
};

export default useGetBillingAccountAccessPolicy;
