import { SELECTED_COUNTERPARTY_IN_TASK } from './selectedCounterparty';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';
import {
  BILLING_ACCOUNT_TEXT,
  COUNTERPARTY_TEXT,
} from 'services/localLocalization/servicesAndModels';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';

const ACCOUNT_RADIO_OPTIONS: [
  IClientSelectOptionV2<SELECTED_COUNTERPARTY_IN_TASK.selectedBillingAccount>,
  IClientSelectOptionV2<SELECTED_COUNTERPARTY_IN_TASK.selectedCounterparty>
] = [
  createClientSelectOptionV2(
    SELECTED_COUNTERPARTY_IN_TASK.selectedBillingAccount,
    makeFirstLetterUppercase(BILLING_ACCOUNT_TEXT)
  ),
  createClientSelectOptionV2(
    SELECTED_COUNTERPARTY_IN_TASK.selectedCounterparty,
    makeFirstLetterUppercase(COUNTERPARTY_TEXT)
  ),
];

export default ACCOUNT_RADIO_OPTIONS;
