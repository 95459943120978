import classNames from 'clsx';
import { FC, PropsWithChildren, memo } from 'react';

type PropsTypeFTable = {
  className?: string;
};

const TableWrapper: FC<PropsWithChildren<PropsTypeFTable>> = (props) => {
  const { className, children } = props;
  return (
    <div className={classNames('table-responsive-none', className)}>
      <table
        className={classNames(
          'table table-bordered table-responsive-xl table-hover'
        )}
      >
        {children}
      </table>
    </div>
  );
};

export default memo(TableWrapper);
