import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { FC, useState, ReactElement, memo, cloneElement } from 'react';
import { Button } from 'reactstrap';
import { PropsTypeDynamicSelectPaginatedFieldV2 } from '../DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import { t } from 'services/utils/translation';
import ToolTipWrapper from '../ToolTipWrapper/ToolTipWrapper';
import classNames from 'clsx';

type PropsType = {
  selectProps: PropsTypeDynamicSelectPaginatedFieldV2;
  disabledAddButton?: boolean;
  modalProps: {
    headerLabel: string;
    Form: ReactElement;
  };
  isViewButton?: boolean;
  key: string;
  className?: string;
};

const DynamicSelectPaginatedAddButton: FC<PropsType> = (props) => {
  const {
    selectProps,
    disabledAddButton,
    isViewButton = true,
    modalProps: { headerLabel, Form },
    className,
    key,
  } = props;
  const [isOpenForm, setIsOpenForm] = useState(false);

  const openModal = () => {
    setIsOpenForm(!isOpenForm);
    return;
  };

  const closeModalHandler = () => {
    setIsOpenForm(false);
  };

  let FormWitchSuccessHandler = cloneElement(Form, {
    successHandler: closeModalHandler,
  });

  return (
    <>
      <div
        className={classNames(
          className,
          'd-flex flex-row justify-content-between align-items-end'
        )}
      >
        <div className="flex-grow-1">
          <DynamicSelectPaginatedFieldV2 {...selectProps} />
        </div>

        {isViewButton && (
          <>
            <div
              className="ml-3 flex "
              style={{ marginBottom: '1rem' }}
              id={`tooltip-${key}`}
            >
              <Button
                color="primary"
                onClick={openModal}
                disabled={disabledAddButton}
                style={{ minHeight: '14px' }}
              >
                <span style={{ fontSize: '1em' }}>+</span>
              </Button>
            </div>
            <ToolTipWrapper target={`tooltip-${key}`} placement="top">
              {t('Добавить')}
            </ToolTipWrapper>
          </>
        )}
      </div>

      <ModalWrapper
        headerLabel={headerLabel}
        isOpen={isOpenForm}
        closeHandler={closeModalHandler}
        children={FormWitchSuccessHandler}
      />
    </>
  );
};

export default memo(DynamicSelectPaginatedAddButton);
