import { initialBookingFilters } from 'store/initialStore/initialBookingFilters';
import { BOOKING_CLEAR_FILTERS, BOOKING_SAVE_FILTERS } from './actionTypes';
import { IBookingFilters } from 'services/api/vehicle/booking/IGetPaginatedParamsBooking';

export const saveBookingFilters = (filters: IBookingFilters) => ({
  type: BOOKING_SAVE_FILTERS,
  filters,
});

export const clearBookingFilters = () => ({
  type: BOOKING_CLEAR_FILTERS,
  filters: initialBookingFilters,
});
