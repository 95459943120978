import { addThreeHours } from 'components/controls/CommentBlock/CommentList/CommentList';
import { parseISO } from 'date-fns';
import { formatDateTimeWithoutYear } from 'services/utils/dateHelper/dateHelper';

const getDateForNotificationList = (date: string) => {
  const ISODate = parseISO(date);
  const ISODatePlusThreeHours = addThreeHours(ISODate);

  return formatDateTimeWithoutYear(ISODatePlusThreeHours)
    .split(' ')
    .join(' в ');
};

export default getDateForNotificationList;
