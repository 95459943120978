import { t } from 'services/utils/translation';
import * as Yup from 'yup';
class LoginData {
  public plain_password: string = '';
  public nickname: string = '';

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      nickname: Yup.string().trim().required(t('Укажите логин.')),
      plain_password: Yup.string().required(t('Укажите пароль.')),
    });
  };
}

export default LoginData;
