import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import { saveBillingAccountExternalRequestFilters } from 'store/billingAccount/actions';
import { saveEventExternalRequestFilters } from 'store/event/actions';
import { saveExternalRequestFilters } from 'store/externalRequest/actions';

const useSaveExternalRequestFilters = ({
  billingAccountPk,
  eventPk,
}: {
  eventPk?: number;
  billingAccountPk?: number;
}) => {
  const dispatch = useDispatch();

  return useCallback(
    (filters: IExternalRequestFilters) => {
      if (!billingAccountPk && !eventPk) {
        return dispatch(saveExternalRequestFilters(filters));
      } else if (billingAccountPk) {
        return dispatch(
          saveBillingAccountExternalRequestFilters(filters, billingAccountPk)
        );
      } else if (eventPk) {
        return dispatch(saveEventExternalRequestFilters(filters, eventPk));
      }
      return dispatch(saveExternalRequestFilters(filters));
    },
    [billingAccountPk, dispatch, eventPk]
  );
};

export default useSaveExternalRequestFilters;
