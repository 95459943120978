import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import { IExternalRequestStatusOld } from '../external-request/IExternalRequestStatusOld';
import { UNDERCURRENT_CHILD_MODULES } from "../../../constants/UNDERCURRENT_CHILD_MODULES";

const api = () =>
  new UndercurrentApiClient().methodCreator(UNDERCURRENT_CHILD_MODULES.OLD);

export const getAvailableStatusesV2Old = async (): Promise<
  IBaseResponse<{ list_of_entities: IExternalRequestStatusOld[] }>
> => api().getOld();
