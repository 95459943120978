import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
type PropsType<T> = {
  isAddEditFormOpen: boolean;
  pathEdit: string;
  pathAdd: string;
  pk?: number;
  state?: T;
};

const useAddOrEditPushNavigation = <T,>(props: PropsType<T>) => {
  const { isAddEditFormOpen, pk, pathEdit, pathAdd, state } = props;

  const isNew = !pk;
  const navigate = useNavigate();
  useEffect(() => {
    if (isAddEditFormOpen && !isNew) {
      const path = pathEdit.replace(':id', pk + '');
      navigate(path, { state });
      return;
    } else if (isAddEditFormOpen && isNew) {
      navigate(pathAdd, { state });
    }
  }, [navigate, isAddEditFormOpen, isNew, pathAdd, pathEdit, pk, state]);
};

export default useAddOrEditPushNavigation;
