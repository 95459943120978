import React, { FC, useState, useEffect } from 'react';
import LoginPage from '../LoginPage';
import styles from './index.module.scss';
import Registration from '../RegistrationPage/Registration';
import clearFormDataStepper from '../RegistrationPage/services/clearFormData';
import { Col, Container } from 'reactstrap';
import PagerSwitch from 'components/misc/Pagers/PagerSwitch/PagerSwitch';
import CookieManager from 'models/CoockieManager/CookieManager';
import PhoneNumberJwtCookie from 'models/CoockieManager/PhoneNumberJwtCookie/PhoneNumberJwtCookie';
import { menuItemsAuth } from '../../../services/constants/MenuItemPager/MenuItemPager';

const login = 'Вход' as const;
const registration = 'Регистрация' as const;

const COMPONENT_MAP = {
  [login]: LoginPage,
  [registration]: Registration,
};

const AuthPage: FC = () => {
  const [activeContent, setActiveContent] = useState<
    typeof login | typeof registration
  >(login);
  const Component = COMPONENT_MAP[activeContent];

  useEffect(() => {
    return () => {
      const cookieManger = new CookieManager();
      const phoneNumberJwtCookie = new PhoneNumberJwtCookie(cookieManger);

      clearFormDataStepper();
      phoneNumberJwtCookie.removePhoneNumberInJwt();
    };
  }, []);

  return (
    <Container className={styles['auth-wrapper']}>
      <Col className={styles.loginFullscreenWrapper}>
        <PagerSwitch
          setActiveContent={setActiveContent}
          items={menuItemsAuth}
          customClassName={styles['auth-wrapper']}
        />
        <Component />
      </Col>
    </Container>
  );
};

export default AuthPage;
