import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

export enum SEARCH_IDENTITY_BY {
  NICKNAME = 'identity_nickname',
  PHONE_NUMBER = 'identity_phone_number',
}

export interface IFormValuesIdentityEditing {
  identity_nickname: string;
  list_of_installation_pks: number[];
  list_of_installation: IClientSelectOptionV2[];
  identity_phone_number: string;
  searchIdentityBy: SEARCH_IDENTITY_BY;
}
