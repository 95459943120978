import useRead from 'hooks/useRead';
import { readBillingAccountV2 } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import { IEventWrapper } from 'services/api/organizationsV2/event/IEventWrapper';
import { readEventApi } from 'services/api/organizationsV2/event/eventApi';
import { t } from 'services/utils/translation';
import IBillingAccountWrapper from 'services/api/organizationsV2/billing-account/IBillingAccountWrapper';
import { useCallback } from 'react';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import {
  targetAccessPolicyBillingAccount,
  targetAccessPolicyEvent,
} from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';

const toEventTitle = (event: IEventWrapper) => {
  return `${t('Создать заявку для события')} — ${event.event.event_name}`;
};

const toBillingAccountTitle = (billingAccount: IBillingAccountWrapper) => {
  return `${t('Создать заявку для ЛС')} — ${
    billingAccount.billing_account.billing_account_code_number
  }`;
};

type TUseGetExternalRequestTitleProps = {
  externalRequestPk?: number;
  isDuplicate?: boolean;
  isChild?: boolean;
  isCreateFromTask?: boolean;
  billingAccountId?: number;
  eventPk?: number;
};

type TGetTitleProps = Omit<
  TUseGetExternalRequestTitleProps,
  'eventPk' | 'billingAccountId'
> & {
  billingAccountTitle?: string;
  eventTitle?: string;
};

const useGetExternalRequestHeaderTitle = (
  props: TUseGetExternalRequestTitleProps
) => {
  const {
    externalRequestPk,
    isDuplicate,
    isChild,
    isCreateFromTask,
    billingAccountId,
    eventPk,
  } = props;

  const [billingAccountHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['R'],
  });

  const [eventHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEvent,
    targetAction: ['R'],
  });

  const { data: eventTitle } = useRead({
    getDataApi: readEventApi,
    condition: !!eventPk && eventHaveAccessToRead,
    params: { pk: eventPk } as { pk: number },
    convertData: toEventTitle,
    initialData: '',
  });

  const { data: billingAccountTitle } = useRead({
    getDataApi: readBillingAccountV2,
    condition: !!billingAccountId && billingAccountHaveAccessToRead,
    params: { pk: billingAccountId } as { pk: number },
    convertData: toBillingAccountTitle,
    initialData: '',
  });

  const getTittle = useCallback(
    ({
      externalRequestPk,
      isDuplicate,
      isChild,
      isCreateFromTask,
      billingAccountTitle,
      eventTitle,
    }: TGetTitleProps) => {
      const conditionsMap = [
        {
          condition: !!billingAccountTitle,
          value: billingAccountTitle,
        },
        {
          condition: !!eventTitle,
          value: eventTitle,
        },
        {
          condition: !externalRequestPk && !isCreateFromTask,
          value: t('Создать заявку'),
        },
        {
          condition:
            externalRequestPk && !isDuplicate && !isChild && !isCreateFromTask,
          value: `${t('Заявка')} ${externalRequestPk}`,
        },
        {
          condition: isChild && !isDuplicate,
          value: `${t('Создать подзаявку')}`,
        },
        {
          condition: isDuplicate && !isChild,
          value: `${t('Скопировать заявку')}`,
        },
        {
          condition: isCreateFromTask,
          value: t(`Создать заявку из задачи`),
        },
      ];

      const matchedCondition = conditionsMap.find(
        (condition) => condition.condition
      );

      return matchedCondition ? matchedCondition.value : '';
    },
    []
  );

  return getTittle({
    externalRequestPk,
    isDuplicate,
    isChild,
    isCreateFromTask,
    eventTitle,
    billingAccountTitle,
  });
};

export default useGetExternalRequestHeaderTitle;
