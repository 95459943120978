import Event from 'models/Event';
import React, { FC } from 'react';
import useRead from '../../../../hooks/useRead';
import Subheader from 'components/layouts/Subheader/Subheader';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyEvent } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { readEventApi } from 'services/api/organizationsV2/event/eventApi';
import EventForm from 'components/organizations/event/EventForm/EventForm';
import { useToFormValuesEvent } from './hooks/useToFormValuesEvent';
import SubHeaderDetails from 'components/layouts/SubHeaderDetails/SubHeaderDetails';
import styles from './index.module.scss';
import { EVENT_TEXT } from 'services/localLocalization/servicesAndModels';
import { makeFirstLetterUppercase } from '../../../../services/utils/stringHelper/stringHelper';
import useGoBackNavigate from 'hooks/useGoBackNavigate/useGoBackNavigate';
import { useParams } from 'react-router-dom';

type EventEditPagePropTypes = {
  id?: string;
};

const EventEditPage: FC<EventEditPagePropTypes> = () => {
  const { id } = useParams<{ id: string }>();

  const pk = id ? Number(id) : undefined;

  const [haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEvent,
    targetAction: ['U'],
  });

  const isViewOnly = !haveAccessToUpdate;

  const successHandler = useGoBackNavigate();

  const toFormValuesEvent = useToFormValuesEvent();

  const {
    data: initialValue,
    isLoading,
    refreshData,
  } = useRead({
    getDataApi: readEventApi,
    condition: !!pk,
    params: { pk } as { pk: number },
    convertData: toFormValuesEvent,
    initialData: new Event(),
  });

  return (
    <MainLayout>
      <Subheader />
      <SubHeaderDetails
        mainInfo={{
          title: makeFirstLetterUppercase(EVENT_TEXT),
          value: initialValue.event_name || '',
        }}
        classNameMainInfoValue={styles.subHeaderTitle}
      />
      <EventForm
        successHandler={successHandler}
        pk={pk}
        initialValues={initialValue}
        isLoading={isLoading}
        isViewOnly={isViewOnly}
        refreshData={refreshData}
      />
    </MainLayout>
  );
};

export default EventEditPage;
