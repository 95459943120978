import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyBillingAccount } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { useMemo } from 'react';
import { targetAccessPolicyController } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyController';
import { IControllerClientAccessPolicy } from 'store/controller/initial/initialControllerAccessPolicy';

const useGetControllerAccessPolicy = (
  isInstaled: boolean
): IControllerClientAccessPolicy => {
  const [
    haveAccessRead,
    haveAccessToCreate,
    haveAccessToDelete,
    haveAccessToUpdate,
  ] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyController,
    targetAction: ['R', 'C', 'D', 'U'],
    dontCall: isInstaled,
  });

  const [billingAccountHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['R'],
    dontCall: isInstaled,
  });

  return useMemo(
    () => ({
      billingAccountHaveAccessToRead,
      controller: {
        haveAccessToUpdate,
        haveAccessToCreate,
        haveAccessToDelete,
        haveAccessRead,
      },
    }),
    [
      billingAccountHaveAccessToRead,
      haveAccessRead,
      haveAccessToCreate,
      haveAccessToDelete,
      haveAccessToUpdate,
    ]
  );
};

export default useGetControllerAccessPolicy;
