import React, { FC, memo, PropsWithChildren } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { getLocalParentPath } from 'services/localPaths';
import { PATH_FRONTPAGE } from 'services/pathConstants';
import { findMenuItem, parentMenuItem } from 'services/paths';
import { frontpageTitle } from 'services/utils/misc/misc';
import { ReactComponent as LeftArrow } from '../../../img/icons/icon-left-arrow.svg';
import styles from './index.module.scss';
import { getBackLocalPath } from '../../../services/localPaths';
import { getActiveInstallationClientName } from '../../../store/auth/selectors';
import { getMenuFromStore } from '../../../store/general/selectors';
import { IMenuItemV2 } from '../../../services/interfaces/IMenuItemV2';
import classNames from 'clsx';
import useGetRouteTemplate from 'hooks/useGetRouteTemplate/useGetRouteTemplate';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';

type SubheaderPropsType = {
  className?: string;
  viewBackLocalPatch?: boolean;
};

const Subheader: FC<PropsWithChildren<SubheaderPropsType>> = (props) => {
  const { children, className, viewBackLocalPatch = true } = props;

  const location = useLocation();
  const matchPath = useGetRouteTemplate();
  const currentPath = matchPath ? matchPath : location.pathname;
  const id = useGetIdFromPath();
  const menu: IMenuItemV2[] = useSelector(getMenuFromStore);
  const tittleActiveInstallation = useSelector(getActiveInstallationClientName);
  let parent = parentMenuItem(menu, currentPath);
  if (parent == null && currentPath === PATH_FRONTPAGE) {
    return (
      <div
        className={`${
          styles.header
        } d-flex flex-row justify-content-between align-items-center ${
          className ? className : ''
        }`}
      >
        <NavLink
          className={`${styles.backLink} d-flex align-items-center flex-nowrap flex-grow-1 px-2`}
          to={PATH_FRONTPAGE}
        >
          <LeftArrow />
          <span className="px-2">{tittleActiveInstallation}</span>
        </NavLink>
        {children}
      </div>
    );
  }

  if (parent == null && currentPath !== PATH_FRONTPAGE) {
    const parentPath = getLocalParentPath(currentPath);
    if (parentPath !== PATH_FRONTPAGE && parentPath != null) {
      parent = findMenuItem(menu, parentPath);
      if (parent == null) {
        return (
          <div
            className={`${
              styles.header
            } d-flex flex-row justify-content-between align-items-center ${
              className ? className : ''
            }`}
          >
            {children}
          </div>
        );
      }
    }
  }

  const parentPath = parent ? parent.url : PATH_FRONTPAGE;
  const parentTitle = parent
    ? parent.title
    : frontpageTitle(tittleActiveInstallation);

  const backLocalPatch = getBackLocalPath(currentPath, id);
  if (backLocalPatch && viewBackLocalPatch) {
    return (
      <div
        className={`${
          styles.header
        } d-flex bd-highlight mb-3 flex-row justify-content-between align-items-center ${
          className ? className : ''
        }`}
      >
        <NavLink
          className={`${styles.backLink} mr-auto p-2 bd-highlight align-items-center flex-nowrap px-2`}
          to={backLocalPatch.patch}
        >
          <LeftArrow />
          <span className="px-2">{backLocalPatch.wherePathTittle}</span>
        </NavLink>
        {children}
      </div>
    );
  }

  return (
    <div
      className={`${
        styles.header
      } mb-1 d-flex bd-highlight  flex-row justify-content-between align-items-center ${
        className ? className : ''
      }`}
    >
      {viewBackLocalPatch && (
        <NavLink
          className={`${styles.backLink} mr-auto p-2 bd-highlight align-items-center flex-nowrap px-2`}
          to={parentPath}
        >
          <LeftArrow /> <span className="px-2">{parentTitle}</span>
        </NavLink>
      )}
      <div
        className={classNames('d-flex flex-row', {
          'ml-auto': !viewBackLocalPatch,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(Subheader, isEqual);
