import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import React, { FC, memo, useEffect, useState } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';
import { usePostV3 } from '../../../hooks/usePostV3';
import useRead from 'hooks/useRead';

import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import ResponsibleDepartment from '../../../models/ResponsibleDepartment';
import IResponsibleDepartment from 'services/api/external-request/responsible-department/IResponsibleDepartment';
import {
  updateResponsibleDepartmentApiV2,
  readResponsibleDepartmentApiV2,
  createResponsibleDepartmentApiV2,
} from '../../../services/api/external-request/responsible-department/responsibleDepartmentApi';
import ICreateResponsibleDepartment from '../../../services/api/external-request/responsible-department/ICreateResponsibleDepartment';
import IUpdateResponsibleDepartment from '../../../services/api/external-request/responsible-department/IUpdateResponsibleDepartment';

interface IFormValuesResponsibleDepartment {
  responsible_department_rendition?: string;
  pk?: number;
}

type PropsType = {
  successHandler: () => void;
  pk?: number;
};

const toFormValuesResponsibleDepartment = (
  data: IResponsibleDepartment
): IFormValuesResponsibleDepartment => ({
  responsible_department_rendition: data.responsible_department_rendition,
  pk: data.responsible_department_pk,
});

const convertData = (payload: {
  responsible_department: IResponsibleDepartment;
}) => toFormValuesResponsibleDepartment(payload.responsible_department);

const ResponsibleDepartmentForm: FC<PropsType> = (props) => {
  const { successHandler, pk } = props;
  const isNew = !pk;
  const [responsibleDepartment, setResponsibleDepartment] =
    useState<IFormValuesResponsibleDepartment>(new ResponsibleDepartment(pk));

  const updateResponsibleDepartment = usePostV3({
    fetchApi: updateResponsibleDepartmentApiV2,
    successHandler,
    successMessage: t('Подразделение  обновлено успешно.'),
  });

  const createResponsibleDepartment = usePostV3({
    fetchApi: createResponsibleDepartmentApiV2,
    successHandler,
    successMessage: t('Подразделение создано успешно.'),
  });

  const { data: formValues, isLoading } = useRead({
    getDataApi: readResponsibleDepartmentApiV2,
    params: { pk },
    convertData,
    condition: !!pk,
    initialData: new ResponsibleDepartment(pk),
  });

  useEffect(() => {
    if (!isNew && formValues) {
      setResponsibleDepartment(formValues);
    }
  }, [formValues, isNew]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={responsibleDepartment}
      validationSchema={ResponsibleDepartment.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        if (!values?.pk) {
          await createResponsibleDepartment(
            values as ICreateResponsibleDepartment,
            setErrors,
            resetForm
          );
        } else {
          await updateResponsibleDepartment(
            values as IUpdateResponsibleDepartment,
            setErrors,
            resetForm
          );
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {!isLoading && (
            <Form onSubmit={handleSubmit} noValidate={true}>
              {!isNew && (
                <HiddenField
                  id="pk"
                  value={values.pk + ''}
                  hasError={errors.pk != null && touched.pk != null}
                />
              )}
              <TextField
                type="text"
                id="responsible_department_rendition"
                label={t('Название')}
                placeholder={t('Название')}
                hasError={
                  errors.responsible_department_rendition != null &&
                  touched.responsible_department_rendition != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={values.responsible_department_rendition}
                required={true}
              />
              <FormGroup className="d-block text-right mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </Form>
          )}
          {isLoading && <LoaderFetch />}
        </>
      )}
    </Formik>
  );
};

export default memo(ResponsibleDepartmentForm);
