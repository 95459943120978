import { FC, useEffect, useState, memo } from 'react';
import CloseButton from 'components/controls/CloseButton';
import { ModalHeader, Modal, ModalBody, Row } from 'reactstrap';
import styles from './index.module.scss';
import AddingForm from './AddingForm/AddingForm';
import RemovingForm from './RemovingForm/RemovingForm';
import TypeOfRequestFunctionEditingEntitiesFromIdentity from './TypeOfRequestFunctionEditingEntitiesFromIdentity';
import { InstallationListKeys } from '../../../services/api/auth/IRegisterIdentity';
import PagerSwitch from 'components/misc/Pagers/PagerSwitch/PagerSwitch';
import { SEARCH_IDENTITY_BY } from './interfaces/interfaces';
import { IMenuItemPager } from 'services/interfaces/IMenuItemPager';
import IdentityFormSetting from 'models/IdentityFormSetting/IdentityFormSetting';
import { useDispatch } from 'react-redux';

export const initialIdentity = {
  hasIdentity: false,
  fullName: '',
  identity_nickname: '',
  identity_phone_number: '',
  installations: [],
  searchIdentityBy: SEARCH_IDENTITY_BY.NICKNAME,
};

type PropsType = {
  isOpen: boolean;
  closeHandler: Function;
  addEntity: TypeOfRequestFunctionEditingEntitiesFromIdentity;
  removeEntity: TypeOfRequestFunctionEditingEntitiesFromIdentity;
  tabs: [IMenuItemPager, IMenuItemPager];
  typeInstallations: InstallationListKeys;
  buttonRemoveText?: string;
  buttonAddText?: string;
};

const EditingEntitiesFromIdentityForm: FC<PropsType> = (props) => {
  const {
    isOpen,
    closeHandler,
    addEntity,
    removeEntity,
    tabs,
    typeInstallations,
    buttonRemoveText,
    buttonAddText,
  } = props;

  const dispatch = useDispatch();

  const [activeContent, setActiveContent] = useState<string>(tabs[0].name);
  const [titleParts, setTitleParts] = useState<{
    add: string;
    remove: string;
  }>({
    add: tabs[0].name,
    remove: tabs[1].name,
  });

  useEffect(() => {
    setTitleParts({ add: tabs[0].name, remove: tabs[1].name });
  }, [dispatch, tabs]);

  const { title } = new IdentityFormSetting(
    tabs,
    activeContent,
    typeInstallations
  );

  const COMPONENT_MAP = {
    [titleParts.add]: (
      <AddingForm
        addEntity={addEntity}
        typeInstallations={typeInstallations}
        buttonAddText={buttonAddText}
      />
    ),
    [titleParts.remove]: (
      <RemovingForm
        removeEntity={removeEntity}
        typeInstallations={typeInstallations}
        buttonRemoveText={buttonRemoveText}
      />
    ),
  };
  const toggle = () => closeHandler();

  return (
    <>
      <Modal
        isOpen={isOpen}
        fade={false}
        toggle={toggle}
        centered={true}
        styles={{ 'max-width': '600px' }}
      >
        <ModalHeader
          toggle={toggle}
          close={<CloseButton onClick={toggle} />}
          className="d-flex flex-row align-items-center"
        >
          {title}
        </ModalHeader>

        <Row className={styles['wrapper-switch-pager']}>
          <PagerSwitch
            setActiveContent={setActiveContent}
            items={tabs}
            customClassName={styles['switch-pager']}
            defaultActiveContent={activeContent}
          />
        </Row>
        <ModalBody>{COMPONENT_MAP[activeContent]}</ModalBody>
      </Modal>
    </>
  );
};

export default memo(EditingEntitiesFromIdentityForm);
