import { RootStateOrAny } from 'react-redux';
import {
  IIdentity,
  IIdentityListOfApplicableInstallationsEntity,
} from '../../services/api/auth/IRegisterIdentity';
import config from 'config.json';

export const getActiveInstallationClientName = (
  state: RootStateOrAny
): string => state.auth.activeInstallation.installationClientName;

export const getIdentityFromStore = (state: RootStateOrAny): IIdentity =>
  state.auth.identity;

export const getInstallationPkFromStore = (
  state: RootStateOrAny
): number | undefined => state.auth.activeInstallation.installationPk;

export const getIdentityListOfApplicableInstallationsFromStore = (
  state: RootStateOrAny
): IIdentityListOfApplicableInstallationsEntity[] | undefined =>
  state.auth.identity?.identity_list_of_applicable_installations;

export const getIdentityPkFromStore = (state: RootStateOrAny) =>
  state.auth?.identity?.identity_pk;

export const getDomainFromStore = (
  state: RootStateOrAny
): keyof typeof config.kladrId => state.auth.activeInstallation.installation;
