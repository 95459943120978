import IBaseGetListParams from 'services/api/interfacesApi/IBaseGetListParams';

export enum SortCriteriaBillingAccountType {
  Default = '',
  NumberAsc = 'billing_account_type_pk',
  NumberDesc = '-billing_account_type_pk',
  RenditionAsc = 'billing_account_type_rendition',
  RenditionDesc = 'billing_account_type_rendition',
}

export interface IGetBillingAccountTypeParams
  extends IBaseGetListParams<SortCriteriaBillingAccountType> {
  billing_account_type_pk?: number;
  billing_account_type_rendition?: string;
  // "billing_account_type_pk_array": [
  //   0
  // ],
  // "billing_account_type_datetime_added_upper_bound": "2022-02-09T15:09:37.965Z",
  // "billing_account_type_datetime_added_lower_bound": "2022-02-09T15:09:37.965Z",
  // "billing_account_type_datetime_edited_lower_bound": "2022-02-09T15:09:37.965Z",
  // "billing_account_type_datetime_edited_upper_bound": "2022-02-09T15:09:37.965Z",
  // "billing_account_type_fulltext_search": "string",
  // "billing_account_type_rendition": "string"
}
