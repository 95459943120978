import useGetFetch from 'hooks/useGetFetch';
import useGetFetchOld from 'hooks/useGetFetchOld';
import { readExternalRequestV2 } from 'services/api/external-request/external-request/externalRequestApi';
import { getPaginatedBookingApiV2 } from 'services/api/vehicle/booking/bookingApi';
import { IHasAttachments } from './IHasAttachments';
import { getFileBuckets } from 'services/api/documents/file-bucket/fileBucketApi';

const initialParamsBooking = { skip: 0, length: 0 };

interface useGetRequestsForAttachmentsProps extends IHasAttachments {}

const useGetRequestsForAttachments = ({
  hasBookings: needBookings = false,
  hasDocuments: needDocuments = false,
  hasInvoice: needInvoice = false,
}: useGetRequestsForAttachmentsProps) => {
  const getBooking = useGetFetch({
    getDataApi: getPaginatedBookingApiV2,
    params: initialParamsBooking,
  });

  const getDocuments = useGetFetchOld({
    getDataApi: getFileBuckets,
  });

  const getExternalRequest = useGetFetch({
    getDataApi: readExternalRequestV2,
    params: { pk: 0 },
  });

  return {
    getBooking: needBookings ? getBooking : null,
    getDocuments: needDocuments ? getDocuments : null,
    getExternalRequest: needInvoice ? getExternalRequest : null,
  };
};

export default useGetRequestsForAttachments;
