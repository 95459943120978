import TemplateMainLayoutHeaderSearch, {
  HEADER_SEARCH_PROP_KEY_LABEL,
  HEADER_SEARCH_PROP_KEY_PK,
} from 'components/layouts/TemplateMainLayoutHeaderSearch/TemplateMainLayoutHeaderSearch';
import { t } from 'services/utils/translation';
import { FC } from 'react';
import { PATH_EXTERNAL_REQUEST_EDIT } from '../../../services/pathConstants';
import { getPaginatedExternalRequestApiV2 } from '../../../services/api/external-request/external-request/externalRequestApi';

const ExternalRequestSearch: FC = () => {
  return (
    <TemplateMainLayoutHeaderSearch
      keys={{
        keyPk: HEADER_SEARCH_PROP_KEY_PK.externalRequest,
        keyForViewText: HEADER_SEARCH_PROP_KEY_LABEL.externalRequest,
      }}
      patch={PATH_EXTERNAL_REQUEST_EDIT}
      searchField={'external_request_pk'}
      getList={getPaginatedExternalRequestApiV2}
      tittle={t('Поиск по номеру заявки')}
    />
  );
};

export default ExternalRequestSearch;
