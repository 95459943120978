import { useCallback, useMemo } from 'react';
import { ValueType } from 'react-select';
import IClientSelectOptionV2 from '../services/api/interfacesApi/IClientSelectOptionV2';

const useVehicleHandleSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
  const handleSearchPk = useCallback(
    (value: string) => {
      onSearchRequest(value, 'vehicle_pk');
    },
    [onSearchRequest]
  );
  const handleSearchType = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2[] | IClientSelectOptionV2,
        boolean
      >
    ) => {
      onSearchRequest(option, 'vehicle_type_fk_array');
    },
    [onSearchRequest]
  );
  const handleSearchRegistrationPlate = useCallback(
    (value: string) => {
      onSearchRequest(value, 'vehicle_registration_plate');
    },
    [onSearchRequest]
  );
  const handleSearchGarageCodeNumber = useCallback(
    (value: string) => {
      onSearchRequest(value, 'vehicle_garage_code_number');
    },
    [onSearchRequest]
  );

  return useMemo(
    () => ({
      handleSearchPk,
      handleSearchGarageCodeNumber,
      handleSearchType,
      handleSearchRegistrationPlate,
    }),
    [
      handleSearchGarageCodeNumber,
      handleSearchPk,
      handleSearchRegistrationPlate,
      handleSearchType,
    ]
  );
};

export default useVehicleHandleSearch;
