import { FC, memo, ReactElement } from 'react';
import SubHeaderLinkWrapperWithTitle from '../FormHeaderLinkWrapperWithTitle/SubHeaderLinkWrapperWithTitle';
import FormHeaderLinkWrapper from '../FormHeaderLinkWrapper/FormHeaderLinkWrapper';
import LinkWitchBlockNavigation from '../FormHeaderLinkWitchBlockNavigation/FormHeaderLinkWitchBlockNavigation';

type PropsType = {
  pk?: number;
  path: string;
  title?: string;
  id?: string;
  children: ReactElement | number | string | undefined;
};

const FormHeaderLink: FC<PropsType> = ({ pk, path, title, id, children }) => {
  return (
    <>
      {title && children && pk && (
        <SubHeaderLinkWrapperWithTitle title={title} id={id}>
          <LinkWitchBlockNavigation path={path} pk={pk}>
            {children}
          </LinkWitchBlockNavigation>
        </SubHeaderLinkWrapperWithTitle>
      )}
      {!title && children && pk && (
        <FormHeaderLinkWrapper id={id}>
          <LinkWitchBlockNavigation path={path} pk={pk}>
            {children}
          </LinkWitchBlockNavigation>
        </FormHeaderLinkWrapper>
      )}
    </>
  );
};

export default memo(FormHeaderLink);
