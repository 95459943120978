import IBaseSubListEntity from 'components/tables/TDSubList/IBaseSubListEntity';
import { ITaskType } from 'services/api/tasks/task-typeV2/IGetTaskTypesPayload';
import toBaseSubListEntity from 'services/utils/toBaseSubListEntity/toBaseSubListEntity';

export interface ITaskTypeForList {
  internal_task_type_pk: number;
  internal_task_type_datetime_added: string;
  internal_task_type_datetime_edited: string;
  internal_task_type_rendition: string;
  internal_task_type_list_of_child_types: IBaseSubListEntity[];
  internal_task_type_parent_type_fk?: number;
}

const convertTaskType = (taskType: ITaskType) => {
  return {
    ...taskType,
    internal_task_type_list_of_child_types: toBaseSubListEntity(
      taskType.internal_task_type_list_of_child_types,
      [
        'internal_task_type_pk',
        'internal_task_type_rendition',
        'internal_task_type_is_active',
      ]
    ),
  };
};

const reduceTaskTypes = (acc: ITaskTypeForList[], curr: ITaskType) => {
  if (curr?.internal_task_type_is_active) {
    const convertingTaskType = convertTaskType(curr);
    acc.push(convertingTaskType);
  }
  return acc;
};

const toTaskTypesForList = (taskTypes: ITaskType[]): ITaskTypeForList[] =>
  taskTypes.reduce(reduceTaskTypes, []);

export default toTaskTypesForList;
