import { useCallback, useEffect, useState } from 'react';

import TBaseOldPostResponse from 'services/api/interfacesApi/TBaseOldPostResponse';
import {
  ISuccessfulResponse,
  IValidationError,
} from 'services/api/interfacesApi/IV2ResponseStatus';
import { catchErrorFromFetch } from 'services/utils/catchAndRegError/catchAndRegError';

const isSuccessfulResponse = <Payload,>(
  payload: ISuccessfulResponse<Payload> | IValidationError
): payload is ISuccessfulResponse<Payload> => 'result' in payload;
interface IUseReadProps<Payload, ConvertData> {
  getDataApi: (pk: number) => Promise<TBaseOldPostResponse<Payload>>;
  convertData: (data: Payload) => ConvertData;
  initialData: ConvertData | null;
  pk: number;
  condition?: boolean;
  errorMessage?: string;
  refreshProp?: boolean;
  successHandler?: () => void;
  offLoad?: boolean;
}

export interface IUseReadOld<ConvertData> {
  data: ConvertData | null;
  isLoading: boolean;
  setData: Function;
  refreshData: () => void;
  request: () => Promise<void>;
}

export function useReadOld<Payload, ConvertData>(
  props: IUseReadProps<Payload, ConvertData>
): IUseReadOld<ConvertData> {
  const {
    getDataApi,
    convertData,
    initialData,
    condition = true,
    pk,
    errorMessage = 'Не возможно загрузить данные. Пожалуйста, свяжитесь с администратором',
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ConvertData | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  const refreshData = () => setRefresh(!refresh);

  const processingRequest = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getDataApi(pk as number);
      if (isSuccessfulResponse(response)) {
        const payload = response.result;
        setData(convertData(payload));
      }
    } catch (error) {
      catchErrorFromFetch(errorMessage, error);
    } finally {
      setIsLoading(false);
    }
  }, [convertData, errorMessage, getDataApi, pk]);

  useEffect(() => {
    if (condition && pk) {
      void processingRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, refresh]);

  return {
    data: data ? data : initialData,
    isLoading,
    setData,
    refreshData,
    request: processingRequest,
  };
}

export default useReadOld;
