import classNames from 'clsx';
import { ReactComponent as InfoIcon } from 'img/icons/icon-question.svg';
import React, { FC, useState, memo } from 'react';
import { Tooltip } from 'reactstrap';
import styles from './index.module.scss';

type TypeProps = {
  id: string;
  helpText: string;
};

const TipButton: FC<TypeProps> = (props) => {
  const { helpText, id } = props;

  const [helpVisible, setHelpVisible] = useState(false);

  const toggleHelp = () => {
    setHelpVisible((value: boolean) => !value);
  };

  const tooltipId = `tooltip-${id}`;

  return (
    <>
      <button
        id={tooltipId}
        type="button"
        className={classNames(styles.helpIcon, { 'd-block': helpText != null })}
        onClick={toggleHelp}
      >
        <InfoIcon />
      </button>
      <Tooltip
        placement="auto"
        isOpen={helpVisible}
        target={tooltipId}
        toggle={toggleHelp}
        trigger="click hover focus"
        innerClassName={styles.tooltip}
        arrowClassName={styles.tooltipArrow}
        popperClassName={styles.tooltipWrapper}
        fade={false}
        autohide={false}
      >
        {helpText}
      </Tooltip>
    </>
  );
};

export default memo(TipButton);
