import { TypeSetValue } from 'hooks/useLocalStorage';
import React, { memo, useCallback } from 'react';
import { TMyFilter } from '../ControlPanelForMyFilters/ControlPanelForMyFilters';

import styles from './index.module.scss';
import classNames from 'clsx';

import ButtonDelete from 'components/controls/ButtonDelete/ButtonDelete';

type PropsType<TypeFilter> = {
  myFilterList: TMyFilter<TypeFilter>[];
  setActiveFilter: Function;
  saveMyFilter: TypeSetValue<TMyFilter<any>[]>;
  saveFiltersInStore: Function;
  successHandler: () => void;
};

function getName<TypeFilter>(myFilter: TMyFilter<TypeFilter>) {
  return Object.keys(myFilter)[0];
}

function MyFilterList<TypeFilter>(props: PropsType<TypeFilter>) {
  const {
    myFilterList,
    setActiveFilter,
    saveMyFilter,
    saveFiltersInStore,
    successHandler,
  } = props;

  const save = saveMyFilter as TypeSetValue<TMyFilter<TypeFilter>[]>;

  const getNameMyFilter = useCallback((myFilter: TMyFilter<TypeFilter>) => {
    return getName(myFilter);
  }, []);

  const deleteMyFilter = (
    event: React.MouseEvent<Element, MouseEvent>,
    myFilterName: string
  ) => {
    event.preventDefault();
    const newListMyFilters = myFilterList.filter(
      (myFilter: TMyFilter<TypeFilter>) =>
        getNameMyFilter(myFilter) !== myFilterName
    );
    save(newListMyFilters);
  };

  const extractedHandlerUseMyFilter = (targetFilter: TypeFilter) => () => {
    setActiveFilter(targetFilter);
    saveFiltersInStore(targetFilter);
    successHandler();
  };

  return (
    <>
      {myFilterList.map((myFilter: TMyFilter<TypeFilter>, i: number) => {
        const myFilterName = getNameMyFilter(myFilter);
        const handlerUseMyFilter = extractedHandlerUseMyFilter(
          myFilter[myFilterName]
        );
        return (
          <div
            key={myFilterName + i}
            className="d-flex align-items-center justify-center mt-2"
          >
            <div
              className={styles.myFilterWrapper}
              onClick={handlerUseMyFilter}
            >
              <span className={styles.myFilterText}>{myFilterName}</span>
              <ButtonDelete
                onClick={(event) => deleteMyFilter(event, myFilterName)}
                className={classNames(styles.buttonDelete, 'ml-2')}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default memo(MyFilterList);
