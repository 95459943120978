import classNames from 'clsx';
import React, { ChangeEvent, ChangeEventHandler, FC, memo } from 'react';
import { Input, Label } from 'reactstrap';
import styles from './index.module.scss';

type ButtonRadioItem = {
  value: string | number;
  label: string;
};

type ButtonRadiosPropsType = {
  checkedItem: string | number;
  items: ButtonRadioItem[];
  name: string;
  onChange: Function;
  hasError?: boolean;
  customClassNameWrapper?: string;
};

export const ButtonRadios: FC<ButtonRadiosPropsType> = (props) => {
  const {
    items,
    checkedItem,
    name,
    onChange,
    customClassNameWrapper,
    hasError = false,
  } = props;

  return (
    <div className={customClassNameWrapper}>
      <div
        className={classNames(
          styles['btn-radios'],
          'my-2',

          {
            'is-invalid': hasError,
          }
        )}
      >
        {items.map(({ value, label }) => (
          <ButtonRadio
            key={value}
            name={name}
            value={value}
            label={label}
            checked={value === checkedItem}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.value)
            }
          />
        ))}
      </div>
    </div>
  );
};

type ButtonRadioPropsType = {
  checked: boolean;
  value: string | number;
  label: string;
  name: string;
  className?: string;
  onChange: ChangeEventHandler;
};

const ButtonRadio: FC<ButtonRadioPropsType> = (props) => {
  const { value, name, checked, onChange, label } = props;

  return (
    <div className={styles['btn-radio']}>
      <Input
        id={`btn-radio-${value}`}
        type="radio"
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <Label for={`btn-radio-${value}`} className="py-2 px-3" check>
        {label}
      </Label>
    </div>
  );
};

export default memo(ButtonRadios);
