import classNames from 'clsx';
import React, { FC, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import { THexToColorsKeys } from '../../tables/misc/colorTr';

type StatusProps = {
  color?: THexToColorsKeys;
  className?: string;
};

const colorMap = {
  '#FFFFFF': true,
  '#F5F6F8': true,
  '#F6DBDE': true,
  '#B3B4B6': true,
  '#C84958': false,
  '#49A6C8': false,
  '#C8BB49': false,
  '#49C89A': false,
  '#8743AD': false,
  '#7F8C8D': false,
  '': false,
};

const getWhite = (statusColor?: THexToColorsKeys) => {
  return statusColor && !colorMap[statusColor] ? 'lightyellow' : '';
};

const ColoredStatusLabel: FC<PropsWithChildren<StatusProps>> = (props) => {
  const { color = '#FFFFFF', children, className } = props;
  const isArchiveStatus = getWhite(color);

  const labelStyle = classNames(className, {
    [styles.statusLabel]: !isArchiveStatus,
    [styles.statusLabelWhite]: isArchiveStatus,
    'p-2': true,
  });
  const style = {
    backgroundColor: color,
    color: getWhite(color),
    maxWidth: '150px',
    // "word-break": 'break-word',
  };
  return (
    <div className={labelStyle} style={style}>
      <span className={styles.statusLabelText}>{children}</span>
    </div>
  );
};

export default ColoredStatusLabel;
