import { VEHICLE_SAVE_FILTERS, VEHICLE_CLEAR_FILTERS } from './actionTypes';

const vehicleReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case VEHICLE_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case VEHICLE_CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.filters,
      };

    default:
      return state;
  }
};

export default vehicleReducer;
