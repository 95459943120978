import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { saveBillingAccountFilters } from '../../../../../store/billingAccount/actions';
import { saveCounterpartyBillingAccountFilters } from 'store/counterparty/actions';
import { IBillingAccountFilters } from 'services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';

const useSaveBillingAccountFilters = (counterpartyPk?: number) => {
  const dispatch = useDispatch();

  return useCallback(
    (filters: IBillingAccountFilters) => {
      if (!counterpartyPk) {
        return dispatch(saveBillingAccountFilters(filters));
      } else {
        return dispatch(
          saveCounterpartyBillingAccountFilters(filters, counterpartyPk)
        );
      }
    },
    [counterpartyPk, dispatch]
  );
};

export default useSaveBillingAccountFilters;
