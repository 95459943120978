import useWsMessageHandlers from './useWsMessageHandlers';

type EVENT =
  | 'number_of_notification_unreacted_to'
  | 'new_notification'
  | 'clickable_link'
  | 'resource_was_created_or_updated';

type IEventMap = {
  [x in EVENT]: () => void;
};

const useWsEventHandler = () => {
  const {
    toastForNotification,
    handlerNumberOfNotificationUnreactedTo,
    toastForCreateOrUpdate,
    handlerClickableLink,
  } = useWsMessageHandlers();

  return (message: string, event: EVENT) => {
    const EVENT_MAP: IEventMap = {
      number_of_notification_unreacted_to: () =>
        handlerNumberOfNotificationUnreactedTo(message),
      new_notification: () => toastForNotification(message),
      clickable_link: () => handlerClickableLink(message),
      resource_was_created_or_updated: () => toastForCreateOrUpdate(message),
    } as const;

    const messageHandler: () => void | undefined = EVENT_MAP?.[event];

    return !!messageHandler && messageHandler();
  };
};

export default useWsEventHandler;
