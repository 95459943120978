import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import { useColumnFilter } from 'hooks/useColumnFilter';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import { FC, useMemo } from 'react';
import { t } from 'services/utils/translation';

import { IModal } from '../../../hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { useGetList, IUseGetList } from '../../../hooks/useGetList';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { Pager } from 'components/tables/Pager';
import useModalV2 from 'hooks/useModalV2';
import config from '../../../config.json';
import TDSubList from 'components/tables/TDSubList/TDSubList';
import toVehicleTypeForList, {
  IVehicleTypeForList,
} from './toVehicleTypeForList';
import VehicleTypeForm from 'components/vehicle/VehicleTypeForm/VehicleTypeForm';
import IGetPaginatedParamsVehicleType from 'services/api/vehicle/vehicle-type/IGetPaginatedParamsVehicleType';
import {
  deleteVehicleTypeApiV2,
  getPaginatedVehicleTypeApiV2,
} from 'services/api/vehicle/vehicle-type/vehicleTypeApi';
import { targetAccessPolicyVehicleType } from '../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyVehicle';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { UI_TITLE } from '../../../services/localLocalization/UITitle';
import { getHintForDropdownDelete } from '../../../services/utils/extractGetLeftValueIfTrue';
import { extractGetLeftValueIfTrue } from '../../../services/utils/extractGetLeftValueIfTrue';

const deleteMessageError = t('Не удалось удалить тип машины');

const MODAL_EDIT_TITLE = t('Редактировать тип машин');
const MODAL_CREATE_TITLE = t('Создать тип машин');
const MODAL_DELETE_TITLE = t('Удалить тип машин');

const CREATE_BUTTON_TITLE = t('Добавить тип машины');

const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);
const COLUMN_LABELS = {
  PK: '№',
  TYPE: t('Тип'),
};
const filters = [
  { name: 'pk', label: COLUMN_LABELS.PK },
  { name: 'rendition', label: COLUMN_LABELS.TYPE },
] as const;
const columnVisibilityInitial = {
  pk: true,
  rendition: true,
};

const VehicleTypePage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial);

  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyVehicleType,
      targetAction: ['U', 'C', 'D'],
    });

  const initialParams: IGetPaginatedParamsVehicleType = useMemo(
    () => ({
      skip: 0,
      length: config.pageLength,
    }),
    []
  );

  const {
    data: vehicleTypes,
    isLoading,
    refreshListData,
    onSearchRequest,
    onSortClick,
    setStart,
    setLength,
    params: {
      skip,
      length,
      vehicle_type_pk: filterPk,
      vehicle_type_rendition: filterRendition,
    },
    total,
  }: IUseGetList<
    IVehicleTypeForList[],
    IGetPaginatedParamsVehicleType
  > = useGetList({
    getDataApi: getPaginatedVehicleTypeApiV2,
    initialParams,
    convertData: toVehicleTypeForList,
  });

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetVehicleType },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteVehicleTypeApiV2,
    entityIdKey: 'pk',
  });

  return (
    <MainLayout>
      <Subheader>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <AddIcon className="mr-2 text-white" /> {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={`id pr-0 ${!visibility.pk ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field="vehicle_type_pk"
                  title={COLUMN_LABELS.PK}
                  onSort={onSortClick}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'vehicle_type_pk');
                  }}
                  defaultValue={filterPk ? filterPk + '' : ''}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${!visibility.rendition ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field="vehicle_type_rendition"
                  title={COLUMN_LABELS.TYPE}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'vehicle_type_rendition');
                  }}
                  onSort={onSortClick}
                  defaultValue={filterRendition}
                />
              </th>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {vehicleTypes &&
                vehicleTypes.map((vehicleType: IVehicleTypeForList) => {
                  const {
                    vehicle_type_pk: pk,
                    vehicle_type_list_of_child_types: listChild,
                    vehicle_type_rendition: rendition,
                  } = vehicleType;
                  return (
                    <tr key={pk}>
                      <td
                        align="center"
                        className={!visibility.pk ? 'd-none' : ''}
                      >
                        {pk}
                      </td>
                      <TDSubList
                        openEditForm={openAddEditForm}
                        openDeleteForm={toggleDeleteForm}
                        listChild={listChild}
                        subListParentName={rendition}
                        visibility={visibility.rendition}
                      />

                      <td align="right">
                        <ActionsDropdown>
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={UI_TITLE.EDIT}
                              onClick={openAddEditForm.bind(null, {
                                pk,
                                rendition,
                              })}
                              icon={<EditIcon className="text-primary" />}
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                rendition,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                              disabled={listChild?.length > 0}
                              hint={getHintForDropdownDelete(
                                listChild?.length > 0
                              )}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />

        <LoaderFetch />
        <ModalWrapper
          headerLabel={getModalTitle(!!targetVehicleType?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <VehicleTypeForm
            successHandler={successHandler}
            pk={targetVehicleType?.pk}
          />
        </ModalWrapper>
        {targetVehicleType?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetVehicleType?.pk}
            entityName={targetVehicleType?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default VehicleTypePage;
