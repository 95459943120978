import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { useMemo } from 'react';
import {
  targetAccessPolicyBooking,
  targetAccessPolicyVehicle,
} from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyVehicle';

const useGetBookingAccessPolicy = () => {
  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyBooking,
      targetAction: ['U', 'C', 'D'],
    });

  const [vehicleHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyVehicle,
    targetAction: ['R'],
  });

  return useMemo(
    () => ({
      vehicleHaveAccessToRead,
      booking: {
        haveAccessToCreate,
        haveAccessToUpdate,
        haveAccessToDelete,
      },
    }),
    [
      haveAccessToCreate,
      haveAccessToDelete,
      haveAccessToUpdate,
      vehicleHaveAccessToRead,
    ]
  );
};

export default useGetBookingAccessPolicy;
