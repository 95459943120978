import { usePostV3 } from '../usePostV3';
import { doCloseEventApi } from '../../services/api/organizationsV2/event/eventApi';
import { useCallback } from 'react';
import { IEventClosedParams } from '../../services/api/organizationsV2/event/IEventIsClodedParams';
import { t } from 'services/utils/translation';

const SUCCESS_MESSAGE_EVENT_OPENED = t('Событие успешно отредактировано');

const useEventToClosed = () => {
  const doCloseEvent = usePostV3({
    fetchApi: doCloseEventApi,
    viewClientTextToast: true,
    successMessage: SUCCESS_MESSAGE_EVENT_OPENED,
  });

  return useCallback(
    async (params: IEventClosedParams) => {
      await doCloseEvent(params);
    },
    [doCloseEvent] 
  );
};

export default useEventToClosed;
