import React, { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useSetGlobalError from 'hooks/useSetGlobalError/useSetGlobalError';
import AppRouter from 'components/routes/AppRouter';
import useInitSentry from 'components/routes/AppRouter/hooks/useInitSentry';
const App: FC = () => {
  useSetGlobalError();
  useInitSentry();
  return <AppRouter />;
};

export default App;
