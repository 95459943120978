import { useEffect, useRef, useState } from 'react';

const useDelayedHover = (delay: number) => {
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef<number | undefined>(undefined);

  const handleMouseEnter = () => {
    // Запускаем таймер для задержки вызова обработчика hover
    timeoutRef.current = window.setTimeout(() => {
      setIsHovered(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    // Если мышь покинула элемент, сбрасываем таймер и сбрасываем состояние
    clearTimeout(timeoutRef.current);
    setIsHovered(false);
  };
  useEffect(() => {
    return () => {
      // При размонтировании компонента очищаем таймер, чтобы избежать утечек памяти
      clearTimeout(timeoutRef.current);
    };
  }, [delay]);

  return { isHovered, handleMouseLeave, handleMouseEnter };
};

export default useDelayedHover;
