import IBaseGetListParams from 'services/api/interfacesApi/IBaseGetListParams';

export enum SortCriteriaResponsibleDepartment {
  Default = '',
  NumberAsc = 'responsible_department_pk',
  NumberDesc = '-responsible_department_pk',
  RenditionAsc = 'responsible_department_rendition',
  RenditionDesc = '-responsible_department_rendition',
}

export interface IGetPaginatedParamsResponsibleDepartment
  extends IBaseGetListParams<SortCriteriaResponsibleDepartment> {
  responsible_department_pk?: number;
  responsible_department_rendition?: string;

  // "work_category_pk_array": [
  //   0
  // ],
  // "work_category_fulltext_search": "string",
}
