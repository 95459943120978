import { TargetAccessPolicy } from './TargetAccessPolicy';
import { TargetAccessPolicyService } from './TargetAccessPolicyService';

 const targetAccessPolicyControllerService =
  new TargetAccessPolicyService('controller');

export const targetAccessPolicyController = new TargetAccessPolicy(
  targetAccessPolicyControllerService,
  'Controller'
).getTargetAccessPolicyStatement();
