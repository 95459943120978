import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import * as Yup from 'yup';
import { t } from 'services/utils/translation';

class BillingAccount {
  pk?: number;
  billing_account_description?: string = '';
  billing_account_type?: IClientSelectOptionV2<number>;
  billing_account_code_number?: string;
  billing_account_1c_number?: string;
  billing_account_stek_number?: string;
  billing_account_address?: string;
  billing_account_is_closed?: boolean;
  counterparty?: IClientSelectOptionV2<number>;

  constructor(pk?: number, counterpartyPk?: number) {
    this.pk = pk;
    this.counterparty = BillingAccount.getInitialCounterparty(counterpartyPk);
  }

  static getInitialCounterparty = (counterpartyPk?: number) => {
    return counterpartyPk ? { label: '', value: counterpartyPk } : undefined;
  };

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      billing_account_description: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.')),
      billing_account_type: Yup.object().nullable().required(t('Укажите тип.')),
      counterparty: Yup.object().nullable().required(t('Укажите контрагента.')),
      billing_account_code_number: Yup.string()
        .trim()
        .required(t('Укажите номер лицевого счета.')),
      billing_account_1c_number: Yup.string().trim().notRequired(),
      billing_account_stek_number: Yup.string().trim().notRequired(),
      billing_account_address: Yup.string().trim().required(t('Укажите адрес')),
    });
  };
}

export default BillingAccount;
