import React, { FC, memo } from 'react';

import { GroupTypeBase, ValueType } from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

import SelectGroup from '../../controls/SelectGroup/SelectGroup';
import useGetSelectStyle from 'hooks/useGetSelectStyle';
import MultiValueLabelWithTooltip from 'components/misc/MultiValueLabelWithTooltip/MultiValueLabelWithTooltip';
import WitchTooltipSelect from 'components/misc/WithTooltip/WitchTooltipSelect';

type PropsType = {
  id: string;
  title: string;
  placeholder?: string;
  onChange: Function; //ChangeEventHandler,
  required?: boolean;
  value?: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>;
  options?: GroupTypeBase<IClientSelectOptionV2>[];
  showDefaultOption?: boolean;
  defaultOptionLabel?: string;
  disabled?: boolean;
  className?: string;
  isMulti: boolean;
  haveReadPermission?: boolean;
};

const HeaderSelectGroup: FC<PropsType> = (props) => {
  const {
    id,
    title,
    onChange,
    className,
    options,
    placeholder,
    isMulti = false,
    value,
    haveReadPermission = true,
  } = props;

  const selectStyles = useGetSelectStyle();

  return (
    <WitchTooltipSelect id={id} haveReadPermission={haveReadPermission}>
      <SelectGroup
        id={id}
        options={options}
        placeholder={placeholder ? placeholder : title}
        onChange={onChange}
        className={className}
        isMulti={isMulti}
        selectStyles={selectStyles}
        value={value}
        customComponents={{ MultiValue: MultiValueLabelWithTooltip }}
      />
    </WitchTooltipSelect>
  );
};

export default memo(HeaderSelectGroup);
