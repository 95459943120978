import { t } from "../../../../services/utils/translation";

export const COLUMN_LABELS_EVENT = {
    PK: '№',
    NAME: t('Наименование'),
    TYPE: t('Тип события'),
    BILLING_ACCOUNT: t('Лицевой счет'),
    IS_CLOSED: t('Статус'),
    CREATE_DATE: t('Дата создания'),
    EDITING_DATE: t('Дата редактирования'),
} as const;
export const filtersEvent = [
    {name: 'pk', label: COLUMN_LABELS_EVENT.PK},
    {
        name: 'event_name',
        label: COLUMN_LABELS_EVENT.NAME,
    },
    {name: 'event_type_fk_array', label: COLUMN_LABELS_EVENT.TYPE},
    {
        name: 'event_billing_account',
        label: COLUMN_LABELS_EVENT.BILLING_ACCOUNT,
    },
    {
        name: 'event_is_closed',
        label: COLUMN_LABELS_EVENT.IS_CLOSED,
    },
    {
        name: 'event_datetime_added',
        label: COLUMN_LABELS_EVENT.CREATE_DATE,
    },
    {
        name: 'event_datetime_edited',
        label: COLUMN_LABELS_EVENT.EDITING_DATE,
    },
] as const;
export const columnVisibilityInitialEvent = {
    pk: true,
    event_name: true,
    event_type_fk_array: true,
    event_billing_account: true,
    event_is_closed: true,
    event_datetime_added: false,
    event_datetime_edited: false,
};
