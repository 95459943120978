import { MouseEvent, useCallback } from 'react';

export enum PagerAction {
  first = 'first',
  previous = 'previous',
  next = 'next',
  last = 'last',
  number = 'number',
}

type PropsTypeusePagerHandlers = {
  onPageChange: (skip: number) => void;
  saveFilters?: (filters: any) => void;
  total: number;
  start: number;
  length: number;
  currentPage: number;
  totalPages: number;
};

const keyStartIndex = 'skip';

//TODO: NEED REACTORINg - switch/case replace to ACTION_MAP or new Class()
const usePagerHandlers = (props: PropsTypeusePagerHandlers) => {
  const { onPageChange, length, currentPage, totalPages, saveFilters } = props;

  const changePage = useCallback(
    (action: PagerAction, page?: number) => {
      switch (action) {
        case PagerAction.first:
          const skipFirst = 0;
          onPageChange(skipFirst);
          saveFilters && saveFilters({ [keyStartIndex]: skipFirst });
          break;
        case PagerAction.previous:
          const skipPrevious = (currentPage - 1) * length - length;
          onPageChange(skipPrevious);
          saveFilters && saveFilters({ [keyStartIndex]: skipPrevious });

          break;
        case PagerAction.next:
          const skipNext = currentPage * length;
          onPageChange(skipNext);
          saveFilters && saveFilters({ [keyStartIndex]: skipNext });
          break;
        case PagerAction.last:
          const skipLast = (totalPages - 1) * length;
          onPageChange(skipLast);
          saveFilters && saveFilters({ [keyStartIndex]: skipLast });

          break;
        case PagerAction.number:
          const skipNumber = (page! - 1) * length;

          onPageChange(skipNumber);
          saveFilters && saveFilters({ [keyStartIndex]: skipNumber });
          break;
        default:
          break;
      }
    },
    [onPageChange, saveFilters, currentPage, length, totalPages]
  );

  return useCallback(
      (action: PagerAction) =>
          (event: MouseEvent<HTMLAnchorElement>, page?: number) => {
            event.preventDefault();
            changePage(action, page);
          },
      [changePage]
  );
};

export default usePagerHandlers;
