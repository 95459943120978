import classNames from 'clsx';
import React, { ChangeEvent, FC, memo } from 'react';
import { CustomInput } from 'reactstrap';
import styles from './index.module.scss';

type RadioProps = {
  defaultChecked: boolean;
  checked?: boolean;
  value: string;
  label: string;
  name: string;
  id: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const Radio: FC<RadioProps> = (props) => {
  const {
    value,
    label,
    // defaultChecked,
    name,
    className,
    onChange,
    id,
    checked,
    disabled,
  } = props;

  return (
    <CustomInput
      type="radio"
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      label={label}
      className={classNames(styles.radio, className)}
      checked={checked}
      disabled={disabled}
    />
  );
};

export default memo(Radio);
