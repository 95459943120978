import { t } from '../utils/translation';

export const BILLING_ACCOUNT_TEXT = t('лицевой счет');
export const WORK_CATEGORY_TEXT = t('классификация работ');
export const RESPONSIBLE_DEPARTMENT_TEXT = t('подразделение');
export const TASK_TEXT = t('задача');
export const COUNTERPARTY_TEXT = t('контрагент');
export const EMPLOYEE_TEXT = t('сотрудник');
export const EVENT_TEXT = t('событие');
export const TASK_TYPES_TEXT = t('типы задач');
export const TASK_STATUSES_TEXT = t('статусы задач');
export const RELATED_BILLING_ACCOUNTS = t('Связанные ЛС');
export const ENDING_A = t('а');
export const ENDING_S = t('ы');

export const CONTROLLER_TEXT = 'контроллер';
