import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { IDocumentFormValues } from '../IDocumentFormValues';
import { TValueOf } from 'services/utils/TSUtils/TValueOf';

const fileBucketValuesToFormData = (values: IDocumentFormValues) => {
  const clearValues = deleteUnnecessaryParameters(values, [
    'file_bucket_schema',
    'uploadedFiles',
  ]);
  return Object.entries(clearValues).reduce(
    (
      formData,
      [key, value]: [keyof IDocumentFormValues, TValueOf<IDocumentFormValues>]
    ) => {
      if (key === 'list_of_uploaded_files') {
        value.forEach((file: File) =>
          formData.append(`list_of_uploaded_files`, file)
        );
      } else if (key === 'parent_ui_element') {
        formData.append(key, JSON.stringify(value));
      } else if (value) {
        formData.append(key as string, value);
      }
      return formData;
    },
    new FormData()
  );
};

export default fileBucketValuesToFormData;
