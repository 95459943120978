import { useMemo } from 'react';
import { getPasswordStrength } from '../useGetStringCharacteristics/utils/getPasswordStrength';

const useGetPasswordStrength = (password?: string) => {
  return useMemo(() => {
    return getPasswordStrength(password);
  }, [password]);
};

export default useGetPasswordStrength;
