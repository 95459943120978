import useCreateUniqueIdKey from 'hooks/useCreateUniqueIdKey';
import useLocalStorage, { TypeSetValue } from 'hooks/useLocalStorage';

const useIsIgnoreStartingPathWasVisited = (): [
  boolean,
  TypeSetValue<boolean>
] => {
  const uniqId = useCreateUniqueIdKey();
  const uniqKeyForLatVisitedPath = `isIgnoreLastVisitedPath_${uniqId}`;

  const [isIgnoreLastVisitedPath, setIsIgnoreLastVisitedPath] = useLocalStorage(
    {
      key: uniqKeyForLatVisitedPath,
      initialValue: false,
    }
  );

  return [isIgnoreLastVisitedPath, setIsIgnoreLastVisitedPath];
};

export default useIsIgnoreStartingPathWasVisited;
