import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';
import { useCallback } from 'react';
import { IClientComment } from '../types/IClientComment';
import toClientComments from '../utils/toClientComments/toClientComments';

const useToClientComments = (currentEmployeePk?: number) => {
  return useCallback(
    (commentsListPayload: IGetCommentsPayloadV2): IClientComment[] => {
      return toClientComments(commentsListPayload, currentEmployeePk);
    },
    [currentEmployeePk]
  );
};

export default useToClientComments;
