import useGetCommonTabs from 'hooks/useGetCommonTabs/useGetCommonTabs';
import { useGetTabExternalRequest } from 'hooks/useGetTabExternalRequest/useGetTabExternalRequest';
import { useMemo } from 'react';
import { parentUIElement } from 'services/constants/parentUIElement';

type PropsType = {
  eventPk: number;
};

const useGetTabsEventView = ({ eventPk }: PropsType) => {
  const { externalRequestTab, isLoadingExternalRequest } =
    useGetTabExternalRequest({
      pk: eventPk,
      parentUI: parentUIElement.event,
    });

  const {
    setRefreshDocuments,
    isLoadingDocument,
    commonTabs: [, historyTab, documentTab],
  } = useGetCommonTabs({
    parentUIElement: parentUIElement.event,
    id: eventPk,
  });

  const memoTabs = useMemo(
    () => [externalRequestTab, historyTab, documentTab],
    [documentTab, externalRequestTab, historyTab]
  );

  const isLoadingCountTabs = isLoadingDocument || isLoadingExternalRequest;

  return {
    tabs: memoTabs,
    refreshTabs: setRefreshDocuments,
    isLoadingCountTabs,
  };
};

export default useGetTabsEventView;
