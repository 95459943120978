import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import React, { FC, memo, useEffect, useState } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';
import { usePostV3 } from '../../../hooks/usePostV3';
import BillingAccountType from '../../../models/BillingAccountType';
import useRead from 'hooks/useRead';

import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import {
  updateBillingAccountTypeApiV2,
  createBillingAccountTypeApiV2,
} from '../../../services/api/organizationsV2/billing-account-type/billingAccountTypaApi';
import IBillingAccountType from 'services/api/organizationsV2/billing-account-type/IBillingAccountType';
import { readBillingAccountTypeApiV2 } from '../../../services/api/organizationsV2/billing-account-type/billingAccountTypaApi';
import ICreateBillingAccountType from '../../../services/api/organizationsV2/billing-account-type/ICreateBillingAccountType';
import IUpdateBillingAccountType from '../../../services/api/organizationsV2/billing-account-type/IUpdateBillingAccount';

interface IFormValuesBillingAccountType {
  billing_account_type_rendition?: string;
  pk?: number;
}

type PropsType = {
  successHandler: () => void;
  pk?: number;
};

const toFormValuesBillingAccountType = (
  data: IBillingAccountType
): IFormValuesBillingAccountType => ({
  billing_account_type_rendition: data.billing_account_type_rendition,
  pk: data.billing_account_type_pk,
});

const convertData = (payload: { billing_account_type: IBillingAccountType }) =>
  toFormValuesBillingAccountType(payload.billing_account_type);

const BillingAccountTypeForm: FC<PropsType> = (props) => {
  const { successHandler, pk } = props;
  const isNew = !pk;
  const [billingAccountType, setBillingAccountType] =
    useState<IFormValuesBillingAccountType>(new BillingAccountType(pk));

  const updateBillingAccountType = usePostV3({
    fetchApi: updateBillingAccountTypeApiV2,
    successHandler,
    successMessage: t('Тип лицевого счета обновлен успешно.'),
  });

  const createBillingAccountType = usePostV3({
    fetchApi: createBillingAccountTypeApiV2,
    successHandler,
    successMessage: t('Тип лицевого счета создан успешно.'),
  });

  const { data: formValues, isLoading } = useRead({
    getDataApi: readBillingAccountTypeApiV2,
    params: { pk },
    convertData,
    initialData: new BillingAccountType(pk),
    condition: !!pk,
  });

  useEffect(() => {
    if (!isNew && formValues) {
      setBillingAccountType(formValues);
    }
  }, [formValues, isNew]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={billingAccountType}
      validationSchema={BillingAccountType.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        if (!values?.pk) {
          await createBillingAccountType(
            values as ICreateBillingAccountType,
            setErrors,
            resetForm
          );
        } else {
          await updateBillingAccountType(
            values as IUpdateBillingAccountType,
            setErrors,
            resetForm
          );
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {!isLoading && (
            <Form onSubmit={handleSubmit} noValidate={true}>
              {!isNew && (
                <HiddenField
                  id="pk"
                  value={values.pk + ''}
                  hasError={errors.pk != null && touched.pk != null}
                />
              )}
              <TextField
                type="text"
                id="billing_account_type_rendition"
                label={t('Тип')}
                placeholder={t('Название типа')}
                hasError={
                  errors.billing_account_type_rendition != null &&
                  touched.billing_account_type_rendition != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={values.billing_account_type_rendition}
                required={true}
              />
              <FormGroup className="d-block text-right mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </Form>
          )}
          {isLoading && <LoaderFetch />}
        </>
      )}
    </Formik>
  );
};

export default memo(BillingAccountTypeForm);
