import classNames from 'clsx';
import { ReactComponent as FolderIcon } from 'img/icons/icon-folder.svg';
import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';
import CounterUI from 'components/layouts/CounterUI/CounterUI';
import { ReactComponent as FavoriteIcon } from '../../../img/icons/icon-star-fill.svg';
import useFavoriteFolderItemHandler from './useFolderItemHandlers';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { t } from '../../../services/utils/translation';

type FolderItemProps = {
  to: string;
  title: string;
  className?: string;
  hasNotification?: boolean;
  isFavorite?: boolean | null;
  name: string;
};

const FolderItem: FC<FolderItemProps> = (props) => {
  const { to, title, className, hasNotification, isFavorite, name } = props;

  const isSubMenu = isFavorite !== null;

  const onFavoriteHandler = useFavoriteFolderItemHandler({
    menuItemProps: {
      to,
      name,
      title,
      isFavorite,
    },
  });

  const tooltipText = isFavorite
    ? t('Убрать из избранного')
    : t('Добавить в избранное');

  return (
    <NavLink className={classNames(styles.folder, className)} to={to}>
      {hasNotification && (
        <CounterUI count={null} className={styles['has-notification']} />
      )}
      <div className="d-flex justify-content-between">
        <FolderIcon className={classNames(styles.folderIcon)} />
        {isSubMenu && (
          <>
            <ToolTipWrapper placement="top" target={`Tooltip-${name}`}>
              {tooltipText}
            </ToolTipWrapper>
            <div id={`Tooltip-${name}`}>
              <FavoriteIcon
                onClick={onFavoriteHandler}
                className={classNames(styles.notIsFavoriteIcon, {
                  [styles.isFavoriteIcon]: isFavorite,
                })}
              />
            </div>
          </>
        )}
      </div>
      <div className={classNames(styles.title)}>
        <span>{title}</span>
      </div>
    </NavLink>
  );
};

export default memo(FolderItem);
