import React, { FC, memo } from 'react';
import { Formik } from 'formik';
import { Button, Form } from 'reactstrap';
import { t } from 'services/utils/translation';

import { usePostV3 } from 'hooks/usePostV3';
import IdentityToInstallations from '../../../../models/IdentityToInstallations';
import { convertClientOptionsToNumbers } from 'services/utils/selects/selects';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { getInstallationsForSelect } from '../../../../services/api/services/installations';
import TypeOfRequestFunctionEditingEntitiesFromIdentity from '../TypeOfRequestFunctionEditingEntitiesFromIdentity';
import { InstallationListKeys } from 'services/api/auth/IRegisterIdentity';
import { TYPE_SELECT } from '../../../controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import IIdentityInstallationsForm from 'services/api/identity/IIdentityInstallationsForm';
import FindIdentity from 'components/identity/FindIdentity/FindIdentity';
import useHandleClickButtonFindIdentity from '../hooks/useHandleClickButtonFindIdentity';
import { IFormValuesIdentityEditing } from '../interfaces/interfaces';
import { useSelector } from 'react-redux';
import { getIdentityInControlPanelFromStore } from 'store/identity/selectors';

const toFormRequest = (
  values: IFormValuesIdentityEditing
): IIdentityInstallationsForm => {
  return {
    nickname: values.identity_nickname,
    list_of_installation_pks:
      values.list_of_installation.length > 0
        ? convertClientOptionsToNumbers(values.list_of_installation)
        : [],
  };
};

type PropsType = {
  addEntity: TypeOfRequestFunctionEditingEntitiesFromIdentity;
  typeInstallations: InstallationListKeys;
  buttonAddText?: string;
};

const AddingForm: FC<PropsType> = (props) => {
  const { addEntity, typeInstallations, buttonAddText = 'Сохранить' } = props;
  const identity = useSelector(getIdentityInControlPanelFromStore);
  const handleClickButtonAddon =
    useHandleClickButtonFindIdentity(typeInstallations);

  const addIdentityToInstallations = usePostV3({
    fetchApi: addEntity,
    successHandler: () => handleClickButtonAddon(identity),
  });

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={new IdentityToInstallations(identity)}
        validationSchema={IdentityToInstallations.validationSchema}
        onSubmit={async (values) => {
          const convertValues = toFormRequest(
            values as IFormValuesIdentityEditing
          );
          await addIdentityToInstallations(convertValues);
        }}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate={true}>
            <FindIdentity
              handleClickAddon={handleClickButtonAddon.bind(null, {
                [values.searchIdentityBy]: values[values.searchIdentityBy],
              })}
              typeInstallations={typeInstallations}
            />
            {typeInstallations !== InstallationListKeys.none && (
              <DynamicSelectPaginatedFieldV2
                id="list_of_installation"
                label={t('Компании')}
                placeholder={t('Выберите компании...')}
                hasError={
                  errors.list_of_installation != null &&
                  touched.list_of_installation != null
                }
                selectHandler={getInstallationsForSelect}
                isMulti={true}
                additionalErrorKey="list_of_installation"
                options={values.list_of_installation}
                required={true}
                type={TYPE_SELECT.fixed}
              />
            )}
            {values.identity_nickname && identity.identity_nickname && (
              <Button color="primary" type="submit">
                {buttonAddText}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default memo(AddingForm);
