import { FC, useEffect, useState, memo } from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { Formik } from 'formik';
import IdentityToInstallations from '../../../../models/IdentityToInstallations';
import { usePostV3 } from 'hooks/usePostV3';
import IClientSelectOptionV2 from '../../../../services/api/interfacesApi/IClientSelectOptionV2';
import classNames from 'clsx';
import styles from './index.module.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import fieldTransition from '../../../../styles/animaion/fieldTransition.module.scss';
import ButtonDelete from '../../../controls/ButtonDelete/ButtonDelete';
import { convertClientOptionsToNumbers } from '../../../../services/utils/selects/selects';

import TypeOfRequestFunctionEditingEntitiesFromIdentity from '../TypeOfRequestFunctionEditingEntitiesFromIdentity';
import { InstallationListKeys } from '../../../../services/api/auth/IRegisterIdentity';
import IIdentityInstallationsForm from 'services/api/identity/IIdentityInstallationsForm';
import FindIdentity from 'components/identity/FindIdentity/FindIdentity';
import useHandleClickButtonFindIdentity from '../hooks/useHandleClickButtonFindIdentity';
import { IFormValuesIdentityEditing } from '../interfaces/interfaces';
import { useSelector } from 'react-redux';
import { getIdentityInControlPanelFromStore } from 'store/identity/selectors';

const toFormRequest = (
  values: IFormValuesIdentityEditing,
  removeInstallations: IClientSelectOptionV2<number>[]
): IIdentityInstallationsForm => ({
  nickname: values.identity_nickname,
  list_of_installation_pks: values.list_of_installation_pks.filter(
    (pkInstallation: number) =>
      !convertClientOptionsToNumbers(removeInstallations).includes(
        pkInstallation
      )
  ),
});

type PropsType = {
  removeEntity: TypeOfRequestFunctionEditingEntitiesFromIdentity;
  typeInstallations: InstallationListKeys;
  buttonRemoveText?: string;
};

const RemovingForm: FC<PropsType> = (props) => {
  const {
    removeEntity,
    typeInstallations,
    buttonRemoveText = 'Сохранить',
  } = props;
  const identity = useSelector(getIdentityInControlPanelFromStore);

  const [installationsListRemove, setInstallationsListRemove] = useState<
    IClientSelectOptionV2[]
  >([]);

  useEffect(() => {
    if (identity.installations) {
      setInstallationsListRemove(identity.installations);
    }
    return () => setInstallationsListRemove([]);
  }, [identity.installations]);

  const removeInstallation = (
    installationPk: number,
    setFieldValue: Function,
    values: number[]
  ) => {
    setInstallationsListRemove((prevState: IClientSelectOptionV2[]) =>
      prevState.filter(
        (installation: IClientSelectOptionV2) =>
          installation.value !== installationPk
      )
    );
    setFieldValue('list_of_installation_pks', values);
  };

  const removeIdentityToInstallations = usePostV3({
    fetchApi: removeEntity,
    successHandler: () => handleClickButtonAddon(identity),
  });

  const handleClickButtonAddon =
    useHandleClickButtonFindIdentity(typeInstallations);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={new IdentityToInstallations(identity)}
        validationSchema={IdentityToInstallations.validationSchema}
        onSubmit={async (values) => {
          const convertValues = toFormRequest(
            values as IFormValuesIdentityEditing,
            installationsListRemove
          );
          await removeIdentityToInstallations(convertValues);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} noValidate={true}>
            <FindIdentity
              handleClickAddon={() => handleClickButtonAddon(values)}
              typeInstallations={typeInstallations}
            />
            {typeInstallations && (
              <FormGroup className="mt-3">
                <fieldset>
                  <CSSTransition
                    in={identity.identity_nickname === values.identity_nickname}
                    timeout={300}
                    classNames={fieldTransition}
                    unmountOnExit
                  >
                    <div
                      className={classNames(
                        'mb-3',
                        styles['installations-scrollable']
                      )}
                    >
                      <TransitionGroup
                        className={styles.fieldBox}
                        in={
                          identity.identity_nickname ===
                          values.identity_nickname
                        }
                      >
                        {installationsListRemove.map(
                          (installation: IClientSelectOptionV2) => (
                            <CSSTransition
                              key={+installation.value}
                              timeout={300}
                              classNames={fieldTransition}
                              unmountOnExit
                            >
                              <div
                                className={'d-flex   align-items-center  mb-3'}
                                style={{
                                  display:
                                    values.list_of_installation_pks.includes(
                                      +installation.value
                                    )
                                      ? 'none'
                                      : 'block',
                                }}
                              >
                                {
                                  <div className=" pr-4">
                                    <Input
                                      id={installation.label}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={installation.label}
                                      disabled={true}
                                    />
                                  </div>
                                }
                                <ButtonDelete
                                  onClick={() =>
                                    removeInstallation(
                                      +installation.value,
                                      setFieldValue,
                                      values.list_of_installation_pks
                                    )
                                  }
                                />
                              </div>
                            </CSSTransition>
                          )
                        )}
                      </TransitionGroup>
                    </div>
                  </CSSTransition>
                </fieldset>
              </FormGroup>
            )}

            {values.identity_nickname && (
              <Button color="primary" type="submit">
                {buttonRemoveText}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default memo(RemovingForm);
