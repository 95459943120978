import {
  localizationActions,
  LocalizationActionsValues,
} from '../../../../pages/staff/access-policy/AccessPolicyPage/toAccessPolicyForList';
import {
  TAccessPolicyEnsembleAll,
  IAccessPolicy,
  TCRUDA,
  TKeysModelAll,
} from '../../../../services/api/staff/access-policy/IAccessPolicy';
import { ILocalization } from '../../../../services/api/general/ILocalization';
import {
  IClientCodeActions,
  IClientAccessPolicyModel,
} from '../AccessPolicyForm/AccessPolicyForm';
import {
  IAccessPolicyStatement,
  TServicesKeys,
} from '../../../../services/api/staff/access-policy/IAccessPolicy';
import AccessPolicy from 'models/AccessPolicy';
import { IUpdateAccessPolicy } from '../../../../services/api/staff/access-policy/ICreateAccessPolicy';
import { useCallback } from 'react';
import { IClientAccessPolicyService } from '../AccessPolicyForm/AccessPolicyForm';
import { getEntries } from 'services/utils/TSUtils/getEntries';
import { makeFirstLetterUppercase } from '../../../../services/utils/stringHelper/stringHelper';

const checkedSectionModels = (
  permissionsForModels: IClientAccessPolicyModel[],
  deleteOrAdd: boolean,
  isAllCheck: boolean = false,
  targetNameModel?: string,
  actionCode?: string
) =>
  permissionsForModels.map((model: IClientAccessPolicyModel) =>
    (isAllCheck && !targetNameModel) || model.nameModel === targetNameModel
      ? {
          ...model,
          actionPermissions: model.actionPermissions.map(
            (code: IClientCodeActions) =>
              isAllCheck || code.actionCode === actionCode
                ? { ...code, checked: deleteOrAdd }
                : code
          ),
        }
      : model
  );

const useAccessPolicyForm = () => {
  const toServerAccessPolicyActionCodes = (codes: IClientCodeActions[]) =>
    codes.reduce((acc: TCRUDA[], cur: IClientCodeActions) => {
      if (cur.checked) {
        acc.push(cur.actionCode);
      }
      return acc;
    }, []);

  const toServerAccessPolicyModels = (
    accessPolicyStatement: IAccessPolicyStatement,
    accessPolicyStatementClient: IClientAccessPolicyService
  ) => {
    const accessPolicyStatementName = accessPolicyStatementClient.serviceName;
    const accessPolicyModels = accessPolicyStatementClient.permissionsForModels;

    return {
      ...accessPolicyStatement,
      [accessPolicyStatementName]: accessPolicyModels.reduce(
        (acc2, curr2: IClientAccessPolicyModel) => {
          return {
            ...acc2,
            [curr2.nameModel]: toServerAccessPolicyActionCodes(
              curr2.actionPermissions
            ),
          };
        },
        {}
      ),
    };
  };

  const toServerAccessPolicyStatement = (
    values: IClientAccessPolicyService[]
  ): IAccessPolicyStatement => {
    return values.reduce(toServerAccessPolicyModels, {});
  };

  const convertActionCodesToClient = (
    targetActionCodes: TCRUDA[],
    fullActionCodes: TCRUDA[]
  ) => {
    return targetActionCodes.map((actionCode: TCRUDA) => ({
      label: localizationActions[actionCode] as LocalizationActionsValues,
      actionCode,
      checked: fullActionCodes ? fullActionCodes.includes(actionCode) : false,
    }));
  };

  const convertAccessPolicyModelToClient = useCallback(
    (
      servicePermissions: TAccessPolicyEnsembleAll,
      localization: ILocalization,
      serviceName: TServicesKeys,
      targetPolicyStatement?: IAccessPolicyStatement
    ) => {
      return getEntries(servicePermissions).map(
        ([nameModel, targetActionCodes]: [
          TKeysModelAll,
          TCRUDA[]
        ]): IClientAccessPolicyModel => {
          const labelAccessPolicyModel = makeFirstLetterUppercase(
            localization[nameModel]
          );

          const service = targetPolicyStatement?.[serviceName] as Record<
            TKeysModelAll,
            TCRUDA[]
          >;

          const fullActionCodes: TCRUDA[] = service?.[nameModel];
          return {
            nameModel,
            labelModel: labelAccessPolicyModel,
            actionPermissions: convertActionCodesToClient(
              targetActionCodes,
              fullActionCodes
            ),
          };
        }
      );
    },
    []
  );

  const convertFullAccessPolicyStatementToClient = useCallback(
    (
      fullAccessPolicy: IAccessPolicyStatement,
      localization: ILocalization,
      targetPolicyStatement?: IAccessPolicyStatement
    ) => {
      return getEntries(
        fullAccessPolicy as Required<IAccessPolicyStatement>
      ).map(([serviceName, servicePermissions]): IClientAccessPolicyService => {
        return {
          labelService: makeFirstLetterUppercase(localization[serviceName]),
          serviceName: serviceName,
          permissionsForModels: convertAccessPolicyModelToClient(
            servicePermissions,
            localization,
            serviceName,
            targetPolicyStatement
          ),
          openServiceList: false,
        };
      });
    },
    [convertAccessPolicyModelToClient]
  );

  const extractToAccessPolicyFormValues =
    (
      localization: ILocalization,
      {
        access_policy_pk,
        access_policy_name,
        access_policy_description,
        access_policy_statement,
      }: IAccessPolicy
    ) =>
    (fullPolicy: IAccessPolicyStatement): AccessPolicy => ({
      pk: access_policy_pk,
      access_policy_name,
      access_policy_description,
      access_policy_statement: convertFullAccessPolicyStatementToClient(
        fullPolicy,
        localization,
        access_policy_statement
      ),
    });

  const extractOnAdderDelete =
    (deleteOrAdd: boolean, isAllCheck: boolean = false) =>
    (
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void,
      prevValue: IClientAccessPolicyService[],
      serviceName: string
    ) =>
    (targetNameModel?: string, actionCode?: string) => {
      const newAccessPolicyStatement: IClientAccessPolicyService[] =
        prevValue.map((service: IClientAccessPolicyService) => {
          if (service.serviceName === serviceName) {
            return {
              ...service,
              permissionsForModels: checkedSectionModels(
                service.permissionsForModels,
                deleteOrAdd,
                isAllCheck,
                targetNameModel,
                actionCode
              ),
            };
          } else {
            return service;
          }
        });
      setFieldValue('access_policy_statement', newAccessPolicyStatement);
    };

  const convertParams = (values: AccessPolicy) =>
    ({
      pk: values?.pk,
      access_policy_name: values?.access_policy_name,
      access_policy_description: values?.access_policy_description,
      access_policy_statement: toServerAccessPolicyStatement(
        values?.access_policy_statement
      ),
    } as IUpdateAccessPolicy);

  return {
    convertParams,
    extractOnAdderDelete,
    extractToAccessPolicyFormValues,
  };
};

export default useAccessPolicyForm;
