import { useCallback, useEffect, useState } from 'react';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';

import { trackPromise } from 'react-promise-tracker';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from '../services/utils/catchAndRegError/catchAndRegError';

interface IUseReadProps<Payload, ConvertData> {
  getDataApi: () => Promise<IBaseResponse<Payload>>;
  convertData: (data: Payload) => ConvertData;
  initialData: ConvertData;
  condition?: boolean;
  errorMessage?: string;
  refresh?: boolean;
  successHandler?: () => void;
  isOneRequest?: boolean;
}

export interface IUseGetRefreshingData<ConvertData> {
  data: ConvertData;
  isLoading: boolean;
  setData: Function;
}

export function useGetDataOld<Payload, ConvertData>(
  props: IUseReadProps<Payload, ConvertData>
): IUseGetRefreshingData<ConvertData> {
  const {
    getDataApi,
    convertData,
    initialData,
    condition = true,
    errorMessage = 'Не удалось загрузить данные',
    successHandler,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ConvertData | null>(null);

  const getData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response: IBaseResponse<Payload> = await trackPromise(getDataApi());
      const {
        result: { verbose, payload, message, outcome },
      } = response;

      if (outcome === 'WARNING') {
        return catchErrorsIfServerStatusFalse(
          verbose ? verbose : errorMessage,
          message
        );
      }
      payload && setData(convertData(payload));
      successHandler && successHandler();
    } catch (e) {
      catchErrorFromFetch(errorMessage, e);
      return;
    } finally {
      setIsLoading(false);
    }
  }, [convertData, errorMessage, getDataApi, successHandler]);

  useEffect(() => {
    if (condition) {
      void getData();
    }
  }, []);

  return { data: data ? data : initialData, isLoading, setData };
}

export default useGetDataOld;
