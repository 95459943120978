import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import IPrimaryKey from 'services/types/IPk';
 import { IBooking, IBookingWrapper } from './IBooking';
import {
  IBookingFilters,
  IGetPaginatedParamsBooking,
} from './IGetPaginatedParamsBooking';
import { ICreateBooking, IUpdateBooking } from './ICreateBooking';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IForSelectRequestV2 } from "../../../utils/generateMethodForSelect/generateMethodForSelect";

const api = () =>
  new UndercurrentApiClient().methodCreator(UNDERCURRENT_CHILD_MODULES.BOOKING);

export const getPaginatedBookingApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsBooking>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IBooking>>> =>
  api().getPaginated(filterParams);

export const readBookingApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IBookingWrapper>> => api().read(params);

export const createBookingApiV2 = async (
  params: IBaseParams<ICreateBooking>
): Promise<IBaseResponse<IBookingWrapper>> => await api().create(params);

export const updateBookingApiV2 = async (
  employee: IBaseParams<IUpdateBooking>
): Promise<IBaseResponse<IBookingWrapper>> => await api().update(employee);

export const deleteBookingApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectBookingApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const exportBookingApiV2 = async (
  filterParams: IBaseParams<IBookingFilters>
): Promise<IBaseResponse<string>> => api().export(filterParams);
