import React from 'react';
import { Col, Row, Table, Container } from 'reactstrap';

import { parseISO } from 'date-fns/esm';
import {
  IExternalRequest,
  IExternalRequestComment,
} from '../../../../services/api/external-request/external-request/IExternalRequest';
import { t } from 'services/utils/translation';
import { IPersonV2 } from '../../../../services/interfaces/IPersonV2';
import { COLUMN_LABELS_EXTERNAL_REQUEST } from 'pages/external-request/external-requests/ExternalRequestPage/utils/columnLabelsExternalRequest';
import { formatDateTime, formatDateTimeShort } from "../../../../services/utils/dateHelper/dateHelper";

type PropsType = {
  printData: {
    externalRequestDetail: IExternalRequest | null;
  };
  className?: string;
};
const ExternalRequestPrintDetail = (props: PropsType) => {
  const {
    printData: { externalRequestDetail },
    className,
  } = props;
  if (externalRequestDetail === null) {
    return <p>{t('Что-то пошло не так')}</p>;
  }
  const {
    external_request_pk: pk,
    external_request_description: description,
    external_request_address_of_incident: address,
    external_request_work_category: workCategory,
    external_request_warehouse_note: warehouseNotes,
    external_request_source_of_information: source,
    external_request_author: author,
    external_request_status: status,
    external_request_date_supposed_duedate: duedate,
    external_request_responsible_department: department,
    external_request_executive_implementer: executiveImplementer,
    external_request_list_of_implementers: implementers,
    external_request_list_of_comments: comments,
    external_request_supervisor: supervisor,
    external_request_datetime_of_actual_commencement: actualCommencement,
    external_request_datetime_of_actual_completion: actualCompletion,
    // external_request_list_of_edits: history,
  } = externalRequestDetail;
  return (
    <Container className={className}>
      <>
        <Row>
          <Col xs="7">
            <h5>
              {t('Заявка')}: №{pk}
            </h5>
            <Table bordered>
              <tbody>
                <tr>
                  <th scope="row">
                    {COLUMN_LABELS_EXTERNAL_REQUEST.DESCRIPTION}
                  </th>
                  <td>{description}</td>
                </tr>
                <tr>
                  <th scope="row">{COLUMN_LABELS_EXTERNAL_REQUEST.ADDRESS}</th>
                  <td>{address}</td>
                </tr>
                {workCategory && (
                  <tr>
                    <th scope="row">Тип работы</th>
                    <td>{workCategory.work_category_rendition}</td>
                  </tr>
                )}
                {warehouseNotes && (
                  <tr>
                    <th scope="row">Заметки для склада</th>
                    <td>{warehouseNotes}</td>
                  </tr>
                )}
                <tr>
                  <th scope="row">Источник</th>
                  <td>{source}</td>
                </tr>

                {author && (
                  <tr>
                    <th scope="row">Диспетчер, кто создал заявку</th>
                    <td>{author.full_name}</td>
                  </tr>
                )}
                {status && (
                  <tr>
                    <th scope="row">Стадия</th>
                    <td>{status.name}</td>
                  </tr>
                )}
                {duedate && (
                  <tr>
                    <th scope="row">Срок исполнения</th>
                    <td>{formatDateTimeShort(parseISO(duedate))}</td>
                  </tr>
                )}
                {/* {order?.actual_date_start && (
                  <tr>
                    <th scope="row">Время исполнения</th>
                    <td>
                      {`c ${formatDateTimeWithoutYear(
                        parseISO(order.actual_date_start)
                      )}
                до ${formatDateTimeWithoutYear(
                  parseISO(order.actual_date_end)
                )}`}
                    </td>
                  </tr>
                )} */}
                {actualCommencement && actualCompletion && (
                  <tr>
                    <th scope="row">{t('Фактическое время исполнения')}</th>
                    <td>
                      {t('C ')}
                      {formatDateTimeShort(parseISO(actualCommencement))}
                      <br />
                      {t('По ')}
                      {formatDateTimeShort(parseISO(actualCompletion))}
                    </td>
                  </tr>
                )}
                {department && (
                  <tr>
                    <th scope="row">Ответственная служба</th>
                    <td>{department?.responsible_department_rendition}</td>
                  </tr>
                )}
                {executiveImplementer && (
                  <tr>
                    <th scope="row">Ответственный исполнитель</th>
                    <td>{executiveImplementer.full_name}</td>
                  </tr>
                )}
                <tr>
                  {implementers && implementers.length > 0 && (
                    <>
                      <th scope="row">Исполнители</th>
                      <td>
                        <ul>
                          {implementers.map((performer: IPersonV2) => {
                            return (
                              <li key={performer.id}>{performer.full_name}</li>
                            );
                          })}
                        </ul>
                      </td>
                    </>
                  )}
                </tr>
                {supervisor && (
                  <tr>
                    <th scope="row">Ответственный руководитель</th>
                    <td>{supervisor.full_name}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
          {comments && comments?.length > 0 && (
            <>
              <Col xs="5">
                <h5>Комментарии</h5>
                <Table bordered>
                  <tbody>
                    {comments?.map((comment: IExternalRequestComment) => (
                      <tr key={comment.external_request_comment_datetime_added}>
                        <td style={{ width: '50%' }}>
                          {comment.external_request_comment_text}
                        </td>
                        <td>
                          {comment.external_request_comment_author?.full_name}
                        </td>
                        <td>
                          {formatDateTime(
                            parseISO(
                              comment.external_request_comment_datetime_added
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </>
          )}
        </Row>
        {/* <Row className={styles.pageBreak}>
          <Row>
            <Col style={{ width: '100%' }}>
              <h5>История</h5>
              <TaskHistory
                taskListOfHistoryEntity={
                  taskHistory?.internal_task_list_of_edits
                }
                creationTaskInfo={{
                  internal_task_datetime_added:
                    taskHistory?.internal_task_datetime_added,
                  internal_task_author_dk: taskHistory?.internal_task_author_dk,
                }}
              />
            </Col>
          </Row>
        </Row> */}
      </>
    </Container>
  );
};

export default ExternalRequestPrintDetail;
