import React, { LegacyRef, forwardRef } from 'react';
import classNames from 'clsx';
import { getColorFromHEX } from 'components/tables/misc/colorTr';
import ColoredStatusLabel from 'components/misc/ColoredStatusLabel';
import { IExternalRequestForList } from '../../../../services/api/external-request/external-request/IExternalRequest';
import { ColumnVisibility } from 'hooks/useColumnFilter';
import {
  ColumnVisibilityExternalRequestsType,
  ExternalRequestColumnLabelsType,
} from 'pages/external-request/external-requests/ExternalRequestPage/utils/columnLabelsExternalRequest';

type PropsType = {
  visibility: ColumnVisibility<ColumnVisibilityExternalRequestsType>;
  className?: string;
  externalRequests: IExternalRequestForList[];
  columnLabels: ExternalRequestColumnLabelsType;
};
const ExternalRequestPrintTable = (
  props: PropsType,
  ref: LegacyRef<HTMLTableElement>
) => {
  const { visibility, className, externalRequests, columnLabels } = props;

  return (
    <table
      ref={ref}
      className={classNames(`table table-bordered  ${className}`)}
    >
      <thead>
        <tr>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.pkAndCodeNumber,
            })}
            style={{ width: '5%', fontWeight: 600 }}
          >
            {columnLabels.PK}
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.createDate,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            {columnLabels.CREATE_DATE}
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.author,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            {columnLabels.AUTHOR}
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.address,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            {columnLabels.ADDRESS}
          </th>
          <th
            scope="col"
            className={classNames('p-2')}
            style={{ width: '10%', fontWeight: 600 }}
          >
            {columnLabels.DESCRIPTION}
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.workCategoryAndResponsibleDepartment,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            {columnLabels.RESPONSIBLE_DEPARTMENT}
          </th>

          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.executiveImplementersAndImplementers,
            })}
            style={{ width: '10%', fontWeight: 600 }}
          >
            {columnLabels.IMPLEMENTERS}
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.executiveImplementersAndImplementers,
            })}
            style={{ width: '18%', fontWeight: 600 }}
          >
            {columnLabels.EXECUTIVE_IMPLEMENTER}
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.duedate,
            })}
            style={{ width: '17%', fontWeight: 600 }}
          >
            {columnLabels.DATE_DUEDATE}
          </th>
          <th
            scope="col"
            className={classNames('p-2', {
              'd-none': !visibility.duedate,
            })}
            style={{ width: '17%', fontWeight: 600 }}
          >
            {columnLabels.STATUS}
          </th>
        </tr>
      </thead>
      <tbody style={{ maxWidth: '2480px', width: '100%' }}>
        {externalRequests?.map(
          ({
            external_request_pk: pk,
            createDate,
            external_request_author_dk: author,
            external_request_address_of_incident: address,
            external_request_description: description,
            external_request_responsible_department: department,
            external_request_executive_implementer_dk: executiveImplementer,
            implementersWithPositions,
            duedate,
            external_request_status: status,
          }) => (
            <tr
              key={pk}
              className={classNames(`table-${getColorFromHEX(status?.color)}`)}
            >
              <td
                className={classNames({
                  'd-none': !visibility.pkAndCodeNumber,
                })}
              >
                {pk}
              </td>
              <td
                className={classNames({
                  'd-none': !visibility.createDate,
                })}
              >
                {createDate}
              </td>
              <td
                className={classNames({
                  'd-none': !visibility.author,
                })}
              >
                {author}
              </td>
              <td
                className={classNames({
                  'd-none': !visibility.address,
                })}
              >
                {address}
              </td>

              <td>{description}</td>

              <td
                className={classNames({
                  'd-none': !visibility.workCategoryAndResponsibleDepartment,
                })}
              >
                {department?.responsible_department_rendition}
              </td>

              <td
                className={classNames({
                  'd-none': !visibility.executiveImplementersAndImplementers,
                })}
              >
                {implementersWithPositions?.map((implementer: string) => {
                  return <div key={implementer}>{implementer}</div>;
                })}
              </td>

              <td
                className={classNames({
                  'd-none': !visibility.executiveImplementersAndImplementers,
                })}
              >
                {executiveImplementer}
              </td>

              <td
                className={classNames({
                  'd-none': !visibility.duedate,
                })}
              >
                {duedate}
              </td>

              <td
                className={classNames({
                  'd-none': !visibility.status,
                })}
              >
                {status && (
                  <ColoredStatusLabel color={status.color}>
                    {status.name}
                  </ColoredStatusLabel>
                )}
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default forwardRef<HTMLTableElement, PropsType>(
  ExternalRequestPrintTable
);
