import React, { FC, memo, useContext } from 'react';
import styles from '../index.module.scss';
import classNames from 'clsx';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { TabContext } from '../PagerWithParameterSwitch/PagerWithParameterSwitch';

const getTabNameFromQueryParams = (patch: any) => patch.search.split('=')[1];

const getTabCountComponent = (isLoading?: boolean, recordsTotal?: number) => {
  if (recordsTotal !== undefined) {
    return isLoading ? (
      <Spinner size="sm" className="" color="info" />
    ) : (
      recordsTotal
    );
  } else {
    return null;
  }
};

type PropsTypeTab = {
  name: string;
  isLoadingCountTabs?: boolean;
  patch: string;
  recordsTotal?: number;
};

const Tab: FC<PropsTypeTab> = ({ name, patch, recordsTotal }) => {
  const { targetTab, isLoading } = useContext(TabContext);
  return (
    <li className="px-2">
      <div className={classNames(styles.container_content)}>
        <Link
          className={classNames(
            styles.menuLink,
            {
              [styles.active]: targetTab === getTabNameFromQueryParams(patch),
            },
            'py-3 px-2'
          )}
          to={patch}
        >
          {name}
        </Link>
        <span className={classNames(styles.badge)}>
          {getTabCountComponent(isLoading, recordsTotal)}
        </span>
      </div>
    </li>
  );
};

export default memo(Tab);
