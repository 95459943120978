import { TargetAccessPolicy } from './TargetAccessPolicy';
import { TargetAccessPolicyService } from './TargetAccessPolicyService';

const targetAccessPolicyPersonnel = new TargetAccessPolicyService('personnel');

export const targetAccessPolicyEmployee = new TargetAccessPolicy(
  targetAccessPolicyPersonnel,
  'Employee'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyForAccess = new TargetAccessPolicy(
  targetAccessPolicyPersonnel,
  'AccessPolicy'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyJobTitle = new TargetAccessPolicy(
  targetAccessPolicyPersonnel,
  'JobTitle'
).getTargetAccessPolicyStatement();
