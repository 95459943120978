import useCreateUniqueIdKey from 'hooks/useCreateUniqueIdKey';
import useLocalStorage, { TypeSetValue } from 'hooks/useLocalStorage';
import { useLocation } from 'react-router';

const regexId = /[0-9]+/g;

const findIdInPathname = (pathName: string) =>
  pathName.split('/').findIndex((value: string) => {
    return regexId.test(value);
  });

const getCurrentNameModelFromLocation = (pathName: string) => {
  const indexIdInPath = findIdInPathname(pathName);
  const indexModelNameInPath = indexIdInPath - 1;
  return pathName.split('/')[indexModelNameInPath];
};

type PropsType<Pk = number | string> = {
  pk: Pk;
  commentValue?: string;
};

const useSaveCommentInDraft = (
  props: PropsType
): [string, TypeSetValue<string>, () => void] => {
  const { pk } = props;
  const location = useLocation();

  const uniqId = useCreateUniqueIdKey(pk);
  const currentNameModel = getCurrentNameModelFromLocation(location.pathname);

  const [commentDraft, setCommentDraft, remove] = useLocalStorage({
    key: `myCommentDraft-${currentNameModel}-${uniqId}`,
    initialValue: '',
  });

  return [commentDraft, setCommentDraft, remove];
};

export default useSaveCommentInDraft;
