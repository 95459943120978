import { ReactComponent as WordIcon } from 'img/icons/icon-file-word.svg';
import { ReactComponent as PDFIcon } from 'img/icons/icon-file-pdf.svg';
import { ReactComponent as IMGIcon } from 'img/icons/icon-file-img.svg';
import { ReactComponent as AnotherFileIcon } from 'img/icons/icon-file-another.svg';
import { ReactComponent as ExcelIcon } from 'img/icons/icon-file-xlsx.svg';

import { ReactElement } from 'react';

const iconMap: { [key: string]: ReactElement } = {
  pdf: <PDFIcon />,
  doc: <WordIcon />,
  docx: <WordIcon />,
  rtf: <WordIcon />,
  jpg: <IMGIcon />,
  jpeg: <IMGIcon />,
  png: <IMGIcon />,
  heic: <IMGIcon />,
  xlsx: <ExcelIcon />,
};

export const getTargetIcon = (fileName: string): ReactElement => {
  const extension = fileName.split('.').pop()?.toLowerCase() || '';
  if (iconMap.hasOwnProperty(extension)) {
    return iconMap[extension];
  }
  return <AnotherFileIcon />;
};
export const getIcons = (files: string[]): ReactElement[] => {
  return files.map((fileName: string) => getTargetIcon(fileName)).slice(0, 5);
};
