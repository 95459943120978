import { IFormValuesEvent } from 'components/organizations/event/EventForm/EventForm';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class Event implements IFormValuesEvent {
  pk?: number;
  event_name?: string;
  eventType?: IClientSelectOptionV2;
  eventBillingAccount?: IClientSelectOptionV2;
  eventIsClosed?: boolean;
  constructor(pk?: number, billingAccountPk?: number) {
    this.pk = pk;
    this.eventBillingAccount = Event.getBillingAccountDefault(billingAccountPk);
  }

  private static getBillingAccountDefault = (billingAccountPk?: number) => {
    if (billingAccountPk) {
      return { label: '', value: billingAccountPk };
    } else {
      return undefined;
    }
  };

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      event_name: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .required(t('Укажите название.')),
      eventType: Yup.object().nullable().required(t('Выберите тип.')),
      eventBillingAccount: Yup.object()
        .nullable()
        .required(t('Выберите лицевой счет.')),
    });
  };
}

export default Event;
