import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IGetBillingAccountTypeParams } from './IBillingAccountTypeGetPaginatedParams';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import IBillingAccountType from './IBillingAccountType';
import IPrimaryKey from 'services/types/IPk';
import ICreateBillingAccountType from './ICreateBillingAccountType';
import IUpdateBillingAccountType from './IUpdateBillingAccount';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.BILLING_ACCOUNT_TYPE
  );

export const getPaginatedBillingAccountTypeApiV2 = async (
  filterParams: IBaseParams<IGetBillingAccountTypeParams>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IBillingAccountType[]>>> =>
  api().getPaginated(filterParams);

export const readBillingAccountTypeApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey<number>>>
): Promise<IBaseResponse<{ billing_account_type: IBillingAccountType }>> =>
  api().read(params);

export const createBillingAccountTypeApiV2 = async (
  params: IBaseParams<ICreateBillingAccountType>
): Promise<IBaseResponse<{ billing_account_type: IBillingAccountType }>> =>
  await api().create(params);

export const updateBillingAccountTypeApiV2 = async (
  billingAccountType: IBaseParams<IUpdateBillingAccountType>
): Promise<IBaseResponse<{ billing_account_type: IBillingAccountType }>> =>
  await api().update(billingAccountType);

export const deleteBillingAccountTypeApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey<number>>
): Promise<IBaseResponse<undefined>> => await api().delete(deleteParams);

export const getForSelectBillingAccountTypeApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);
