export interface IColorChannelWeight {
  redChannelWeight: number;
  greenChannelWeight: number;
  blueChannelWeight: number;
}

class ColorHelper {
  private readonly minColorValue: number = 0;
  private readonly maxColorValue: number = 255;
  private readonly redChannelMask: number = 0xff0000;
  private readonly greenChannelMask: number = 0x00ff00;
  private readonly blueChannelMask: number = 0x0000ff;
  protected readonly hexStringLength: number = 6;
  protected readonly hashShift: number = 5;

  protected adjustBrightness(color: string, amount: number): string {
    const cc = parseInt(color.substring(1), 16);

    let r = (cc & this.redChannelMask) + amount;
    let g = ((cc & this.greenChannelMask) >> 8) + amount;
    let b = (cc & this.blueChannelMask) + amount;

    r = Math.min(Math.max(this.minColorValue, r), this.maxColorValue);
    g = Math.min(Math.max(this.minColorValue, g), this.maxColorValue);
    b = Math.min(Math.max(this.minColorValue, b), this.maxColorValue);

    return `#${((r << 16) | (g << 8) | b)
      .toString(16)
      .padStart(this.hexStringLength, '0')}`;
  }

  protected calculateBrightness(
    colorCode: string,
    {
      redChannelWeight,
      greenChannelWeight,
      blueChannelWeight,
    }: IColorChannelWeight
  ): number {
    return (
      (parseInt(colorCode.substring(1, 3), 16) * redChannelWeight +
        parseInt(colorCode.substring(3, 5), 16) * greenChannelWeight +
        parseInt(colorCode.substring(5, 7), 16) * blueChannelWeight) /
      1000
    );
  }
}

export default ColorHelper;
