import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import React from 'react';
import { components } from 'react-select';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

const LENGTH_FOR_DISPLAY_HINT = 2;

const MultiValueLabelWithTooltip = <T,>(
  props: MultiValueProps<IClientSelectOptionV2<T> | IClientSelectOptionV2<T>[]>
) => {
  const { label, value } = props.data as IClientSelectOptionV2;

  const isTextOverflow = label.length > LENGTH_FOR_DISPLAY_HINT;

  const id = `multi-label-${value}`;
  return (
    <components.MultiValue {...props}>
      <span id={id}>{label}</span>
      {isTextOverflow && (
        <ToolTipWrapper target={id} placement="left-start">
          {label}
        </ToolTipWrapper>
      )}
    </components.MultiValue>
  );
};

export default MultiValueLabelWithTooltip;
