import { IBaseResponse } from '../interfacesApi/IBaseResponse';
import UndercurrentApiClient from '../UndercurrentApiClient';
import { IWrapperObtainLocalization } from './ILocalization';
import { IRequestId } from '../interfacesApi/IBaseParams';
import { IEmployeeWrapper } from '../staff/employee/IEmployee';
import { IAccessPolicyStatement } from '../staff/access-policy/IAccessPolicy';
import ApiActions from '../apiActions';
import { ApiAdditionalActions } from '../apiActions';
import { UNDERCURRENT_CHILD_MODULES } from "../../constants/UNDERCURRENT_CHILD_MODULES";

const api = () =>
  new UndercurrentApiClient().methodCreator(UNDERCURRENT_CHILD_MODULES.GENERAL);

export const getLocalizationApiV2 = async (
  id: IRequestId
): Promise<IBaseResponse<IWrapperObtainLocalization>> =>
  api().getWithoutParams(
    id,
    ApiActions.OBTAIN,
    ApiAdditionalActions.LOCALIZATION
  );

export const getFullAccessPolicyApiV2 = async (
  id: IRequestId
): Promise<IBaseResponse<IAccessPolicyStatement>> =>
  api().getWithoutParams(
    id,
    ApiActions.OBTAIN,
    ApiAdditionalActions.FULL_ACCESS_POLICY_STATEMENT
  );

export const getCurrentEmployeeApiV2 = async (
  id: IRequestId
): Promise<IBaseResponse<IEmployeeWrapper>> =>
  api().getWithoutParams(
    id,
    ApiActions.OBTAIN,
    ApiAdditionalActions.CURRENT_EMPLOYEE
  );

export const getAccessPolicyStatementCurrentEmployeeApiV2 = async (
  id: IRequestId
): Promise<IBaseResponse<string>> =>
  api().getWithoutParams(
    id,
    ApiActions.OBTAIN,
    ApiAdditionalActions.ACCESS_POLICE_CURRENT_EMPLOYEE
  );
