import { extractFullNameFromIdentity } from 'services/utils/misc/misc';
import { getInstallationsFromIdentity } from './getInstallationsFromIdentity';
import {
  IIdentity,
  InstallationListKeys,
} from 'services/api/auth/IRegisterIdentity';
import { SEARCH_IDENTITY_BY } from '../interfaces/interfaces';
import { IIdentityState } from '../interfaces/IIdentityState';

type PropsToClientIdentityState = {
  identity: IIdentity;
  searchIdentityBy: SEARCH_IDENTITY_BY;
  typeInstallations: InstallationListKeys;
};

const toClientIdentityState = ({
  identity,
  searchIdentityBy,
  typeInstallations,
}: PropsToClientIdentityState): IIdentityState => {
  const listInstallation =
    typeInstallations !== InstallationListKeys.none
      ? identity[typeInstallations]
      : [];

  return {
    hasIdentity: true,
    fullName: extractFullNameFromIdentity(identity),
    identity_nickname: identity?.identity_nickname,
    installations: getInstallationsFromIdentity(listInstallation),
    identity_phone_number: identity.identity_phone_number,
    searchIdentityBy,
    listIdentity: [],
  };
};

export default toClientIdentityState;
