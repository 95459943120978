import classNames from 'clsx';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { FC, memo, useCallback } from 'react';
import { Badge } from 'reactstrap';
import {
  IIdentity,
  InstallationListKeys,
} from 'services/api/auth/IRegisterIdentity';
import { extractFullNameFromIdentity } from 'services/utils/misc/misc';
import toClientIdentityState from '../EditingEntitiesFromIdentityForm/utils/toClientIdentityState';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIdentityInControlPanel } from 'store/identity/actions';
import { getIdentityInControlPanelFromStore } from 'store/identity/selectors';

type PropsType = {
  identities: IIdentity[];
  typeInstallations: InstallationListKeys;
};

const ListOfIdentitiesToSelect: FC<PropsType> = (props) => {
  const { identities, typeInstallations } = props;
  const identityInControlPanelState = useSelector(
    getIdentityInControlPanelFromStore
  );
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (identity: IIdentity) => {
      const payloadIdentity = toClientIdentityState({
        identity,
        searchIdentityBy: identityInControlPanelState.searchIdentityBy,
        typeInstallations,
      });
      dispatch(setCurrentIdentityInControlPanel(payloadIdentity));
    },
    [dispatch, identityInControlPanelState.searchIdentityBy, typeInstallations]
  );

  return (
    <div className={styles.badgesContainer}>
      {identities.map((identity: IIdentity) => {
        return (
          <div key={identity.identity_pk}>
            <Badge
              as="button"
              id={`tooltip-${identity.identity_pk}`}
              color="primary"
              className={classNames('ml-1 ', styles.badge)}
              onClick={handleClick.bind(null, identity)}
            >
              {identity.identity_nickname}
            </Badge>
            <ToolTipWrapper
              target={`tooltip-${identity.identity_pk}`}
              placement="top"
            >
              {extractFullNameFromIdentity(identity)}
            </ToolTipWrapper>
          </div>
        );
      })}
    </div>
  );
};

export default memo(ListOfIdentitiesToSelect);
