import { useSelector } from 'react-redux';
import { getBillingAccountFilters } from '../../../../../store/billingAccount/selectors';
import { isEqual } from 'lodash';
import { getCounterpartyBillingAccountTableFiltersFromStore } from 'store/counterparty/selectors';
import { initialBillingAccountsFilters } from '../../../../../store/initialStore/initialBillingAccountsFilters';
import { useMemo } from 'react';

const useGetInitialBillingAccountFilters = (counterpartyPk?: number) => {
  const defaultBillingAccountFilters = useSelector(
    getBillingAccountFilters,
    isEqual
  );

  const defaultCounterpartyFilters = useSelector(
    getCounterpartyBillingAccountTableFiltersFromStore,
    isEqual
  );

  return useMemo(() => {
    if (counterpartyPk) {
      return {
        ...initialBillingAccountsFilters,
        ...defaultCounterpartyFilters?.[counterpartyPk],
        billing_account_counterparty_fk: counterpartyPk,
      };
    } else {
      return defaultBillingAccountFilters;
    }
  }, [
    counterpartyPk,
    defaultBillingAccountFilters,
    defaultCounterpartyFilters,
  ]);
};

export default useGetInitialBillingAccountFilters;
