import TemplateMainLayoutHeaderSearch, {
  HEADER_SEARCH_PROP_KEY_LABEL,
  HEADER_SEARCH_PROP_KEY_PK,
} from 'components/layouts/TemplateMainLayoutHeaderSearch/TemplateMainLayoutHeaderSearch';
import { t } from 'services/utils/translation';
import { FC } from 'react';
import { PATH_TASK_EDIT } from '../../../services/pathConstants';
import { getPaginatedTasksApiV2 } from 'services/api/tasks/tasks/tasksApiV2';

const TaskSearch: FC = () => {
  return (
    <TemplateMainLayoutHeaderSearch
      keys={{
        keyPk: HEADER_SEARCH_PROP_KEY_PK.internalTask,
        keyForViewText: HEADER_SEARCH_PROP_KEY_LABEL.internalTask,
      }}
      patch={PATH_TASK_EDIT}
      searchField={'internal_task_pk'}
      getList={getPaginatedTasksApiV2}
      tittle={t('Поиск по номеру задачи')}
    />
  );
};

export default TaskSearch;
