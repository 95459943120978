import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { useState } from 'react';
import styles from './index.module.scss';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';

import { t } from 'services/utils/translation';
import { TypeSetValue } from '../../../../../hooks/useLocalStorage';
import CreateMyFilter from '../CreateMyFilter/CreateMyFilter';
import MyFilterList from '../MyFilterList/MyFilterList';

export type TMyFilter<Filter> = { [key: string]: Filter };

type PropsType<TypeFilter> = {
  setActiveFilter: Function;
  currentFilter: TypeFilter;
  listMyFilters: TMyFilter<TypeFilter>[];
  saveMyFilter: TypeSetValue<TMyFilter<TypeFilter>[]>;
  saveFiltersInStore: Function;
};

function ControlPanelForMyFilters<CurrentFilter>(
  props: PropsType<CurrentFilter>
) {
  const {
    currentFilter,
    listMyFilters,
    setActiveFilter,
    saveMyFilter,
    saveFiltersInStore,
  } = props;
  const [openModalFilters, setOpenModalFilters] = useState<boolean>(false);
  const [openModalSaveFilters, setOpenModalSaveFilters] =
    useState<boolean>(false);

  const toggleModalFilters = () => setOpenModalFilters(!openModalFilters);
  const toggleModal = () => setOpenModalSaveFilters(!openModalSaveFilters);
  const closeMyFilters = () => setOpenModalFilters(false);

  return (
    <>
      <span onClick={toggleModalFilters}>Мои фильтры</span>
      <button
        id={'addButton'}
        style={{ lineHeight: 0.9 }}
        color="white"
        onClick={toggleModal}
        className={styles.addButton}
      >
        <ToolTipWrapper target={'addButton'} placement="top">
          {t('Добавить  фильтр')}
        </ToolTipWrapper>
        <span style={{ fontSize: ' 2em' }}>+</span>
      </button>
      <ModalWrapper
        isOpen={openModalFilters}
        headerLabel={t('Мои фильтры')}
        closeHandler={toggleModalFilters}
      >
        <MyFilterList
          setActiveFilter={setActiveFilter}
          saveMyFilter={saveMyFilter}
          myFilterList={listMyFilters}
          saveFiltersInStore={saveFiltersInStore}
          successHandler={closeMyFilters}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={openModalSaveFilters}
        headerLabel={t('Добавить фильтр')}
        closeHandler={toggleModal}
      >
        <CreateMyFilter
          currentFilter={currentFilter}
          saveMyFilter={saveMyFilter}
          listEntities={listMyFilters}
          closeModalHandler={toggleModal}
        />
      </ModalWrapper>
    </>
  );
}

export default ControlPanelForMyFilters;
