import { RootStateOrAny } from 'react-redux';
import { IExternalRequestFilters } from '../../services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { IFormValuesExternalRequest } from '../../components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import { IExternalRequestClientAccessPolicy } from './initial';

export const getExternalRequestFiltersFromStore = (
  state: RootStateOrAny
): IExternalRequestFilters => state.externalRequest.tableFilters;

export const getExternalRequestMarkedFromStore = (
  state: RootStateOrAny
): number[] => state.externalRequest.marked;

export const getExternalRequestFieldsValueFromStore = (
  state: RootStateOrAny
): IFormValuesExternalRequest => state.externalRequest.fieldValues;

export const getExternalRequestInitialValuesFromStore = (
  state: RootStateOrAny
): IFormValuesExternalRequest => state.externalRequest.initialValues;

export const getExternalRequestFirstStatusFromStore = (
  state: RootStateOrAny
): IClientSelectOptionV2 | undefined => state.externalRequest.firstStatus;

export const getExternalRequestAccessPolicyFromStore = (
  state: RootStateOrAny
): IExternalRequestClientAccessPolicy =>
  state.externalRequest.externalRequestAccessPolicy.accessPolicy;

export const getExternalRequestIsInstallAccessPolicyFromStore = (
  state: RootStateOrAny
): boolean => state.externalRequest.externalRequestAccessPolicy.isInstalled;
