import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import { regTelephoneNumber } from "../services/utils/myRegex/myRegex";

class VerifyOtp {
  public phone_number: string = '';
  public otp: string = '';
  constructor(phoneNumber: string = '') {
    this.phone_number = phoneNumber;
  }
  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      phone_number: Yup.string()
        .trim()
        .matches(regTelephoneNumber, t('Некорректно введен номер телефона'))
        .required(t('Укажите телефон.')),
      otp: Yup.string().trim().required(t('Введите отправленный код')),
    });
  };
}

export default VerifyOtp;
