import MainLayout from 'components/layouts/MainLayout/MainLayout';
import React, { FC, useMemo } from 'react';
import {
  getHistoryCounterpartyApiV2,
  readCounterpartyApiV2,
} from 'services/api/organizationsV2/counterparty/counterpartyApi';
import Counterparty from 'models/Counterparty';
import CounterpartyForm from '../../../components/organizations/counterparty/CounterpartyForm/CounterpartyForm';
import { targetAccessPolicyCounterparty } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import useGetRefreshingData from '../../../hooks/useGetRefreshingData';
import {
  PATH_ORGANIZATIONS_COUNTERPARTY,
  TAB_QUERY_KEY,
  TAB_QUERY_PARAMS,
} from '../../../services/pathConstants';
import DocumentsPage from 'pages/documents/DocumentsPage/DocumentsPage';
import CounterpartyHistory from 'components/organizations/counterparty/CounterpartyHistory/CounterpartyHistory';
import useGetQueryParameter from 'hooks/useGetQueryParameter';
import PagerWithParameterSwitch from 'components/misc/Pagers/PagerWithParameterSwitch/PagerWithParameterSwitch';
import useUtilsCounterpartyEdit from './hooks/useUtilsCounterpartyEdit';
import { parentUIElement } from 'services/constants/parentUIElement';
import SubHeaderEditEntity from 'components/controls/FormHeader/FormHeaderEditEntity/SubHeaderEditEntity';
import TaskPage from 'pages/internal-task/task/TaskPage/TaskPage';
import useGetCounterpartyTabs from 'hooks/useGetCounterpartyTabs/useGetCounterpartyTabs';
import { formTitleCounterparty } from '../../../services/constants/FormTitles/FormTitles';
import BillingAccountPage from '../billing-accounts/BillingAccountPage/BillingAccountPage';
import { useParams } from 'react-router-dom';
import useSuccessHandlerNavigate from 'hooks/useSuccessHandlerNavigate/useSuccessHandlerNavigate';

type CounterpartyPropsType = {
  id?: string;
};

const CounterpartyEditPage: FC<CounterpartyPropsType> = () => {
  const { id } = useParams<{ id: string }>();

  const pk = Number(id);

  const isNew = !pk;

  const initialParams = useMemo(
    () => ({
      pk,
    }),
    [pk]
  );

  const { tabs, refreshDocumentTabs, isLoadingTab } = useGetCounterpartyTabs({
    id: pk,
  });

  const { toCounterparty, convertHistoryData } = useUtilsCounterpartyEdit();

  const { data: initialValue, isLoading } = useGetRefreshingData({
    getDataApi: readCounterpartyApiV2,
    params: initialParams,
    condition: !isNew,
    convertData: toCounterparty,
    initialData: new Counterparty(pk),
  });

  const { data: historyData, isLoading: isLoadingHistory } =
    useGetRefreshingData({
      getDataApi: getHistoryCounterpartyApiV2,
      initialData: null,
      params: initialParams,
      condition: !isNew,
      convertData: convertHistoryData,
    });

  const [haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyCounterparty,
    targetAction: ['U'],
  });

  const successHandler = useSuccessHandlerNavigate(
    PATH_ORGANIZATIONS_COUNTERPARTY
  );

  const CONTENT_MAP = {
    [TAB_QUERY_PARAMS.edit]: (
      <CounterpartyForm
        successHandler={successHandler}
        pk={pk}
        initialValue={initialValue}
        isLoading={isLoading}
        isViewOnly={!haveAccessToUpdate}
      />
    ),
    [TAB_QUERY_PARAMS.documents]: (
      <DocumentsPage
        parentUIName={parentUIElement.counterparty}
        parentUIPk={pk + ''}
        refreshTabs={refreshDocumentTabs}
        viewOnly={!haveAccessToUpdate}
      />
    ),
    [TAB_QUERY_PARAMS.history]: (
      <CounterpartyHistory
        counterpartyRequestHistory={historyData?.rows}
        creationCounterpartyInfo={historyData?.creationInfo}
        isLoading={isLoadingHistory}
      />
    ),
    [TAB_QUERY_PARAMS.tasks]: <TaskPage counterpartyPk={pk} />,
    [TAB_QUERY_PARAMS.billingAccount]: (
      <BillingAccountPage counterpartyPk={pk} />
    ),
  };

  const subHeaderTitle = formTitleCounterparty.getFormTitle(pk);

  const targetTab =
    (useGetQueryParameter(
      TAB_QUERY_KEY.counterparty
    ) as keyof typeof CONTENT_MAP) || TAB_QUERY_PARAMS.edit;

  const ContentForRender = CONTENT_MAP[targetTab];

  return (
    <MainLayout>
      <SubHeaderEditEntity title={subHeaderTitle} />
      {!isNew && (
        <PagerWithParameterSwitch
          items={tabs}
          targetTab={targetTab}
          isLoadingCountTabs={isLoadingTab}
        />
      )}
      {ContentForRender}
    </MainLayout>
  );
};

export default CounterpartyEditPage;
