export enum TasksSortCriteria {
  Default = '',
  NumberAsc = 'internal_task_pk',
  NumberDesc = '-internal_task_pk',
  EndDateAsc = ' internal_task_date_duedate',
  EndDateDesc = '-internal_task_date_duedate',
  CreateDateAsc = 'internal_task_datetime_added',
  CreateDateDesc = '-internal_task_datetime_added',
  EditDateAsc = 'internal_task_datetime_edited',
  EditDateDesc = '-internal_task_datetime_edited',
}
