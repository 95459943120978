import { IControllerFilters } from 'services/api/controller/IControllerFilters';
import { RootState } from 'store/IRootState';
import { IControllerClientAccessPolicy } from './initial/initialControllerAccessPolicy';
import { IFormValuesController } from 'components/controller/controller/ControllerForm/ControllerForm';

export const getControllerFiltersFromStore = (
  state: RootState
): IControllerFilters => state.controller.tableFilters;

export const getControllerAccessPolicyFromStore = (
  state: RootState
): IControllerClientAccessPolicy => state.controller.accessPolicy.accessPolicy;

export const getControllerIsInstallAccessPolicyFromStore = (
  state: RootState
): boolean => state.controller.accessPolicy.isInstalled;

export const getControllerFieldsValueFromStore = (
  state: RootState
): { [key: string]: IFormValuesController } =>
  state.controller.form.fieldValues;

export const getControllerInitialValuesFromStore = (
  state: RootState
): { [key: string]: IFormValuesController } =>
  state.controller.form.initialValues;
