import { useCallback } from 'react';
import { composeParamsGetController } from '../utils/composeParamsGetController/composeParamsGetController';
import {
  IControllerFilters,
  IControllerPaginatedParams,
} from 'services/api/controller/IControllerFilters';

export const useControllerComposeParams = () => {
  return useCallback(
    (params: IControllerFilters): IControllerPaginatedParams => {
      return composeParamsGetController(params);
    },
    []
  );
};
