import cookies, { CookieAttributes } from 'js-cookie';
class CookieManager {
  private static readonly COOKIE_OPTIONS: CookieAttributes = {
    sameSite: 'none',
    secure: true,
  };

  public setCookie(name: string, value: string) {
    cookies.set(name, value, CookieManager.COOKIE_OPTIONS);
  }

  public getCookie(name: string): string | undefined {
    return cookies.get(name);
  }

  public removeCookie(name: string) {
    cookies.remove(name, CookieManager.COOKIE_OPTIONS);
  }
}

export default CookieManager;
