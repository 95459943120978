import { TargetAccessPolicy } from './TargetAccessPolicy';
import { TargetAccessPolicyService } from './TargetAccessPolicyService';

export const targetAccessPolicyExternalRequestService =
  new TargetAccessPolicyService('external_request');

export const targetAccessPolicyExternalRequest = new TargetAccessPolicy(
  targetAccessPolicyExternalRequestService,
  'ExternalRequest'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyExternalRequestWorkCategory =
  new TargetAccessPolicy(
    targetAccessPolicyExternalRequestService,
    'WorkCategory'
  ).getTargetAccessPolicyStatement();

export const targetAccessPolicyExternalRequestResponsibleDepartment =
  new TargetAccessPolicy(
    targetAccessPolicyExternalRequestService,
    'ResponsibleDepartment'
  ).getTargetAccessPolicyStatement();
