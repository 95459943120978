import IBaseGetListParams from 'services/api/interfacesApi/IBaseGetListParams';

export enum SortCriteriaJobTitle {
  Default = '',
  NumberAsc = 'job_title_pk',
  NumberDesc = '-job_title_pk',
  NameAsc = 'job_title_name',
  NameDesc = '-job_title_name',
  DescriptionAsc = 'job_title_description',
  DescriptionDesc = '-job_title_description',
}

interface IGetPaginatedParamsJobTitle
  extends IBaseGetListParams<SortCriteriaJobTitle> {
  job_title_pk?: number;
  job_title_name?: string;
  job_title_description?: string;
}

export default IGetPaginatedParamsJobTitle;
