import { createMenuItemsFormatForPager } from '../../utils/createMenuItems/createMenuItems';
import { t } from '../../utils/translation';

const pagersContents: { [key: string]: [string, string] } = {
  auth: [t('Вход'), t('Регистрация')],
  editInstallations: [t('Добавить'), t('Удалить')],
  inviteInstallations: [t('Пригласить'), t('Отменить приглашение')],
  ownerShipInstallations: [
    t('Сделать владельцем'),
    t('Удалить право владением'),
  ],
  canInviteInstallations: [
    t('Дать право добавлять'),
    t('Удалить право добавлять'),
  ],
  adminSettings: [t('Сделать админом'), t('Удалить админку')],
};

export const menuItemsAuth = createMenuItemsFormatForPager(pagersContents.auth);

export const menuItemsEditInstallations = createMenuItemsFormatForPager(
  pagersContents.editInstallations
);

export const menuItemsInviteToInstallations = createMenuItemsFormatForPager(
  pagersContents.inviteInstallations
);

export const menuItemsOwnerShipInstallations = createMenuItemsFormatForPager(
  pagersContents.ownerShipInstallations
);

export const menuItemsCanInviteInstallations = createMenuItemsFormatForPager(
  pagersContents.canInviteInstallations
);

export const menuItemsAdminSettings = createMenuItemsFormatForPager(
  pagersContents.adminSettings
);
