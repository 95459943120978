import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

const extractFilterOptions =
  (currentId: string) => (item: IClientSelectOptionV2) =>
    item.value + '' !== currentId;

const extractHandlePayloadOptions =
  (currentId?: string) => (options: IClientSelectOptionV2<number>[]) => {
    if (!currentId) {
      return options;
    } else {
      const filterOptions = extractFilterOptions(currentId);
      //TODO: perhaps we can do without this filter.
      //Since we have a check in the AddParent method to prevent the parent from being assigned itself
      return options.filter(filterOptions);
    }
  };

export default extractHandlePayloadOptions;
