import * as AppYup from 'yup';
import { dataSizeIsValid } from './dataSizeIsValid';
import { dateNotEarlierThan } from './dateNotEarlierThan';
import { dateValid } from './dateValid';
import { innValid } from './innValid';
import { dateRange } from './checkDateRange';
import { requiredWhenDefined } from './requiredWhenDefined';
import { requiredByConditionWithDoubleRef } from './requiredByCondition';
import {
  requiredByCondition,
  requiredByConditionWithRef,
} from './requiredByCondition';
import { fileNameLengthValid } from './fileNameLengthValid';
import { checkPasswordComplexity } from './checkPasswordComplexity';
AppYup.addMethod(AppYup.array, 'requiredWhenDefined', requiredWhenDefined);
AppYup.addMethod(AppYup.string, 'dateNotEarlierThan', dateNotEarlierThan);
AppYup.addMethod(AppYup.string, 'dateValid', dateValid);
AppYup.addMethod(
  AppYup.string,
  'checkPasswordComplexity',
  checkPasswordComplexity
);

AppYup.addMethod(AppYup.string, 'innValid', innValid);
AppYup.addMethod(AppYup.array, 'dataSizeIsValid', dataSizeIsValid);
AppYup.addMethod(AppYup.array, 'fileNameLengthValid', fileNameLengthValid);
AppYup.addMethod(AppYup.string, 'dateRange', dateRange);
AppYup.addMethod(AppYup.string, 'requiredWhenDefined', requiredWhenDefined);
AppYup.addMethod(AppYup.object, 'requiredByCondition', requiredByCondition);
AppYup.addMethod(AppYup.string, 'requiredByCondition', requiredByCondition);
AppYup.addMethod(
  AppYup.object,
  'requiredByConditionWithRef',
  requiredByConditionWithRef
);
AppYup.addMethod(
  AppYup.object,
  'requiredByConditionWithDoubleRef',
  requiredByConditionWithDoubleRef
);
AppYup.addMethod(
  AppYup.array,
  'requiredByConditionWithDoubleRef',
  requiredByConditionWithDoubleRef
);
AppYup.addMethod(
  AppYup.array,
  'requiredByConditionWithDoubleRef',
  requiredByConditionWithDoubleRef
);
AppYup.addMethod(
  AppYup.array,
  'requiredByConditionWithRef',
  requiredByConditionWithRef
);

export default AppYup;
