import { IIdentityState } from 'components/identity/EditingEntitiesFromIdentityForm/interfaces/IIdentityState';
import { SEARCH_IDENTITY_BY } from 'components/identity/EditingEntitiesFromIdentityForm/interfaces/interfaces';

export const initialIdentityInControlPanel: IIdentityState = {
  hasIdentity: false,
  fullName: '',
  identity_nickname: '',
  identity_phone_number: '',
  installations: [],
  searchIdentityBy: SEARCH_IDENTITY_BY.NICKNAME,
};
