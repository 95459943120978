import useGetRefreshingData from 'hooks/useGetRefreshingData';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { readCounterpartyApiV2 } from 'services/api/organizationsV2/counterparty/counterpartyApi';
import toCounterpartyDetails from '../ui/CounterpartyDetailsSelect/toCounterpartyDetails';
import { ICounterpartyWrapper } from 'services/api/organizationsV2/counterparty/ICounterpartyWrapper';

const useGetCounterpartyDetails = (counterpartyPk?: number) => {
  const location = useLocation();

  const initialParams = useMemo(() => {
    return { pk: Number(counterpartyPk) };
  }, [counterpartyPk]);

  const { data: counterpartyDetails, isLoading: isLoadingCounterparty } =
    useGetRefreshingData({
      getDataApi: readCounterpartyApiV2,
      params: initialParams,
      condition: !!counterpartyPk,
      convertData: (response: ICounterpartyWrapper) =>
        toCounterpartyDetails(response.counterparty),
      initialData: null,
    });

  return { isLoadingCounterparty, counterpartyDetails, location };
};

export default useGetCounterpartyDetails;
