import { extractValueFromClientSelectOptionV2 } from 'services/utils/selects/selects';
import { IFormValuesTask } from './TaskForm';
import {
  IUpdateTask,
  ICreateTask,
} from '../../../services/api/tasks/tasks/ICreateTask';
import { getParametersRenullForTask } from '../../../services/utils/renull/renull';
import { createDateFromClientFormForServerV2 } from '../../../services/utils/dateHelper/dateHelper';
import deleteUnnecessaryParameters from '../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import IClientSelectOptionV2 from '../../../services/api/interfacesApi/IClientSelectOptionV2';

const UNNECESSARY_PARAMETERS = [
  'taskPk',
  'statusClient',
  'created_by',
  'taskType',
  'supervisor',
  'account_legal_status',
  'account',
  'status',
  'comments',
  'internal_task_author_dk',
  'internal_task_list_of_comments',
  'counterparty',
  'selectedCounterparty',
];

class TaskFormValues {
  pk?: number = undefined;
  internal_task_new_comment?: string = '';
  internal_task_date_duedate?: string = '';
  internal_task_supervisor_dk?: number = undefined;
  internal_task_type_fk?: number = undefined;
  internal_task_billing_account_fk?: number = undefined;
  internal_task_status_fk?: number = undefined;
  internal_task_counterparty_fk?: number = undefined;
  internal_task_description?: string;
  internal_task_reporter_phone_number?: string;
  internal_task_parent_internal_task_fk?: number = undefined;
  internal_task_parent_external_request_fk?: number = undefined;
  internal_task_time_adjustment?: number;

  constructor(values: IFormValuesTask) {
    const {
      taskPk,
      internal_task_new_comment: newComment,
      internal_task_date_duedate: dateDuedate,
      internal_task_description: description,
      internal_task_reporter_phone_number: phoneNumber,
      account,
      internal_task_parent_external_request_fk: parentExternalRequestFk,
      internal_task_parent_internal_task_fk: parentInternalTask,
      internal_task_time_adjustment: timeAdjustment,
      taskType,
      status,
      supervisor,
      counterparty,
    } = values;

    this.pk = taskPk;
    this.internal_task_description = description;
    this.internal_task_reporter_phone_number = phoneNumber;
    this.internal_task_new_comment = newComment;
    this.internal_task_date_duedate =
      dateDuedate && createDateFromClientFormForServerV2(dateDuedate);
    this.internal_task_supervisor_dk =
      extractValueFromClientSelectOptionV2(supervisor);
    this.internal_task_type_fk = extractValueFromClientSelectOptionV2(taskType);
    this.internal_task_billing_account_fk = TaskFormValues.getBillingAccount(
      account,
      counterparty
    );
    this.internal_task_status_fk = extractValueFromClientSelectOptionV2(status);
    this.internal_task_counterparty_fk = TaskFormValues.getCounterparty(
      counterparty,
      account
    );
    this.internal_task_parent_external_request_fk = parentExternalRequestFk;
    this.internal_task_parent_internal_task_fk = parentInternalTask;
    this.internal_task_time_adjustment = timeAdjustment;
  }

  //TODO need one func
  static getCounterparty = (
    counterparty?: IClientSelectOptionV2,
    billingAccount?: IClientSelectOptionV2
  ) => {
    return counterparty && !billingAccount
      ? extractValueFromClientSelectOptionV2(counterparty)
      : undefined;
  };

  static getBillingAccount = (
    billingAccount?: IClientSelectOptionV2,
    counterparty?: IClientSelectOptionV2
  ) => {
    return billingAccount && !counterparty
      ? extractValueFromClientSelectOptionV2(billingAccount)
      : undefined;
  };

  public getParams = (): ICreateTask | IUpdateTask => {
    const renullParams = getParametersRenullForTask(this, [
      'internal_task_billing_account_fk',
      'internal_task_counterparty_fk',
    ]);
    return deleteUnnecessaryParameters(
      { ...this, ...renullParams },
      UNNECESSARY_PARAMETERS
    );
  };
}

export default TaskFormValues;
