import { useMemo } from 'react';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import { COLUMN_LABELS_EXTERNAL_REQUEST } from '../utils/columnLabelsExternalRequest';
import getHintForSelectArrayOptions from 'services/utils/getHintForSelectArrayOptions/getHintForSelectArrayOptions';

const useGetHintForDoubleFiltersExternalRequest = (
  params: IExternalRequestFilters
) => {
  const {
    external_request_pk: filterPk,
    external_request_external_code_number: filterCodeNumberFilter,
    external_request_executive_implementer_dk: executiveImplementerFilter,
    external_request_implementer_dk_array: implementersFilter,
    external_request_billing_account_fk_array: billingAccountArrayFilter,
    external_request_event_fk_array: eventArrayFilter,
    external_request_work_category_fk_array: workCategoryArrayFilter,
    external_request_responsible_department_fk_array:
      responsibleDepartmentArrayFilter,
  } = params;

  const hintForExecutiveImplementerFilter = `${COLUMN_LABELS_EXTERNAL_REQUEST.EXECUTIVE_IMPLEMENTER}: ${executiveImplementerFilter?.label}`;
  const hintForImplementersFilter = useMemo(
    () =>
      getHintForSelectArrayOptions(COLUMN_LABELS_EXTERNAL_REQUEST.IMPLEMENTERS)(
        implementersFilter
      ),
    [implementersFilter]
  );

  const hintForPkFilter = `${COLUMN_LABELS_EXTERNAL_REQUEST.PK}: ${filterPk}`;
  const hintForCodeNumber = `${COLUMN_LABELS_EXTERNAL_REQUEST.CODE_NUMBER}: ${filterCodeNumberFilter}`;

  const hintForBillingAccountFilter = useMemo(
    () =>
      getHintForSelectArrayOptions(
        COLUMN_LABELS_EXTERNAL_REQUEST.BILLING_ACCOUNT
      )(billingAccountArrayFilter),
    [billingAccountArrayFilter]
  );
  const hintForEventFilter = useMemo(
    () =>
      getHintForSelectArrayOptions(COLUMN_LABELS_EXTERNAL_REQUEST.EVENT)(
        eventArrayFilter
      ),
    [eventArrayFilter]
  );

  const hintForWorkCategoryFilter = useMemo(
    () =>
      getHintForSelectArrayOptions(
        COLUMN_LABELS_EXTERNAL_REQUEST.WORK_CATEGORY
      )(workCategoryArrayFilter),
    [workCategoryArrayFilter]
  );

  const hintForResponsibleDepartmentArrayFilter = useMemo(
    () =>
      getHintForSelectArrayOptions(
        COLUMN_LABELS_EXTERNAL_REQUEST.RESPONSIBLE_DEPARTMENT
      )(responsibleDepartmentArrayFilter),
    [responsibleDepartmentArrayFilter]
  );

  return useMemo(
    () => ({
      hintForExecutiveImplementerFilter,
      hintForImplementersFilter,
      hintForPkFilter,
      hintForCodeNumber,
      hintForBillingAccountFilter,
      hintForEventFilter,
      hintForResponsibleDepartmentArrayFilter,
      hintForWorkCategoryFilter,
    }),
    [
      hintForBillingAccountFilter,
      hintForCodeNumber,
      hintForEventFilter,
      hintForExecutiveImplementerFilter,
      hintForImplementersFilter,
      hintForPkFilter,
      hintForResponsibleDepartmentArrayFilter,
      hintForWorkCategoryFilter,
    ]
  );
};

export default useGetHintForDoubleFiltersExternalRequest;
