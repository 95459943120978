import classNames from 'clsx';
import React, { ChangeEvent, useState, useEffect, memo } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import styles from './index.module.scss';
import usePagerHandlers, { PagerAction } from './hooks/usePagerHandlers';

export type PagerProps = {
  saveFilters?: (filters: any) => void;
  onPageChange: (skip: number) => void;
  onLengthChange: (length: number) => void;
  total: number;
  start: number;
  length: number;
};

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const Pager = (props: PagerProps) => {
  const [countItemsPage, setCountItemPage] = useState<number>(0);
  const { onPageChange, total, start, length, onLengthChange, saveFilters } =
    props; 
  const totalPages = Math.ceil(total / length);
  const currentPage = Math.trunc(start / length) + 1;

  useEffect(() => {
    const actualCount =
      totalPages !== currentPage ? Number(start) + Number(length) : total;
    setCountItemPage(actualCount);
  }, [currentPage, length, start, total, totalPages]);

  const nextPageEnabled = currentPage < totalPages;
  const previousPageEnabled = currentPage > 1;

  const extractPagerHandlers = usePagerHandlers({
    onPageChange,
    total,
    start,
    length,
    totalPages,
    currentPage,
    saveFilters,
  });

  const goFirst = extractPagerHandlers(PagerAction.first);
  const goPrevious = extractPagerHandlers(PagerAction.previous);
  const changePage = extractPagerHandlers(PagerAction.number);
  const goNext = extractPagerHandlers(PagerAction.next);
  const goLast = extractPagerHandlers(PagerAction.last);

  const changeLength = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    onLengthChange(+e.target.value);
    saveFilters && saveFilters({ length: e.target.value });
  };

  if (!total || totalPages === 0) {
    return (
      <table className=" ml-3 mr-4">
        <tbody>
          <tr key="pager">0 записей</tr>
        </tbody>
      </table>
    );
  }
  const pageIndices = Array.from({ length: totalPages }, (x, i) => i + 1);

  const maxPages = 5;
  const currentPageIndex = currentPage - 1;
  let startPageIndex = currentPageIndex - maxPages;
  if (startPageIndex < 0) {
    startPageIndex = 0;
  }
  let endPagesIndex = currentPageIndex + maxPages;
  if (endPagesIndex > totalPages - 1) {
    endPagesIndex = totalPages;
  }
  const pages = pageIndices.slice(startPageIndex, endPagesIndex);
  return (
    <div>
      <div className="d-flex flex-row align-items-center mt-2  ml-3 mr-4 pb-3">
        <div className="ml-3 mr-4">
          {total && (
            <select
              className={classNames('form-control', styles.lengthSwitcher)}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                onPageChange(0);
                changeLength(event);
              }}
              defaultValue={length}
            >
              {PAGE_SIZE_OPTIONS.map((l) => (
                <option key={l} value={l}>
                  {l}
                </option>
              ))}
            </select>
          )}
        </div>
        <div style={{ marginLeft: '10px', marginRight: '20px' }}>
          Показано{' '}
          <span style={{ fontWeight: 'bolder' }}>
            с {start + 1} до {countItemsPage}
          </span>
          {countItemsPage !== total && ` из ${total} записей`}
        </div>
        <Pagination
          size="sm"
          aria-label="Page navigation"
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <PaginationItem disabled={!previousPageEnabled}>
            <PaginationLink
              previous
              href="#"
              onClick={goFirst}
              className="first"
            >
              &lt;&lt;
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={!previousPageEnabled} className="mr-3">
            <PaginationLink
              previous
              href="#"
              onClick={goPrevious}
              className="previous"
            >
              &lt;
            </PaginationLink>
          </PaginationItem>
          {startPageIndex > 0 && (
            <PaginationItem disabled={true}>
              <PaginationLink href="#" className="more">
                ...
              </PaginationLink>
            </PaginationItem>
          )}
          {pages.map((item) => (
            <PaginationItem key={item} active={item === currentPage}>
              <PaginationLink
                href="#"
                onClick={(e) => {
                  changePage(e, item);
                }}
              >
                {item}
              </PaginationLink>
            </PaginationItem>
          ))}
          {endPagesIndex < totalPages - 1 && (
            <PaginationItem disabled={true}>
              <PaginationLink href="#" className="more">
                ...
              </PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem disabled={!nextPageEnabled} className="ml-3">
            <PaginationLink next href="#" onClick={goNext} className="next">
              &gt;
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={!nextPageEnabled}>
            <PaginationLink next href="#" onClick={goLast} className="last">
              &gt;&gt;
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  );
};

export default memo(Pager);
