import { FC } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import './spinner.scss';

type PropsTypeLoaderFetch = {
  type?:
    | 'Audio'
    | 'BallTriangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'Oval'
    | 'Puff'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots'
    | 'Watch'
    | 'RevolvingDot'
    | 'Triangle'
    | 'Plane'
    | 'MutatingDots'
    | 'CradleLoader';
  height?: string;
  width?: string;
};

export const LoaderFetch: FC<PropsTypeLoaderFetch> = (props) => {
  const { type = 'ThreeDots', height = '120', width = '120' } = props;
  const { promiseInProgress } = usePromiseTracker({ delay: 0 });

  return (
    <>
      {promiseInProgress && (
        <div className="spinner">
          <Loader type={type} color="#49A6C8" height={height} width={width} />
        </div>
      )}
    </>
  );
};

export default LoaderFetch;
