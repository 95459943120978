import { useDispatch, useSelector } from 'react-redux';
import { getLocalizationApiV2 } from 'services/api/general/apiGeneralV2';
import localizationDefault from 'services/api/general/defaultLocalization';
import { IWrapperObtainLocalization } from 'services/api/general/ILocalization';
import hasValuesInObject from 'services/utils/hasValueInObject/hasValueInObject';
import { loadLocalization } from 'store/general/actions';
import { getLocalizationFromStore } from 'store/general/selectors';
import useRead from './useRead';

const useLoadLocalization = (authDomain?: number) => {
  const localization = useSelector(getLocalizationFromStore);
  const reduxDispatch = useDispatch();
  const hasLocalization = hasValuesInObject(localization);

  const { isLoading } = useRead({
    getDataApi: getLocalizationApiV2,
    convertData: (payload: IWrapperObtainLocalization) => {
      reduxDispatch(loadLocalization(payload.localization));
      return payload.localization;
    },
    initialData: localizationDefault,
    params: undefined,
    condition: !hasLocalization && !!authDomain,
  });

  return { isLoading, hasLocalization };
};

export default useLoadLocalization;
