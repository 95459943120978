import useSaveLastPath from 'hooks/useSaveLastPath/useSaveLastPath';
import { useCallback } from 'react';
import usePushLastVisitedPath from './usePushLastVisitedPath';
import useBeforeOnload from 'hooks/useBeforeOnload/useBeforeOnload';
import useIsIgnoreStartingPathWasVisited from 'components/routes/AuthRoute/hooks/useIsIgnoreStartingPathWasVisited';

const usePathNavigationPersistence = () => {
  const { saveLastVisitedPath } = useSaveLastPath();
  const [, setIsIgnoreLastVisitedPath] = useIsIgnoreStartingPathWasVisited();

  const handleBeforeUnload = useCallback(() => {
    saveLastVisitedPath();
    setIsIgnoreLastVisitedPath(false);
  }, [saveLastVisitedPath, setIsIgnoreLastVisitedPath]);

  usePushLastVisitedPath();
  useBeforeOnload(handleBeforeUnload);
};

export default usePathNavigationPersistence;
