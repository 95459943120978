import React, { memo, FC } from 'react';
import classNames from 'clsx';
import styles from './index.module.scss';
import { IKeyValueString } from 'services/interfaces/IKeyValue';
import { IKeyValue } from '../../../services/interfaces/IKeyValue';
import { ReactComponent as SettingIcon } from 'img/icons/icon-settings.svg';
import { t } from '../../../services/utils/translation';
import { Link } from 'react-router-dom';
import SubHeaderDetailsItem from '../SubHeaderDetailsItem/SubHeaderDetailsItem';

type TypeProps = {
  mainInfo: IKeyValueString;
  items?: IKeyValue<string | JSX.Element>;
  linkOnRightButton?: string;
  classNameMainInfoValue?: string;
};

const SubHeaderDetails: FC<TypeProps> = (props) => {
  const { mainInfo, items, linkOnRightButton, classNameMainInfoValue } = props;

  return (
    <div
      className={classNames(styles.header, 'd-flex justify-content-between')}
    >
      <div className={classNames('d-flex flex-column flex-sm-row')}>
        <div
          className={classNames(
            styles.account,
            'px-4 pt-4 pb-2 py-sm-4 d-flex flex-column justify-content-center'
          )}
        >
          <div>{mainInfo.title}</div>
          <div className={classNames(classNameMainInfoValue, styles.num)}>
            {mainInfo.value}
          </div>
        </div>
        {items && <SubHeaderDetailsItem items={items} />}
      </div>
      {linkOnRightButton && (
        <Link
          to={linkOnRightButton}
          className={classNames(
            'mt-4 mr-4  text-decoration-none',
            styles['btn-right']
          )}
        >
          <div>
            <SettingIcon />
            <span className="p-2">{t('Редактировать')}</span>
          </div>
        </Link>
      )}
    </div>
  );
};

export default memo(SubHeaderDetails);
