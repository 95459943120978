import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import React, { FC, useEffect, useMemo } from 'react';
import TaskPage from '../../../internal-task/task/TaskPage/TaskPage';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { useDispatch } from 'react-redux';
import DocumentsPage from '../../../documents/DocumentsPage/DocumentsPage';
import { IBillingAccount } from '../../../../services/api/organizationsV2/billing-account/IBillingAccount';
import {
  readBillingAccountV2,
  getHistoryBillingAccountApiV2,
} from '../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import { makeLink } from 'services/localPaths';
import { PATH_ORGANIZATIONS_ACCOUNTS_EDIT } from 'services/pathConstants';
import BillingAccountCounterpartyDetails from 'components/organizations/billing-account/BillingAccountCounterpartyDetails/BillingAccountCounterpartyDetails';
import useTabsAccountView from './hooks/useTabsAccountView';
import { clearBillingAccountFormDataDefaultValue } from '../../../../store/billingAccount/actions';
import useGetRefreshingData from '../../../../hooks/useGetRefreshingData';
import ExternalRequestPage from 'pages/external-request/external-requests/ExternalRequestPage/ExternalRequestPage';

import {
  TAB_QUERY_PARAMS,
  TAB_QUERY_KEY,
} from '../../../../services/pathConstants';
import useGetQueryParameter from 'hooks/useGetQueryParameter';
import { IGetHistoryBillingAccountPayload } from '../../../../services/api/organizationsV2/billing-account/IHistoryBillingAccount';
import BillingAccountHistory from 'components/organizations/billing-account/BillingAccountHistory/BillingAccountHistory';
import { parentUIElement } from 'services/constants/parentUIElement';
import ViewEntity from 'components/layouts/ViewEntity/ViewEntity';
import EventPage from 'pages/organizations/event/EventPage/EventPage';
import useGetBillingAccountLabels from './hooks/useGetBillingAccountLabels';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';
 
const convertHistoryData = (data: IGetHistoryBillingAccountPayload) => ({
  rows: data?.billing_account_list_of_edits,
  creationInfo: {
    billing_account_datetime_added: data?.billing_account_datetime_added,
    billing_account_author_dk: data?.billing_account_author_dk,
    billing_account_author: data?.billing_account_author,
  },
});

type AccountViewPagePropTypes = {
  id?: string;
};

const BillingAccountViewPage: FC<AccountViewPagePropTypes> = () => {

  const id = useGetIdFromPath();
  const billingAccountPkNumber = Number(id);

  const dispatch = useDispatch();

  const { tabs, refreshTabs, isLoadingCountTabs } = useTabsAccountView({
    accountPk: billingAccountPkNumber,
  });

  const initialParams = useMemo(() => {
    return { pk: billingAccountPkNumber };
  }, [billingAccountPkNumber]);

  const { data: historyData, isLoading: isLoadingHistory } =
    useGetRefreshingData({
      getDataApi: getHistoryBillingAccountApiV2,
      initialData: null,
      params: initialParams,
      condition: !!billingAccountPkNumber,
      convertData: convertHistoryData,
    });

  const { data: account } = useGetRefreshingData({
    getDataApi: readBillingAccountV2,
    params: initialParams,
    condition: !!billingAccountPkNumber,
    convertData: (response: { billing_account: IBillingAccount }) =>
      response.billing_account,
    initialData: null,
  });

  const headerSubLabels = useGetBillingAccountLabels(account);

  useEffect(() => {
    return () => {
      dispatch(clearBillingAccountFormDataDefaultValue());
    };
  }, [account, dispatch]);

  const linkOnRightButton = makeLink(
    PATH_ORGANIZATIONS_ACCOUNTS_EDIT,
    billingAccountPkNumber
  );

  const CONTENT_MAP = {
    [TAB_QUERY_PARAMS.tasks]: (
      <TaskPage billingAccountPk={billingAccountPkNumber} />
    ),
    [TAB_QUERY_PARAMS.externalRequest]: (
      <ExternalRequestPage billingAccountPk={billingAccountPkNumber} />
    ),
    [TAB_QUERY_PARAMS.counterparty]: (
      <BillingAccountCounterpartyDetails account={account} />
    ),
    [TAB_QUERY_PARAMS.event]: (
      <EventPage billingAccountPk={billingAccountPkNumber} />
    ),
    [TAB_QUERY_PARAMS.documents]: (
      <DocumentsPage
        parentUIName={parentUIElement.account}
        parentUIPk={billingAccountPkNumber}
        refreshTabs={refreshTabs}
      />
    ),
    [TAB_QUERY_PARAMS.history]: (
      <BillingAccountHistory
        billingAccountRequestHistory={historyData?.rows}
        creationBillingAccountInfo={historyData?.creationInfo}
        isLoading={isLoadingHistory}
      />
    ),
    // [TAB_QUERY_PARAMS.controller]: (
    //   <ControllerPage billingAccountPk={billingAccountPkNumber} />
    // ),
  };

  //TODO create hook or component
  const targetTab = useGetQueryParameter(
    TAB_QUERY_KEY.account
  ) as keyof typeof CONTENT_MAP;

  const ContentForRender = CONTENT_MAP[targetTab || TAB_QUERY_PARAMS.tasks];

  if (account === null) {
    return (
      <MainLayout>
        <Subheader />
        <LoaderFetch />
      </MainLayout>
    );
  }

  return (
    <ViewEntity
      mainInfo={{
        title: 'Лицевой счет',
        value: account.billing_account_code_number + '',
      }}
      tabs={tabs}
      linkOnRightButton={linkOnRightButton}
      items={headerSubLabels || undefined}
      targetTab={targetTab || TAB_QUERY_PARAMS.tasks}
      isLoadingCountTabs={isLoadingCountTabs}
    >
      {ContentForRender}
    </ViewEntity>
  );
};

export default BillingAccountViewPage;
