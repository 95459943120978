import IWrapperExternalRequest from 'services/api/external-request/external-request/IWrapperExternalRequest';
import { TFormValuesTaskWithEvent } from '../interfaces/TFormValuesTaskWithEvent';
import { IInitialValuesFromOrder } from '../interfaces/IInitialValuesFromOrder';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';
import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import Task from 'models/Task';

export const convertExternalRequestToFormValuesTask = (
  externalRequest: IWrapperExternalRequest
): TFormValuesTaskWithEvent => {
  const {
    external_request: {
      external_request_billing_account: billingAccount,
      external_request_description: description,
      external_request_reporter_phone_number: phoneNumber,
      external_request_pk: pk,
      external_request_list_of_implementers,
      external_request_event: event,
    },
  } = externalRequest;

  const firstSupervisor = external_request_list_of_implementers?.[0];
  const initialValuesFromOrder: IInitialValuesFromOrder = {
    internal_task_parent_external_request_fk: pk,
    account: billingAccount
      ? createClientSelectOptionV2(
          billingAccount.billing_account_pk,
          `${billingAccount.billing_account_code_number}`
        )
      : undefined,

    internal_task_description: description,
    supervisor: firstSupervisor
      ? createClientSelectOptionV2(
          firstSupervisor.id,
          firstSupervisor.full_name
        )
      : undefined,
    internal_task_reporter_phone_number: convertToServerPhoneNumber(
      phoneNumber + ''
    ),
    event,
  };

  return { ...new Task(), ...initialValuesFromOrder };
};
