import { useState, useCallback } from 'react';

const useToggle = (initialValue: boolean = false): [boolean, () => void] => {
  const [state, setState] = useState(initialValue);
  const toggle = useCallback(() => {
    setState((state) => !state);
  }, []);
  return [state, toggle];
};

export default useToggle;
