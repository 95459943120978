import { FC, memo } from 'react';
import { ReactComponent as RefreshIcon } from 'img/icons/icon-repeat.svg';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { Spinner } from 'reactstrap';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';
import { TMods } from 'services/types/TMods';
import classNames from 'clsx';
import styles from './index.module.scss';

type PropsType = {
  toggleRefresh(): void;
  disabled?: boolean;
  isLoading?: boolean;
  color?: TABLE_COLOR;
};

const RefreshButton: FC<PropsType> = ({
  toggleRefresh,
  isLoading = false,
  color = TABLE_COLOR.DEFAULT,
}: PropsType) => {
  const ModsIcon: TMods = {
    'text-white': TABLE_COLOR.DEFAULT === color,
    [styles.greyIcon]: TABLE_COLOR.GREY === color,
  };

  return (
    <SubheaderButton
      onClick={toggleRefresh}
      bordered={false}
      hintText={'Обновить'}
      hintTarget={'refreshButton'}
      disabled={isLoading}
    >
      {!isLoading ? (
        <RefreshIcon className={classNames(ModsIcon)} />
      ) : (
        <div className="mr-2  d-flex align-items-center">
          <Spinner
            color={TABLE_COLOR.DEFAULT === color ? 'light' : 'secondary'}
            size="sm"
          />
        </div>
      )}
    </SubheaderButton>
  );
};

export default memo(RefreshButton);
