import { IForSelectRequestWitchParams } from './interfacesApi/IBaseForSelectRequestV2';
import createTimestamp from '../utils/createTimestamp/createTimestamp';
import IBaseParams from './interfacesApi/IBaseParams';
import IPrimaryKey from '../types/IPk';
import { IBaseResponse } from './interfacesApi/IBaseResponse';
import deleteUnnecessaryParametersForGetListV2 from '../utils/deleteUnnecessaryParameters/deleteUnnecessaryParametersForGetListV2';
import IBaseGetListParams from './interfacesApi/IBaseGetListParams';
import ApiClientBase from './ApiClientBase';
import ApiActions from './apiActions';
import { ApiUrlBuilder } from './ApiUrlBuilder';
import { ApiAdditionalActions } from './apiActions';
import { IForSelectResponseV2 } from 'components/controls/DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';
import config from 'config.json';
import { UNDERCURRENT_CHILD_MODULES } from '../constants/UNDERCURRENT_CHILD_MODULES';
import { IForSelectRequestV2 } from '../utils/generateMethodForSelect/generateMethodForSelect';
import deleteUnnecessaryParameters from '../utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import getDefaultUndercurrentInstallation from './getDefaultUndercurrentInstallation';

export enum PARENT_BASE_KEYS {
  task = 'internal_task_parent_internal_task_fk',
  externalRequest = 'external_request_parent_external_request_fk',
}
export interface IFormValuesWithParentBase {
  pk?: number | string;
  [PARENT_BASE_KEYS.task]?: number;
  [PARENT_BASE_KEYS.externalRequest]?: number;
}
export class UndercurrentApiMethods extends ApiClientBase {
  static initialPropsForSelect = {
    params: { search: '', page: 0, length: config.forSelectLength },
    id: createTimestamp(),
  };

  public childModule: UNDERCURRENT_CHILD_MODULES =
    UNDERCURRENT_CHILD_MODULES.DEFAULT;
  constructor(childModule: UNDERCURRENT_CHILD_MODULES) {
    super('undercurrent', getDefaultUndercurrentInstallation(), 'v2');
    this.childModule = childModule;
  }
  //TODO: need refactoring - use id crete in hook / rewrite all forselect
  public forSelect = async (
    {
      params,
      id,
    }: IBaseParams<IForSelectRequestV2> = UndercurrentApiMethods.initialPropsForSelect
  ): Promise<IBaseResponse<IForSelectResponseV2>> => {
    const newParams = {
      params: { search: params.search },
      id,
    };

    const apiUrl = this.createUrlForSelect(params.page);

    return this.postJson(apiUrl, newParams);
  };

  public forSelectWitchAdditionalParams = async ({
    params,
    id,
  }: IBaseParams<IForSelectRequestWitchParams>): Promise<
    IBaseResponse<IForSelectResponseV2>
  > => {
    const newParams = {
      params: deleteUnnecessaryParameters(
        {
          length: config.forSelectLength,
          ...params,
        },
        ['page']
      ),
      id,
    };

    const apiUrl = this.createUrlForSelect(params.page);

    return this.postJson(apiUrl, newParams);
  };

  public read = async <Entity extends Object, Pk extends string | number>(
    params: IBaseParams<Partial<IPrimaryKey<Pk>>>
  ): Promise<IBaseResponse<Entity>> => {
    const pk = params.params?.pk;

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.READ)
      .pk(pk)
      .buildString();

    const { id } = params;
    return this.postJson(apiUrl, { id });
  };

  public create = async <FormValues extends {}, Payload>(
    formValues: IBaseParams<FormValues>
  ): Promise<IBaseResponse<Payload>> => {
    const clearParams = deleteUnnecessaryParameters(formValues);

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.CREATE)
      .buildString();

    const createMethod = this.extractPostJsonV2(
      this.replaceDash(this.childModule)
    );

    return createMethod(apiUrl, clearParams);
  };

  public update = async <FormValues extends { pk?: number | string }, Payload>(
    formValues: IBaseParams<FormValues>
  ): Promise<IBaseResponse<Payload>> => {
    const pk = formValues?.params.pk;
    if (!pk) {
      throw new Error('missing pk');
    }
    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.UPDATE)
      .pk(pk)
      .buildString();

    const put = this.extractPutJson(this.replaceDash(this.childModule));

    return put(apiUrl, formValues);
  };

  public extractUpdateParent =
    (parentKey: PARENT_BASE_KEYS) =>
    async <FormValues extends IFormValuesWithParentBase, Payload>(
      formValues: IBaseParams<FormValues>
    ): Promise<IBaseResponse<Payload>> => {
      const pk = formValues?.params.pk;
      const pkParent = formValues?.params[parentKey as keyof FormValues];
      if (!pkParent) {
        throw new Error('Missing parent pk');
      }

      if (pkParent === pk) {
        throw new Error('You cannot appoint yourself as a parent');
      }

      return this.update(formValues);
    };

  public delete = async (
    deleteParams: IBaseParams<IPrimaryKey>
  ): Promise<IBaseResponse<undefined>> => {
    const {
      params: { pk },
      id,
    } = deleteParams;

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.DELETE)
      .pk(pk)
      .buildString();

    return this.httpDeleteV2(apiUrl, { id });
  };

  addComment = async <
    Pk extends number | string,
    CommentParams extends { pk: Pk; attr: { [key: string]: number } },
    CommentPayload
  >(
    params: IBaseParams<CommentParams>
  ): Promise<IBaseResponse<CommentPayload>> => {
    const { attr, pk } = params.params;
    const clearParams = deleteUnnecessaryParameters(params, ['pk', 'attr']);

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.ADD_COMMENT)
      .pk(pk)
      .buildString();

    return this.postForm(apiUrl, clearParams, {
      attr,
    });
  };

  getComments = async <PK extends number | string>(
    params: IBaseParams<IPrimaryKey<PK>>
  ) => {
    const {
      params: { pk },
      id,
    } = params;

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.READ_COMMENTS)
      .pk(pk)
      .buildString();

    return this.postJson(apiUrl, {
      id,
    });
  };

  getPaginated = async <Filters extends IBaseGetListParams, Payload>(
    params: IBaseParams<Filters>
  ): Promise<IBaseResponse<Payload>> => {
    const {
      params: { skip, length, sort_by },
    } = params;

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.PAGINATED)
      .skip(skip)
      .length(length)
      .buildString();

    const clearParams = deleteUnnecessaryParametersForGetListV2(params);
    return this.postFilters(apiUrl, clearParams, { sort_by });
  };

  export = async <Filters extends IBaseGetListParams, Payload>(
    params: IBaseParams<Filters>
  ): Promise<IBaseResponse<Payload>> => {
    const {
      params: { sort_by },
    } = params;

    const LENGTH_NULLABLE = 0;
    const SKIP_NULLABLE = 0;

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.EXPORT_TO_EXCEL)
      .skip(SKIP_NULLABLE)
      .length(LENGTH_NULLABLE)
      .buildString();

    const clearParams = deleteUnnecessaryParametersForGetListV2(params);

    return this.postFilters(apiUrl, clearParams, { sort_by });
  };

  getOld = async <Payload,>(): Promise<IBaseResponse<Payload>> => {
    const apiUrl = new ApiUrlBuilder()

      .childModule(this.childModule)
      .action(ApiActions.EXTERNAL_REQUEST_LIST_OF_STATUSES)
      .buildString();
    return this.get(apiUrl);
  };

  getWithoutParams = async <Payload,>(
    id: {
      id: number;
    },
    apiActions: ApiActions,
    additionalAction?: ApiAdditionalActions
  ): Promise<IBaseResponse<Payload>> => {
    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(apiActions)
      .additionalAction(additionalAction)
      .buildString();
    return this.postWithoutParams(apiUrl, id);
  };

  public getHistory = async <Entity extends Object, Pk extends string | number>(
    params: IBaseParams<Partial<IPrimaryKey<Pk>>>
  ): Promise<IBaseResponse<Entity>> => {
    const pk = params.params.pk;

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.READ_LIST_OF_EDITS)
      .pk(pk)
      .buildString();

    const { id } = params;
    return this.postJson(apiUrl, { id });
  };

  doUpdate = async <Entity extends Object>(
    params: IBaseParams<IPrimaryKey>,
    action: ApiActions
  ): Promise<IBaseResponse<Entity>> => {
    const {
      params: { pk },
      id,
    } = params;

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(action)
      .pk(pk)
      .buildString();

    return await this.putJson(apiUrl, {
      id,
    });
  };

  createUrlForSelect = (page: number) => {
    return new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.FORSELECT_READ)
      .page(page)
      .length(config.forSelectLength)
      .buildString();
  };

  private replaceDash = (targetString: string) =>
    targetString.replace(/-/gi, '_');
}

export default UndercurrentApiMethods;
