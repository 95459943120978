import { IEventFilters } from 'services/api/organizationsV2/event/IGetPaginatedParamsEvent';
import {
  EVENT_SAVE_FILTERS,
  EVENT_CLEAR_FILTERS,
  EVENT_SAVE_EXTERNAL_REQUEST_FILTERS,
  EVENT_CLEAR_EXTERNAL_REQUEST_FILTERS,
  SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER,
  SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER,
  SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER,
} from './actionTypes';
import initialEventFilters from 'store/initialStore/initialEventFilters';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';

export const saveEventFilters = (filters: IEventFilters) => ({
  type: EVENT_SAVE_FILTERS,
  filters,
});

export const clearEventFilters = () => ({
  type: EVENT_CLEAR_FILTERS,
  filters: initialEventFilters,
});

export const saveEventExternalRequestFilters = (
  filters: IExternalRequestFilters,
  pk: number
) => ({
  type: EVENT_SAVE_EXTERNAL_REQUEST_FILTERS,
  payload: { pk, filters },
});

export const extractClearEventExternalRequestFilters = (pk: number) => () => ({
  type: EVENT_CLEAR_EXTERNAL_REQUEST_FILTERS,
  payload: pk,
});

export const setEventExternalRequestIsActivePkFilter =
  (eventPk: number) => (isActivePkFilter: boolean) => ({
    type: SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER,
    payload: { eventPk, isActivePkFilter },
  });

export const setEventIsActiveWorkCategoryFilter =
  (eventPk?: number) => (isActiveWorkCategoryFilter: boolean) => ({
    type: SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER,
    payload: { eventPk, isActiveWorkCategoryFilter },
  });

export const setEventIsActiveImplementerDkArrayFilter =
  (eventPk?: number) => (isActiveImplementerDkArrayFilter: boolean) => ({
    type: SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER,
    payload: { eventPk, isActiveImplementerDkArrayFilter },
  });
