import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import IClientSelectOptionV2 from '../services/api/interfacesApi/IClientSelectOptionV2';
import { regTransport } from '../services/utils/myRegex/myRegex';

class Vehicle {
  pk?: number;
  vehicle_registration_plate?: string;
  vehicleType?: IClientSelectOptionV2<number>;
  vehicle_garage_code_number?: string;

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      vehicle_registration_plate: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .matches(
          regTransport,
          t('Некорректно введен регистрационный номер транспортного средства')
        )
        .required(t('Укажите регистрационный номер.')),
      vehicleType: Yup.object().nullable().required(t('Укажите тип')),
      vehicle_garage_code_number: Yup.object().nullable(),
    });
  };
}

export default Vehicle;
