import classNames from 'clsx';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { t } from 'services/utils/translation';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { useDispatch, useSelector } from 'react-redux';
import { usePostV3 } from 'hooks/usePostV3';
import {
  createTaskV2,
  updateTaskV2,
} from '../../../services/api/tasks/tasks/tasksApiV2';
import Task from '../../../models/Task';
import {
  IChildInternalTask,
  ITaskListOfComment,
} from '../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import { IUpdateTask } from '../../../services/api/tasks/tasks/ICreateTask';
import { IPersonV2 } from 'services/interfaces/IPersonV2';
import toFormValuesTask from 'store/utils/toFormValuesTaskV2';
import TaskComments from '../TaskComments/TaskComments';
import useNextWindow from 'hooks/useNextWindow';
import { PATH_TASK_EDIT } from '../../../services/pathConstants';
import { IChildExternalRequest } from '../../../services/api/external-request/external-request/IExternalRequest';
import { getCurrentEmployeePkFromStore } from '../../../store/general/selectors';
import { saveTaskInStore, setInitialTask } from '../../../store/task/actions';
import { getTaskFieldsValueFromStore } from '../../../store/task/selectors';
import { IWrapperTask } from '../../../services/api/tasks/tasks/ITask';
import useTaskFormUtils from './hooks/useTaskFormUtils';
import TaskFormFieldsWrapper from './ui/TaskFormFieldsWrapper/TaskFormFieldsWrapper';
import { SELECTED_COUNTERPARTY_IN_TASK } from './constants/selectedCounterparty';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

export interface IFormValuesTask {
  taskPk?: number;
  internal_task_reporter_phone_number?: string;
  internal_task_date_duedate?: string;
  status?: IClientSelectOptionV2<number>;
  internal_task_description?: string;
  taskType?: IClientSelectOptionV2<number>;
  account?: IClientSelectOptionV2<number>;
  supervisor?: IClientSelectOptionV2<number>;
  internal_task_time_adjustment?: number;
  internal_task_author?: IPersonV2;
  internal_task_list_of_comments?: ITaskListOfComment[];
  internal_task_new_comment?: string;
  internal_task_is_pruned?: boolean;
  internal_task_comment_text?: string;
  internal_task_parent_internal_task_fk?: number;
  internal_task_parent_external_request_fk?: number;
  childInternalTasks?: IChildInternalTask[];
  childExternalRequests?: IChildExternalRequest[];
  counterparty?: IClientSelectOptionV2<number>;
  selectedCounterparty?: SELECTED_COUNTERPARTY_IN_TASK;
  isSelectedAffiliate?: boolean;
}

type PropsType = {
  className?: string;
  successHandler: (isSaveAndExit: boolean) => void;
  taskPk?: number;
  viewOnly: boolean;
  refreshHistory?: () => void;
  isLoadingTask?: boolean;
  defaultStatus?: IClientSelectOptionV2<number>;
  isDuplicate?: boolean;
  initialValuesFromOrder?: IFormValuesTask;
  counterpartyPk?: number;
  billingAccountId?: number;
};

const TaskForm: FC<PropsType> = (props) => {
  const {
    successHandler,
    taskPk,
    className,
    viewOnly,
    refreshHistory,
    isLoadingTask,
    defaultStatus,
    isDuplicate = false,
    initialValuesFromOrder,
    counterpartyPk,
    billingAccountId,
  } = props;
  const isNew = !taskPk;

  const isDefaultAccountOrCounterparty = !!counterpartyPk || !!billingAccountId;

  const {
    extractHandlePayloadCreate,
    extractedDisabledFieldTask,
    convertParams,
  } = useTaskFormUtils();

  const isInitialValuesFromOrder = !!initialValuesFromOrder;

  const [isSaveAndExit, setIsSaveAndExit] = useState<boolean>(false);
  const [isLoadingUpdateForm, setIsLoadingUpdateForm] =
    useState<boolean>(false);

  const disableFieldTask = extractedDisabledFieldTask(
    viewOnly,
    isLoadingUpdateForm
  );

  const hasDefaultBillingAccount = !!billingAccountId;
  const taskFieldsValue = useSelector(getTaskFieldsValueFromStore);
  const authorId = useSelector(getCurrentEmployeePkFromStore);

  const dispatchRedux = useDispatch();
  const [task, setTask] = useState<Task | IFormValuesTask>(
    new Task(taskPk, defaultStatus, billingAccountId, counterpartyPk)
  );
  const nextEditPage = useNextWindow(PATH_TASK_EDIT);

  const handlePayloadCreate = extractHandlePayloadCreate(
    isSaveAndExit,
    nextEditPage
  );

  const createTask = usePostV3({
    fetchApi: createTaskV2,
    successMessage: t('Задача создана успешно.'),
    successHandler: () => successHandler(isSaveAndExit),
    convertParams,
    handlePayload: handlePayloadCreate,
    viewClientTextToast: true,
  });

  const handlePayload = (payload: IWrapperTask) => {
    if (!isSaveAndExit && taskPk) {
      const taskFormValues = toFormValuesTask(payload.internal_task);
      setTask(taskFormValues);
      dispatchRedux(saveTaskInStore(taskFormValues));
      dispatchRedux(setInitialTask(taskFormValues));
      refreshHistory && refreshHistory();
    }
  };

  const handleUpdateTask = async (
    values: IFormValuesTask,
    setErrors: Function,
    resetForm: Function
  ) => {
    setIsLoadingUpdateForm(true);
    await updateTask(values as IUpdateTask, setErrors, resetForm);
    setIsLoadingUpdateForm(false);
  };

  const submitForm = async (
    values: IFormValuesTask,
    setErrors: Function,
    resetForm: Function
  ) => {
    values?.taskPk
      ? await handleUpdateTask(values, setErrors, resetForm)
      : await createTask(values, setErrors, resetForm);
  };

  useEffect(() => {
    if (!isNew) {
      setTask(taskFieldsValue);
    }
  }, [dispatchRedux, isNew, taskFieldsValue]);

  const updateTask = usePostV3<IWrapperTask, IUpdateTask>({
    fetchApi: updateTaskV2,
    successMessage: t('Задача изменена успешно.'),
    successHandler: () => successHandler(isSaveAndExit),
    convertParams,
    handlePayload,
    viewClientTextToast: true,
  });

  const viewForm = isNew || (!isLoadingTask && task?.internal_task_author);
  const initialValues = initialValuesFromOrder || task;

  return (
    <Row className="mr-0">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Task.validationSchema(task.taskPk == null)}
        onSubmit={async (values, { setErrors, resetForm, setSubmitting }) => {
          setSubmitting(true);

          await submitForm(values, setErrors, resetForm);

          setSubmitting(false);
        }}
      >
        {({ values: { taskPk: pk }, handleSubmit, isSubmitting }) => {
          return (
            <Col>
              {viewForm && (
                <Form
                  onSubmit={handleSubmit}
                  noValidate={true}
                  className={classNames({
                    className,
                    'd-flex flex-column flex-md-row justify-content-md-between ml-4  mr-md-auto pl-3':
                      taskPk != null || isDuplicate || isInitialValuesFromOrder,
                  })}
                  style={{ maxWidth: pk != null ? '100%' : '' }}
                >
                  <div
                    className={classNames('my-2 my-md-3 mr-4 w-100')}
                    style={{
                      maxWidth:
                        pk != null || isInitialValuesFromOrder ? '800px' : '',
                    }}
                  >
                    <TaskFormFieldsWrapper
                      formState={{
                        isDisableField: disableFieldTask,
                        isNew,
                        hasDefaultBillingAccount,
                        isDuplicate,
                        isDefaultAccountOrCounterparty,
                      }}
                    />
                    <FormGroup
                      className={classNames(
                        'd-flex justify-content-end mt-4 mt-4',
                        {
                          ' d-md-block': pk != null,
                        }
                      )}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || viewOnly}
                        onClick={() => setIsSaveAndExit(false)}
                      >
                        {t('Сохранить')}
                      </Button>
                      <Button
                        className="ml-3"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || viewOnly}
                        onClick={async () => {
                          // setIsBlockedNavigation(false);
                          setIsSaveAndExit(true);
                        }}
                      >
                        {t('Сохранить и выйти')}
                      </Button>
                    </FormGroup>
                  </div>
                </Form>
              )}
              {isLoadingTask && <LoaderFetch />}
            </Col>
          );
        }}
      </Formik>

      <div
        className={classNames('ml-md-4', {
          'd-none': taskPk == null,
        })}
      >
        {!!taskPk && (
          <TaskComments pk={taskPk} authorDk={authorId} viewOnly={viewOnly} />
        )}
      </div>
    </Row>
  );
};

TaskForm.whyDidYouRender = true;

export default TaskForm;
