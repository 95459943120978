import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import IPrimaryKey from 'services/types/IPk';
import ICreateJobTitle from './ICreateJobTitle';
import { IJobTitle, IWrapperJobTitle } from './IJobTitle';
import IGetPaginatedParamsJobTitle from './IGetPaginatedParamsJobTitle';
import IUpdateJobTitle from './IUpdateJobTitle';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.JOB_TITLE
  );

export const getPaginatedJobTitleApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsJobTitle>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IJobTitle>>> =>
  api().getPaginated(filterParams);

export const readJobTitleApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<IWrapperJobTitle>> => api().read(params);

export const createJobTitleApiV2 = async (
  params: IBaseParams<ICreateJobTitle>
): Promise<IBaseResponse<IWrapperJobTitle>> => await api().create(params);

export const updateJobTitleApiV2 = async (
  JobTitle: IBaseParams<IUpdateJobTitle>
): Promise<IBaseResponse<IWrapperJobTitle>> => await api().update(JobTitle);

export const deleteJobTitleApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectJobTitleApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const exportJobTitleApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsJobTitle>
): Promise<IBaseResponse<string>> => api().export(filterParams);
