import React, { useMemo, useState } from 'react';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import { t } from '../../../../services/utils/translation';

import { ActionsDropdown } from '../../../../components/tables/ActionsDropdown';
import { ActionsDropdownItem } from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as FlowChartIcon } from 'img/icons/icon-flowchart.svg';

import ColoredStatusLabel from '../../../../components/misc/ColoredStatusLabel';
import { getColorFromHEX } from '../../../../components/tables/misc/colorTr';
import TicketStatusForm, {
  IClientStatus,
} from '../../../../components/tasks/TaskStatusForm/TaskStatusForm';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { INode } from 'components/FlowChart/FlowChart';
import FlowChart from 'components/FlowChart/FlowChart';
import useModal, { IModal } from '../../../../hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import IGetPaginatedStatusesParams from '../../../../services/api/tasks/task-statusesV2/IGetPaginatedStatusesParams';
import { IUseGetList, useGetList } from '../../../../hooks/useGetList';
import config from '../../../../config.json';
import LoaderFetch from '../../../../components/layouts/LoaderFetch/LoaderFetch';
import { Pager } from '../../../../components/tables/Pager';
import { getPaginatedTaskStatusesApiV2 } from '../../../../services/api/tasks/task-statusesV2/taskSatusesV2';
import ITaskStatus from '../../../../services/api/tasks/task-statusesV2/ITaskStatus';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { UI_TITLE } from '../../../../services/localLocalization/UITitle';
import { targetAccessPolicyTaskStatus } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import { extractGetLeftValueIfTrue } from '../../../../services/utils/extractGetLeftValueIfTrue';
import { textFromBoolean } from '../../../../services/utils/extractGetLeftValueIfTrue';

const TITLE_VIEW_DIAGRAM = t('Показать диаграмму');

const MODAL_EDIT_TITLE = t('Редактировать статус задачи');
const MODAL_CREATE_TITLE = t('Создать статус задачи');
const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);

const COLUMN_LABELS = {
  NAME: t('Статус'),
  CAN_BE_FIRST: t('Начальный статус'),
  CAN_BE_LAST: t('Конечный статус'),
};

const sortTaskStatuses = (ticketStatuses: ITaskStatus[]): ITaskStatus[] =>
  ticketStatuses.sort((a: ITaskStatus, b: ITaskStatus) => {
    if (a.internal_task_status_can_be_first) {
      return -1;
    }
    if (b.internal_task_status_can_be_last) {
      return -1;
    }
    return 1;
  });

const formNodes = (ticketStatuses: ITaskStatus[]) =>
  ticketStatuses.map((ticketStatus: ITaskStatus) => ({
    name: ticketStatus.internal_task_status_rendition,
    color: ticketStatus.internal_task_status_color,
  }));

const sortAndFormNodes = (ticketStatuses: ITaskStatus[]) =>
  formNodes(sortTaskStatuses(ticketStatuses));

export const TaskStatusesPage = () => {
  const [nodes, setNodes] = useState<INode[]>([]);
  const [isDiagramsOpen, setIsDiagramsOpen] = useState<boolean>(false);

  const [haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyTaskStatus,
    targetAction: ['U'],
  });

  const initialParams = useMemo(
    () => ({
      internal_task_status_pk: undefined,
      internal_task_status_datetime_added_upper_bound: '',
      internal_task_status_datetime_added_lower_bound: '',
      internal_task_status_datetime_edited_lower_bound: '',
      internal_task_status_datetime_edited_upper_bound: '',
      internal_task_status_rendition: '',
      skip: 0,
      length: config.pageLength,
    }),
    []
  );

  const {
    data: taskStatuses,
    isLoading,
    refreshListData,
    total,
    setStart,
    setLength,
    params: { skip, length },
  }: IUseGetList<ITaskStatus[], IGetPaginatedStatusesParams> = useGetList({
    getDataApi: getPaginatedTaskStatusesApiV2,
    initialParams,
    handlePayload: (statuses: ITaskStatus[]) =>
      setNodes(sortAndFormNodes(statuses)),
  });

  const {
    state: { isAddEditFormOpen, entity: status },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
  }: IModal<IClientStatus> = useModal({
    setRefresh: refreshListData,
  });

  const toggleDiagrams = () => setIsDiagramsOpen(!isDiagramsOpen);

  return (
    <MainLayout>
      <Subheader>
        <div style={{ display: 'flex' }} className="mr-2">
          <SubheaderButton onClick={toggleDiagrams} bordered={true}>
            <FlowChartIcon className="mr-2 text-white" /> {TITLE_VIEW_DIAGRAM}
          </SubheaderButton>
        </div>
      </Subheader>
      {!isDiagramsOpen ? (
        <div className="table-responsive-none">
          <table className="table table-bordered table-responsive-xl table-hover">
            <thead>
              <tr>
                <th align="center" scope="col" style={{ width: '10%' }}>
                  {COLUMN_LABELS.NAME}
                </th>
                <th scope="col" className={`p-1 `}>
                  {COLUMN_LABELS.CAN_BE_FIRST}
                </th>
                <th scope="col" className=" p-1 ">
                  {COLUMN_LABELS.CAN_BE_LAST}
                </th>
                <th scope="col" className="actions p-0 " />
              </tr>
            </thead>
            {!isLoading && (
              <tbody>
                {taskStatuses &&
                  taskStatuses.map((status: ITaskStatus) => {
                    const {
                      internal_task_status_color: statusColor,
                      internal_task_status_rendition: statusRendition,
                      internal_task_status_can_be_first: statusCanBeFirst,
                      internal_task_status_can_be_last: statusCanBeLast,
                      internal_task_status_pk: statusPk,
                      internal_task_status_order: statusOrder,
                    } = status;
                    return (
                      <tr
                        key={status.internal_task_status_pk}
                        className={`table-${getColorFromHEX(statusColor)}`}
                      >
                        <td>
                          <ColoredStatusLabel color={statusColor}>
                            {statusRendition}
                          </ColoredStatusLabel>
                        </td>
                        <td>{textFromBoolean(statusCanBeFirst)}</td>
                        <td>{textFromBoolean(statusCanBeLast)}</td>
                        <td align="right">
                          <ActionsDropdown>
                            {haveAccessToUpdate && (
                              <ActionsDropdownItem
                                label={UI_TITLE.EDIT}
                                onClick={() =>
                                  openAddEditForm({
                                    id: statusPk,
                                    name: statusRendition,
                                    color: statusColor,
                                    can_be_first: statusCanBeFirst,
                                    can_be_last: statusCanBeLast,
                                    order: statusOrder,
                                  })
                                }
                                icon={<EditIcon className="text-primary" />}
                              />
                            )}
                          </ActionsDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
          <LoaderFetch />
          <Pager
            onPageChange={setStart}
            onLengthChange={setLength}
            total={total}
            length={length}
            start={skip}
          />
          <ModalWrapper
            headerLabel={getModalTitle(!!status?.id)}
            isOpen={isAddEditFormOpen}
            closeHandler={closeAddEditForm}
          >
            <TicketStatusForm successHandler={successHandler} status={status} />
          </ModalWrapper>
        </div>
      ) : (
        <FlowChart nodes={nodes} />
      )}
    </MainLayout>
  );
};

export default TaskStatusesPage;
