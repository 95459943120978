import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { useMemo } from 'react';
import {
  targetAccessPolicyExternalRequest,
  targetAccessPolicyExternalRequestResponsibleDepartment,
  targetAccessPolicyExternalRequestWorkCategory,
} from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyExternalRequest';
import {
  targetAccessPolicyBillingAccount,
  targetAccessPolicyEvent,
} from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { targetAccessPolicyEmployee } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyPersonnel';

const useGetExternalRequestAccessPolicy = (isInstall: boolean) => {
  const [
    haveAccessToArchive,
    haveAccessToUpdate,
    haveAccessToCreate,
    haveAccessToExport,
  ] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyExternalRequest,
    targetAction: ['A', 'U', 'C', 'E'],
    dontCall: isInstall,
  });

  const [employeeHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEmployee,
    targetAction: ['R'],
    dontCall: isInstall,
  });

  const [billingAccountHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['R'],
    dontCall: isInstall,
  });

  const [eventHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEvent,
    targetAction: ['R'],
    dontCall: isInstall,
  });

  const [workCategoryHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyExternalRequestWorkCategory,
    targetAction: ['R'],
    dontCall: isInstall,
  });

  const [responsibleDepartmentHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyExternalRequestResponsibleDepartment,
    targetAction: ['R'],
    dontCall: isInstall,
  });

  return useMemo(() => {
    return {
      employeeHaveAccessToRead,
      billingAccountHaveAccessToRead,
      eventHaveAccessToRead,
      workCategoryHaveAccessToRead,
      responsibleDepartmentHaveAccessToRead,
      esxternalRequest: {
        haveAccessToArchive,
        haveAccessToUpdate,
        haveAccessToCreate,
        haveAccessToExport,
      },
    };
  }, [
    billingAccountHaveAccessToRead,
    employeeHaveAccessToRead,
    eventHaveAccessToRead,
    haveAccessToArchive,
    haveAccessToCreate,
    haveAccessToExport,
    haveAccessToUpdate,
    responsibleDepartmentHaveAccessToRead,
    workCategoryHaveAccessToRead,
  ]);
};

export default useGetExternalRequestAccessPolicy;
