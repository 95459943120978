import { IFormValuesController } from 'components/controller/controller/ControllerForm/ControllerForm';
import { IController } from 'services/api/controller/IController';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';

export const toControllerFormValues = (
  data: IController
): IFormValuesController => {
  const {
    controller_pk,
    controller_description,
    controller_billing_account,
    controller_is_closed,
  } = data;
  return {
    controller_pk,
    controller_description,
    controllerBillingAccount: createClientSelectOptionV2<number>(
      controller_billing_account.billing_account_pk,
      controller_billing_account.billing_account_code_number
    ),

    controllerIsClosed: controller_is_closed,
  };
};
