import { ICounterpartyFilters } from 'services/api/organizationsV2/counterparty/IGetCounterPartyPaginatedParams';
import config from 'config.json';

export const initialCounterpartyFilters: ICounterpartyFilters = {
  counterparty_pk: undefined,
  //counterparty_pk_array: [],
  counterparty_datetime_added_upper_bound: '',
  counterparty_datetime_added_lower_bound: '',
  //counterparty_author_dk: undefined,
  // counterparty_author_dk_array: [],
  counterparty_description: '',
  // counterparty_fulltext_search: '',
  counterparty_is_natural_person: undefined,
  counterparty_is_affiliate: undefined,
  counterparty_email: '',
  counterparty_phone_number: undefined,
  counterparty_actual_address: '',
  //counterparty_legal_address: '',
  //counterparty_individual_last_name: '',
  //counterparty_individual_first_name: '',
  // //counterparty_individual_middle_name: '',
  // counterparty_company_full_name: '',
  // counterparty_company_short_name: '',
  counterparty_inn: '',
  // counterparty_company_ogrn: '',
  // counterparty_company_kpp: '',
  counterparty_complete_name: '',
  skip: 0,
  length: config.pageLength,
};
