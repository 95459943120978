import { t } from '../../../services/utils/translation';
import styles from './index.module.scss';
import { PropsWithChildren, FC } from 'react';
import WrapperGrayTable from '../WrapperGrayTable/WrapperGrayTable';
import SubHeaderGreyWrapper from '../SubHeaderGreyWrapper/SubHeaderGreyWrapper';

const CREATED_COLUMN_LABEL = t('Дата');
const DESCRIPTION_COLUMN_LABEL = t('Событие');
const FIELD_COLUMN_LABEL = t('Поле');
const OLD_VALUE_COLUMN_LABEL = t('Старое значение');
const NEW_VALUE_COLUMN_LABEL = t('Новое значение');
const AUTHOR_COLUMN_LABEL = t('Автор');

type HistoryContainerTableProps = {
  className?: string;
};
type PropsType = PropsWithChildren<HistoryContainerTableProps>;

const HistoryContainerTable: FC<PropsType> = ({ children }) => {
  return (
    <>
      <SubHeaderGreyWrapper mainTitle={t('История')} />
      <WrapperGrayTable>
        <table className="table table-bordered table-responsive-xl ">
          <thead>
            <tr className={styles.tableHeader}>
              <th scope="col">{CREATED_COLUMN_LABEL}</th>
              <th scope="col">{DESCRIPTION_COLUMN_LABEL}</th>
              <th scope="col">{FIELD_COLUMN_LABEL}</th>
              <th scope="col">{OLD_VALUE_COLUMN_LABEL}</th>
              <th scope="col">{NEW_VALUE_COLUMN_LABEL}</th>
              <th scope="col">{AUTHOR_COLUMN_LABEL}</th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </WrapperGrayTable>
    </>
  );
};

export default HistoryContainerTable;
