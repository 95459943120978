import IBaseGetListParams from 'services/api/interfacesApi/IBaseGetListParams';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

export enum VehicleRequestSortCriteria {
  Default = '',
  PkAsc = 'vehicle_pk',
  PkDesc = '-vehicle_pk',
  RegistrationPlateAsc = 'vehicle_registration_plate',
  RegistrationPlateDesc = '-vehicle_registration_plate',
  GarageCodeNumberAsc = 'vehicle_garage_code_number',
  GarageCodeNumberDesc = '-vehicle_garage_code_number',
}

export interface IVehicleFilters
  extends IBaseGetListParams<VehicleRequestSortCriteria> {
  vehicle_pk?: number;
  vehicle_type_fk_array?: IClientSelectOptionV2<number>[];
  vehicle_registration_plate: string;
  vehicle_garage_code_number: string;
}
export interface IGetPaginatedParamsVehicle
  extends IBaseGetListParams<VehicleRequestSortCriteria> {
  vehicle_pk?: number;
  vehicle_pk_array?: number[];
  vehicle_type_fk?: number;
  vehicle_type_fk_array?: number[];
  vehicle_fulltext_search?: string;
  vehicle_registration_plate?: string;
  vehicle_garage_code_number?: string;
}
