import { FC, memo } from 'react';
import styles from './index.module.scss';
import classNames from 'clsx';
import { PASSWORD_MIN_LENGTH } from 'components/controls/PasswordInput/hooks/useGetStringCharacteristics/utils/isMediumPassword';
import { t } from 'services/utils/translation';
import CustomTooltip from 'components/misc/CustomTooltip/CustomTooltip';
import useGetStringCharacteristics from '../../hooks/useGetStringCharacteristics/useGetStringCharacteristics';
type PropsTypePasswordVerificationTips = {
  password: string;
};

const PasswordVerificationTips: FC<PropsTypePasswordVerificationTips> = (
  props
) => {
  const { password } = props;

  const {
    containsUppercase,
    containsLowercase,
    containsNumber,
    containsMinLength,
    containsSpecialCharacter,
  } = useGetStringCharacteristics(password);

  return (
    <>
      <CustomTooltip>
        <div className={'d-flex flex-column'}>
          <span
            className={classNames(styles.prompt, {
              [styles.noOk]: !containsMinLength,
              [styles.ok]: containsMinLength,
            })}
          >
            {t('Содержит больше')} {PASSWORD_MIN_LENGTH} {t('символов')}
          </span>
          <span
            className={classNames(styles.prompt, {
              [styles.ok]: containsUppercase && containsLowercase,
              [styles.noOk]: !containsUppercase || !containsLowercase,
            })}
          >
            {t('Содержит заглавные и строчные буквы')}
          </span>
          <span
            className={classNames(styles.prompt, {
              [styles.ok]: containsNumber,
              [styles.noOk]: !containsNumber,
            })}
          >
            {t('Содержит цифры')}
          </span>
        </div>
        <div className={'d-flex flex-column'}>
          <span
            className={classNames(styles.prompt, {
              [styles.noOk]: !containsSpecialCharacter,
              [styles.ok]: containsSpecialCharacter,
            })}
          >
            {t('Содержит какой-либо из спецсимволов')}
            {': '}
            {`!@#$%^&*()_+-=[]{};':"|,.<>/?\\`}
          </span>
        </div>
      </CustomTooltip>
    </>
  );
};

export default memo(PasswordVerificationTips);
