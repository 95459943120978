import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setIsActiveBillingAccountArrayFilter,
  setIsActiveImplementerDkArrayFilter,
} from 'store/externalRequest/actions';
import { setIsActiveWorkCategoryFilter } from '../../../../../store/externalRequest/actions';
import {
  setEventExternalRequestIsActivePkFilter,
  setEventIsActiveImplementerDkArrayFilter,
  setEventIsActiveWorkCategoryFilter,
} from 'store/event/actions';
import {
  setBillingAccountExternalRequestIsActivePkFilter,
  setBillingAccountIsActiveImplementerDkArrayFilter,
  setBillingAccountIsActiveWorkCategoryFilter,
} from 'store/billingAccount/actions';
import { IExternalRequestEntityPk } from '../ExternalRequestPage';

type PropsType = IExternalRequestEntityPk;

enum ExternalRequestEntityType {
  billingAccount = 'billingAccount',
  event = 'event',
  none = 'none',
}

const getTypeEntity = ({
  billingAccountPk,
  eventPk,
}: PropsType): ExternalRequestEntityType => {
  if (!billingAccountPk && !eventPk) {
    return ExternalRequestEntityType.none;
  }
  if (billingAccountPk) {
    return ExternalRequestEntityType.billingAccount;
  } else if (eventPk) {
    return ExternalRequestEntityType.event;
  }
  return ExternalRequestEntityType.none;
};

const useDoubleFilterUtilitiesForExternalRequest = ({
  eventPk,
  billingAccountPk,
}: PropsType) => {
  const dispatch = useDispatch();
  const entityType = getTypeEntity({ billingAccountPk, eventPk });
  const setIsActiveBillingAccountFilter = useCallback(
    (isActiveBillingAccountFilter: boolean) => {
      dispatch(
        setIsActiveBillingAccountArrayFilter(isActiveBillingAccountFilter)
      );
    },
    [dispatch]
  );

  const callSetIsActivePkFilter = useCallback(
    (isActiveImplementerDkArrayFilter: boolean) => {
      const ACTION_MAP = {
        [ExternalRequestEntityType.billingAccount]:
          setBillingAccountExternalRequestIsActivePkFilter(
            billingAccountPk as number
          ),
        [ExternalRequestEntityType.event]:
          setEventExternalRequestIsActivePkFilter(eventPk as number),
        [ExternalRequestEntityType.none]: setIsActiveImplementerDkArrayFilter,
      };
      const action = ACTION_MAP[entityType];
      dispatch(action(isActiveImplementerDkArrayFilter));
    },

    [billingAccountPk, dispatch, entityType, eventPk]
  );

  const callSetIsActiveImplementersFilter = useCallback(
    (isActivePkFilter: boolean) => {
      const ACTION_MAP = {
        [ExternalRequestEntityType.billingAccount]:
          setBillingAccountIsActiveImplementerDkArrayFilter(
            billingAccountPk as number
          ),
        [ExternalRequestEntityType.event]:
          setEventIsActiveImplementerDkArrayFilter(eventPk as number),
        [ExternalRequestEntityType.none]: setIsActiveImplementerDkArrayFilter,
      };

      const action = ACTION_MAP[entityType];
      dispatch(action(isActivePkFilter));
    },
    [billingAccountPk, dispatch, entityType, eventPk]
  );

  const callSetIsActiveWorkCategoryFilter = useCallback(
    (IsActiveWorkCategory: boolean) => {
      const ACTION_MAP = {
        [ExternalRequestEntityType.billingAccount]:
          setBillingAccountIsActiveWorkCategoryFilter(
            billingAccountPk as number
          ),
        [ExternalRequestEntityType.event]: setEventIsActiveWorkCategoryFilter(
          eventPk as number
        ),
        [ExternalRequestEntityType.none]: setIsActiveWorkCategoryFilter,
      };

      const action = ACTION_MAP[entityType];
      dispatch(action(IsActiveWorkCategory));
    },
    [billingAccountPk, dispatch, entityType, eventPk]
  );

  return {
    callSetIsActivePkFilter,
    callSetIsActiveWorkCategoryFilter,
    callSetIsActiveImplementersFilter,
    setIsActiveBillingAccountFilter,
  };
};

export default useDoubleFilterUtilitiesForExternalRequest;
