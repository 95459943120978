import useSaveLastPath from 'hooks/useSaveLastPath/useSaveLastPath';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useIsStartingPathWasVisited from './useIsStartingPathWasVisited';
import useIsIgnoreStartingPathWasVisited from './useIsIgnoreStartingPathWasVisited';

const usePushLastVisitedPath = () => {
  const isStartingPathWasVisited = useIsStartingPathWasVisited();
  const { lastVisitedPath } = useSaveLastPath();
  const [isIgnoreLastVisitedPath, setIsIgnoreLastVisitedPath] =
    useIsIgnoreStartingPathWasVisited();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      lastVisitedPath &&
      isStartingPathWasVisited &&
      !isIgnoreLastVisitedPath
    ) {
      setIsIgnoreLastVisitedPath(true);
      navigate(lastVisitedPath);
    }
  }, [
    navigate,
    isIgnoreLastVisitedPath,
    isStartingPathWasVisited,
    lastVisitedPath,
    setIsIgnoreLastVisitedPath,
  ]);
};

export default usePushLastVisitedPath;
