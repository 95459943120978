import { useMemo } from 'react';
import { initialTasksFilters } from '../../store/task/initialTasksFilters';
import { useGetList } from 'hooks/useGetList';
import { getPaginatedTasksApiV2 } from 'services/api/tasks/tasks/tasksApiV2';
import { parentUIElement } from 'services/constants/parentUIElement';
import { TParentUiForTaskTab } from 'hooks/useGetTaskTab/useGetTaskTab';

const KEY_PARAMS_FOR_GET_TOTAL_TASK = {
  [parentUIElement.account]: 'internal_task_billing_account_fk',
  [parentUIElement.counterparty]: 'internal_task_counterparty_fk',
} as const;

interface IUseGetTotalTask {
  pk: number;
  parentUI: TParentUiForTaskTab;
}

const useGetTotalTask = ({ pk, parentUI }: IUseGetTotalTask) => {
  const initialParamsTask = useMemo(
    () => ({
      ...initialTasksFilters,
      [KEY_PARAMS_FOR_GET_TOTAL_TASK[parentUI]]: pk,
    }),
    [parentUI, pk]
  );

  const { total: totalTask, isLoading: isLoadingTask } = useGetList({
    getDataApi: getPaginatedTasksApiV2,
    initialParams: initialParamsTask,
    initialData: 0,
  });

  return { totalTask, isLoadingTask };
};

export default useGetTotalTask;
