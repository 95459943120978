import Highlighter from 'react-highlight-words';
import styles from './index.module.scss';

const formatOptionLabelHighLighter = ({ label }: any, { inputValue }: any) => (
  <Highlighter
    searchWords={[inputValue]}
    textToHighlight={label}
    highlightClassName={styles.highlightSelect}
    // autoEscape={true}
  />
  
);

export default formatOptionLabelHighLighter;
