import { IControllerFilters } from 'services/api/controller/IControllerFilters';
import config from 'config.json';

const initialControllerFilters: IControllerFilters = {
  controller_pk: undefined,
  // controller_pk_array: [],
  controller_datetime_installed_upper_bound: '',
  controller_datetime_installed_lower_bound: '',
  controller_datetime_last_active_lower_bound: '',
  controller_datetime_last_active_upper_bound: '',
  controller_comment_text: '',
  controller_description: '',
  controller_billing_account_fk: undefined,
  controller_billing_account_fk_array: [],
  controller_is_closed: undefined,
  skip: 0,
  length: config.pageLength,
};

export default initialControllerFilters;
