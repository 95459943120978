import { useState, useEffect, useCallback, useMemo } from 'react';
import { t } from 'services/utils/translation';
import useGetPrevValue from './useGetPrevValue';
import { catchErrorFromFetch } from "../services/utils/catchAndRegError/catchAndRegError";

const getErrorMessage = (keyWord: string) =>
  t(
    `Произошла ошибка во время ${keyWord} данных. Обратитесь, пожалуйста, к администратору`
  );

export type TypeSetValue<Value> = (value: Value) => void;

export type useLocalStorageProps<Value> = {
  key: string;
  initialValue: Value;
};

function useLocalStorage<Value>(
  props: useLocalStorageProps<Value>
): [Value, TypeSetValue<Value>, () => void] {
  const { key, initialValue } = props;

  const item = useMemo(() => window.localStorage.getItem(key), [key]);
  const prevItem = useGetPrevValue(item);
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<Value>(initialValue);
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.

  const setValue = useCallback(
    (value: Value | ((val: Value) => Value)) => {
      try {
        // Allow value to be a function, so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        catchErrorFromFetch(getErrorMessage(t('сохранения')), error);
      }
    },
    [key, storedValue]
  );

  const remove = useCallback(() => {
    window.localStorage.removeItem(key);
  }, [key]);

  useEffect(() => {
    if (item && prevItem !== item) {
      const loadData = item ? JSON.parse(item) : initialValue;
      setValue(loadData);
    }
  }, [initialValue, item, key, prevItem, setValue]);

  return [storedValue, setValue, remove];
}

export default useLocalStorage;
