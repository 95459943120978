import { useEffect, useCallback } from 'react';

const usePreventReload = (isPrevent: boolean) => {
  const preventReload = useCallback((event: any) => {
    event.preventDefault();
    event.returnValue = '';
  }, []);

  useEffect(() => {
    if (isPrevent) {
      window.addEventListener('beforeunload', preventReload);
    }
    return () => {
      if (isPrevent) {
        window.removeEventListener('beforeunload', preventReload);
      }
    };
  }, [preventReload, isPrevent]);
};

export default usePreventReload;
