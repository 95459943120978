import { IEmployee } from '../../../../services/api/staff/employee/IEmployee';
import Employee from '../../../../models/Employee';
import { createClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import { getFullNameFromInitials } from "../../../../services/utils/getFullNameFromInitials/getFullNameFromInitials";

const toEmployeeFormValues = (employee: IEmployee): Employee => {
  const {
    employee_pk: pk,
    employee_job_title: jobTitle,
    employee_supervisor,
    employee_phone_number: phoneNumber,
  } = employee;

  const superVisorFullName: string = getFullNameFromInitials({
    firstName: employee_supervisor?.employee_first_name,
    lastName: employee_supervisor?.employee_last_name,
    middleName: employee_supervisor?.employee_middle_name,
  });

  return {
    pk,
    ...employee,
    jobTitle: createClientSelectOptionV2(
      jobTitle?.job_title_pk,
      jobTitle?.job_title_name
    ),
    employee_phone_number: phoneNumber ? phoneNumber + '' : '',
    supervisor: employee_supervisor?.employee_pk
      ? createClientSelectOptionV2(
          employee_supervisor?.employee_pk,
          superVisorFullName
        )
      : undefined,
    accessPolicy: createClientSelectOptionV2(
      employee?.employee_access_policy_fk,
      employee?.employee_access_policy?.access_policy_name
    ),
  };
};

export default toEmployeeFormValues;
