import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import classNames from 'clsx';
import styles from './index.module.scss';
import { ReactComponent as DeleteIcon } from '../../../img/icons/icon-trash.svg';
import { ReactComponent as RefundIcon } from '../../../img/icons/icon-refund.svg';

import { sliceTimestamp } from '../../../services/utils/dateHelper/dateHelper';

type PropsTypeThumb = {
  file: File;
  onDeleteFile: MouseEventHandler<HTMLButtonElement>;
  onReturnedDeletedFile?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  isActive?: boolean;
};
export const Thumb: FC<PropsTypeThumb> = (props) => {
  const {
    file,
    onDeleteFile,
    className,
    onReturnedDeletedFile,
    isActive = true,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [thumb, setThumb] = useState<string>('');

  useEffect(() => {
    if (file) {
      setLoading(true);
      let reader = new FileReader();
      reader.onloadend = () => {
        setLoading(false);
        if (typeof reader.result === 'string') {
          setThumb(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  if (!file) {
    return null;
  }

  if (loading) {
    return <Spinner color="light" />;
  }
  return (
    <Card
      className={classNames(className, styles.file, 'mt-2', {
        [styles.fileIsNotActive]: !isActive,
      })}
    >
      <CardImg src={thumb} alt={file.name} className="p-1" />
      <CardBody>
        <Row>
          <Col md={10}>
            <CardSubtitle tag="h6" className="mb-2 text-muted">
              {sliceTimestamp(file.name)}
            </CardSubtitle>
          </Col>
          <Col md={2}>
            {isActive ? (
              <button
                type="button"
                className={classNames(
                  'd-flex justify-content-center align-items-center',
                  styles.deleteIconContainer
                )}
                onClick={onDeleteFile}
              >
                <DeleteIcon />
              </button>
            ) : (
              <button
                className={classNames(
                  'd-flex justify-content-center align-items-center',
                  styles.refundIconContainer
                )}
                type="button"
                onClick={onReturnedDeletedFile}
              >
                <RefundIcon />
              </button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
