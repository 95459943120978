import { useCallback } from 'react';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { ValueType } from 'react-select';

const useControllerHandleSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
  const handleSearchPk = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'controller_pk');
    },
    [onSearchRequest]
  );

  const handleSearchControllerDescription = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'controller_description');
    },
    [onSearchRequest]
  );

  const handleSelectBillingAccountArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'controller_billing_account_fk_array');
    },
    [onSearchRequest]
  );

  return {
    handleSearchPk,
    handleSearchControllerDescription,
    handleSelectBillingAccountArray,
  };
};

export default useControllerHandleSearch;
