import { PasswordStrength } from 'components/controls/PasswordInput/hooks/useGetStringCharacteristics/utils/getPasswordStrength';
import { FC, memo } from 'react';
import classNames from 'clsx';
import styles from './index.module.scss';

type PropsType = { strength: PasswordStrength | '' };

const UnderLines: FC<PropsType> = ({ strength }) => {
  return (
    <div
      className={classNames(
        styles.underlines,
        strength && styles[strength],
        'mt-2'
      )}
    >
      <div className={styles.line}/>
      <div className={styles.line}/>
      <div className={styles.line}/>
      <div className={styles.line}/>
    </div>
  );
};

export default memo(UnderLines);
