import styles from './index.module.scss';
import classNames from 'clsx';
import { FC, useMemo } from 'react';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { t } from 'services/utils/translation';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import useModalV2 from 'hooks/useModalV2';
import { IModal } from 'hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import SubheaderButtonExport from 'components/tables/SubHeaderButtonExport/SubHeaderButtonExport';
import { useColumnFilter } from 'hooks/useColumnFilter';
import { IUseGetList, useGetList } from 'hooks/useGetList';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import Pager from 'components/tables/Pager';
import {
  deleteVehicleApiV2,
  getPaginatedVehicleApiV2,
} from 'services/api/vehicle/vehicle/vehicleApi';
import { IVehicle } from 'services/api/vehicle/vehicle/IVehicle';
import {
  IVehicleFilters,
  VehicleRequestSortCriteria,
} from 'services/api/vehicle/vehicle/IGetPaginatedParamsVehicle';
import { exportVehicleApiV2 } from '../../../services/api/vehicle/vehicle/vehicleApi';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicleFiltersFromStore } from '../../../store/vehicle/selectors';
import isEqual from 'react-fast-compare';
import useClearFilters from 'hooks/useClearFilters';
import { initialVehicleFilters } from '../../../store/initialStore/initialVehicleFilters';
import useVehicleHandleSearch from 'hooks/useVehicleHandleSearch';
import HeaderSelectGroup from 'components/tables/HeaderSelectGroup/HeaderSelectGroup';
import { Spinner } from 'reactstrap';
import { IGetPaginatedParamsVehicle } from '../../../services/api/vehicle/vehicle/IGetPaginatedParamsVehicle';
import { IKeyValue } from 'services/interfaces/IKeyValue';
import { composeClientSelectOptionsInNumbersArray } from 'services/utils/selects/composeIClientSelectOptionsInNumbers';
import trimStringInObject from 'services/utils/trimStringInObject/trimStringInObject';
import {
  clearVehicleFilters,
  saveVehicleFilters,
} from '../../../store/vehicle/actions';
import useGetVehicleTypeOptions from 'hooks/useGetVehicleTypeOptions';
import VehicleForm from 'components/vehicle/VehicleForm/VehicleForm';
import { targetAccessPolicyVehicle } from '../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyVehicle';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { UI_TITLE } from '../../../services/localLocalization/UITitle';
import { extractGetLeftValueIfTrue } from '../../../services/utils/extractGetLeftValueIfTrue';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import MyFilters from 'components/tables/MyFilters/MyFilters';

const deleteMessageError = t('Не удалось удалить машину');
const CREATE_BUTTON_TITLE = t('Добавить машину');
const MODAL_EDIT_TITLE = t('Редактировать машину');
const MODAL_CREATE_TITLE = t('Создать машину');
const MODAL_DELETE_TITLE = t('Удалить машину');

const getTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);

const COLUMN_LABELS_VEHICLE = {
  PK: '№',
  REGISTRATION_PLATE: 'Номерной знак',
  GARAGE_CODE_NUMBER: 'Номер гаража',
  TYPE: 'Тип',
};

const filters = [
  { name: 'vehicle_pk', label: COLUMN_LABELS_VEHICLE.PK },
  {
    name: 'vehicle_registration_plate',
    label: COLUMN_LABELS_VEHICLE.REGISTRATION_PLATE,
  },
  {
    name: 'vehicle_garage_code_number',
    label: COLUMN_LABELS_VEHICLE.GARAGE_CODE_NUMBER,
  },
  {
    name: 'vehicle_type',
    label: COLUMN_LABELS_VEHICLE.TYPE,
  },
] as const;
const columnVisibilityInitial = {
  vehicle_pk: true,
  vehicle_registration_plate: true,
  vehicle_type: true,
  vehicle_garage_code_number: true,
};

const composeFiltersForGetVehicle = (params: IVehicleFilters) => {
  const valuesFromClientSelectOptions: IKeyValue<number[]> =
    composeClientSelectOptionsInNumbersArray<IVehicleFilters>(params, [
      'vehicle_type_fk_array',
    ]);

  const composeNewParams = {
    ...params,
    ...valuesFromClientSelectOptions,
  };
  return deleteUnnecessaryParameters(
    trimStringInObject(composeNewParams)
  ) as IGetPaginatedParamsVehicle;
};

const VehiclePage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial, 'vehicleColumnFilter');

  const [
    haveAccessToUpdate,
    haveAccessToCreate,
    haveAccessToDelete,
    haveAccessToExport,
  ] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyVehicle,
    targetAction: ['U', 'C', 'D', 'E'],
  });

  const dispatchRedux = useDispatch();

  const saveFilters = (filters: IVehicleFilters) =>
    dispatchRedux(saveVehicleFilters(filters));

  const defaultTableFilters = useSelector(getVehicleFiltersFromStore, isEqual);

  const initialParams: IVehicleFilters = useMemo(
    () => defaultTableFilters,
    [defaultTableFilters]
  );

  const { vehicleTypeOptions } = useGetVehicleTypeOptions();

  const onFinally = () => setClearingFiltersSpinner(false);

  const {
    data: vehicleList,
    isLoading,
    total,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    refreshListData,
    params: {
      skip,
      length,
      vehicle_pk,
      vehicle_type_fk_array,
      vehicle_registration_plate,
      vehicle_garage_code_number,
    },
    setParams,
    params,
  }: IUseGetList<IVehicle[], IVehicleFilters> = useGetList({
    getDataApi: getPaginatedVehicleApiV2,
    convertedParameters: composeFiltersForGetVehicle,
    initialParams,
    onFinally,
    saveFilters,
  });

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetVehicle },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteVehicleApiV2,
    entityIdKey: 'pk',
  });

  const {
    clearFilters,
    isDisableClearButton,
    clearingFiltersSpinner,
    setClearingFiltersSpinner,
  } = useClearFilters(
    initialVehicleFilters,
    params,
    clearVehicleFilters,
    setParams
  );

  const {
    handleSearchPk,
    handleSearchGarageCodeNumber,
    handleSearchType,
    handleSearchRegistrationPlate,
  } = useVehicleHandleSearch(onSearchRequest);

  return (
    <MainLayout>
      <Subheader>
        <div style={{ display: 'flex' }} className="mr-5">
          <SubheaderButton>
            <MyFilters
              setFilters={setParams}
              currentFilter={params}
              saveFilters={saveFilters}
              keyMyFilter="vehicle"
            />
          </SubheaderButton>
          <SubheaderButtonExport
            params={params}
            getExportLinkApi={exportVehicleApiV2}
            disabled={isLoading}
            haveAccessToExport={haveAccessToExport}
          />
        </div>
        <SubheaderButton
          onClick={clearFilters}
          bordered={true}
          disabled={isDisableClearButton}
        >
          {UI_TITLE.REMOVE_FILTERS}
          {clearingFiltersSpinner && isLoading && (
            <Spinner color="light" size="sm" className="ml-2" />
          )}
        </SubheaderButton>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <AddIcon className="mr-2 text-white" /> {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={`'id p-0 w-5' ${
                  !visibility.vehicle_pk ? 'd-none' : ''
                }`}
                style={{ width: '5%' }}
              >
                <HeaderWithSearchAndSort
                  field={VehicleRequestSortCriteria.PkAsc}
                  title={COLUMN_LABELS_VEHICLE.PK}
                  onSort={onSortClick}
                  onSearch={handleSearchPk}
                  defaultValue={vehicle_pk ? vehicle_pk + '' : ''}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${
                  !visibility.vehicle_registration_plate ? 'd-none' : ''
                }`}
                style={{ width: '40%' }}
              >
                <HeaderWithSearchAndSort
                  field="vehicle_registration_plate"
                  title={COLUMN_LABELS_VEHICLE.REGISTRATION_PLATE}
                  onSearch={handleSearchRegistrationPlate}
                  onSort={onSortClick}
                  defaultValue={vehicle_registration_plate}
                />
              </th>
              <th
                scope="col"
                className={classNames('p-0', {
                  'd-none': !visibility.vehicle_type,
                })}
                style={{ width: '25%', height: '100%' }}
              >
                <HeaderSelectGroup
                  id="vehicle_type_fk_array"
                  title={COLUMN_LABELS_VEHICLE.TYPE}
                  options={vehicleTypeOptions}
                  isMulti={true}
                  onChange={handleSearchType}
                  className={
                    vehicle_type_fk_array && vehicle_type_fk_array.length > 0
                      ? styles['borderBackLight']
                      : ''
                  }
                  value={vehicle_type_fk_array}
                  key={+isLoading}
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${
                  !visibility.vehicle_garage_code_number ? 'd-none' : ''
                }`}
                style={{ width: '30%' }}
              >
                <HeaderWithSearchAndSort
                  field="vehicle_garage_code_number"
                  title={COLUMN_LABELS_VEHICLE.GARAGE_CODE_NUMBER}
                  onSearch={handleSearchGarageCodeNumber}
                  onSort={onSortClick}
                  defaultValue={vehicle_garage_code_number}
                />
              </th>
              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {vehicleList &&
                vehicleList.map((vehicle: IVehicle) => {
                  const {
                    vehicle_pk: pk,
                    vehicle_registration_plate: registrationPlate,
                    vehicle_garage_code_number: codeNumber,
                    vehicle_type: vehicleType,
                  } = vehicle;
                  return (
                    <tr key={pk}>
                      <td
                        className={classNames({
                          'd-none': !visibility.vehicle_pk,
                        })}
                      >
                        <span>{pk}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.vehicle_registration_plate,
                        })}
                      >
                        <span>{registrationPlate}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.vehicle_type,
                        })}
                      >
                        <span>{vehicleType.vehicle_type_rendition}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.vehicle_garage_code_number,
                        })}
                      >
                        <span>{codeNumber}</span>
                      </td>
                      <td align="right">
                        <ActionsDropdown>
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={UI_TITLE.EDIT}
                              onClick={openAddEditForm.bind(null, {
                                pk: pk,
                                rendition: registrationPlate,
                              })}
                              icon={<EditIcon className="text-primary" />}
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                rendition: registrationPlate,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        {isLoading && <LoaderFetch />}
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />

        <ModalWrapper
          headerLabel={getTitle(!!targetVehicle?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <VehicleForm successHandler={successHandler} pk={targetVehicle?.pk} />
        </ModalWrapper>
        {targetVehicle?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetVehicle?.pk}
            entityName={targetVehicle?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default VehiclePage;
