import { MouseEvent, useCallback } from 'react';
import { ISubMenuItemV2 } from '../../../services/interfaces/IMenuItemV2';
import useLoadMenu from 'hooks/useLoadMenu/useLoadMenu';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMenuFavoritesFromStore,
} from '../../../store/general/selectors';
import useMyListOfFavoriteFolders from 'hooks/useFavoriteFolders';
import { setFavoritesMyFolder } from '../../../store/general/actions';
import { getInstallationPkFromStore } from "../../../store/auth/selectors";

type PropsType = {
  menuItemProps: {
    to: string;
    name: string;
    title: string;
    isFavorite?: boolean | null;
  };
};

const useFavoriteFolderItemHandler = (props: PropsType) => {
  const {
    menuItemProps: { to, name, title, isFavorite },
  } = props;

  const installationPk = useSelector(getInstallationPkFromStore);
  const dispatch = useDispatch();
  const [, setMyListOfFavoriteFolders] = useMyListOfFavoriteFolders();

  const loadMenu = useLoadMenu(installationPk, true, true);
  const myFavoriteListFolder = useSelector(getMenuFavoritesFromStore);

  const addFavorite = useCallback(() => {
    const lastIndexMenuItem = myFavoriteListFolder.length - 1;

    const weight = myFavoriteListFolder?.[lastIndexMenuItem]?.weight
      ? myFavoriteListFolder?.[lastIndexMenuItem].weight + 100
      : 100;

    const myTargetValue: ISubMenuItemV2 = {
      name,
      url: to,
      weight,
      title: title,
      isFavorite: true,
    };
    dispatch(setFavoritesMyFolder([...myFavoriteListFolder, myTargetValue]));
    setMyListOfFavoriteFolders([...myFavoriteListFolder, myTargetValue]);
    loadMenu();
  }, [
    dispatch,
    loadMenu,
    myFavoriteListFolder,
    name,
    setMyListOfFavoriteFolders,
    title,
    to,
  ]);

  const removeFromFavorite = useCallback(() => {
    const newMyListOfFavoriteFolder = myFavoriteListFolder.filter(
      (submenuItem: ISubMenuItemV2) => submenuItem.name !== name
    );
    dispatch(setFavoritesMyFolder(newMyListOfFavoriteFolder));
    setMyListOfFavoriteFolders(newMyListOfFavoriteFolder);
    loadMenu();
  }, [
    dispatch,
    loadMenu,
    myFavoriteListFolder,
    name,
    setMyListOfFavoriteFolders,
  ]);

  return useCallback(
    (event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
      event.preventDefault();
      if (isFavorite === false) {
        addFavorite();
      } else {
        removeFromFavorite();
      }
    },
    [addFavorite, isFavorite, removeFromFavorite]
  );
};

export default useFavoriteFolderItemHandler;
