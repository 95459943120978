import styles from './index.module.scss';
import { t } from '../../../../services/utils/translation';
import TextField from '../../../controls/TextField';
import React, { FC, memo } from 'react';
import PhoneField from '../../../controls/PhoneField/PhoneField';
import DynamicSelectPaginatedFieldV2 from '../../../controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { getForSelectEmployeeApiV2 } from '../../../../services/api/staff/employee/employeeApi';
import IClientSelectOptionV2 from '../../../../services/api/interfacesApi/IClientSelectOptionV2';
import { IHandlerExternalRequestFormProps } from '../types/IHandlerExternalRequestFormProps';
import { IFirstSectionExternalRequestState } from '../types/IStateExternalRequestForm';
import { useFormikContext } from 'formik';
import { IFormValuesExternalRequestFromTask } from '../../../../pages/external-request/external-requests/ExternalRequestFromTask/ExternalRequestFromTask';
import ExternalRequest from '../../../../models/ExternalRequest';
import useHandleBlurWrappersForExternalRequest from '../formUtils/useHandleBlurWrappersForExternalRequest';
import BillingAccountDetailsSelect from 'components/controls/BillingAccountDetailsSelect/BillingAccountDetailsSelect';
import { getForSelectBillingAccountApiV2 } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import EventDetailsSelect from '../EventDetailsSelect/EventDetailsSelect';
import { getForSelectEventApi } from 'services/api/organizationsV2/event/eventApi';
import useSetAndGetExternalRequestAccessPolicy from 'pages/external-request/external-requests/ExternalRequestPage/hooks/useSetExternalRequestAccessPolicy';

interface PropsTypeExternalRequestFirstSection {
  handlerFormProps: IHandlerExternalRequestFormProps;
  state: IFirstSectionExternalRequestState;
}

const ExternalRequestFormOneStep: FC<PropsTypeExternalRequestFirstSection> = (
  props
) => {
  const { handleBlur, values, errors, touched, handleChange } =
    useFormikContext<IFormValuesExternalRequestFromTask | ExternalRequest>();

  const {
    employeeHaveAccessToRead,
    billingAccountHaveAccessToRead,
    eventHaveAccessToRead,
  } = useSetAndGetExternalRequestAccessPolicy();

  const {
    external_request_external_code_number,
    external_request_source_of_information,
    external_request_reporter_full_name,
    external_request_reporter_phone_number,
    external_request_description,
    author,
    external_request_new_comment,
    event,
    billingAccount,
  } = values;
  const {
    handlerFormProps: { saveValueInStoreForExternalRequest, hasChange },
    state: {
      isNew,
      isDisable,
      initialValues: {
        initialCodeNumber,
        initialSourceOfInformation,
        initialReporterFullName,
        initialReporterPhoneNumber,
        initialDescription,
      },
      isDuplicate,
    },
  } = props;

  const {
    handleBlurCodeNumber,
    handleBlurSourceOfInformation,
    handleBlurReporterFullname,
    handleBlurReporterPhoneNumber,
    handleBlurDescription,
  } = useHandleBlurWrappersForExternalRequest();

  return (
    <>
      <fieldset className={styles.performer} disabled={isDisable}>
        <h4>{t('Основное')}</h4>
        <TextField
          id="external_request_external_code_number"
          type="text"
          label={t('Номер заявки (внешний)')}
          hasError={
            errors.external_request_external_code_number != null &&
            touched.external_request_external_code_number != null
          }
          onBlur={handleBlurCodeNumber}
          onChange={handleChange}
          required={false}
          defaultValue={external_request_external_code_number}
          disabled={isDisable}
          highlightEditing={hasChange(
            initialCodeNumber,
            external_request_external_code_number
          )}
        />
        <TextField
          id="external_request_source_of_information"
          type="text"
          label={t('Источник задания')}
          hasError={
            errors.external_request_source_of_information != null &&
            touched.external_request_source_of_information != null
          }
          onBlur={handleBlurSourceOfInformation}
          onChange={handleChange}
          required={true}
          value={external_request_source_of_information}
          disabled={isDisable}
          highlightEditing={hasChange(
            initialSourceOfInformation,
            external_request_source_of_information
          )}
        />
        <div className="d-flex flex-row justify-content-between">
          <div className="flex-grow-1">
            <TextField
              id="external_request_reporter_full_name"
              type="text"
              label={t('Заявитель (ФИО)')}
              hasError={
                errors.external_request_reporter_full_name != null &&
                touched.external_request_reporter_full_name != null
              }
              onBlur={handleBlurReporterFullname}
              onChange={handleChange}
              required={true}
              value={external_request_reporter_full_name}
              disabled={!isNew && !isDuplicate}
              highlightEditing={hasChange(
                initialReporterFullName,
                external_request_reporter_full_name
              )}
            />
          </div>

          <div className="ml-3" style={{ maxWidth: '200px' }}>
            <PhoneField
              id="external_request_reporter_phone_number"
              label={t('Телефон заявителя')}
              hasError={
                errors.external_request_reporter_phone_number != null &&
                touched.external_request_reporter_phone_number != null
              }
              onBlur={handleBlurReporterPhoneNumber}
              onChange={handleChange}
              required={true}
              value={external_request_reporter_phone_number}
              disabled={(!isNew && !isDuplicate) || isDisable}
              highlightEditing={hasChange(
                initialReporterPhoneNumber,
                external_request_reporter_phone_number
              )}
            />
          </div>
        </div>
        <TextField
          id="external_request_description"
          type="textarea"
          label={t('Суть проблемы')}
          hasError={
            errors.external_request_description != null &&
            touched.external_request_description != null
          }
          onBlur={handleBlurDescription}
          onChange={handleChange}
          required={true}
          disabled={isDisable}
          value={external_request_description}
          highlightEditing={hasChange(
            external_request_description,
            initialDescription
          )}
        />
        {!isNew && !isDuplicate && (
          <DynamicSelectPaginatedFieldV2
            id="author"
            label={t('ФИО Диспетчера, кто создал заявку')}
            hasError={errors.author != null && touched.author != null}
            selectHandler={getForSelectEmployeeApiV2}
            options={author}
            additionalErrorKey="responsible_dispatcher_id"
            onChange={(author: IClientSelectOptionV2<number>) => {
              saveValueInStoreForExternalRequest({
                value: {
                  author,
                },
              });
            }}
            disabled={true}
            haveReadPermission={employeeHaveAccessToRead}
          />
        )}
        {(isNew || isDuplicate) && (
          <TextField
            id="external_request_new_comment"
            type="textarea"
            label={t('Комментарий')}
            hasError={
              errors.external_request_new_comment != null &&
              touched.external_request_new_comment != null
            }
            onBlur={handleBlur}
            onChange={handleChange}
            required={false}
            defaultValue={external_request_new_comment}
            disabled={isDisable}
          />
        )}
      </fieldset>
      {isNew && billingAccount?.value && (
        <BillingAccountDetailsSelect billingAccountPk={billingAccount.value}>
          <DynamicSelectPaginatedFieldV2
            id="billingAccount"
            label={t('Лицевой счет')}
            placeholder={t('Выберите лицевой счет...')}
            hasError={
              (errors.billingAccount != null &&
                touched.billingAccount != null) ||
              (errors.billingAccount != null && touched.billingAccount != null)
            }
            selectHandler={getForSelectBillingAccountApiV2}
            options={values.billingAccount}
            additionalErrorKey="external_request_billing_account_fk"
            onChange={(billingAccount: IClientSelectOptionV2<number>) => {
              saveValueInStoreForExternalRequest({
                value: {
                  billingAccount,
                },
              });
            }}
            disabled={true}
            required={true}
            haveReadPermission={billingAccountHaveAccessToRead}
          />
        </BillingAccountDetailsSelect>
      )}
      {isNew && event?.value && (
        <EventDetailsSelect eventPk={event.value}>
          <DynamicSelectPaginatedFieldV2
            id="event"
            label={t('Событие')}
            placeholder={t('Выберите событие...')}
            hasError={
              (errors.event != null && touched.event != null) ||
              (errors.event != null && touched.event != null)
            }
            selectHandler={getForSelectEventApi}
            options={event}
            additionalErrorKey="external_request_event_fk"
            onChange={(event: IClientSelectOptionV2<number>) => {
              saveValueInStoreForExternalRequest({
                value: {
                  event,
                },
              });
            }}
            disabled={true}
            required={true}
            haveReadPermission={eventHaveAccessToRead}
          />
        </EventDetailsSelect>
      )}
    </>
  );
};

export default memo(ExternalRequestFormOneStep);
