import { BOOKING_SAVE_FILTERS, BOOKING_CLEAR_FILTERS } from './actionTypes';

const bookingReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case BOOKING_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case BOOKING_CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.filters,
      };

    default:
      return state;
  }
};

export default bookingReducer;
