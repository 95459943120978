import React, { FC, memo } from 'react';
import { parseISO } from 'date-fns';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { ICreationExternalRequestInfo } from '../../../services/api/external-request/external-request/IHistoryExternalRequest';
import { IExternalRequestOfHistoryEntity } from '../../../services/api/external-request/external-request/IHistoryExternalRequest';
import HistoryRows from 'components/misc/WrapperHistoryRows/HistoryRows';
import HistoryContainerTable from 'components/tables/HistoryContaierTable/HistoryContainerTable';
import { formatDateTime } from "../../../services/utils/dateHelper/dateHelper";


type PropTypes = {
  externalRequestHistory?: IExternalRequestOfHistoryEntity[];
  creationExternalRequestInfo?: ICreationExternalRequestInfo;
  isLoading?: boolean;
};

//delete duplicate - create component container for history
const ExternalRequestHistory: FC<PropTypes> = (props) => {
  const { externalRequestHistory, isLoading, creationExternalRequestInfo } =
    props;
  return (
    <HistoryContainerTable>
      {isLoading && <LoaderFetch />}
      {externalRequestHistory &&
        externalRequestHistory?.length > 0 &&
        !isLoading &&
        externalRequestHistory.map(
          (record: IExternalRequestOfHistoryEntity) => {
            return (
              !!record?.external_request_edited_column_to_old_and_new_values && (
                <HistoryRows
                  editorFullName={record?.external_request_editor?.full_name}
                  valuesRaw={
                    record.external_request_edited_column_to_old_and_new_values
                  }
                  dateTimeEdit={record?.external_request_datetime_edited}
                />
              )
            );
          }
        )}
      {creationExternalRequestInfo?.internal_task_datetime_added &&
        creationExternalRequestInfo?.internal_task_author_dk && (
          <tr>
            <td>
              {formatDateTime(
                parseISO(
                  creationExternalRequestInfo?.internal_task_datetime_added
                )
              )}
            </td>
            <td colSpan={4}>{'Cоздание'}</td>
            <td>
              {creationExternalRequestInfo?.internal_task_author?.full_name}
            </td>
          </tr>
        )}
    </HistoryContainerTable>
  );
};

ExternalRequestHistory.whyDidYouRender = true;

export default memo(ExternalRequestHistory);
