import { useEffect, useCallback, useRef } from 'react';
import { NotificationSocketClient } from 'services/socket/SocketClientBase';

import useWebSocket from 'react-use-websocket';
import { useDispatch } from 'react-redux';
import { isDevEnv } from 'config';

import useWsEventHandler from './useWsEventHandler';
import CookieManager from 'models/CoockieManager/CookieManager';
import AuthTokenCookie from 'models/CoockieManager/AuthTokenCoockie/AuthTokenCookie';

type EVENT =
  | 'number_of_notification_unreacted_to'
  | 'new_notification'
  | 'clickable_link'
  | 'resource_was_created_or_updated';

const useNotifications = () => {
  const didUnmount = useRef(false);
  const cookieManager = new CookieManager();
  const authTokenCookie = new AuthTokenCookie(cookieManager);
  const hasAuthDomain = authTokenCookie.getAuthToken();
  const wsUrl = !!hasAuthDomain
    ? new NotificationSocketClient().composeBaseUrl()
    : null;
  const { sendMessage, lastJsonMessage } = useWebSocket(
    wsUrl,
    {
      onOpen: () => isDevEnv() && console.log('opened ', wsUrl),
      onClose: () => isDevEnv() && console.log('close ', wsUrl),
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    },
    !!hasAuthDomain
  );

  const handleClickSendMessage = useCallback(
    (message: any) => sendMessage(message),
    [sendMessage]
  );
  const dispatch = useDispatch();

  const wsEventListener = useWsEventHandler();

  useEffect(() => {
    if (lastJsonMessage && wsEventListener) {
      const { event, message } = lastJsonMessage;
      wsEventListener(message, event as EVENT);
    }

    return () => {
      didUnmount.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lastJsonMessage]);

  return { handleClickSendMessage };
};

export default useNotifications;
