import useDeleteParent from 'hooks/useDeleteParent/useDeleteParent';
import { usePostV3 } from 'hooks/usePostV3';
import useSetParent, {
  IPropsTypeUseSetParent,
} from 'hooks/useSetParent/useSetParent';
import {
  setParentExternalRequestForTaskApi,
  setParentTaskForTaskApi,
} from 'services/api/tasks/tasks/tasksApiV2';
import { KeyParent } from 'services/constants/KeyParents';
import { RENULL_KEYS } from 'services/utils/renull/renull';
import { t } from 'services/utils/translation';
import { deleteParentExternalRequestForTaskApi } from '../../../../services/api/tasks/tasks/tasksApiV2';

enum EntityLocaleName {
  externalRequest = 'Заявка',
  task = 'Задача',
}

//TODO duplicate delete

const getSuccessMessageSetParent = (entityName: EntityLocaleName) =>
  t(`${entityName} успешно назначена родителем`);

const getSuccessMessageDeleteParent = (entityName: EntityLocaleName) =>
  t(`${entityName} успешно удалена из родителей`);

const useHandlersTaskHeader = (
  props: Omit<IPropsTypeUseSetParent, 'fetchSetParent' | 'key'>
) => {
  const { refresh, pk } = props;

  const successMessageParentTask = getSuccessMessageSetParent(
    EntityLocaleName.task
  );
  const successMessageParentExternalRequest = getSuccessMessageSetParent(
    EntityLocaleName.externalRequest
  );

  const successMessageDeleteParentTask = getSuccessMessageDeleteParent(
    EntityLocaleName.task
  );

  const successMessageDeleteParentExternalRequest =
    getSuccessMessageDeleteParent(EntityLocaleName.externalRequest);

  // SET REQUEST

  const setParentTask = usePostV3({
    fetchApi: setParentTaskForTaskApi,
    viewClientTextToast: true,
    successMessage: successMessageParentTask,
  });

  const setParentExternalRequest = usePostV3({
    fetchApi: setParentExternalRequestForTaskApi,
    viewClientTextToast: true,
    successMessage: successMessageParentExternalRequest,
  });

  // SET HANDLERS

  const selectHandlerSetParentTask = useSetParent({
    refresh,
    pk,
    key: KeyParent.taskParentForTask,
    fetchSetParent: setParentTask,
  });

  const selectHandlerExternalRequest = useSetParent({
    refresh,
    pk,
    key: KeyParent.externalRequestForTask,
    fetchSetParent: setParentExternalRequest,
  });

  // DELETE REQUEST

  const deleteParentExternalRequest = usePostV3({
    fetchApi: deleteParentExternalRequestForTaskApi,
    viewClientTextToast: true,
    successMessage: successMessageDeleteParentExternalRequest,
  });

  const deleteParentTask = usePostV3({
    fetchApi: deleteParentExternalRequestForTaskApi,
    viewClientTextToast: true,
    successMessage: successMessageDeleteParentTask,
  });

  //DELETE HANDLERS

  const handleDeleteParentTask = useDeleteParent({
    refresh,
    pk,
    parentKeyDelete: KeyParent.taskParentForTask,
    renullKey: RENULL_KEYS.INTERNAL_TASK,
    fetchDeleteParent: deleteParentTask,
  });

  const handleDeleteParentExternalRequest = useDeleteParent({
    refresh,
    pk,
    parentKeyDelete: KeyParent.externalRequestForTask,
    renullKey: RENULL_KEYS.INTERNAL_TASK,
    fetchDeleteParent: deleteParentExternalRequest,
  });

  return {
    selectHandlerSetParentTask,
    selectHandlerExternalRequest,
    handleDeleteParentTask,
    handleDeleteParentExternalRequest,
  };
};

export default useHandlersTaskHeader;
