import { IBillingAccount } from 'services/api/organizationsV2/billing-account/IBillingAccount';
import { extractCounterpartyFromAccount } from './billingAccountUtils';

export const composeTitleBillingAccount = (account: IBillingAccount): string => {
  const billingAccountCounterparty = extractCounterpartyFromAccount(account);

  const billingAccountAddress =
    billingAccountCounterparty?.counterparty_legal_address;

  return account?.billing_account_code_number
    ? `${account.billing_account_code_number} | ${billingAccountAddress ?? ''}`
    : '';
};
