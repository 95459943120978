import { useState, FC } from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'clsx';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { makeLink } from '../../../../services/localPaths';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import TurningArrow from 'components/misc/TurningArrow/TurningArrow';
import { IListEntities } from '../FormHeaderEditEntity/SubHeaderEditEntity';
 
const INITIAL_COUNT_OF_ENTITIES_DISPLAYED = 1;

export interface ILinkEntity {
  pk: number;
  titleEntity: string | number;
  description?: string;
}

type SubHeaderListLinksArrayPropsType = {
  list: ILinkEntity[];
  title: string;
  patch: string;
};
const FormHeaderListsLinksArray: FC<SubHeaderListLinksArrayPropsType> = ({
  list,
  title,
  patch,
}) => {
  const [openList, setOpenList] = useState<boolean>(false);
  const toggleList = () => setOpenList(!openList);
  const firstEntity = list[0];
  return (
    <div className="px-4 py-2 py-sm-2 mr-2 d-flex flex-column justify-content-center align-items-center">
      <div className={classNames(styles.title, 'mb-2')}>{title}</div>
      <div className=" d-flex  align-items-start">
        <div>
          <Link
            className={classNames(styles.tittleChildrenLink)}
            to={makeLink(patch, firstEntity.pk)}
            id={firstEntity.pk + ''}
          >
            {firstEntity.titleEntity}
          </Link>
          <ToolTipWrapper placement="top" target={firstEntity.pk + ''}>
            {firstEntity.description + ''}
          </ToolTipWrapper>

          <Collapse isOpen={openList}>
            {list
              .slice(INITIAL_COUNT_OF_ENTITIES_DISPLAYED)
              .map((child: ILinkEntity) => {
                return (
                  <div
                    className={classNames(styles.tittleChildrenLink)}
                    key={child.pk}
                  >
                    <Link
                      to={makeLink(patch, child.pk)}
                      id={`Tooltip-${child.pk}`}
                    >
                      {child.titleEntity}
                    </Link>
                    {child?.description && (
                      <ToolTipWrapper
                        placement="top"
                        target={`Tooltip-${child.pk}`}
                      >
                        {child.description}
                      </ToolTipWrapper>
                    )}
                  </div>
                );
              })}
          </Collapse>
        </div>

        {list.length > 1 && (
          <TurningArrow
            id={firstEntity?.pk}
            onClick={toggleList}
            turnUp={openList}
          />
        )}
      </div>
    </div>
  );
};

type SubHeaderListLinksPropsType = {
  attributesForListLinks?: IListEntities[];
};

const SubHeaderListLinks: FC<SubHeaderListLinksPropsType> = (props) => {
  const { attributesForListLinks } = props;
  const hasListListsLinks =
    attributesForListLinks && attributesForListLinks?.length > 0;

  return (
    <>
      {hasListListsLinks &&
        attributesForListLinks.map((attributesForListLinks: IListEntities) => {
          const { listLinks, titleListLinks, patch } = attributesForListLinks;
          return (
            listLinks &&
            listLinks?.length > 0 && (
              <FormHeaderListsLinksArray
                title={titleListLinks}
                patch={patch}
                list={listLinks}
              />
            )
          );
        })}
    </>
  );
};

export default SubHeaderListLinks;
