import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class StorageItem<TypeItem> {
  public entityName: string = '';
  public entity: TypeItem;

  constructor(item: TypeItem) {
    this.entity = item;
  }

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      entityName: Yup.string()
        .trim()
        .required(t('Укажите название.'))
        .min(1, t('Название не может быть короче 1 символа.')),
    });
  };
}

export default StorageItem;
