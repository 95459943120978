export const makeFirstLetterUppercase = (str: string) => {
  if (str?.length > 0) {
    return str[0].toUpperCase() + str.slice(1);
  } else {
    return '';
  }
};
export const isDigitalValue = (str: string) => /^\d+$/.test(str);

export const cleanUpPhone = (phone?: string): string => {
    if (phone == null) {
        return '';
    }
    return phone.replace(/[^\d!?]/g, '').slice(-10);
};
