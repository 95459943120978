import classNames from 'clsx';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';

import { useColumnFilter } from 'hooks/useColumnFilter';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import React, { FC, useMemo } from 'react';
import { t } from 'services/utils/translation';
import {
  IGetPaginatedParamsResponsibleDepartment,
  SortCriteriaResponsibleDepartment,
} from '../../../../services/api/external-request/responsible-department/IGetPaginatedParamsResponsibleDepartment';
import { IUseGetList, useGetList } from '../../../../hooks/useGetList';
import {
  getPaginatedResponsibleDepartmentApiV2,
  deleteResponsibleDepartmentApiV2,
} from '../../../../services/api/external-request/responsible-department/responsibleDepartmentApi';
import IResponsibleDepartment from '../../../../services/api/external-request/responsible-department/IResponsibleDepartment';
import { IModal } from '../../../../hooks/useModal';
import useModalV2 from '../../../../hooks/useModalV2';

import LoaderFetch from '../../../../components/layouts/LoaderFetch/LoaderFetch';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import ResponsibleDepartmentForm from 'components/external-request/responsible-department/ResponsibleDepartmentForm';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import { Pager } from '../../../../components/tables/Pager';
import config from 'config.json';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { UI_TITLE } from 'services/localLocalization/UITitle';
import { targetAccessPolicyExternalRequestResponsibleDepartment } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyExternalRequest';
import { extractGetLeftValueIfTrue } from '../../../../services/utils/extractGetLeftValueIfTrue';

const deleteMessageError = t('Не удалось удалить подразделение');
const CREATE_BUTTON_TITLE = t('Добавить ответственную службу');
const MODAL_EDITING_TITLE = t('Редактировать ответственную службу');
const MODAL_CREATE_TITLE = t('Создать ответственную службу');
const MODAL_DELETE_TITLE = t('Удалить ответственную службу');

const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDITING_TITLE,
  MODAL_CREATE_TITLE
);

const COLUMN_LABELS = {
  PK: '№',
  RENDITION: 'Название',
};
const filters = [
  { name: 'responsible_department_pk', label: COLUMN_LABELS.PK },
  { name: 'responsible_department_rendition', label: COLUMN_LABELS.RENDITION },
] as const;

const columnVisibilityInitial = {
  responsible_department_pk: true,
  responsible_department_rendition: true,
};

const ResponsibleDepartmentPage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial);

  const initialParams: IGetPaginatedParamsResponsibleDepartment = useMemo(
    () => ({
      skip: 0,
      length: config.pageLength,
    }),
    []
  );

  const {
    data: responsibleDepartmentList,
    isLoading,
    refreshListData,
    onSearchRequest,
    onSortClick,
    total,
    setStart,
    setLength,
    params: { skip, length },
  }: IUseGetList<
    IResponsibleDepartment[],
    IGetPaginatedParamsResponsibleDepartment
  > = useGetList({
    getDataApi: getPaginatedResponsibleDepartmentApiV2,
    initialParams,
  });

  const {
    state: {
      isAddEditFormOpen,
      isDeleteFormOpen,
      entity: responsibleDepartment,
    },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteResponsibleDepartmentApiV2,
    entityIdKey: 'pk',
  });

  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy:
        targetAccessPolicyExternalRequestResponsibleDepartment,
      targetAction: ['U', 'C', 'D'],
    });

  return (
    <MainLayout>
      <Subheader>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <AddIcon className="mr-2 text-white" />
            {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>

      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                scope="col"
                className={classNames('p-0', {
                  'd-none': !visibility.responsible_department_pk,
                })}
                style={{ width: '5%' }}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaResponsibleDepartment.NumberAsc}
                  title={COLUMN_LABELS.PK}
                  onSort={onSortClick}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'responsible_department_pk');
                  }}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${
                  !visibility.responsible_department_rendition ? 'd-none' : ''
                }`}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaResponsibleDepartment.RenditionAsc}
                  title={COLUMN_LABELS.RENDITION}
                  onSearch={(searchString: string) => {
                    onSearchRequest(
                      searchString,
                      'responsible_department_rendition'
                    );
                  }}
                  onSort={onSortClick}
                />
              </th>
              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {responsibleDepartmentList &&
                responsibleDepartmentList.map(
                  ({
                    responsible_department_pk: pk,
                    responsible_department_rendition: rendition,
                  }: IResponsibleDepartment) => {
                    return (
                      <tr key={pk}>
                        <td
                          className={classNames({
                            'd-none': !visibility.responsible_department_pk,
                          })}
                        >
                          {pk}
                        </td>
                        <td
                          className={classNames({
                            'd-none':
                              !visibility.responsible_department_rendition,
                          })}
                        >
                          {rendition}
                        </td>
                        <td align="right">
                          <ActionsDropdown>
                            {haveAccessToUpdate && (
                              <ActionsDropdownItem
                                label={UI_TITLE.EDIT}
                                onClick={openAddEditForm.bind(null, {
                                  pk,
                                  rendition,
                                })}
                                icon={<EditIcon className="text-primary" />}
                              />
                            )}
                            {haveAccessToDelete && (
                              <ActionsDropdownItem
                                label={UI_TITLE.DELETE}
                                onClick={toggleDeleteForm.bind(null, {
                                  pk,
                                  rendition,
                                })}
                                icon={<DeleteIcon className="text-primary" />}
                              />
                            )}
                          </ActionsDropdown>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          )}
        </table>
        {isLoading && <LoaderFetch />}
        <ModalWrapper
          headerLabel={getModalTitle(!!responsibleDepartment?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <ResponsibleDepartmentForm
            successHandler={successHandler}
            pk={responsibleDepartment?.pk}
          />
        </ModalWrapper>
        {responsibleDepartment?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={responsibleDepartment?.pk}
            entityName={responsibleDepartment?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />
      </div>
    </MainLayout>
  );
};

export default ResponsibleDepartmentPage;
