import { ORDER_NO_PERFORMERS } from 'config';
import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import IClientSelectOptionV2 from '../services/api/interfacesApi/IClientSelectOptionV2';
import { IExternalRequestStatusOld } from '../services/api/external-request/external-request/IExternalRequestStatusOld';
import { createClientSelectOptionV2 } from '../services/utils/selects/selects';
import { IEmployee } from '../services/api/staff/employee/IEmployee';
import getAvailableExternalRequestFormSections from 'components/external-request/ExternalRequestForm/formUtils/getAvailableExternalRequestFormSections';
import { regTelephoneNumber } from '../services/utils/myRegex/myRegex';
import { EVENT_STATE } from 'components/external-request/ExternalRequestForm/ExternalRequestSecondSection/constants';

const STATUS_CLOSE_ID = 8; //matches closing statuses on the backend
class ExternalRequest {
  public billingAccount?: IClientSelectOptionV2;
  // public external_request_billing_account_fk?: number;
  public pk?: number;
  public external_request_external_code_number?: string;
  public external_request_address_of_incident?: string;
  public external_request_description?: string;
  public workCategory?: IClientSelectOptionV2;
  // public external_request_work_category_fk?: number;
  public external_request_reporter_full_name?: string;
  public external_request_reporter_phone_number?: string;
  public external_request_source_of_information?: string;
  public author?: IClientSelectOptionV2;
  public executiveImplementer?: IClientSelectOptionV2;

  // public external_request_executive_implementer_dk?: number;
  public external_request_date_supposed_duedate?: string;
  public implementers?: IClientSelectOptionV2[] = [];
  public external_request_new_comment?: string;
  public supervisor?: IClientSelectOptionV2;
  public status?: IClientSelectOptionV2;
  public availableStatuses?: IClientSelectOptionV2[];
  // public external_request_status_fk?: number;
  public responsibleDepartment?: IClientSelectOptionV2;
  // public external_request_responsible_department_fk?: number;
  public performerType?: string = ORDER_NO_PERFORMERS;
  public currentStatus?: IExternalRequestStatusOld;
  public affiliate?: IClientSelectOptionV2;

  // For colored labels.
  // public status_name?: string;
  // public status_color?: string;
  public external_request_warehouse_note?: string;
  public external_request_parent_external_request_fk?: number;
  public external_request_parent_internal_task_fk?: number;

  public external_request_payment_status?: string;
  public external_request_payment_amount?: string;
  public external_request_payment_kind?: string;

  public isHaveMechanization?: boolean = false;
  public isPruned?: boolean = false;
  public event?: IClientSelectOptionV2;
  public eventState: EVENT_STATE = EVENT_STATE.enabled_event;
  public isEventClosed: boolean = false;
  public isBillingAccountClosed: boolean = false;

  constructor(
    externalRequestPk?: number,
    user?: IEmployee,
    billingAccountId?: number,
    eventPk?: number
  ) {
    this.pk = externalRequestPk;
    this.setInitialUser(user);
    this.setInitialBillingAccount(billingAccountId);
    this.setInitialEvent(eventPk);
  }

  private setInitialBillingAccount = (billingAccountId?: number) => {
    this.billingAccount = billingAccountId
      ? { label: '', value: billingAccountId }
      : undefined;
  };

  private setInitialEvent = (eventPk?: number) => {
    this.event = eventPk ? { label: '', value: eventPk } : undefined;
  };

  private setInitialUser = (user?: IEmployee) => {
    if (user != null) {
      this.author = createClientSelectOptionV2(
        user.employee_pk,
        user.employee_full_name
      );
    }
  };

  private static isHaveNotExecutiveImplementerInImplementers = (
    instanceImplementers?: IClientSelectOptionV2[],
    curValue?: IClientSelectOptionV2
  ) => {
    if (!curValue || !instanceImplementers) {
      return true;
    }

    return !instanceImplementers.some((implementer: IClientSelectOptionV2) => {
      return implementer.value === curValue?.value;
    });
  };

  private static isClosedEvent = (
    eventIsClosed?: boolean,
    curValue?: IClientSelectOptionV2
  ) => {
    if (!curValue) {
      return true;
    }
    return !eventIsClosed;
  };

  private static isClosedBillingAccount = (
    billingAccountIsClosed?: boolean,
    curValue?: IClientSelectOptionV2
  ) => {
    if (!curValue) {
      return true;
    }
    return !billingAccountIsClosed;
  };

  private static isHaveNotExecutiveImplementer = (
    instanceExecutiveImplementers?: IClientSelectOptionV2,
    implementers?: IClientSelectOptionV2[]
  ) => {
    if (!implementers || implementers.length === 0) {
      return true;
    }
    return !!instanceExecutiveImplementers?.value;
  };

  private static isEventUnable = (
    eventState?: string,
    event?: IClientSelectOptionV2
  ) => {
    if (eventState === EVENT_STATE.unable_event) {
      return true;
    }
    return !!event;
  };

  private static isEventEnabled = (
    eventState?: string,
    event?: IClientSelectOptionV2
  ) => {
    if (eventState === EVENT_STATE.enabled_event) {
      return true;
    }
    return !!event;
  };

  public static validationSchema = (
    nextStatusValue: number | null,
    statusPk?: number
  ): Yup.ObjectSchema<any> => {
    const { accessSecondSection, accessThirdSection } =
      getAvailableExternalRequestFormSections(statusPk);
    if (nextStatusValue === STATUS_CLOSE_ID) {
      return Yup.object({
        status: Yup.object().requiredByCondition(
          nextStatusValue !== STATUS_CLOSE_ID,
          (value: number) => value === STATUS_CLOSE_ID,
          ''
        ),
      });
    }
    return Yup.object({
      external_request_external_code_number: Yup.string(),
      external_request_address_of_incident: Yup.string()
        .trim()
        .min(1, t('Введите 1 или более букв.')),
      external_request_description: Yup.string()
        .trim()
        .min(1, t('Введите 1 или более букв.'))
        .required(t('Укажите суть проблемы.')),
      external_request_source_of_information: Yup.string()
        .trim()
        .min(1, t('Введите 1 или более букв.'))
        .required(t('Укажите источник задания.')),
      external_request_reporter_full_name: Yup.string()
        .trim()
        .min(1, t('Введите 1 или более букв.'))
        .required(t('Укажите ФИО заявителя.')),
      external_request_reporter_phone_number: Yup.string()
        .trim()
        .matches(regTelephoneNumber, t('Некорректно введен номер телефона'))
        .required(t('Укажите телефон заявителя.')),
      external_request_date_supposed_duedate: Yup.string()
        .requiredWhenDefined()
        .dateValid()
        .dateNotEarlierThan()
        .requiredByCondition(
          accessThirdSection,
          (value: string) => value?.length > 0,
          'Укажите планируемую дату завершения'
        ),
      event: Yup.object()
        .requiredByConditionWithRef(
          {
            keyField: 'eventState',
            getCondition: this.isEventUnable,
            precondition: !accessSecondSection,
          },
          t('Выберите событие')
        )
        .requiredByConditionWithRef(
          {
            keyField: 'isEventClosed',
            getCondition: this.isClosedEvent,
            precondition: !accessSecondSection,
          },
          t('Это событие закрыто. Отредактируйте событие или выберите другое')
        )
        .nullable(),

      workCategory: Yup.object()
        .nullable()
        .requiredByCondition(
          accessThirdSection,
          (value: IClientSelectOptionV2) => !!value,
          t('Выберите классификацию работ.')
        ),
      author: Yup.object(),
      supervisor: Yup.object()
        .nullable()
        .requiredByCondition(
          accessSecondSection,
          (value: IClientSelectOptionV2) => !!value,
          t('Укажите ответственного руководителя')
        ),
      implementers: Yup.array().requiredByConditionWithRef(
        {
          keyField: 'executiveImplementer',
          getCondition: this.isHaveNotExecutiveImplementer,
        },
        t(
          'Нельзя назначить исполнителя без выбранного ответственного исполнителя'
        )
      ),
      executiveImplementer: Yup.object()
        .nullable(true)
        .requiredByConditionWithRef(
          {
            keyField: 'implementers',
            getCondition: this.isHaveNotExecutiveImplementerInImplementers,
          },
          t(
            'Нельзя назначить ответственным исполнителем человека из исполнителя.'
          )
        ),

      status: Yup.object().nullable(true),
      responsibleDepartment: Yup.object()
        .nullable()
        .requiredByCondition(
          accessSecondSection,
          (value: IClientSelectOptionV2) => !!value,
          t('Выберите ответственную службу.')
        ),

      billingAccount: Yup.object()
        .nullable()
        // .requiredByCondition(
        //   accessSecondSection,
        //   (value: IClientSelectOptionV2) => !!value,
        //   t('Укажите лицевой счет.')
        // )
        .requiredByConditionWithRef(
          {
            keyField: 'eventState',
            getCondition: this.isEventEnabled,
            precondition: !accessSecondSection,
          },
          t('Выберите лицевой счет')
        )
        .requiredByConditionWithRef(
          {
            keyField: 'isBillingAccountClosed',
            getCondition: this.isClosedBillingAccount,
            precondition: !accessSecondSection,
          },
          t(
            'Этот лицевой счет закрыт. Отредактируйте лицевой счет или выберите другой'
          )
        )
        .nullable(),

      external_request_payment_status: Yup.string(),
      external_request_payment_kind: Yup.string(),
      external_request_payment_amount: Yup.string(),
    });
  };
}

export default ExternalRequest;
