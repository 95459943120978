import classNames from 'clsx';
import { FC, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import { t } from '../../../../services/utils/translation';
import { Link } from 'react-router-dom';
import { makeLink } from '../../../../services/localPaths';

import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import useGetEventDetails from './hooks/useGetEventDetails/useGetEventDetails';
import {
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
  PATH_ORGANIZATIONS_EVENT_VIEW,
} from 'services/pathConstants';
import AlertWrapper from 'components/misc/AlertWrapper/AlertWrapper';

type EventDetailsFormPropsType = {
  eventPk?: number;
  className?: string;
};

const EventDetailsSelect: FC<PropsWithChildren<EventDetailsFormPropsType>> = (
  props
) => {
  const { eventPk, children, className } = props;
  const { eventDetails, isLoadingEvent, location } =
    useGetEventDetails(eventPk);
  const eventBillingAccount = eventDetails?.eventBillingAccount;
  return (
    <fieldset className={classNames(styles.fieldsContainer, className)}>
      <h4>
        {t('Событие ')}
        {eventDetails && !isLoadingEvent && eventPk && (
          <Link
            state={{ from: location }}
            to={{
              pathname: makeLink(
                PATH_ORGANIZATIONS_EVENT_VIEW,
                eventDetails.eventPk
              ),
            }}
          >
            {eventDetails.eventName}
          </Link>
        )}
      </h4>
      {children}
      {eventPk && (
        <ListGroup horizontal className="w-100">
          <ListGroupItem className="w-50 r-1">
            <ListGroupItemHeading
              className="font-weight-bold "
              style={{ fontSize: '14px' }}
            >
              {t('Тип')}
            </ListGroupItemHeading>

            <ListGroupItemText>
              {!isLoadingEvent ? (
                <>
                  <p>{eventDetails?.eventTypeName}</p>
                </>
              ) : (
                <LoaderFetch />
              )}
            </ListGroupItemText>
          </ListGroupItem>
          <ListGroupItem className="w-50">
            <ListGroupItemHeading
              className="font-weight-bold w-50"
              style={{ fontSize: '14px' }}
            >
              {t('Лицевой счет')}
            </ListGroupItemHeading>
            {eventBillingAccount && (
              <ListGroupItemText>
                {!isLoadingEvent ? (
                  <>
                    <span>
                      {t('Название: ')}
                      <Link
                        to={{
                          pathname: makeLink(
                            PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
                            eventBillingAccount.billingAccountPk
                          ),
                        }}
                        state={{ from: location }}
                      >
                        {eventBillingAccount.billingAccountPk}
                      </Link>
                    </span>
                    <p>
                      {t('Адрес')}: {eventBillingAccount.address + ''}
                    </p>
                  </>
                ) : (
                  <LoaderFetch />
                )}
              </ListGroupItemText>
            )}
          </ListGroupItem>
        </ListGroup>
      )}
      {eventDetails?.isClosed && (
        <AlertWrapper
          text={t('Событие закрыто')}
          helpText={t('Отредактируйте событие или выберите другое')}
        />
      )}
    </fieldset>
  );
};

export default EventDetailsSelect;
