import { useCallback } from 'react';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { KeyParent } from 'services/constants/KeyParents';

type TSetParentParams = {
  [key in KeyParent]: number;
} & {
  pk: number;
};
export interface IPropsTypeUseSetParent {
  refresh?: () => void;
  pk?: number;
  key: KeyParent;
  fetchSetParent: (
    params: TSetParentParams,
    setErrors?: Function | undefined,
    resetForm?: Function | undefined
  ) => Promise<void>;
}

const useSetParent = ({
  refresh,
  pk,
  fetchSetParent,
  key,
}: IPropsTypeUseSetParent) => {
  return useCallback(
    async (option: IClientSelectOptionV2) => {
      if (pk !== undefined && refresh) {
        const params: TSetParentParams = {
          [key]: option.value,
          pk,
        } as TSetParentParams;
        await fetchSetParent(params);
        refresh();
      }
    },
    [fetchSetParent, key, pk, refresh]
  );
};

export default useSetParent;
