import { FC, memo } from 'react';
import { t } from 'services/utils/translation';
import classNames from 'clsx';
import styles from './index.module.scss';

import {
  PATH_EXTERNAL_REQUEST_EDIT,
  PATH_EXTERNAL_REQUEST_FROM_TASK,
  PATH_TASK_CREATE_CHILD_TICKET,
  PATH_TASK_EDIT,
} from '../../../services/pathConstants';
import ButtonSubHeaderForm from 'components/controls/ButtonSubHeaderForn/ButtonSubHeaderForm';
import useNextWindow from '../../../hooks/useNextWindow';
import { IFormValuesTask } from '../TaskForm/TaskForm';
import FormHeaderLink from '../../controls/FormHeader/FormHeaderLink/FormHeaderLink/FormHeaderLink';
import { IChildInternalTask } from '../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import { IChildExternalRequest } from '../../../services/api/external-request/external-request/IExternalRequest';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyTask } from '../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import { PATH_ORGANIZATIONS_ACCOUNTS_VIEW } from '../../../services/pathConstants';
import { getInitialTaskFromStore } from '../../../store/task/selectors';
import { useSelector } from 'react-redux';
import ListChildrenLinksSubHeader from 'components/controls/ListLinkFormHeader/ListLinkFormHeader/ListLinkFormHeader';
import { getAccountNumber } from '../../../services/utils/bilingAccountUtils/billingAccountUtils';
import { getTaskForSelectApiV2 } from 'services/api/tasks/tasks/tasksApiV2';
import PopoutDynamicSelectPaginated from 'components/controls/PopoutDynamicSelectPaginted/PopoutDynamicSelectPaginated';
import { PopoutSelectMenuPosition } from 'components/controls/PopoutDynamicSelectPaginted/ui/PopoutSelectMenu/PopoutSelectMenu';
import useHandlersTaskHeader from './hooks/useHandlersTaskHeader';
import { getExternalRequestForSelectApiV2 } from 'services/api/external-request/external-request/externalRequestApi';
import { UI_TITLE } from 'services/localLocalization/UITitle';

//TODO: move variables - because they are also used external request
const BILLING_ACCOUNT_HEADER_LABEL = t('Лицевой счет');

const BUTTON_SUBHEADER_TEXT_SUBTASK = t('Создать подзадачу');
const BUTTON_SUBHEADER_TEXT_TO_ORDER = t('Создать подзаявку');

export const SUBHEADER_TASK_PARENT_TITLE = t('Родительская задача');
export const SUBHEADER_EXTERNAL_REQUEST_PARENT_TITLE = t('Родительская заявка');

export const TITLE_CHILDREN_TASKS = t('Дочерние задачи');
export const TITLE_CHILDREN_EXTERNAL_REQUEST = t('Дочерние заявки');

const getTitle = (
  titleTaskPk?: string,
  isDuplicate?: boolean,
  isInitialValuesFromOrder?: boolean
) => {
  if (!isDuplicate && !isInitialValuesFromOrder) {
    return `${t('Задача')} ${titleTaskPk}`;
  }
  if (isDuplicate) {
    return `${t('Создать подзадачу')} ${titleTaskPk}`;
  }

  return `Создать задачу из заявки`;
};

type PropsType = {
  taskPk?: number;
  isDuplicate?: boolean;
  isInitialValuesFromOrder?: boolean;
  task?: IFormValuesTask | null;
  refreshForm?: () => void;
};

const TaskHeader: FC<PropsType> = (props) => {
  const { taskPk, isDuplicate, isInitialValuesFromOrder, task, refreshForm } =
    props;

  const parentTaskFk = task?.internal_task_parent_internal_task_fk;
  const parentExternalRequestFk =
    task?.internal_task_parent_external_request_fk;
  const { account: billingAccount } = useSelector(getInitialTaskFromStore);
  const nextWindowChildrenTask = useNextWindow(PATH_TASK_CREATE_CHILD_TICKET);

  const nextWindowCreateExternalRequest = useNextWindow(
    PATH_EXTERNAL_REQUEST_FROM_TASK
  );

  const titleTaskPk =
    taskPk && !isDuplicate && !isInitialValuesFromOrder ? `№${taskPk}` : '';

  const {
    selectHandlerSetParentTask,
    selectHandlerExternalRequest,
    handleDeleteParentTask,
    handleDeleteParentExternalRequest,
  } = useHandlersTaskHeader({ pk: taskPk, refresh: refreshForm });

  const [haveAccessToCreate, haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyTask,
    targetAction: ['C', 'U'],
  });

  const [internalTaskListChildren, externalRequestListChildren]: [
    IChildInternalTask[] | undefined,
    IChildExternalRequest[] | undefined
  ] = [task?.childInternalTasks, task?.childExternalRequests];

  const hasChildrenTasks =
    internalTaskListChildren &&
    internalTaskListChildren.length > 0 &&
    !isDuplicate;

  const hasChildrenExternalRequests =
    externalRequestListChildren &&
    externalRequestListChildren.length > 0 &&
    !isDuplicate;

  const title = getTitle(titleTaskPk, isDuplicate, isInitialValuesFromOrder);

  const accountNumber =
    billingAccount?.label && getAccountNumber(billingAccount?.label);

  const hasParentExternalRequest = !!parentExternalRequestFk;
  const hasParentTaskFk = !!parentTaskFk;

  const buttonSetExternalRequestParentText = hasParentExternalRequest
    ? `${t('Родительская заявка')}:${parentExternalRequestFk}`
    : UI_TITLE.TO_PARENT_EXTERNAL_REQUEST;

  const buttonSetTaskParentText = hasParentTaskFk
    ? `${t('Родительская задача')}:${parentTaskFk}`
    : UI_TITLE.TO_PARENT_TASK;

  return (
    <div
      className={classNames(
        'py-2 py-md-3 pl-4 pr-4',
        'd-flex flex-row justify-content-between align-items-center',
        styles.header
      )}
    >
      <div
        className={classNames(
          styles.headerData,
          'd-flex flex-column flex-sm-row'
        )}
      >
        <div
          className={classNames(
            styles.title,
            'd-flex flex-column justify-content-center px-3'
          )}
        >
          <div>{title}</div>
        </div>

        <div
          className={classNames(
            'd-flex flex-row justify-content-start align-items-start '
          )}
        >
          {accountNumber && (
            <FormHeaderLink
              title={BILLING_ACCOUNT_HEADER_LABEL}
              path={PATH_ORGANIZATIONS_ACCOUNTS_VIEW}
              pk={billingAccount.value}
            >
              {accountNumber}
            </FormHeaderLink>
          )}
          {!isDuplicate && (
            <FormHeaderLink
              pk={parentTaskFk}
              path={PATH_TASK_EDIT}
              title={SUBHEADER_TASK_PARENT_TITLE}
            >
              {parentTaskFk}
            </FormHeaderLink>
          )}
          {hasChildrenTasks && (
            <ListChildrenLinksSubHeader
              listOfChildren={internalTaskListChildren}
              title={TITLE_CHILDREN_TASKS}
              path={PATH_TASK_EDIT}
            />
          )}
          <FormHeaderLink
            pk={parentExternalRequestFk}
            path={PATH_EXTERNAL_REQUEST_EDIT}
            title={SUBHEADER_EXTERNAL_REQUEST_PARENT_TITLE}
          >
            {parentExternalRequestFk}
          </FormHeaderLink>
          {hasChildrenExternalRequests && (
            <ListChildrenLinksSubHeader
              listOfChildren={externalRequestListChildren}
              title={TITLE_CHILDREN_EXTERNAL_REQUEST}
              path={PATH_EXTERNAL_REQUEST_EDIT}
            />
          )}
        </div>
      </div>
      {taskPk &&
        !isDuplicate &&
        !isInitialValuesFromOrder &&
        haveAccessToCreate && (
          <div className="d-flex-column">
            <div>
              <ButtonSubHeaderForm
                handleClick={nextWindowChildrenTask.bind(null, taskPk)}
                text={BUTTON_SUBHEADER_TEXT_SUBTASK}
                className={classNames(styles.subButtonsCreateChildren)}
              />
              <ButtonSubHeaderForm
                handleClick={nextWindowCreateExternalRequest.bind(null, taskPk)}
                text={BUTTON_SUBHEADER_TEXT_TO_ORDER}
                className={classNames(styles.subButtonsCreateChildren)}
              />
            </div>
            {haveAccessToUpdate && (
              <div className={classNames('d-flex')}>
                <PopoutDynamicSelectPaginated
                  id="taskParentSelected"
                  placeholder={t('Выберите родительскую задачу...')}
                  onChange={selectHandlerSetParentTask}
                  options={[]}
                  selectHandler={getTaskForSelectApiV2}
                  buttonDefaultText={buttonSetTaskParentText}
                  handlerForBeforeIcon={handleDeleteParentTask}
                  isViewIconBefore={hasParentTaskFk}
                />

                <PopoutDynamicSelectPaginated
                  id="externalRequestParentSelected"
                  placeholder={t('Выберите родительскую заявку...')}
                  onChange={selectHandlerExternalRequest}
                  options={[]}
                  selectHandler={getExternalRequestForSelectApiV2}
                  position={PopoutSelectMenuPosition.left}
                  buttonDefaultText={buttonSetExternalRequestParentText}
                  handlerForBeforeIcon={handleDeleteParentExternalRequest}
                  isViewIconBefore={hasParentExternalRequest}
                />
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default memo(TaskHeader);
