import * as Yup from 'yup';
import { t } from 'services/utils/translation';
import IClientSelectOptionV2 from '../services/api/interfacesApi/IClientSelectOptionV2';

class Booking {
  pk?: number;
  vehicle?: IClientSelectOptionV2<number>;
  externalRequestPk?: number;
  constructor(externalRequestPk?: number) {
    this.externalRequestPk = externalRequestPk;
  }
  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      vehicle: Yup.object().nullable().required(t('Выберите машину.')),
    });
  };
}

export default Booking;
