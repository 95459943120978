import React, { FC, ReactNode, memo } from 'react';
import DocViewer, {
  DocViewerRenderers,
  IHeaderOverride,
} from 'react-doc-viewer';

import ReactHtmlParser from 'react-html-parser';
import useFileReader from './hooks/useFileReader';
import styles from './index.module.scss';

const myHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
  if (!state.currentDocument || state.config?.header?.disableFileName) {
    return null;
  }

  return (
    <>
      <div style={{ backgroundColor: 'blue' }}>
        {state.currentDocument.uri || ''}
      </div>
      <div>
        <button onClick={previousDocument} disabled={state.currentFileNo === 0}>
          Previous Document
        </button>
        <button
          onClick={nextDocument}
          disabled={state.currentFileNo >= state.documents.length - 1}
        >
          Next Document
        </button>
      </div>
    </>
  );
};

const configDocViewer = {
  header: {
    disableHeader: true,
    disableFileName: true,
    retainURLParams: false,
    overrideComponent: myHeader,
  },
};

type PropsType = {
  src: null | Blob;
  fileType?: string;
};

const DocPreview: FC<PropsType> = (props) => {
  const { src, fileType } = props;

  const isDoc = (fileType && fileType === 'doc') || fileType === 'docx';

  const { uriBlob, htmlContent } = useFileReader(src, fileType);

  return (
    <>
      {uriBlob && !isDoc && (
        <DocViewer
          documents={[{ uri: uriBlob, fileType }]}
          pluginRenderers={DocViewerRenderers}
          config={configDocViewer}
          className={styles['document-preview']}
        />
      )}
      {htmlContent && (
        <div style={{ maxWidth: '700px' }}>
          {
            ReactHtmlParser(htmlContent.trim(), {
              decodeEntities: true,
            }) as ReactNode
          }
        </div>
      )}
    </>
  );
};

export default memo(DocPreview);
