import { useCallback, useMemo } from 'react';
 
const useCounterpartyHandleSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
 
  const handleSearchCounterpartyPk = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'counterparty_pk');
    },
    [onSearchRequest]
  );

  const handleSearchPhoneNumber = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'counterparty_phone_number');
    },
    [onSearchRequest]
  );

  const handleSearchDescription = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'counterparty_description');
    },
    [onSearchRequest]
  );

  const handleSearchEmail = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'counterparty_email');
    },
    [onSearchRequest]
  );

  const handleSearchActualAddress = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'counterparty_actual_address');
    },
    [onSearchRequest]
  );

  const handleSearchINN = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'counterparty_inn');
    },
    [onSearchRequest]
  );

  const handleCounterpartyCompleteName = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'counterparty_complete_name');
    },
    [onSearchRequest]
  );

  return useMemo(
    () => ({
      handleSearchCounterpartyPk,
      handleSearchPhoneNumber,
      handleSearchDescription,
      handleSearchEmail,
      handleSearchActualAddress,
      handleSearchINN,
      handleCounterpartyCompleteName,
    }),
    [
      handleCounterpartyCompleteName,
      handleSearchActualAddress,
      handleSearchCounterpartyPk,
      handleSearchDescription,
      handleSearchEmail,
      handleSearchINN,
      handleSearchPhoneNumber,
    ]
  );
};

export default useCounterpartyHandleSearch;
