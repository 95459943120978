import classNames from 'clsx';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Folder from 'components/misc/Folder';
import React, { FC } from 'react';
import { PATH_FRONTPAGE } from 'services/pathConstants';
import styles from './index.module.scss';
import { t } from '../../../services/utils/translation';
import ListFolderItems from 'components/misc/ListFolder/ListFolder';
import { useSelector } from 'react-redux';
import { getMenuFavoritesFromStore } from '../../../store/general/selectors';

const MY_FAVORITE_FOLDER_TITLE = t('Избранное');

const HomePage: FC = () => {
  const myFavoritesMenu = useSelector(getMenuFavoritesFromStore);

  return (
    <MainLayout>
      <div className="justify-content-center">
        <Folder path={PATH_FRONTPAGE} />
        {myFavoritesMenu.length > 0 && (
          <>
            <div className={classNames('d-flex-column', styles.line)}>
              <h4 className={styles.mainTitle}>{MY_FAVORITE_FOLDER_TITLE}</h4>
            </div>
            <ListFolderItems menu={myFavoritesMenu} />
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default HomePage;
