import { t } from 'services/utils/translation';
import { MainDescriptionService } from './MainDescriptionService';

export class NumberOfDocumentsDescriptionService extends MainDescriptionService {
  protected DOCUMENTS_TEXT = t('Файлы');
  public getNumberOfDocumentsDescription(numberOfDocuments: number | null) {
    if (numberOfDocuments === null) {
      return this.handleNull(this.DOCUMENTS_TEXT);
    }
    if (numberOfDocuments <= 0) {
      return `${this.DOCUMENTS_TEXT}: ${this.NO_TEXT}`;
    }
    return `${this.DOCUMENTS_TEXT}: ${numberOfDocuments}`;
  }
}
