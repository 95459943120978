import { RootStateOrAny } from 'react-redux';
import { IBillingAccountFilters } from 'services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';
import { ICounterpartyFilters } from 'services/api/organizationsV2/counterparty/IGetCounterPartyPaginatedParams';
import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { RootState } from 'store/IRootState';

export const getCounterpartyFiltersFromStore = (
  state: RootState
): ICounterpartyFilters => state.counterparty.tableFilters;

export const getCounterpartyTaskTableFiltersFromStore = (
  state: RootStateOrAny
): { [x: number]: ITaskFilters } => state.counterparty.taskTableFilters;

export const getCounterpartyBillingAccountTableFiltersFromStore = (
  state: RootStateOrAny
): { [x: number]: IBillingAccountFilters } =>
  state.counterparty.billingAccountTableFilters;
