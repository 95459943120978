import { useState, useEffect, RefObject } from 'react';

function useFocus<T extends HTMLElement>(elementRef: RefObject<T>): boolean {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const element = elementRef.current;

    if (element) {
      element?.addEventListener('focus', handleFocus);
      element?.addEventListener('blur', handleBlur);
    }

    return () => {
      if (element) {
        element?.removeEventListener('focus', handleFocus);
        element?.removeEventListener('blur', handleBlur);
      }
    };
  }, [elementRef]);

  return isFocused;
}

export default useFocus;
