import { memo } from 'react';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import { ReactComponent as IconExport } from 'img/icons/icon-downoload.svg';
import { Spinner } from 'reactstrap';
import useExportApi from 'hooks/useExportApi';
import { t } from '../../../services/utils/translation';

const HINT_DISABLED_EXPORT = t(
  'У вас недостаточно прав для экспорта этого ресурса. Обратитесь, пожалуйста, к администратору'
);

const HINT_EXPORT = t('Экспорт в Excel');

type PropsType<Params> = {
  params: Params;
  getExportLinkApi: (
    params: IBaseParams<Params>
  ) => Promise<IBaseResponse<string>>;
  disabled: boolean;
  convertParams?: any; //TODO fix any
  haveAccessToExport?: boolean;
};

const SubheaderButtonExportComponent = <Params,>(props: PropsType<Params>) => {
  const {
    params,
    getExportLinkApi,
    disabled = false,
    convertParams,
    haveAccessToExport = true,
  } = props;

  const { isLoadingExportLink, handlerExport } = useExportApi({
    getExportLinkApi,
    convertParams,
  });

  const disabledExport = disabled || !haveAccessToExport || isLoadingExportLink;

  const hintExport = haveAccessToExport ? HINT_EXPORT : HINT_DISABLED_EXPORT;
  return (
    <SubheaderButton
      bordered={false}
      hintText={hintExport}
      hintTarget={'loadingExport'}
      disabled={disabledExport}
      onClick={handlerExport.bind(null, params)}
    >
      {!isLoadingExportLink ? (
        <IconExport />
      ) : (
        <div className="   d-flex align-items-center">
          <Spinner color="light" size="sm" className="ml-1" />
        </div>
      )}
    </SubheaderButton>
  );
};

export const SubheaderButtonExport = memo(
  SubheaderButtonExportComponent
) as typeof SubheaderButtonExportComponent;

export default SubheaderButtonExport;
