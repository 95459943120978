import { Link } from 'react-router-dom';
import { FC } from 'react';

type PropsType = {
  text: string;
  to: string;
};

const ToastLink: FC<PropsType> = ({ text, to }) => {
  const location = window.location.href;

  return (
    <Link
      to={{
        pathname: to,
      }}
      state={{ from: location }}
    >
      {text}
    </Link>
  );
};

export default ToastLink;
