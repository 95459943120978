import {
  IDENTITY_LOGOUT,
  SET_ACCESS_TOKEN,
  SET_ACTIVE_INSTALLATION,
  USER_LOGIN,
} from './actionTypes';
import { initialState } from "../initialStore/InitialState";

/**
 * Auth reducer.
 *
 * @param state
 * @param action
 */
const authReducer = (state = {}, action: any) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        loggedIn: true,
        identity: action.identity,
      };

    case SET_ACTIVE_INSTALLATION:
      return {
        ...state,
        activeInstallation: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };

    case IDENTITY_LOGOUT:
      return initialState;

    default:
      return state;
  }
};
export default authReducer;
