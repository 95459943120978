import { FC } from 'react';
import ExternalRequestThirdSection from '../ExternalRequestThirdSection/ExternalRequestThirdSection';
import ExternalRequestSecondSection from '../ExternalRequestSecondSection/ExternalRequestSecondSection';
import {
  IInitialValuesFirstSectionExternalRequestState,
  IInitialValuesSecondSectionExternalRequestState,
  ISecondSectionExternalRequestState,
  IInitialValuesThirdExternalRequestState,
  IThirdSectionExternalRequestState,
} from '../types/IStateExternalRequestForm';
import ExternalRequestFormOneStep from '../ExternalRequestFirstSection/ExternalRequestFirstSection';

import classNames from 'clsx';
import { IHandlerExternalRequestFormProps } from '../types/IHandlerExternalRequestFormProps';
import { IExternalRequestStatusOld } from '../../../../services/api/external-request/external-request/IExternalRequestStatusOld';
import getAvailableExternalRequestFormSections from '../formUtils/getAvailableExternalRequestFormSections';
import { getExternalRequestInitialValuesFromStore } from '../../../../store/externalRequest/selectors';
import { useSelector } from 'react-redux';

interface IExternalRequestFormState {
  isDisable: boolean;
  isNew: boolean;
  isDuplicate?: boolean;
  currentStatus?: IExternalRequestStatusOld;
}

type ExternalRequestSectionsWrapperPropsType = {
  className?: string;
  customHandlers: IHandlerExternalRequestFormProps;
  formState: IExternalRequestFormState;
};

export const ExternalRequestSectionsWrapper: FC<
  ExternalRequestSectionsWrapperPropsType
> = (props) => {
  const {
    formState: { isDisable, isNew, isDuplicate, currentStatus },
    customHandlers,
  } = props;

  const { accessSecondSection, accessThirdSection } =
    getAvailableExternalRequestFormSections(currentStatus?.id);

  const initialExternalRequestValue = useSelector(
    getExternalRequestInitialValuesFromStore
  );

  const {
    external_request_source_of_information: initialSourceOfInformation,
    external_request_reporter_full_name: initialReporterFullName,
    external_request_reporter_phone_number: initialReporterPhoneNumber,
    external_request_description: initialDescription,
    responsibleDepartment: initialResponsibleDepartment,
    external_request_external_code_number: initialCodeNumber,
    external_request_address_of_incident: initialAddressOfIncident,
    billingAccount: initialBillingAccount,
    supervisor: initialSupervisor,
    workCategory: initialWorkCategory,
    implementers: initialImplementers,
    executiveImplementer: initialExecutiveImplementer,
    affiliate: initialAffiliate,
    external_request_payment_kind: initialPaymentKind,
    external_request_payment_amount: initialPaymentAmount,
    external_request_payment_status: initialPaymentStatus,
    external_request_date_supposed_duedate: initialSupposedDuedate,
    event: initialEvent,
  } = initialExternalRequestValue;

  const initialValuesFirstSection: IInitialValuesFirstSectionExternalRequestState =
    {
      initialCodeNumber,
      initialSourceOfInformation,
      initialReporterFullName,
      initialReporterPhoneNumber,
      initialDescription,
      initialResponsibleDepartment,
    };
  const formStateFirstSection = {
    isDisable,
    isNew,
    isDuplicate,
    initialValues: initialValuesFirstSection,
  };
  const initialValuesSecondSection: IInitialValuesSecondSectionExternalRequestState =
    {
      initialAddressOfIncident,
      initialBillingAccount,
      initialSupervisor,
      initialResponsibleDepartment,
      initialEvent,
    };
  const formStateSecondSection: ISecondSectionExternalRequestState = {
    isDisable,
    initialValues: initialValuesSecondSection,
  };
  const initialValuesThirdSection: IInitialValuesThirdExternalRequestState = {
    initialWorkCategory,
    initialImplementers,
    initialExecutiveImplementer,
    initialAffiliate,
    initialPaymentKind,
    initialPaymentAmount,
    initialPaymentStatus,
    initialSupposedDuedate,
  };
  const formStateThirdSection: IThirdSectionExternalRequestState = {
    isDisable,
    initialValues: initialValuesThirdSection,
  };

  return (
    <div className={classNames('w-100')}>
      <ExternalRequestFormOneStep
        handlerFormProps={customHandlers}
        state={formStateFirstSection}
      />
      {accessSecondSection && (
        <ExternalRequestSecondSection
          handlerFormProps={customHandlers}
          state={formStateSecondSection}
        />
      )}
      {accessThirdSection && (
        <ExternalRequestThirdSection
          handlerFormProps={customHandlers}
          state={formStateThirdSection}
        />
      )}
    </div>
  );
};
export default ExternalRequestSectionsWrapper;
