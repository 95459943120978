import { RefObject, useCallback, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IExternalRequest } from 'services/api/external-request/external-request/IExternalRequest';
import { usePostV3 } from '../../../../../hooks/usePostV3';
import { readExternalRequestV2 } from 'services/api/external-request/external-request/externalRequestApi';
import IWrapperExternalRequest from 'services/api/external-request/external-request/IWrapperExternalRequest';

interface IReturnUseHandlePrintDetails {
  printData: {
    externalRequestDetail: IExternalRequest | null;
  };
  handlePrintDetails: (pk: number) => Promise<void>;
}



const useHandlePrintDetailExternalRequest = (
  ref: RefObject<HTMLDivElement>
): IReturnUseHandlePrintDetails => {

  const [printData, setPrintData] = useState<{
    externalRequestDetail: IExternalRequest | null;
  }>({ externalRequestDetail: null });

  const toPrintDetails = useReactToPrint({
    content: () => ref.current,
    documentTitle: `Заявка`,
    onAfterPrint: () => setPrintData({ externalRequestDetail: null }),
  });

  const getExternalRequestDetails = usePostV3({
    fetchApi: readExternalRequestV2,
    handlePayload: (payload: IWrapperExternalRequest) =>
      setPrintData({ externalRequestDetail: payload.external_request }),
    viewToast: false,
  });

  const handlePrintDetails = useCallback(
    async (pk: number) => {
      await getExternalRequestDetails({ pk });
      toPrintDetails && toPrintDetails();
    },
    [getExternalRequestDetails, toPrintDetails]
  );

  return { handlePrintDetails, printData };
};

export default useHandlePrintDetailExternalRequest;
