import useGetCommonTabs from 'hooks/useGetCommonTabs/useGetCommonTabs';
import { parentUIElement } from 'services/constants/parentUIElement';



const useGetcontrollerTabs = (props: { id: string }) => {
  const { id } = props;

  const {
    setRefreshDocuments: refreshDocumentTabs,
    isLoadingDocument,
    commonTabs: [editTabs, historyTabs, documentsTab],
  } = useGetCommonTabs({ parentUIElement: parentUIElement.controller, id });

  const tabResult = [editTabs, historyTabs, documentsTab];

  return {
    tabs: tabResult,
    refreshDocumentTabs,
    isLoadingTab: isLoadingDocument,
  };
};

export default useGetcontrollerTabs;
