import { useDispatch } from 'react-redux';
import { memo } from 'react';
import ControlPanelForMyFilters, {
  TMyFilter,
} from './ui/ControlPanelForMyFilters/ControlPanelForMyFilters';
import useLocalStorage from 'hooks/useLocalStorage';
import useCreateUniqueIdKey from 'hooks/useCreateUniqueIdKey';

type PropsType<Filters> = {
  setFilters: Function;
  currentFilter: Filters;
  saveFilters: Function;
  keyMyFilter: string;
};

const MyFilters = <Filters,>(props: PropsType<Filters>) => {
  const { setFilters, currentFilter, saveFilters, keyMyFilter } = props;
  const additionalStorageKey = `myFilters-${keyMyFilter}`;
  const id = useCreateUniqueIdKey(additionalStorageKey);
  const [listMyFilters, setStoredValue] = useLocalStorage<TMyFilter<Filters>[]>(
    {
      key: id,
      initialValue: [],
    }
  );

  const dispatchRedux = useDispatch();

  const saveFiltersInStore = (filters: Filters) =>
    dispatchRedux(saveFilters(filters));

  return (
    <ControlPanelForMyFilters
      listMyFilters={listMyFilters}
      setActiveFilter={setFilters}
      currentFilter={currentFilter}
      saveMyFilter={setStoredValue}
      saveFiltersInStore={saveFiltersInStore}
    />
  );
};

export default memo(MyFilters);
