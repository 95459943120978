import classNames from 'clsx';
import React, { FC, memo, useEffect, useState } from 'react';
import styles from '../index.module.scss';
import { IMenuItemPager } from "../../../../services/interfaces/IMenuItemPager";

type ContentMenuTabsProps = {
  items: IMenuItemPager[];
  setActiveContent: Function;
  customClassName?: string;
  defaultActiveContent?: string;
};

const PagerSwitch: FC<ContentMenuTabsProps> = (props) => {
  const { items, setActiveContent, customClassName, defaultActiveContent } =
    props;

  const [active, setActive] = useState<boolean[]>([]);

  useEffect(() => {
    const activeIndex = defaultActiveContent
      ? items.findIndex(
          (tab: IMenuItemPager) => tab.name === defaultActiveContent
        )
      : 0;
    const arrayActive = Array.from(
      { length: items.length },
      (x, i) => i === activeIndex
    );
    setActive(arrayActive);
  }, [defaultActiveContent, items]);

  const clickSetActive = (idClick: number, activeName: string) => {
    const newActive = active.map((_, i: number) => i === idClick);
    setActive([...newActive]);
    setActiveContent(activeName);
  };

  return (
    <ul className={classNames(styles.menu, customClassName, 'px-3')}>
      {items.map((item: IMenuItemPager, id) => (
        <li key={item.name + id} className="px-2">
          <div className={classNames(styles.container_content)}>
            <button
              style={{ border: 'none', outline: 'none' }}
              onClick={(e: any) => {
                e.preventDefault();
                clickSetActive(id, item.name);
              }}
              className={classNames(
                styles.menuLink,
                { [styles.active]: active[id] },
                'py-3 px-2'
              )}
            >
              {item.name}
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};
export default memo(PagerSwitch);
