import classNames from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { PATH_ORGANIZATIONS_COUNTERPARTY_EDIT } from 'services/pathConstants';
import { t } from 'services/utils/translation';
import useGetCounterpartyDetails from '../../hooks/useGetCounterpartyDetails';
import { makeLink } from 'services/localPaths';
import styles from './index.module.scss';
import { textFromIsNaturalPerson } from 'services/utils/extractGetLeftValueIfTrue';

type EventDetailsFormPropsType = {
  counterpartyPk?: number;
  className?: string;
};

const CounterpartyDetailsSelect: FC<
  PropsWithChildren<EventDetailsFormPropsType>
> = (props) => {
  const { counterpartyPk, children, className } = props;

  const { counterpartyDetails, isLoadingCounterparty, location } =
    useGetCounterpartyDetails(counterpartyPk);

  const counterpartyName = counterpartyDetails?.name;

  return (
    <fieldset className={classNames(styles.fieldsContainer, className)}>
      <h4>
        {t('Контрагент')}{' '}
        {counterpartyName && !isLoadingCounterparty && counterpartyPk && (
          <Link
            to={{
              pathname: makeLink(
                PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
                counterpartyPk
              ),
            }}
            state={{ from: location }}
          >
            {counterpartyName}
          </Link>
        )}
      </h4>
      {children}
      {!!counterpartyPk && (
        <ListGroup horizontal className="w-100">
          <ListGroupItem className="w-50 r-1">
            <ListGroupItemHeading
              className="font-weight-bold "
              style={{ fontSize: '14px' }}
            >
              {t('Основное')}
            </ListGroupItemHeading>

            <ListGroupItemText>
              {!isLoadingCounterparty ? (
                <>
                  {t('ИНН')}: {counterpartyDetails?.inn}
                  <p>
                    {t('Статус')}:{' '}
                    {textFromIsNaturalPerson(
                      !!counterpartyDetails?.isNaturalPerson
                    )}
                  </p>
                  {counterpartyDetails?.isAffiliate && (
                    <span>{t('Партнер')}</span>
                  )}
                </>
              ) : (
                <LoaderFetch />
              )}
            </ListGroupItemText>
          </ListGroupItem>
          <ListGroupItem className="w-50">
            <ListGroupItemHeading
              className="font-weight-bold w-50"
              style={{ fontSize: '14px' }}
            >
              {t('Контакты')}
            </ListGroupItemHeading>
            {counterpartyDetails?.address && (
              <ListGroupItemText>
                {!isLoadingCounterparty ? (
                  <>
                    <span>
                      {t('Адрес: ')}
                      {counterpartyDetails.address}
                    </span>
                    <p>
                      {t('Телефон')}:{' '}
                      <a href={`tel:${counterpartyDetails.phoneNumber + ''}`}>
                        {counterpartyDetails.phoneNumber}
                      </a>
                    </p>
                  </>
                ) : (
                  <LoaderFetch />
                )}
              </ListGroupItemText>
            )}
          </ListGroupItem>
        </ListGroup>
      )}
    </fieldset>
  );
};

export default CounterpartyDetailsSelect;
