import { useCallback, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setError } from 'store/general/actions';

const useSetGlobalError = () => {
  const dispatch = useDispatch();

  const handleError = useCallback(
    (error: ErrorEvent) => {
      dispatch(setError(error.error));
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, [handleError]);
};

export default useSetGlobalError;
