import {
  IEventFilters,
  IGetPaginatedEventParams,
} from 'services/api/organizationsV2/event/IGetPaginatedParamsEvent';
import { useCallback } from 'react';
import { composeParamsGetEvent } from '../utils/composeParamsGetEvent/composeParamsGetEvent';

export const useComposeParamsGetEvent = () => {
  return useCallback((params: IEventFilters): IGetPaginatedEventParams => {
    return composeParamsGetEvent(params);
  }, []);
};
