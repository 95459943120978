import useGetTotalTask from 'hooks/useGetTotalTask/useGetTotalTask';
import usePrepareLink from 'hooks/usePrepareLinks';
import { TAB_QUERY_PARAMS } from 'services/pathConstants';
import { TAB_QUERY_KEY } from '../../services/pathConstants';
import { t } from 'services/utils/translation';
import { parentUIElement } from 'services/constants/parentUIElement';

export type TParentUiForTaskTab =
  | parentUIElement.account
  | parentUIElement.counterparty;

interface IUseGetTaskTab {
  pk: number;
  parentUI: TParentUiForTaskTab;
}

export const useGetTaskTab = ({ pk, parentUI }: IUseGetTaskTab) => {
  const { totalTask, isLoadingTask } = useGetTotalTask({
    pk,
    parentUI,
  });

  const tabKey = TAB_QUERY_KEY[parentUI];

  const taskPatch = usePrepareLink({
    query: {
      [tabKey]: TAB_QUERY_PARAMS.tasks,
    },
  });

  const taskTab = {
    name: t('Задачи'),
    recordsTotal: totalTask,
    patch: taskPatch,
  };

  return { taskTab, isLoadingTask };
};
