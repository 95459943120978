import * as Yup from 'yup';
import { t } from '../utils/translation';

const checkValidINNNaturalPerson = (value: string) => {
  const checkSumOne =
    (value
      .split('')
      .slice(0, -2)
      .reduce(
        (sum, symbol, index) =>
          [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + sum,
        0
      ) %
      11) %
    10;

  const checkSumTwo =
    (value
      .split('')
      .slice(0, -1)
      .reduce(
        (sum, symbol, index) =>
          [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + sum,
        0
      ) %
      11) %
    10;

  return checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11]);
};

const checkValidLegalINN = (value: string) => {
  return (
    Number(value[9]) ===
    (value
      .split('')
      .slice(0, -1)
      .reduce(
        (sum, symbol, index) =>
          [2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + sum,
        0
      ) %
      11) %
      10
  );
};

export function innValid(
  this: Yup.StringSchema,
  keysNaturalPerson: string,
  msg?: string
) {
  return this.when(
    keysNaturalPerson,
    (isNaturalPerson: boolean, schema: any) => {
      if (isNaturalPerson) {
        return schema.test({
          name: 'inn-natural-valid',
          exclusive: false,
          message: msg || t('Такой формат ИНН не используется в РФ для физ. лица.'),
          test: function (value?: string) {
            if (!value) {
              return true;
            }
            return checkValidINNNaturalPerson(value);
          },
        });
      } else {
        return schema.test({
          name: 'inn-legal-valid',
          exclusive: false,
          message: msg || t('Такой формат ИНН не используется в РФ для юр. лица.'),
          test: function (value?: string) {
            if (!value) {
              return true;
            }
            return !!value && checkValidLegalINN(value);
          },
        });
      }
    }
  );
}
