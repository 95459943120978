import classNames from 'clsx';
import { FC, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import { t } from '../../../services/utils/translation';
import { Link } from 'react-router-dom';
import { makeLink } from '../../../services/localPaths';
import {
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
  PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
} from '../../../services/pathConstants';
import useGetBillingAccountDetail from '../../tasks/TaskForm/hooks/useGetBillingAccountDetail';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import AlertWrapper from 'components/misc/AlertWrapper/AlertWrapper';

type BillingAccountDetailsInForPropsType = {
  billingAccountPk?: number;
  className?: string;
};

const BillingAccountDetailsSelect: FC<
  PropsWithChildren<BillingAccountDetailsInForPropsType>
> = (props) => {
  const { billingAccountPk, children, className } = props;

  const { billingAccountClientDetail, isLoadingBillingAccount, location } =
    useGetBillingAccountDetail(billingAccountPk);

  const billingAccount = billingAccountClientDetail?.billingAccount;
  const counterparty = billingAccountClientDetail?.counterparty;

  return (
    <fieldset className={classNames(styles.fieldsContainer, className)}>
      <h4>
        {t('Лицевой счет ')}
        {billingAccount && !isLoadingBillingAccount && billingAccountPk && (
          <Link
            to={{
              pathname: makeLink(
                PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
                billingAccount.pk
              ),
            }}
            state={{ from: location }}
          >
            {billingAccount.codeNumber}
          </Link>
        )}
      </h4>
      {children}
      {!!billingAccountPk && (
        <ListGroup horizontal className="w-100">
          <ListGroupItem className="w-50 r-1">
            <ListGroupItemHeading
              className="font-weight-bold "
              style={{ fontSize: '14px' }}
            >
              {t('Адрес')}
            </ListGroupItemHeading>

            <ListGroupItemText>
              {!isLoadingBillingAccount ? (
                <>
                  <p>{billingAccount?.address}</p>
                </>
              ) : (
                <LoaderFetch />
              )}
            </ListGroupItemText>
          </ListGroupItem>
          <ListGroupItem className="w-50">
            <ListGroupItemHeading
              className="font-weight-bold w-50"
              style={{ fontSize: '14px' }}
            >
              {t('Контрагент')}
            </ListGroupItemHeading>
            {counterparty && (
              <ListGroupItemText>
                {!isLoadingBillingAccount ? (
                  <>
                    <span>
                      {counterparty.isCompany ? t('Название') : t('ФИО')}:{' '}
                      <Link
                        to={{
                          pathname: makeLink(
                            PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
                            counterparty.pk
                          ),
                        }}
                        state={{ from: location }}
                      >
                        {counterparty.name}
                      </Link>
                    </span>
                    <p>
                      {t('Телефон')}:{' '}
                      <a href={`tel:${counterparty.phoneNumber}`}>
                        {counterparty.phoneNumber}
                      </a>
                    </p>
                  </>
                ) : (
                  <LoaderFetch />
                )}
              </ListGroupItemText>
            )}
          </ListGroupItem>
        </ListGroup>
      )}
      {billingAccount?.isClosed && (
        <AlertWrapper
          text={t('Лицевой счет закрыт')}
          helpText={t('Отредактируйте лицевой счет или выберите другой')}
        />
      )}
    </fieldset>
  );
};

export default BillingAccountDetailsSelect;
