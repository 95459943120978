import React, { FC, memo } from 'react';

import { parseISO } from 'date-fns';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import HistoryRows from 'components/misc/WrapperHistoryRows/HistoryRows';
import HistoryContainerTable from 'components/tables/HistoryContaierTable/HistoryContainerTable';
import {
  IBillingAccountListOfHistoryEntity,
  ICreationBillingAccountInfo,
} from '../../../../services/api/organizationsV2/billing-account/IHistoryBillingAccount';
import { formatDateTime } from "../../../../services/utils/dateHelper/dateHelper";

type PropTypes = {
  billingAccountRequestHistory?: IBillingAccountListOfHistoryEntity[];
  creationBillingAccountInfo?: ICreationBillingAccountInfo;
  isLoading?: boolean;
};

// delete duplicate - create component container for history
const BillingAccountHistory: FC<PropTypes> = (props) => {
  const {
    billingAccountRequestHistory,
    isLoading,
    creationBillingAccountInfo,
  } = props;
  return (
    <HistoryContainerTable>
      {isLoading && <LoaderFetch />}
      {billingAccountRequestHistory &&
        billingAccountRequestHistory?.length > 0 &&
        !isLoading &&
        billingAccountRequestHistory.map(
          (record: IBillingAccountListOfHistoryEntity) => {
            return (
              !!record?.billing_account_edited_column_to_old_and_new_values && (
                <HistoryRows
                  editorFullName={record?.billing_account_editor?.full_name}
                  valuesRaw={
                    record.billing_account_edited_column_to_old_and_new_values
                  }
                  dateTimeEdit={record?.billing_account_datetime_edited}
                />
              )
            );
          }
        )}
      {creationBillingAccountInfo?.billing_account_datetime_added &&
        creationBillingAccountInfo?.billing_account_author_dk && (
          <tr>
            <td>
              {formatDateTime(
                parseISO(
                  creationBillingAccountInfo?.billing_account_datetime_added
                )
              )}
            </td>
            <td colSpan={4}>{'Cоздание'}</td>
            <td>
              {creationBillingAccountInfo?.billing_account_author?.full_name}
            </td>
          </tr>
        )}
    </HistoryContainerTable>
  );
};

export default memo(BillingAccountHistory);
