import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import {
  targetAccessPolicyBillingAccount,
  targetAccessPolicyEvent,
} from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { targetAccessPolicyEventType } from '../../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { useMemo } from 'react';

const useGetEventAccessPolicy = () => {
  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyEvent,
      targetAction: ['R', 'C', 'D'],
    });

  const [eventTypeHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEventType,
    targetAction: ['R'],
  });

  const [billingAccountHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['R'],
  });

  return useMemo(
    () => ({
      billingAccountHaveAccessToRead,
      eventTypeHaveAccessToRead,
      event: {
        haveAccessToUpdate,
        haveAccessToCreate,
        haveAccessToDelete,
      },
    }),
    [
      billingAccountHaveAccessToRead,
      eventTypeHaveAccessToRead,
      haveAccessToUpdate,
      haveAccessToCreate,
      haveAccessToDelete,
    ]
  );
};

export default useGetEventAccessPolicy;
