import useGetControllerAccessPolicy from './useGetContollerAccessPolicy';

const useSetAndGetControllerAccessPolicy = () => {
  // const controllerAccesPolicyStore = useSelector(
  //   getControllerAccessPolicyFromStore
  // );

  // const isInstalled = useSelector(getControllerIsInstallAccessPolicyFromStore);
  const controllerAccessPolicy = useGetControllerAccessPolicy(false);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (
  //     !isInstalled &&
  //     controllerAccessPolicy &&
  //     controllerAccessPolicy.controller.haveAccessRead !== undefined
  //   ) {
  //     dispatch(setControllerAccessPolicy(controllerAccessPolicy));
  //   }
  // }, [
  //   dispatch,
  //   controllerAccesPolicyStore,
  //   controllerAccessPolicy,
  //   isInstalled,
  // ]);

  return controllerAccessPolicy;
};

export default useSetAndGetControllerAccessPolicy;
