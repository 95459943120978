import styles from './index.module.scss';
import classNames from 'clsx';
import { FC, memo } from 'react';

type FormHeaderPropsType = {
  title: string;
  className?: string
};

const FormHeaderTitle: FC<FormHeaderPropsType> = ({ title, className }) => {
  return <div className={classNames(styles.title, className)}>{title}</div>;
};

export default memo(FormHeaderTitle);
