import { useDispatch, useSelector } from 'react-redux';
import { getAvailableStatusesV2Old } from 'services/api/external-request/external-request-status/externalRequestStatusApiV2';
import { getExternalRequestFirstStatusFromStore } from 'store/externalRequest/selectors';
import { convertStatuses } from '../ExternalRequestEditPage/ExternalRequestEditPage';
import useGetRefreshingDataOld from '../../../../hooks/useGetDataOld';
import { useEffect } from 'react';
import { setExternalRequestFirstStatus } from '../../../../store/externalRequest/actions';

const useFirstExternalRequestStatus = () => {
  const dispatch = useDispatch();

  const { data: initialStatuses } = useGetRefreshingDataOld({
    getDataApi: getAvailableStatusesV2Old,
    convertData: convertStatuses,
    initialData: [],
  });

  const firstStatusFromStore = useSelector(
    getExternalRequestFirstStatusFromStore
  );
  const needSetFirstStatus =
    initialStatuses?.length > 0 && !firstStatusFromStore;

  useEffect(() => {
    if (needSetFirstStatus) {
      dispatch(setExternalRequestFirstStatus(initialStatuses[0]));
    }
  }, [dispatch, initialStatuses, needSetFirstStatus]);

  return firstStatusFromStore;
};

export default useFirstExternalRequestStatus;
