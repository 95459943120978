import IClientSelectOptionV2 from '../../api/interfacesApi/IClientSelectOptionV2';
import { IKeyValue } from '../../interfaces/IKeyValue';

/**
 *
 *
 * @export
 * @template Params
 * @template T
 * @param {Params} stateParams
 * @param {string[]} fieldNames
 * @return {*}  {IKeyValue<T[]>}
 */
export function composeClientSelectOptions<
  Params extends { [x: string]: any },
  Result = IKeyValue<number[]>
>(stateParams: Params, fieldNames: string[]): Result {
  return fieldNames.reduce(
    (acc: Result, prop: string) => ({
      ...acc,
      [prop]:
        stateParams?.[prop] && Array.isArray(stateParams[prop])
          ? stateParams?.[prop]?.map(
              (option: IClientSelectOptionV2) => option.value
            )
          : stateParams[prop]?.value
          ? stateParams[prop]?.value
          : null,
    }),
    {} as Result
  );
}

export function composeClientSelectOptionsInNumbersArray<
  Params extends { [x: string]: any },
  Result = IKeyValue<number[]>
>(stateParams: Params, fieldNames: string[]): Result {
  return composeClientSelectOptions<Params, Result>(stateParams, fieldNames);
}

/**
 *
 *
 * @export
 * @template T
 * @param {IClientSelectOptionV2<T>} ClientSelectOption
 * @return {*}  {T}
 */
export default function composeClientSelectOptionInNumber<T>(
  ClientSelectOption: IClientSelectOptionV2<T>
): T {
  return ClientSelectOption.value;
}
