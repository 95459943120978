import { useMemo } from 'react';
import { useGetList } from 'hooks/useGetList';
import { parentUIElement } from 'services/constants/parentUIElement';
import { getPaginatedBillingAccountsV2 } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import { initialBillingAccountsFilters } from '../../store/initialStore/initialBillingAccountsFilters';
import { TParentUIForCounterpartyTab } from 'hooks/useGetBillingAccountTab/useGetBillingAccountTab';

const KEY_PARAMS_FOR_GET_TOTAL_BILLING_ACCOUNT = {
  [parentUIElement.counterparty]: 'billing_account_counterparty_fk',
} as const;

interface IUseGetTotalBillingAccount {
  pk: number;
  parentUI: TParentUIForCounterpartyTab;
}

const useGetTotalBillingAccount = ({
  pk,
  parentUI,
}: IUseGetTotalBillingAccount) => {
  const initialParamsBillingAccount = useMemo(
    () => ({
      ...initialBillingAccountsFilters,
      [KEY_PARAMS_FOR_GET_TOTAL_BILLING_ACCOUNT[parentUI]]: pk,
    }),
    [parentUI, pk]
  );

  const { total: totalBillingAccount, isLoading: isLoadingBillingAccount } =
    useGetList({
      getDataApi: getPaginatedBillingAccountsV2,
      initialParams: initialParamsBillingAccount,
      initialData: 0,
    });

  return { totalBillingAccount, isLoadingBillingAccount };
};

export default useGetTotalBillingAccount;
