import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { trackPromise } from 'react-promise-tracker';
import config from 'config.json';
import { IBaseResponse } from '../services/api/interfacesApi/IBaseResponse';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import createTimestamp from '../services/utils/createTimestamp/createTimestamp';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from '../services/utils/catchAndRegError/catchAndRegError';

type ActualParams<Arg, Params, NewParams> = Arg extends undefined
  ? Params
  : NewParams;

export interface IUseGetList<DataTypes, ParamsTypes> {
  data: DataTypes;
  isLoading: boolean;
  total: number;
  onSearchRequest: (searchString: any, field: string) => void;
  onSortClick: (sortCriterion: string) => void;
  params: ParamsTypes;
  setData: Dispatch<SetStateAction<DataTypes>>;
  setStart: (skip: number) => void;
  setLength: (length: number) => void;
  setParams: Function;
  refreshListData: () => void;
  refreshAndReturnToStartPosition: () => void;
}

interface IUseGetListProps<
  ResponseTypes,
  ParamsTypes,
  DataTypes,
  NewParamsTypes
> {
  initialParams: ParamsTypes;
  errorMessage?: string; // Error message for the user. Default value: 'Не удалось загрузить данные'
  catchErrors?: boolean; // Catch an error from the server if the response came with status 'false' Default value: 'true'
  initialData?: any; // Default value: []
  convertData?: (args: any) => DataTypes; //  A function that will convert the incoming value
  getDataApi: (
    args: IBaseParams<ActualParams<NewParamsTypes, ParamsTypes, NewParamsTypes>>
  ) => Promise<ResponseTypes>;
  dataKey?: string; // the name of the key to retrieve the data. Default value: 'rows'
  isRefresh?: boolean;
  requestStatusLoading?: boolean; //Do we need loading state? Default value: 'true'
  condition?: boolean; // condition for request
  delayRequest?: boolean; // delay request
  isOneRequest?: boolean; //if we want only one request
  handlePayload?: Function;
  saveFilters?: Function; // if we want to save the filters;
  onFinally?: Function; // if we want to do something at the end of the request
  totalKey?: string;
  convertedParameters?: (args: ParamsTypes) => NewParamsTypes; //if we need to convert parameters before sending fetch
}

/**
 *Processing get requests to receive data from api
 *
 * @export
 * @template ResponseTypes
 * @template ParamsTypes
 * @template DataTypes
 * @param {IUseGetListProps<ResponseTypes, ParamsTypes, DataTypes>} props
 * @return {*}  {IUseFetch<DataTypes, ParamsTypes>}
 */
export function useGetList<
  Payload extends { [k: string]: any },
  ParamsTypes,
  DataTypes,
  NewParamsTypes
>(
  props: IUseGetListProps<
    IBaseResponse<Payload>,
    ParamsTypes,
    DataTypes,
    NewParamsTypes
  >
): IUseGetList<DataTypes, ParamsTypes> {
  const {
    errorMessage = 'Не удалось загрузить данные',
    getDataApi,
    initialParams,
    convertData,
    dataKey = 'list_of_entities',
    initialData = [],
    isRefresh = false,
    requestStatusLoading = true,
    condition = true,
    delayRequest = true,
    handlePayload,
    totalKey = 'total_number_of_filtered_entities',
    saveFilters,
    onFinally,
    convertedParameters,
    // isOneRequest = false,
  } = props;

  const searchTimeout = useRef(0);

  const noDelay = () => {
    searchTimeout.current = 0;
  };
  const [data, setData] = useState<DataTypes>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [params, setParams] = useState<ParamsTypes>(initialParams);
  const [refresh, setRefresh] = useState<boolean>(isRefresh);

  const abortController = useRef(new AbortController());

  const cancelRequest = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  function onSearchRequest(searchString: any, field: string) {
    setParams((prevState: ParamsTypes) => ({
      ...prevState,
      skip: 0,
      [field]: searchString,
    }));
    saveFilters && saveFilters({ [field]: searchString, skip: 0 });
  }

  const refreshListData = () => setRefresh(!refresh);

  const onSortClick = useCallback(
    (sortCriterion: string) => {
      noDelay();
      setParams((prevState: ParamsTypes) => ({
        ...prevState,
        skip: 0,
        sort_by: sortCriterion,
      }));
      saveFilters && saveFilters({ sort_by: sortCriterion });
    },
    [saveFilters]
  );

  const setStart = (skip: number) => {
    setParams((prevState: ParamsTypes) => ({ ...prevState, skip }));
  };

  const setLength = (length: number) => {
    setParams((prevState: ParamsTypes) => ({ ...prevState, length }));
  };

  const refreshAndReturnToStartPosition = () => {
    refreshListData();
    setStart(0);
  };

  //TODO: May cause extra rendering. need refactoring
  useEffect(() => {
    setParams(initialParams);
  }, [initialParams]);

  const latestRequest = useRef<number>(0);

  const fetchData = useCallback(async () => {
    const currentRequest = Date.now();
    latestRequest.current = currentRequest; // Обновляем текущий запрос

    const fullErrorMessage = `${errorMessage} .Пожалуйста, свяжитесь с администратором.'`;
    requestStatusLoading && setIsLoading(true);

    try {
      if (condition) {
        const newParams = convertedParameters
          ? convertedParameters(params)
          : params;

        const response: IBaseResponse<Payload> = await trackPromise(
          getDataApi({
            id: createTimestamp(),
            params: newParams as ActualParams<
              NewParamsTypes,
              ParamsTypes,
              NewParamsTypes
            >,
            signal: abortController.current.signal,
          })
        );
        if (currentRequest === latestRequest.current) {
          const {
            result: { outcome, payload, message, verbose },
          } = response;
          if (outcome === 'WARNING' || !payload) {
            catchErrorsIfServerStatusFalse(
              verbose ? verbose : errorMessage,
              message
            );
            console.error(message);
            return;
          }

          setTotal(payload?.[totalKey]);
          convertData
            ? setData(convertData(payload?.[dataKey]))
            : setData(payload?.[dataKey]);
          handlePayload && handlePayload(payload?.[dataKey]);
        }
      }
    } catch (err) {
      catchErrorFromFetch(fullErrorMessage, err);
      return;
    } finally {
      requestStatusLoading && setIsLoading(false);
      onFinally && onFinally();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, errorMessage, getDataApi, params, condition]);

  //const debounceOnSearch = useDebounce(fetchData, 500);

  useEffect(() => {
    if (condition) {
      const timeOutId = setTimeout(() => fetchData(), searchTimeout.current);
      if (searchTimeout.current === 0 && delayRequest) {
        searchTimeout.current = config.searchTypingTimeout;
        return;
      }
      return () => {
        clearTimeout(timeOutId);
        cancelRequest();
      };
    } else {
      return () => null;
    }
  }, [condition, delayRequest, fetchData, refresh]);

  return {
    data,
    isLoading,
    total,
    onSearchRequest,
    onSortClick,
    params,
    setData,
    setStart,
    setLength,
    setParams,
    refreshListData,
    refreshAndReturnToStartPosition,
  };
}
