import ApiActions from './apiActions';
import { ApiAdditionalActions, ACTIONS_SEPARATOR } from './apiActions';
import deleteUnnecessaryParameters from '../utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

interface ApiUrl {
  old?: string;
  childModule: string;
  action: ApiActions | 'old';
  actionList?: string;
  name?: string;
  length?: number;
  page?: number;
  pk?: number | string;
  skip?: number;
}

export class ApiUrlBuilder {
  private readonly _apiUrl: ApiUrl;

  constructor() {
    this._apiUrl = {
      childModule: '',
      action: ApiActions.DEFAULT,
    };
  }

  childModule(childModule: string): ApiUrlBuilder {
    this._apiUrl.childModule = childModule;
    return this;
  }

  action(action: ApiActions | ApiActions.OLD): ApiUrlBuilder {
    this._apiUrl.action = action;
    return this;
  }

  actionList(actionList: ApiActions[], separator: string = '/'): ApiUrlBuilder {
    this._apiUrl.actionList = actionList.join(separator);
    return this;
  }

  additionalAction(additionalActions?: ApiAdditionalActions): ApiUrlBuilder {
    this._apiUrl.action += additionalActions
      ? ACTIONS_SEPARATOR + additionalActions
      : '';
    return this;
  }

  length(length: number): ApiUrlBuilder {
    this._apiUrl.length = length;
    return this;
  }

  page(page: number): ApiUrlBuilder {
    this._apiUrl.page = page;
    return this;
  }

  pk(pk?: number | string): ApiUrlBuilder {
    this._apiUrl.pk = pk;
    return this;
  }

  skip(skip: number): ApiUrlBuilder {
    this._apiUrl.pk = skip;
    return this;
  }

  name(name: string): ApiUrlBuilder {
    this._apiUrl.name = name;
    return this;
  }

  old(): ApiUrlBuilder {
    this._apiUrl.old = 'old';
    return this;
  }

  build(): ApiUrl {
    return this._apiUrl;
  }

  buildString(): string {
    return Object.values(deleteUnnecessaryParameters(this._apiUrl)).join('/');
  }
}
