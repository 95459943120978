import { useMemo } from 'react';
import { getPaginatedBookingApiV2 } from 'services/api/vehicle/booking/bookingApi';
import { useGetList } from './useGetList';

type PropsType = {
  pk: number;
  haveAccessPolicyReadBooking: boolean;
};

const useGetTotalBookingInEntity = ({
  pk,
  haveAccessPolicyReadBooking,
}: PropsType): [number, boolean, () => void] => {
  const initialParamsBooking = useMemo(
    () => ({
      skip: 0,
      length: 1,
      booking_external_request_fk: pk,
    }),
    [pk]
  );

  const { total, isLoading, refreshListData } = useGetList({
    getDataApi: getPaginatedBookingApiV2,
    initialParams: initialParamsBooking,
    condition: haveAccessPolicyReadBooking,
    initialData: 0,
  });

  return [total, isLoading, refreshListData];
};

export default useGetTotalBookingInEntity;
