import { IFileBucket } from 'services/api/documents/file-bucket/IGetResponseFileBuckets';
import IFileFormValues from '../IFileFormValues';
import { IDocumentFormValues } from '../IDocumentFormValues';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

const toFileFormValues = (uploadedFile: File): IFileFormValues => {
  return {
    isActive: true,
    file: uploadedFile,
  };
};

const toDocumentFormValues = (
  fileBucket: IFileBucket,
  uploadedFiles: File[],
  schema?: IClientSelectOptionV2
): IDocumentFormValues => ({
  pk: fileBucket.pk,
  file_bucket_name: fileBucket.file_bucket_name,
  uploadedFiles: uploadedFiles.map(toFileFormValues),
  file_bucket_schema: schema,
  file_bucket_schema_pk: fileBucket.file_bucket_schema_pk,
  file_bucket_description: fileBucket.file_bucket_description,
  list_of_uploaded_files: [],
});

export default toDocumentFormValues;
