import findAndMakeLink from '../findAndMakeLink/findAndMakeLink';

//todo: CREATE COMPONENT

export const checkLineBreak = (text: string) => /\n/.test(text);

const makeLineBreakText = (commentText: string, isMyComment: boolean) => {
  if (checkLineBreak(commentText)) {
    return (
      <>
        {commentText.split(/\n/).map((newLineComment: string, index) => (
          <span key={index}>
            {findAndMakeLink(newLineComment, isMyComment)}
            <br />
          </span>
        ))}
      </>
    );
  }

  return findAndMakeLink(commentText, isMyComment);
};

export default makeLineBreakText;
