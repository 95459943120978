import { isUrlAbsolute } from '../utils/misc/misc';
import IRequestOptions from './interfacesApi/IRequestOptions';
import config from 'config.json';
import { isDevEnv } from 'config';
import IBaseParams, { IRequestId } from './interfacesApi/IBaseParams';
import { t } from 'services/utils/translation';
import { ApiHeaderBuilder, createHeadersForJson } from './ApiHeaderBuilder';
import { UrlMapKeys } from '../interfaces/UrlMapKeys';
import CookieManager from 'models/CoockieManager/CookieManager';
import InstallationCookie from 'models/CoockieManager/InstallationCookie/InstallationCookie';
import AuthTokenCookie from 'models/CoockieManager/AuthTokenCoockie/AuthTokenCookie';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
//TODO: need refactoring
class ApiClientBase {
  protected devDomain: string = config.devDomain;
  protected prodDomain: string = config.prodDomain;
  protected module: string;
  protected installation: string | null = null;
  protected baseUrl: string;
  protected baseProtocol: string = config.baseProtocol;
  protected versionApi: string;
  protected hasAccessToken: boolean = true;

  constructor(
    module: string,
    installation: string | null = null,
    versionApi: string = 'v1',
    hasAccessToken: boolean = true
  ) {
    this.module = module;
    this.versionApi = versionApi;
    this.installation = this.getInstallation(installation);
    this.baseUrl = this.createUrl();
    this.hasAccessToken = hasAccessToken;
  }

  private getInstallation = (installation: string | null) => {
    if (installation == null) {
      const cookieManger = new CookieManager();
      const authTokenCookie = new InstallationCookie(cookieManger);
      const authDomain = authTokenCookie.getInstallation();
      if (authDomain == null) {
        const errorMessage = 'Auth domain not set';
        toast.error(errorMessage);
        console.error(errorMessage);
        Sentry.captureException(errorMessage);
        const authTokenCookie = new AuthTokenCookie(cookieManger);
        authTokenCookie.removeAuthData();
        window.location.href = '/';
        throw new Error(errorMessage);
      }

      return authDomain;
    } else {
      return installation;
    }
  };

  //@todo refactor me // need instruction to create url
  public URL_MAP = (key: UrlMapKeys): string =>
    ({
      prod: `${this.baseProtocol}${this.prodDomain}/${this.installation}/${this.module}/api/${this.versionApi}/`,
      dev: `${this.baseProtocol}${this.installation}.${this.devDomain}/${this.module}/api/${this.versionApi}/`,
      devApiV2: `${this.baseProtocol}${this.devDomain}/${this.installation}/${this.module}/api/${this.versionApi}/`,
    }[key]);

  public createUrl = (): string => {
    let keyForUrlMap: UrlMapKeys = 'devApiV2';
    switch (true) {
      case this.installation === 'centralregistry-dev' ||
        (this.module === 'undercurrent' && this.isDev()):
        keyForUrlMap = 'devApiV2';
        break;
      case this.isDev():
        keyForUrlMap = 'dev';
        break;
      case !this.isDev():
        keyForUrlMap = 'prod';
        break;
      default:
        throw new Error(
          'An unknown condition was triggered to get the url map key'
        );
    }

    return this.URL_MAP(keyForUrlMap);
  };
  public extractPostJsonV2 =
    (keyParams?: string) =>
    async <Params extends object | undefined, ExtraOptions extends object>(
      path: string,
      bodyParams: IBaseParams<Params> | Omit<IBaseParams<Params>, 'params'>,
      extraOptions?: ExtraOptions
    ): Promise<any> => {
      const absolutePath = this.absolutePath(path);

      const headers = createHeadersForJson(this.hasAccessToken);

      let body = '';
      let { id, signal = null } = bodyParams;
      const keyWrapperParams = 'params' as keyof typeof bodyParams;
      const params = bodyParams?.[keyWrapperParams];
      try {
        let collectedBody;
        if (params && keyParams) {
          const collectedParams = { [keyParams]: params };
          collectedBody = {
            params: { ...collectedParams, ...extraOptions },
            id,
          };
        } else {
          collectedBody = { id };
        }
        body = JSON.stringify(collectedBody);
      } catch (e) {
        throw new Error('JSON parameter serialization error.');
      }
      const newParams =
        signal && Object.keys(signal).length > 0
          ? {
              method: 'POST',
              headers,
              credentials: 'include' as RequestCredentials,
              body,
              signal,
            }
          : {
              method: 'POST',
              headers,
              credentials: 'include' as RequestCredentials,
              body,
            };

      const response = await fetch(absolutePath, newParams);

      this.checkResponse(response);

      return response.json();
    };

  public postFilters = this.extractPostJsonV2('filters');
  public postForm = this.extractPostJsonV2('form');
  public postAttr = this.extractPostJsonV2('attr');
  public postWithoutParams = this.extractPostJsonV2();

  postJson = async (path: string, params?: object): Promise<any> => {
    const absolutePath = this.absolutePath(path);

    const headers = createHeadersForJson(this.hasAccessToken);

    let body = '';
    try {
      body = JSON.stringify(params);
    } catch (e) {
      throw new Error('JSON parameter serialization error.');
    }

    const response = await fetch(absolutePath, {
      method: 'POST',
      headers,
      credentials: 'include',
      body,
    });

    this.checkResponse(response);

    return await response.json();
  };

  public get = async (
    path: string,
    params?: object,
    incomingHeaders?: HeadersInit,
    settings: IRequestOptions = {},
    signal?: AbortSignal
  ): Promise<any> => {
    const response = await this.performFetchRequest(
      path,
      params,
      incomingHeaders,
      settings,
      signal
    );
    return response.json();
  };

  public getBlob = async (
    path: string,
    params?: object,
    incomingHeaders?: HeadersInit,
    settings: IRequestOptions = {},
    signal?: AbortSignal
  ): Promise<Blob> => {
    const response = await this.performFetchRequest(
      path,
      params,
      incomingHeaders,
      settings,
      signal
    );
    return response.blob();
  };

  public putJson = async (path: string, params: object): Promise<any> => {
    const absolutePath = this.absolutePath(path);

    const headers = createHeadersForJson(this.hasAccessToken);

    let body = '';
    try {
      body = JSON.stringify(params);
    } catch (e) {
      throw new Error('JSON parameter serialization error.');
    }

    const response = await fetch(absolutePath, {
      method: 'PUT',
      headers,
      credentials: 'include',
      body,
    });

    this.checkResponse(response);

    return await response.json();
  };

  public extractPutJson =
    (keyParams: string) =>
    async <Params extends object>(
      path: string,
      bodyParams: IBaseParams<Params>,
      incomingHeaders?: HeadersInit
    ): Promise<any> => {
      const absolutePath = this.absolutePath(path);

      const headers = createHeadersForJson(
        this.hasAccessToken,
        incomingHeaders
      );

      let body = '';
      const { id, params } = bodyParams;

      try {
        body = JSON.stringify({ params: { [keyParams]: { ...params } }, id });
      } catch (e) {
        throw new Error('JSON parameter serialization error.');
      }

      const response = await fetch(absolutePath, {
        method: 'PUT',
        headers,
        credentials: 'include',
        body,
      });

      this.checkResponse(response);

      return await response.json();
    };

  public httpDelete = async (
    path: string,
    params?: object,
    incomingHeaders?: HeadersInit
  ): Promise<any> => {
    const absolutePath = this.absolutePath(path);

    const headers = new ApiHeaderBuilder(incomingHeaders)
      .token(this.hasAccessToken)
      .contentTypeCustom('application/x-www-form-urlencoded')
      .build();

    const body = params ? this.encodeRequestParams(params) : '';

    const response = await fetch(absolutePath, {
      method: 'DELETE',
      headers,
      credentials: 'include',
      body,
    });
    this.checkResponse(response);

    return await response.json();
  };

  /**
   *DELETE V2
   *
   * @param {string} path
   * @param {IRequestId} params
   * @param incomingHeaders
   * @member ApiClientBase
   */
  public httpDeleteV2 = async (
    path: string,
    params: IRequestId,
    incomingHeaders?: HeadersInit
  ): Promise<any> => {
    const absolutePath = this.absolutePath(path);

    const headers = createHeadersForJson(this.hasAccessToken, incomingHeaders);

    const body = JSON.stringify(params);
    const response = await fetch(absolutePath, {
      method: 'DELETE',
      headers,
      credentials: 'include',
      body,
    });

    this.checkResponse(response);

    return await response.json();
  };

  /**
   *Method for sending files
   *
   * @param {string} path
   * @param {FormData} [params]
   * @param incomingHeaders
   * @memberof ApiClientBase
   */
  public postWithFileUpload = async (
    path: string,
    params?: FormData,
    incomingHeaders?: HeadersInit
  ): Promise<any> => {
    const absolutePath = this.absolutePath(path);

    const headers = new ApiHeaderBuilder(incomingHeaders)
      .token(this.hasAccessToken)
      .build();

    const response = await fetch(absolutePath, {
      method: 'POST',
      headers,
      credentials: 'include',
      body: params,
    });
    this.checkResponse(response);

    return await response.json();
  };

  private getBaseUrl(): string {
    return this.baseUrl;
  }

  /**
   * Check if the path is absolute and absolution it if not.
   *
   * @param {string} path
   * @returns {string}
   */
  public absolutePath(path: string): string {
    return isUrlAbsolute(path) ? path : this.getBaseUrl() + path;
  }

  /**
   * Basic check for request success.
   *
   * @param {Response} response
   * @throws Error
   */
  checkResponse(response: Response) {
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        throw new Error(
          `${
            403 ? t('Доступ ограничен') : t('Некорректный логин или пароль')
          }. status: ${response.status} ${response.statusText}`
        );
      }
      throw new Error(
        `Response failed. status: ${response.status} ${response.statusText}`
      );
    }
    return;
  }

  /**
   * Encode request parameters to comply with the 'application/x-www-form-urlencoded' content type.
   *
   * @param {object} params
   * @param useBracketsForArrays
   * @returns {string}
   */
  private encodeRequestParams(
    params: object,
    useBracketsForArrays: boolean = false
  ): string {
    return (Object.keys(params) as Array<keyof typeof params>)
      .map((key) => {
        if (Array.isArray(params[key])) {
          const array = params[key] as Array<string>;
          return array
            .map(
              (param: any) =>
                encodeURIComponent(
                  `${key}${useBracketsForArrays ? '[]' : ''}`
                ) +
                '=' +
                encodeURIComponent(param)
            )
            .join('&');
        }

        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
  }

  private isDev = () =>
    isDevEnv() || window.location.host.indexOf('dev') !== -1;

  private async performFetchRequest(
    path: string,
    params?: object,
    incomingHeaders?: HeadersInit,
    settings: IRequestOptions = {},
    signal?: AbortSignal
  ): Promise<Response> {
    let absolutePath = this.absolutePath(path);
    const { useBracketsForArrays } = settings;
    if (params != null && Object.keys(params).length > 0) {
      absolutePath +=
        '?' + this.encodeRequestParams(params, useBracketsForArrays);
    }
    const headers = new ApiHeaderBuilder(incomingHeaders)
      .token(this.hasAccessToken)
      .build();

    const response = await fetch(absolutePath, {
      method: 'GET',
      signal,
      headers,
      credentials: 'include',
    });

    this.checkResponse(response);

    return response;
  }
}

export default ApiClientBase;
