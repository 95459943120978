import { t } from 'services/utils/translation';
import * as Yup from 'yup';

const validationPassword = (yupInstance: any): boolean => {
  return (
    yupInstance.from[0].value['plain_password'] ===
    yupInstance.from[0].value['cloned_password']
  );
};
class RegisterIdentity {
  public identity_nickname: string = '';
  public identity_last_name: string = '';
  public identity_first_name: string = '';
  public identity_middle_name: string = '';
  public phone_number_in_jwt: string = '';
  public plain_password: string = '';
  public cloned_password: string = '';
  constructor(jwt: string) {
    this.phone_number_in_jwt = jwt;
  }

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    function checkRepeatedPassword(yupInstance: any) {
      return validationPassword(yupInstance);
    }
    return Yup.object({
      identity_nickname: Yup.string()
        .trim()
        .required(t('Укажите логин.'))
        .min(3, t('Логин не может быть короче 3 символов.')),
      identity_last_name: Yup.string()
        .trim()
        .required(t('Укажите фамилию.'))
        .min(1, t('Фамилия не может быть короче 1 символа.')),
      identity_first_name: Yup.string()
        .trim()
        .required(t('Укажите имя.'))
        .min(1, t('Имя не может быть короче 1 символа.')),
      identity_middle_name: Yup.string()
        .trim()
        .min(1, t('Отчество не может быть короче 1 символа.')),
      plain_password: Yup.string()
        .trim()
        .required(t('Укажите пароль.'))
        .checkPasswordComplexity(),
      cloned_password: Yup.string()
        .trim()
        .required(t('Повторите пароль.'))
        .test(
          'is-correct-password',
          t('Некорректно повторен пароль'),
          function (): boolean {
            return checkRepeatedPassword(this);
          }
        ),
    });
  };
}

export default RegisterIdentity;
