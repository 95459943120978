import React, { FC, ReactNode } from 'react';

type TDSubtitleProps = {
  // subtitleText: string;
  children: ReactNode;
};

const TDSubtitle: FC<TDSubtitleProps> = ({ children }) => {
  return <span className={'text-muted'}>{children}</span>;
};

export default TDSubtitle;
