import useGetTaskAccessPolicy from '../useGetTaskAccessPolicy/useGetTaskAccessPolicy';

const useSetAndGetTaskAccessPolicy = () => {
  //const taskAccesPolicyStore = useSelector(getTaskAccessPolicyFromStore);

  //const isInstalled = useSelector(getTaskIsInstallAccessPolicyFromStore);

  const taskAccessPolicy = useGetTaskAccessPolicy(false);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!isInstalled) {
  //     dispatch(setTaskAccessPolicy(taskAccessPolicy));
  //   }
  // }, [dispatch, taskAccesPolicyStore, taskAccessPolicy, isInstalled]);

  return taskAccessPolicy;
};

export default useSetAndGetTaskAccessPolicy;
