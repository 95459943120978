import { FC, memo } from 'react';
import {
  grantInvitationShipOfInstallationsToIdentityApiV2,
  revokeInvitationShipOfInstallationsFromIdentityApiV2,
} from 'services/api/identity/identity';
import EditingEntitiesFromIdentityForm from '../EditingEntitiesFromIdentityForm/EditingEntitiesFromIdentityForm';
import { InstallationListKeys } from 'services/api/auth/IRegisterIdentity';
import { menuItemsCanInviteInstallations } from "../../../services/constants/MenuItemPager/MenuItemPager";

type PropsType = {
  isOpen: boolean;
  closeHandler: Function;
};

const GrantAndRemoveInvitation: FC<PropsType> = ({ isOpen, closeHandler }) => {
  return (
    <EditingEntitiesFromIdentityForm
      isOpen={isOpen}
      closeHandler={closeHandler}
      addEntity={grantInvitationShipOfInstallationsToIdentityApiV2}
      removeEntity={revokeInvitationShipOfInstallationsFromIdentityApiV2}
      tabs={menuItemsCanInviteInstallations}
      typeInstallations={InstallationListKeys.canInvite}
    />
  );
};

export default memo(GrantAndRemoveInvitation);
