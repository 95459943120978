import { useCallback } from 'react';
import { KeyParent } from 'services/constants/KeyParents';
import { TValueOf } from 'services/utils/TSUtils/TValueOf';
import { RENULL_KEYS } from 'services/utils/renull/renull';

type TDeleteParentParams = {
  [key in TValueOf<
    Pick<typeof RENULL_KEYS, 'EXTERNAL_REQUEST' | 'INTERNAL_TASK'>
  >]: [KeyParent];
} & { pk: number };

//TODO delete any
export interface IPropsTypeUseDeleteParent {
  refresh?: () => void;
  pk?: number;
  parentKeyDelete: KeyParent;
  renullKey: TValueOf<typeof RENULL_KEYS>;
  fetchDeleteParent: (
    params: any,
    setErrors?: Function | undefined,
    resetForm?: Function | undefined
  ) => Promise<void>;
}

const useDeleteParent = (props: IPropsTypeUseDeleteParent) => {
  const { refresh, pk, fetchDeleteParent, parentKeyDelete, renullKey } = props;

  return useCallback(async () => {
    if (pk !== undefined && refresh) {
      const params: TDeleteParentParams = {
        [renullKey]: [parentKeyDelete],
        pk,
      } as TDeleteParentParams;
      await fetchDeleteParent(params);
      refresh();
    }
  }, [fetchDeleteParent, parentKeyDelete, pk, refresh, renullKey]);
};

export default useDeleteParent;
