import React, { ReactNode, memo } from 'react';
import WithTooltip from './WithTooltip';
import { t } from 'services/utils/translation';

const textHint = t('У вас недастоточно разрешений для доступа к этому ресурсу');

interface WithTooltipProps {
  id: string;
  children: ReactNode;
  haveReadPermission: boolean;
}

const WitchTooltipSelect: React.FC<WithTooltipProps> = ({
  id,
  children,
  haveReadPermission,
}) => {
  return (
    <WithTooltip
      id={id}
      isVisible={!haveReadPermission}
      textTootltip={textHint}
    >
      {children}
    </WithTooltip>
  );
};

export default memo(WitchTooltipSelect);
