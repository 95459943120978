import classNames from 'clsx';
import styles from './index.module.scss';
import { FC, PropsWithChildren, memo } from 'react';

type PropsType = {
  className?: string;
};

const CustomTooltip: FC<PropsWithChildren<PropsType>> = (props) => {
  const { className, children } = props;
  return (
    <div
      className={classNames(
        styles.customTooltip,
        'd-flex flex-column mt-2 ml-1',
        className
      )}
    >
      {children}
    </div>
  );
};

export default memo(CustomTooltip);
