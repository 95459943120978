import { FC, PropsWithChildren, ReactElement } from 'react';
import { InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import classNames from 'clsx';
import ToolTipWrapper from '../ToolTipWrapper/ToolTipWrapper';
import { t } from '../../../services/utils/translation';
import { ReactComponent as VisibilityOnIcon } from 'img/icons/icon-arow-in-square.svg';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export interface InputGroupWithButtonAddonPropsType {
  className?: string;
  onClickAddon?: () => void;
  addonText?: string | ReactElement;
  addonType?: 'prepend' | 'append';
  hint?: string;
  children?: ReactElement;
  id?: string;
}

export const InputGroupWithButtonAddon: FC<
  InputGroupWithButtonAddonPropsType
> = (props) => {
  const {
    className,
    children,
    onClickAddon,
    addonText,
    addonType = 'prepend',
    id,
    hint,
  } = props;

  const idForTooltip = `tooltip-${id}-addon`;

  return (
    <InputGroup className={classNames(className, 'flex-nowrap')}>
      {addonText && (
        <>
          <InputGroupAddon
            addonType={addonType}
            onClick={onClickAddon}
            id={idForTooltip}
          >
            <InputGroupText>{addonText}</InputGroupText>
          </InputGroupAddon>
          {hint && (
            <ToolTipWrapper target={idForTooltip} placement="top">
              {hint}
            </ToolTipWrapper>
          )}
        </>
      )}
      {children}
    </InputGroup>
  );
};

interface IInputGroupWithView
  extends Omit<InputGroupWithButtonAddonPropsType, 'addonText' | 'hint'> {
  linkAddon?: string;
}
type PropsType = PropsWithChildren<IInputGroupWithView>;

export const InputGroupWithView = (props: PropsType) => {
  const { className, addonType, linkAddon, children, id } = props;
  const location = useLocation();
  const to = {
    pathname: linkAddon,
    state: { from: location },
  };
  const addOnText = linkAddon && (
    <Link to={to}>
      <VisibilityOnIcon />
    </Link>
  );

  const hint = t('Просмотр');
  return (
    <InputGroupWithButtonAddon
      addonText={addOnText}
      className={className}
      addonType={addonType}
      hint={hint}
      id={id}
    >
      {children}
    </InputGroupWithButtonAddon>
  );
};
