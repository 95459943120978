import { useCallback } from 'react';
import IBaseResponseOld from 'services/api/interfacesApi/IBaseResponseOld';
import {
    catchErrorFromFetch,
    catchErrorsIfServerStatusFalse
} from "../services/utils/catchAndRegError/catchAndRegError";

interface IUseGetFetchProps<Payload, Params extends {}> {
  getDataApi: (params: Params) => Promise<IBaseResponseOld<Payload>>;
  errorMessage?: string;
}

function useGetFetchOld<Payload, Params extends {}>(
  props: IUseGetFetchProps<Payload, Params>
) {
  const { getDataApi, errorMessage = 'Не удалось загрузить данные' } = props;

  return useCallback(
    async (paramsEntity: Params) => {
      const fullErrorMessage = `${errorMessage} .Пожалуйста, свяжитесь с администратором.'`;

      try {
         const response = await getDataApi(paramsEntity);
        if (!response?.result) {
          catchErrorsIfServerStatusFalse(fullErrorMessage, response?.message);
        }

        return response;
      } catch (err) {
        catchErrorFromFetch(fullErrorMessage, err);
        return;
      }
    },
    [errorMessage, getDataApi]
  );
}

export default useGetFetchOld;
