import { IFormValuesController } from 'components/controller/controller/ControllerForm/ControllerForm';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import {
  CONTROLLER_TEXT,
  ENDING_A,
} from 'services/localLocalization/servicesAndModels';
import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class Controller implements IFormValuesController {
  controller_pk?: string;
  controller_description?: string;
  controllerBillingAccount?: IClientSelectOptionV2;
  controllerIsClosed?: boolean;
  controller_new_comment?: string;
  constructor(pk?: string, billingAccountPk?: number) {
    this.controller_pk = pk;
    this.controllerBillingAccount =
      Controller.getBillingAccountDefault(billingAccountPk);
  }

  private static getBillingAccountDefault = (billingAccountPk?: number) => {
    if (billingAccountPk) {
      return { label: '', value: billingAccountPk };
    } else {
      return undefined;
    }
  };

  static isValidControllerId = (controllerPk?: string | null): boolean => {
    if (controllerPk == null) {
      return false;
    }
    const pattern = /^[A-Z0-9]+_[0-9]+$/;
    return pattern.test(controllerPk);
  };

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      controller_pk: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.'))
        .required(`${t('Укажите номер')} ${CONTROLLER_TEXT}${ENDING_A}`)
        .test(
          'is valid controller_pk',
          t(
            'Идентификатор контроллера может содержать только заглавные английские буквы, цифры и подчеркивания'
          ),
          this.isValidControllerId
        ),

      controller_description: Yup.string()
        .trim()
        .min(3, t('Введите 3 или более буквы.')),
      controllerBillingAccount: Yup.object()
        .nullable()
        .required(t('Выберите лицевой счет.')),
      controller_new_comment: Yup.string().trim(),
    });
  };
}

export default Controller;
