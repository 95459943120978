import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import React, { FC, memo, useEffect, useState } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';
import { usePostV3 } from '../../../hooks/usePostV3';
import useRead from 'hooks/useRead';

import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import IWorkCategory from '../../../services/api/external-request/work-category/IWorkCategory';
import { readWorkCategoryApiV2 } from 'services/api/external-request/work-category/workCategoryApi';
import WorkCategory from '../../../models/WorkCategory';
import { ICreateWorkCategory } from '../../../services/api/external-request/work-category/ICreateWorkCategory';
import {
  createWorkCategoryApiV2,
  updateWorkCategoryApiV2,
} from '../../../services/api/external-request/work-category/workCategoryApi';
import IUpdateWorkCategory from 'services/api/external-request/work-category/IUpdateWorkCategory';

interface IFormValuesWorkCategory {
  work_category_rendition?: string;
  pk?: number;
}

type PropsType = {
  successHandler: () => void;
  pk?: number;
};

const toFormValuesWorkCategory = (
  data: IWorkCategory
): IFormValuesWorkCategory => ({
  work_category_rendition: data.work_category_rendition,
  pk: data.work_category_pk,
});

const convertData = (payload: { work_category: IWorkCategory }) =>
  toFormValuesWorkCategory(payload.work_category);

const WorkCategoryForm: FC<PropsType> = (props) => {
  const { successHandler, pk } = props;
  const isNew = !pk;
  const [workCategory, setWorkCategory] = useState<IFormValuesWorkCategory>(
    new WorkCategory(pk)
  );

  const updateWorkCategory = usePostV3({
    fetchApi: updateWorkCategoryApiV2,
    successHandler,
    successMessage: t('Классификация работ обновлена успешно.'),
  });

  const createWorkCategory = usePostV3({
    fetchApi: createWorkCategoryApiV2,
    successHandler,
    successMessage: t('Классификация работ создана успешно.'),
  });

  const { data: formValues, isLoading } = useRead({
    getDataApi: readWorkCategoryApiV2,
    params: { pk },
    convertData,
    condition: !!pk,
    initialData: new WorkCategory(pk),
  });
  useEffect(() => {
    if (!isNew && formValues) {
      setWorkCategory(formValues);
    }
  }, [formValues, isNew]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={workCategory}
      validationSchema={WorkCategory.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        if (!values?.pk) {
          await createWorkCategory(
            values as ICreateWorkCategory,
            setErrors,
            resetForm
          );
        } else {
          await updateWorkCategory(
            values as IUpdateWorkCategory,
            setErrors,
            resetForm
          );
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {!isLoading && (
            <Form onSubmit={handleSubmit} noValidate={true}>
              {!isNew && (
                <HiddenField
                  id="pk"
                  value={values.pk + ''}
                  hasError={errors.pk != null && touched.pk != null}
                />
              )}
              <TextField
                type="text"
                id="work_category_rendition"
                label={t('Название')}
                placeholder={t('Название')}
                hasError={
                  errors.work_category_rendition != null &&
                  touched.work_category_rendition != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={values.work_category_rendition}
                required={true}
              />
              <FormGroup className="d-block text-right mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </Form>
          )}
          {isLoading && <LoaderFetch />}
        </>
      )}
    </Formik>
  );
};

export default memo(WorkCategoryForm);
