const trimStringInObject = <T extends { [x: string]: unknown }>(params: T): T => {
  const newObject: { [x: string]: unknown } = { ...params };
  for (const key in params) {
    if (params.hasOwnProperty(key) && typeof params[key] === 'string') {
      newObject[key] = (params[key] as string).trim();
    }
  }
  return newObject as T;
};

export default trimStringInObject;
