export const BILLING_ACCOUNT_SAVE_FILTERS: string =
  'BILLING_ACCOUNT_SAVE_FILTERS';
export const BILLING_ACCOUNT__CLEAR_FILTERS: string =
  'BILLING_ACCOUNT_CLEAR_FILTERS';

export const SET_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA =
  'SET_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA';
export const CLEAR_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA =
  'CLEAR_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA';
export const SET_IS_ACTIVE_1C_FILTER = 'SET_IS_ACTIVE_1C_FILTER';

export const BILLING_ACCOUNT_SAVE_TASK_FILTERS: string =
  'BILLING_ACCOUNT_SAVE_TASK_FILTERS';

export const BILLING_ACCOUNT_CLEAR_TASK_FILTERS: string =
  'BILLING_ACCOUNT_CLEAR_TASK_FILTERS';

export const BILLING_ACCOUNT_SAVE_EXTERNAL_REQUEST_FILTERS: string =
  'BILLING_ACCOUNT_SAVE_EXTERNAL_REQUEST_FILTERS';

export const BILLING_ACCOUNT_CLEAR_EXTERNAL_REQUEST_FILTERS: string =
  'BILLING_ACCOUNT_CLEAR_EXTERNAL_REQUEST_FILTERS';

export const SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER =
  'SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER';
export const SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER =
  'SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER';
export const SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER =
  'SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER';
