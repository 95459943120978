export enum COLORS_KEYS {
    'danger' = '#C84958',
    'default' = '#FFFFFF',
    'info' = '#F5F6F8',
    'primary' = '#49A6C8',
    'warning' = '#C8BB49',
    'success' = '#49C89A',
    'secondary' = '#B3B4B6',
    'archive' = '#8743AD',
}

export const colorsMap = {
    '#C84958': 'danger',
    '#FFFFFF': 'default',
    '#F5F6F8': 'info',
    '#49A6C8': 'primary',
    '#C8BB49': 'warning',
    '#49C89A': 'success',
    '#B3B4B6': 'secondary',
    '#8743AD': 'archive',
    '#7F8C8D': 'default',
    '': 'default',
};
