import { t } from '../../../services/utils/translation';

export const COLUMN_LABELS_COUNTERPARTY = {
  PK: t('№'),
  DATETIME_ADDED: t('Дата создания'),
  DESCRIPTION: t('Описание'),
  PHONE_NUMBER: t('Телефон'),
  EMAIL: t('Эл. Почта'),
  ACTUAL_ADDRESS: t('Фактический адрес'),
  LEGAL_STATUS: t('Юр. статус'),
  IS_AFFILIATION: t('Партнер'),
  INN: t('ИНН'),
  NAME: t('Наименование'),
} as const;

export const filtersCounterparty = [
  { name: 'pk', label: COLUMN_LABELS_COUNTERPARTY.PK },
  { name: 'dateTimeAdded', label: COLUMN_LABELS_COUNTERPARTY.DATETIME_ADDED },
  { name: 'description', label: COLUMN_LABELS_COUNTERPARTY.DESCRIPTION },
  { name: 'phone_number', label: COLUMN_LABELS_COUNTERPARTY.PHONE_NUMBER },
  { name: 'actual_address', label: COLUMN_LABELS_COUNTERPARTY.ACTUAL_ADDRESS },
  { name: 'email', label: COLUMN_LABELS_COUNTERPARTY.EMAIL },
  {
    name: 'is_natural_person',
    label: COLUMN_LABELS_COUNTERPARTY.LEGAL_STATUS,
  },
  { name: 'is_affiliation', label: COLUMN_LABELS_COUNTERPARTY.IS_AFFILIATION },
  { name: 'inn', label: COLUMN_LABELS_COUNTERPARTY.INN },
  { name: 'complete_name', label: COLUMN_LABELS_COUNTERPARTY.NAME },
] as const;

export const columnVisibilityInitialCounterparty = {
  pk: true,
  dateTimeAdded: true,
  phone_number: true,
  description: true,
  email: true,
  actual_address: true,
  inn: true,
  is_natural_person: true,
  is_affiliation: true,
  complete_name: true,
} as const;
