import { useState, useCallback } from 'react';

const useToggleWitchInput = (
  initialValue: boolean = false
): [boolean, (input?: boolean) => void] => {
  const [state, setState] = useState(initialValue);
  const toggle = useCallback((input?: boolean) => {
    setState((state) => (input ? input : !state));
  }, []);
  return [state, toggle];
};

export default useToggleWitchInput;
