import CommentBlockWrapper from 'components/controls/CommentBlock/CommentBlockWrapper/CommentBlockWrapper';
import { FC } from 'react';
import { getCommentsTaskV2 } from 'services/api/tasks/tasks/tasksApiV2';
import { addTaskCommentApiV2 } from '../../../services/api/tasks/tasks/tasksApiV2';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from '../../../services/api/interfacesApi/IBaseResponse';
import { extractConvertParamsForAddComment } from '../../controls/CommentBlock/CommentBlockWrapper/CommentBlockWrapper';
import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';
import IPrimaryKey from 'services/types/IPk';

type PropsType = {
  pk: number;
  viewOnly: boolean;
  authorDk: number;
};

const TaskComments: FC<PropsType> = ({ pk, viewOnly, authorDk }) => {
  const convertParams = extractConvertParamsForAddComment(
    authorDk,
    'internal_task'
  );

  return (
    <CommentBlockWrapper
      pk={pk}
      getComments={
        getCommentsTaskV2 as (
          params: IBaseParams<IPrimaryKey<number | string>>
        ) => Promise<IBaseResponse<IGetCommentsPayloadV2>>
      }
      addCommentApiV2={
        addTaskCommentApiV2 as (
          params: IBaseParams<unknown>
        ) => Promise<IBaseResponse<unknown>>
      }
      convertParams={convertParams}
      viewOnly={viewOnly}
    />
  );
};

export default TaskComments;
