import {
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
} from '../checkingСharacteInString/checkingСharacteInString';

const getStringCharacteristics = (word?: string, minLength?: number) => {
  return {
    containsUppercase: !!word && hasUpperCase(word),
    containsLowercase: !!word && hasLowerCase(word),
    containsNumber: !!word && hasNumber(word),
    containsMinLength: !!word && !!minLength && word.length >= minLength,
    containsSpecialCharacter: !!word && hasSpecialCharacter(word),
  };
};

export default getStringCharacteristics;
