import { t } from 'services/utils/translation';
import * as Yup from 'yup';

class ChangeMyPassword {
  public plain_old_password: string = '';
  public plain_new_password: string = '';

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      plain_old_password: Yup.string()
        .min(3, t('Введите 3 или более букв.'))
        .required(t('Введите свой текущий пароль.')),
      plain_new_password: Yup.string()
        .checkPasswordComplexity()
        .required(t('Введите новый пароль.')),
    });
  };
}

export default ChangeMyPassword;
