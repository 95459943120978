import classNames from 'clsx';
import { FC, PropsWithChildren, memo } from 'react';
import styles from './index.module.scss';

const THPadding: Record<THType, string> = {
  Unruled: 'p-2',
  Default: 'p-0',
  Select: 'p-0',
  Date: 'p-0',
} as const;

export enum THColor {
  lightGrey = 'lightGrey',
  default = '',
}

export enum THType {
  select = 'Select',
  default = 'Default',
  unruled = 'Unruled',
  date = 'Date',
}

type PropsType = {
  className?: string;
  isVisible?: boolean;
  color?: THColor;
  type?: THType;
  disabled?: boolean;
};
export type THCustomPropsType = PropsWithChildren<PropsType>;

const THCustom: FC<THCustomPropsType> = (props) => {
  const {
    className,
    isVisible = true,
    color = THColor.default,
    type = THType.default,
    children,
    disabled = false,
  } = props;

  const backGroundColor = styles[color];
  const thType = styles[type];
  const padding = THPadding[type];
  return (
    <th
      scope="col"
      className={classNames(padding, className, backGroundColor, thType, {
        'd-none': !isVisible,
        disabled: disabled,
      })}
    >
      {children}
    </th>
  );
};

export default memo(THCustom);
