import { useEffect } from 'react';

const useBeforeOnload = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [callback]);

  return null;
};

export default useBeforeOnload;
