import React, { FC, useCallback, memo, useEffect } from 'react';

import {
  FocusEventHandler,
  GroupTypeBase,
  OptionsType,
  SelectComponentsConfig,
  ValueType,
} from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import styles from './index.module.scss';
import classNames from 'clsx';
import Select, { components } from 'react-select';
import formatOptionLabelHighLighter from '../utils/formatOptionLabelHighLighter';

type PropsType = {
  id: string;
  placeholder?: string;
  onChange: Function; //ChangeEventHandler,

  // options?: GroupedOptionsType<IClientSelectOptionV2>;
  options?: GroupTypeBase<IClientSelectOptionV2>[];
  showDefaultOption?: boolean;
  defaultOptionLabel?: string;
  disabled?: boolean;
  className?: string;
  isMulti: boolean;
  required?: boolean;

  onBlur?: FocusEventHandler;
  value?: ValueType<
    IClientSelectOptionV2 | OptionsType<IClientSelectOptionV2>,
    boolean
  >;
  defaultValue?: any;
  selectStyles?: any;
  groupStyles?: any;
  customComponents?: SelectComponentsConfig<
    IClientSelectOptionV2 | IClientSelectOptionV2[],
    boolean
  >;
};
// Функция для добавления всех детей родителя селект если выбран родитель
const checkAndAddGroupValues = (
  options: GroupTypeBase<IClientSelectOptionV2>[],
  values: IClientSelectOptionV2[]
): IClientSelectOptionV2[] => {
  if (values.length === 0) {
    return values;
  }
  const lastIndex: number = values?.length - 1;
  const lastLabel: string = values[lastIndex].label;

  const findParent = (group: GroupTypeBase<IClientSelectOptionV2>) =>
    group.label.substring(0, group.label.length - 1) === lastLabel;

  const groupValues = lastLabel && options.find(findParent)?.options;
  if (groupValues) {
    return [...values, ...groupValues.slice(1)];
  } else {
    return values;
  }
};

const SelectGroup: FC<PropsType> = (props) => {
  const {
    id,
    onChange,
    className,
    options,
    placeholder,
    isMulti = false,
    required = false,
    selectStyles,
    value,
    defaultValue,
    disabled = false,
    groupStyles,
    customComponents,
  } = props;

  useEffect(() => {}, [value]);
  const filterOption = useCallback(
    ({ label, value }: any, string: string) => {
      const fixString = (string: string) => string.toLocaleLowerCase().trim();
      const stringFilter = fixString(string);
      if (fixString(label).includes(stringFilter)) return true;
      let groupOptions;
      if (options) {
        groupOptions = options.filter((group) =>
          fixString(group.label).includes(stringFilter)
        );
      }

      if (groupOptions) {
        for (const groupOption of groupOptions) {
          const option = groupOption.options.find((opt) => opt.value === value);
          if (option) {
            return true;
          }
        }
      }
      return false;
    },
    [options]
  );
  const Group = (props: any) => {
    const { options } = props;
    const findParent = (el: GroupTypeBase<IClientSelectOptionV2[]>) =>
      el.index === 0;
    const check = options.some(findParent);
    return (
      <div
        style={groupStyles}
        className={classNames({ [styles.group]: check })}
      >
        <components.Group {...props} />
      </div>
    );
  };
  return (
    <Select
      id={id}
      options={options}
      components={{ Group, ...customComponents }}
      placeholder={placeholder}
      onChange={(value: IClientSelectOptionV2[]) => {
        if (options && isMulti) {
          value = checkAndAddGroupValues(options, value);
        }
        onChange(value);
      }}
      className={classNames('f-custom-select', className)}
      isClearable={true}
      isMulti={isMulti}
      styles={selectStyles}
      required={required}
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      filterOption={filterOption}
      showDefaultOption={true}
      formatOptionLabel={formatOptionLabelHighLighter}
    />
  );
};

export default memo(SelectGroup);
