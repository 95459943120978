import IFileFormValues from '../IFileFormValues';

export const extractChangeFileIsActive =
  (isActive: boolean) =>
  (uploadedFiles: IFileFormValues[], indexTargetFile: number) => {
    return uploadedFiles.map((file: IFileFormValues, i: number) => {
      if (i === indexTargetFile) {
        return { file: file.file, isActive };
      } else {
        return file;
      }
    });
  };
