 import { colorsMap } from '../../../services/constants/UI_COLORS';

export type THexToColorsKeys = keyof typeof colorsMap;

export function getColorFromHEX(hexCode?: THexToColorsKeys) {
  return hexCode ? colorsMap[hexCode] : 'default';
}

export const getColorsTrForTicket = (
  isPruned: boolean | null,
  hexCode?: THexToColorsKeys
): string => {
  return isPruned === true ? 'secondary' : getColorFromHEX(hexCode);
};
