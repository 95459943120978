import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import { IEventFilters } from 'services/api/organizationsV2/event/IGetPaginatedParamsEvent';
import { RootState } from 'store/IRootState';

export const getEventFiltersFromStore = (state: RootState): IEventFilters =>
  state.event.tableFilters;

export const getEventExtenralRequesFiltersFromStore = (
  state: RootState
): { [x: number]: IExternalRequestFilters } =>
  state.event.externalRequestTableFilters;
