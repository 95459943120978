import React, { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { t } from '../../../services/utils/translation';
import { logoutApiV2 } from '../../../services/api/auth/authApiV2';
import {
  PATH_LOGIN,
  PATH_NOTIFICATIONS,
} from '../../../services/pathConstants';
import classNames from 'clsx';
import styles from './index.module.scss';
import NotificationUI from '../NotificationUI/NotificationUI';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { ReactComponent as Avatar } from '../../../img/header/avatar.svg';
import ModalWrapper from '../../controls/ModalWrapper/ModalWrapper';
import { IdentityChangeMyPassword } from '../../identity/IdentityChangeMyPassword/IdentityChangeMyPassword';
import AddAndRemoveIdentityToInstallationsForm from 'components/identity/AddAndRemoveIdentityForm/AddAndRemoveIdentityToInstallationsForm';
import OwnerShipInstallationsForm from 'components/identity/OwnerShipInstallationsForm/OwnerShipInstallationsForm';
import AdminIdentityForm from 'components/identity/AdminIdentityForm/AdminIdentityForm';
import MainLayoutHeaderSearchWrapper from '../MainLayoutHeaderSearchWrapper/MainLayoutHeaderSearchWrapper';
import { getIdentityFromStore } from '../../../store/auth/selectors';
import SetPasswordViaAdminForm from 'components/identity/SetPasswordViaAdminForm/SetPasswordViaAdminForm';
import useActionIdentityControlPanel from './hook/useActionIdentityPanel';
import GrantAndRemoveInvitation from 'components/identity/GrantAndRemoveInvitation/GrantAndRemoveInvitation';
import { identityLogout } from 'store/reducers/action';
import CookieManager from 'models/CoockieManager/CookieManager';
import AuthTokenCookie from 'models/CoockieManager/AuthTokenCoockie/AuthTokenCookie';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from '../../../services/utils/catchAndRegError/catchAndRegError';
import useSaveLastPath from 'hooks/useSaveLastPath/useSaveLastPath';
import ScreenshotButton from 'components/misc/ScreenshotButton/ScreenshotButton';

type PropsType = {
  userFullName: string;
};

const RightHeaderElements: FC<PropsType> = (props) => {
  const { userFullName } = props;

  const {
    toggleOpenAddUserToInstallations,
    toggleCloseAddUserToInstallations,
    toggleOpenAbilityAddToInstallations,
    toggleCloseAbilityAddToInstallations,
    toggleOpenEditMyPassword,
    toggleCloseEditMyPassword,
    toggleOpenOwnerShipEdit,
    toggleCloseOwnerShipEdit,
    toggleOpenAdminForm,
    toggleCloseAdminForm,
    toggleOpenSetViaAdminForm,
    toggleCloseSetViaAdminForm,
    stateIdentityActionPanel,
  } = useActionIdentityControlPanel();

  const {
    identity_list_of_installations_can_invite_to:
      listOfInstallationsCanInviteTo,
    identity_list_of_owned_installations: listOfOwnedInstallations,
    identity_is_admin: isAdmin,
  } = useSelector(getIdentityFromStore);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saveLastVisitedPath } = useSaveLastPath();

  //TODO: use usePost

  const logout = async () => {
    const errorMessage = t(
      'При выходе из приложения, что-то пошло не так, пожалуйста, обратитесь к администратору'
    );
    try {
      const response = await logoutApiV2();
      const {
        result: { verbose, outcome, message },
      } = response;
      if (outcome === 'WARNING') {
        catchErrorsIfServerStatusFalse(
          verbose ? verbose : errorMessage,
          message
        );
        return;
      }
    } catch (error) {
      catchErrorFromFetch(errorMessage, error);
      return;
    }

    const cookieManager = new CookieManager();
    const authCookie = new AuthTokenCookie(cookieManager);
    authCookie.removeAuthData();
    saveLastVisitedPath();
    dispatch(identityLogout());
    navigate(PATH_LOGIN);
    return;
  };
  const [hasAddToCompany, hasOpportunityToAddToCompany, hasMakeToAdminOrOwner] =
    [
      listOfInstallationsCanInviteTo?.length > 0 || isAdmin,
      listOfOwnedInstallations?.length > 0 || isAdmin,
      isAdmin,
    ];
  return (
    <div className="d-flex flex-row justify-content-end align-items-center flex-grow-1">
      <ScreenshotButton className="mr-3" />
      <MainLayoutHeaderSearchWrapper />
      <Link to={PATH_NOTIFICATIONS}>
        <div
          className={classNames(
            styles.notification,
            styles['container-notification'],
            'd-flex flex-column justify-content-center pr-4'
          )}
        >
          <NotificationUI />
        </div>
      </Link>
      <UncontrolledDropdown
        className={classNames(
          styles['profile-menu'],
          'd-flex flex-row justify-content-end align-items-center'
        )}
        direction="down"
        inNavbar
      >
        <DropdownToggle
          className={classNames(styles.toggle, 'd-flex flex-row')}
        >
          <span
            className={classNames(
              styles.username,
              'd-none d-sm-flex flex-column justify-content-center'
            )}
          >
            {userFullName}
          </span>
          <span
            className={classNames(
              styles.avatar,
              'd-flex flex-column justify-content-center'
            )}
          >
            <Avatar />
          </span>
        </DropdownToggle>
        <DropdownMenu right>
          <>
            {hasAddToCompany && (
              <DropdownItem onClick={toggleOpenAddUserToInstallations}>
                {t('Добавить в компанию')}
              </DropdownItem>
            )}
            {hasOpportunityToAddToCompany && (
              <DropdownItem onClick={toggleOpenAbilityAddToInstallations}>
                {t('Возможность добавлять в компанию')}
              </DropdownItem>
            )}
            {hasMakeToAdminOrOwner && (
              <>
                <DropdownItem onClick={toggleOpenOwnerShipEdit}>
                  {t('Сделать владельцем')}
                </DropdownItem>
                <DropdownItem onClick={toggleOpenAdminForm}>
                  {t('Сделать админом')}
                </DropdownItem>
                <DropdownItem onClick={toggleOpenSetViaAdminForm}>
                  {t('Установить пароль пользователю')}
                </DropdownItem>
              </>
            )}
          </>
          <DropdownItem onClick={toggleOpenEditMyPassword}>
            {t('Изменить свой пароль')}
          </DropdownItem>
          <DropdownItem onClick={logout}>{t('Выйти')}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <AddAndRemoveIdentityToInstallationsForm
        isOpen={stateIdentityActionPanel.isOpenAddUserToInstallations}
        closeHandler={toggleCloseAddUserToInstallations}
      />
      <GrantAndRemoveInvitation
        isOpen={stateIdentityActionPanel.isOpenAbilityAddToInstallation}
        closeHandler={toggleCloseAbilityAddToInstallations}
      />
      <OwnerShipInstallationsForm
        isOpen={stateIdentityActionPanel.isOpenOwnerShip}
        closeHandler={toggleCloseOwnerShipEdit}
      />
      <AdminIdentityForm
        isOpen={stateIdentityActionPanel.isOpenAdminForm}
        closeHandler={toggleCloseAdminForm}
      />
      <ModalWrapper
        headerLabel={t('Изменить пароль')}
        isOpen={stateIdentityActionPanel.isOpenEditMyPassword}
        closeHandler={toggleCloseEditMyPassword}
      >
        <IdentityChangeMyPassword successHandler={toggleCloseEditMyPassword} />
      </ModalWrapper>
      <ModalWrapper
        headerLabel={t('Установить пароль пользователю')}
        isOpen={stateIdentityActionPanel.isOpenSetPasswordViaAdminForm}
        closeHandler={toggleCloseSetViaAdminForm}
      >
        <SetPasswordViaAdminForm successHandler={toggleCloseSetViaAdminForm} />
      </ModalWrapper>
    </div>
  );
};

export default memo(RightHeaderElements);
