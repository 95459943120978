import { Formik } from 'formik';
import { Form, FormGroup, Button } from 'reactstrap';
import { t } from '../../../../../services/utils/translation';
import TextField from 'components/controls/TextField';
import { memo, useState } from 'react';
import { TypeSetValue } from '../../../../../hooks/useLocalStorage';
import StorageItem from 'models/StorageItem';
import { TMyFilter } from '../ControlPanelForMyFilters/ControlPanelForMyFilters';
import { toast } from 'react-toastify';

type PropsType<CurrentFilter> = {
  currentFilter: CurrentFilter;
  saveMyFilter: TypeSetValue<TMyFilter<any>[]>;

  listEntities: TMyFilter<CurrentFilter>[];
  closeModalHandler: Function;
};

type FormValues = {
  entityName: string;
};

function CreateMyFilter<CurrentEntity>(props: PropsType<CurrentEntity>) {
  const { saveMyFilter, currentFilter, listEntities, closeModalHandler } =
    props;

  const save = saveMyFilter as TypeSetValue<TMyFilter<CurrentEntity>[]>;

  const [item] = useState<StorageItem<CurrentEntity>>(
    new StorageItem(currentFilter)
  );

  const saveValue = (value: FormValues) => {
    save([...listEntities, { [value.entityName]: currentFilter }]);
    toast(t(`Фильтр ${value.entityName} успешно сохранен`));
    closeModalHandler();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={item}
      validationSchema={StorageItem.validationSchema()}
      onSubmit={(values) => {
        saveValue(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} noValidate={true}>
          <TextField
            type="text"
            id="entityName"
            label={t('Название фильтра')}
            placeholder={t('Название фильтра')}
            hasError={errors.entityName != null && touched.entityName != null}
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.entityName}
            required={true}
          />

          <FormGroup className="d-block text-right mt-4">
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {t('Сохранить')}
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
}

export default memo(CreateMyFilter);
