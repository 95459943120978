import { t } from '../../translation';

export const extractBooleanToText =
  (trueTextText: string, falseText: string) => (bool: boolean) =>
    bool ? trueTextText : falseText;

export const isPrunedToText = (() => {
  const IS_ARCHIVE_TEXT = t('В архиве');
  const IS_NOT_ARCHIVE_TEXT = t('Нет в архиве');
  return extractBooleanToText(IS_ARCHIVE_TEXT, IS_NOT_ARCHIVE_TEXT);
})();

 