export enum UNDERCURRENT_CHILD_MODULES {
  ACCESS_POLICY = 'access-policy',
  BOOKING = 'booking',
  BILLING_ACCOUNT = 'billing-account',
  BILLING_ACCOUNT_TYPE = 'billing-account-type',
  COUNTERPARTY = 'counterparty',
  COUNTERPARTY_AFFILIATE = 'counterparty-affiliate',
  CONTROLLER = 'controller',
  DOCUMENTS = 'documents',
  DEFAULT = '',
  EXTERNAL_REQUEST = 'external-request',
  EMPLOYEE = 'employee',
  EVENT = 'event',
  EVENT_TYPE = 'event-type',
  GENERAL = '',
  JOB_TITLE = 'job-title',
  OLD = 'old',
  UPLOADS = 'uploads',
  RESPONSIBLE_DEPARTMENT = 'responsible-department',
  TASK_TYPE = 'internal-task-type',
  TASK_STATUS = 'internal-task-status',
  INTERNAL_TASK = 'internal-task',
  VEHICLE = 'vehicle',
  VEHICLE_TYPE = 'vehicle-type',
  FILE_BUCKET = 'file-bucket',
  FILE_BUCKET_SCHEMA = 'file-bucket-schema',
  WORK_CATEGORY = 'work-category',
}
