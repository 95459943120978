import { IValueForSaveInStore } from 'hooks/useSaveValueStore';
import React, { useCallback } from 'react';
import { IFormValuesExternalRequest } from '../components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import { IFormValuesTask } from '../components/tasks/TaskForm/TaskForm';
const useExtractHandleBlurWrappers = (
  handleBlur: (e: React.FocusEvent<any>) => void,
  saveValue: (value: IValueForSaveInStore) => void
) => {
  return useCallback(
    (value: IFormValuesExternalRequest | IFormValuesTask) => {
      return (e: React.FocusEvent) => {
        saveValue({ value });
        handleBlur(e);
      };
    },
    [handleBlur, saveValue]
  );
};

export default useExtractHandleBlurWrappers;
