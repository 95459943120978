import ApiClientBase from './ApiClientBase';
import { isDev } from '../../config';

class HeadSpringApiClient extends ApiClientBase {
  constructor() {
    const installation = isDev() ? 'centralregistry-dev' : 'centralregistry';

    super('headspring', installation, 'v2', false);
  }
}

export default HeadSpringApiClient;
