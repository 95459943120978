import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { saveBillingAccountTaskFilters } from 'store/billingAccount/actions';
import { saveCounterpartyTaskFilters } from 'store/counterparty/actions';
import { saveTasksFilters } from 'store/task/actions';

const useSaveTaskFilters = ({
  counterpartyPk,
  billingAccountPk,
}: {
  counterpartyPk?: number;
  billingAccountPk?: number;
}) => {
  const dispatch = useDispatch();

  return useCallback(
    (filters: ITaskFilters) => {
      if (!billingAccountPk && !counterpartyPk) {
        return dispatch(saveTasksFilters(filters));
      }
      if (billingAccountPk) {
        return dispatch(
          saveBillingAccountTaskFilters(filters, billingAccountPk)
        );
      }
      if (counterpartyPk) {
        return dispatch(saveCounterpartyTaskFilters(filters, counterpartyPk));
      } else {
        return dispatch(saveTasksFilters(filters));
      }
    },
    [billingAccountPk, counterpartyPk, dispatch]
  );
};

export default useSaveTaskFilters;
