import { RootStateOrAny } from 'react-redux';
export const getStatusLoadingExportLinkFromStore = (
  state: RootStateOrAny
): boolean => state.export.isLoading;

export const getExportLinkFromStore = (state: RootStateOrAny): string | null =>
  state.export.link;

export const getExportToastIdFromStore = (
  state: RootStateOrAny
): string | number | undefined => state.export.toastId;
