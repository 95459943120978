import { toast } from 'react-toastify';
import {
  INotification,
  TParentUI,
} from '../services/api/notification/IresponseGetNotifications';
import {
  getTextNotificationLocation,
  getNotificationLocation,
} from '../services/api/notification/utils';
import { useDispatch, useSelector } from 'react-redux';
import viewToastWithExportLink from './utils/extractViewToastWithExportLink';
import isEqual from 'react-fast-compare';
import { setExportLink } from '../store/export/actions';
import {
  PATH_TASK_EDIT,
  PATH_EXTERNAL_REQUEST_EDIT,
  PATH_ORGANIZATIONS_ACCOUNTS_EDIT,
} from '../services/pathConstants';
import { makeLink } from 'services/localPaths';
import ToastLink from 'components/misc/ToastLink.tsx/ToastLink';
import { getExportToastIdFromStore } from '../store/export/selectors';
import { setNumberNotifications } from 'store/notification/actions';
import { getNumberOfNotificationsFromStore } from 'store/notification/selectors';

const useWsMessageHandlers = () => {
  const dispatch = useDispatch();
  const numberNotifications = useSelector(getNumberOfNotificationsFromStore);
  const toastId = useSelector(getExportToastIdFromStore);

  const toastForNotification = (message: any) => {
    const notification: INotification = JSON.parse(message);
    const text = getTextNotificationLocation(
      notification.notification_parent_ui_element
    );
    toast.info(`${text}. ${notification.notification_text}`);
  };

  const toastForCreateOrUpdate = (message: any) => {
    const notification: INotification = JSON.parse(message);
    const parentUI = notification.notification_parent_ui_element;
    const { locationNameParentUiElement, id } =
      getNotificationLocation(parentUI);

    const PATH_MAP: Record<TParentUI, string> = {
      internal_task: PATH_TASK_EDIT,
      external_request: PATH_EXTERNAL_REQUEST_EDIT,
      billing_account: PATH_ORGANIZATIONS_ACCOUNTS_EDIT,
      export: '',
    };
    const TARGET_PATH = PATH_MAP[locationNameParentUiElement.en];
    const to = makeLink(TARGET_PATH, id);
    const text = `${notification.notification_text} №${id}`;
    const Link = <ToastLink to={to} text={text} />;

    toast.info(Link);
  };

  const handlerClickableLink = (message: string) => {
    dispatch(setExportLink(message));
    toast.dismiss(toastId);
    viewToastWithExportLink(dispatch, message);
  };

  const handlerNumberOfNotificationUnreactedTo = (message: any): void => {
    !isEqual(numberNotifications, message) &&
      dispatch(setNumberNotifications(message));
  };

  return {
    toastForNotification,
    handlerClickableLink,
    handlerNumberOfNotificationUnreactedTo,
    toastForCreateOrUpdate,
  };
};
export default useWsMessageHandlers;
