import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { useMemo } from 'react';
import {
  targetAccessPolicyBillingAccount,
  targetAccessPolicyCounterparty,
} from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { targetAccessPolicyEmployee } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyPersonnel';
import { targetAccessPolicyTaskType } from '../../../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import {
  targetAccessPolicyTask,
  targetAccessPolicyTaskStatus,
} from '../../../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import { ITaskClientAccessPolicy } from 'store/task/initialTask';

const useGetTaskAccessPolicy = (
  isInstalled: boolean
): ITaskClientAccessPolicy => {
  const [
    haveAccessToArchive,
    haveAccessToUpdate,
    haveAccessToCreate,
    haveAccessToExport,
  ] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyTask,
    targetAction: ['A', 'U', 'C', 'E'],
    dontCall: isInstalled,
  });

  const [taskStatusHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyTaskStatus,
    targetAction: ['R'],
    dontCall: isInstalled,
  });

  const [taskTypeHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyTaskType,
    targetAction: ['R'],
    dontCall: isInstalled,
  });
  const [employeeHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEmployee,
    targetAction: ['R'],
    dontCall: isInstalled,
  });

  const [billingAccountHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['R'],
    dontCall: isInstalled,
  });

  const [counterpartyHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyCounterparty,
    targetAction: ['R'],
    dontCall: isInstalled,
  });

  return useMemo(
    () => ({
      employeeHaveAccessToRead,
      taskStatusHaveAccessToRead,
      taskTypeHaveAccessToRead,
      billingAccountHaveAccessToRead,
      counterpartyHaveAccessToRead,
      task: {
        haveAccessToCreate,
        haveAccessToExport,
        haveAccessToArchive,
        haveAccessToUpdate,
      },
    }),
    [
      employeeHaveAccessToRead,
      taskStatusHaveAccessToRead,
      taskTypeHaveAccessToRead,
      billingAccountHaveAccessToRead,
      counterpartyHaveAccessToRead,
      haveAccessToCreate,
      haveAccessToExport,
      haveAccessToArchive,
      haveAccessToUpdate,
    ]
  );
};

export default useGetTaskAccessPolicy;
