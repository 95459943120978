import { UNDERCURRENT_CHILD_MODULES } from 'services/constants/UNDERCURRENT_CHILD_MODULES';
import UndercurrentApiMethods, {
  PARENT_BASE_KEYS,
} from './UndercurrentApiMethods';

class UndercurrentExternalRequestParentUpdateApiMethod extends UndercurrentApiMethods {
  constructor() {
    super(UNDERCURRENT_CHILD_MODULES.EXTERNAL_REQUEST);
  }

  public addParent = this.extractUpdateParent(PARENT_BASE_KEYS.externalRequest);
}

export const createExternalRequestParentUpdateApiMethod = () => {
  const instance = new UndercurrentExternalRequestParentUpdateApiMethod();
  return {
    addParent: instance.addParent,
  };
};

class UndercurrentTaskParentUpdateApiMethod extends UndercurrentApiMethods {
  constructor() {
    super(UNDERCURRENT_CHILD_MODULES.INTERNAL_TASK);
  }

  public addParent = this.extractUpdateParent(PARENT_BASE_KEYS.task);
}

export const createTaskParentUpdateApiMethod = () => {
  const instance = new UndercurrentTaskParentUpdateApiMethod();
  return {
    addParent: instance.addParent,
  };
};
