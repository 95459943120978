import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
 import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import IPrimaryKey from 'services/types/IPk';
import ICreateVehicleType from './ICreateVehicleType';
import IGetPaginatedParamsVehicleType from './IGetPaginatedParamsVehicleType';
import IUpdateVehicleType from './IUpdateVehicleType';
import { IVehicleType, IWrapperVehicleType } from './IVehicleType';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IForSelectRequestV2 } from "../../../utils/generateMethodForSelect/generateMethodForSelect";

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.VEHICLE_TYPE
  );

export const getPaginatedVehicleTypeApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsVehicleType>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IVehicleType>>> =>
  api().getPaginated(filterParams);

export const readVehicleTypeApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<IWrapperVehicleType>> => api().read(params);

export const createVehicleTypeApiV2 = async (
  params: IBaseParams<ICreateVehicleType>
): Promise<IBaseResponse<IWrapperVehicleType>> => await api().create(params);

export const updateVehicleTypeApiV2 = async (
  VehicleType: IBaseParams<IUpdateVehicleType>
): Promise<IBaseResponse<IWrapperVehicleType>> =>
  await api().update(VehicleType);

export const deleteVehicleTypeApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectVehicleTypeParentApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const exportVehicleTypeApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsVehicleType>
): Promise<IBaseResponse<string>> => api().export(filterParams);
