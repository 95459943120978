import { FC, memo } from 'react';
import {
  giveOwnerShipOfInstallationsToIdentityApiV2,
  rescindOwnerShipOfInstallationsFromIdentityApiV2,
} from 'services/api/identity/identity';
import EditingEntitiesFromIdentityForm from '../EditingEntitiesFromIdentityForm/EditingEntitiesFromIdentityForm';
import { InstallationListKeys } from 'services/api/auth/IRegisterIdentity';
import { menuItemsOwnerShipInstallations } from "../../../services/constants/MenuItemPager/MenuItemPager";

type PropsType = {
  isOpen: boolean;
  closeHandler: Function;
};

const OwnerShipInstallationsForm: FC<PropsType> = ({
  isOpen,
  closeHandler,
}) => {
  return (
    <EditingEntitiesFromIdentityForm
      isOpen={isOpen}
      closeHandler={closeHandler}
      addEntity={giveOwnerShipOfInstallationsToIdentityApiV2}
      removeEntity={rescindOwnerShipOfInstallationsFromIdentityApiV2}
      tabs={menuItemsOwnerShipInstallations}
      typeInstallations={InstallationListKeys.owned}
    />
  );
};

export default memo(OwnerShipInstallationsForm);
