import { useFormikContext } from 'formik';
import {
  SAVE_FILELD_VALUES_ACTION,
  useSaveValueStore,
} from 'hooks/useSaveValueStore';
import { useCallback } from 'react';

const useHandlerDateTaskForm = () => {
  const { setFieldValue } = useFormikContext();
  const saveValueInStoreForTask = useSaveValueStore(
    SAVE_FILELD_VALUES_ACTION.task
  );

  return useCallback(
    async (date: string) => {
      await setFieldValue('internal_task_date_duedate', date);
      saveValueInStoreForTask({
        value: {
          internal_task_date_duedate: date,
        },
      });
    },
    [saveValueInStoreForTask, setFieldValue]
  );
};

export default useHandlerDateTaskForm;
