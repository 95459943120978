import { getLocalPathTitle, isPathLocal } from './localPaths';
import { PATH_FRONTPAGE } from './pathConstants';
import { IMenuItemV2, ISubMenuItemV2 } from './interfaces/IMenuItemV2';

export const isPathAllowed = (menu: IMenuItemV2[], path: string) => {
  // Skip path check for the paths which are not present on the server.

  if (isPathLocal(path)) {
    return true;
  }

  for (let i = 0; i < menu.length; i++) {
    let menuPath = menu[i].url;
    if (menuPath === path) {
      return true;
    }

    if (isPathAllowed(menu[i]?.submenu || [], path)) {
      return true;
    }
  }

  return false;
};

const compareWeight = (menuItem1: IMenuItemV2, menuItem2: IMenuItemV2) => {
  return menuItem1.weight - menuItem2.weight;
};

export const sortMenu = (menuItems: IMenuItemV2[]): IMenuItemV2[] => {
  return menuItems
    .map((menuItem: IMenuItemV2) => {
      if (menuItem.submenu == null) {
        return menuItem;
      }

      return {
        ...menuItem,
        submenu: menuItem.submenu.sort(compareWeight),
      };
    })
    .sort(compareWeight);
};

export const isSubMenu = (
  menuItem: IMenuItemV2 | ISubMenuItemV2
): menuItem is ISubMenuItemV2 =>
  (menuItem as IMenuItemV2).submenu === undefined;

export const getPaths = (
  menu: IMenuItemV2[],
  parentPath?: string
): IMenuItemV2[] | null => {
  if (parentPath == null || parentPath === PATH_FRONTPAGE) {
    return menu;
  }

  const parentMenuItem = menu.find((menuItem: IMenuItemV2) => {
    return menuItem.url === parentPath;
  });

  if (parentMenuItem) {
    return parentMenuItem.submenu || null;
  }

  return null;
};

export const parentMenuItem = (
  menu: IMenuItemV2[],
  childPath: string
): IMenuItemV2 | null => {
  if (childPath == null || childPath === PATH_FRONTPAGE) {
    return null;
  }

  const parentMenuItem = menu.find((menuItem: IMenuItemV2) => {
    if (menuItem.submenu == null) {
      return false;
    }

    const foundChildMenuItem = menuItem.submenu.find(
      (menuItem: IMenuItemV2) => {
        return menuItem.url === childPath;
      }
    );

    return !!foundChildMenuItem;
  });

  return parentMenuItem || null;
};

export function getPathTitle(menu: IMenuItemV2[], path: string): string | null {
  if (isPathLocal(path)) {
    return getLocalPathTitle(path);
  }

  const menuItem = findMenuItem(menu, path);
  if (menuItem) {
    return menuItem.title;
  }

  return null;
}

export const findMenuItem = (
  menu: IMenuItemV2[],
  path: string
): IMenuItemV2 | null => {
  if (path == null || path === PATH_FRONTPAGE) {
    return null;
  }

  for (let i = 0; i < menu.length; i++) {
    let menuPath = menu[i].url;

    if (menuPath === path) {
      return menu[i];
    }

    const foundMenuItem = findMenuItem(menu[i].submenu || [], path);
    if (foundMenuItem) {
      return foundMenuItem;
    }
  }

  return null;
};
