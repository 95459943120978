import React from 'react';
import { Col, Row, Table, Container } from 'reactstrap';

import { parseISO } from 'date-fns/esm';
import styles from './index.module.scss';
import {
  ITaskListOfComment,
} from '../../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import TaskHistory from 'components/tasks/TaskHistory';
import { IGetHistoryTaskPayload } from '../../../../services/api/tasks/tasks/IGetHistoryTaskPayload';
import { ITask } from "../../../../services/api/tasks/tasks/ITask";
import { t } from "../../../../services/utils/translation";
import {
    formatDateFromServer,
    formatDateTime,
    parseDateFromServer
} from "../../../../services/utils/dateHelper/dateHelper";

type PropsType = {
  taskDetails: ITask | null;
  taskHistory: IGetHistoryTaskPayload | null;
  className?: string;
};
const TaskPrintDetailsPage = (props: PropsType) => {
  const { taskDetails, taskHistory, className } = props;

  return (
    <Container className={className}>
      {taskDetails ? (
        <>
          <Row>
            <Col xs="7">
              <h5>Задача: №{taskDetails?.internal_task_pk}</h5>
              <Table bordered>
                <tbody>
                  {taskDetails?.internal_task_author && (
                    <tr>
                      <th scope="row" style={{ width: '25%' }}>
                        Автор
                      </th>
                      <td>{taskDetails?.internal_task_author?.full_name}</td>
                    </tr>
                  )}
                  {taskDetails?.internal_task_description && (
                    <tr>
                      <th scope="row">Текст</th>
                      <td>{taskDetails?.internal_task_description}</td>
                    </tr>
                  )}
                  {taskDetails?.internal_task_billing_account && (
                    <tr>
                      <th scope="row">Лицевой счет</th>
                      <td>
                        {
                          taskDetails?.internal_task_billing_account
                            ?.billing_account_code_number
                        }
                      </td>
                    </tr>
                  )}
                  {taskDetails?.internal_task_reporter_phone_number && (
                    <tr>
                      <th scope="row">Контактный телефон</th>
                      <td>
                        {taskDetails?.internal_task_reporter_phone_number}
                      </td>
                    </tr>
                  )}
                  {taskDetails?.internal_task_date_duedate && (
                    <tr>
                      <th scope="row">{t('Запланировано')}</th>
                      <td>
                        {formatDateFromServer(
                          parseDateFromServer(
                            taskDetails?.internal_task_date_duedate
                          )
                        )}
                      </td>
                    </tr>
                  )}

                  {taskDetails?.internal_task_type && (
                    <tr>
                      <th scope="row">Тип задачи</th>
                      <td>
                        {
                          taskDetails?.internal_task_type
                            ?.internal_task_type_rendition
                        }
                      </td>
                    </tr>
                  )}
                  {taskDetails?.internal_task_status && (
                    <tr>
                      <th scope="row">Статус</th>
                      <td>
                        {
                          taskDetails?.internal_task_status
                            .internal_task_status_rendition
                        }
                      </td>
                    </tr>
                  )}

                  {taskDetails?.internal_task_supervisor && (
                    <tr>
                      <th scope="row">Исполнитель</th>
                      <td>
                        {taskDetails?.internal_task_supervisor?.full_name}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            {taskDetails?.internal_task_list_of_comments?.length > 0 && (
              <>
                <Col xs="5">
                  <h5>Комментарии</h5>
                  <Table bordered>
                    <tbody>
                      {taskDetails?.internal_task_list_of_comments.map(
                        (comment: ITaskListOfComment) => (
                          <tr
                            key={comment.internal_task_comment_datetime_added}
                          >
                            <td style={{ width: '50%' }}>
                              {comment.internal_task_comment_text}
                            </td>
                            <td>
                              {comment.internal_task_comment_author.full_name}
                            </td>
                            <td>
                              {formatDateTime(
                                parseISO(
                                  comment.internal_task_comment_datetime_added
                                )
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </>
            )}
          </Row>
          <Row className={styles.pageBreak}>
            <Row>
              <Col style={{ width: '100%' }}>
                <h5>История</h5>
                <TaskHistory
                  taskListOfHistoryEntity={
                    taskHistory?.internal_task_list_of_edits
                  }
                  creationTaskInfo={{
                    internal_task_datetime_added:
                      taskHistory?.internal_task_datetime_added,
                    internal_task_author_dk:
                      taskHistory?.internal_task_author_dk,
                  }}
                />
              </Col>
            </Row>
          </Row>
        </>
      ) : (
        <p>Что-то пошло не так</p>
      )}
    </Container>
  );
};

export default TaskPrintDetailsPage;
