import IClientSelectOptionV2 from '../../../../services/api/interfacesApi/IClientSelectOptionV2';
import { IPersonV2 } from '../../../../services/interfaces/IPersonV2';
import { createClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import { useCallback } from 'react';
import { isDev } from '../../../../config';
import { IFormValuesTask } from '../TaskForm';
import {
  SAVE_FILELD_VALUES_ACTION,
  useSaveValueStore,
} from 'hooks/useSaveValueStore';
import { useFormikContext } from 'formik';
import Task from 'models/Task';

const TASK_STATUS_LABEL_COMPLETE = isDev() ? 'Ожидает проверки' : 'Выполнено';

const statusIsCompleted = (labelStatus: string) =>
  labelStatus === TASK_STATUS_LABEL_COMPLETE;

const getSuperVisor = (
  supervisor: IClientSelectOptionV2,
  author?: IPersonV2
): IClientSelectOptionV2 =>
  author
    ? createClientSelectOptionV2(author?.id, author?.full_name)
    : supervisor;

const useHandlerTaskFormUtils = () => {
  const saveValueInStoreForTask = useSaveValueStore(
    SAVE_FILELD_VALUES_ACTION.task
  );
  const {
    values: { internal_task_author: author, supervisor },
    setFieldValue,
  } = useFormikContext<IFormValuesTask | Task>();

  const handleChangeStatus = useCallback(
    async (status: IClientSelectOptionV2) => {
      if (status) {
        const labelStatus = status.label;
        saveValueInStoreForTask({
          value: { status },
        });

        await setFieldValue('status', status);

        saveValueInStoreForTask({
          value: { status },
        });

        if (supervisor && statusIsCompleted(labelStatus)) {
          const targetSuperVisor = getSuperVisor(supervisor, author);
          saveValueInStoreForTask({
            value: { supervisor: targetSuperVisor },
          });
          await setFieldValue('supervisor', targetSuperVisor);
        }
      }
    },
    [author, saveValueInStoreForTask, setFieldValue, supervisor]
  );

  return { handleChangeStatus };
};

export default useHandlerTaskFormUtils;
