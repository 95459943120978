import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import React, { useRef, useState, useEffect, FC } from 'react';
import { OptionProps, components } from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

const CustomOptionForPopoutDynamicSelected: FC<
  OptionProps<IClientSelectOptionV2, boolean>
> = (props) => {
  const { label, value } = props.data;
  const [showTooltip, setShowTooltip] = useState(false);

  const optionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (optionRef.current) {
      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.whiteSpace = 'nowrap';
      tempDiv.style.maxWidth = '270px';
      tempDiv.textContent = label;
      document.body.appendChild(tempDiv);
      const isOverflowing = tempDiv.scrollWidth > 270;
      setShowTooltip(isOverflowing);
      document.body.removeChild(tempDiv);
    }
  }, [label]);

  return (
    <div ref={optionRef}>
      <components.Option {...props}>
        {showTooltip ? (
          <>
            <span id={`option-${value}`}>{label}</span>
            <ToolTipWrapper target={`option-${value}`} placement="auto">
              {label}
            </ToolTipWrapper>
          </>
        ) : (
          label
        )}
      </components.Option>
    </div>
  );
};

export default CustomOptionForPopoutDynamicSelected;
