import CloseButton from 'components/controls/CloseButton';
import React, { FC, FormEvent, memo } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { t } from 'services/utils/translation';

type PropsType = {
  className?: string;
  headerLabel: string;
  deleteHandler: Function;
  deleteStatusSuspenseMessage?: string;
  isOpen: boolean;
  entityId: number;
  entityName?: string;
  toggleHandler: Function;
  deleteMessageError?: string;
  successDeleteHandler?: () => void;
};
const PopupDeleteForm: FC<PropsType> = (props) => {
  const {
    className,
    deleteHandler,
    headerLabel,
    entityName,
    entityId,
    toggleHandler,
    isOpen,
    deleteStatusSuspenseMessage = `${entityName}. Удаление прошло успешно`,
    deleteMessageError,
    successDeleteHandler,
  } = props;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteHandler(
      entityId,
      deleteStatusSuspenseMessage,
      deleteMessageError,
      successDeleteHandler
    );
  };

  const toggle = () => toggleHandler();

  return (
    <Modal
      isOpen={isOpen}
      fade={false}
      toggle={toggle}
      className={className ? className : ''}
      centered={true}
    >
      <ModalHeader
        toggle={toggle}
        close={<CloseButton onClick={toggle} />}
        className="d-flex flex-row align-items-center"
      >
        {headerLabel}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit} noValidate={true}>
          {entityName && (
            <p>{t('Вы уверены, что хотите удалить') + ` "${entityName}"?`}</p>
          )}

          <FormGroup className="d-block text-right mt-4">
            <Button type="submit" color="danger">
              {t('Удалить')}
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default memo(PopupDeleteForm);
