import { Link } from 'react-router-dom';
import { makeLink } from '../../../../../services/localPaths';
import { FC, memo, useState, ReactElement, useId } from 'react';
import WrapperCustomPrompt, {
  ContainerMessagePrompt,
} from 'components/misc/CustomPrompt/CustomPrompt';
import { PROMPT_MESSAGE } from 'config';
import { useSelector } from 'react-redux';
import { getHasUnsavedValuesFromStore } from 'store/form/selectors';
import { useNavigate } from 'react-router';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';

type PropsTypeFormHeaderLinkWitchBlockNavigation = {
  pk?: number;
  path: string;
  children: string | number | ReactElement;
  className?: string;
  tooltipDescription?: string;
};

const FormHeaderLinkWitchBlockNavigation: FC<
  PropsTypeFormHeaderLinkWitchBlockNavigation
> = (props) => {
  const { pk, path, children, className, tooltipDescription } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);

  const showPrompt = useSelector(getHasUnsavedValuesFromStore);

  const navigate = useNavigate();

  const handleClick = () => {
    if (!showPrompt && pk) {
      navigate(makeLink(path, pk));
      setShowConfirmation(false);
      return;
    } else {
      setShowConfirmation(true);
      return;
    }
  };

  const oncCancel = () => {
    setShowConfirmation(false);
  };

  const confirmAndNavigate = () => {
    setShowConfirmation(false);

    if (pk) {
      navigate(makeLink(path, pk));
    }
  };

  const id = `tooltip-subheader-link-${useId().slice(1).replace(':', '')}`;
  return (
    <>
      {pk && (
        <div>
          <Link
            to="#"
            onClick={handleClick}
            className={className}
            replace
            id={id}
          >
            {children}
          </Link>
          {tooltipDescription && (
            <ToolTipWrapper placement="top" target={id}>
              {tooltipDescription}
            </ToolTipWrapper>
          )}
        </div>
      )}
      {showPrompt && showConfirmation && (
        <WrapperCustomPrompt
          message={PROMPT_MESSAGE}
          handleCancel={oncCancel}
          open={showConfirmation}
        >
          <ContainerMessagePrompt
            ok={confirmAndNavigate}
            cancel={oncCancel}
            message={PROMPT_MESSAGE}
          />
        </WrapperCustomPrompt>
      )}
    </>
  );
};

export default memo(FormHeaderLinkWitchBlockNavigation);
