import { RootStateOrAny } from 'react-redux';
import { IAccessPolicyStatement } from 'services/api/staff/access-policy/IAccessPolicy';
import { ILocalization } from 'services/api/general/ILocalization';
import { IEmployee } from '../../services/api/staff/employee/IEmployee';
import {
  IMenuItemV2,
  ISubMenuItemV2,
} from '../../services/interfaces/IMenuItemV2';

export const getLocalizationFromStore = (
  state: RootStateOrAny
): ILocalization => state.general.localization;

export const getCurrentEmployeeFromStore = (state: RootStateOrAny): IEmployee =>
  state.general.currentEmployee;

export const getCurrentEmployeeAccessPolicyFromStore = (
  state: RootStateOrAny
): IAccessPolicyStatement =>
  state.general.currentEmployee?.employee_access_policy
    ?.access_policy_statement;

export const getCurrentEmployeePkFromStore = (state: RootStateOrAny): number =>
  state.general.currentEmployee.employee_pk;

export const getInstallationPkFromStore = (
  state: RootStateOrAny
): number | undefined => state.auth.activeInstallation.installationPk;

export const getLoggedInFromStore = (state: RootStateOrAny): boolean =>
  state.auth.loggedIn;

export const getMenuFromStore = (state: RootStateOrAny): IMenuItemV2[] =>
  state.general.menu;

export const getErrorsFromStore = (state: RootStateOrAny): Error | null =>
  state.general.errors;

export const getMenuFavoritesFromStore = (
  state: RootStateOrAny
): ISubMenuItemV2[] => state.general.myFavoriteMenuItems;

export const getActiveInstallationName = (state: RootStateOrAny): string =>
  state.auth.activeInstallation.installation;
