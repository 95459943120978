import React, { FC, memo, ChangeEvent, useState } from 'react';
import {
  FormGroup,
  Label,
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardBody,
  Table,
  Collapse,
} from 'reactstrap';
import styles from './index.module.scss';
import classNames from 'clsx';
import { t } from 'services/utils/translation';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import WarehouseNote from 'models/WarehouseNote';
import { useSelector } from 'react-redux';
import { getExternalRequestFieldsValueFromStore } from '../../../store/externalRequest/selectors';
import { updateExternalRequestV2 } from '../../../services/api/external-request/external-request/externalRequestApi';
import { usePostV3 } from '../../../hooks/usePostV3';
import { SAVE_FILELD_VALUES_ACTION, useSaveValueStore } from 'hooks/useSaveValueStore';
import { IInvoice } from '../../../services/api/warehouse/IGetInvoicesResponse';
import { parseISO } from 'date-fns';
import TurningArrow from 'components/misc/TurningArrow/TurningArrow';
import { formatDateTimeShort } from '../../../services/utils/dateHelper/dateHelper';
import { textFromBoolean } from '../../../services/utils/extractGetLeftValueIfTrue';

const getExternalRequestInvoice = (
  externalRequestInvoice?: IInvoice | IInvoice[]
) => {
  if (externalRequestInvoice) {
    return Array.isArray(externalRequestInvoice)
      ? externalRequestInvoice
      : [externalRequestInvoice];
  } else {
    return [];
  }
};

const LABELS_INVOICE = {
  REFUND: t('Отменена'),
  IS_MANUALLY: t('Вручную'),
  ITEMS: t('Товары'),
};

type PropsType = {
  externalRequestPk?: number;
  externalRequestInvoice?: IInvoice[];
  isViewOnly?: boolean;
};

const ExternalRequestWarehouse: FC<PropsType> = (props) => {
  const {
    externalRequestPk,
    externalRequestInvoice: invoice,
    isViewOnly,
  } = props;
  const externalRequestFieldsValue = useSelector(
    getExternalRequestFieldsValueFromStore
  );
  const saveNotes = usePostV3({
    fetchApi: updateExternalRequestV2,
    successMessage: t('Заметка добавлена.'),
    // successHandler,
    viewClientTextToast: true,
  });
  const saveValueInStoreForExternalRequest =
    useSaveValueStore(SAVE_FILELD_VALUES_ACTION.externalRequest);

  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const newExternalRequestInvoice = getExternalRequestInvoice(invoice);

  return (
    <div className="d-flex flex-column pl-5 ml-6">
      {invoice && (
        <div>
          <Label
            className={classNames(
              'control-label mb-4 mt-4',
              styles['form-group-label']
            )}
          >
            {t('Выдано')}
          </Label>
          <div className="d-flex flex-wrap">
            {newExternalRequestInvoice.map((invoice: IInvoice) => {
              return (
                <>
                  <Card style={{ width: '700px' }} className="p-1 m-1">
                    <CardHeader className="d-flex justify-content-between">
                      <span className={styles.cardTitle}>
                        {invoice?.invoice_num}
                      </span>
                      <div>
                        {formatDateTimeShort(parseISO(invoice.invoice_date))}
                      </div>
                    </CardHeader>

                    <CardBody>
                      <CardSubtitle className="d-flex justify-content-between">
                        <div>
                          <span>{LABELS_INVOICE.REFUND}</span>:
                          <span>{textFromBoolean(invoice?.refund)}</span>
                        </div>
                        <div>
                          <span>{LABELS_INVOICE.IS_MANUALLY}</span>:
                          <span>{textFromBoolean(invoice?.is_manually)}</span>
                        </div>
                      </CardSubtitle>
                    </CardBody>
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <CardSubtitle className="pb-2">
                          {LABELS_INVOICE.ITEMS}:{invoice.items?.length}
                        </CardSubtitle>
                        <TurningArrow
                          className="pl-2"
                          id={invoice.invoice_num}
                          onClick={toggleCollapse}
                          turnUp={isOpen}
                        />
                      </div>
                      <Collapse isOpen={isOpen}>
                        <Table>
                          <thead>
                            <tr>
                              <th>{t('Наименование')}</th>
                              <th>{t('Количество')}</th>
                              <th>{t('Сумма')}</th>
                              <th>
                                {t('Откуда')}-{t('Куда')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoice.items.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name.trim()}</td>
                                <td>
                                  {item.count} {item.unit}
                                </td>
                                <td>{item.amount}</td>
                                <td>
                                  {item.from_warehouse} → {item.to_warehouse}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Collapse>
                    </CardBody>
                  </Card>
                </>
              );
            })}
          </div>
        </div>
      )}

      <Formik
        enableReinitialize={true}
        initialValues={{
          external_request_warehouse_note:
            externalRequestFieldsValue?.external_request_warehouse_note,
          pk: externalRequestPk,
        }}
        validationSchema={WarehouseNote.validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await saveNotes(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate={true}>
            <div className={classNames(' pb-1')}>
              <Label
                className={classNames(
                  'control-label mb-4 mt-4',
                  styles['form-group-label']
                )}
              >
                Задание
              </Label>
              <TextField
                id="external_request_warehouse_note"
                type="textarea"
                hasError={
                  errors.external_request_warehouse_note != null &&
                  touched.external_request_warehouse_note != null
                }
                onBlur={handleBlur}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  saveValueInStoreForExternalRequest({ event });
                  handleChange(event);
                }}
                defaultValue={values.external_request_warehouse_note}
                className="text-area-container"
                style={{ width: '100%' }}
                disabled={isViewOnly}
              />
              <FormGroup className="d-block text-left mt-4">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || isViewOnly}
                >
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default memo(ExternalRequestWarehouse);
