import React, { FC, memo } from 'react';

import DatePickerRangeV2 from '../DatePickerRangeV2';
import HeaderWithSort from '../HeaderWithSort/HeaderWithSort';
import styles from './index.module.scss';

export interface IPropsOnSearch {
  onSearchRequest: (searchString: any, field: string) => void;
  keyDate: [string, string];
}

type PropsType = {
  propsOnSearch: IPropsOnSearch;
  title: string;
  startDate: Date | null;
  endDate: Date | null;
  onSort?: Function;
  field: string;
  customInput?: boolean;
  hasTime?: boolean;
};

const HeaderWithDatePickerRangeV2: FC<PropsType> = (props) => {
  const { title, propsOnSearch, startDate, endDate, onSort, field, hasTime } =
    props;
  return (
    <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
      <DatePickerRangeV2
        className={styles['search']}
        title={title}
        propsOnSearch={propsOnSearch}
        startDate={startDate}
        endDate={endDate}
        hasTime={hasTime}
      />
      {onSort && (
        <HeaderWithSort
          className={styles['sort']}
          field={field}
          onSort={onSort}
        />
      )}
    </div>
  );
};

export default memo(HeaderWithDatePickerRangeV2);
