import CookieManager from 'models/CoockieManager/CookieManager';

class PhoneNumberJwtCookie {
  private readonly cookieManager: CookieManager;

  constructor(cookieManager: CookieManager) {
    this.cookieManager = cookieManager;
  }

  public setPhoneNumberJwt(jwtPhoneNumber: string) {
    this.cookieManager.setCookie('phone_number_in_jwt', jwtPhoneNumber);
  }

  public removePhoneNumberInJwt() {
    this.cookieManager.removeCookie('phone_number_in_jwt');
  }

  public getPhoneNumberInJwt = () =>
    this.cookieManager.getCookie('phone_number_in_jwt');
}

export default PhoneNumberJwtCookie;
