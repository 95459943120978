import React, { memo } from 'react';
import useCapsLockOn from '../../../PasswordField/hooks/useCapsLockOn/useCapsLockOn';
import { t } from 'services/utils/translation';
import styles from './index.module.scss';

const CapsLockIndicator: React.FC = () => {
  const capsLockOn = useCapsLockOn();
  const textIndicator = t('включен Caps Lock');
  return (
    <div>
      {capsLockOn && (
        <span className={styles.textIndicator}>{textIndicator}</span>
      )}
    </div>
  );
};

export default memo(CapsLockIndicator);
