import { t } from '../../utils/translation';
export interface IResponseNotifications {
  result: IGetResultNotification;
  id: number;
}

export interface IGetResultNotification {
  list_of_entities: INotification[];
  total_number_of_entities: number;
}
export interface INotification {
  notification_pk: number;
  notification_parent_ui_element: NotificationParentUiElement; //TODO: The type specified is incorrect. NotificationParentUiElement has only one of the listed properties
  notification_text: string;
  notification_source_installation: string;
  notification_datetime_created: string;
  notification_payload: string;
  notification_is_reacted_to: boolean;
}

export interface NotificationParentUiElement {
  billing_account: number | 0;
  external_request: number | 0;
  internal_task: number | 0;
  export: number | 0;
  total: number | 0;
}

export type TParentUI = keyof Omit<NotificationParentUiElement, 'total'>;

export const notificationParentUiElementMap = {
  billing_account: t('Лицевой счет'),
  external_request: t('Заявка'),
  internal_task: t('Задача'),
  export: t('Экспорт'),
} as const;
