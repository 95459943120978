import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { IBaseGetPaginatedPayload } from 'services/IBaseGetPaginated';
import IPrimaryKey from 'services/types/IPk';
import { ICreateVehicle, IUpdateVehicle } from './ICreateVehicle';
import {
  IGetPaginatedParamsVehicle,
  IVehicleFilters,
} from './IGetPaginatedParamsVehicle';
import { IVehicle, IVehicleWrapper } from './IVehicle';
 import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IForSelectRequestV2 } from "../../../utils/generateMethodForSelect/generateMethodForSelect";

const api = () =>
  new UndercurrentApiClient().methodCreator(UNDERCURRENT_CHILD_MODULES.VEHICLE);

export const getPaginatedVehicleApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedParamsVehicle>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IVehicle>>> =>
  api().getPaginated(filterParams);

export const readVehicleApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<IVehicleWrapper>> => api().read(params);

export const createVehicleApiV2 = async (
  params: IBaseParams<ICreateVehicle>
): Promise<IBaseResponse<IVehicleWrapper>> => await api().create(params);

export const updateVehicleApiV2 = async (
  employee: IBaseParams<IUpdateVehicle>
): Promise<IBaseResponse<IVehicleWrapper>> => await api().update(employee);

export const deleteVehicleApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectVehicleApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const exportVehicleApiV2 = async (
  filterParams: IBaseParams<IVehicleFilters>
): Promise<IBaseResponse<string>> => api().export(filterParams);
