import IBaseSubListEntity from 'components/tables/TDSubList/IBaseSubListEntity';
import toBaseSubType from './toBaseSubType';

const toBaseSubListEntity = <IEntitySubType,>(
  subTypes: IEntitySubType[],
  [pkKey, nameKey, isActiveKey]: [
    keyof IEntitySubType,
    keyof IEntitySubType,
    keyof IEntitySubType | null
  ]
): IBaseSubListEntity[] =>
  subTypes.reduce((acc: IBaseSubListEntity[], cur: IEntitySubType) => {
    if (isActiveKey === null || (isActiveKey && cur?.[isActiveKey])) {
      const baseSubType = toBaseSubType(cur, [pkKey, nameKey]);
      acc.push(baseSubType);
    }
    return acc;
  }, []);

export default toBaseSubListEntity;
