import useReadOld from 'hooks/useReadOld/useReadOld';
import { getFileBucketApi } from 'services/api/documents/file-bucket/fileBucketApi';
import { t } from 'services/utils/translation';
import { useCallback, useEffect, useState } from 'react';
import { IFileBucket } from 'services/api/documents/file-bucket/IGetResponseFileBuckets';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { IDocumentFormValues } from '../IDocumentFormValues';
import { processFiles } from '../utils/processFiles';
import toDocumentFormValues from '../utils/toDocumenFormtValues';
import { findTargetSchema } from '../utils/findTargetSchema';
import { catchErrorFromFetch } from 'services/utils/catchAndRegError/catchAndRegError';

const errorMessageFileBucketRead = t(
  `Не возможно загрузить документ. Пожалуйста, свяжитесь с администратором`
);

type PropsType = {
  pk?: number;
  listSchemes?: IClientSelectOptionV2[];
  initial: IDocumentFormValues;
};

export const useGetInitialFileBucket = ({
  pk,
  listSchemes,
  initial,
}: PropsType) => {
  const [initialFileBucket, setInitialFileBucket] =
    useState<IDocumentFormValues>(initial);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: fileBucketInitial, isLoading: isLoadingFileBucket } =
    useReadOld({
      getDataApi: getFileBucketApi,
      pk: pk as number,
      initialData: null,
      condition: !!pk && !!listSchemes,
      convertData: (res: IFileBucket) => res,
      errorMessage: errorMessageFileBucketRead,
    });

  const setInitialFormValues = useCallback(
    async (fileBucket: IFileBucket, listSchema: IClientSelectOptionV2[]) => {
      setIsLoading(true);
      try {
        const [files, schema] = await Promise.all([
          processFiles(fileBucket.list_of_uploaded_file_names),
          findTargetSchema(listSchema, fileBucket.file_bucket_schema_pk),
        ]);
        const values = toDocumentFormValues(fileBucket, files, schema);
        setInitialFileBucket(values);
      } catch (error) {
        catchErrorFromFetch(errorMessageFileBucketRead, error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (fileBucketInitial && !!listSchemes) {
      void setInitialFormValues(fileBucketInitial, listSchemes);
    }
  }, [fileBucketInitial, listSchemes, setInitialFormValues]);

  return {
    initialFileBucket,
    isLoadingFileBucket: isLoading || isLoadingFileBucket,
  };
};
