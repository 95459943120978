import classNames from 'clsx';
import React, { FC, memo, useEffect, useRef } from 'react';
import { t } from 'services/utils/translation';
import styles from './index.module.scss';
import LoaderFetch from '../../../layouts/LoaderFetch/LoaderFetch';
import { formatTime } from '../../../../services/utils/dateHelper/dateHelper';
import isEqual from 'react-fast-compare';
import { IClientCommentsGroup } from '../CommentBlockWrapper/types/IClientCommentsGroup';
import { IClientComment } from '../CommentBlockWrapper/types/IClientComment';
import IClientsGroupCommentsWithAddition from '../CommentBlockWrapper/types/IClientCommentsWithOptions';
import { addHours, parseISO } from 'date-fns';
import config from 'config.json';

type PropsType = {
  comments: IClientsGroupCommentsWithAddition;
  isLoading: boolean;
};

const addHoursForDate = (additionalHours: number) => (date: Date) =>
  addHours(date, additionalHours);

export const addThreeHours = addHoursForDate(config.moscowTimeZone);

export const clientDate = (timeAdded: string) => {
  const parsedTime = parseISO(timeAdded);
  const newTime = addThreeHours(parsedTime);
  return formatTime(newTime);
};

const CommentList: FC<PropsType> = (props) => {
  const {
    comments: { groupComments, numberAllMessages },
    isLoading,
  } = props;
  const commentsBlockRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    commentsBlockRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (commentsBlockRef == null || commentsBlockRef.current == null) {
      return;
    }
    const commentList = commentsBlockRef.current;
    const scrollHeight = commentList.scrollHeight;
    const height = commentList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    commentList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    if (groupComments.length > 1) {
      scrollToBottom();
    }
  }, [groupComments]);

  return (
    <div className={styles.comments}>
      <div
        className={classNames('d-flex align-items-center p-3', styles.header)}
      >
        {t('Комментарии')} ({numberAllMessages})
      </div>
      <div
        ref={commentsBlockRef}
        className={classNames(styles.comments, 'px-4 py-2', {
          'd-none': groupComments?.length === 0,
        })}
      >
        {groupComments.map(({ comments, date }: IClientCommentsGroup) => {
          return (
            <>
              <div className={styles.date}> {date}</div>
              {comments.map((comment: IClientComment, i: number) => (
                <div
                  className={classNames('p-2 my-2', {
                    [styles.notMyComment]: !comment.isMyComment,
                    [styles.myComment]: comment.isMyComment,
                  })}
                  key={comment.timeAdded + i}
                >
                  <div className={styles.commentHeader}>
                    <div className={classNames(styles.author, 'pr-2')}>
                      {comment.authorName}
                    </div>
                    <div className={styles.time}>
                      {clientDate(comment.timeAdded)}
                    </div>
                  </div>
                  <p className={classNames(styles.commentBody, 'my-1')}>
                    {comment.commentText}
                  </p>
                </div>
              ))}
            </>
          );
        })}
      </div>
      {isLoading && <LoaderFetch />}
    </div>
  );
};

CommentList.whyDidYouRender = true;

export default memo(CommentList, isEqual);
