import { IEventFilters } from 'services/api/organizationsV2/event/IGetPaginatedParamsEvent';
import config from 'config.json';
import { SortCriteriaEvent } from 'services/api/organizationsV2/event/SortCriteriaEvent';

const initialEventFilters: IEventFilters = {
  event_pk: undefined,
  event_name: '',
  event_billing_account_fk_array: [],
  event_type_fk_array: [],
  event_is_closed: undefined,
  event_datetime_added_upper_bound: '',
  event_datetime_added_lower_bound: '',
  event_datetime_edited_lower_bound: '',
  event_datetime_edited_upper_bound: '',
  skip: 0,
  length: config.pageLength,
  sort_by: SortCriteriaEvent.Default,
};

export default initialEventFilters;
