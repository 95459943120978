import { TypeSetValue } from 'hooks/useLocalStorage';
import React, { FC, memo, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { getPaths } from 'services/paths';
import { getMenuFromStore } from '../../../store/general/selectors';
import ListFolderItems from '../ListFolder/ListFolder';
import { ISubMenuItemV2 } from '../../../services/interfaces/IMenuItemV2';

type TypeProps = {
  path?: string;
  setListMyFavoriteFolder?: TypeSetValue<ISubMenuItemV2[]>;
};

type FolderProps = PropsWithChildren<TypeProps>;

const Folder: FC<FolderProps> = (props) => {
  const { path, children } = props;

  const menu = useSelector(getMenuFromStore);

  if (children == null) {
    let submenu = getPaths(menu, path);

    return (
      <>{submenu && submenu.length > 0 && <ListFolderItems menu={submenu} />}</>
    );
  }

  return (
    <div className="py-3 px-2 d-flex flex-row flex-wrap justify-content-center justify-content-sm-start">
      {children}
    </div>
  );
};

export default memo(Folder);
