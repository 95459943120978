import { TargetAccessPolicy } from './TargetAccessPolicy';
import { TargetAccessPolicyService } from './TargetAccessPolicyService';

export const targetAccessPolicyServiceVehicles = new TargetAccessPolicyService(
  'vehicles'
);

export const targetAccessPolicyBooking = new TargetAccessPolicy(
  targetAccessPolicyServiceVehicles,
  'Booking'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyVehicle = new TargetAccessPolicy(
  targetAccessPolicyServiceVehicles,
  'Vehicle'
).getTargetAccessPolicyStatement();

export const targetAccessPolicyVehicleType = new TargetAccessPolicy(
  targetAccessPolicyServiceVehicles,
  'VehicleType'
).getTargetAccessPolicyStatement();
