import {
  BILLING_ACCOUNT_CLEAR_EXTERNAL_REQUEST_FILTERS,
  BILLING_ACCOUNT_CLEAR_TASK_FILTERS,
  BILLING_ACCOUNT_SAVE_EXTERNAL_REQUEST_FILTERS,
  BILLING_ACCOUNT_SAVE_FILTERS,
  BILLING_ACCOUNT_SAVE_TASK_FILTERS,
  BILLING_ACCOUNT__CLEAR_FILTERS,
  SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER,
  SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER,
  SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER,
  SET_IS_ACTIVE_1C_FILTER,
} from './actionTypes';
import { CLEAR_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA } from './actionTypes';
import { initialBillingAccountsFilters } from '../initialStore/initialBillingAccountsFilters';
import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import { IBillingAccountFilters } from 'services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';

//BILLING_ACCOUNT_PAGE
export const saveBillingAccountFilters = (filters: IBillingAccountFilters) => ({
  type: BILLING_ACCOUNT_SAVE_FILTERS,
  filters,
});

export const clearBillingAccountFilters = () => ({
  type: BILLING_ACCOUNT__CLEAR_FILTERS,
  filters: initialBillingAccountsFilters,
});

export const clearBillingAccountFormDataDefaultValue = () => ({
  type: CLEAR_BILLING_ACCOUNT_DEFAULT_VALUE_FORM_DATA,
});

export const setIsActive1CFilter = (IsActive1NumberFilter: boolean) => ({
  type: SET_IS_ACTIVE_1C_FILTER,
  payload: IsActive1NumberFilter,
});

//TASK_PAGE

export const saveBillingAccountTaskFilters = (
  filters: ITaskFilters,
  pk: number
) => ({
  type: BILLING_ACCOUNT_SAVE_TASK_FILTERS,
  payload: { pk, filters },
});

export const extractClearBillingAccountTaskFilters = (pk: number) => () => ({
  type: BILLING_ACCOUNT_CLEAR_TASK_FILTERS,
  payload: pk,
});

//EXTERNAL_REQUEST_PAGE

export const saveBillingAccountExternalRequestFilters = (
  filters: IExternalRequestFilters,
  pk: number
) => ({
  type: BILLING_ACCOUNT_SAVE_EXTERNAL_REQUEST_FILTERS,
  payload: { pk, filters },
});

export const extractClearBillingAccountExternalRequestFilters =
  (pk: number) => () => ({
    type: BILLING_ACCOUNT_CLEAR_EXTERNAL_REQUEST_FILTERS,
    payload: pk,
  });

export const setBillingAccountExternalRequestIsActivePkFilter =
  (eventPk: number) => (isActivePkFilter: boolean) => ({
    type: SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER,
    payload: { eventPk, isActivePkFilter },
  });

export const setBillingAccountIsActiveImplementerDkArrayFilter =
  (eventPk?: number) => (isActiveImplementerDkArrayFilter: boolean) => ({
    type: SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER,
    payload: { eventPk, isActiveImplementerDkArrayFilter },
  });

export const setBillingAccountIsActiveWorkCategoryFilter =
  (eventPk?: number) => (isActiveWorkCategoryFilter: boolean) => ({
    type: SET_BILLING_ACCOUNT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER,
    payload: { eventPk, isActiveWorkCategoryFilter },
  });
