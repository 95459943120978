import { initialEmployeeFilters } from 'store/initialStore/initialEmplouyeeFilters';
import { EMPLOYEE_SAVE_FILTERS, EMPLOYEE_CLEAR_FILTERS } from './actionTypes';
import { IEmployeeFilters } from '../../services/api/staff/employee/IGetPaginatedParamsEmployee';
export const saveEmployeeFilters = (filters: IEmployeeFilters) => ({
  type: EMPLOYEE_SAVE_FILTERS,
  filters,
});

export const clearEmployeeFilters = () => ({
  type: EMPLOYEE_CLEAR_FILTERS,
  filters: initialEmployeeFilters,
});
