import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import IClientSelectOptionV2 from '../services/api/interfacesApi/IClientSelectOptionV2';
import {
  regEmail,
  regTelephoneNumber,
} from '../services/utils/myRegex/myRegex';

class Employee {
  public pk?: number;

  public employee_last_name?: string = '';
  public employee_first_name?: string = '';
  public employee_middle_name?: string = '';
  public employee_phone_number?: string = '';
  public employee_email?: string = '';
  public employee_identity_nickname?: string = '';
  public employee_personnel_code_number?: string = '';
  public jobTitle?: IClientSelectOptionV2<number>;
  public supervisor?: IClientSelectOptionV2<number>;
  public accessPolicy?: IClientSelectOptionV2<number>;
  public employee_is_driver?: boolean = false;

  constructor(pk?: number) {
    this.pk = pk;
  }

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      employee_is_driver: Yup.boolean(),
      employee_last_name: Yup.string()
        .trim()
        .required(t('Укажите фамилию сотрудника'))
        .min(1, t('Текст не может быть короче 1 символа')),
      employee_first_name: Yup.string()
        .trim()
        .required(t('Укажите имя сотрудника'))
        .min(1, t('Текст не может быть короче 1 символа')),
      employee_middle_name: Yup.string()
        .trim()
        .notRequired()
        .min(1, t('Текст не может быть короче 1 символа')),
      employee_phone_number: Yup.string()
        .trim()
        .notRequired()
        .nullable()
        .matches(regTelephoneNumber, {
          excludeEmptyString: true,
          message: t('Некорректно введен номер телефона'),
        }),
      employee_email: Yup.string()
        .trim()
        .matches(regEmail, t('Некорректно введен email'))
        .notRequired(),
      employee_personnel_code_number: Yup.string()
        .trim()
        .min(1, t('Текст не может быть короче 1 символа')),
      jobTitle: Yup.object().nullable(),
      supervisor: Yup.object().nullable(),
      accessPolicy: Yup.object().nullable(),
    });
  };
}

export default Employee;
