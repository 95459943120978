import {
  SET_ACCESS_TOKEN,
  SET_ACTIVE_INSTALLATION,
  USER_LOGIN,
} from './actionTypes';
import { Dispatch } from 'redux';
import { getObtainAccessTokenApiV3 } from '../../services/api/services';
import createTimestamp from '../../services/utils/createTimestamp/createTimestamp';
import { ERROR_ACTION } from '../general/actionTypes';
import { IIdentity } from '../../services/api/auth/IRegisterIdentity';

export const userLoginApiV2 = (identity: IIdentity) => {
  return {
    type: USER_LOGIN,
    identity,
  };
};
export const setActiveInstallation = (
  installation: string,
  installationClientName: string,
  installationPk: number
) => {
  const activeInstallation = {
    installationClientName,
    installation,
    installationPk,
  };
  return {
    type: SET_ACTIVE_INSTALLATION,
    payload: activeInstallation,
  };
};
export const setAccessToken =
  (installationPk: number) => async (dispatch: Dispatch) => {
    const errorMessage =
      'Не удалось загрузить пользователя. Обратитесь пожалуйста к администратору';

    try {
      const response = await getObtainAccessTokenApiV3({
        id: createTimestamp(),
        params: { target_installation_pk: installationPk },
      });

      const {
        result: { verbose, payload, message, outcome },
      } = response;

      if (outcome === 'WARNING' && !payload) {
        return dispatch({
          type: ERROR_ACTION,
          payload: { msg: verbose ? verbose : errorMessage, message },
        });
      } else {
        return dispatch({
          type: SET_ACCESS_TOKEN,
          payload,
        });
      }
    } catch (error) {
      return dispatch({
        type: ERROR_ACTION,
        payload: { msg: errorMessage, error },
      });
    }
  };
