import { FC, memo, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'clsx';

type SubHeaderWrapperPropsType = {
  mainTitle: string;
  children?: ReactNode;
  classNameWrapper?: string;
};

const SubHeaderGreyWrapper: FC<SubHeaderWrapperPropsType> = (props) => {
  const { children, mainTitle, classNameWrapper } = props;
  return (
    <div
      className={classNames('ml-4', styles.wrapperSubHeader, classNameWrapper)}
    >
      <div className={classNames('ml-4', styles.title)}>{mainTitle}</div>
      <div className="d-flex align-items-center mr-4">{children}</div>
    </div>
  );
};

export default memo(SubHeaderGreyWrapper);
