import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import TaskType from 'models/TaskType';
import { FC, useState } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';
import { usePostV3 } from '../../../hooks/usePostV3';

import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import {
  getTaskTypeParentsForSelectApi,
  createTaskTypeApiV2,
  updateTaskTypeApiV2,
} from '../../../services/api/tasks/task-typeV2/task-typeV2';
import useRead from '../../../hooks/useRead';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { readTaskTypeV2 } from '../../../services/api/tasks/task-typeV2/task-typeV2';
import { IUpdateTaskType } from '../../../services/api/tasks/task-typeV2/ICreateTaskType';
import { ITaskTypeWrapper } from '../../../services/api/tasks/task-typeV2/IGetTaskTypesPayload';
import { extractValueFromClientSelectOptionV2 } from '../../../services/utils/selects/selects';
import IClientSelectOptionV2 from '../../../services/api/interfacesApi/IClientSelectOptionV2';
import deleteUnnecessaryParameters from '../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

const preprocessValues = (values: IFormValues) => {
  return deleteUnnecessaryParameters(
    {
      ...values,
      internal_task_type_parent_type_fk: extractValueFromClientSelectOptionV2(
        values?.parent
      ),
    },
    ['parent']
  );
};

interface IFormValues {
  pk?: number;
  internal_task_type_rendition: string;
  parent?: IClientSelectOptionV2<number>;
  internal_task_type_parent_type_fk?: number;
  // permission?: string;
  // permission_name?: string;
}

type PropsType = {
  successHandler: () => void;
  pk?: number;
};

const TaskTypeForm: FC<PropsType> = (props) => {
  const { successHandler, pk } = props;

  const [isParent, setIsParent] = useState<boolean>(false);

  const convertData = ({
    internal_task_type,
  }: ITaskTypeWrapper): IFormValues => {
    setIsParent(!internal_task_type?.internal_task_type_parent_type_fk);
    return {
      pk: internal_task_type?.internal_task_type_pk,
      internal_task_type_rendition:
        internal_task_type.internal_task_type_rendition,
      internal_task_type_parent_type_fk:
        internal_task_type.internal_task_type_parent_type_fk,
    };
  };

  const { data: initialValues, isLoading } = useRead({
    getDataApi: readTaskTypeV2,
    params: { pk },
    convertData,
    condition: !!pk,
    initialData: new TaskType(),
    warningHandler: successHandler,
  });
  const createTaskType = usePostV3({
    fetchApi: createTaskTypeApiV2,
    successHandler,
    viewClientTextToast: true,
    successMessage: t('Тип задачи успешно создан'),
  });
  const updateTaskType = usePostV3({
    fetchApi: updateTaskTypeApiV2,
    successHandler,
    viewClientTextToast: true,
    successMessage: t('Тип задачи успешно отредактирован'),
  });

  return (
    <>
      <LoaderFetch />
      {!isLoading && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={TaskType.validationSchema()}
          onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
            setSubmitting(true);
            const newValues = preprocessValues(values);
            if (newValues?.pk !== undefined) {
              await updateTaskType(newValues as IUpdateTaskType, setErrors);
            } else {
              await createTaskType(newValues, setErrors, resetForm);
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} noValidate={true}>
              {values.pk && (
                <HiddenField
                  id="pk"
                  value={values.pk}
                  hasError={errors.pk != null && touched.pk != null}
                />
              )}
              <TextField
                type="text"
                id="internal_task_type_rendition"
                label={t('Название')}
                placeholder={t('Название')}
                hasError={
                  errors.internal_task_type_rendition != null &&
                  touched.internal_task_type_rendition != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={values.internal_task_type_rendition}
                required={true}
              />
              {!isParent && (
                <DynamicSelectPaginatedFieldV2
                  id="parent"
                  label={t('Выбрать родительский тип задач')}
                  placeholder={t('Выберите тип')}
                  hasError={errors.parent != null && touched.parent != null}
                  selectHandler={getTaskTypeParentsForSelectApi}
                  options={values.parent}
                  additionalErrorKey="parent_id"
                />
              )}
              <FormGroup className="d-block text-right mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

// const toFormValues = (taskType: ITaskTypeOld): IFormValues => {
//   return {
//     internal_task_type_pk: taskType.internal_task_type_pk,
//     name: taskType.name,
//     parent_id: taskType?.parent?.id && taskType.parent.id,
//   };
// };

export default TaskTypeForm;
