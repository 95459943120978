import { parseISO } from 'date-fns';
import { IController } from 'services/api/controller/IController';
import { formatDateTimeShort } from 'services/utils/dateHelper/dateHelper';

export interface IControllerForList extends IController {
  createDate: string;
  editedDate: string;
}

const toControllerForList = (controller: IController): IControllerForList => {
  const {
    controller_datetime_added: dateAdded,
    controller_datetime_edited: editedDate,
  } = controller;

  return {
    ...controller,
    createDate: formatDateTimeShort(parseISO(dateAdded)),
    editedDate: formatDateTimeShort(parseISO(editedDate)),
  };
};

export const toControllersForList = (
  controllers: IController[]
): IControllerForList[] => controllers.map(toControllerForList);
