import { FC, ReactNode, memo } from 'react';
import MainLayout from '../MainLayout/MainLayout';
import SubHeaderDetails from '../SubHeaderDetails/SubHeaderDetails';
import PagerWithParameterSwitch from 'components/misc/Pagers/PagerWithParameterSwitch/PagerWithParameterSwitch';
import { IKeyValue, IKeyValueString } from 'services/interfaces/IKeyValue';
import { IMenuItemPagerV2 } from 'services/interfaces/IMenuItemPagerV2';
interface ViewEntityProps {
  mainInfo: IKeyValueString;
  items?: IKeyValue<string | JSX.Element>;
  linkOnRightButton: string;
  tabs: IMenuItemPagerV2[];
  targetTab: string;
  isLoadingCountTabs: boolean;
  children?: ReactNode;
}

const ViewEntity: FC<ViewEntityProps> = (props) => {
  const {
    children,
    mainInfo,
    items,
    linkOnRightButton,
    tabs,
    targetTab,
    isLoadingCountTabs,
  } = props;
  return (
    <MainLayout>
      <SubHeaderDetails
        mainInfo={mainInfo}
        items={items}
        linkOnRightButton={linkOnRightButton}
      />

      <PagerWithParameterSwitch
        items={tabs}
        targetTab={targetTab}
        isLoadingCountTabs={isLoadingCountTabs}
      />
      {children}
    </MainLayout>
  );
};

export default memo(ViewEntity);
