import ApiClientBase from '../api/ApiClientBase';
import { isDev } from '../../config';
import config from 'config.json';
class SocketClientBase extends ApiClientBase {
  constructor(
    module: string,
    installation: string | null = null,
    versionApi: string = 'v2'
  ) {
    super(module, installation, versionApi);
    this.baseProtocol = 'wss://';
    this.installation = isDev() ? 'centralregistry-dev' : 'centralregistry';
    this.baseUrl = this.composeBaseUrl();
  }

  composeBaseUrl = () =>
    !isDev()
      ? `${this.baseProtocol}${config.prodDomain}/${this.installation}/${this.module}/api/${this.versionApi}/ws`
      : `${this.baseProtocol}${this.devDomain}/${this.installation}/${this.module}/api/${this.versionApi}/ws`;
}
export class NotificationSocketClient extends SocketClientBase {
  constructor() {
    super('notifications', null, 'v2');
  }
}
