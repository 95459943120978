import MainLayout from 'components/layouts/MainLayout/MainLayout';
import styles from './index.module.scss';
import { useRouteError } from 'react-router-dom';
import { t } from '../../../services/utils/translation';
import { Button } from 'reactstrap';
// import { NavLink } from 'react-router-dom';
import classNames from 'clsx';
import { useSelector } from 'react-redux';
import { getErrorsFromStore } from 'store/general/selectors';
import { FC } from 'react';

const ErrorFallback: FC = () => {
  const reloadPage = () => window.location.reload();
  const error = useRouteError() as Error | null;
  const errorFromStore = useSelector(getErrorsFromStore);
  const errorMessage = `${t('Что-то пошло не так')}: 
  ${error?.message ? error?.message : errorFromStore}`;

  return (
    <MainLayout>
      <div
        className={classNames(styles.container, styles['fullscreen-wrapper'])}
      >
        <span className="mb-3">{errorMessage}</span>
        <div>
          <Button onClick={reloadPage}>{t('Перезагрузить страницу')}</Button>
          {/* <NavLink className="btn btn-primary ml-4" to="/">
            {t('Вернуться на главную')}
          </NavLink> */}
        </div>
      </div>
    </MainLayout>
  );
};

export default ErrorFallback;
