import { Formik } from 'formik';
import { ChangeEvent, FC, memo } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import HiddenField from 'components/controls/HiddenField';
import { t } from 'services/utils/translation';
import TextField from 'components/controls/TextField';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import styles from './index.module.scss';

import { usePostV3 } from 'hooks/usePostV3';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import classNames from 'clsx';
import { createEmployeeApiV2 } from 'services/api/staff/employee/employeeApi';
import {
  updateEmployeeApiV2,
  getForSelectOnlyActiveEmployeeApiV2,
} from '../../../../services/api/staff/employee/employeeApi';
import Employee from 'models/Employee';
import { extractValueFromClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import {
  ICreateEmployee,
  IUpdateEmployee,
} from '../../../../services/api/staff/employee/ICreateEmpoyee';
import PhoneField from 'components/controls/PhoneField/PhoneField';
import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { getForSelectJobTitleApiV2 } from '../../../../services/api/staff/job-title/jobTitleApiV2';
import { getForSelectAccessPolicyApiV2 } from '../../../../services/api/staff/access-policy/accessPolicyApiV2';
import Checkbox from 'components/controls/Checkbox';
import { getParametersRenullForEmployee } from '../../../../services/utils/renull/renull';
import deleteUnnecessaryParameters from '../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import useGetEmployeeAccessPolicy from 'pages/staff/employee/EmployeePage/hooks/useGetEmployeeAccessPolicy';

const convertParams = (values: Employee): IUpdateEmployee | ICreateEmployee => {
  const { jobTitle, supervisor, accessPolicy, pk } = values;
  const newValues = {
    ...values,
    employee_job_title_fk: extractValueFromClientSelectOptionV2(jobTitle),
    employee_supervisor_fk: extractValueFromClientSelectOptionV2(supervisor),
    employee_access_policy_fk:
      extractValueFromClientSelectOptionV2(accessPolicy),
  };

  const MAY_BE_RENULL = [
    'employee_job_title_fk',
    'employee_supervisor_fk',
    'employee_access_policy_fk',
    'employee_middle_name',
    'employee_phone_number',
    'employee_email',
    'employee_personnel_code_number',
  ];

  // type TClientOptionsProp =
  //   | 'supervisor'
  //   | 'jobTitle'
  //   | 'accessPolicy'
  //   | 'employee_supervisor'
  //   | 'employee_job_title';

  const renull = pk && getParametersRenullForEmployee(newValues, MAY_BE_RENULL);

  const res = deleteUnnecessaryParameters(
    newValues,
    [
      'supervisor',
      'jobTitle',
      'accessPolicy',
      'employee_supervisor',
      'employee_job_title',
      'employee_pk',
    ],
    ['employee_is_driver']
  );

  return { ...res, ...renull } as IUpdateEmployee | ICreateEmployee;
};

type PropsType = {
  successHandler: () => void;
  pk?: number;
  initialValue: Employee;
  isLoading: boolean;
  isViewOnly: boolean;
};
const EmployeeForm: FC<PropsType> = (props) => {
  const { initialValue, isLoading, pk, successHandler, isViewOnly } = props;

  const isNew = !pk;

  const { jobTitleHaveAccessToRead, accessPolicyHaveAccessToRead } =
    useGetEmployeeAccessPolicy();

  const createEmployee = usePostV3({
    fetchApi: createEmployeeApiV2,
    successHandler,
    successMessage: t('Сотрудник создан успешно.'),
    convertParams: convertParams,
    viewClientTextToast: true,
  });

  const updateEmployee = usePostV3({
    fetchApi: updateEmployeeApiV2,
    successHandler,
    successMessage: t('Сотрудник отредактирован успешно.'),
    convertParams: convertParams as (values: Employee) => IUpdateEmployee,
    viewClientTextToast: true,
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValue}
      validationSchema={Employee.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        isNew
          ? await createEmployee(
              values as ICreateEmployee,
              setErrors,
              resetForm
            )
          : await updateEmployee(
              values as IUpdateEmployee,
              setErrors,
              resetForm
            );

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <>
          {!isLoading && (
            <Form
              onSubmit={handleSubmit}
              noValidate={true}
              style={{ maxWidth: '100%' }}
            >
              <div
                className={classNames(
                  'd-flex flex-column flex-md-row justify-content-md-between ml-4 mr-4 mr-md-auto pl-3'
                )}
              >
                <div
                  className={classNames('my-2 my-md-3 py-3 w-100')}
                  style={{ maxWidth: '800px' }}
                >
                  {!isNew && (
                    <HiddenField
                      id="pk"
                      value={values.pk + ''}
                      hasError={errors.pk != null && touched.pk != null}
                    />
                  )}

                  <fieldset className={classNames(styles.fieldsContainer)}>
                    <h4 className={'mb-4'}>{t('Основная информация')}</h4>
                    <TextField
                      required={true}
                      type="text"
                      id="employee_last_name"
                      label={t('Фамилия')}
                      placeholder={t('Введите фамилию сотрудника...')}
                      hasError={
                        errors.employee_last_name != null &&
                        touched.employee_last_name != null
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.employee_last_name}
                      disabled={isViewOnly}
                    />
                    <TextField
                      required={true}
                      type="text"
                      id="employee_first_name"
                      label={t('Имя')}
                      placeholder={t('Введите имя сотрудника...')}
                      hasError={
                        errors.employee_first_name != null &&
                        touched.employee_first_name != null
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.employee_first_name}
                      disabled={isViewOnly}
                    />
                    <TextField
                      type="text"
                      id="employee_middle_name"
                      label={t('Отчество')}
                      placeholder={t('Введите отчество сотрудника...')}
                      hasError={
                        errors.employee_middle_name != null &&
                        touched.employee_middle_name != null
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.employee_middle_name}
                      disabled={isViewOnly}
                    />
                  </fieldset>
                  <fieldset className={classNames(styles.fieldsContainer)}>
                    <h4 className={'mb-4'}>{t('Контактные данные')}</h4>
                    <PhoneField
                      id="employee_phone_number"
                      label={t('Tелефон')}
                      hasError={
                        errors.employee_phone_number != null &&
                        touched.employee_phone_number != null
                      }
                      onBlur={handleBlur}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          'employee_phone_number',
                          convertToServerPhoneNumber(event.target.value)
                        );
                      }}
                      value={values?.employee_phone_number}
                      defaultValue={values?.employee_phone_number}
                      disabled={isViewOnly}
                    />
                    <TextField
                      id="employee_email"
                      autoComplete="email"
                      type="email"
                      placeholder={t('Введите электронную почту сотрудника...')}
                      label={t('Email')}
                      hasError={
                        errors.employee_email != null &&
                        touched.employee_email != null
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.employee_email}
                      value={values.employee_email}
                      disabled={isViewOnly}
                    />
                  </fieldset>
                  <fieldset
                    className={classNames(styles.customer)}
                    disabled={isViewOnly}
                  >
                    <Checkbox
                      name="employee_is_driver"
                      label={t('Является водителем')}
                      defaultChecked={values.employee_is_driver}
                      hasError={
                        errors.employee_is_driver != null &&
                        touched.employee_is_driver != null
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('employee_is_driver', !e.target.checked);
                        handleChange(e);
                      }}
                      className=" pb-3 pl-0"
                      required={true}
                    />
                    <DynamicSelectPaginatedFieldV2
                      id="jobTitle"
                      label={t('Должность')}
                      placeholder={t('Выберите должность...')}
                      hasError={
                        (errors.jobTitle != null && touched.jobTitle != null) ||
                        (errors.jobTitle != null && touched.jobTitle != null)
                      }
                      selectHandler={getForSelectJobTitleApiV2}
                      options={values.jobTitle}
                      onChange={(jobTitle: IClientSelectOptionV2<number>) => {
                        if (jobTitle) {
                          setFieldValue('jobTitle', jobTitle);
                        }
                      }}
                      disabled={isViewOnly}
                      haveReadPermission={jobTitleHaveAccessToRead}
                    />
                    <DynamicSelectPaginatedFieldV2
                      id="accessPolicy"
                      label={t('Роль')}
                      placeholder={t('Выберите роль...')}
                      hasError={
                        (errors.accessPolicy != null &&
                          touched.accessPolicy != null) ||
                        (errors.accessPolicy != null &&
                          touched.accessPolicy != null)
                      }
                      selectHandler={getForSelectAccessPolicyApiV2}
                      options={values.accessPolicy}
                      onChange={(
                        accessPolicy: IClientSelectOptionV2<number>
                      ) => {
                        if (accessPolicy) {
                          setFieldValue('accessPolicy', accessPolicy);
                        }
                      }}
                      disabled={isViewOnly}
                      haveReadPermission={accessPolicyHaveAccessToRead}
                    />
                    <DynamicSelectPaginatedFieldV2
                      id="supervisor"
                      label={t('Руководитель')}
                      placeholder={t('Выберите руководителя...')}
                      hasError={
                        (errors.supervisor != null &&
                          touched.supervisor != null) ||
                        (errors.supervisor != null &&
                          touched.supervisor != null)
                      }
                      selectHandler={getForSelectOnlyActiveEmployeeApiV2}
                      options={values.supervisor}
                      onChange={(supervisor: IClientSelectOptionV2<number>) => {
                        if (supervisor) {
                          setFieldValue('supervisor', supervisor);
                        }
                      }}
                      disabled={isViewOnly}
                    />
                    <TextField
                      id="employee_personnel_code_number"
                      type="text"
                      placeholder={t('Введите табельный номер сотрудника...')}
                      label={t('Табельный номер')}
                      hasError={
                        errors.employee_personnel_code_number != null &&
                        touched.employee_personnel_code_number != null
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.employee_personnel_code_number}
                      value={values.employee_personnel_code_number}
                      disabled={isViewOnly}
                    />
                  </fieldset>
                  {!isViewOnly && (
                    <FormGroup className="d-block text-right mt-4">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {t('Сохранить')}
                      </Button>
                    </FormGroup>
                  )}
                </div>
              </div>
            </Form>
          )}

          {isLoading && <LoaderFetch />}
        </>
      )}
    </Formik>
  );
};

export default memo(EmployeeForm);
