import React, { memo } from 'react';
import { parseISO } from 'date-fns';
import { OldAndNewValuesType } from 'services/api/interfacesApi/IColumnHistory';
import { IUpdateExternalRequest } from 'services/api/external-request/external-request/ICreateExternalRequest';
import isEqual from 'react-fast-compare';
import { IUpdateTask } from '../../../services/api/tasks/tasks/ICreateTask';
import { IUpdateCounterparty } from 'services/api/organizationsV2/counterparty/ICreateCounterparty';
import {
  formatDateTimeShort,
  parseISOAndFormatDateTime,
} from '../../../services/utils/dateHelper/dateHelper';
import { regISO } from '../../../services/utils/myRegex/myRegex';
import { textFromBoolean } from 'services/utils/extractGetLeftValueIfTrue';

//todo move in directory utils and add tests
const toStringValue = (value?: string | number) => (value ? value + '' : '');

const checkISOAndParse = (value: string) => {
  if (regISO.test(value)) {
    return formatDateTimeShort(parseISO(value));
  } else {
    return value;
  }
};

const getAndParseValue = (value?: string | number) => {
  const stringValue = toStringValue(value);
  return checkISOAndParse(stringValue);
};

const isBooleanValue = (value: any) =>
  typeof value === 'boolean';

const handleBooleanValue = (value: any) =>
  isBooleanValue(value) ? textFromBoolean(value) : value;

type PropsHistoryRows<
  T extends IUpdateExternalRequest | IUpdateTask | IUpdateCounterparty
> = {
  editorFullName: string;
  valuesRaw: OldAndNewValuesType<Partial<T>>;
  dateTimeEdit: string;
};

const HistoryRows = <T extends IUpdateExternalRequest | IUpdateTask>(
  props: PropsHistoryRows<T>
) => {
  const { dateTimeEdit, valuesRaw, editorFullName } = props;
  const keys = Object.keys(valuesRaw);

  return (
    <>
      {keys.map((key, index: number, array: string[]) => {
        const attrTitle: string = valuesRaw[key]?.attr_name;

        const oldValue = getAndParseValue(
          handleBooleanValue(valuesRaw[key]?.old)
        );
        const newValue = getAndParseValue(
          handleBooleanValue(valuesRaw[key]?.new)
        );
        return (
          <tr key={attrTitle + index}>
            {index === 0 && (
              <td rowSpan={array.length}>
                {parseISOAndFormatDateTime(dateTimeEdit)}
              </td>
            )}
            {index === 0 && <td rowSpan={array.length}>{'Изменение'}</td>}
            <td>{attrTitle}</td>
            <td>{oldValue}</td>
            <td>{newValue}</td>
            {index === 0 && <td rowSpan={array.length}>{editorFullName}</td>}
          </tr>
        );
      })}
    </>
  );
};

export default memo(HistoryRows, isEqual);
