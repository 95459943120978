import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

// TODO: refactoring    2).  large number of parameters, 2) tests

export function getSelectedValue<T>(
  loadedOptions: IClientSelectOptionV2<T>[],
  optionsArray: IClientSelectOptionV2<T>[],
  isMulti: boolean
): IClientSelectOptionV2<T>[] | IClientSelectOptionV2<T> {
  if (isMulti) {
    return optionsArray;
  }

  let value = loadedOptions.find((option: IClientSelectOptionV2<T>) =>
    optionsArray.find((option2) => option.value === option2.value)
  );

  if (value == null) {
    value = optionsArray[0];
  }

  return value;
}
