import { useCallback, useMemo } from 'react';
import { ValueType } from 'react-select';
import IClientSelectOptionV2 from '../../../../services/api/interfacesApi/IClientSelectOptionV2';
import { useDispatch } from 'react-redux';
import { setIsActive1CFilter } from 'store/billingAccount/actions';

const useBillingAccountHandleSearch = (
  onSearchRequest: (searchString: any, field: string) => void
) => {
  const dispatchRedux = useDispatch();

  const handleSearchCodeNumber = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'billing_account_code_number');
    },
    [onSearchRequest]
  );
  const handleSearchDescription = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'billing_account_description');
    },
    [onSearchRequest]
  );
  const handleSearchTypeFkArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'billing_account_type_fk_array');
    },
    [onSearchRequest]
  );
  const handleSearchAddress = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'billing_account_address');
    },
    [onSearchRequest]
  );
  const handleSearchCounterpartyFkArray = useCallback(
    (
      option: ValueType<
        IClientSelectOptionV2 | IClientSelectOptionV2[],
        boolean
      >
    ) => {
      onSearchRequest(option, 'billing_account_counterparty_fk_array');
    },
    [onSearchRequest]
  );
  const handleSearchComment = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'billing_account_comment_text');
    },
    [onSearchRequest]
  );
  const handleSearch1CNumber = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'billing_account_1c_number');
    },
    [onSearchRequest]
  );
  const handleSearchSTECNumber = useCallback(
    (searchString: string) => {
      onSearchRequest(searchString, 'billing_account_stek_number');
    },
    [onSearchRequest]
  );

  const handleIsActive1CNumber = useCallback(
    (isActive: boolean) => {
      dispatchRedux(setIsActive1CFilter(isActive));
    },
    [dispatchRedux]
  );

  return useMemo(
    () => ({
      handleSearchCodeNumber,
      handleSearchDescription,
      handleSearchTypeFkArray,
      handleSearchCounterpartyFkArray,
      handleSearchComment,
      handleSearchSTECNumber,
      handleSearch1CNumber,
      handleSearchAddress,
      handleIsActive1CNumber,
    }),
    [
      handleSearchCodeNumber,
      handleSearchDescription,
      handleSearchTypeFkArray,
      handleSearchAddress,
      handleSearchCounterpartyFkArray,
      handleSearchComment,
      handleSearchSTECNumber,
      handleSearch1CNumber,
      handleIsActive1CNumber,
    ]
  );
};

export default useBillingAccountHandleSearch;
