import { toast } from 'react-toastify';
import { catchErrorFromFetch } from 'services/utils/catchAndRegError/catchAndRegError';
import { t } from 'services/utils/translation';

const copyToClipboardImage = async (blob: Blob) => {
  try {
    const item = new ClipboardItem({ 'image/png': blob });

    await navigator.clipboard.write([item]);

    toast.success(t('Скриншот скопирован в буфер обмена'));
  } catch (error) {
    catchErrorFromFetch(
      t('Ошибка при копировании скриншота в буфер обмена'),
      error
    );
  }
};

export default copyToClipboardImage;
