import { useLocation, useResolvedPath } from 'react-router-dom';
import { IKeyValueString } from 'services/interfaces/IKeyValue';
import { useMemo } from 'react';

type PropsType = {
  to?: string;
  isRelativePath?: boolean | undefined;
  query?: IKeyValueString | undefined;
  pushToQuery?: IKeyValueString | undefined;
  hash?: any;
  keepOldQuery?: boolean | undefined;
  state?: IKeyValueString | undefined;
};

const usePrepareLink = ({
  to,
  isRelativePath = false,
  query = {},
  pushToQuery = {},
  hash,
  keepOldQuery = false,
  state = {},
}: PropsType) => {
  const location = useLocation();
  const { pathname: url } = useResolvedPath('');

  let pathname: string = '';

  if (url && isRelativePath) {
    pathname = url + to;
  } else {
    pathname = to || location.pathname;
  }

  const newQuery = useMemo(
    () =>
      keepOldQuery
        ? new URLSearchParams(location.search)
        : new URLSearchParams(),
    [keepOldQuery, location.search]
  );

  Object.entries(query).forEach(([key, value]) => {
    newQuery.set(key, String(value));
  });

  Object.entries(pushToQuery).forEach(([key, value]) => {
    const currentValue = newQuery.get(key);
    const splitValue = currentValue ? currentValue.split(',') : [];
    splitValue.push(String(value));

    newQuery.set(key, splitValue.join(''));
  });

  return useMemo(
    () => ({
      pathname: pathname.replace(/\/\//g, '/'),
      search: newQuery.toString() ? `?${newQuery.toString()}` : '',
      hash,
      state,
    }),
    [hash, newQuery, pathname, state]
  );
};

export default usePrepareLink;
