import { PATH_FRONTPAGE_TITLE } from 'services/pathConstants';
import { IIdentity } from '../../api/auth/IRegisterIdentity';
import { IPersonV2 } from 'services/interfaces/IPersonV2';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

const SELECT_KEY_VALUE = 'value';

export function extractFullNameFromIdentity(identity: IIdentity): string {
  return [identity.identity_first_name, identity.identity_last_name].join(' ');
}

export function extractFirstAndLastNameFromPerson(person: IPersonV2): string {
  const { first_name: firstName, last_name: lastName } = person;
  return `${firstName} ${lastName ?? ''}`;
}

const filterArrayByKeys = (
  currentArray: IClientSelectOptionV2[],
  newArray: IClientSelectOptionV2[]
): IClientSelectOptionV2[] => {
  const key = SELECT_KEY_VALUE;
  const newArrayValues = new Set(newArray.map((item) => item[key]));
  return currentArray.filter((item) => !newArrayValues.has(item[key]));
};

export const mergeArraysByProperty = (
  currentArray: IClientSelectOptionV2[],
  newArray: IClientSelectOptionV2[]
): IClientSelectOptionV2[] => {
  const filteredArray = filterArrayByKeys(currentArray, newArray);
  return [...filteredArray, ...newArray];
};

export const isUrlAbsolute = (url: string): boolean => {
  const isAbsolute = new RegExp('^([a-z]+://|//)', 'i');
  return isAbsolute.test(url);
};

export const frontpageTitle = (activeInstallation?: string): string => {
  return activeInstallation ? activeInstallation : PATH_FRONTPAGE_TITLE;
};

export const isObject = (prop: unknown) =>
  typeof prop === 'object' && prop !== null && !Array.isArray(prop);

export const isEmpty = (object: object): boolean => {
  return (
    object == null ||
    (Object.keys(object).length === 0 && object.constructor === Object)
  );
};
