import ButtonLink from 'components/controls/ButtonLink/ButtonLink';
import { FC, useState, memo } from 'react';
import findAndMakeLink from 'services/utils/findAndMakeLink/findAndMakeLink';
import styles from './index.module.scss';
import classNames from 'clsx';
const TABLE_CELL_TEXT_LIMIT = 200;

type PropsType = {
  str: string;
  limit?: number;
  author?: string;
};

export const DropdownText: FC<PropsType> = (props) => {
  const [openText, setOpenText] = useState<boolean>(false);

  const { str, limit = TABLE_CELL_TEXT_LIMIT, author } = props;

  const toggle = () => setOpenText(!openText);

  return (
    <div className={'d-flex flex-column pb-2'}>
      {author && <span className={styles.author}>{author} </span>}
      <span
        className={classNames('d-flex flex-column  text-wrap', styles.text)}
      >
        {!openText
          ? findAndMakeLink(str.substring(0, limit))
          : findAndMakeLink(str)}
      </span>
      {str.length > limit && (
        <ButtonLink
          text={!openText ? '...' : '↑'}
          handleClick={toggle}
          classNameCustom={styles.linkOpenText}
        />
      )}
    </div>
  );
};

export default memo(DropdownText);
