import {
  NotificationParentUiElement,
  notificationParentUiElementMap,
} from './IresponseGetNotifications';
import {
  PATH_EXTERNAL_REQUEST_EDIT,
  PATH_ORGANIZATIONS_ACCOUNTS_EDIT,
  PATH_TASK_EDIT,
} from '../../pathConstants';
import { makeLink } from '../../localPaths';
import { Link } from 'react-router-dom';
import { TValueOf } from 'services/utils/TSUtils/TValueOf';
import { tGetKeys } from 'services/utils/TSUtils/tGetKeys';
import { TParentUI } from './IresponseGetNotifications';

type LocationNameParentUiElement = {
  en: TParentUI;
  ru: TValueOf<typeof notificationParentUiElementMap>;
};

export const getNotificationLocation = (
  parentUi: NotificationParentUiElement
) => {
  //cannot be parentUi 'total' | 'export'
  type ParentUIKeys = TParentUI[];

  const parentUIKeys = tGetKeys(parentUi) as ParentUIKeys;

  const locationNameParentUiElement: LocationNameParentUiElement = {
    en: parentUIKeys[0] as TParentUI,
    ru: notificationParentUiElementMap[parentUIKeys[0]],
  };
  const id = parentUi[locationNameParentUiElement.en];

  return { locationNameParentUiElement, id };
};

export const getTextNotificationLocation = (
  parentUi: NotificationParentUiElement
): string => {
  const { locationNameParentUiElement, id } = getNotificationLocation(parentUi);
  const textMap: Record<TParentUI, string> = {
    internal_task: `${locationNameParentUiElement.ru} №${id}`,
    external_request: `${locationNameParentUiElement.ru} №${id}`,
    billing_account: `${locationNameParentUiElement.ru} №${id}`,
    export: `${locationNameParentUiElement.ru} №${id}`,
  };
  return textMap[locationNameParentUiElement.en];
};

const matchesInstallation = (
  activeInstallation: string,
  installation: string
): boolean => installation === activeInstallation;

export const extractGetLinkNotificationLocation =
  (activeInstallation: string) =>
  (parentUi: NotificationParentUiElement, installationName: string) => {
    const { locationNameParentUiElement, id } =
      getNotificationLocation(parentUi);
    const componentMap = {
      internal_task: matchesInstallation(
        activeInstallation,
        installationName
      ) ? (
        <Link to={makeLink(PATH_TASK_EDIT, id)}>
          {locationNameParentUiElement.ru} №{id}
        </Link>
      ) : (
        <span>
          {locationNameParentUiElement.ru} №{id}
        </span>
      ),
      external_request: matchesInstallation(
        activeInstallation,
        installationName
      ) ? (
        <Link to={makeLink(PATH_EXTERNAL_REQUEST_EDIT, id)}>
          {locationNameParentUiElement.ru} №{id}
        </Link>
      ) : (
        <span>
          {locationNameParentUiElement.ru} №{id}
        </span>
      ),
      billing_account: (
        <Link to={makeLink(PATH_ORGANIZATIONS_ACCOUNTS_EDIT, id)}>
          {locationNameParentUiElement.ru} №{id}
        </Link>
      ),
      export: locationNameParentUiElement.ru,
    };

    return componentMap[locationNameParentUiElement.en];
  };
