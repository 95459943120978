import { Button, Label } from 'reactstrap';
import styles from './index.module.scss';
import { t } from '../../../../services/utils/translation';
import SelectFieldAdvanced from 'components/controls/SelectFieldColors';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { IHandlerExternalRequestFormProps } from '../types/IHandlerExternalRequestFormProps';
import { targetAccessPolicyExternalRequest } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyExternalRequest';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { IStatusControlExternalRequestState } from '../types/IStateExternalRequestForm';
import ColoredStatusLabel from 'components/misc/ColoredStatusLabel';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { ReactComponent as ArchiveIcon } from 'img/icons/icon-layers.svg';
import { FC } from 'react';
import { doPrunedExternalRequestApiV2 } from 'services/api/external-request/external-request/externalRequestApi';
import { usePostV3 } from 'hooks/usePostV3';
import { IFormValuesExternalRequestFromTask } from '../../../../pages/external-request/external-requests/ExternalRequestFromTask/ExternalRequestFromTask';
import ExternalRequest from '../../../../models/ExternalRequest';
import { useFormikContext } from 'formik';

interface PropsTypeExternalExternalRequestThirdSection {
  handlerFormProps: Omit<IHandlerExternalRequestFormProps, 'handleChange'> & {
    refreshExternalRequest(): void;
    handlerStatusSelect(status: IClientSelectOptionV2): void;
  };
  state: IStatusControlExternalRequestState;
}

const ExternalRequestStatusControl: FC<
  PropsTypeExternalExternalRequestThirdSection
> = (props) => {
  const {
    handlerFormProps: {
      saveValueInStoreForExternalRequest,
      hasChange,
      refreshExternalRequest,
      handlerStatusSelect,
    },
    state: {
      isDisable,
      initialValues: { initialStatus },
      statuses,
      isSubmitting,
    },
  } = props;

  const { values, errors, handleBlur, setFieldValue } = useFormikContext<
    IFormValuesExternalRequestFromTask | ExternalRequest
  >();

  const { status, pk } = values;

  const [haveAccessPolicyToArchive] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyExternalRequest,
    targetAction: ['A'],
  });

  const doPrunedExternalRequest = usePostV3({
    fetchApi: doPrunedExternalRequestApiV2,
    viewClientTextToast: true,
    successMessage: 'Заявка перенесена в архив',
  });

  const archiveExternalRequestHandler = async (pk: number) => {
    await doPrunedExternalRequest({ pk });
    refreshExternalRequest && refreshExternalRequest();
  };

  return (
    <>
      <Label required={true} for="status">
        {t('Перевести в статус')}
      </Label>
      <div className="d-flex ">
        <div className="mr-2 d-flex flex-row">
          <div>
            <ColoredStatusLabel
              className={styles.statusFieldColoredLabel}
              color={statuses.currentStatus?.color}
            >
              {statuses.currentStatus?.name}
            </ColoredStatusLabel>
          </div>
          {pk && haveAccessPolicyToArchive && (
            <div className="ml-4">
              <Button
                id="toArchiveButton"
                color="warning"
                onClick={async () =>
                  await archiveExternalRequestHandler(pk as number)
                }
                disabled={isSubmitting || isDisable}
              >
                <ArchiveIcon />
                <ToolTipWrapper target="toArchiveButton" placement="right">
                  {t('В архив')}
                </ToolTipWrapper>
              </Button>
            </div>
          )}
        </div>

        <div className="d-flex flex-column justify-content-center flex-grow-1">
          <SelectFieldAdvanced
            id="status"
            placeholder={t('Выберите новый статус...')}
            hasError={errors.status != null}
            onBlur={handleBlur}
            onChange={async (value: IClientSelectOptionV2<number>) => {
              await setFieldValue('status', value ? value : null, false);
              handlerStatusSelect(value);
              saveValueInStoreForExternalRequest({
                value: { status: value },
              });
            }}
            options={statuses?.availableStatuses}
            isClearable={true}
            disabled={isDisable}
            required={true}
            additionalErrorKey="external_request_status"
            highlightEditing={hasChange(initialStatus?.value, status?.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ExternalRequestStatusControl;
