import React, { FC, useMemo } from 'react';
import { Container, Table, Label, Row, Fade } from 'reactstrap';
import { getNotificationsApi } from 'services/api/notification/notificationApi';
import { t } from '../../services/utils/translation';
import config from 'config.json';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import classNames from 'clsx';
import styles from './index.module.scss';
import { useGetListOld } from 'hooks/useGetListOld';
import Pager from 'components/tables/Pager';
import LoaderFetch from '../../components/layouts/LoaderFetch/LoaderFetch';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { useSelector } from 'react-redux';
import { ReactComponent as IsReadIcon } from 'img/icons/icon-email-3.svg';
import { CSSTransition } from 'react-transition-group';
import transition from './transition.module.scss';
import { ButtonRadios } from '../../components/controls/ButtonRadios';
import { IRequestGetNotifications } from '../../services/api/notification/IRequestGetNotifications';
import { extractGetLinkNotificationLocation } from '../../services/api/notification/utils';
import ButtonLink from 'components/controls/ButtonLink/ButtonLink';
import { getIdentityFromStore } from '../../store/auth/selectors';
import useHandleNotificationPayload from './hooks/useHandlePayload';
import useMarkNotificationsAsRead from './hooks/useMarkToRead';
import { getActiveInstallationName } from 'store/general/selectors';
import toNotificationForList from './utils/toNotificationForList/toNotificationForList';
import INotificationForList from './utils/toNotificationForList/INotificationForList';

const iS_READ_TO_FILTER_MAP = {
  readOnly: true,
  onlyUnread: false,
  all: null,
};

const FILTER_ITEMS = [
  { value: 'all', label: 'Все' },
  {
    value: 'readOnly',
    label: 'Прочитанные',
  },
  {
    value: 'onlyUnread',
    label: 'Непрочитанные',
  },
];

const findActiveElement = (isReactedTo: boolean | null | undefined): string => {
  const targetValue = Object.entries(iS_READ_TO_FILTER_MAP).find(
    (el: [string, boolean | null | undefined]) => el.includes(isReactedTo)
  )?.[0];
  return targetValue ? targetValue : 'all';
};

const NotificationsPage: FC = () => {
  const identityPk = useSelector(getIdentityFromStore).identity_pk;

  const initialParams: IRequestGetNotifications = useMemo(
    () => ({
      skip: 0,
      length: config.pageLength,
      identityPk,
      params: { column_name_to_value: { notification_is_reacted_to: null } },
    }),
    [identityPk]
  );

  const activeInstallation = useSelector(getActiveInstallationName);

  const {
    data: notifications,
    setStart,
    setLength,
    isLoading,
    total,
    params,
    doRefresh,
    onSearchRequest,
  } = useGetListOld({
    getDataApi: getNotificationsApi,
    initialParams,
    delayRequest: false,
    convertData: toNotificationForList,
  });

  const {
    length,
    skip,
    params: {
      column_name_to_value: { notification_is_reacted_to },
    },
  } = params;

  const {
    markedOneAsRead,
    markedAllAsRead,
    isLoadingMarkToReact,
    counterUnreadNotifications,
  } = useMarkNotificationsAsRead(notifications, doRefresh);

  const getLinkNotificationLocation =
    extractGetLinkNotificationLocation(activeInstallation);

  const [handleClickDownload, copyText] = useHandleNotificationPayload();

  return (
    <MainLayout>
      <Container
        fluid
        className={classNames(styles.tableContainer, {
          [styles.cursorWait]: isLoadingMarkToReact,
        })}
      >
        <Row className="d-flex flex-row justify-content-between pt-3 pt-1">
          <Label className={classNames(styles.tableHeader)}>
            {t('Уведомления')}
          </Label>
          <CSSTransition
            in={counterUnreadNotifications > 0}
            timeout={600}
            classNames={transition}
            appear
            unmountOnExit
          >
            <div className="d-flex">
              <SubheaderButton
                className={classNames(styles.subHeaderButton)}
                bordered={false}
                onClick={async () => await markedAllAsRead()}
              >
                <IsReadIcon
                  className={classNames('mr-1', styles.markedSendButtonIcon)}
                  id="IsReadIcon"
                />
              </SubheaderButton>
              <Fade
                timeout={500}
                in={counterUnreadNotifications > 0}
                tag="span"
              >
                {t('Прочитать')} {counterUnreadNotifications}
              </Fade>
            </div>
          </CSSTransition>
        </Row>
        <Row>
          <Table className="table table-bordered  table-hover table-responsive-md">
            {!isLoading && (
              <tbody>
                {notifications?.length > 0 ? (
                  notifications.map((notification: INotificationForList) => {
                    const {
                      notification_pk: pk,
                      notification_parent_ui_element: parentUiElement,
                      notification_text: text,
                      notification_is_reacted_to: isRead,
                      notification_payload,
                      notification_source_installation: sourceInstallation,
                      clientDateTime,
                      isReadText,
                    } = notification;
                    return (
                      <tr
                        key={pk}
                        className={classNames({
                          [styles['is-loading-notification-tr']]:
                            isLoadingMarkToReact && !isRead,
                          [styles['is-unread-notification-tr']]: !isRead,
                          [styles['is-read-notification-tr']]: isRead,
                        })}
                        onClick={async () =>
                          !isRead && (await markedOneAsRead([pk]))
                        }
                      >
                        <td style={{ width: '8%' }}>
                          <span>{clientDateTime}</span>
                        </td>
                        <td style={{ width: '50%' }}>
                          <span>{text}</span>
                          {notification_payload && (
                            <>
                              <br />
                              <div className="d-flex-row">
                                <ButtonLink
                                  classNameCustom={classNames(
                                    'mt-3',
                                    styles.linkPayload
                                  )}
                                  classNameText={classNames(
                                    'font-weight-light'
                                  )}
                                  handleClick={() =>
                                    handleClickDownload(notification_payload)
                                  }
                                  text={t('Скачать файл')}
                                />
                                <ButtonLink
                                  classNameCustom={classNames(
                                    'mt-3 pl-3',
                                    styles.linkPayload
                                  )}
                                  classNameText="font-weight-light"
                                  handleClick={() => {
                                    copyText(notification_payload);
                                  }}
                                  text={t('Скопировать ссылку')}
                                />
                              </div>
                            </>
                          )}
                        </td>
                        <td style={{ width: '10%' }}>
                          {getLinkNotificationLocation(
                            parentUiElement,
                            sourceInstallation
                          )}
                          <div className="pt-2">
                            <small>{sourceInstallation}</small>
                          </div>
                        </td>

                        <td style={{ width: '10%' }}>
                          <span>{isReadText}</span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>{t('Уведомления отсутствуют')}</td>
                  </tr>
                )}
              </tbody>
            )}
          </Table>

          {isLoading && <LoaderFetch />}
        </Row>
        <Row className="d-flex justify-content-between mt-2">
          <Pager
            onPageChange={setStart}
            onLengthChange={setLength}
            total={total}
            length={length}
            start={skip}
          />
          <ButtonRadios
            items={FILTER_ITEMS}
            checkedItem={findActiveElement(notification_is_reacted_to)}
            name="importType"
            onChange={(targetRadio: keyof typeof iS_READ_TO_FILTER_MAP) => {
              onSearchRequest(
                {
                  column_name_to_value: {
                    notification_is_reacted_to:
                      iS_READ_TO_FILTER_MAP[targetRadio],
                  },
                },
                'params'
              );
            }}
          />
        </Row>
      </Container>
    </MainLayout>
  );
};

export default NotificationsPage;
