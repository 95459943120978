import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import { ICreateFileBucketSchemaParams } from '../../services/api/documents/file-bucket-schema/ICreateFileBucketSchema';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

class FileBucketSchema implements ICreateFileBucketSchemaParams {
  public pk?: number;
  public file_bucket_schema_author_id: number;
  public file_bucket_schema_name: string = '';
  public file_bucket_schema_description?: string;
  public access_policies?: number[];
  public accessPolicies?: IClientSelectOptionV2[];

  constructor(authorId: number, pk?: number) {
    this.file_bucket_schema_author_id = authorId;
    this.pk = pk;
  }
  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      file_bucket_schema_name: Yup.string()
        .required(t('Укажите название'))
        .min(1, t('Текст не может быть короче 1 символа.')),
    });
  };
}

export default FileBucketSchema;
