import { IControllerHistoryPayload } from 'services/api/controller/IControllerHistory';

const controllerConvertHistoryData = (data: IControllerHistoryPayload) => ({
  rows: data?.controller_list_of_edits,
  creationInfo: {
    controller_datetime_added: data?.controller_datetime_added,
    controller_author_dk: data?.controller_author_dk,
    controller_author: data?.controller_author,
  },
});

export default controllerConvertHistoryData;
