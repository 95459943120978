import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import UndercurrentApiClient from '../../UndercurrentApiClient';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import { IGetPaginatedTasksPayload } from './IGetPaginatedTasksPayload';
import {
  ITaskFilters,
  IGetPaginatedTasksParams,
} from './IGetPaginatedTasksParams';
import { IGetHistoryTaskPayload } from './IGetHistoryTaskPayload';
import { IUpdateTask } from './ICreateTask';
import { IFormValuesTask } from '../../../../components/tasks/TaskForm/TaskForm';
import IAddCommentTaskParams from './IAddCommentParams';
import IPrimaryKey from 'services/types/IPk';
import ApiActions from 'services/api/apiActions';
import { ITask, IWrapperTask } from './ITask';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { ISetParentTaskForTaskParams } from './ISetParentTaskForTaskParams';
import { IDeleteParentForTask } from './IDeleteParentForTask';
import { ISetParentExternalRequestForTask } from './ISetParentExternalRequestForTask';
import { createTaskParentUpdateApiMethod } from 'services/api/UndercurrentParentUpdateApiMethods';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.INTERNAL_TASK
  );

export const getPaginatedTasksApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedTasksParams | ITaskFilters>
): Promise<IBaseResponse<IGetPaginatedTasksPayload>> =>
  api().getPaginated(filterParams);

export const readTaskApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IWrapperTask>> => api().read(params);

export const createTaskV2 = async (
  params: IBaseParams<IFormValuesTask>
): Promise<IBaseResponse<IWrapperTask>> => api().create(params);

export const updateTaskV2 = async (
  params: IBaseParams<IUpdateTask>
): Promise<IBaseResponse<IWrapperTask>> => api().update(params);

export const addTaskCommentApiV2 = async (
  params: IBaseParams<IAddCommentTaskParams>
): Promise<IBaseResponse<IWrapperTask>> => api().addComment(params);

export const getCommentsTaskV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IGetCommentsPayloadV2>> => api().getComments(params);

export const getHistoryTask = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<IGetHistoryTaskPayload>> => api().getHistory(params);

export const exportTaskApiV2 = async (
  filterParams: IBaseParams<ITaskFilters>
): Promise<IBaseResponse<string>> => api().export(filterParams);

export const doPrunedTaskApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<ITask>> => api().doUpdate(params, ApiActions.PRUNE);

export const doCloseTaskApiV2 = async (
  params: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<ITask>> => api().doUpdate(params, ApiActions.CLOSE);

export const getTaskForSelectApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const setParentTaskForTaskApi = async (
  params: IBaseParams<ISetParentTaskForTaskParams>
): Promise<IBaseResponse<IWrapperTask>> =>
  createTaskParentUpdateApiMethod().addParent(params);

export const setParentExternalRequestForTaskApi = async (
  params: IBaseParams<ISetParentExternalRequestForTask>
): Promise<IBaseResponse<IWrapperTask>> => api().update(params);

export const deleteParentExternalRequestForTaskApi = async (
  params: IBaseParams<IDeleteParentForTask>
): Promise<IBaseResponse<IWrapperTask>> => api().update(params);
