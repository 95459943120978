import classNames from 'clsx';
import styles from './index.module.scss';
import { FC, memo } from 'react';
import { IKeyValue } from 'services/interfaces/IKeyValue';

type SubHeaderDetailsItemPropsType = {
  items: IKeyValue<string | JSX.Element>;
  className?: string;
};
const SubHeaderDetailsItem: FC<SubHeaderDetailsItemPropsType> = (props) => {
  const { items, className } = props;
  return (
    <>
      {Object.keys(items).map((item: string) => (
        <div
          className={classNames(
            className,
            'px-4 py-2 mr-2 d-flex flex-column justify-content-center'
          )}
          key={item}
        >
          <div className={classNames(styles.title, 'mb-2')}>{item}</div>
          <div className={classNames(styles.value)}>{items[item]}</div>
        </div>
      ))}
    </>
  );
};

export default memo(SubHeaderDetailsItem);
