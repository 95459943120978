import React, { FC, memo } from 'react';

import { parseISO } from 'date-fns';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import HistoryRows from 'components/misc/WrapperHistoryRows/HistoryRows';
import HistoryContainerTable from 'components/tables/HistoryContaierTable/HistoryContainerTable';
import {
  ICounterpartyListOfHistoryEntity,
  ICreationCounterpartyInfo,
} from '../../../../services/api/organizationsV2/counterparty/IHistoryCounterparty';
import { formatDateTime } from "../../../../services/utils/dateHelper/dateHelper";

type PropTypes = {
  counterpartyRequestHistory?: ICounterpartyListOfHistoryEntity[];
  creationCounterpartyInfo?: ICreationCounterpartyInfo;
  isLoading?: boolean;
};

// delete duplicate - create component container for history
const CounterpartyHistory: FC<PropTypes> = (props) => {
  const { counterpartyRequestHistory, isLoading, creationCounterpartyInfo } =
    props;
  return (
    <HistoryContainerTable>
      {isLoading && <LoaderFetch />}
      {counterpartyRequestHistory &&
        counterpartyRequestHistory?.length > 0 &&
        !isLoading &&
        counterpartyRequestHistory.map(
          (record: ICounterpartyListOfHistoryEntity) => {
            return (
              !!record?.counterparty_edited_column_to_old_and_new_values && (
                <HistoryRows
                  editorFullName={record?.counterparty_editor?.full_name}
                  valuesRaw={
                    record.counterparty_edited_column_to_old_and_new_values
                  }
                  dateTimeEdit={record?.counterparty_datetime_edited}
                />
              )
            );
          }
        )}
      {creationCounterpartyInfo?.counterparty_datetime_added &&
        creationCounterpartyInfo?.counterparty_author_dk && (
          <tr>
            <td>
              {formatDateTime(
                parseISO(creationCounterpartyInfo?.counterparty_datetime_added)
              )}
            </td>
            <td colSpan={4}>{'Cоздание'}</td>
            <td>{creationCounterpartyInfo?.counterparty_author?.full_name}</td>
          </tr>
        )}
    </HistoryContainerTable>
  );
};

export default memo(CounterpartyHistory);
