import { useCallback, useEffect, useState } from 'react';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';

import useGetFetch from './useGetFetch';
import useGetPrevValue from './useGetPrevValue';
import { isEqual } from 'lodash';

interface IUseReadProps<Payload, ConvertData, Params> {
  getDataApi: (params: IBaseParams<Params>) => Promise<IBaseResponse<Payload>>;
  convertData: (data: Payload) => ConvertData;
  initialData: ConvertData;
  params: Params;
  condition?: boolean;
  errorMessage?: string;
  refresh?: boolean;
  successHandler?: () => void;
  isNotComparePrevParams?: boolean;
}

export interface IUseGetRefreshingData<ConvertData> {
  data: ConvertData;
  isLoading: boolean;
  setData: Function;
  refreshData: () => void;
}

export function useGetRefreshingData<Payload, ConvertData, Params>(
  props: IUseReadProps<Payload, ConvertData, Params>
): IUseGetRefreshingData<ConvertData> {
  const {
    params,
    getDataApi,
    convertData,
    initialData,
    condition = true,
    errorMessage = 'Не удалось загрузить данные',
    successHandler,
    isNotComparePrevParams = false,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ConvertData | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  const getData = useGetFetch<Payload, Params>({
    getDataApi,
    params,
    errorMessage,
    successHandler,
  });

  const refreshData = () => setRefresh((prevState: boolean) => !prevState);

  const processingRequest = useCallback(async () => {
    setIsLoading(true);

    const payload: Payload | undefined = await getData();
    payload && setData(convertData(payload));

    setIsLoading(false);
  }, [convertData, getData]);

  const prevParams = useGetPrevValue(params);

  const hasNewParams =
    (prevParams && !isEqual(prevParams, params)) || isNotComparePrevParams;

  const isRequestCanBeSent =
    (!isEqual(useGetPrevValue(refresh), refresh) || hasNewParams) && condition;

  useEffect(() => {
    if (isRequestCanBeSent) {
      void processingRequest();
    }
  }, [condition, isRequestCanBeSent, processingRequest, params, refresh]);
  //TODO: return only data

  return { data: data ? data : initialData, isLoading, setData, refreshData };
}

export default useGetRefreshingData;
