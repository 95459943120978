import { ICreateEvent } from 'services/api/organizationsV2/event/ICreateEvent';
import { IFormValuesEvent } from '../../EventForm/EventForm';
import { IUpdateEvent } from 'services/api/organizationsV2/event/IUpdateEvent';
import { extractValueFromClientSelectOptionV2 } from 'services/utils/selects/selects';
import { isUpdateEvent } from '../isUpdateEvent/isUpdateEvent';
import deleteUnnecessaryParameters
    from "../../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters";

const EXTRA_EVENT_PARAMETERS = [
  'eventType',
  'eventBillingAccount',
  'eventIsClosed',
];

export const preprocessValuesEvent = (
  eventValues: IFormValuesEvent
): ICreateEvent | IUpdateEvent => {
  const nevValues = {
    ...eventValues,
    event_type_fk: extractValueFromClientSelectOptionV2(eventValues?.eventType),
    event_billing_account_fk: extractValueFromClientSelectOptionV2(
      eventValues?.eventBillingAccount
    ),
  };

  const newValues = deleteUnnecessaryParameters(
    nevValues,
    EXTRA_EVENT_PARAMETERS
  );
  return isUpdateEvent(newValues)
    ? (newValues as IUpdateEvent)
    : (newValues as ICreateEvent);
};
