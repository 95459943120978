import { useFormikContext } from 'formik';
import { IFormValuesController } from '../../ControllerForm';
import { FC, useEffect, useRef } from 'react';
import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { t } from 'services/utils/translation';
import BillingAccountDetailsSelect from 'components/controls/BillingAccountDetailsSelect/BillingAccountDetailsSelect';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { getForSelectBillingAccountApiV2 } from 'services/api/organizationsV2/billing-account/billingAccountApi';
import { useDispatch } from 'react-redux';
import { saveControllerFieldValue } from 'store/controller/actions';

type ControllerFileldsProps = {
  isNew: boolean;
  isViewOnly: boolean;
  isDefaultBillingAccount: boolean;
  permanentInitialValue?: IFormValuesController;
};

const ControllerFilelds: FC<ControllerFileldsProps> = (props) => {
  const { isNew, isViewOnly, isDefaultBillingAccount, permanentInitialValue } =
    props;

  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext<IFormValuesController>();

  const {
    controller_description: description,
    controller_pk: pk,
    controllerBillingAccount,
    controller_new_comment: newComment,
  } = values;

  // new Controller(undefined, controllerBillingAccount?.value);

  const valuesRef = useRef(values);

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  useEffect(() => {
    return () => {
      if (!!valuesRef.current.controller_pk) {
        dispatch(
          saveControllerFieldValue(valuesRef.current.controller_pk)(
            valuesRef.current
          )
        );
      }
    };
  }, [dispatch]);
  return (
    <div>
      {pk && !isNew && (
        <HiddenField
          id="controller_pk"
          hasError={
            errors.controller_pk != null && touched.controller_pk != null
          }
          value={pk}
        />
      )}
      {isNew && (
        <TextField
          type="text"
          id="controller_pk"
          label={t('Номер')}
          placeholder={t('Введите  номер контроллера...')}
          hasError={
            errors.controller_pk != null && touched.controller_pk != null
          }
          onBlur={handleBlur}
          onChange={handleChange}
          defaultValue={pk}
          required={true}
          disabled={isViewOnly}
          highlightEditing={permanentInitialValue?.controller_pk !== pk}
        />
      )}
      <TextField
        type="text"
        id="controller_description"
        label={t('Описание')}
        placeholder={t('Введите  описание контроллера...')}
        hasError={
          errors.controller_description != null &&
          touched.controller_description != null
        }
        onBlur={handleBlur}
        onChange={handleChange}
        defaultValue={description}
        disabled={isViewOnly}
        highlightEditing={
          permanentInitialValue?.controller_description !== description
        }
      />
      <BillingAccountDetailsSelect
        billingAccountPk={controllerBillingAccount?.value}
      >
        <DynamicSelectPaginatedFieldV2
          id="controllerBillingAccount"
          label={t('Лицевой счет')}
          placeholder={t('Выберите лицевой счет...')}
          hasError={
            (errors.controllerBillingAccount != null &&
              touched.controllerBillingAccount != null) ||
            (errors.controllerBillingAccount != null &&
              touched.controllerBillingAccount != null)
          }
          selectHandler={getForSelectBillingAccountApiV2}
          options={controllerBillingAccount}
          required={true}
          disabled={isViewOnly || isDefaultBillingAccount}
          highlightEditing={
            permanentInitialValue?.controllerBillingAccount?.value !==
            controllerBillingAccount?.value
          }
        />
      </BillingAccountDetailsSelect>
      {isNew && (
        <TextField
          id="controller_new_comment"
          type="textarea"
          label={t('Комментарий')}
          hasError={
            errors.controller_new_comment != null &&
            touched.controller_new_comment != null
          }
          onBlur={handleBlur}
          onChange={handleChange}
          value={newComment}
          disabled={isViewOnly}
        />
      )}
    </div>
  );
};

export default ControllerFilelds;
