import { t } from '../translation';

const getErrorMessage = (model: string) => {
  const CREATE = t('создать');
  const EDIT = t('отредактировать');
  const READ = t('Загрузить');
  const getFullErrorMessage = (action: string) =>
    t(`Не удалось ${action} ${model}. Пожалуйста, обратитесь к администратору`);
  return {
    errorMessageCreate: getFullErrorMessage(CREATE),
    errorMessageUpdate: getFullErrorMessage(EDIT),
    errorMessageRead: getFullErrorMessage(READ),
  };
};

export default getErrorMessage;
