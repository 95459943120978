import ExternalRequestSearch from 'components/external-request/ExternalRequestSearch/ExternalRequestSearch';
import TaskSearch from 'components/tasks/TaskSearch/TaskSearch';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import BillingAccountSearch from '../../organizations/billing-account/BillingAccountSearch/BillingAccountSearch';

// If you need to take not the first part of the url,
//generate this value through redux in the target component
const targetLocation = 1;

const SEARCH_MODULE_MAP = {
  organizations: BillingAccountSearch,
  'external-request-ensemble': ExternalRequestSearch,
  'internal-task-ensemble': TaskSearch,
};

const MainLayoutHeaderSearchWrapper: FC = () => {
  const location = useLocation();

  const patchName = useMemo(
    () => location.pathname.split('/')[targetLocation],
    [location.pathname]
  ) as keyof typeof SEARCH_MODULE_MAP;

  const SearchComponent: FC | undefined = SEARCH_MODULE_MAP[patchName];

  return <>{SearchComponent && <SearchComponent />}</>;
};

export default MainLayoutHeaderSearchWrapper;
