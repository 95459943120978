import { useFormikContext } from 'formik';
import findFileIndex from '../utils/findFileIndex';
import { extractChangeFileIsActive } from '../utils/makeFileInactive';
import { IDocumentFormValues } from '../IDocumentFormValues';
import DOCUMENT_UPLOADED_KEY from '../const/DOCUMENT_UPLOADED_KEY';
import IFileFormValues from '../IFileFormValues';
const useSetUploadedFilesFile = (isActive: boolean) => {
  const {
    setFieldValue,
    values: { uploadedFiles },
  } = useFormikContext<IDocumentFormValues>();
  const changeFileIsActive = extractChangeFileIsActive(isActive);

  return async (file: IFileFormValues) => {
    const indexTargetFile = findFileIndex(file, uploadedFiles);
    const newValues = changeFileIsActive(uploadedFiles, indexTargetFile);
    await setFieldValue(DOCUMENT_UPLOADED_KEY, newValues);
  };
};

export default useSetUploadedFilesFile;
