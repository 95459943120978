import styles from './index.module.scss';
import { FC, ReactElement, useCallback, useState } from 'react';
import { ReactComponent as ToggleSquareRightIcon } from 'img/icons/icon-toggle-square-rigth.svg';
import { ReactComponent as ToggleSquareLeftIcon } from 'img/icons/icon-toggle-square-left.svg';

import classNames from 'clsx';
import useSaveIsActiveFirst from './useSaveIsActiveFirst';
import Circle, { CIRCLE_COLOR } from 'components/misc/Circle/Circle';
import { isObject } from '../../../services/utils/misc/misc';
import ToolTipWrapper from '../../controls/ToolTipWrapper/ToolTipWrapper';
import { CIRCLE_SIZE } from '../../misc/Circle/Circle';

const isEmpty = (prop: any) => {
  const objectValue = isObject(prop) ? Object.keys(prop).length <= 0 : false;
  return (
    prop === '' ||
    prop === undefined ||
    prop === null ||
    prop?.length === 0 ||
    objectValue
  );
};
interface IComponentSettings {
  value?: any;
  hint?: string;
  name: string;
}

type DoubleHeaderFilterContainerPropsType = {
  handleClick?: () => void;
  children: [ReactElement, ReactElement];
  isFirstIsVisibleProp?: boolean;
  saveIsActive?: (isActive: boolean) => void;
  componentSettings: {
    first: IComponentSettings;
    second: IComponentSettings;
  };
  isDisabled?: boolean;
};

export const DoubleHeaderFilterContainer: FC<
  DoubleHeaderFilterContainerPropsType
> = (props) => {
  const {
    children,
    handleClick,
    saveIsActive: cleaner,
    isFirstIsVisibleProp = true,
    componentSettings,
    isDisabled,
  } = props;
  const {
    first: { value: valueFirst, hint: hintFirst, name: nameFirst },
    second: { value: valueSecond, hint: hintSecond, name: nameSecond },
  } = componentSettings;

  const [isFirstIsVisible, setIsFirstIsVisible] =
    useState<boolean>(isFirstIsVisibleProp);

  const [firstFilter, secondFilter] = children;

  useSaveIsActiveFirst(isFirstIsVisible, cleaner);

  const handleToggle = useCallback(() => {
    setIsFirstIsVisible(!isFirstIsVisible);
    handleClick && handleClick();
  }, [handleClick, isFirstIsVisible]);

  const isVisibleFirstCircle = !isFirstIsVisible && !isEmpty(valueFirst);
  const isVisibleSecondCircle = isFirstIsVisible && !isEmpty(valueSecond);

  const firstId = `tooltip-first-${nameFirst}`;
  const secondId = `tooltip-second-${nameSecond}`;

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className={styles.container}>
        {isVisibleFirstCircle && (
          <Circle
            id={firstId}
            color={CIRCLE_COLOR.CITY_BLUE}
            isInvisible={isFirstIsVisible}
            className={styles['top-left']}
            size={CIRCLE_SIZE.S}
          />
        )}

        {isVisibleSecondCircle && (
          <Circle
            id={secondId}
            color={CIRCLE_COLOR.CITY_BLUE}
            isInvisible={!isFirstIsVisible}
            className={styles['top-right']}
            size={CIRCLE_SIZE.S}
          />
        )}

        <button
          onClick={handleToggle}
          className={classNames(
            styles.toggle,
            styles.center,
            'd-flex flex-column justify-content-center'
          )}
          disabled={isDisabled}
        >
          {isFirstIsVisible ? (
            <ToggleSquareLeftIcon className={classNames(styles.buttonIcon)} />
          ) : (
            <ToggleSquareRightIcon className={classNames(styles.buttonIcon)} />
          )}
        </button>
      </div>
      {isFirstIsVisible ? (
        <div
          className={classNames(styles.filterContainer)}
          key={componentSettings.first.name}
        >
          {firstFilter}
        </div>
      ) : (
        <div
          className={classNames(styles.filterContainer)}
          key={componentSettings.second.name}
        >
          {secondFilter}
        </div>
      )}
      {hintFirst && isVisibleFirstCircle && (
        <ToolTipWrapper target={firstId} placement="top">
          {hintFirst}
        </ToolTipWrapper>
      )}
      {hintSecond && isVisibleSecondCircle && (
        <ToolTipWrapper target={secondId} placement="top">
          {hintSecond}
        </ToolTipWrapper>
      )}
    </div>
  );
};
export default DoubleHeaderFilterContainer;
