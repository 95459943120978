import classNames from 'clsx';
import { getColorsTrForTicket } from 'components/tables/misc/colorTr';
import { FC, useCallback, useRef, useState } from 'react';
import { ITaskForList } from 'services/api/tasks/tasks/IGetPaginatedTasksPayload';
import styles from './index.module.scss';
import { getTaskMarkedFromStore } from 'store/task/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setMarkedTask } from 'store/task/actions';
import CellWithPkTask from '../CellWithPkTask/CellWithPkTask';
import { ColumnVisibility } from 'hooks/useColumnFilter';
import TDCollapse from 'components/tables/TdCollapse/TDCollapse';
import DropdownText from 'components/tables/DropdownText/DropdownText';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { getDropDownEditTitle } from 'services/utils/extractGetLeftValueIfTrue';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyTask } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import useNextWindow from 'hooks/useNextWindow';
import {
  PATH_EXTERNAL_REQUEST_FROM_TASK,
  PATH_TASK_CREATE_CHILD_TICKET,
  PATH_TASK_EDIT,
} from 'services/pathConstants';
import { ReactComponent as ArchiveIcon } from 'img/icons/icon-layers.svg';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as PrintIcon } from 'img/icons/icon-printer.svg';
import { ReactComponent as CloseIcon } from 'img/icons/icon-inbox-1.svg';
import { ReactComponent as AddIcon } from 'img/icons/icon-plus.svg';
import { t } from 'services/utils/translation';
import {
  doCloseTaskApiV2,
  doPrunedTaskApiV2,
  getHistoryTask,
  readTaskApiV2,
} from 'services/api/tasks/tasks/tasksApiV2';
import createTimestamp from 'services/utils/createTimestamp/createTimestamp';
import {
  catchErrorFromFetch,
  catchErrorsIfServerStatusFalse,
} from 'services/utils/catchAndRegError/catchAndRegError';
import { ITask } from 'services/api/tasks/tasks/ITask';
import { IGetHistoryTaskPayload } from 'services/api/tasks/tasks/IGetHistoryTaskPayload';
import { useReactToPrint } from 'react-to-print';
import { usePostV3 } from 'hooks/usePostV3';
import { useLocation } from 'react-router-dom';
import TaskPrintDetailsPage from 'pages/internal-task/task/TaskPrintDetailsPage/TaskPrintDetailsPage';
import { columnVisibilityInitialTask } from '../../columnTasks';

type PropsTypeTaskList = {
  taskList?: ITaskForList[];
  visibility: ColumnVisibility<typeof columnVisibilityInitialTask>;
  toggleComments: (id: number) => void;
  refresh: () => void;
  isLoading: boolean;
  isDisabledDoubleFilterCounterpartyAndBillingAccoount: boolean;
};

const TasksList: FC<PropsTypeTaskList> = (props) => {
  const {
    taskList,
    visibility,
    toggleComments,
    refresh,
    isLoading,
    isDisabledDoubleFilterCounterpartyAndBillingAccoount,
  } = props;
  const [taskDetails, setTaskDetails] = useState<ITask | null>(null);
  const [taskHistory, setTaskHistory] = useState<IGetHistoryTaskPayload | null>(
    null
  );
  const marked = useSelector(getTaskMarkedFromStore);
  const dispatchRedux = useDispatch();
  const doCloseTask = usePostV3({
    fetchApi: doCloseTaskApiV2,
    viewClientTextToast: true,
    successMessage: 'Задача закрыта',
  });

  const doPrunedTask = usePostV3({
    fetchApi: doPrunedTaskApiV2,
    viewClientTextToast: true,
    successMessage: 'Задача перенесена в архив',
  });

  const archiveTaskHandler = async (pk: number) => {
    await doPrunedTask({ pk });
    refresh();
  };

  const closeTaskHandler = async (pk: number) => {
    await doCloseTask({ pk });
    refresh();
  };

  const taskDetailsPrintRef = useRef<HTMLDivElement>(null);

  const toPrintDetails = useReactToPrint({
    content: () => taskDetailsPrintRef.current,
    documentTitle: `Задача`,
    onAfterPrint: () => setTaskDetails(null),
  });

  const getTicketDetailsData = useCallback(async (pk: number) => {
    const errorMessage = t(
      'Невозможно загрузить данные. Пожалуйста, свяжитесь с администратором.'
    );
    try {
      const response = await readTaskApiV2({
        params: { pk },
        id: createTimestamp(),
      });
      const responseHistory = await getHistoryTask({
        params: { pk },
        id: createTimestamp(),
      });
      const {
        result: { outcome, payload, verbose, develop },
      } = response;
      const {
        result: {
          outcome: outcomeHistory,
          payload: payloadHistory,
          // verbose: verboseHistory,
          // develop: developHistory,
        },
      } = responseHistory;
      if (
        outcome === 'WARNING' ||
        !payload ||
        outcomeHistory === 'WARNING' ||
        !payloadHistory
      ) {
        catchErrorsIfServerStatusFalse(
          verbose ? verbose : errorMessage,
          develop
        );
        return;
      }

      setTaskDetails(payload.internal_task);
      setTaskHistory(payloadHistory);
    } catch (error) {
      catchErrorFromFetch(
        t('Не удалось загрузить данные. Обратитесь к администратору.'),
        error
      );
      return;
    }
  }, []);

  const handlePrintDetails = async (id: number) => {
    await getTicketDetailsData(id);
    toPrintDetails && toPrintDetails();
  };

  const [haveAccessToArchive, haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyTask,
    targetAction: ['A', 'U', 'C', 'E'],
  });

  const location = useLocation();

  const nextWindowChildrenTask = useNextWindow(
    PATH_TASK_CREATE_CHILD_TICKET,
    location.pathname
  );
  const nextWindowCreateChildrenExternalRequest = useNextWindow(
    PATH_EXTERNAL_REQUEST_FROM_TASK,
    location.pathname
  );

  const nextWindowTaskEditPage = useNextWindow(
    PATH_TASK_EDIT,
    location.pathname
  );

  return (
    <>
      {taskList?.map((task: ITaskForList) => {
        const {
          internal_task_author: author,
          pk,
          billingAccountAndCounterparty,
          internal_task_description: description,
          commentElements,
          internal_task_status: status,
          statusLabel,
          internal_task_type: type,
          internal_task_supervisor: supervisor,
          // internal_task_billing_account: billing_account,
          openComments,
          internal_task_is_pruned: isPruned,
          isPrunedText,
          duedate,
          createDate,
          editedDate,
          parentAndChildExternalRequestLinks,
          parentAndChildTaskLinks,
          isBeforeCurrentDate,
        } = task;
        return (
          <tr
            key={pk.toString()}
            className={classNames(
              `table-${getColorsTrForTicket(
                isPruned,
                status?.internal_task_status_color
              )}`,
              {
                [styles.trActive]:
                  marked.includes(pk) &&
                  status?.internal_task_status_color === '#FFFFFF',
                [styles.trActiveColor]: marked.includes(pk),
                [styles.trArchive]: isPruned,
              }
            )}
            onClick={(event: any) => {
              if (event.target.tagName === 'TD') {
                dispatchRedux(setMarkedTask(pk));
              }
            }}
          >
            <td className={classNames({ 'd-none': !visibility.pk })}>
              <CellWithPkTask pk={pk} />
            </td>
            <td
              className={classNames({
                'd-none': !visibility.author,
              })}
            >
              {author?.full_name}
            </td>
            <td
              className={classNames({
                'd-none': !visibility.supervisor,
              })}
            >
              {supervisor?.full_name}
            </td>
            {!isDisabledDoubleFilterCounterpartyAndBillingAccoount && (
              <td
                className={classNames({
                  'd-none': !visibility.accountAndCounterparty,
                })}
              >
                {billingAccountAndCounterparty}
              </td>
            )}
            <td className={classNames({ 'd-none': !visibility.type })}>
              {type?.internal_task_type_rendition
                ? type?.internal_task_type_rendition
                : '-'}
            </td>
            <td
              className={classNames({
                'd-none': !visibility.internal_task_status,
              })}
            >
              {statusLabel}
            </td>
            <td
              className={classNames({
                'd-none': !visibility.is_pruned,
              })}
            >
              {isPrunedText}
            </td>
            <td
              className={classNames({
                'd-none': !visibility.editedDate,
              })}
            >
              <span>{editedDate}</span>
            </td>
            <td
              className={classNames({
                'd-none': !visibility.createDate,
              })}
            >
              <span>{createDate}</span>
            </td>
            <td
              className={classNames({
                'd-none': !visibility.duedate,
              })}
            >
              <span>{duedate}</span>
            </td>
            <td>{description && <DropdownText str={description} />}</td>
            <td
              className={classNames({
                'd-none': !visibility.parentTask,
              })}
            >
              {parentAndChildTaskLinks}
            </td>
            <td
              className={classNames({
                'd-none': !visibility.parentExternalRequest,
              })}
            >
              {parentAndChildExternalRequestLinks}
            </td>
            <TDCollapse
              visibility={visibility.comment}
              openList={openComments}
              entity={commentElements}
              toggleTd={toggleComments}
              entityId={pk}
            />
            <td align="center">
              <ActionsDropdown>
                <ActionsDropdownItem
                  label={getDropDownEditTitle(!isPruned && haveAccessToUpdate)}
                  onClick={nextWindowTaskEditPage.bind(null, pk)}
                  icon={<EditIcon className="text-primary" />}
                />
                <ActionsDropdownItem
                  label={t('Создать подзадачу')}
                  onClick={nextWindowChildrenTask.bind(null, pk)}
                  icon={<AddIcon className="text-primary" />}
                />
                <ActionsDropdownItem
                  label={t('Создать подзаявку')}
                  onClick={nextWindowCreateChildrenExternalRequest.bind(
                    null,
                    pk
                  )}
                  icon={<AddIcon className="text-primary" />}
                />
                <ActionsDropdownItem
                  label={t('Распечатать')}
                  onClick={() => handlePrintDetails(pk)}
                  icon={
                    <PrintIcon
                      style={{ width: '18px', height: '18px' }}
                      className="text-primary"
                    />
                  }
                />
                {!isPruned && !isBeforeCurrentDate && (
                  <ActionsDropdownItem
                    label={t('Закрыть')}
                    onClick={closeTaskHandler.bind(null, pk)}
                    icon={<CloseIcon className="text-primary" />}
                  />
                )}
                {haveAccessToArchive && !isPruned && (
                  <ActionsDropdownItem
                    label={t('В архив')}
                    onClick={archiveTaskHandler.bind(null, pk)}
                    icon={<ArchiveIcon className="text-primary" />}
                  />
                )}
              </ActionsDropdown>
            </td>
          </tr>
        );
      })}

      {!isLoading && taskList?.length === 0 && (
        <tr>
          <td colSpan={10}>{t('Задачи отсутствуют')}</td>
        </tr>
      )}
      {taskDetails && (
        <div ref={taskDetailsPrintRef} className={styles['print-source']}>
          <TaskPrintDetailsPage
            taskDetails={taskDetails}
            taskHistory={taskHistory}
          />
        </div>
      )}
    </>
  );
};

export default TasksList;
