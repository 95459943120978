import { Link } from 'react-router-dom';
import {
  IBooking,
  IBookingForList,
} from 'services/api/vehicle/booking/IBooking';
import { makeLink } from 'services/localPaths';
import { PATH_EXTERNAL_REQUEST_EDIT } from 'services/pathConstants';
import { t } from 'services/utils/translation';

const toBookingForList = (payload: IBooking[]) => {
  const convert = (booking: IBooking): IBookingForList => {
    const {
      booking_vehicle: {
        vehicle_registration_plate: registrationPlate,
        vehicle_garage_code_number: garageCodeNumber,
      },
      // booking_period_start: periodStart,
      // booking_period_end: periodEnd,
      booking_external_request: externalRequest,
      booking_pk: bookingPk,
    } = booking;
    return {
      bookingPk,
      vehicleTdContentElement: (
        <>
          <span>{t('Номер машины')}: </span> <b>{registrationPlate}</b>
          {garageCodeNumber && (
            <>
              <br />
              <span>{t('Номер гаража')}: </span>
              <b>{garageCodeNumber}</b>
            </>
          )}
        </>
      ),
      // periodStart: periodStart && formatDateTimeShort(parseISO(periodStart)),
      // periodEnd: periodEnd && formatDateTimeShort(parseISO(periodEnd)),
      externalRequestLink: externalRequest && (
        <Link
          to={makeLink(
            PATH_EXTERNAL_REQUEST_EDIT,
            externalRequest.external_request_pk
          )}
        >
          №{externalRequest.external_request_pk}
          <br />
          {externalRequest.external_request_description}
        </Link>
      ),
    };
  };

  return payload.map(convert);
};

export default toBookingForList;
