import { FC, useMemo } from 'react';
import config from '../../../../config.json';
import { IUseGetList, useGetList } from '../../../../hooks/useGetList';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { t } from 'services/utils/translation';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import { useColumnFilter } from '../../../../hooks/useColumnFilter';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import LoaderFetch from '../../../../components/layouts/LoaderFetch/LoaderFetch';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import { Pager } from '../../../../components/tables/Pager';
import useModalV2 from 'hooks/useModalV2';
import { IModal } from 'hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import {
  deleteWorkCategoryApiV2,
  getPaginatedWorkCategoryApiV2,
} from 'services/api/external-request/work-category/workCategoryApi';
import { IGetPaginatedParamsWorkCategory } from 'services/api/external-request/work-category/IGetPaginatedParamsWorkCategory';
import IWorkCategory from '../../../../services/api/external-request/work-category/IWorkCategory';
import WorkCategoryForm from 'components/external-request/WorkCategoryForm/WorkCategoryForm';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { UI_TITLE } from 'services/localLocalization/UITitle';
import { targetAccessPolicyExternalRequestWorkCategory } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyExternalRequest';
import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';

const deleteMessageError = t('Не удалось удалить классификацию работ');
const CREATE_BUTTON_TITLE = t('Добавить классификацию работ');

const MODAL_EDITING_TITLE = t('Редактировать классификацию работ');
const MODAL_CREATE_TITLE = t('Создать классификацию работ');
const MODAL_DELETE_TITLE = t('Удалить классификацию работ');

const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDITING_TITLE,
  MODAL_CREATE_TITLE
);

const COLUMN_LABELS = {
  PK: '№',
  RENDITION: 'Классификация',
};

const filters = [
  { name: 'work_category_pk', label: COLUMN_LABELS.PK },
  {
    name: 'work_category_rendition',
    label: COLUMN_LABELS.RENDITION,
  },
] as const;
const columnVisibilityInitial = {
  work_category_pk: true,
  work_category_rendition: true,
};

const WorkCategoryPage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial);

  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyExternalRequestWorkCategory,
      targetAction: ['U', 'C', 'D'],
    });

  const initialParams: IGetPaginatedParamsWorkCategory = useMemo(
    () => ({
      skip: 0,
      length: config.pageLength,
    }),
    []
  );

  const {
    data: workCategoryList,
    isLoading,
    refreshListData,
    total,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    params: { skip, length },
  }: IUseGetList<IWorkCategory[], IGetPaginatedParamsWorkCategory> = useGetList(
    {
      getDataApi: getPaginatedWorkCategoryApiV2,
      initialParams,
    }
  );

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: workCategory },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteWorkCategoryApiV2,
    entityIdKey: 'pk',
  });

  return (
    <MainLayout>
      <Subheader>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <AddIcon className="mr-2 text-white" />
            {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={`id pr-0 ${!visibility ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field="work_category_pk"
                  title={COLUMN_LABELS.PK}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'work_category_pk');
                  }}
                  onSort={onSortClick}
                  isOnlyNumbers
                />
              </th>

              <th
                scope="col"
                className={`p-0 ${
                  !visibility.work_category_rendition ? 'd-none' : ''
                }`}
              >
                <HeaderWithSearchAndSort
                  field="work_category_rendition"
                  title={COLUMN_LABELS.RENDITION}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'work_category_rendition');
                  }}
                  onSort={onSortClick}
                />
              </th>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {workCategoryList &&
                workCategoryList.map((workCategory: IWorkCategory) => {
                  const {
                    work_category_pk: pk,
                    work_category_rendition: rendition,
                  } = workCategory;
                  return (
                    <tr key={pk}>
                      <td>
                        <span>{pk}</span>
                      </td>
                      <td>
                        <span>{rendition}</span>
                      </td>
                      <td align="right">
                        <ActionsDropdown>
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={UI_TITLE.EDIT}
                              onClick={openAddEditForm.bind(null, {
                                pk: workCategory?.work_category_pk,
                                rendition:
                                  workCategory?.work_category_rendition,
                              })}
                              icon={<EditIcon className="text-primary" />}
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk: workCategory?.work_category_pk,
                                rendition:
                                  workCategory?.work_category_rendition,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        {isLoading && <LoaderFetch />}
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />
        <ModalWrapper
          headerLabel={getModalTitle(!!workCategory?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <WorkCategoryForm
            successHandler={successHandler}
            pk={workCategory?.pk}
          />
        </ModalWrapper>

        {workCategory?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={workCategory?.pk}
            entityName={workCategory?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default WorkCategoryPage;
