import IBaseParams from '../../interfacesApi/IBaseParams';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import UndercurrentApiClient from '../../UndercurrentApiClient';
import { IPaginatedParamsAccessPolicy } from './IPaginatedParamsAccessPolicy';
import { IBaseGetPaginatedPayload } from '../../../IBaseGetPaginated';
import { IAccessPolicy, IWrapperAccessPolicy } from './IAccessPolicy';
import IPrimaryKey from 'services/types/IPk';
import { IForSelectRequestV2 } from '../../../utils/generateMethodForSelect/generateMethodForSelect';
import {
  ICreateAccessPolicy,
  IUpdateAccessPolicy,
} from './ICreateAccessPolicy';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.ACCESS_POLICY
  );

export const getPaginatedAccessPolicyApiV2 = async (
  filterParams: IBaseParams<IPaginatedParamsAccessPolicy>
): Promise<IBaseResponse<IBaseGetPaginatedPayload<IAccessPolicy>>> =>
  api().getPaginated(filterParams);

export const readAccessPolicyApiV2 = async (
  params: IBaseParams<Partial<IPrimaryKey>>
): Promise<IBaseResponse<IWrapperAccessPolicy>> => api().read(params);

export const createAccessPolicyApiV2 = async (
  params: IBaseParams<ICreateAccessPolicy>
): Promise<IBaseResponse<IWrapperAccessPolicy>> => await api().create(params);

export const updateAccessPolicyApiV2 = async (
  params: IBaseParams<IUpdateAccessPolicy>
): Promise<IBaseResponse<IWrapperAccessPolicy>> => await api().update(params);

export const deleteAccessPolicyApiV2 = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectAccessPolicyApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const exportAccessPolicyApiV2 = async (
  filterParams: IBaseParams<IPaginatedParamsAccessPolicy>
): Promise<IBaseResponse<string>> => api().export(filterParams);
