import React, { FC, useEffect, memo, useState } from 'react';
import createEngine, {
  DiagramModel,
  DefaultNodeModel,
  DefaultPortModel,
  DefaultLinkModel,
} from '@projectstorm/react-diagrams';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import FlowChartWrapper from './FlowChartWrapper/FlowChartWrapper';

export interface INode {
  color: string;
  name: string;
}

type PropsType = {
  nodes: INode[];
};

const DEFAULT_DISTANCE_BETWEEN_NODES: number = 200;

const calculatePosX = (distance: number, i: number): number =>
  (i + 1) * distance;

const createNameForPort = (i: number, payloadLength: number) =>
  i === 0
    ? 'Начальный'
    : i + 1 === payloadLength
    ? 'Конечный'
    : 'Промежуточный';

const convertPorts = (
  i: number,
  payloadLength: number,
  node: DefaultNodeModel
) => {
  const name = createNameForPort(i, payloadLength);
  return node.addOutPort(name);

};

const convertDefaultNodeModel = (nodes: INode[]): DefaultNodeModel[] => {
  return nodes.map((node: any, i: number) => {
    const posX: number = calculatePosX(i + 1, DEFAULT_DISTANCE_BETWEEN_NODES);
    const defaultNode = new DefaultNodeModel(node.name, node.color);
    defaultNode.setPosition(posX, 100);
    return defaultNode;
  });
};

const convertDefaultPort = (
  payloadNodes: DefaultNodeModel[]
): DefaultPortModel[] =>
  payloadNodes.map((node: DefaultNodeModel, i: number) =>
    convertPorts(i, payloadNodes.length, node)
  );

const createLinks = (
  payloadPorts: DefaultPortModel[],
  nodesLength: number
): DefaultLinkModel[] =>
  payloadPorts.map(
    (port: any, i: number) =>
      i !== nodesLength - 1 && port.link(payloadPorts[i + 1])
  );

const fillChartValues = (
  model: DiagramModel,
  payloadNodes: DefaultNodeModel[],
  payloadLinks: DefaultLinkModel[]
): void =>
  payloadNodes.forEach((el: any, i: number) => {
    if (i !== payloadNodes.length) {
      model.addAll(el, payloadNodes[i + 1], payloadLinks[i]);
    }
  });

const FlowChart: FC<PropsType> = (props) => {
  //1) settings the diagram engine
  const engine = createEngine();
  const { nodes } = props;
  const [model] = useState<DiagramModel>(new DiagramModel());
  // sample for link with simple line (no additional points)

  useEffect(() => {
    const payloadNodes: DefaultNodeModel[] = convertDefaultNodeModel(nodes);
    const payloadPorts: DefaultPortModel[] = convertDefaultPort(payloadNodes);
    const payloadLinks: DefaultLinkModel[] = createLinks(
      payloadPorts,
      payloadNodes.length
    );

    fillChartValues(model, payloadNodes, payloadLinks);
  }, [model, nodes]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  engine.setModel(model);

  model.setLocked(true);

  return (
    <FlowChartWrapper>
      <CanvasWidget engine={engine} />
    </FlowChartWrapper>
  );
};

export default memo(FlowChart);
