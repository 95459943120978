enum ApiActions {
  FORSELECT_READ = 'forselect-read',
  ADD_COMMENT = 'add-comment',
  DELETE = 'delete',
  READ = 'read',
  READ_LIST_OF_EDITS = 'read-list-of-edits',
  UPDATE = 'update',
  READ_COMMENTS = 'read-list-of-comments',
  PAGINATED = 'paginated-read',
  PAGINATED_OLD = 'read/paginated',
  EXPORT_TO_EXCEL = 'export-to-excel',
  CREATE = 'create',
  UPLOADS = 'uploads',
  EXTERNAL_REQUEST_LIST_OF_STATUSES = 'list-of-statuses-for-external-request',
  OBTAIN = 'obtain',
  PRUNE = 'prune',
  CLOSE = 'close',
  WITH_FILES = 'with-files',
  OLD = 'old',
  DEFAULT = '',
}

export const ACTIONS_SEPARATOR = '-';

export enum ApiAdditionalActions {
  LOCALIZATION = 'localization',
  ACCESS_POLICE_CURRENT_EMPLOYEE = 'access-policy-statement-for-current-employee',
  CURRENT_EMPLOYEE = 'current-employee',
  FULL_ACCESS_POLICY_STATEMENT = 'full-access-policy-statement',
}

export default ApiActions;
