import { ITaskFilters } from '../../services/api/tasks/tasks/IGetPaginatedTasksParams';
import config from '../../config.json';
import { TasksSortCriteria } from '../../services/api/tasks/tasks/TasksSortCriteria';

export const initialTasksFilters: ITaskFilters = {
  internal_task_pk: undefined,
  internal_task_author_dk_array: [],
  internal_task_supervisor_dk_array: [],
  internal_task_type_fk_array: [],
  internal_task_billing_account_fk: undefined,
  internal_task_billing_account_fk_array: [],
  internal_task_status_fk_array: [],
  internal_task_datetime_added_upper_bound: '',
  internal_task_datetime_added_lower_bound: '',
  internal_task_datetime_edited_upper_bound: '',
  internal_task_datetime_edited_lower_bound: '',
  internal_task_date_duedate_lower_bound: '',
  internal_task_date_duedate_upper_bound: '',
  internal_task_description: '',
  internal_task_comment_text: '',
  internal_task_is_pruned: undefined,
  internal_task_counterparty_fk_array: [],
  internal_task_counterparty_fk: undefined,
  isActiveCounterpartyFilter: false,
  skip: 0,
  length: config.pageLength,
  sort_by: TasksSortCriteria.Default,
};
