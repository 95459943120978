import HorizontalTable, {
  ILabelValue,
} from 'components/tables/HorizontalTable/HorizontalTable';
import { FC, memo, useEffect, useState } from 'react';
import { IBillingAccount } from 'services/api/organizationsV2/billing-account/IBillingAccount';
import { t } from 'services/utils/translation';
import { PATH_ORGANIZATIONS_COUNTERPARTY_EDIT } from '../../../../services/pathConstants';
import { makeLink } from 'services/localPaths';
import { Link, Location, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import classNames from 'clsx';
import { isLegalStatusCompany } from 'services/utils/bilingAccountUtils/billingAccountUtils';
import { extractCounterpartyFromAccount } from '../../../../services/utils/bilingAccountUtils/billingAccountUtils';
import convertObjectInValueLabelArray from '../../../../services/utils/convertObjectInValueLabelArray/convertObjectInValueLabelArray';
import isEqual from 'react-fast-compare';

const getDetailInfoCounterpartyFromBillingAccount = <T,>(
  account: IBillingAccount,
  location: Location<T>
) => {
  const LABELS_COUNTERPARTY = {
    NAME: t('Наименование'),
    ADDRESS: t('Адрес'),
    PHONE_NUMBER: t('Телефон'),
    INN: t('ИНН'),
  };
  const counterParty = extractCounterpartyFromAccount(account);
  if (!!counterParty) {
    const {
      counterparty_phone_number,
      counterparty_company_full_name: companyFullName,
      counterparty_pk,
      counterparty_individual_last_name: lastName,
      counterparty_individual_first_name: firstName,
      counterparty_individual_middle_name: middleName,
      counterparty_inn: inn,
      counterparty_legal_address: address,
    } = counterParty;
    const isCompany = isLegalStatusCompany(counterParty);

    const fullNameCounterparty = isCompany
      ? companyFullName
      : `${lastName} ${firstName} ${middleName || ''}`;

    const detailInfoCounterparty = {
      [LABELS_COUNTERPARTY.NAME]: (
        <Link
          to={{
            pathname: makeLink(
              PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
              counterparty_pk
            ),
          }}
          state={{ from: location }}
        >
          {fullNameCounterparty}
        </Link>
      ),
      [LABELS_COUNTERPARTY.ADDRESS]: address || '-',
      [LABELS_COUNTERPARTY.INN]: inn ? inn + '' : '-',
      [LABELS_COUNTERPARTY.PHONE_NUMBER]: counterparty_phone_number ? (
        <a href={`tel:${counterparty_phone_number + ''}`}>
          {counterparty_phone_number}
        </a>
      ) : (
        '-'
      ),
    };
    return convertObjectInValueLabelArray(detailInfoCounterparty);
  } else {
    return null;
  }
};

interface PropsType {
  account: IBillingAccount | null;
}

const BillingAccountCounterpartyDetails: FC<PropsType> = ({ account }) => {
  const [counterpartyContent, setCounterpartyContent] = useState<ILabelValue[]>(
    []
  );

  const location = useLocation();

  useEffect(() => {
    if (account) {
      const detailInfoCounterparty =
        getDetailInfoCounterpartyFromBillingAccount(account, location);

      detailInfoCounterparty && setCounterpartyContent(detailInfoCounterparty);
    }
  }, [account, location]);

  return (
    <>
      <div className={classNames(styles.title)}>{t('Контрагент')}</div>
      <HorizontalTable content={counterpartyContent} />
    </>
  );
};

export default memo(BillingAccountCounterpartyDetails, isEqual);
 