import { Link, useLocation } from 'react-router-dom';
import { makeLink } from '../../../../../../services/localPaths';
import { PATH_EXTERNAL_REQUEST_EDIT } from 'services/pathConstants';
import { memo, FC } from 'react';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { t } from 'services/utils/translation';
import useDelayedHover from 'hooks/useDelayedHover';
import useShowAttachmentOnHover from 'hooks/useShowAttachmentOnHover/useShowAttachmentOnHover';
import TooltipWitchLoading from 'components/misc/Tooltip/TooltipWitchLoading/TooltipWitchLoading';
import { parentUIElement } from 'services/constants/parentUIElement';

type PropsType = {
  pk: number;
  codeNumber?: string;
};

const CellWithExternalRequestPk: FC<PropsType> = (props) => {
  const { pk, codeNumber } = props;
  const location = useLocation();

  const { isHovered, handleMouseLeave, handleMouseEnter } =
    useDelayedHover(500);

  const { descriptionAttachment, isLoading } = useShowAttachmentOnHover({
      isHovered,
      pk,
      parentUIName: parentUIElement.externalRequest,
  });

  return (
    <>
      <div>
        №:
        <Link
          id={`tooltip-attachment-${pk}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          to={{
            pathname: makeLink(PATH_EXTERNAL_REQUEST_EDIT, pk),
          }}
          state={{ from: location }}
        >
          {pk}
        </Link>
      </div>
      <TooltipWitchLoading
        id={pk}
        isLoading={isLoading}
        text={descriptionAttachment}
      />
      <br /> <br />
      {codeNumber && (
        <div id={`tooltip-code-number-${pk}`}>
          №:{codeNumber}
          <ToolTipWrapper target={`tooltip-code-number-${pk}`} placement="top">
            {t('Внешний номер заявки')}
          </ToolTipWrapper>
        </div>
      )}
    </>
  );
};

export default memo(CellWithExternalRequestPk);
