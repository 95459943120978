import { useGetBillingAccountTab } from 'hooks/useGetBillingAccountTab/useGetBillingAccountTab';
import useGetCommonTabs from 'hooks/useGetCommonTabs/useGetCommonTabs';
import { useGetTaskTab } from 'hooks/useGetTaskTab/useGetTaskTab';
import { parentUIElement } from 'services/constants/parentUIElement';

const useGetCounterpartyTabs = (props: { id: number }) => {
  const { id } = props;

  const {
    setRefreshDocuments: refreshDocumentTabs,
    isLoadingDocument,
    commonTabs: [editTabs, historyTabs, documentsTab],
  } = useGetCommonTabs({ parentUIElement: parentUIElement.counterparty, id });
  const { taskTab, isLoadingTask } = useGetTaskTab({
    pk: id,
    parentUI: parentUIElement.counterparty,
  });

  const { billingAccountTab, isLoadingBillingAccount } =
    useGetBillingAccountTab({
      pk: id,
      parentUI: parentUIElement.counterparty,
    });

  const tabResult = [
    editTabs,
    taskTab,
    billingAccountTab,
    historyTabs,
    documentsTab,
  ];

  return {
    tabs: tabResult,
    refreshDocumentTabs,
    isLoadingTab: isLoadingDocument || isLoadingTask || isLoadingBillingAccount,
  };
};

export default useGetCounterpartyTabs;
