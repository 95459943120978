import { INotification } from 'services/api/notification/IresponseGetNotifications';
import INotificationForList from './INotificationForList';
import { t } from 'services/utils/translation';
import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';
import getDateForNotificationList from '../getDtateForNotificationList/getDateForNotificationList';

const IS_NOT_READ_TEXT = t('Новая');
const IS_READ_TEXT = t('Прочитано');

const getIsReadText = extractGetLeftValueIfTrue(IS_READ_TEXT, IS_NOT_READ_TEXT);

const toNotificationForList = (
  notifications: INotification[]
): INotificationForList[] => {
  const result: INotificationForList[] = [];
  const length = notifications.length;

  for (let i = length - 1; i >= 0; i--) {
    const notification = notifications[i];
    result.push({
      ...notification,
      isReadText: getIsReadText(notification.notification_is_reacted_to),
      clientDateTime: getDateForNotificationList(
        notification.notification_datetime_created
      ),
    });
  }

  return result;
};

export default toNotificationForList;
