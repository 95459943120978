import React, { FC, useState } from 'react';
import classNames from 'clsx';
import { Formik } from 'formik';
import { Button, Form } from 'reactstrap';

import { t } from 'services/utils/translation';

import styles from './index.module.scss';
import TextField from 'components/controls/TextField';
import { usePostV3 } from '../../../hooks/usePostV3';
import RegisterIdentity from '../../../models/RegisterIdentity';
import PasswordField from 'components/controls/PasswordField/PasswordField';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import IRegisterData from '../../../services/api/auth/IRegisterFormData';

import { registerIdentity } from 'services/api/auth/authApiV2';
import { useDispatch } from 'react-redux';
import { RegisterPayload } from '../../../services/api/auth/IRegisterIdentity';
import { userLoginApiV2 } from '../../../store/auth/actions';
import deleteUnnecessaryParameters from '../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import CookieManager from 'models/CoockieManager/CookieManager';
import AuthTokenCookie from 'models/CoockieManager/AuthTokenCoockie/AuthTokenCookie';
import PhoneNumberJwtCookie from 'models/CoockieManager/PhoneNumberJwtCookie/PhoneNumberJwtCookie';

const convertParams = (formData: RegisterIdentity): IRegisterData =>
  deleteUnnecessaryParameters(formData, ['cloned_password']);

const RegistrationFinal: FC = () => {
  const cookieManger = new CookieManager();
  const phoneNumberJwtCookie = new PhoneNumberJwtCookie(cookieManger);

  const jwt = phoneNumberJwtCookie.getPhoneNumberInJwt() as string; //TODO: fix as string
  const location = useLocation();
  const navigate = useNavigate();

  if (!jwt) {
    toast.error('Что-то пошло не так. Попробуйте еще раз');
    navigate(location, {
      state: {
        activeStep: 0,
      },
    });
  }

  const [initialValues] = useState<RegisterIdentity>(new RegisterIdentity(jwt));
  const dispatch = useDispatch();

  const handlePayload = (payload: RegisterPayload) => {
    const { identity_pk } = payload;
    const cookieManger = new CookieManager();
    const authToken = new AuthTokenCookie(cookieManger);
    authToken.saveAuthToken(identity_pk.toString());

    dispatch(userLoginApiV2(payload));
  };

  const onSubmit = usePostV3({
    fetchApi: registerIdentity,
    convertParams,
    handlePayload,
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={RegisterIdentity.validationSchema()}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <div>
          <Form
            onSubmit={handleSubmit}
            noValidate={true}
            className={classNames(styles['registration-form'])}
          >
            <h5>{t('Финальный шаг')}</h5>
            <TextField
              id="identity_nickname"
              label={t('Логин')}
              hasError={
                errors.identity_nickname != null &&
                touched.identity_nickname != null
              }
              onBlur={handleBlur}
              onChange={handleChange}
              required={true}
              defaultValue={values.identity_nickname}
              autoComplete="login"
            />
            <TextField
              id="identity_last_name"
              label={t('Фамилия')}
              hasError={
                errors.identity_last_name != null &&
                touched.identity_last_name != null
              }
              onBlur={handleBlur}
              onChange={handleChange}
              required={true}
              defaultValue={values.identity_last_name}
              autoComplete="last-name"
            />
            <TextField
              id="identity_first_name"
              label={t('Имя')}
              hasError={
                errors.identity_first_name != null &&
                touched.identity_first_name != null
              }
              onBlur={handleBlur}
              onChange={handleChange}
              required={true}
              defaultValue={values.identity_first_name}
              autoComplete="first-name"
            />
            <TextField
              id="identity_middle_name"
              label={t('Отчество')}
              hasError={
                errors.identity_middle_name != null &&
                touched.identity_middle_name != null
              }
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.identity_middle_name}
              autoComplete="middle-name"
            />
            <PasswordField
              id="plain_password"
              label={t('Пароль')}
              placeholder={t('Введите пароль')}
              hasError={
                errors.plain_password != null && touched.plain_password != null
              }
              onBlur={handleBlur}
              onChange={handleChange}
              required={true}
              defaultValue={values.plain_password}
              autoComplete="on"
              checkDifficulty
            />
            <PasswordField
              id="cloned_password"
              label={t('Повторите пароль')}
              placeholder={t('Введите пароль')}
              hasError={
                errors.cloned_password != null &&
                touched.cloned_password != null
              }
              onBlur={handleBlur}
              onChange={handleChange}
              required
              defaultValue={values.cloned_password}
              autoComplete="on"
            />
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              className="btn btn-lg btn-primary btn-block"
            >
              {t('Зарегистрироваться')}
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default RegistrationFinal;
