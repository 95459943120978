import { initialTasksFilters } from 'store/task/initialTasksFilters';
import {
  COUNTERPARTY_SAVE_FILTERS,
  COUNTERPARTY_CLEAR_FILTERS,
  COUNTERPARTY_SAVE_TASK_FILTERS,
  COUNTERPARTY_CLEAR_TASK_FILTERS,
  COUNTERPARTY_SAVE_BILLING_ACCOUNT_FILTERS,
  COUNTERPARTY_CLEAR_BILLING_ACCOUNT_FILTERS,
} from './actionTypes';
import { initialBillingAccountsFilters } from 'store/initialStore/initialBillingAccountsFilters';

const counterpartyReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case COUNTERPARTY_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case COUNTERPARTY_CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.filters,
      };

    case COUNTERPARTY_SAVE_TASK_FILTERS: {
      const { filters, pk } = action.payload;
      return {
        ...state,
        taskTableFilters: {
          [pk]: {
            ...state.taskTableFilters?.[pk],
            ...filters,
          },
        },
      };
    }

    case COUNTERPARTY_CLEAR_TASK_FILTERS:
      return {
        ...state,
        taskTableFilters: {
          ...state.taskTableFilters,
          [action.payload]: {
            ...initialTasksFilters,
            internal_task_counterparty_fk: action.payload,
          },
        },
      };

    case COUNTERPARTY_SAVE_BILLING_ACCOUNT_FILTERS: {
      const { filters, pk } = action.payload;
      return {
        ...state,
        billingAccountTableFilters: {
          ...state.billingAccountTableFilters,
          [pk]: {
            ...state.billingAccountTableFilters?.[pk],
            ...filters,
          },
        },
      };
    }

    case COUNTERPARTY_CLEAR_BILLING_ACCOUNT_FILTERS:
      return {
        ...state,
        billingAccountTableFilters: {
          ...state.billingAccountTableFilters,
          [action.payload]: {
            ...initialBillingAccountsFilters,
            billing_account_counterparty_fk: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default counterpartyReducer;
