 import { IVehicleFilters } from '../../services/api/vehicle/vehicle/IGetPaginatedParamsVehicle';
import { VEHICLE_CLEAR_FILTERS, VEHICLE_SAVE_FILTERS } from './actionTypes';
import { initialVehicleFilters } from 'store/initialStore/initialVehicleFilters';

export const saveVehicleFilters = (filters: IVehicleFilters) => ({
  type: VEHICLE_SAVE_FILTERS,
  filters,
});

export const clearVehicleFilters = () => ({
  type: VEHICLE_CLEAR_FILTERS,
  filters: initialVehicleFilters,
});
