import {
  SET_EXPORT_LINK_STATUS_LOADING,
  SET_EXPORT_LINK,
  CLEAR_EXPORT_LINK,
  SET_EXPORT_ERROR,
  SET_TOAST_ID,
} from './actionTypes';
const exportReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SET_EXPORT_LINK_STATUS_LOADING:
      return {
        ...state,
        ...action.payload,
      };

    case SET_EXPORT_LINK: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CLEAR_EXPORT_LINK: {
      return action.payload;
    }

    case SET_EXPORT_ERROR: {
      return action.payload;
    }
    case SET_TOAST_ID:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default exportReducer;
