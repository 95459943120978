import { FC, memo } from 'react';
import { Alert } from 'reactstrap';

type PropsTypeAlert = {
  text: string;
  helpText?: string;
  onAgree?: Function;
  onCancel?: Function;
};

const AlertWrapper: FC<PropsTypeAlert> = (props) => {
  const { text, helpText } = props;

  return (
    <Alert color="danger">
      <div className="d-flex flex-column   align-items-center">
        <h4>{text}</h4>
        {helpText && <p>{helpText}</p>}
      </div>
    </Alert>
  );
};

export default memo(AlertWrapper);
