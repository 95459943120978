import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useGoBackNavigate = () => {
  const navigation = useNavigate();

  return useCallback(() => {
    navigation(-1);
  }, [navigation]);
};

export default useGoBackNavigate;
