import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
} from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';

type PermanentValue = { [key: string]: number | string | undefined };

//TODO: use one argument {}
interface IUseClearFilters {
  clearFilters: () => void;
  isDisableClearButton: boolean;
  clearingFiltersSpinner: boolean;
  setClearingFiltersSpinner: Dispatch<SetStateAction<boolean>>;
}

const useClearFilters = <Filters extends object>(
  initialFilters: Filters,
  currentParams: Filters,
  actionClearFilters: Function,
  setParams: Function,
  permanentValue?: PermanentValue
): IUseClearFilters => {
  const [isDisableClearButton, setIsDisableClearButton] =
    useState<boolean>(true);

  const [clearingFiltersSpinner, setClearingFiltersSpinner] =
    useState<boolean>(false);

  const keyPermanentValue: string = permanentValue
    ? Object.keys(permanentValue)[0]
    : '';

  const isButtonShouldBeDisabled = useCallback(
    () =>
      permanentValue
        ? isEqual(
            {
              ...initialFilters,
              [keyPermanentValue]: permanentValue?.[keyPermanentValue],
            },
            currentParams
          )
        : isEqual(initialFilters, currentParams),
    [currentParams, initialFilters, keyPermanentValue, permanentValue]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    isButtonShouldBeDisabled()
      ? setIsDisableClearButton(true)
      : setIsDisableClearButton(false);
  }, [initialFilters, currentParams, isButtonShouldBeDisabled]);

  const clearFilters = () => {
    setClearingFiltersSpinner(true);
    permanentValue
      ? dispatch(actionClearFilters(permanentValue[keyPermanentValue]))
      : dispatch(actionClearFilters());
    setParams(
      permanentValue
        ? {
            ...initialFilters,
            [keyPermanentValue]: permanentValue[keyPermanentValue],
          }
        : initialFilters
    );
  };

  return {
    clearFilters,
    isDisableClearButton,
    clearingFiltersSpinner,
    setClearingFiltersSpinner,
  };
};

export default useClearFilters;
