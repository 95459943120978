import { t } from '../../../../services/utils/translation';
import { useColumnFilter } from '../../../../hooks/useColumnFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveEmployeeFilters,
  clearEmployeeFilters,
} from '../../../../store/employee/actions';
import { getEmployeeFiltersFromStore } from '../../../../store/employee/selectors';
import isEqual from 'react-fast-compare';
import { useGetList } from 'hooks/useGetList';
import { IEmployeeForList } from '../../../../services/api/staff/employee/IEmployee';
import { useMemo } from 'react';
import { IEmployeeFilters } from '../../../../services/api/staff/employee/IGetPaginatedParamsEmployee';
import { getPaginatedEmployeeApiV2 } from 'services/api/staff/employee/employeeApi';
import toEmployeeForList from './toEmployee';
import useClearFilters from 'hooks/useClearFilters';
import { initialEmployeeFilters } from '../../../../store/initialStore/initialEmplouyeeFilters';
import composeParamsForGetEmployee from './composeParamsForGetEmployee';
import useNextWindow from 'hooks/useNextWindow';
import { PATH_PERSONNEL_EMPLOYEE_EDIT } from '../../../../services/pathConstants';
import { exportEmployeeApiV2 } from '../../../../services/api/staff/employee/employeeApi';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';

import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';

import classNames from 'clsx';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import SubheaderButtonExport from 'components/tables/SubHeaderButtonExport/SubHeaderButtonExport';
import { EmployeeRequestSortCriteria } from '../../../../services/api/staff/employee/IGetPaginatedParamsEmployee';
import HeaderWithSelectAdvanced from 'components/tables/HeaderWithSelectAdvanced/HeaderWithSelectAdvanced';
import { getForSelectJobTitleApiV2 } from '../../../../services/api/staff/job-title/jobTitleApiV2';
import HeaderWithDynamicSelectPaginatedV2 from 'components/tables/HeaderWithDynamicSelectPaginatedV2/HeaderWithDynamicSelectPaginatedV2';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import LoaderFetch from '../../../../components/layouts/LoaderFetch/LoaderFetch';
import { Pager } from '../../../../components/tables/Pager';
import useEmployeeHandleSearch from './useEmployeeHandleSearch';
import { toTObject } from 'services/utils/TSUtils/toTObject';
import RefreshButton from 'components/tables/RefreshButton/RefreshButton';
import { getForSelectOnlySupervisorEmployeeApiV2 } from '../../../../services/api/staff/employee/employeeApi';
import { UI_TITLE } from '../../../../services/localLocalization/UITitle';
import { makeLink } from 'services/localPaths';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import { getDropDownEditTitle } from '../../../../services/utils/extractGetLeftValueIfTrue';
import {
  THCustomDefault,
  THCustomSelect,
  THCustomUnruled,
} from 'components/tables/THCustomWrapper/THCustomWrapper';
import MyFilters from 'components/tables/MyFilters/MyFilters';
import useGetEmployeeAccessPolicy from './hooks/useGetEmployeeAccessPolicy';

const IS_DRIVER_TITLE: string = t('Является водителем');
const NOT_IS_DRIVER_TITLE: string = t('Не является водителем');

const IS_ACTIVE_EMPLOYEE_TITLE: string = t('Активный пользователь');
const NOT_IS_ACTIVE_EMPLOYEE_TITLE: string = t('Не активный пользователь');

const extractGetHint =
  ({ onHint, offHint }: { onHint: string; offHint: string }) =>
  (toggle?: boolean) => {
    if (toggle !== undefined) {
      return toggle ? onHint : offHint;
    } else {
      return undefined;
    }
  };

const getHintForIsDriver = extractGetHint({
  onHint: IS_DRIVER_TITLE,
  offHint: NOT_IS_DRIVER_TITLE,
});

const getHintForIsActive = extractGetHint({
  onHint: IS_ACTIVE_EMPLOYEE_TITLE,
  offHint: NOT_IS_ACTIVE_EMPLOYEE_TITLE,
});

const COLUMN_LABELS_EMPLOYEE = toTObject({
  PK: '№',
  LAST_NAME: t('Фамилия'),
  ACCESS_POLICY: t('Роль'),
  SUPERVISOR: t('Руководитель'),
  POSITION: t('Должность'),
  PHONE_NUMBER: t('Телефон'),
  EMAIL: t('Почта'),
  LOGIN_IS_ALLOWED: t('Доступ'),
  PERSONAL_CODE_NUMBER: t('Табельный номер'),
  IS_DRIVER: t('Водитель'),
  NICKNAME: t('Логин'),
  IS_ACTIVE: t('Активный'),
});

const filters = [
  { name: 'pk', label: COLUMN_LABELS_EMPLOYEE.PK },
  { name: 'accessPolicy', label: COLUMN_LABELS_EMPLOYEE.ACCESS_POLICY },
  { name: 'lastName', label: COLUMN_LABELS_EMPLOYEE.LAST_NAME },
  { name: 'email', label: COLUMN_LABELS_EMPLOYEE.EMAIL },
  {
    name: 'position',
    label: COLUMN_LABELS_EMPLOYEE.POSITION,
  },
  { name: 'phone_number', label: COLUMN_LABELS_EMPLOYEE.PHONE_NUMBER },
  { name: 'supervisor', label: COLUMN_LABELS_EMPLOYEE.SUPERVISOR },
  {
    name: 'is_driver',
    label: COLUMN_LABELS_EMPLOYEE.IS_DRIVER,
  },
  {
    name: 'personnel_code_number',
    label: COLUMN_LABELS_EMPLOYEE.PERSONAL_CODE_NUMBER,
  },
  {
    name: 'isActive',
    label: COLUMN_LABELS_EMPLOYEE.IS_ACTIVE,
  },
] as const;

const columnVisibilityInitial = {
  pk: true,
  lastName: true,
  accessPolicy: true,
  email: true,
  phone_number: true,
  supervisor: true,
  position: true,
  roles: true,
  is_driver: true,
  personnel_code_number: true,
  nickname: true,
  isActive: true,
};

const EmployeePage = () => {
  const dispatchRedux = useDispatch();

  const {
    jobTitleHaveAccessToRead,
    employee: { haveAccessToUpdate, haveAccessToExport },
  } = useGetEmployeeAccessPolicy();

  const saveFilters = (filters: IEmployeeFilters) =>
    dispatchRedux(saveEmployeeFilters(filters));

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial, 'employeeColumnFilter');

  const defaultTableFilters = useSelector(getEmployeeFiltersFromStore, isEqual);

  const initialParams: IEmployeeFilters = useMemo(
    () => defaultTableFilters,
    [defaultTableFilters]
  );

  const onFinally = () => setClearingFiltersSpinner(false);

  const {
    data: employees,
    isLoading,
    total,
    onSearchRequest,
    onSortClick,
    params,
    params: {
      employee_pk,
      employee_supervisor_fk_array,
      employee_email,
      employee_phone_number,
      employee_job_title_fk_array,
      employee_personnel_code_number,
      employee_is_driver,
      employee_last_name,
      employee_is_active: isActive,
      skip,
      length,
    },
    setStart,
    setLength,
    setParams,
    refreshAndReturnToStartPosition,
  } = useGetList({
    getDataApi: getPaginatedEmployeeApiV2,
    initialParams,
    convertData: toEmployeeForList,
    saveFilters,
    onFinally,
    convertedParameters: composeParamsForGetEmployee,
  });

  const { clearFilters, isDisableClearButton, setClearingFiltersSpinner } =
    useClearFilters(
      initialEmployeeFilters,
      params,
      clearEmployeeFilters,
      setParams
    );

  //SEARCH

  const {
    handleSearchSupervisor,
    handleSearchEmail,
    handleSearchPhoneNumber,
    handleSearchPosition,
    handleSearchPersonalCodeNumber,
    handleSearchIsDriver,
    handleSearchPk,
    handleSearchLastName,
    handleSearchIsActive,
  } = useEmployeeHandleSearch(onSearchRequest);

  //NAVIGATION CONTROL

  const nextWindowEmployeeEditPage = useNextWindow(
    PATH_PERSONNEL_EMPLOYEE_EDIT
  );

  return (
    <MainLayout>
      <Subheader>
        <div style={{ display: 'flex' }} className="mr-5">
          <SubheaderButtonExport
            params={params}
            getExportLinkApi={exportEmployeeApiV2}
            disabled={isLoading}
            haveAccessToExport={haveAccessToExport}
          />
        </div>
        <SubheaderButton>
          <MyFilters
            setFilters={setParams}
            currentFilter={params}
            saveFilters={saveFilters}
            keyMyFilter="employee"
          />
        </SubheaderButton>
        <SubheaderButton
          onClick={clearFilters}
          bordered={true}
          disabled={isDisableClearButton}
        >
          {UI_TITLE.REMOVE_FILTERS}
        </SubheaderButton>
        <RefreshButton
          toggleRefresh={refreshAndReturnToStartPosition}
          isLoading={isLoading}
        />
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <THCustomDefault
                isVisible={visibility.pk}
                className={classNames(styles.columnPk)}
              >
                <HeaderWithSearchAndSort
                  field={EmployeeRequestSortCriteria.PkAsc}
                  title={COLUMN_LABELS_EMPLOYEE.PK}
                  onSort={onSortClick}
                  onSearch={handleSearchPk}
                  defaultValue={employee_pk ? employee_pk + '' : ''}
                  isOnlyNumbers
                />
              </THCustomDefault>
              <THCustomDefault
                isVisible={visibility.lastName}
                className={classNames(styles.columnLastName)}
              >
                <HeaderWithSearchAndSort
                  field={EmployeeRequestSortCriteria.LastNameAsc}
                  title={COLUMN_LABELS_EMPLOYEE.LAST_NAME}
                  onSort={onSortClick}
                  onSearch={handleSearchLastName}
                  defaultValue={employee_last_name}
                />
              </THCustomDefault>
              <THCustomUnruled
                isVisible={visibility.accessPolicy}
                className={classNames(styles.columnAccessPolicy)}
              >
                {COLUMN_LABELS_EMPLOYEE.ACCESS_POLICY}
              </THCustomUnruled>
              <THCustomSelect
                className={classNames(styles.columnPosition)}
                isVisible={visibility.position}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  selectHandler={getForSelectJobTitleApiV2}
                  id="employee_job_title_fk_array"
                  title={COLUMN_LABELS_EMPLOYEE.POSITION}
                  options={employee_job_title_fk_array}
                  onChange={handleSearchPosition}
                  isMulti={true}
                  haveReadPermission={jobTitleHaveAccessToRead}
                />
              </THCustomSelect>
              <THCustomSelect
                isVisible={visibility.supervisor}
                className={classNames(styles.columnSupervisor)}
              >
                <HeaderWithDynamicSelectPaginatedV2
                  selectHandler={getForSelectOnlySupervisorEmployeeApiV2}
                  id="employee_supervisor_fk_array"
                  title={COLUMN_LABELS_EMPLOYEE.SUPERVISOR}
                  options={employee_supervisor_fk_array}
                  onChange={handleSearchSupervisor}
                  isMulti={true}
                />
              </THCustomSelect>
              <THCustomDefault
                isVisible={visibility.email}
                className={classNames(styles.columnEmail)}
              >
                <HeaderWithSearchAndSort
                  field={EmployeeRequestSortCriteria.EmailAsc}
                  title={COLUMN_LABELS_EMPLOYEE.EMAIL}
                  onSort={onSortClick}
                  onSearch={handleSearchEmail}
                  defaultValue={employee_email}
                />
              </THCustomDefault>
              <THCustomDefault
                isVisible={visibility.phone_number}
                className={classNames(styles.columnPhoneNumber)}
              >
                <HeaderWithSearchAndSort
                  field={EmployeeRequestSortCriteria.PhoneAsc}
                  title={COLUMN_LABELS_EMPLOYEE.PHONE_NUMBER}
                  onSort={onSortClick}
                  onSearch={handleSearchPhoneNumber}
                  defaultValue={employee_phone_number}
                  isOnlyNumbers
                />
              </THCustomDefault>
              <THCustomUnruled
                isVisible={visibility.nickname}
                className={classNames(styles.columnNickName)}
              >
                {COLUMN_LABELS_EMPLOYEE.NICKNAME}
              </THCustomUnruled>
              <THCustomDefault
                isVisible={visibility.personnel_code_number}
                className={classNames(styles.columnPersonnelCodeNumber)}
              >
                <HeaderWithSearchAndSort
                  field={EmployeeRequestSortCriteria.PhoneAsc}
                  onSort={onSortClick}
                  title={COLUMN_LABELS_EMPLOYEE.PERSONAL_CODE_NUMBER}
                  onSearch={handleSearchPersonalCodeNumber}
                  defaultValue={employee_personnel_code_number}
                />
              </THCustomDefault>
              <THCustomSelect
                isVisible={visibility.is_driver}
                className={classNames(styles.columnIsDriver)}
              >
                <HeaderWithSelectAdvanced
                  label={COLUMN_LABELS_EMPLOYEE.IS_DRIVER}
                  options={[
                    { label: IS_DRIVER_TITLE, value: true },
                    { label: NOT_IS_DRIVER_TITLE, value: false },
                  ]}
                  onChange={handleSearchIsDriver}
                  defaultValue={
                    employee_is_driver
                      ? {
                          label: employee_is_driver
                            ? IS_DRIVER_TITLE
                            : NOT_IS_DRIVER_TITLE,
                          value: employee_is_driver,
                        }
                      : undefined
                  }
                  hint={getHintForIsDriver(employee_is_driver)}
                />
              </THCustomSelect>
              <THCustomSelect
                isVisible={visibility.isActive}
                className={classNames(styles.columnIsDriver)}
              >
                <HeaderWithSelectAdvanced
                  label={COLUMN_LABELS_EMPLOYEE.IS_ACTIVE}
                  options={[
                    { label: IS_ACTIVE_EMPLOYEE_TITLE, value: true },
                    { label: NOT_IS_ACTIVE_EMPLOYEE_TITLE, value: false },
                  ]}
                  onChange={handleSearchIsActive}
                  defaultValue={
                    isActive
                      ? {
                          label: isActive
                            ? IS_ACTIVE_EMPLOYEE_TITLE
                            : NOT_IS_ACTIVE_EMPLOYEE_TITLE,
                          value: isActive,
                        }
                      : undefined
                  }
                  hint={getHintForIsActive(isActive)}
                />
              </THCustomSelect>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {employees &&
                employees.map((employee: IEmployeeForList) => {
                  const {
                    employee_pk: pk,
                    fullNameSupervisor,
                    fullNameEmployee,
                    employee_phone_number: phoneNumber,
                    employee_email: email,
                    employee_personnel_code_number: personalCodeNumber,
                    employee_job_title,
                    employee_access_policy: accessPolicy,
                    isDriver,
                    isActive,
                    employee_identity_nickname: nickname,
                  } = employee;

                  const jobTitleName = employee_job_title?.job_title_name;

                  return (
                    <tr key={pk}>
                      <td
                        className={classNames({
                          'd-none': !visibility.pk,
                        })}
                      >
                        <Link
                          className="d-block"
                          style={{ maxWidth: '100%' }}
                          to={makeLink(PATH_PERSONNEL_EMPLOYEE_EDIT, pk)}
                        >
                          {pk}
                        </Link>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.lastName,
                        })}
                      >
                        <span>{fullNameEmployee}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.accessPolicy,
                        })}
                      >
                        <span>{accessPolicy?.access_policy_name}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.position,
                        })}
                      >
                        <span>{jobTitleName}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.supervisor,
                        })}
                      >
                        <span>{fullNameSupervisor}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.email,
                        })}
                      >
                        <span>{email}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.phone_number,
                        })}
                      >
                        <span>{[phoneNumber]}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.phone_number,
                        })}
                      >
                        {nickname}
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.personnel_code_number,
                        })}
                      >
                        <span>{personalCodeNumber}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.is_driver,
                        })}
                      >
                        {isDriver}
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.is_driver,
                        })}
                      >
                        {isActive}
                      </td>

                      <td align="right">
                        <ActionsDropdown>
                          <ActionsDropdownItem
                            label={getDropDownEditTitle(haveAccessToUpdate)}
                            onClick={nextWindowEmployeeEditPage.bind(null, pk)}
                            icon={<EditIcon className="text-primary" />}
                          />
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        {!isLoading && (
          <Pager
            onPageChange={setStart}
            onLengthChange={setLength}
            total={total}
            length={length}
            start={skip}
            saveFilters={saveFilters}
          />
        )}

        {isLoading && <LoaderFetch />}
      </div>
    </MainLayout>
  );
};

export default EmployeePage;
