import {
  SET_EXPORT_LINK_STATUS_LOADING,
  SET_EXPORT_LINK,
  CLEAR_EXPORT_LINK,
  SET_EXPORT_ERROR,
  SET_TOAST_ID,
} from './actionTypes';
import { INotification } from '../../services/api/notification/IresponseGetNotifications';
import { initialExport } from "../initialStore/initialExport";

export const setExportLinkLoading = (isLoading: boolean) => ({
  type: SET_EXPORT_LINK_STATUS_LOADING,
  payload: { isLoading },
});

export const setToastId = (toastId: string) => ({
  type: SET_TOAST_ID,
  payload: toastId,
});

export const clearExport = () => ({
  type: CLEAR_EXPORT_LINK,
  payload: initialExport,
});

export const setExportError = (hasError: boolean) => ({
  type: SET_EXPORT_ERROR,
  payload: { isLoading: false, link: null, hasError },
});

export const setExportLink = (message: string) => {
  const notification: Omit<INotification, 'notification_pk'> = JSON.parse(
    decodeURIComponent(message)
  );
  const exportLink = notification?.notification_payload;
  return {
    type: SET_EXPORT_LINK,
    payload: { link: exportLink, isLoading: false },
  };
};
