import { toTObject } from 'services/utils/TSUtils/toTObject';

const localizationDefault = toTObject({
  AccessPolicy: 'правила доступа',
  BillingAccount: 'лицевые счета',
  BillingAccountType: 'лицевые счета: тип',
  Counterparty: 'контрагенты',
  Controller: 'контроллеры',
  Employee: 'сотрудник',
  ExternalRequest: 'заявки',
  EventType: 'события: тип',
  Event: 'события',
  InternalTask: 'задачи',
  InternalTaskStatus: 'задачи: статус',
  InternalTaskType: 'задачи: тип',
  JobTitle: 'должность',
  ResponsibleDepartment: 'ответственная служба',
  WorkCategory: 'типы работ',
  external_request: 'работа со внешними заявками',
  accounts: 'объекты инфраструктуры, контрагенты и ЛС',
  internal_task: 'работа со внутренними задачами',
  personnel: 'персонал и настройка уровней доступа',
  documents: 'работа с документами',
  FileBucketSchema: 'типы документов',
});

export default localizationDefault;
