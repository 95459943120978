import { t } from 'services/utils/translation';
import {
  BILLING_ACCOUNT_TEXT,
  EVENT_TEXT,
  RESPONSIBLE_DEPARTMENT_TEXT,
  WORK_CATEGORY_TEXT,
} from '../../../../../services/localLocalization/servicesAndModels';
import { makeFirstLetterUppercase } from '../../../../../services/utils/stringHelper/stringHelper';

export const COLUMN_LABELS_EXTERNAL_REQUEST = {
  PK: t('№'),
  CODE_NUMBER: t('№ (Внешний)'),
  CREATE_DATE: t('Дата создания'),
  EDITED_DATE: t('Дата редактирования'),
  AUTHOR: t('Автор'),
  ADDRESS: t('Адрес нарушения'),
  BILLING_ACCOUNT: makeFirstLetterUppercase(BILLING_ACCOUNT_TEXT),
  EVENT: makeFirstLetterUppercase(EVENT_TEXT),
  DESCRIPTION: t('Суть заявки'),
  WORK_CATEGORY: makeFirstLetterUppercase(WORK_CATEGORY_TEXT),
  RESPONSIBLE_DEPARTMENT: makeFirstLetterUppercase(RESPONSIBLE_DEPARTMENT_TEXT),
  IMPLEMENTERS: t('Исполнитель'),
  EXECUTIVE_IMPLEMENTER: t('Ответственный исполнитель'),
  SUPERVISOR: t('Ответственный руководитель'),
  DATE_DUEDATE: t('Срок исполнения'),
  STATUS: t('Статус'),
  PARENT_TASK: t('Родственные задачи'),
  PARENT_EXTERNAL_REQUEST: t('Родственные заявки'),
  AFFILIATE: t('Партнер'),
  ARCHIVE: t('Архив'),
  PK_AND_CODE_NUMBER: t('Номер заявки'),
  EXECUTIVE_IMPLEMENTER_AND_IMPLEMENTERS: t('Исполнители и Ответственный'),
  BILLING_ACCOUNT_AND_EVENT: `${makeFirstLetterUppercase(
    BILLING_ACCOUNT_TEXT
  )} ${t('и')} ${EVENT_TEXT}`,
  WORK_CATEGORY_AND_DEPARTMENT: `${makeFirstLetterUppercase(
    WORK_CATEGORY_TEXT
  )} ${t('и')} ${RESPONSIBLE_DEPARTMENT_TEXT}`,
};

export type ExternalRequestColumnLabelsType =
  typeof COLUMN_LABELS_EXTERNAL_REQUEST;

export const externalRequestFilters = [
  {
    name: 'pkAndCodeNumber',
    label: COLUMN_LABELS_EXTERNAL_REQUEST.PK_AND_CODE_NUMBER,
  },
  { name: 'createDate', label: COLUMN_LABELS_EXTERNAL_REQUEST.CREATE_DATE },
  { name: 'editedDate', label: COLUMN_LABELS_EXTERNAL_REQUEST.EDITED_DATE },

  { name: 'author', label: COLUMN_LABELS_EXTERNAL_REQUEST.AUTHOR },
  { name: 'address', label: COLUMN_LABELS_EXTERNAL_REQUEST.ADDRESS },
  {
    name: 'billingAccountAndEvent',
    label: COLUMN_LABELS_EXTERNAL_REQUEST.BILLING_ACCOUNT_AND_EVENT,
  },
  {
    name: 'workCategoryAndResponsibleDepartment',
    label: COLUMN_LABELS_EXTERNAL_REQUEST.WORK_CATEGORY_AND_DEPARTMENT,
  },

  { name: 'affiliate', label: COLUMN_LABELS_EXTERNAL_REQUEST.AFFILIATE },
  {
    name: 'executiveImplementersAndImplementers',
    label:
      COLUMN_LABELS_EXTERNAL_REQUEST.EXECUTIVE_IMPLEMENTER_AND_IMPLEMENTERS,
  },

  { name: 'supervisor', label: COLUMN_LABELS_EXTERNAL_REQUEST.SUPERVISOR },

  { name: 'duedate', label: COLUMN_LABELS_EXTERNAL_REQUEST.DATE_DUEDATE },
  { name: 'status', label: COLUMN_LABELS_EXTERNAL_REQUEST.STATUS },

  { name: 'is_pruned', label: COLUMN_LABELS_EXTERNAL_REQUEST.ARCHIVE },
  { name: 'parentTask', label: COLUMN_LABELS_EXTERNAL_REQUEST.PARENT_TASK },
  {
    name: 'parentExternalRequest',
    label: COLUMN_LABELS_EXTERNAL_REQUEST.PARENT_EXTERNAL_REQUEST,
  },
] as const;

export const columnVisibilityInitialExternalRequest = {
  pkAndCodeNumber: true,
  createDate: true,
  editedDate: false,
  author: true,
  address: true,
  billingAccountAndEvent: false,
  workCategoryAndResponsibleDepartment: true,
  affiliate: false,
  executiveImplementersAndImplementers: true,
  supervisor: false,
  duedate: true,
  status: true,
  is_pruned: false,
  parentTask: false,
  parentExternalRequest: false,
};

export type ColumnVisibilityExternalRequestsType =
  typeof columnVisibilityInitialExternalRequest;
