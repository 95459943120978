import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IEvent } from 'services/api/organizationsV2/event/IEvent';
import { IKeyValue } from 'services/interfaces/IKeyValue';
import { makeLink } from 'services/localPaths';
import { PATH_ORGANIZATIONS_ACCOUNTS_VIEW } from 'services/pathConstants';
import { t } from 'services/utils/translation';
import ColoredStatusHeaderLabel from 'components/misc/ColoredStatusHeaderLabel/ColoredStatusHeaderLabel';
import styles from './index.module.scss';
import getStatusLabelColor from 'services/utils/getStatusLabelColor/getStatusLabelColor';

const HEADER_LABELS = {
  TYPE: t('Тип'),
  ACCOUNT: t('Лицевой счет'),
  STATUS: t('Статус'),
} as const;

const useGetEventLabels = (event: IEvent | null) => {
  return useMemo((): IKeyValue<string | JSX.Element> | null => {
    if (!event) {
      return null;
    }

    const { labelStatus, color } = getStatusLabelColor(event.event_is_closed);

    return {
      [HEADER_LABELS.STATUS]: (
        <ColoredStatusHeaderLabel color={color} className={styles.statusViewDetails}>
          {labelStatus}
        </ColoredStatusHeaderLabel>
      ),
      [HEADER_LABELS.ACCOUNT]: (
        <Link
          to={makeLink(
            PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
            event.event_billing_account_fk
          )}
        >
          {event.event_billing_account.billing_account_code_number}
        </Link>
      ),
      [HEADER_LABELS.TYPE]: event.event_type.event_type_rendition,
    } as const;
  }, [event]);
};

export default useGetEventLabels;
