import { AttachmentsService } from 'models/AttachmentsService/AttachmentsService';
import {
  AttachmentDescription,
  AttachmentClientDescriptionBase,
} from './AttachmentClientDescriptionBase';

export class AttachmentClientDescriptionTask extends AttachmentClientDescriptionBase {
  constructor(attachments: AttachmentsService) {
    super(attachments, [
      AttachmentDescription.vehicleDescription,
      AttachmentDescription.invoiceDescription,
    ]);
  }
}
