import IClientSelectOptionV2 from '../../api/interfacesApi/IClientSelectOptionV2';

const getIsLastIndex = (i: number, array: any[]) => array?.length - 1 === i;

const getHintForSelectArrayOptions =
  (title: string) => (options?: IClientSelectOptionV2[]) =>
    `${title}: ${options?.reduce(
      (
        accumulator: string,
        currentValue: IClientSelectOptionV2,
        index: number
      ) => {
        return `${accumulator} ${currentValue.label}${
          getIsLastIndex(index, options) ? '' : ','
        }`;
      },
      ''
    )}`;

export default getHintForSelectArrayOptions;
