import config from '../../config.json';
import {
  IVehicleFilters,
  VehicleRequestSortCriteria,
} from 'services/api/vehicle/vehicle/IGetPaginatedParamsVehicle';

export const initialVehicleFilters: IVehicleFilters = {
  vehicle_pk: undefined,
  vehicle_type_fk_array: [],
  vehicle_registration_plate: '',
  vehicle_garage_code_number: '',
  skip: 0,
  length: config.pageLength,
  sort_by: VehicleRequestSortCriteria.Default,
};
