import { t } from 'services/utils/translation';
import { MainDescriptionService } from './MainDescriptionService';

export class InvoiceDescriptionService extends MainDescriptionService {
  protected INVOICE_TEXT = t('Накладная');
  protected INVOICES_TEXT = t('Накладные');

  public getInvoiceDescription(invoices: number | null) {
    if (invoices == null) {
      return this.handleNull(this.INVOICE_TEXT);
    }
    return this.getDescriptionOnOrOfInvoice(invoices);
  }

  protected getDescriptionOnOrOfInvoice = (invoices: number) => {
    if (invoices <= 0) {
      return `${this.INVOICE_TEXT}: ${t('Отсутствует')}`;
    } else if (invoices === 1) {
      return `${this.INVOICE_TEXT}: ${t('Есть')}`;
    } else {
      return `${this.INVOICES_TEXT}: ${invoices}`;
    }
  };
}
