import React, { FC, memo } from 'react';
import classNames from 'clsx';
import {
  PATH_EXTERNAL_REQUEST_TABLE,
  PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
} from 'services/pathConstants';
import { t } from 'services/utils/translation';
import styles from './index.module.scss';
import {
  PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST,
  PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST,
} from '../../../services/pathConstants';
import ButtonSubHeaderForm from 'components/controls/ButtonSubHeaderForn/ButtonSubHeaderForm';
import useNextWindow from 'hooks/useNextWindow';
import {
  PATH_TASK_EDIT,
  PATH_EXTERNAL_REQUEST_EDIT,
} from '../../../services/pathConstants';
import { getExternalRequestInitialValuesFromStore } from '../../../store/externalRequest/selectors';
import { useSelector } from 'react-redux';
import { IChildExternalRequest } from '../../../services/api/external-request/external-request/IExternalRequest';
import { IChildInternalTask } from '../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import {
  SUBHEADER_TASK_PARENT_TITLE,
  SUBHEADER_EXTERNAL_REQUEST_PARENT_TITLE,
} from '../../tasks/TaskHeader/TaskHeader';
import {
  TITLE_CHILDREN_TASKS,
  TITLE_CHILDREN_EXTERNAL_REQUEST,
} from '../../tasks/TaskHeader/TaskHeader';
import FormHeaderLink from 'components/controls/FormHeader/FormHeaderLink/FormHeaderLink/FormHeaderLink';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyTask } from '../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import { IFormValuesExternalRequest } from '../ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import ListLinkFormHeader from 'components/controls/ListLinkFormHeader/ListLinkFormHeader/ListLinkFormHeader';
import { getAccountNumber } from '../../../services/utils/bilingAccountUtils/billingAccountUtils';
import PopoutDynamicSelectPaginated from 'components/controls/PopoutDynamicSelectPaginted/PopoutDynamicSelectPaginated';
import useHandlersExternalRequestHeader from './hooks/useHandlersExternalRequestHeader';
import { getTaskForSelectApiV2 } from 'services/api/tasks/tasks/tasksApiV2';
import { getExternalRequestForSelectApiV2 } from 'services/api/external-request/external-request/externalRequestApi';
import { PopoutSelectMenuPosition } from 'components/controls/PopoutDynamicSelectPaginted/ui/PopoutSelectMenu/PopoutSelectMenu';
import { UI_TITLE } from 'services/localLocalization/UITitle';
import ColoredStatusHeaderLabel from 'components/misc/ColoredStatusHeaderLabel/ColoredStatusHeaderLabel';
import useGetExternalRequestHeaderTitle from './hooks/useGetExternalRequestTitle';
import ExternalRequestHeaderTimeCompletion from './ui/ExternalRequestHeaderTimeCompletion';

const BILLING_ACCOUNT_HEADER_LABEL = t('Лицевой счет');
// toDO NEED REFACTORING

type ExternalRequestHeaderPropTypes = {
  externalRequestParentPk?: number;
  externalRequestPk?: number;
  isDuplicate?: boolean;
  externalRequest?: IFormValuesExternalRequest | null;
  refreshTabs?: () => void;
  isCreateChild?: boolean;
  isCreateFromTask?: boolean;
  haveAccessToCreate: boolean;
  refreshForm?: () => void;
  billingAccountId?: number;
  eventPk?: number;
};

const ExternalRequestHeader: FC<ExternalRequestHeaderPropTypes> = (props) => {
  const {
    externalRequestPk,
    isDuplicate,
    externalRequest,
    isCreateChild,
    isCreateFromTask,
    haveAccessToCreate,
    refreshForm,
    billingAccountId,
    eventPk,
  } = props;

  const [haveAccessTaskToCreate, haveAccessToUpdate] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyTask,
      targetAction: ['C', 'U'],
    });

  const title = useGetExternalRequestHeaderTitle({
    externalRequestPk,
    isDuplicate,
    isChild: isCreateChild,
    isCreateFromTask,
    billingAccountId,
    eventPk,
  });

  const {
    currentStatus,
    billingAccount,
    external_request_datetime_of_actual_commencement: actualCommencement,
    external_request_datetime_of_actual_completion: actualCompletion,
  } = useSelector(getExternalRequestInitialValuesFromStore);

  const externalRequestParentPk =
    externalRequest?.external_request_parent_external_request_fk;

  const parentTaskPk =
    externalRequest?.external_request_parent_internal_task_fk;

  const nextWindowChildrenExternalRequest = useNextWindow(
    PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST
  );
  const nextWindowCreateTaskFromExternalRequest = useNextWindow(
    PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST,
    PATH_EXTERNAL_REQUEST_TABLE
  );

  const isNew =
    !externalRequestPk || isDuplicate || isCreateChild || isCreateFromTask;

  const isEditing = !isNew && !isCreateChild && !isCreateFromTask;

  const [internalTaskListChildren, externalRequestListChildren]: [
    IChildInternalTask[] | undefined,
    IChildExternalRequest[] | undefined
  ] = [
    externalRequest?.childInternalTasks,
    externalRequest?.childExternalRequests,
  ];

  const hasChildrenTasks =
    internalTaskListChildren &&
    internalTaskListChildren.length > 0 &&
    isEditing;

  const hasChildrenExternalRequests =
    externalRequestListChildren &&
    externalRequestListChildren.length > 0 &&
    isEditing;

  const hasParentExternalRequest = !!externalRequestParentPk;
  const hasParentTask = !!parentTaskPk;

  const accountNumber =
    billingAccount && getAccountNumber(billingAccount?.label);

  const {
    selectHandlerSetParentTask,
    selectHandlerSetParentExternalRequest,
    handleDeleteParentTask,
    handleDeleteParentExternalRequest,
  } = useHandlersExternalRequestHeader({
    pk: externalRequestPk,
    refresh: refreshForm,
  });

  const buttonSetExternalRequestParentText = hasParentExternalRequest
    ? `${t('Родительская заявка')}:${externalRequestParentPk}`
    : UI_TITLE.TO_PARENT_EXTERNAL_REQUEST;

  const buttonSetTaskParentText = hasParentTask
    ? `${t('Родительская задача')}:${parentTaskPk}`
    : UI_TITLE.TO_PARENT_TASK;

  // @todo combine with subHeaderDetails component
  return (
    <>
      <div className={classNames('py-2 py-md-3 pl-4 pr-4', styles.header)}>
        <div className="d-flex flex-row justify-content-between align-items-center ">
          <div
            className={classNames(
              styles.headerData,
              'd-flex flex-column flex-sm-row'
            )}
          >
            <div
              className={classNames(
                styles.mainTitle,
                'd-flex flex-column justify-content-center px-3'
              )}
            >
              {currentStatus && (
                <div className="d-inline-flex">
                  <ColoredStatusHeaderLabel
                    color={currentStatus.color}
                    isVisible={!isNew}
                  >
                    {currentStatus.name}
                  </ColoredStatusHeaderLabel>
                </div>
              )}
              <div>{title}</div>
            </div>
            <div
              className={classNames(
                'd-flex flex-row justify-content-start align-items-start '
              )}
            >
              {accountNumber && (
                <FormHeaderLink
                  title={BILLING_ACCOUNT_HEADER_LABEL}
                  pk={billingAccount.value}
                  path={PATH_ORGANIZATIONS_ACCOUNTS_VIEW}
                >
                  {accountNumber}
                </FormHeaderLink>
              )}
              {isEditing && !isNew && (
                <FormHeaderLink
                  pk={externalRequestParentPk}
                  path={PATH_EXTERNAL_REQUEST_EDIT}
                  title={SUBHEADER_EXTERNAL_REQUEST_PARENT_TITLE}
                >
                  {externalRequestParentPk}
                </FormHeaderLink>
              )}
              {hasChildrenExternalRequests && (
                <ListLinkFormHeader
                  listOfChildren={externalRequestListChildren}
                  title={TITLE_CHILDREN_EXTERNAL_REQUEST}
                  path={PATH_EXTERNAL_REQUEST_EDIT}
                />
              )}
              <FormHeaderLink
                pk={parentTaskPk}
                path={PATH_TASK_EDIT}
                title={SUBHEADER_TASK_PARENT_TITLE}
              >
                {parentTaskPk}
              </FormHeaderLink>
              {hasChildrenTasks && (
                <ListLinkFormHeader
                  listOfChildren={internalTaskListChildren}
                  title={TITLE_CHILDREN_TASKS}
                  path={PATH_TASK_EDIT}
                />
              )}
              {actualCommencement && (
                <ExternalRequestHeaderTimeCompletion
                  actualCommencement={actualCommencement}
                  actualCompletion={actualCompletion}
                />
              )}
            </div>
          </div>
          <div className="d-flex-column">
            {externalRequestPk && !isDuplicate && !isNew && (
              <div className="mb-1">
                {haveAccessToCreate && (
                  <ButtonSubHeaderForm
                    handleClick={nextWindowCreateTaskFromExternalRequest.bind(
                      null,
                      externalRequestPk
                    )}
                    text={UI_TITLE.CREATE_SUBTASK}
                    className={classNames(styles.subButtonsCreateChildren)}
                  />
                )}
                {haveAccessTaskToCreate && (
                  <ButtonSubHeaderForm
                    handleClick={nextWindowChildrenExternalRequest.bind(
                      null,
                      externalRequestPk
                    )}
                    text={UI_TITLE.CREATE_SUB_EXTERNAL_REQUEST}
                    className={classNames(
                      styles.subButtonsCreateChildren,
                      'ml-1'
                    )}
                  />
                )}
              </div>
            )}
            {haveAccessToUpdate && !isNew && (
              <div className={'d-flex'}>
                <PopoutDynamicSelectPaginated
                  id="taskParentSelected"
                  placeholder={t('Выберите родительскую задачу...')}
                  onChange={selectHandlerSetParentTask}
                  selectHandler={getTaskForSelectApiV2}
                  buttonDefaultText={buttonSetTaskParentText}
                  isViewIconBefore={hasParentTask}
                  handlerForBeforeIcon={handleDeleteParentTask}
                 />

                <PopoutDynamicSelectPaginated
                  id="externalRequestParentSelected"
                  placeholder={t('Выберите родительскую заявку...')}
                  onChange={selectHandlerSetParentExternalRequest}
                  selectHandler={getExternalRequestForSelectApiV2}
                  position={PopoutSelectMenuPosition.left}
                  buttonDefaultText={buttonSetExternalRequestParentText}
                  className="ml-1"
                  isViewIconBefore={hasParentExternalRequest}
                  handlerForBeforeIcon={handleDeleteParentExternalRequest}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ExternalRequestHeader);
