enum ExternalRequestSortCriteria {
  Default = '',
  PkAsc = 'external_request_pk',
  PkDesc = '-external_request_pk',
  DateAddedAsc = 'external_request_datetime_added',
  DateAddedDesc = '-external_request_datetime_added',
  DateEditedAsc = 'external_request_datetime_edited',
  DueDateAsc = 'external_request_date_supposed_duedate',
  DueDateDesc = '-external_request_date_supposed_duedate',
}

export default ExternalRequestSortCriteria;
