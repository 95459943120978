import React, { FC, memo } from 'react';

import {
  ITaskListOfHistoryEntity,
  ICreationTaskInfo,
} from '../../../services/api/tasks/tasks/IGetHistoryTaskPayload';
import { parseISO } from 'date-fns';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import HistoryRows from 'components/misc/WrapperHistoryRows/HistoryRows';
import HistoryContainerTable from 'components/tables/HistoryContaierTable/HistoryContainerTable';
import { formatDateTime } from "../../../services/utils/dateHelper/dateHelper";

type PropTypes = {
  taskListOfHistoryEntity?: ITaskListOfHistoryEntity[];
  creationTaskInfo?: ICreationTaskInfo;
  isLoading?: boolean;
};

// delete duplicate - create component container for history
const TaskHistory: FC<PropTypes> = (props) => {
  const { taskListOfHistoryEntity, creationTaskInfo, isLoading } = props;
  return (
    <HistoryContainerTable>
      {isLoading && <LoaderFetch />}
      {taskListOfHistoryEntity &&
        taskListOfHistoryEntity?.length > 0 &&
        !isLoading &&
        taskListOfHistoryEntity
          ?.reverse()
          .map((record: ITaskListOfHistoryEntity) => {
            return (
              !!record?.internal_task_edited_column_to_old_and_new_values && (
                <HistoryRows
                  editorFullName={record?.internal_task_editor?.full_name}
                  valuesRaw={
                    record.internal_task_edited_column_to_old_and_new_values
                  }
                  dateTimeEdit={record.internal_task_datetime_edited}
                />
              )
            );
          })}
      {creationTaskInfo?.internal_task_datetime_added &&
        creationTaskInfo?.internal_task_author_dk && (
          <tr>
            <td>
              {formatDateTime(
                parseISO(creationTaskInfo?.internal_task_datetime_added)
              )}
            </td>
            <td colSpan={4}>{'Cоздание'}</td>
            <td>{creationTaskInfo?.internal_task_author?.full_name}</td>
          </tr>
        )}
    </HistoryContainerTable>
  );
};

TaskHistory.whyDidYouRender = true;

export default memo(TaskHistory);
