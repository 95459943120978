import { composeClientSelectOptionsInNumbersArray } from '../../../../services/utils/selects/composeIClientSelectOptionsInNumbers';
import { IKeyValue } from '../../../../services/interfaces/IKeyValue';
import {
  IEmployeeFilters,
  IGetPaginatedParamsEmployee,
} from '../../../../services/api/staff/employee/IGetPaginatedParamsEmployee';
import deleteUnnecessaryParameters
    from "../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters";

const composeParamsForGetEmployee = (
  params: IEmployeeFilters
): IGetPaginatedParamsEmployee => {
  const { employee_phone_number } = params;

  const {
    employee_job_title_fk_array,
    employee_supervisor_fk_array,
  }: IKeyValue<number[]> =
    composeClientSelectOptionsInNumbersArray<IEmployeeFilters>(params, [
      'employee_supervisor_fk_array',
      'employee_job_title_fk_array',
    ]);

  const composeNewParams = {
    ...params,
    employee_job_title_fk_array,
    employee_supervisor_fk_array,
    employee_identity_dk: params?.employee_identity_dk
      ? params?.employee_identity_dk.value
      : undefined,
    employee_phone_number: employee_phone_number
      ? +employee_phone_number
      : undefined,
  };
  return deleteUnnecessaryParameters(composeNewParams);
};

export default composeParamsForGetEmployee;
