import { t } from 'services/utils/translation';
import Yup from './checkDateRange';
import isMediumPassword from 'components/controls/PasswordInput/hooks/useGetStringCharacteristics/utils/isMediumPassword';

export function checkPasswordComplexity(this: Yup.StringSchema, msg?: string) {
  return this.test({
    name: 'check-password-complexity',
    exclusive: false,
    message: msg || t('Введите более сложный пароль'),
    test: function (value?: string) {
      return value ? isMediumPassword(value) : false;
    },
  });
}
