import IBaseParams from '../../interfacesApi/IBaseParams';
import IGetPaginatedStatusesParams from './IGetPaginatedStatusesParams';
import { IBaseResponse } from '../../interfacesApi/IBaseResponse';
import UndercurrentApiClient from '../../UndercurrentApiClient';
import { IGetPaginatedStatusesPayload } from './IGetPaginatedStatusesPayload';
import ITaskStatus from './ITaskStatus';
import {
  IUpdateTaskStatusParams,
  ICreateTaskStatusParams,
} from './IUpdateTaskStatusParams';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import { IForSelectRequestV2 } from '../../../utils/generateMethodForSelect/generateMethodForSelect';
import deleteUnnecessaryParameters from '../../../utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
const { TASK_STATUS } = UNDERCURRENT_CHILD_MODULES;

const api = () => new UndercurrentApiClient().methodCreator(TASK_STATUS);

export /**
 *Get TaskStatusesPage
 *
 * @param {IBaseParams<IGetPaginatedStatusesParams>} filterParams
 * @return {*}
 */
const getPaginatedTaskStatusesApiV2 = async (
  filterParams: IBaseParams<IGetPaginatedStatusesParams>
): Promise<IBaseResponse<IGetPaginatedStatusesPayload>> => {
  const api = new UndercurrentApiClient();

  const {
    params: { skip, length },
  } = filterParams;

  const clearParams = deleteUnnecessaryParameters(filterParams, [
    'skip',
    'length',
  ]);

  return await api.postFilters(
    `internal-task-status/paginated-read/${skip}/${length}`,
    clearParams
  );
};

export /**
 *Get TaskStatusesPage
 *
 * @param {IBaseParams<IGetPaginatedStatusesParams>} filterParams
 * @return {*}
 */
const getAllStatusesApiV2 = async (
  filterParams: IBaseParams<{}>
): Promise<IBaseResponse<IGetPaginatedStatusesPayload>> => {
  const api = new UndercurrentApiClient();

  return await api.postFilters(
    `internal-task-status/paginated-read/${0}/${0}`,
    filterParams
  );
};

export /**
 *Create TaskStatusesPage
 *
 * @return {*}
 * @param formParams
 */
const createTaskStatusesApiV2 = async (
  formParams: IBaseParams<ICreateTaskStatusParams>
): Promise<IBaseResponse<ITaskStatus>> => {
  const clearParams = deleteUnnecessaryParameters(formParams, [
    'internal_task_status_pk',
  ]);

  const api = new UndercurrentApiClient();
  const create = api.extractPostJsonV2('internal_task_status');
  return await create(`internal-task-status/create/`, clearParams);
};

export /**
 *update TaskStatusesPage
 *
 * @return {*}
 * @param formParams
 */
const updateTaskStatusesApiV2 = async (
  formParams: IBaseParams<IUpdateTaskStatusParams>
): Promise<IBaseResponse<ITaskStatus>> => {
  const {
    params: { internal_task_status_pk },
  } = formParams;
  const clearParams = deleteUnnecessaryParameters(formParams, [
    'internal_task_status_pk',
  ]);

  const api = new UndercurrentApiClient();
  const put = api.extractPutJson('internal_task_status');
  return await put(
    `internal-task-status/update/${internal_task_status_pk}`,
    clearParams
  );
};

export /**
 *FOR SELECT TASK-STATUS
 *
 * @param {IBaseForSelectRequestV2} params
 * @return {*}
 */
const getTaskStatusesForSelectApiV2 = async (
  params: IBaseParams<IForSelectRequestV2>
) => {
  return api().forSelect(params);
};
