export const EVENT_SAVE_FILTERS = 'EVENT_SAVE_FILTERS';
export const EVENT_CLEAR_FILTERS = 'EVENT_CLEAR_FILTERS';
export const EVENT_SAVE_EXTERNAL_REQUEST_FILTERS =
  'EVENT_SAVE_EXTERNAL_REQUEST_FILTERS';
export const EVENT_CLEAR_EXTERNAL_REQUEST_FILTERS =
  'EVENT_CLEAR_EXTERNAL_REQUEST_FILTERS';
export const SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER =
  'SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_PK_FILTER';

export const SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER =
  'SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_WORK_CATEGORY_FILTER';

export const SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER =
  'SET_EVENT_EXTERNAL_REQUEST_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER';
