import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import { UNDERCURRENT_CHILD_MODULES } from 'services/constants/UNDERCURRENT_CHILD_MODULES';
import IPrimaryKey from 'services/types/IPk';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { ICreateController } from './ICreateController';
import { IControllerWrapper } from './IControllerWrapper';
import { IUpdateController } from './IUpdateController';
import { IControllerClosedParams } from './IControllerClosedParams';
import {
  IControllerFilters,
  IControllerPaginatedParams,
} from './IControllerFilters';
import { IAddCommentsControllerParams } from './IAddCommentsControllerParams';
import { IController } from './IController';
import { IControllerHistoryPayload } from './IControllerHistory';
import IGetCommentsPayloadV2 from 'services/interfaces/IGetCommentsPayload';

const api = () =>
  new UndercurrentApiClient().methodCreator(
    UNDERCURRENT_CHILD_MODULES.CONTROLLER
  );

export const createControllerApi = async (
  params: IBaseParams<ICreateController>
): Promise<IBaseResponse<IControllerWrapper>> => api().create(params);

export const updateControllerApi = async (
  params: IBaseParams<IUpdateController>
): Promise<IBaseResponse<IControllerWrapper>> => await api().update(params);

export const doCloseControllerApi = async (
  params: IBaseParams<IControllerClosedParams>
): Promise<IBaseResponse<IController>> => api().update(params);

export const readControllerApi = async (
  params: IBaseParams<Partial<IPrimaryKey<string>>>
): Promise<IBaseResponse<IControllerWrapper>> => api().read(params);

export const getPaginatedControllersApi = async (
  params: IBaseParams<IControllerPaginatedParams | IControllerFilters>
): Promise<IBaseResponse<IController[]>> => api().getPaginated(params);

export const deleteControllerApi = async (
  deleteParams: IBaseParams<IPrimaryKey>
): Promise<IBaseResponse<undefined>> => api().delete(deleteParams);

export const getForSelectControllerApi = async (
  params: IBaseParams<IForSelectRequestV2>
) => api().forSelect(params);

export const getHistoryControllerApiV2 = async (
  params: IBaseParams<IPrimaryKey<string>>
): Promise<IBaseResponse<IControllerHistoryPayload>> =>
  api().getHistory(params);

export const getCommentsControllerApi = async (
  params: IBaseParams<IPrimaryKey<string>>
): Promise<IBaseResponse<IGetCommentsPayloadV2>> => api().getComments(params);

export const addCommentControllerApi = async (
  params: IBaseParams<IAddCommentsControllerParams<string>>
): Promise<IBaseResponse<IControllerWrapper>> => api().addComment(params);
