import { useGetList } from 'hooks/useGetList';
import { FC, useMemo } from 'react';
import {
  getCounterpartyPaginatedApiV2,
  deleteCounterpartyApiV2,
  exportCounterpartyApiV2,
} from '../../../services/api/organizationsV2/counterparty/counterpartyApi';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import { t } from 'services/utils/translation';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { Pager } from '../../../components/tables/Pager';
import { useColumnFilter } from '../../../hooks/useColumnFilter';
import HeaderWithSearch from 'components/tables/HeaderWithSearch';
import HeaderWithDatePickerRangeV2 from 'components/tables/HeaderWithDatePickerRangeV2/HeaderWithDatePickerRangeV2';
import classNames from 'clsx';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import useModalV2 from 'hooks/useModalV2';
import { IModalV2 } from '../../../hooks/useModalV2';

import useAddOrEditPushNavigation from '../../../hooks/useAddOrEditPushNavigation';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';

import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ICounterpartyFilters } from 'services/api/organizationsV2/counterparty/IGetCounterPartyPaginatedParams';
import {
  PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
  PATH_ORGANIZATIONS_COUNTERPARTY_ADD,
} from '../../../services/pathConstants';
import HeaderWithSelectAdvanced from 'components/tables/HeaderWithSelectAdvanced/HeaderWithSelectAdvanced';
import { parseISO } from 'date-fns';
import { ICounterpartyForList } from '../../../services/api/organizationsV2/counterparty/ICounterparty';
import SubHeaderButtonExport from 'components/tables/SubHeaderButtonExport/SubHeaderButtonExport';
import { targetAccessPolicyCounterparty } from '../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { filtersCounterparty } from './counterPartyColumnConstant';
import {
  columnVisibilityInitialCounterparty,
  COLUMN_LABELS_COUNTERPARTY,
} from './counterPartyColumnConstant';
import { formatDateTimeShort } from '../../../services/utils/dateHelper/dateHelper';
import { getDropDownEditTitle } from '../../../services/utils/extractGetLeftValueIfTrue';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCounterpartyFilters,
  saveCounterpartyFilters,
} from 'store/counterparty/actions';
import isEqual from 'react-fast-compare';
import useUtilsForCounterpartyPage from './hooks/useUtilsForCounterpartyPage';
import useFilterBooleanSelect, {
  BooleanSelectField,
} from 'hooks/useFilterIsPruned/useFilterBooleanSelect';
import useCounterpartyHandleSearch from './hooks/useCounterpartyHandleSearch';
import { getCounterpartyFiltersFromStore } from 'store/counterparty/selectors';
import composeParamsGetCounterparty from './utils/composeParamsGetCounterparty';
import { initialCounterpartyFilters } from 'store/initialStore/initialCounterpartyFilters';
import useClearFilters from 'hooks/useClearFilters';
import MyFilters from 'components/tables/MyFilters/MyFilters';
const CREATE_BUTTON_TITLE = t('Добавить контрагента');

const CounterpartyPage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitialCounterparty);

  const [haveAccessToCreate, haveAccessToUpdate, haveAccessToExport] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyCounterparty,
      targetAction: ['C', 'U', 'E'],
    });

  const dispatch = useDispatch();

  const defaultTableFilters = useSelector(
    getCounterpartyFiltersFromStore,
    isEqual
  );
  const saveFilters = (filters: ICounterpartyFilters) =>
    dispatch(saveCounterpartyFilters(filters));

  const { composeCounterPartyList } = useUtilsForCounterpartyPage();

  const initialParams: ICounterpartyFilters = useMemo(
    () => defaultTableFilters,
    [defaultTableFilters]
  );

  const onFinally = () => setClearingFiltersSpinner(false);

  const {
    data: counterPartyList,
    isLoading,
    refreshListData,
    total,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    setParams,
    params,
    params: {
      skip,
      length,
      counterparty_pk: pk,
      counterparty_datetime_added_upper_bound: datetimeUpperBoundFilter,
      counterparty_datetime_added_lower_bound: dateTimeLowerBoundFilter,
      counterparty_actual_address: addressFilter,
      counterparty_description: descriptionFilter,
      counterparty_phone_number: phoneNumberFilter,
      counterparty_email: emailFilter,
      counterparty_inn: innFilter,
      counterparty_complete_name: completedNameFilter,
      counterparty_is_natural_person: isNaturalPerson,
      counterparty_is_affiliate: isAffiliate,
    },
  } = useGetList({
    getDataApi: getCounterpartyPaginatedApiV2,
    initialParams,
    convertData: composeCounterPartyList,
    saveFilters,
    convertedParameters: composeParamsGetCounterparty,
    onFinally,
  });

  const {
    handleSelectBooleanType: handleSelectBooleanTypeIsNatural,
    booleanOptions: booleanOptionsIsNatural,
  } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.counterpartyIsNatural,
  });

  const {
    handleSelectBooleanType: handleSelectBooleanTypeIsAffiliate,
    booleanOptions: booleanOptionsIsAffiliate,
  } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.counterpartyIsAffiliate,
  });

  const {
    handleSearchCounterpartyPk,
    handleSearchPhoneNumber,
    handleSearchDescription,
    handleSearchEmail,
    handleSearchActualAddress,
    handleSearchINN,
    handleCounterpartyCompleteName,
  } = useCounterpartyHandleSearch(onSearchRequest);

  const { clearFilters, isDisableClearButton, setClearingFiltersSpinner } =
    useClearFilters(
      initialCounterpartyFilters,
      params,
      clearCounterpartyFilters,
      setParams
    );

  const {
    state: { isAddEditFormOpen, entity: counterparty },
    openAddEditForm,
  }: IModalV2<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteCounterpartyApiV2,
    entityIdKey: 'pk',
  });

  useAddOrEditPushNavigation({
    pk: counterparty?.pk,
    isAddEditFormOpen,
    pathEdit: PATH_ORGANIZATIONS_COUNTERPARTY_EDIT,
    pathAdd: PATH_ORGANIZATIONS_COUNTERPARTY_ADD,
  });

  //IMPORT

  return (
    <MainLayout>
      <Subheader>
        <SubheaderButton>
          <MyFilters
            saveFilters={saveFilters}
            currentFilter={params}
            setFilters={setParams}
            keyMyFilter="counterparty"
          />
        </SubheaderButton>

        <SubheaderButton
          onClick={clearFilters}
          bordered={true}
          disabled={isDisableClearButton}
        >
          {t('Сбросить фильтры')}
        </SubheaderButton>
        <SubHeaderButtonExport
          params={params}
          getExportLinkApi={exportCounterpartyApiV2}
          disabled={isLoading}
          haveAccessToExport={haveAccessToExport}
          convertParams={composeParamsGetCounterparty}
        />

        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <AddIcon className="mr-2 text-white" /> {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}

        <div style={{ display: 'flex' }} className="mr-2" />
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                scope="col"
                className={`id pr-0 ${!visibility.pk ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field="counterparty_object_pk"
                  title={COLUMN_LABELS_COUNTERPARTY.PK}
                  onSearch={handleSearchCounterpartyPk}
                  defaultValue={pk ? pk + '' : ''}
                  customStyle={{ marginLeft: '0px' }}
                  onSort={onSortClick}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={classNames('p-0', {
                  'd-none': !visibility.dateTimeAdded,
                })}
              >
                <HeaderWithDatePickerRangeV2
                  title={COLUMN_LABELS_COUNTERPARTY.DATETIME_ADDED}
                  propsOnSearch={{
                    onSearchRequest,
                    keyDate: [
                      'counterparty_datetime_added_lower_bound',
                      'counterparty_datetime_added_upper_bound',
                    ],
                  }}
                  startDate={
                    dateTimeLowerBoundFilter
                      ? new Date(dateTimeLowerBoundFilter)
                      : null
                  }
                  endDate={
                    datetimeUpperBoundFilter
                      ? new Date(datetimeUpperBoundFilter)
                      : null
                  }
                  field="counterparty_datetime_added"
                  onSort={onSortClick}
                  hasTime
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${!visibility.phone_number ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field="counterparty_phone_number"
                  title={COLUMN_LABELS_COUNTERPARTY.PHONE_NUMBER}
                  onSearch={handleSearchPhoneNumber}
                  onSort={onSortClick}
                  defaultValue={phoneNumberFilter ? phoneNumberFilter + '' : ''}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${!visibility.description ? 'd-none' : ''}`}
              >
                <HeaderWithSearch
                  title={COLUMN_LABELS_COUNTERPARTY.DESCRIPTION}
                  onSearch={handleSearchDescription}
                  defaultValue={descriptionFilter ? descriptionFilter : ''}
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${!visibility.email ? 'd-none' : ''}`}
              >
                <HeaderWithSearch
                  title={COLUMN_LABELS_COUNTERPARTY.EMAIL}
                  onSearch={handleSearchEmail}
                  defaultValue={emailFilter}
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${!visibility.actual_address ? 'd-none' : ''}`}
              >
                <HeaderWithSearch
                  title={COLUMN_LABELS_COUNTERPARTY.ACTUAL_ADDRESS}
                  onSearch={handleSearchActualAddress}
                  defaultValue={addressFilter}
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${!visibility.inn ? 'd-none' : ''}`}
              >
                <HeaderWithSearchAndSort
                  field="counterparty_inn"
                  title={COLUMN_LABELS_COUNTERPARTY.INN}
                  onSearch={handleSearchINN}
                  onSort={onSortClick}
                  defaultValue={innFilter}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={classNames(
                  'w-10',
                  !visibility.is_natural_person && 'd-none'
                )}
              >
                <HeaderWithSelectAdvanced
                  label={COLUMN_LABELS_COUNTERPARTY.LEGAL_STATUS}
                  options={booleanOptionsIsNatural}
                  onChange={handleSelectBooleanTypeIsNatural}
                  defaultValue={isNaturalPerson}
                />
              </th>
              <th
                scope="col"
                className={classNames(
                  'w-10',
                  !visibility.is_natural_person && 'd-none'
                )}
                style={{ width: '8%' }}
              >
                <HeaderWithSelectAdvanced
                  label={COLUMN_LABELS_COUNTERPARTY.IS_AFFILIATION}
                  options={booleanOptionsIsAffiliate}
                  onChange={handleSelectBooleanTypeIsAffiliate}
                  defaultValue={isAffiliate}
                />
              </th>
              <th
                scope="col"
                className={`p-0 ${!visibility.complete_name ? 'd-none' : ''}`}
              >
                {
                  <HeaderWithSearch
                    title={COLUMN_LABELS_COUNTERPARTY.NAME}
                    onSearch={handleCounterpartyCompleteName}
                    defaultValue={completedNameFilter}
                  />
                }
              </th>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filtersCounterparty.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {counterPartyList &&
                counterPartyList?.map((counterParty: ICounterpartyForList) => {
                  const {
                    counterparty_pk: pk,
                    counterparty_datetime_added: addedDateTime,
                    counterparty_description: description,
                    counterparty_phone_number: phoneNumber,
                    counterparty_email: email,
                    legalStatus,
                    isAffiliate,
                    counterparty_actual_address: actualAddress,
                    counterparty_inn: inn,
                    name,
                    counterpartyLink,
                  } = counterParty;

                  return (
                    <tr key={pk}>
                      <td className={classNames({ 'd-none': !visibility.pk })}>
                        {counterpartyLink}
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.dateTimeAdded,
                        })}
                      >
                        <span>
                          {formatDateTimeShort(parseISO(addedDateTime))}
                        </span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.phone_number,
                        })}
                      >
                        <span>{phoneNumber}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.description,
                        })}
                      >
                        <span>{description}</span>
                      </td>

                      <td
                        className={classNames({
                          'd-none': !visibility.email,
                        })}
                      >
                        <span>{email}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.actual_address,
                        })}
                      >
                        <span>{actualAddress}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.inn,
                        })}
                      >
                        <span>{inn}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.is_natural_person,
                        })}
                      >
                        <span>{legalStatus}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.is_affiliation,
                        })}
                      >
                        <span>{isAffiliate}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.complete_name,
                        })}
                      >
                        <span>{name}</span>
                      </td>

                      <td align="right">
                        <ActionsDropdown>
                          <ActionsDropdownItem
                            label={getDropDownEditTitle(haveAccessToUpdate)}
                            onClick={openAddEditForm.bind(null, {
                              pk,
                              rendition:
                                counterParty?.counterparty_company_full_name
                                  ? counterParty?.counterparty_company_full_name
                                  : counterParty?.counterparty_individual_last_name ??
                                    '',
                            })}
                            icon={<EditIcon className="text-primary" />}
                          />
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <LoaderFetch />
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />
      </div>
    </MainLayout>
  );
};

export default CounterpartyPage;
