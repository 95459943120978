import * as Yup from 'yup';
import { t } from '../utils/translation';
import { isFilenameLimitInDocumentForm } from 'services/utils/isFileNameLimit/isFileNameLimit';

function fileNameValidator(values: File[] | undefined): boolean {
  return values ? isFilenameLimitInDocumentForm(values) : true;
}

export function fileNameLengthValid(this: Yup.ArraySchema<any>, msg?: string) {
  return this.test({
    name: 'file-name-is-valid',
    exclusive: false,
    message: msg || t('Достигнут лимит длины имени файла.'),
    test: function (value?: any[]) {
      return fileNameValidator(value);
    },
  });
}
