import HiddenField from 'components/controls/HiddenField';
import TextField from 'components/controls/TextField';
import { Formik } from 'formik';
import { FC, memo, useEffect, useState } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';

import { t } from 'services/utils/translation';
import { usePostV3 } from '../../../hooks/usePostV3';
import useRead from 'hooks/useRead';

import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { IEventType } from 'services/api/organizationsV2/event-type/IEventType';
import EventType from 'models/EventType/EventType';
import {
  createEventTypeApiV2,
  readEventTypeApiV2,
  updateEventTypeApiV2,
} from 'services/api/organizationsV2/event-type/eventTypeApi';
import { ICreateEventType } from 'services/api/organizationsV2/event-type/ICreateEventType';
import { IUpdateEventType } from 'services/api/organizationsV2/event-type/IUpdateEventType';

interface IFormValuesEventType {
  event_type_rendition?: string;
  pk?: number;
}

type PropsType = {
  successHandler: () => void;
  pk?: number;
};

const toFormValuesEventType = (data: IEventType): IFormValuesEventType => ({
  event_type_rendition: data.event_type_rendition,
  pk: data.event_type_pk,
});

const convertData = (payload: { event_type: IEventType }) =>
  toFormValuesEventType(payload.event_type);

const EventTypeForm: FC<PropsType> = (props) => {
  const { successHandler, pk } = props;
  const isNew = !pk;
  const [eventType, setEventType] = useState<IFormValuesEventType>(
    new EventType(pk)
  );

  const updateEventType = usePostV3({
    fetchApi: updateEventTypeApiV2,
    successHandler,
    successMessage: t('Тип события обновлен успешно.'),
  });

  const createEventType = usePostV3({
    fetchApi: createEventTypeApiV2,
    successHandler,
    successMessage: t('Тип события создан успешно.'),
  });

  const { data: formValues, isLoading } = useRead({
    getDataApi: readEventTypeApiV2,
    params: { pk },
    convertData,
    initialData: new EventType(pk),
    condition: !!pk,
  });

  useEffect(() => {
    if (!isNew && formValues) {
      setEventType(formValues);
    }
  }, [formValues, isNew]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={eventType}
      validationSchema={EventType.validationSchema()}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        if (!values?.pk) {
          await createEventType(
            values as ICreateEventType,
            setErrors,
            resetForm
          );
        } else {
          await updateEventType(
            values as IUpdateEventType,
            setErrors,
            resetForm
          );
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {!isLoading && (
            <Form onSubmit={handleSubmit} noValidate={true}>
              {!isNew && (
                <HiddenField
                  id="pk"
                  value={values.pk + ''}
                  hasError={errors.pk != null && touched.pk != null}
                />
              )}
              <TextField
                type="text"
                id="event_type_rendition"
                label={t('Тип')}
                placeholder={t('Название типа')}
                hasError={
                  errors.event_type_rendition != null &&
                  touched.event_type_rendition != null
                }
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={values.event_type_rendition}
                required={true}
              />
              <FormGroup className="d-block text-right mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {t('Сохранить')}
                </Button>
              </FormGroup>
            </Form>
          )}
          {isLoading && <LoaderFetch />}
        </>
      )}
    </Formik>
  );
};

export default memo(EventTypeForm);
