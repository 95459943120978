import { usePostV2 } from 'hooks/usePostV2';
import { markAsReactedToApi } from 'services/api/notification/notificationApi';
import { t } from '../../../services/utils/translation';
import { useState } from 'react';
import INotificationForList from '../utils/toNotificationForList/INotificationForList';

const SUCCESS_MESSAGE_ALL_READ = t('Уведомления отмечены, как прочитанные');
const SUCCESS_MESSAGE_ONE_READ = t('Уведомление отмечено, как прочитанное');

const getOnReadNotifications = (notifications: INotificationForList[]) =>
  notifications.reduce(
    (accumulator: number[], currentNotification: INotificationForList) => {
      if (!currentNotification.notification_is_reacted_to) {
        accumulator.push(currentNotification.notification_pk);
      }
      return accumulator;
    },

    []
  );

const composeParamsForRaedNotifications = (notificationsPk: number[]) => ({
  list_of_notification_pks: notificationsPk,
});

const useMarkNotificationsAsRead = (
  notifications: INotificationForList[],
  setRefresh: Function
) => {
  const [isLoadingMarkToReact, setIsLoadingMarkToRead] =
    useState<boolean>(false);

  const extractMarkedAsRead = usePostV2({
    fetchApi: markAsReactedToApi,
    errorMessage: t('Не удалось отметить уведомление, как прочитанное'),
    successHandler: () => {
      setRefresh((prevState: boolean) => !prevState);
    },
  });

  const unreadNotifications = getOnReadNotifications(notifications);

  const counterUnreadNotifications = unreadNotifications.length;

  const markedOneAsRead = async (notificationPks: number[]) => {
    setIsLoadingMarkToRead(true);
    const paramsForMarkAsRead =
      composeParamsForRaedNotifications(notificationPks);
    await extractMarkedAsRead(paramsForMarkAsRead, SUCCESS_MESSAGE_ONE_READ);
    setIsLoadingMarkToRead(false);
  };

  const markedAllAsRead = async () => {
    setIsLoadingMarkToRead(true);
    const paramsForMarkAsRead =
      composeParamsForRaedNotifications(unreadNotifications);
    await extractMarkedAsRead(paramsForMarkAsRead, SUCCESS_MESSAGE_ALL_READ);
    setIsLoadingMarkToRead(false);
  };

  return {
    markedOneAsRead,
    markedAllAsRead,
    isLoadingMarkToReact,
    counterUnreadNotifications,
  };
};

export default useMarkNotificationsAsRead;
