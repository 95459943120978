import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_EXTERNAL_REQUEST_TABLE } from 'services/pathConstants';

const useExternalRequestSuccessHandler = (
  to: string = PATH_EXTERNAL_REQUEST_TABLE
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const wherePath = location?.state?.['from'];
  
  return useCallback(
      (isSaveAndExit: boolean) => {
          if (isSaveAndExit) {
              wherePath ? navigate(wherePath) : navigate(to);
          }
      },
      [navigate, to, wherePath]
  );
};

export default useExternalRequestSuccessHandler;
