import * as Yup from 'yup';

export function requiredWhenDefined(this: Yup.AnySchema, msg?: string) {
  return this.test({
    message: msg || '',
    name: 'requiredWhenDefined',
    test(value) {
      if (value === undefined) {
        return true;
      }

      return value !== null;


    },
  });
}
