enum BillingAccountSortCriteria {
  Default = '',
  DateAddedAsc = 'billing_account_datetime_added',
  DateAddedDesc = '-billing_account_datetime_added',
  DateEditedAsc = 'billing_account_datetime_edited',
  DateEditedDesc = '-billing_account_datetime_edited',
  CodeAsc = 'billing_account_code_number',
  CodeDesc = '-billing_account_code_number',
}

export default BillingAccountSortCriteria;
