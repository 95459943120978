import deleteUnnecessaryParameters from "./deleteUnnecessaryParameters";
const deleteUnnecessaryParametersForGetListV2 = <ParamsType extends {}>(
  params: ParamsType
): ParamsType => {
  const defaultKeysToDelete = ['skip', 'length', 'sort_by'];

  return deleteUnnecessaryParameters(params, defaultKeysToDelete);
};

export default deleteUnnecessaryParametersForGetListV2;
