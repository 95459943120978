import { FC, useState } from 'react';
import config from 'config.json';
import { IUseGetList, useGetList } from '../../../hooks/useGetList';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import { t } from 'services/utils/translation';
import { ReactComponent as AddIcon } from 'img/icons/icon-file.svg';
import { useColumnFilter } from '../../../hooks/useColumnFilter';
import HeaderWithSearchAndSort from 'components/tables/HeaderWithSearchAndSort/HeaderWithSearchAndSort';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { ReactComponent as EditIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import LoaderFetch from '../../../components/layouts/LoaderFetch/LoaderFetch';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import { Pager } from '../../../components/tables/Pager';
import useModalV2 from 'hooks/useModalV2';
import { IModal } from 'hooks/useModal';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import { ColumnFilter, ColumnFilterItem } from 'components/tables/ColumnFilter';
import {
  IEventTypeGetPaginatedParams,
  SortCriteriaEventType,
} from '../../../services/api/organizationsV2/event-type/IEventTypeGetPaginatedParams';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyEventType } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import { UI_TITLE } from '../../../services/localLocalization/UITitle';
import { extractGetLeftValueIfTrue } from '../../../services/utils/extractGetLeftValueIfTrue';
import { IEventType } from 'services/api/organizationsV2/event-type/IEventType';
import {
  deleteEventTypeApiV2,
  getPaginatedEventTypeApiV2,
} from 'services/api/organizationsV2/event-type/eventTypeApi';
import EventTypeForm from 'components/organizations/event-type/EventTypeForm';
import classNames from 'clsx';

const deleteMessageError = t('Не удалось удалить тип события');
const CREATE_BUTTON_TITLE = t('Добавить тип');
const MODAL_CREATE_TITLE = t('Создать тип события');
const MODAL_EDITING_TITLE = t('Редактировать тип события');

const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDITING_TITLE,
  MODAL_CREATE_TITLE
);
const MODAL_DELETE_TITLE = t('Удалить тип события');

const COLUMN_LABELS = {
  PK: '№',
  RENDITION: 'Тип',
};

const filters = [
  { name: 'event_type_pk', label: COLUMN_LABELS.PK },
  {
    name: 'event_type_rendition',
    label: COLUMN_LABELS.RENDITION,
  },
] as const;

const columnVisibilityInitial = {
  event_type_pk: true,
  event_type_rendition: true,
};

const EventTypePage: FC = () => {
  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial);

  const [initialParams] = useState<IEventTypeGetPaginatedParams>({
    skip: 0,
    length: config.pageLength,
  });

  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyEventType,
      targetAction: ['U', 'C', 'D'],
    });

  const {
    data: eventTypeList,
    isLoading,
    total,
    refreshListData,
    setStart,
    setLength,
    onSearchRequest,
    onSortClick,
    params: {
      skip,
      length,
      event_type_pk: filterPk,
      event_type_rendition: filterRendition,
    },
  }: IUseGetList<IEventType[], IEventTypeGetPaginatedParams> = useGetList({
    getDataApi: getPaginatedEventTypeApiV2,
    initialParams,
  });

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetEventType },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<{ pk: number; rendition: string }> = useModalV2({
    setRefresh: refreshListData,
    deleteRequest: deleteEventTypeApiV2,
    entityIdKey: 'pk',
  });

  return (
    <MainLayout>
      <Subheader>
        <div style={{ display: 'flex' }} className="mr-5">
          {haveAccessToCreate && (
            <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
              <AddIcon className="mr-2 text-white" />
              {CREATE_BUTTON_TITLE}
            </SubheaderButton>
          )}
        </div>
      </Subheader>
      <div className="table-responsive-none">
        <table className="table table-bordered table-responsive-xl table-hover">
          <thead>
            <tr>
              <th
                align="center"
                scope="col"
                className={`id pr-0 ${
                  !visibility.event_type_pk ? 'd-none' : ''
                }`}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaEventType.NumberAsc}
                  title={COLUMN_LABELS.PK}
                  onSort={onSortClick}
                  onSearch={(searchString: string) =>
                    onSearchRequest(searchString, 'event_type_pk')
                  }
                  defaultValue={filterPk ? filterPk + '' : ''}
                  isOnlyNumbers
                />
              </th>

              <th
                scope="col"
                className={`p-0 ${
                  !visibility.event_type_rendition ? 'd-none' : ''
                }`}
              >
                <HeaderWithSearchAndSort
                  field={SortCriteriaEventType.RenditionAsc}
                  title={COLUMN_LABELS.RENDITION}
                  onSearch={(searchString: string) => {
                    onSearchRequest(searchString, 'event_type_rendition');
                  }}
                  onSort={onSortClick}
                  defaultValue={filterRendition}
                />
              </th>

              <th scope="col" className="actions p-0">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {eventTypeList &&
                eventTypeList.map((targetEventType: IEventType) => {
                  const { event_type_pk: pk, event_type_rendition: rendition } =
                    targetEventType;
                  return (
                    <tr key={pk}>
                      <td
                        className={classNames({
                          'd-none': !visibility.event_type_pk,
                        })}
                      >
                        <span>{pk}</span>
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.event_type_rendition,
                        })}
                      >
                        <span>{rendition}</span>
                      </td>
                      <td align="right">
                        <ActionsDropdown>
                          {haveAccessToUpdate && (
                            <ActionsDropdownItem
                              label={UI_TITLE.EDIT}
                              onClick={openAddEditForm.bind(null, {
                                pk,
                                rendition,
                              })}
                              icon={<EditIcon className="text-primary" />}
                            />
                          )}
                          {haveAccessToDelete && (
                            <ActionsDropdownItem
                              label={UI_TITLE.DELETE}
                              onClick={toggleDeleteForm.bind(null, {
                                pk,
                                rendition,
                              })}
                              icon={<DeleteIcon className="text-primary" />}
                            />
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>

        <LoaderFetch />
        <Pager
          onPageChange={setStart}
          onLengthChange={setLength}
          total={total}
          length={length}
          start={skip}
        />
        <ModalWrapper
          headerLabel={getModalTitle(!!targetEventType?.pk)}
          isOpen={isAddEditFormOpen}
          closeHandler={closeAddEditForm}
        >
          <EventTypeForm
            successHandler={successHandler}
            pk={targetEventType?.pk}
          />
        </ModalWrapper>

        {targetEventType?.pk && (
          <PopupDeleteForm
            headerLabel={MODAL_DELETE_TITLE}
            entityId={targetEventType?.pk}
            entityName={targetEventType?.rendition}
            deleteHandler={entityDeleteHandler}
            isOpen={isDeleteFormOpen}
            toggleHandler={toggleDeleteForm}
            deleteMessageError={deleteMessageError}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default EventTypePage;
